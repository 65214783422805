import React from "react";
import {Link} from "react-router-dom";
import faQuestion  from '../../assets/img/faq/ico-resulve-tus-dudas.svg';
import faPhone from '../../assets/img/faq/ico-lineas-de-atencion.svg';
import faComment  from '../../assets/img/faq/ico-escribenos-correo.svg';


export default function ContactHelp(pros) {
  return (
    <div
      className="container-fluid"
      style={{ background: "#ffffff", fontSize: 12,paddingRight: "0px",paddingLeft: "0px" }}
    >
      <div className="row">
        <div className="col-12 text-center mt-1 mb-5 tituloAyuda">
          <br />
          ¿Necesitas ayuda?
          <div className="mt-5 mb-4">
            <p style={{fontSize:"16px", color:"#595959", fontFamily: "Helvetica"}}>
            Recibe una asesoría completa sobre los beneficios de tu portafolio de servicios, selecciona el medio de comunicación que prefieras:
            </p>
          </div>
        </div>
        <div className="col-10 offset-1 rectangle mb-5 pl-0 pr-0" style={{borderBottom: '4px solid #323B4E'}}>
          <div className="mt-5 mb-5">
            <div className="row text-center">
              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                <img src={faQuestion} className="mb-2" alt="LogoPreguntas" style={{filter:'contrast(0.5)'}}/>
                <p className="mt-3" style={{  color: "#58595B", fontFamily:"Next", fontSize:"16px" }}>
                  Resuelve tus dudas
                </p>
                <Link to="/preguntas-frecuentes/resultado-#Dudas-sobre-el-registro"
                  style={{ color: "#58595B", fontSize:"15px", fontFamily:"Helvetica"}}
                >
                  Visita nuestras preguntas frecuentes
                </Link>
              </div>
              <div className="bottomDudas mb-4" style={{display:'none'}}></div>

              <div className="col-lg-4 col-md-12 col-sm-12 col-12 black borderLeftC">
                <img src={faPhone} className="mb-3 mt-3-mb " alt="LogoLlamadas"/>
                <p className="mt-2" style={{ fontFamily: "Next", color: "#58595B" , fontSize:"16px"}}>
                  Llama a nuestras líneas de atención
                </p>
                <p className="grayColor">
                  <span  className="grayColor d-sm-none d-md-none d-lg-block d-none" style={{fontSize:"15px"}}>
                    Bogotá: (601)743 1460.  Nacional:  01 8000 930 131
                  </span>
                  <span  className="grayColor d-lg-none d-md-block d-block" style={{fontSize:"16px"}}>
                    Bogotá: (601)743 1460<br/>  Nacional: 01 8000 930 131
                  </span>
                </p>
              </div>
              <div className="bottomDudas mb-4" style={{display:'none'}}></div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 black borderLeftC">
              <img src={faComment} className="mb-2" alt="LogoLlamadas"/>
                <p className=" mt-3" style={{  color: "#58595B", fontFamily:"Next",fontSize:"16px"}}>
                  Escríbenos un correo electrónico
                </p>
                <div>
                <p style={{marginTop: '22px'}}>
                  <a
                    href="mailto:comunicaciones@segurotesirve.com"
                    className="grayColor mailContactHelp"
                    style={{ color: "#58595B", fontFamily: "Helvetica", fontSize:'15px'}}
                  >
                    comunicaciones@vivesegura.com
                  </a>
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
        <div className="fondoGris d-none d-lg-block"></div>
      </div>
    </div>
  );
}
