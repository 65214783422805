import React,{useState} from 'react';
import Modal from "react-bootstrap/Modal";

export default function ModalPreInscripcion(props){
  const { showM, email, clickClose} = props;
    const [show, setShow] = useState(showM);
    const handleClose = () => setShow(false);
    
    return( 
    <>
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      className="contendeorModalBeneficiario"
    >
        <Modal.Header>
            <div >
              <button
              id="btnCierraModal"
              className="swal2-close-modal"
              onClick={handleClose}
              style={{marginTop: "34px"}}
              ><p>×</p></button>
          </div>
        </Modal.Header>
      <Modal.Body>
          <div className="container">
            <div className="row"> 
                <div className="p-2">
                    <div className="text-center">
                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-8 text-center">
                            <h4 className=" pt-3 pb-2" style={{fontSize: "22px", fontFamily: "Inter-Bold", color: "#323B4E" }}>
                              Beneficiario pendiente de pre inscripción 
                            </h4>
                          </div>
                          <div className="col-2"></div>
                        </div>
                        <p className="mt-4 ml-4 mr-4 mb-3" style={{fontSize:"14px", color:"#58595B", fontFamily:"Scotia-regular"}}>
                        Para finalizar el proceso de pre inscripción de menores de
                        edad, se debe diligenciar la autorización necesaria.  
                        <br/><br/>
                        Una vez finalice este proceso, le notificaremos vía correo 
                        electrónico a {email}
                        <br/>la autorización de ingreso.
                        </p>
                        <button className="btnMain mb-4" style={{borderRadius:"4px", width: "202px",backgroundColor: '#323B4E'}}  onClick={clickClose}>Aceptar</button>
                    </div>
                </div>
            </div>
          </div>
      </Modal.Body>
    </Modal>
  </>
    );
}