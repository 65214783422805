import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import borrar from "../../assets/img/icon/standard/delete.svg";
import flechaAbajo from "../../assets/img/scotia-vida-img-025.svg";
import { validateEmail } from '../../utils/validations';
import { isEmpty } from "lodash";
import ModalAutoData from "../modal/ModalAutoData";
import ModalEditBene from '../modal/ModalEditBene';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import ModalAlerts from "../modal/ModalAlerts";
import ModalConsentTele from "../modal/ModalConsentTele";
registerLocale("es", es);
setDefaultLocale("es");

export default function FormBeneficiary(props) {
    const { value, onChange, documentTypes, kinship, country, clickClose, clickSave, handleDataPicker, clickDelete, servicesB } = props;
    const validacion = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
    const validacionDoc = /[0-9]/;
    const validacionFecha = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/;
    const [stateButton, setStateButton] = useState(true);
    const [stateType, setStateType] = useState(false);
    const [bacground, setBackground] = useState("#D8D8D8");
    const [display, setDisplay] = useState("none");
    let date = new Date();
    const range = (start, end, step) => Array.from({ length: (start - end) / step + 1 }, (_, i) => end + (i * step));
    const getYear2 = (date) => {
        return new Date(date).getFullYear();
    }
    const getMonth = (date) => {
        return new Date(date).getMonth();
    }
    const years = range(getYear2(new Date()), getYear2(new Date()) - 50, 1);
    const months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];
    const selectPa = [
        { id: 30048, text: 'Padres', habilitarOtro: false },
        { id: 30049, text: 'Tutor', habilitarOtro: false },
        { id: 30051, text: 'Amigo/a', habilitarOtro: false },
        { id: 30053, text: 'Otro', habilitarOtro: false },

    ]
    const onChangetipe = (e) => {
        //console.log(e);
        if (e >= 0 || e === 8) {
            setStateType(true)
        }else if (e.target.value === '8' || e == 8) {
            setStateType(true)
        }else{
            setStateType(false)
        }
    }
    const setOfServices = new Set(servicesB);
    const uniqueServices = [...setOfServices];

    useEffect(() => {
        

        if (value.otro !== '' && value.dicParentesco.toString() === '29161') {
            setDisplay("block");
        }
        handleValidator();
    });
    useEffect(()=>{
        onChangetipe(props.value.dicTipoIdentificacionBen);
    },[])
    const [validatePr, setValidatePr] = useState({
        nombreError: '',
        apellidoError: '',
        tipoError: '',
        identificacionError: '',
        fechaError: '',
        paisError: '',
        correoError: '',
        parentescoError: '',
        otroError: '',
        terminosError: '',
        servicioError: '',
    });
    const validarNombre = () => {
        let nombreError = '';
        if (!value.nombres) {
            nombreError = 'El campo Nombres es obligatorio';
        }
        if (!validacion.test(value.nombres) && !isEmpty(value.nombres)) {
            nombreError = "Nombre no valido";
        }
        setValidatePr({ ...validatePr, nombreError });
        handleValidator();
    }

    const validarApellidos = () => {
        let apellidoError = '';
        if (!value.apellidos) {
            apellidoError = 'El campo Apellidos es obligatorio';
        }
        if (!validacion.test(value.apellidos) && !isEmpty(value.apellidos)) {
            apellidoError = "Apellido no valido";
        }
        setValidatePr({ ...validatePr, apellidoError });
        handleValidator();
    }

    const validarTipo = () => {
        let tipoError = '';
        let fechaError = '';
        value.dicTipoIdentificacionBen = parseInt(value.dicTipoIdentificacionBen);
        if (!value.dicTipoIdentificacionBen) {

            tipoError = 'El campo tipo de documento es obligatorio';
        }
        if (value.dicTipoIdentificacionBen <= 0) {
            tipoError = "Tipo de documento no valido";
        }
        if (value.dicTipoIdentificacionBen.toString() === "5") {
            var comparacion2 = new Date().getFullYear() - new Date(value.fechaDeNacimiento).getFullYear();
            if (comparacion2 < 18) {
                fechaError = "Fecha de nacimiento no es acorde a su edad.";
            }
        }
        if (value.dicTipoIdentificacionBen.toString() === "8") {
            var comparacion2 = new Date().getFullYear() - new Date(value.fechaDeNacimiento).getFullYear();
            if (comparacion2 > 17) {
                fechaError = "Fecha de nacimiento no es acorde a su edad.";
            }
        }
        setValidatePr({ ...validatePr, tipoError });
        setValidatePr({ ...validatePr, fechaError });
        handleValidator();
    }

    const validarIdentificacion = () => {
        let identificacionError = '';
        if (!value.identificacion) {
            identificacionError = 'El campo documento es obligatorio';
        }
        if (!validacionDoc.test(value.identificacion) && !isEmpty(value.identificacion)) {
            identificacionError = "Documento no valido";
        }
        ////console.log(value.identificacion);
        let val = value.identificacion.length;
        if (val >= 12) {
            value.identificacion = value.identificacion.slice(0, 12)
        }
        setValidatePr({ ...validatePr, identificacionError });
        handleValidator();
    }


    const validarFecha = (e) => {
        let fechaError = '';
        handleDataPicker(e);
        if (value.fechaDeNacimiento === null) {
            fechaError = 'El campo fecha de nacimiento es obligatorio.';
        }
        if (!validacionFecha.test(value.fechaDeNacimiento) && !isEmpty(value.fechaDeNacimiento)) {
            fechaError = "Fecha de nacimiento no valido.";
        }
        if (value.fechaDeNacimiento > date) {
            fechaError = "Fecha de nacimiento no valido.";
        }
        if (value.dicTipoIdentificacionBen.toString() === "5") {
            var comparacion = new Date().getFullYear() - new Date(e).getFullYear();
            if (comparacion < 18) {
                fechaError = "Fecha de nacimiento no es acorde a su edad.";
            }
        }
        if (value.dicTipoIdentificacionBen.toString() === "8") {
            var comparacion = new Date().getFullYear() - new Date(e).getFullYear();
            if (comparacion > 17) {
                fechaError = "Fecha de nacimiento no es acorde a su edad.";
            }

        }
        var fechaC = `${new Date(e).getDate()}/${new Date(e).getMonth()}/${new Date(e).getFullYear()}`;
        var fechaAct = `${new Date().getDate()}/${new Date().getMonth()}/${new Date().getFullYear()}`;
        if (fechaAct === fechaC) {
            fechaError = "Fecha de nacimiento no valido.";
        }

        setValidatePr({ ...validatePr, fechaError });
        handleValidator();
    }

    const validarPais = () => {
        let paisError = '';
        if (!value.dicPaisdeNacimiento) {
            paisError = 'El campo pais de nacimiento es obligatorio';
        }
        if (value.dicPaisdeNacimiento <= 0) {
            paisError = "Pais de nacimiento no valido";
        }
        setValidatePr({ ...validatePr, paisError });
        handleValidator();
    }

    const validarCorreo = () => {
        let correoError = '';
        if (!value.correoElectronico) {
            correoError = 'El campo correo electronico es obligatorio';
        }
        if (!validateEmail(value.correoElectronico) && !isEmpty(value.correoElectronico)) {
            correoError = "Correo electronico no valido";
        }
        setValidatePr({ ...validatePr, correoError });
        handleValidator();
    }

    const validarOtro = () => {
        let otroError = '';
        if (!value.otro) {
            otroError = "El campo de parentesco es obligatorio";
        }
        if (!validacion.test(value.otro) && !isEmpty(value.otro)) {
            otroError = "Parenteso no valido";
        }
        setValidatePr({ ...validatePr, otroError });
        handleValidator();
    }

    const validarParentesco = (e) => {
        let parentescoError = '';
        onChange(e);
        setDisplay("none");
        if (!value.dicParentesco) {
            parentescoError = 'El campo parentesco es obligatorio';
        }
        if (value.dicParentesco <= 0) {
            parentescoError = "Parentesco no valido";
        }
        if (value.dicParentesco.toString() === "29161" || value.dicParentesco.toString() === "30053") {
            setDisplay("block");
        }
        else {
            setDisplay("none");

        }
        setValidatePr({ ...validatePr, parentescoError });
        handleValidator();
    }
    const validarServicios = (e) => {
        onChange(e);
        let servicioError = '';
        if (!value.servicio) {
            servicioError = 'El servicio es obligatorio';
        }
        setValidatePr({ ...validatePr, servicioError });
    }
    const validarTerminos = (e) => {
        let terminosError = '';
        if (value.terminos === false) {
            terminosError = "Los terminos son obligatorios";
        }
        setValidatePr({ ...validatePr, terminosError });
        handleValidator();
    }


    const handleValidator = () => {
        if (value.nombres.trim() === "" || value.apellidos.trim() === "" || value.dicTipoIdentificacionBen.toString() === "0" || value.identificacion.trim() === "" || value.correoElectronico.trim() === "" || value.dicPaisdeNacimiento.toString() === "0" || value.dicParentesco.toString() === "0" || value.terminos === false || value.fechaDeNacimiento == null || validatePr.fechaError != "" || value.servicio == "") {
            setStateButton(true);
            setBackground("#D8D8D8");
        }
        else {
            if (value.dicParentesco.toString() === "29161" && value.otro.trim() === "") {
                setStateButton(false);
                setBackground("#EC1C24");
            } else {
                setStateButton(false);
                setBackground("#EC1C24");
            }
        }
    }


    return (
        <div className="row col-12 mt-2 usuarioNuevoDatos"
            style={{ margin: "0 auto" }}>
            <div className="col-12 mt-2">
                <img src={flechaAbajo} alt="flecha abajo" style={{ transform: "rotate(180deg)", float: "right", cursor: "pointer" }} onClick={clickClose} />
                <p className="textosBeneficiarios">
                Esta información es fundamental para incluir su beneficiario. Recuerde validar los datos antes de <br></br>
                confirmarlos. Todos los campos son obligatorios.
                </p>
            </div>
            <div className="col-12 col-md-6 col-lg-6 mt-2">
                <label className="labelPerfil">Nombres</label>
                <input type="text" className="form-control" name="nombres" value={value.nombres} onChange={(e) => { onChange(e) }} onKeyUp={() => { validarNombre() }} onClick={() => { validarNombre() }} />
                <small className="form-text text-danger">{validatePr.nombreError}</small>
            </div>
            <div className="col-12 col-md-6 col-lg-6 mt-2">
                <label className="labelPerfil">Apellidos</label>
                <input type="text" className="form-control" name="apellidos" value={value.apellidos} onChange={(e) => { onChange(e) }} onKeyUp={() => { validarApellidos() }} onClick={() => { validarApellidos() }} />
                <small className="form-text text-danger">{validatePr.apellidoError}</small>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-2 d-inline">
                <label style={{ whiteSpace: "nowrap" }} className="labelPerfil">Tipo de documento</label>
                <select
                    name="dicTipoIdentificacionBen"
                    className="form-control select-css3 estiloSelect"
                    id="dicTipoIdentificacionBen"
                    value={value.dicTipoIdentificacionBen}
                    onChange={(e) => { onChange(e) }}
                    onBlur={() => { validarTipo() }}
                    style={{paddingRight:"19%"}}
                >
                    <option value={0} >
                        Seleccionar
                    </option>
                    {documentTypes.map((document) => (
                        <option key={document.id} value={document.id}>
                            {document.text}
                        </option>
                    ))}
                </select>

                <small className="form-text text-danger" >{validatePr.tipoError}</small>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-2 estiloDocumento" >
                <label className="labelPerfil" style={{whiteSpace:"nowrap"}}>Número de documento</label>
                <input type="number" className="form-control" name="identificacion" value={value.identificacion} onChange={(e) => { onChange(e) }} onKeyUp={() => { validarIdentificacion() }} onClick={() => { validarIdentificacion() }} size="12" maxLength="12"/>
                <small className="form-text text-danger" >{validatePr.identificacionError}</small>
            </div>
            <div className="col-12 col-md-6 col-lg-6 mt-2">
                <label className="labelPerfil">Fecha de nacimiento</label>
                <div className="calendarioProfile">
                <DatePicker renderCustomHeader={({date,changeYear,changeMonth,})=>(
                    <div className="row justify-content-center m-2">
                    <select className="selectCalendar col-sm-5" style={{marginLeft: "15px",cursor:'pointer'}}
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }>
                      {months.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <select className="selectCalendar col-sm-5" style={{marginLeft: "15px",cursor:'pointer'}} value={getYear2(date)}
                      onChange={({ target: { value } }) => changeYear(value)}>
                      {years.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                    )}
                    locate="es"
                    className="form-control selecticon"
                    selected={value.fechaDeNacimiento}
                    onChange={(e) => { validarFecha(e) }}
                    showYearDropdown
                    ppreSelection={value.fechaDeNacimiento}
                    calendarClassName="backCalendar"
                    onChangeRaw={e=>{validarFecha(e)}}
                    dateFormatCalendar="MMMM"
                    dateFormat="dd/MM/yyyy"
                    yearDropdownItemNumber={30}
                    maxDate={(new Date())}
                    scrollableYearDropdown
                />
                </div>
                <small className="form-text text-danger" >{validatePr.fechaError}</small>
            </div>
            <div className="col-12 col-md-6 col-lg-6 mt-2">
                <label className="labelPerfil">País de nacimiento</label>
                <select
                    type="text"
                    className="form-control selecticon"
                    name="dicPaisdeNacimiento"
                    id="dicPaisdeNacimiento"
                    value={value.dicPaisdeNacimiento}
                    onChange={(e) => { onChange(e) }}
                    onBlur={() => { validarPais() }}
                > <option value={0}>
                        Seleccione un País de nacimiento
                    </option>
                    {country.map((country) => (
                        <option key={country.id} value={country.id}>
                            {country.text}
                        </option>
                    ))}
                </select>
                <small className="form-text text-danger" >{validatePr.paisError}</small>
            </div>
            <div className="col-12 col-md-6 col-lg-6 mt-2">
                <label className="labelPerfil">Correo electrónico</label>
                <input type="email" className="form-control" name="correoElectronico" value={value.correoElectronico} onChange={(e) => { onChange(e) }} onKeyUp={() => { validarCorreo() }} onClick={() => { validarCorreo() }} />
                <small className="form-text text-danger">{validatePr.correoError}</small>
            </div>
            <div className="col-12 col-md-6 col-lg-6 mt-2">
                <label className="labelPerfil">¿Cuál es tu parentesco con el beneficiario?</label>
                <select
                    name="dicParentesco"
                    className="form-control selecticon"
                    id="dicParentesco"
                    onChange={(e) => { validarParentesco(e) }}
                    value={value.dicParentesco}
                    onBlur={(e) => { validarParentesco(e) }}
                    onClick={(e) => { validarParentesco(e) }}
                >
                    <option value={0}>
                        Seleccione un parentesco
                    </option>
                    {
                           value.dicTipoIdentificacionBen === 8?
                           kinship.map((kinship) => (
                            (kinship.id !== 30050 && kinship.id !== 30052) &&
                            <option key={kinship.id} value={kinship.id}>
                                {kinship.text}
                            </option>
                            ))
                            :
                            kinship.map((kinship) => (
                            <option key={kinship.id} value={kinship.id}>
                                {kinship.text}
                            </option>
                            ))
                    }
                </select>
                <small className="form-text text-danger">{validatePr.parentescoError}</small>
            </div>
            <div className="col-12 col-md-6 col-lg-6 mt-2" style={{ display: display }}>
                <label className="labelPerfil">¿Cuál?</label>
                <input type="text" className="form-control" name="otro" value={value.otro} onChange={(e) => { onChange(e) }} onKeyUp={() => { validarOtro() }} onClick={() => { validarOtro() }} />
                <small className="form-text text-danger">{validatePr.otroError}</small>
            </div>
            <div className="col-12 col-md-6 col-lg-6 mt-2" >
                <label className="labelPerfil">Servicios</label>
            <select
                    name="servicio"
                    className="form-control selecticon"
                    id="dicServicio"
                    onChange={(e) => { validarServicios(e) }}
                    value={value.servicio}
                    onBlur={(e) => { validarServicios(e) }}
                    onClick={(e) => { validarServicios(e) }}
                >
                    <option value="">
                        Seleccione un servicio
                    </option>
                    {uniqueServices.length > 0 &&(
                        uniqueServices.map((service,i) => (
                        <option key={i} value={service.value}>
                            {service.text === "Salud y Bienestar" ? "Instafit" : service.text} 
                        </option>
                    )))}
            </select>
            </div>
            
            <div className="row col-12">
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4 text-left">
                    <div className="checkbox-terminos" style={{ marginTop: "auto" }}>
                        <input type="checkbox" id="checkbox-terminos" name="terminos" checked={value.terminos} onChange={(e) => { onChange(e) }} onClick={(e) => { validarTerminos(e) }} />
                        <label for="checkbox-terminos"></label>
                        <span><ModalAutoData/>
                            {(value.dicTipoIdentificacionBen.toString() === "8" && value.servicio.includes("telemedicina") && (value.dicParentesco.toString() === "30048" || value.dicParentesco.toString() === "30049" )) &&(<ModalConsentTele title="consentimiento informado consulta telemedicina para menor de edad," info={value} style={{cursor:"pointer",color: "#006dbc",fontSize:"14px",textDecorationLine:"underline",paddingLeft:"28px",width: "266px"}}/>)}
                            <ModalEditBene/>
                        </span> 
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-right caja-agendamiento-m mt-4" id="caja-agendamiento-beneficiario">
                    <div className="row justify-content-between pt-2 btns-accion" >
                        <div className="col-lg-5 col-md-6 col-sm-6 d-inline">
                            <button  onClick={clickDelete}  className="btnMainEliminar pb-3" style={{ padding: "24px auto" }} 
                                //disabled={value.idBeneficiario !== '' ? true : false}
                            >
                                <img src={borrar} alt="rectangulo" className="mr-2"/>
                                Eliminar 
                            </button>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-6 d-inline pl-0">
                            <button className="btnBeneficiary" disabled={stateButton} 
                            style={{color: stateButton ?"#ffffff" : "#ffffff" , backgroundColor: stateButton ? "#CFCFCF" : "#323B4E" }} 
                            onClick={() => { clickSave() }}>Agregar beneficiario</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

