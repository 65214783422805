import React, { useEffect, useState } from 'react'
import { Card, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import ModalTermsVideoCallPhan from '../../modal/ModalPhantom/ModalTermsVideoCallPhan';
import { encryptData } from '../../../utils/validations';
import useIsMobile from '../../../hooks/useIsMobile'

export const ScheduleTeleNew = ({ module, infoSheduling }) => {
    const history = useHistory();
    const isMobile = useIsMobile();
    const [showModalTerms, setShowModalTerms] = useState(true);
    const [showModalTYC, setShowModalTYC] = useState(false);
    const handleClose = () => setShowModalTerms(false);
    const typeSheduling = infoSheduling;

    const dobleEnter = (
      <>
        <br />
        <br />
      </>
    );
    const enter = (
      <>
        <br />
      </>
    );
  
  
    const handleIframeMessage = (event) => {
      if (event.data === 'Open modal tyc') {
        setShowModalTYC(true)
      }
      if (event.data === 'cambio-de-url') {
        history.push("/auth/inicio");
      }
    };
  
    const [dataURL, setDataURL] = useState("");
    useEffect(() => {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true })
  
      var data = encryptData(infoSheduling)
      setDataURL(data)
      window.addEventListener('message', handleIframeMessage);
  
      return () => {
        window.removeEventListener('message', handleIframeMessage);
      };
    }, [])
  
    return (
      <React.Fragment>
        {
          showModalTYC && (
            <ModalTermsVideoCallPhan />
          )
        }
        <Modal show={showModalTerms} onHide={handleClose} size="lg" centered>
            <Modal.Header>
            <div style={{ height: "40px" }}>
                <button
                id="btnCierraModal"
                type="button"
                className="swal2-close-modal btnTerms"
                onClick={handleClose}
                ><p>×</p></button>
            </div>
            </Modal.Header>
            <Modal.Body>
            <div className={`body-modal-terms ${isMobile ? 'none_padding':'active_padding'}`} >

                <React.Fragment>
                <h4 className="tittle-modal-t" >CONSENTIMIENTO INFORMADO</h4>
                <p className="overflow-scroll  text-justify">
                    Declaro en pleno uso de mis facultades mentales que he recibido una explicación y descripción clara sobre el servicio de Consulta bajo la modalidad de Consulta Medicina General que voy a recibir, mediante la cual un Profesional de la Salud, haciendo uso de una herramienta de videollamada, hará una valoración de mi estado de salud, con el fin de obtener un concepto médico y recomendar el curso de acción que considere necesarios para la atención solicitada.
                    {dobleEnter}
                    Adicionalmente entiendo que este servicio no tiene como objeto la atención de casos de urgencias o emergencias vitales en las cuales pueda estar en riesgo la vida del paciente y entiendo claramente que en estos casos debo dirigirme a un centro de atención de urgencias médicas de forma inmediata.
                    {dobleEnter}
                    Usted podrá ser evaluado (a) y tratado (a) por un profesional médico desde un lugar remoto; como cualquier procedimiento médico, se presentan beneficios y riesgos potenciales, a saber:
                    {dobleEnter}
                    <b>Beneficios</b>{enter}
                    Entiendo que como usuario de nuestro servicio de consulta Medicina General cuento con los siguientes derechos:
                    {dobleEnter}
                    - Valoración con el médico para iniciar tratamiento sin asistir a la sede. {enter}
                    - Mejores resultados y tratamientos menos costosos, dada la inmediatez del servicio. {enter}
                    - Alternativa para frenar la propagación de virus altamente transmisibles. {enter}
                    - Disminución de costos y riesgos de desplazamiento. {enter}
                    - Obtener su formulación médica, solicitud de exámenes y certificados médicos en formato digital con validez para uso inmediato. {enter}
                    - Seguimiento continuo de su condición de salud desde la comodidad de su casa.
                    {dobleEnter}
                    <b>Riesgos</b>{enter}
                    - La información puede no ser suficiente para para dar lugar a una consulta concluyente por parte del médico. {enter}
                    - La evaluación y el tratamiento médicos podrían demorarse debido a deficiencias o fallas de los equipos. {enter}
                    - Necesidad de valoración presencial por parte del médico.
                    {dobleEnter}
                    <b>Derechos y deberes del paciente</b>
                    {dobleEnter}
                    Entiendo que como usuario de nuestro servicio de Consulta Medicina General cuento con los siguientes derechos:
                    {dobleEnter}
                    - A recibir información sobre mi salud, trámites administrativos y demás requisitos para la prestación del servicio por medio de Consulta Medicina General. {enter}
                    - A recibir un trato humanizado, cordial, respetuoso y privado. {enter}
                    - A exigir respeto por mis creencias, religión, costumbres, hábitos e identidad sexual. {enter}
                    - A presentar quejas, reclamos, sugerencias y felicitaciones de los servicios prestados. {enter}
                    - A que mis datos sean tratados con reserva y confidencialidad.
                    {dobleEnter}
                    Adicionalmente, acepto y entiendo que tengo los siguientes deberes irrevocables para el desarrollo de los servicios por medio de Consulta Medicina General:
                    {dobleEnter}
                    - Brindar información veraz y completa a los Profesionales de la Salud para el desarrollo de mi atención. {enter}
                    - Cumplir con las políticas y reglas del prestador de los servicios de salud. {enter}
                    - En caso de asistencia para menores de edad, contar con la presencia de uno de sus padres o tutor.
                    {dobleEnter}
                    <b>Al aceptar este consentimiento, entiendo lo siguiente:  </b>
                    {dobleEnter}
                    1. Entiendo que puedo esperar los beneficios del uso de Consulta Medicina General en mi atención médica, pero que los resultados, como en la atención medica habitual, no pueden ser garantizados, pues la labor médica es de medios y no de resultados. {dobleEnter}
                    2. Entiendo que La medicina no es una ciencia exacta y puede tener diversos resultados de acuerdo o conforme al tratamiento al que será sometido el paciente. No se pueden garantizar los resultados y puede haber errores en el diagnóstico y el diagnóstico puede verse correlacionado con otros resultados de acuerdo con el tratamiento realizado.  {dobleEnter}
                    3. Entiendo que no todos los motivos de consulta pueden ser resueltos a distancia por consulta Medicina General y es el médico quien decidirá si el motivo de consulta puede ser atendido a distancia, pudiendo definir de ser necesario y de acuerdo con la evolución del paciente, que la atención en la modalidad de consulta Medicina General no es suficiente para emitir recomendaciones con certeza e indicar una atención presencial, domiciliaria o de urgencias. {dobleEnter}
                    4. Entiendo que tengo la opción de rechazar o retirar mi consentimiento para el uso de la consulta Medicina General en cualquier momento sin afectar mi derecho a recibir atención o tratamiento en un futuro. {dobleEnter}
                    5. Entiendo que tengo derecho a inspeccionar y/o examinar toda la información obtenida y registrada en el curso de una interacción de Consulta Medicina General y puedo recibir copias de esta información. {dobleEnter}
                    6. Entiendo que los métodos alternativos de atención médica de salud pueden estar disponibles incluida la interacción presencial y que puedo elegir otra alternativa en cualquier momento. {dobleEnter}
                    7. Comprendo que las normas que protegen la Reserva, confidencialidad, custodia, seguridad y tratamiento de mis datos personales e información de salud, aplican a la consulta Medicina General y que ninguna información, obtenida en el uso de la consulta Medicina General, la cual me identifica, será revelada a terceros no autorizados por las normas sin mi autorización. {dobleEnter}
                    8. Comprendo y declaro que se me ha informado que, para efectos de brindarme atención médica, autorizo expresamente a la IPS GRUPO MOK para recolectar, clasificar, almacenar, utilizar, archivar y cualquier otra modalidad de tratamiento a mis Datos Personales, incluyendo mis Datos Sensibles, conforme a las finalidades establecidas en la Política de Tratamiento de Datos Personales de GRUPO MOK anexo al presente consentimiento, la cual puede ser modificada por la IPS sin previo aviso; en caso que el paciente sea menor de 18 años y/o incapaz, su representante legal autoriza expresamente a la IPS GRUPO MOK para recolectar, clasificar, almacenar, utilizar, archivar y cualquier otra modalidad de tratamiento a sus Datos Personales, incluyendo sus Datos Sensibles conforme a las finalidades establecidas en la Política de Tratamiento de Datos Personales de GRUPO MOK anexo al presente consentimiento, la cual puede ser modificada por la IPS sin previo aviso. {dobleEnter}
                    9. Entiendo, acepto y autorizo, como titular de mis datos biométricos y en particular de mi imagen, a la IPS GRUPO MOK al tratamiento de las imágenes que me sean tomadas en el desarrollo del objeto de la prestación del servicio de consulta Medicina General. {dobleEnter}
                    10. Entiendo, acepto y autorizo a la IPS GRUPO MOK para transferir y/o transmitir mis datos personales con la finalidad específica de cumplir con la prestación del servicio de consulta Medicina General. {dobleEnter}
                    11. Entiendo y acepto que la IPS GRUPO MOK no grabará la consulta, quedando como único registro de la misma la Historia Clínica del paciente, la cual será resguardada con los niveles de seguridad exigidos por la Constitución Política y las leyes sobre esa naturaleza.  {dobleEnter}
                    12. Fui informado de que, en caso de querer actualizar, rectificar o suprimir los datos que he suministrado, puedo enviar un correo electrónico a la dirección protecciondedatos@grupomok.com, donde se le dará trámite a mi solicitud de conformidad con lo establecido en la Ley 1581 de 2012 y demás normas concordantes.
                    {dobleEnter}
                    <b>En caso de que el paciente sea menor de 18 años y/o incapaz, este consentimiento será aceptado por su representante legal </b>
                    {dobleEnter}
                    He comprendido todo lo anterior, y por ende otorgo mi Consentimiento en nombre del paciente menor de edad y/o incapaz, para que se le preste el servicio de Consulta Externa bajo la modalidad de Consulta Medicina General.
                    {dobleEnter}
                    Si después de leer detenidamente esta información, está totalmente de acuerdo con lo aquí comunicado, por favor proceda a ingresar en el siguiente link para dar inicio al servicio de Consulta Medicina General.
                </p>
                </React.Fragment>

            </div>
            <div className="text-center">
                <button
                type="button"
                onClick={handleClose}
                className="btnModal pl-5 pr-5"
                >
                Aceptar
                </button>
            </div>
            </Modal.Body>
        </Modal>
        <div className={typeSheduling.paddingSheduling}>
          <div className="container">
            <div className="row w-100 p-0 m-auto">
                <Card className="col-sm-12 col-lg-12 col-md-12 quitarBorde" id="cardContentSheduling" style={{ marginBottom: "28px", height: module.includes('Pediatra') ? "1000px" : "100vh", border: 'none'}}>
                  <iframe
                    src={`${process.env.REACT_APP_URL_PHANTOM}/${module.includes('Pediatra') ? 'pediatria' : 'telemedicina'}/${dataURL}`}
                    width="100%"
                    height="100%"
                    className="Container__Iframe"
                    style={{ border: 'none' }}
                    allow="camera *;microphone *"
                    allowusermedia
                  />
                </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
