import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContainerService from "../../../components/container-service/ContainerService";
import ModalAlerts from "../../../components/modal/ModalAlerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import { urlAPI } from "../../../utils/config";
import { getUser, getToken } from "../../../utils/validations";

export default function FinanzasPersonales() {

    const linkbread = [{
        to: "/auth/inicio",
        tittle: ""
    },
    {
        to: "/auth/inicio",
        tittle: "",
    },
    {
        to: "/auth/inicio",
        tittle: ""
    }];

    return (
        <React.Fragment>
            <ContainerService tittle="Tus finanzas personales" styleTittle={{ fontSize: "34px", lineHeight: "56px", fontFamily: 'DTL-ArgoT-regular' }} linkBread={linkbread}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12" style={{ padding: '30px 0px 30px 20px', fontFamily: 'Inter-regular' }}>
                            <div className="pb-4">
                                <h3 style={{ fontWeight: 'bold', fontSize: '28px', color: '#60666B', fontFamily: 'DTL Argo T' }}>Aprende a administrar tus finanzas </h3>
                                <p style={{ fontSize: "16px", fontFamily: 'OpenSans',marginTop: "30px" }}>Aprende a administrar tus finanzas de una forma equilibrada. Por medio de módulos de aprendizaje personalizados a tu medida.
                                      Aquí puedes elegir tus cursos personalizados para administrar tu vida financiera de manera inteligente.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ContainerService>
        </React.Fragment>
    );
}

