import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContainerService from "../../../components/container-service/ContainerService";
import ModalAlerts from "../../../components/modal/ModalAlerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import { urlAPI } from "../../../utils/config";
import { getUser, getToken } from "../../../utils/validations";
import { map, set } from "lodash";
import { Fragment } from "react";

export default function SaludFinanciera() {

    const linkbread = [{
        to: "/auth/inicio",
        tittle: ""
    },
    {
        to: "/auth/inicio",
        tittle: "",
    },
    {
        to: "/auth/inicio",
        tittle: ""
    }];

    // function Datatitle(){
    //     return{
    //         nombreCard:'',
    //         descripCard:'',
    //     }

        
    // }

    function ButtonNotifications(props) {
        const { text, step } = props;
        const styles = {
            width: '260px',
            height: '50px',
            borderRadius: '25px',
            color: '#FFFFFF',
            border: 'none'
        };
        return (
            <button className='text-center imback2' style={{ ...styles, background: '#034797', fontFamily: 'OpenSans', fontWeight: '400', fontSize: '16px', lineHeight: '30px', color: '#FFFFFF' }} onClick={step}>
                {text}
            </button>
        )
    }
    function ButtonCard(props) {
        const { text, step } = props;
        const styles = {
            width: '232px',
            height: '44px',
            borderRadius: '25px',
            color: '#FFFFFF',
            border: 'none',
            booton: '15px'
        };

        return (
            <button  type="submit" style={{ ...styles, background: '#034797', fontFamily: 'OpenSans', fontWeight: '400', fontSize: '16px', lineHeight: '30px', color: '#FFFFFF' }} onClick={step}>
                {text}
            </button>
        )

    }
    const [textButton, setTextButton] = useState("Comenzar");

    const [estado, setestado] = useState(1);

    const [cards,setcard]= useState([]);
    const [count,setCount] = useState(0);

    useEffect(()=>{
        Axios.get(`${urlAPI}/saludfin/obtenerIndicadores/${parseInt(getUser().idUsuario)}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            //console.log(succ+"esto es suc");
            //console.log(succ.data.data);
            let arrayAux=[]

            //console.log(arrayAux);
            succ.data.data.forEach((e) => {
                var json=
                {
                    nombreCard:e.nombreSaludFinancieraTipo,
                    descripCard:e.descripcion
                }
                arrayAux.push(json)
            });
            //console.log(arrayAux);
            setcard([... new set(arrayAux)])

        })
    },[count])
    const getSaludFinanciera = () => {
        //console.log("Ingreso");
        Axios.get(`${urlAPI}/saludfin/obtenerIndicadores/${parseInt(getUser().idUsuario)}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            //console.log(succ+"esto es suc");
            //console.log(succ.data.data);
            let arrayAux=[]

            //console.log(arrayAux);
            succ.data.data.forEach((e) => {
                var json=
                {
                    nombreCard:e.nombreSaludFinancieraTipo,
                    descripCard:e.descripcion
                }
                arrayAux.push(json)
            });
            //console.log(arrayAux);
            setcard([... new set(arrayAux)])

        })

    }



    return (
        <React.Fragment>
            <ContainerService tittle="Mide tu salud financiera " styleTittle={{ fontSize: "34px", lineHeight: "56px", fontFamily: 'DTL-ArgoT-regular', marginTop: '7%', marginLeft: '18%' }} linkBread={linkbread}>
                {estado === 1 && (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12" style={{ padding: '30px 0px 30px 0px' }}>
                                <div className="pb-4">

                                    <p className="mt-2" style={{ fontFamily: 'OpenSans', fontWeight: '400', fontSize: '16px', lineHeight: '28px', color: '#58595B', width: '106%' }}>
                                        Con esta prueba de salud financiera, podrás identificar comportamientos que afectan tus finanzas y aprenderás a corregirlos. una vez identifiques los aspectos que debes mejorar, conocerás que medidas correctivas implementar

                                    </p>

                                    <p className="mt-2" style={{ fontFamily: 'OpenSans', fontWeight: '400', fontSize: '16px', lineHeight: '28px', color: '#58595B', marginTop: '4%' }}>
                                        La información suministrada será manejada de forma confidencial y su uso está limitado exclusivamente a fines estadísticos.

                                    </p>
                                    <div className="text-center mt-3 ">
                                        <ButtonNotifications text={textButton} step={() => setestado(2)} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {estado === 2 && (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12" style={{ padding: '30px 0px 30px 0px' }}>
                                <div className="pb-4">
                                    <p style={{ fontFamily: 'DTL-ArgoT-regular', fontWeight: '700', lineHeight: '56px', color: '#60666B', fontSize: "26px" }}>Evalua tu salud financiera </p>

                                    <p className="mt-2" style={{ fontFamily: 'OpenSans', fontWeight: '400', fontSize: '16px', lineHeight: '28px', color: '#58595B', width: '106%' }}>
                                        El Score mide la salud financiera en 10 dimensiones, usando puntajes porcentuales en el cual el mínimo es 0% y el máximo 100%. Se utilizan 4 colores para ilustrar el nivel de desempeño en cada dimensión.

                                    </p>

                                    <p className="mt-3" style={{ fontFamily: 'OpenSans', fontWeight: '400', fontSize: '16px', lineHeight: '28px', color: '#58595B', marginTop: '4%' }}>
                                        Selecciona el area que más te interese, reponde las preguntas y obten tu puntaje correspondiente. Para obetner tu score total debes completar todas las areas de tu salud financiera.

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row mr-3">
                            <div class="col-3 text-center " >
                                <div>
                                    <div style={{ display: 'inline-table', borderRadius: '25px', backgroundColor: '#6DC032', height: '13px', width: '29px', marginRight: '10px', marginTop: '-50px' }} >

                                    </div>
                                    <strong style={{ fontFamily: 'Scotia-bold', fontSize: '15px', lineHeight: '20px', color: '#5E5E5E' }} >Bueno:</strong> <span style={{ fontFamily: 'Scotia', fontSize: '15px', lineHeight: '20px', color: '#5E5E5E' }} >100 a 76% </span>
                                </div>
                            </div>
                            <div class="col-3 text-center " >
                                <div>
                                    <div style={{ display: 'inline-table', borderRadius: '25px', backgroundColor: '#57A3E4', height: '13px', width: '29px', marginRight: '10px', marginTop: '-50px' }} >

                                    </div>
                                    <strong style={{ fontFamily: 'Scotia-bold', fontSize: '15px', lineHeight: '20px', color: '#5E5E5E' }} >Regular:</strong> <span style={{ fontFamily: 'Scotia', fontSize: '15px', lineHeight: '20px', color: '#5E5E5E' }} >75 a 51% </span>
                                </div>
                            </div>
                            <div class="col-3 text-center " >
                                <div>
                                    <div style={{ display: 'inline-table', borderRadius: '25px', backgroundColor: '#F1D033', height: '13px', width: '29px', marginRight: '10px', marginTop: '-50px' }} >

                                    </div>
                                    <strong style={{ fontFamily: 'Scotia-bold', fontSize: '15px', lineHeight: '20px', color: '#5E5E5E' }} >Deficiente:</strong> <span style={{ fontFamily: 'Scotia', fontSize: '15px', lineHeight: '20px', color: '#5E5E5E' }} >50 a 21% </span>
                                </div>
                            </div>
                            <div class="col-3 text-center " >
                                <div>
                                    <div style={{ display: 'inline-table', borderRadius: '25px', backgroundColor: '#D24B4B', height: '13px', width: '29px', marginRight: '10px', marginTop: '-50px' }} >

                                    </div>
                                    <strong style={{ fontFamily: 'Scotia-bold', fontSize: '15px', lineHeight: '20px', color: '#5E5E5E' }} >Crítico:</strong> <span style={{ fontFamily: 'Scotia', fontSize: '15px', lineHeight: '20px', color: '#5E5E5E' }} >20 a 0% </span>
                                </div>
                            </div>
                        </div>
                        <div>

                        <button  onClick={() => getSaludFinanciera()} type="submit"> hola este es el boton </button>
                        </div>

                            {cards.map((e)=>{
                                return(
                                    <Fragment>
                                        <div className="pb-5 mt-2 col-xl-4 col-lg-6  col-md-12" style={{ display: 'inline-grid' }}>
                                        <div className="col-12 mb-4">
                                <div className="card redondeado ml-1 mr-1 col-12" style={{ backgroundColor: '#FBFBFB',minHeight: '330px' }}>

                                    <div className="card-body pb-5">
                                        <h5 className="card-title" >{e.nombreCard}</h5>
                                        <p className="card-text" > {e.descripCard}</p>

                                    </div>
                                    <div>

                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <div >
                                            <ButtonCard  text={textButton}  />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                                    </Fragment>
                                )
                                
                            })}
                    </div>

                )}


            </ContainerService>
        </React.Fragment>
    );
}

