import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import cerrarModal from "../../assets/img/cardif-boton-cerrar.svg";

export default function ModalTerms(props) {
  const { title } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function cerrarCookies() {
    document.getElementById("contenedorCookies").style.display = "none";
    // document.getElementById("contenedorSugerencias").style.display = "none";
    handleClose()
  }
  const dobleEnter = (
    <>
      <br />
      <br />
    </>
  );

  return (
    <>
      <span style={{cursor:"pointer"}} onClick={handleShow} className="hover mr-3 text-center">
        {title}
      </span>

      <Modal
        show={show}
        onHide={handleClose} 
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header>
        <div style={{ height: "40px" }}>
          <button
            id="btnCierraModal"
            className="swal2-close-modal"
            onClick={handleClose}
            style={{marginTop:"56px", marginRight:"12px"}}
          ><p>×</p></button>
        </div>
        </Modal.Header>
        <Modal.Body>
        <div className="overflow-scroll body-modal-t">
        <h4 className="tittle-modal-t" style={{fontSize: '20px'}}>
          Política de privacidad y manejo de cookies
          <br/>
          <br/>
        </h4>
        <p className="text-justify cookiesText">
            Como la mayoría de los sitios web, usamos cookies propias y de terceros para ofrecer una experiencia personalizada. 
            {dobleEnter}
            Usted puede controlar la utilización de las cookies en cualquier momento, desde el menú de ajustes de su navegador o dispositivo. 
            {dobleEnter}
            Podemos utilizar cookies que: 
            {dobleEnter}
            · Sean necesarias para el correcto funcionamiento del sitio web
            <br></br>
            · Recuerden sus preferencias
            <br></br>
            · Recopilen información acerca de cómo utilizar nuestro sitio web
            <br></br>
            · Ofrezcan contenidos y publicidad más relevantes. 
            {dobleEnter}
            Lea nuestra política de utilización de cookies en vivesegura.com 
            {dobleEnter}
            Adicionalmente, podemos compartir los análisis de navegación y los grupos de interés inferidos con terceros. Al hacer click en "Continuar" o si continúa navegando, acepta su uso. Para conocer más sobre los derechos que le asisten, el derecho a presentar a solicitudes de información, actualización, supresión y/o rectificación las formas para ejercerlos y las finalidades y tipos de tratamiento a los que sus datos personales serán sometidos, lo invitamos a consultar la Política de Tratamiento de la Información, disponible en www.bnpparibascardif.com.co enlace “Privacidad” 
            Cualquier consulta, solicitud, reclamo, queja y petición relacionada con sus datos personales, podrá dirigirla a atencionalcliente@cardif.com.co.”
            {dobleEnter}
            ___ Marque la casilla para aceptar las cookies publicitarias y de contenidos, incluso de terceros.
            {dobleEnter}
            <strong>
            Política de manejo de cookies
            </strong>
            {dobleEnter}
            <strong>
            I. ¿QUÉ SON LAS COOKIES?
            </strong>
            {dobleEnter}
            Las cookies son pequeños archivos de información, ficheros, cifras o fragmentos de texto enviados por un sitio web o aplicación tecnológica de propiedad de CARDIF COLOMBIA SEGUROS GENERALES S.A. 
            y que se almacenan en el navegador o dispositivo del usuario. A través de las cookies, los sitios web recuerdan información sobre la visita de los usuarios, lo que permite que se proporcione una mejor y más segura experiencia de navegación en el mismo. Las cookies se asocian tanto a usuarios anónimos, es decir, 
            aquellos que visitan el sitio web sin identificarse o registrarse, como aquellos que sí lo hacen.
            {dobleEnter}
            vivesegura.com utiliza “cookies” propias y de terceros para mejorar nuestro servicio, monitorear información estadística y presentarte contenidos y publicidad relacionados con tus preferencias.
            {dobleEnter}
            Las cookies no son un virus o cualquier otro tipo de programa malicioso que pueda dañar dispositivos de los usuarios. Además, las cookies no pueden borrar ni leer información del computador o dispositivo de los usuarios.
            {dobleEnter}
            Las "cookies" no nos pueden suministrar tu correo electrónico ni otra información personal de identificación a menos que nos los brindes voluntariamente por ejemplo al registrarte en uno de nuestros servicios. Nosotros usamos las "cookies" para realizar seguimiento y reunir información que permita mejoras de contenido y servicios, 
            para guardar tu contraseña en áreas protegidas y seguras.
            {dobleEnter}
            <strong>
            II. ¿CÓMO OBTIENE VIVESEGURA.COM LAS COOKIES?
            </strong>
            {dobleEnter}
            Las cookies se crean y/o actualizan en el computador o dispositivo del usuario de manera automática, cuando éste accede a un sitio web de vivesegura.com, lo cual permite a vivesegura.com o a las terceras personas que este contrate, realizar seguimiento a las cookies del usuario y por ende a la información que estas cookies contienen u obtienen del usuario. Es importante aclarar que las cookies solo son leídas por el sitio web que las creó.
            {dobleEnter}
            <strong>
            III.¿QUÉ CLASE DE COOKIES UTILIZA VIVESEGURA.COM Y PARA QUÉ LAS UTILIZA?
            </strong>
            {dobleEnter}
            <strong>1. Cookies Esenciales</strong>
            {dobleEnter}
            Estas cookies son esenciales para el uso del sitio web, en el sentido de facilitar el proceso de registro en el mismo, así como permitir a los usuarios un acceso más rápido y seguro a los servicios seleccionados dentro del sitio web. Sin estas cookies, es posible que vivesegura.com no les pueda ofrecer a los usuarios ciertos servicios dentro del sitio web y éste puede que no funcione con normalidad.
            {dobleEnter}
            <strong>2. Cookies Funcionales</strong>
            {dobleEnter}
            Mediante el uso de las cookies funcionales, es posible para vivesegura.com o el tercero que este contrate, personalizar los servicios que se ofrecen en el sitio web, facilitando a cada usuario información que es o puede ser de su interés, en atención al uso que realiza de los servicios y a las páginas específicas que visita dentro del sitio web. A través de estas cookies vivesegura.com o las terceras personas que contrate, podrán ofrecer a los usuarios una mejor experiencia y mejor rendimiento en la navegación dentro del sitio web.
            {dobleEnter}
            <strong>3. Cookies de Publicidad</strong>
            {dobleEnter}
            vivesegura.com o el tercero que este contrate, mediante el uso de estas cookies, podrá entregarle al usuario publicidad que considere que puede llegar a ser de su interés, según las preferencias y comportamientos que el usuario tiene o ha tenido dentro del sitio web de vivesegura.com.  A través de estas cookies, se podrá optimizar la publicidad que el usuario ve en el sitio web. Las cookies de publicidad podrán ser entregadas a anunciantes de vivesegura.com, para que sean utilizadas por estos para sus campañas de mercadeo y publicidad.
            {dobleEnter}
            <strong>4. Cookies de Análisis o Desempeño</strong>
            {dobleEnter}
            A través de las cookies de análisis o desempeño, vivesegura.com o o el tercero que éste contrate, puede realizar distintos análisis y estudios de la información recolectada, con el fin de mejorar los productos y servicios que vivesegura.com ofrece a los usuarios.
            vivesegura.com y los terceros con los que contrate servicios para el uso de cookies, son los únicos que podrán acceder a la información almacenada en las cookies que se han instalado dentro del equipo del usuario. La información almacenada en las cookies no puede ser leída por otros usuarios, ni estos podrán acceder a la misma.
            {dobleEnter}
            <strong>IV.¿PUEDO DESHABILITAR LA INSTALACIÓN Y USO DE COOKIES?</strong>
            El usuario puede deshabilitar tanto la instalación de las cookies como el uso de las mismas. Para deshabilitar la instalación y uso de cookies el usuario deberá realizar el procedimiento por navegador tal como se indica a continuación:
            <br></br><strong>· Internet Explorer:</strong> Herramientas -> Opciones de Internet -> Privacidad -> Configuración. Para más información, puede consultar el Soporte de Microsoft o la Ayuda del navegador.
            <br></br><strong>· Firefox:</strong> Herramientas -> Opciones -> Privacidad -> Historial -> Configuración Personalizada. Para más información, puede consultar el Soporte de Mozilla o la Ayuda del navegador.
            <br></br><strong>· Chrome:</strong> Configuración -> Mostrar opciones avanzadas -> Privacidad -> Configuración de contenido. Para más información, puede consultar el Soporte de Google o la Ayuda del navegador.
            <br></br><strong>. Safari:</strong> Preferencias -> Seguridad.
            Para más información, puede consultar el Soporte de Apple o la Ayuda del navegador.
            Si usted borra o desactiva las cookies, es posible que algunas funciones del presente sitio web no estén disponibles para su utilización o que su funcionamiento no sea óptimo.
            {dobleEnter}
            <strong>V. ¿VIVESEGURA.COM COMPARTE LA INFORMACIÓN OBTENIDA A TRAVÉS DE LAS COOKIES CON TERCERAS PERSONAS?</strong>
            {dobleEnter}
            vivesegura.com podrá compartir información obtenida a través de las cookies con personas externas o terceros (aliados, clientes, proveedores), con el fin de mejorar la usabilidad y servicios al usuario. Así mismo, la información que se recibe a través de las cookies será utilizada por vivesegura.com y los terceros mencionados, para los fines descritos en el presente documento y los indicados en las políticas de privacidad en www.bnpparibascardif.com.co enlace “Privacidad”.
            {dobleEnter}
            <strong>VI. ¿DÓNDE ESTÁ ALMACENADA LA INFORMACIÓN OBTENIDA A TRAVÉS DE LAS COOKIES?</strong>
            {dobleEnter}
            vivesegura.com podrá contratar terceras personas encargadas de almacenar y obtener la información a través de las cookies, o que incluyan cookies dentro del sitio web, personas que podrán estar localizadas dentro de Colombia o en el exterior. Así mismo, vivesegura.com podrá entregar a terceros, la información que se obtenga de las cookies para crear perfiles de usuarios, ofrecer campañas personalizadas, sin que lo anterior, implique entrega de datos personales.
            {dobleEnter}
            <strong>VII.¿QUÉ DEBO TENER EN CUENTA SOBRE LA POLÍTICA DE USO DE COOKIES Y DÓNDE ME PUEDO CONTACTAR EN CASO DE DUDAS?</strong>
            {dobleEnter}
            La presente política contiene la información necesaria que debe conocer todo usuario del sitio web sobre el uso de las cookies que realiza vivesegura.com o los terceros que este contrate. vivesegura.com podrá modificar el presente documento en cualquier momento y sin previo aviso para mantenerlos vigentes y actualizados. Por lo anterior, recomendamos a los usuarios revisar la fecha de elaboración o actualización de los mismos, la cual se establece al final del presente documento.
            {dobleEnter}
            En caso de dudas o inquietudes acerca del uso de cookies o sobre cualquiera de los puntos detallados en esta política, por favor escriba al siguiente correo electrónico: atencionalcliente@cardif.com.co.
        </p>
        </div>
        <div className="text-center" style={{margin:"12px"}}>
            <button
              type="button" 
              onClick={handleClose}
              className="btnModal pl-5 pr-5"
            >
              ACEPTAR
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

    