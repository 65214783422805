import React,{useEffect, useState} from 'react';
import ContainerService from "../../components/container-service/ContainerService";
import Sheduling from '../../components/sheduling/Sheduling';
import {Card,Tab,Tabs} from 'react-bootstrap';
import IconPhone from '../../assets/img/ayuda/ico-linea-de-atencion-inact.svg';
import IconCalendar from '../../assets/img/ayuda/ico-programacion-inact.svg';
import IconPhoneAct from '../../assets/img/ayuda/ico-linea-de-atencion-act.svg';
import IconCalendarAct from '../../assets/img/ayuda/ico-programacion-act.svg';

export default function Help(props){
    const linkbread=[{
        to:"/auth/inicio",
        tittle:"Inicio >"
    },
    {
        to:"/auth/ayuda/lineas-atencion",
        tittle:" Centro de ayuda",
    },];

    useEffect(()=>{
        if (window.location.pathname.includes('agenda-ayuda')) {
            document.getElementById('contenedorScheduling').style.margin= '0px 24px 0px 17px'
            document.getElementById('contenedorScheduling').style.padding= '0px'
        }
    },[])
    function cambiarStyle(params) {
        document.getElementById('contenedorScheduling').style.margin= '0px 24px 0px 17px'
        document.getElementById('contenedorScheduling').style.padding= '0px'
    }
    const [stateTabs,setStateTabs]=useState({
        line:true,
        sheduling:false,
    });
    const { pathname } = props.location;

    const handleClick = (e) =>{
       if(e === 'line'){
        setStateTabs({line:true,sheduling:false});
       }else{
        setStateTabs({line:false,sheduling:true});
       }
    }
    const infoSheduling =  {
        typeSheduling:'2045',
        typeTextSheduling:<h5 className="typeShedulingText" style={{color: '#323B4E'}}>Asesoría personal</h5>,
        module:'Ayuda',
        classVolver: 'btnBack',
        tittleSheduling:'',
        paragraph:<p style={{fontFamily:"Inter-regular", marginLeft:"10px", color:'#808285'}}>Solicita una asesoría para ayudarte. Selecciona una fecha, hora y medio para contactarte</p>,
        alertCancelTitle:'Cancela tu asesoría',
        alertCancelText: '¿Estás segura de cancelar tu asesoría?',
        messageSuccess:{
          variant:'success',
          checkText:'Has agendado tu asesoría con éxito',
          alertText:'En tu correo y/o número celular recibiras la confirmación de tu consultoría.',
          class:'alert alertSuccessAyu',
        },
        messageCancel:{
          module: "Ayuda",
          variant:'success',
          checkText:'Has cancelado tu asesoría con éxito',
          alertText:'Si deseas programar una nueva consultoría, haz clic a continuación.',
          class:'alert alertDesempleoHelp',
          textButton: "AGENDAR"
        }
      }; 
    return(
        <ContainerService tittle="Centro de ayuda" tittleSize="34px" styleTittle={{marginTop:"%",fontSize:"34px" ,fontFamily:"cib font sans",marginLeft:'20%' }} linkBread={linkbread} Background={"linear-gradient(90deg, #0040A8 74%,#026ED3 )"}>
            <div className="container" style={{padding:"2rem"}}>
                <div className="resposive row tabSheduling">
                    <h3 id="fontTitle" className="col-12 textParagraph" style={{color:'#666666',marginBottom:"26px",marginTop:"15px"}}>Si necesitas ayuda, puedes seleccionar una asesoría. Indícanos una fecha, hora y medio para ponernos en contacto contigo.</h3>
                    <Tabs className="contenedorTabsprofile row col-12" defaultActiveKey={pathname.includes('agenda')?'sheduling':'line'} id="uncontrolled-tab-example" onSelect={(e)=>{handleClick(e)}}>
                        <Tab eventKey="line" title={<span><img className="mr-2" src={stateTabs.line?IconPhoneAct:IconPhone} alt="icono telefono" />Líneas de atención</span>} tabClassName="lineTab fuenteText lineTab col-md-3 col-lg-3 col-9"> 
                            <Card style={{margin: '55px 20px 45px'}}>
                                <Card.Body>
                                    <div className="container">
                                        <div className="row" style={{marginLeft:"-4px", borderColor:'#E0E0E0', borderRadius:'2px'}}>
                                            <h2 className="col-12 titleHelp">Contáctanos a nuestras líneas de atención</h2>
                                            <div className="col-12">
                                                <p className="textHelp" style={{color: "#57595B"}}>En nuestras líneas de atención podremos resolver tus consultas y ayudarte. ¡Llámanos!</p>
                                            </div>
                                            <div className=" row col-lg-4 col-sm-12" style={{marginLeft:"15px"}}>
                                                <ul className="listContact">
                                                    <li><img src={IconPhoneAct} alt="icono telefono" style={{width:"31px",marginBottom:"16px"}}/></li>
                                                    <li style={{color:'#474747', fontFamily:'Inter-bold'}}>Línea de atención:</li>
                                                    <li><div style={{width:'120px', display:'inline-block'}}>Bogotá:</div> <span className="numberContact styleNum" > (601) 7431460</span></li>
                                                    <li><div style={{width:'120px', display:'inline-block'}}>Cali:</div> <span className="numberContact styleNum">(602) 4851736</span></li>
                                                    <li><div style={{width:'120px', display:'inline-block'}}>Medellín:</div> <span className="numberContact styleNum">(604) 6041013</span></li>
                                                    <li><div style={{width:'120px', display:'inline-block'}}>Barranquilla:</div><span className="numberContact styleNum">(605) 3852406</span></li>
                                                    <li><div style={{width:'120px', display:'inline-block'}}>Bucaramanga:</div><span className="numberContact styleNum" >(607) 6970006</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-4 col-sm-12">
                                                <ul className="listContact" style={{ marginTop:'41%'}}>
                                                     <li><div style={{width:'120px', display:'inline-block'}}>Ibagué:</div> <span className="numberContact styleNum" > (608) 2770253</span></li>
                                                     <li><div style={{width:'120px', display:'inline-block'}}>Pereira:</div> <span className="numberContact styleNum"> (606) 3400351</span></li>
                                                     <li><div style={{width:'120px', display:'inline-block'}}>Santa Marta:</div> <span className="numberContact styleNum">(605) 4351746</span></li>
                                                     <li><div style={{width:'120px', display:'inline-block'}}>Cartagena:</div> <span className="numberContact styleNum">(605) 6930839</span></li>
                                                    <li><div style={{width:'120px', display:'inline-block'}}>Armenia:</div> <span className="numberContact styleNum" >(606) 7357336</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-4 col-sm-12">
                                                <ul className="listContact" style={{ marginTop:'40%'}}>
                                                     <li><div style={{width:'120px', display:'inline-block'}}>Pasto:</div> <span className="numberContact styleNum" > (602) 7365089</span></li>
                                                     <li><div style={{width:'120px', display:'inline-block'}}>Tunja:</div> <span className="numberContact styleNum"> (608) 7470899</span></li>
                                                     <li><div style={{width:'120px', display:'inline-block'}}>Manizales:</div> <span className="numberContact styleNum">(606) 8918540</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            
                        </Tab>
                        <Tab eventKey="sheduling" onClick={cambiarStyle} title={<span><img className="mr-2" src={!stateTabs.sheduling?IconCalendar:IconCalendarAct} alt="icono telefono"  />Programar atención</span>} tabClassName="lineTab col-md-3 col-lg-3 col-9 shedulingTab">
                       
                            <div style={{marginTop:"12px",width:"100%",paddingLeft:'16px'}}>
                                <div className="contAcordeon">
                                <Sheduling key={2} type="2045" module="Ayuda" propsP={props} infoSheduling={infoSheduling}/>
                            </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </ContainerService>
    );
}