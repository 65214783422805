import React, { useEffect } from "react";
import ContainerService from "../../../components/container-service/ContainerService";
import CardService from "../../../components/card-service/CardService";
import networking from "../../../assets/img/home-in/itt/bancolombia-networking-002.jpg";
import comunidad from "../../../assets/img/home-in/itt/bancolombia-networking-003.jpg";

export default function NetworkingServices(){
    const linkbread=[{
        to:"/auth/inicio",
        tittle:"Inicio > "
    },
    {
        to:"/ITT/auth/networking",
        tittle:"Networking > ",
    },
    {
        to:"/ITT/auth/networking",
        tittle:"Servicio",
    }];
    const infoCards = [
        {
            imageUrl:networking,
            tittle:"Eventos de networking",
            subTittle:"Conoce la programación de eventos en tu ciudad.", 
            textButton:"CONOCER EVENTOS" ,
            linkButton:"/ITT/auth/networking/servicios/eventos",
        },
        {
            imageUrl:comunidad,
            tittle:"Comunidad de emprendedores",
            subTittle:"Conoce empresas y servicios de diferentes sectores e industrias.",
            textButton:"CONOCER LA COMUNIDAD",
            linkButton:"/ITT/auth/networking/servicios/comunidad-emprendimientos"
        }
    ];
    useEffect(()=>{
        localStorage.removeItem('modal')
    })
    return(
        <React.Fragment style={{maxWidth:"1030px"}}>
            <ContainerService tittle="Networking" styleTittle={{fontSize:"34px"}} linkBread={linkbread}>
                <div className="container-fluid" >
                    <div className="row pt-4 pb-4">
                        <div className="col-sm-12 pt-4" id="resp-p">
                            <p className="text-parrafo" style={{fontFamily:"OpenSans", fontSize:"16px"}}>Amplía tus redes de contactos, conéctate con empresas, emprendedores y freelance (servicios de terceros) y materializa fácilmente tus ideas y necesidades de negocio.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-5">
                        {infoCards.map((element,index)=>{
                            return(
                            <div key={index} className="col-sm-6" style={{maxWidth:"378px", paddingTop: "1.5rem", padding: "12px"}}>
                                <CardService view="service" serviceInfo={element}/>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </ContainerService>
        </React.Fragment>
    );
}