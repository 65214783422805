import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {FormGroup,Input,Label} from "reactstrap";
import cerrarModal from "../../assets/img/cardif-boton-cerrar.svg";

export default function ModalTerms(props) {
  const { title } = props;
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const [btnAccept, setbtnAccept] = useState(0);
  const handleClose = () => {
    setbtnAccept(0); 
    setShow(false)
  };
  const cambiobtnAccept = e=>{
    setbtnAccept(1);
    //console.log(btnAccept)
  }
  const dobleEnter = (
    <>
      <br />
      <br />
    </>
  );

  return (
    <>
      <span style={{cursor:"pointer"}} onClick={handleShow} className="hover mr-lg-2 mr-md-0  text-center TipoLetra">
        {title}
      </span>

      <Modal 
          show={show}
          onHide={handleClose} 
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
      >
        <Modal.Header>
        <div style={{ height: "40px"}}>
          <button
            id="btnCierraModal"
            type="button"
            className="swal2-close-modal btnTerms"
            onClick={handleClose}
          ><p>×</p></button>
        </div>
        </Modal.Header>
        <Modal.Body>
          <div className="body-modal-terms">
          <h3 className="tittle-modal-t" >Términos y condiciones</h3>
          <p className="overflow-scroll  text-justify termCondStyle">
          {dobleEnter}
          Declaración Legal
          {dobleEnter}
          Por favor lea con atención estos términos y condiciones de uso (los “Términos y Condiciones” o el “Acuerdo”) antes de utilizar los servicios relacionados con el ecosistema (los “Servicios”), incluidas todas las herramientas, funcionalidades, interfaces de usuario, software y contenidos que están disponibles a través la página web vivesegura.com  (el “Sitio”). Este Acuerdo establece los términos y condiciones legales y vinculantes a los cuales usted, en su condición de usuario del Sitio y del Servicio, estará sometido (el “Usuario”). Al usar el Servicio de cualquier manera, incluyendo, pero no limitado a, registrarse en el Sitio, navegar a través del Sitio, contratar los Servicios, reproducir los contenidos audiovisuales disponibles en el Sitio, que incluyen, pero no se limitan a, videos, presentaciones, test, ejercicios específicos, entre otros (los “Contenidos”), el Usuario se obliga, de manera libre y voluntaria, al cumplimiento de estos Términos y Condiciones. 
          {dobleEnter}
          El Sitio es operado por CARDIF COLOMBIA SEGUROS GENERALES S.A., sociedad constituida y existente conforme a las leyes de Colombia, con domicilio en Bogotá D.C. 
          {dobleEnter}
          Estos Términos y Condiciones, sus eventuales modificaciones, y cualquier información contenida en este Sitio, están sujetos a modificaciones sin aviso previo, que entrarán en vigencia a partir del momento en que sean publicadas en el Sitio o notificadas expresamente al Usuario, lo que primero ocurra, circunstancia que el Usuario entiende y acepta al utilizar este Sitio y al aceptar los presentes Términos y Condiciones. En consecuencia, el uso del Sitio con posterioridad a la publicación de los cambios realizados a los Términos y Condiciones constituye aceptación total de los mismos por parte del Usuario. Es responsabilidad del Usuario revisar los Términos y Condiciones de manera periódica. Toda la información que se brinda en este Sitio tiene validez únicamente dentro del territorio de la República de Colombia.
          {dobleEnter}
          El contenido de este Sitio tiene como único objetivo informar al Usuario y no pretende que ninguno de los materiales incluidos deba interpretarse o entenderse como una imposición, consejo o recomendación.
          {dobleEnter}
          <b>1. Propiedad.</b> Este Sitio es propiedad de y operado por CARDIF COLOMBIA SEGUROS GENERALES S.A. Este Sitio ha sido creado para la información, educación, y/ o comunicación del Usuario. Este Sitio y todos sus componentes, incluyendo sin limitación y entre otros, el texto, las imágenes y el audio, son © Derechos Reservados de CARDIF COLOMBIA SEGUROS GENERALES S.A, quienes se reservan el derecho de modificar su contenido, alcance, términos y condiciones, así como todo y cualquier elemento componente del Sitio, en cualquier momento y sin aviso previo. El Usuario reconoce que CARDIF COLOMBIA SEGUROS GENERALES S.A. no tiene control alguno sobre otros sitios o páginas a las que eventualmente se pueda acceder a través de links u otros medios y, por tanto, CARDIF COLOMBIA SEGUROS GENERALES S.A., COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S.  no se hacen responsables por el contenido de los mismos, ni por el manejo que los mismos puedan hacer de la información allí contenida o a la que pudieran tener acceso los usuarios.
          {dobleEnter}
          <b>2. Restricciones de uso.</b> Este Sitio está disponible sólo para uso personal, no para uso comercial. Está prohibido copiarlo, reproducirlo, republicarlo, diseminarlo, distribuirlo, transmitirlo o modificarlo de manera alguna, ya sea en su totalidad o parcialmente. Si tiene dieciocho (18) años o menos, antes de darnos su nombre, dirección o correo electrónico, o cualquier otro dato personal, deberá solicitar el permiso a sus padres o tutores.
          {dobleEnter}
          <b>3. Derecho de propiedad Industrial o Comercial.</b> El Usuario reconoce que el contenido (incluidos entre otros: texto, software, música, sonido, fotografías, vídeo, gráficos u otro material) ubicado bien sea en la publicidad de los anunciantes o en la información producida comercialmente y distribuida de forma electrónica y presentada al usuario por CARDIF COLOMBIA SEGUROS GENERALES S.A. por sí mismo o por un tercero autorizado, está protegido por derechos de autor, marcas, patentes u otros bienes mercantiles o formas diferentes del derecho de propiedad y no podrá ser modificado, copiado, publicado, o distribuido por el usuario. Esto puede incurrir en responsabilidad civil y responsabilidad penal según las normas vigentes.
          {dobleEnter}
          <b>4. Empleados.</b> Si el Usuario llegara a estar vinculado a CARDIF COLOMBIA SEGUROS GENERALES S.A. y/o COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S mediante contrato de trabajo, ninguno de los materiales proporcionados en este Sitio constituye, ni deben considerarse, parte de, o relacionados con, dicha relación de trabajo. Adicionalmente, la información disponible en este Sitio de ninguna manera constituye una garantía u obligación de CARDIF COLOMBIA SEGUROS GENERALES S.A. y COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S para con sus empleados o personal, tanto propio como de terceros, que le presten servicios.
          {dobleEnter}
          <b>5. Obligaciones.</b> El Usuario se obliga a:
          {dobleEnter}
          - No usar el Sitio y/o los Servicios para fines que sean contrarios a los Términos y Condiciones, a la legislación de la República de Colombia y/o a ley de la jurisdicción que le sea aplicable.
          <br></br>
          - No archivar, descargar (salvo por la descarga en caché necesario para su uso personal), reproducir, distribuir, modificar, transmitir, mostrar, exhibir, ejecutar, reproducir, duplicar, publicar, otorgar licencias, crear obras derivadas basadas en los Servicios, u ofrecer en venta, o usar información contenida en u obtenida de los Servicios, sin consentimiento previo por escrito de CARDIF COLOMBIA SEGUROS GENERALES S.A., COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S.
          <br></br>
          - No evitar, eliminar, alterar, desactivar, interferir con o burlar las medidas tecnológicas de protección aplicadas al Sitio; no usar ningún, spider, scraper u otra forma automatizada para acceder a los Servicios, y no descompilar, realizar ingeniería inversa, descifrar, descompilar, desensamblar o intentar derivar el código fuente o ideas subyacentes o algoritmos de cualquier parte del Sitio, el software u otro producto o proceso a los que se acceda a través de los Servicios. 
          <br></br>
          - No subir, publicar, enviar por email o transmitir de cualquier otra forma ningún material diseñado para interrumpir, destruir o limitar la funcionalidad de cualquier software, hardware o equipo de telecomunicaciones asociados con los Servicios. 
          <br></br>
          - No modificar, reproducir, publicar o transferir de cualquiera de los Contenidos a terceros, o su uso para fines comerciales. CARDIF COLOMBIA SEGUROS GENERALES S.A. se reserva el derecho, sujeto a su absoluta discreción, de negar o cancelar los Servicios, en caso de detectar conductas o actividades que, a su juicio, puedan ser contrarias a estos Términos y Condiciones.
          <br></br>
          - Ser responsable de todas las actividades que desarrolle o ejecute en el Sitio. Está prohibido el uso de terceros no autorizados de la cuenta asignada y es deber del Usuario evitar el uso no autorizado de la misma.
          {dobleEnter}
          <b>6. Divulgación y uso de sus mensajes y comunicados.</b> Toda comunicación de cualquier tipo enviada a este Sitio por correo electrónico u otro medio no es de carácter confidencial y CARDIF COLOMBIA SEGUROS GENERALES S.A. o COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. no tendrán responsabilidad alguna sobre su consecuente uso o divulgación. El Usuario declara que entiende y acepta que, hasta donde la legislación aplicable lo permita, todas las comunicaciones (incluyendo, sin limitación, ideas no solicitadas, sugerencias o materiales) que envíe a este Sitio, o a CARDIF COLOMBIA SEGUROS GENERALES S.A., por correo electrónico, se convertirán en propiedad única y exclusiva de CARDIF COLOMBIA SEGUROS GENERALES S.A., y podrán ser utilizados para cualquier propósito, inclusive comercial, sin compensación alguna. De requerirlo así CARDIF COLOMBIA SEGUROS GENERALES S.A.,  el Usuario se obliga a producir, suscribir, celebrar y entregar todos los documentos, pertinentes y necesarios, para establecer en CARDIF COLOMBIA SEGUROS GENERALES S.A., la propiedad única y exclusiva sobre dichas comunicaciones. Si por cualquier motivo no fuere posible establecer la propiedad única y exclusiva sobre dichas comunicaciones, entonces por este medio el Usuario otorga una licencia gratuita, exclusiva y sin limitación territorial o temporal alguna, para utilizar tales comunicaciones.
          {dobleEnter}
          <b>7. Productos.</b> Los productos a los que se refiere este Sitio están disponibles en Colombia. CARDIF COLOMBIA SEGUROS GENERALES S.A., declaran expresamente que la información y el material en este Sitio, incluyendo sin limitación la información y otros materiales que promueven los productos descritos aquí, no están disponibles en otras jurisdicciones. 
          {dobleEnter}
          <b>8. Virus.</b> CARDIF COLOMBIA SEGUROS GENERALES S.A., no establece representación o garantía alguna sobre la exactitud, confiabilidad o integridad del contenido (entre otros, textos e imágenes) de este sitio, e incluso no garantiza que este sitio o el servidor que lo hace disponible estén libres de "virus de computadoras".
          {dobleEnter}
          <b>9. Límites de responsabilidad.</b> CARDIF COLOMBIA SEGUROS GENERALES S.A., COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. en la medida en que la ley lo permita, no asumen responsabilidad alguna por los daños y perjuicios de toda naturaleza que pudieran derivarse de la utilización inadecuada o negligente delos servicios y de los contenidos por parte del usuario, ni por los que puedan derivarse de la falta de veracidad, vigencia, exhaustividad y/o autenticidad de la información que el usuario proporciona a otros usuarios acerca de sí mismos y, en particular, aunque no de forma exclusiva, por los daños y perjuicios de toda naturaleza que puedan derivarse de la suplantación de la personalidad de un tercero efectuada por un usuario en cualquier clase de comunicación realizada a través de éste sitio web.
          {dobleEnter}
          <b>10. Vínculos con otros sitios en la Internet:</b> La inclusión de vínculos a otros sitios a través de vivesegura.com no implica ninguna relación diferente al "vínculo" mismo. Todas las transacciones realizadas en dichos vínculos son responsabilidad exclusiva del usuario y de la entidad relacionada.
          {dobleEnter}
          <b>11. Cambios en la Aplicación y en los Términos y Condiciones:</b> Es responsabilidad del Usuario revisar los Términos y Condiciones de manera periódica. El uso de los Servicios con posterioridad a la publicación de los cambios realizados a los Términos y Condiciones constituye aceptación tácita de dichos cambios y de la totalidad de los Términos y Condiciones.
          {dobleEnter}
          En la medida en que la legislación aplicable lo permita, CARDIF COLOMBIA SEGUROS GENERALES S.A., se reserva el derecho de modificar, suspender o interrumpir los Servicios en cualquier momento, por cualquier razón y sin aviso previo. También se reserva el derecho a limitar ciertos usos y características de del Sitio, del Servicio y de las herramientas provistas en ésta, y de restringir el acceso parcial o total al Servicio y al Sitio, sin obligación de dar aviso previo o presentar justificación alguna.
          <br />
          Se excluye expresamente cualquier tipo de responsabilidad que se le pudiera atribuir a CARDIF COLOMBIA SEGUROS GENERALES S.A., como consecuencia de las modificaciones, suspensiones o interrupciones, permanentes o temporales, que sufra el Sitio y/o los Servicio. 
          {dobleEnter}
          <b>12. Otros.</b> Si se llegara a determinar que algún término, condición o disposición de estos Términos y Condiciones es ilegal, inválido, nulo o, por cualquier razón, inaplicable, la validez y aplicación de los demás términos, condiciones y disposiciones de estos Términos y Condiciones no se verán de ninguna manera afectados. Así mismo, todos los títulos que encabezan cada artículo son solamente descriptivos y no agotan el contenido de los mismos. Toda cuestión entre CARDIF COLOMBIA SEGUROS GENERALES S.A, COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y el Usuario será regida por las leyes de la República de Colombia, con exclusión de toda otra norma que pudiera eventualmente remitir a aplicación de ley extranjera alguna. Así mismo, serán los Tribunales Ordinarios quienes entiendan en cualquier controversia que se suscite, con exclusión de todo otro fuero o jurisdicción que pudiera corresponder. La demora u omisión de CARDIF COLOMBIA SEGUROS GENERALES S.A., en exigir el estricto cumplimiento de estos Términos y Condiciones no podrá interpretarse como renuncia a sus derechos.
          {dobleEnter}
          SI NO ESTÁ DE ACUERDO CON ESTOS TÉRMINOS Y CONDICIONES, NO UTILICE ESTE SITIO.
          </p>
          </div>
          <FormGroup className="rdAccept">
            <Input
              id="radio1"
              type="radio"
              onChange={cambiobtnAccept}
            />
            <Label for="radio1" >
              <p className="txtTerminosPolitiAccept">Acepto la Política de Términos y Condiciones.</p>
            </Label>
          </FormGroup>
         {btnAccept===0?(
            <div className="text-center">
              <button
              type="button"
              className="btnModal mt-0 pl-5 pr-5"
              style={{background:"#D5D5D5", cursor:"default"}}
            >
              ACEPTAR
              </button>
            </div>
            )
          :(
            <div className="text-center">
              <button
              type="button"
              onClick={handleClose}
              className="btnModal mt-0 pl-5 pr-5"
            >
              ACEPTAR
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
