import React ,{ useState , useEffect} from "react";
import ContainerService from "../../../components/container-service/ContainerService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Form from 'react-bootstrap/Form';
import Sheduling from "../../../components/sheduling/Sheduling";
import { getSchedules } from '../../../utils/validations';
import {  useHistory } from "react-router-dom";

export default function Legal(props){
    const {pathname} = props.location;
    const [type, setType] = useState('');
    const [auxType, setAuxType] = useState(0);
    const [step, setStep] = useState(1);
    const [typeError, setTypeError] = useState('');
    const linkbread=[{
        to:"/auth/inicio",
        tittle:"Inicio  >"
    },
    {
        to:"/ITT/auth/tu-asesoria-legal",
        tittle: "  Tu consultoría legal   ",
    },];


    const SelectLegal = [{
        value:"0",
        text:'Selecciona una consulta',
    },{
        value:"2037",
        text:'Pasos básicos para declaración de renta',
    },{
        value:"2040",
        text:'Pasos básicos para el trámite de Lema Comercial',
    },{
        value:"2041",
        text:'Pasos básicos para el trámite de Creación empresa',
    },{
        value:"2038",
        text:'Pasos básicos para el trámite de Registro de Marca',
    },{
        value:"2039",
        text:'Pasos básicos para el trámite de Registro de Patente',
    },{
        value:"2042",
        text:'Otros',
    }]
    useEffect(()=>{
        let sheduling;
        let res = new Promise((resolve)=>{
             sheduling= getSchedules(SelectLegal);
            resolve(sheduling);
        }).then((res)=>{
            setStep(res?2:1);
        });
    },[]);

    const history = useHistory();
    useEffect(()=>{
        if(window.location.pathname === "/ITT/auth/tu-asesoria-legal"){
            setStep(1);
        }
    },[history.location.pathname]);
    const infoSheduling = {
        typeSheduling: type,
        classTitleSheduling: 'fechahoraTitulo',
        classVolver: 'btnBackGrey',
        typeTextSheduling:<h5 className="typeShedulingText">Consultoría legal</h5>,
        module:'Legal ITT',
        tittleSheduling:'',
        paragraph:'Para prestarte un servicio adecuado por favor confirma los siguientes datos:',
        alertCancelTitle:'Cancelación de agenda',
        alertCancelText:'¿Estás seguro de cancelar tu orientación legal?',
        tooltipsClass:'tooltipShedulingEco',
        messageSuccess:{
          module:'Seguridad',
          variant:'success',
          checkText:'Confirmación de consultoría éxitosa',
          alertText:'Recibirás una notificación vía correo electrónico y SMS confirmando tu cita',
          class:'alert alertSuccessEcosistemas',
        },
        messageCancel:{
          variant:'success',
          checkText:'Tu consultoría ha sido cancelada con éxito',
          alertText:'Si deseas programar una nueva consultoría, haz clic a continuación.',
          class:'alert alertEcoWarning',
          textButton: "AGENDAR NUEVA CONSULTORÍA"
        }
    };
    const handleSelect = (e) =>{
        if(e.target.value !== '' || e.target.value !== '0'){
            setTypeError('');
        }
        setType(e.target.value);
        setAuxType(1);
    }
    const handleSave = () =>{
        if(type === '' || type === "0"){
            setStep(1);
            setTypeError('Seleccione un motivo de su consulta');
        }else if(auxType === 0 || auxType === 1){
            setStep(2);
            setTypeError('');
            setAuxType(2);
        }else{
            setStep(1)
            setTypeError('Seleccione un motivo de su consulta');
        }
    }
    

    return(
        <React.Fragment>
            <ContainerService tittle="Consultoría legal" styleTittle={{fontSize:"34px", margintop:"6%",lineHeight:"56px",fontFamily:'cibSans-Bold'}} linkBread={linkbread}>
                <div className="container-fluid">   
                    <div className="row">
                        <div className="col-12" style={{padding:'30px 0px 30px 20px', }}>
                            <div className="pb-4">
                            {step === 1&&( 
                            <div className="mt-5" id="resp-p1">
                                <div className="row">
                                <p style={{ color: "#58595B", fontFamily: 'OpenSans', fontSize: "16px"}}>
                                Las siguientes preguntas nos permitirán asesorarte adecuadamente. Recuerda que la consultoría legal solo se podrá prestar para temas 
                                relacionados con creación de empresa, registros de marcas y patentes y/o temas tributarios.
                                </p>
                                <div className="col-lg-6 col-md-6 col-sm-6" id="resp-questions">
                                        <p style={{ color: "#808285", fontFamily: 'OpenSans', fontSize: "14px", marginTop: "25px", marginBottom: "0px"}}>
                                        ¿Cuál es el motivo de consulta?
                                        </p>
                                        <select onChange={(e)=>{handleSelect(e)}} name="select-legal" className="form-control iconoArrow borderContact" style={{ color: "#808285", fontFamily: 'OpenSans', fontSize: "14px", height: "42px" , paddingLeft: "10px"}}>
                                        {SelectLegal.map((e,i) => {
                                            return(
                                            <option key={i} value={e.value}>
                                                {e.text}
                                            </option>
                                            )
                                        })}    
                                        </select>
                                        <small className="form-text text-danger">{typeError}</small>
                                    </div>
                                <div className="col-md-6 col-lg-6" id="resp-questions">
                                        <p style= {{ color: "#808285", fontSize:"14px", fontFamily:'OpenSans',  marginTop: "25px"}}>
                                        ¿Actualmente tienes algún proceso judicial ?
                                        </p> 
                                        <div className="resp-radiobu" style={{display:"inline-flex"}}>
                                            <Form.Check
                                            type="radio"
                                            label="Si"
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios1"
                                            />
                                            <Form.Check
                                            type="radio"
                                            label="No"
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios2"
                                            defaultChecked
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                <button onClick={()=>{handleSave()}} style={{fontFamily: 'inherit'}} className=" btn btn-yellowSheduling mt-5">CONTINUAR MI CONSULTORÍA</button>
                                </div>
                            </div>
                            )}
                            {step==2&&(
                                <Sheduling key={1} type={type} module="Legal ITT" propsP={props} infoSheduling={infoSheduling}/> 
                            )}
                            </div>
                        </div>
                    </div>
                </div>
                
            </ContainerService>
        </React.Fragment>
    );
}