import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ContainerService from '../../components/container-service/ContainerService';
import advisoryIco from "../../assets/img/Advisory-desempleo/bdb-advisory-ico-blue.svg";
import Sheduling from "../../components/sheduling/Sheduling";
import { getSchedules } from '../../utils/validations';
import Form from 'react-bootstrap/Form';
import {  useHistory } from "react-router-dom";

export default function Advisory(props) {
    const [formContact, setFormContact] = useState('');
    const [auxFormContact, setAuxFormContact] = useState(0);
    const [typeError, setTypeError] = useState(true);
    const [step, setStep] = useState(1);
    const linkbread = [{
        to: "/auth/inicio",
        tittle: "Inicio > "
    },
    {
        to: "/s/desempleo/asesoria-legal",
        tittle: "Consultoría legal",
    }];

    const handleFormContact = (e) => {
        if(e.target.value !== '' || e.target.value !== '0'){
            setTypeError('');
        }
        setFormContact(e.target.value);
        setAuxFormContact(1);
    }
    const send = () => {
        if((formContact === '' || formContact === "0") ){
            setStep(1);
            setTypeError('Seleccione un motivo de su consulta');
        }else if(auxFormContact === 0 || auxFormContact === 1){
            setStep(2);
            setTypeError('');
            setAuxFormContact(2);
        }else{
            setStep(1);
            setTypeError('Seleccione un motivo de su consulta');
        }
    }
    const infoSheduling = {
        typeSheduling: formContact,
        classTitleSheduling: 'fechahoraTitulo',
        classVolver: 'btnBackGrey',
        typeTextSheduling:<h5 className="typeShedulingText">Consultoría legal</h5>,
        module:'Legal',
        tittleSheduling:'',
        paragraph:'Para prestarte un servicio adecuado por favor confirma los siguientes datos:',
        alertCancelTitle:'Cancelación agenda',
        alertCancelText:'¿Estás seguro de cancelar tu orientación legal?',
        tooltipsClass:'tooltipShedulingEco',
        messageSuccess:{
          module:'Seguridad',
          variant:'success',
          checkText:'Confirmación de consultoría éxitosa',
          alertText:'Recibirás una notificación via correo electrónico y SMS confirmando tu cita.',
          class:'alert alertSuccessEcosistemas',
        },
        messageCancel:{
          module: "Desempleo",
          variant:'success',
          checkText:'Tu consultoría ha sido cancelada con éxito',
          alertText:'Si deseas programar una nueva consultoría, haz clic a continuación.',
          class:'alert alertEcoWarning',
          textButton: "AGENDAR CONSULTORÍA"
        }
    };
    const opcLegal = [
        {id:'2025',text:'Pago de prestaciones sociales atrasadas',},{id:'2026',text:'Pago de cesantías e intereses a las cesantías',},{id:'2027',text:'Pago de primas y vacaciones',},{id:'2028',text:'Liquidación por terminación de contrato',},
        {id:'2029',text:'Mecanismo de protección al cesante',},{id:'2030',text:'Terminación de contrato sin justa causa - Indemnización',},{id:'2031',text:'No pago de la liquidación al terminar el contrato de trabajo',},{id:'2032',text:'Trámite de solicitud de pensión',},
        {id:'2033',text:'Accidente laborales',},{id:'2034',text:'Acoso laboral',},{id:'2035',text:'Otros asuntos laborales',}
    ]
    const history = useHistory();
    useEffect(()=>{
        if(window.location.pathname === "/s/desempleo/asesoria-legal"){
            setStep(1);
        }
    },[history.location.pathname]);
    useEffect(()=>{
        let sheduling;
        let res = new Promise((resolve)=>{
             sheduling= getSchedules(opcLegal);
            resolve(sheduling);
        }).then((res)=>{
            setStep(res?2:1);
        });
       
    },[]);
    return (
        <React.Fragment>
            <ContainerService tittle="Orientación legal laboral" styleTittle={{ fontSize: "34px", lineHeight: "56px", fontFamily: 'DTL-ArgoT-regular' }} linkBread={linkbread}>
                
                <section className="content">
                    <div className="col-12" style={{ padding: '0% 6% 2% 6%' }}>
                        <div className="tab-content mt-3" id="pills-tabContent">
                        {step === 1 &&(
                            <div className="mb-3 mt-5">
                                <p className="letterSheduling">
                                La siguiente pregunta nos permitirá orientarte adecuadamente. 
                                Recuerda que la consultoría legal sólo se podrá prestar en temas relacionados con Derecho Laboral.
                                </p>
                                <div className="row">
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mt-3 mr-3" style={{paddingLeft:"0px"}}>
                                        <div className="form-group">
                                            <label className="letterSheduling mb-0" for="typeId">
                                                <span>
                                                    ¿Cuál es el motivo de consulta?
                                                </span>
                                            </label>
                                            <select name="consulta" className="form-control borderContact iconoArrow" id="opcLegal" value={formContact.consulta} onChange={(e) => { handleFormContact(e) }}>
                                                    <option value="0">
                                                        Selecciona una consulta*
                                                    </option>
                                                {
                                                    opcLegal.map(opcLegalvar => {
                                                        return (
                                                                <option value={opcLegalvar.id}>
                                                                    {opcLegalvar.text}
                                                                </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-4 col-md-5 col-sm-12 " style={{marginTop:"-24px"}}>
                                    <p className="letterSheduling" style= {{ marginTop: "40px"}}>
                                    ¿Actualmente tienes algún proceso judicial ?
                                    </p> 
                                    <div style={{display:"inline-flex"}}>
                                        <Form.Check
                                        type="radio"
                                        label="Si"
                                        name="formHorizontalRadios"
                                        id="formHorizontalRadios1"
                                        className="letterSheduling"
                                        />
                                        <Form.Check
                                        type="radio"
                                        label="No"
                                        name="formHorizontalRadios"
                                        className="letterSheduling"
                                        id="formHorizontalRadios2"
                                        style={{marginLeft:"20px"}}
                                        defaultChecked
                                        />
                                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                        <button type="button" id="btn-nextopcLegal" className="btn mt-5 btn-yellowSheduling pull-right" onClick={() => { send() }}>
                                            CONTINUAR MI CONSULTORÍA
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {step === 2 &&(
                            <div >
                                <Sheduling key={1} type={formContact} module="Legal" propsP={props} infoSheduling={infoSheduling}/>
                            </div>
                        )}
                        </div>
                    </div>
                </section>
                
                
            </ContainerService>
        </React.Fragment>
    );
}