import React, { useEffect, useState } from 'react';
import { getUser, getToken, addLog, addLogList } from '../../utils/validations';
import { urlAPI, url, urlVideoLlamada, urlAPIVideoLlamada, tokenVideoLlamada, colorPrincipal, sponsor, idExternoSala, TokenUser, regexValidacionMail } from '../../utils/config';
import Card from 'react-bootstrap/Card';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import world from '../../assets/img/seguridad-informatica/icon-world.svg'
import 'react-datepicker/dist/react-datepicker.css';
import TypeAlert from '../alert/TypeAlert';
import es from 'date-fns/locale/es';
import iconCalendar from '../../assets/img/seguridad-informatica/calendar.svg';
import iconClock from '../../assets/img/seguridad-informatica/icon-clock.svg';
import iconBack from '../..//assets/img/seguridad-informatica/icon-volver-blue.svg';
import CardService from '../card-service/CardService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faSkype } from '@fortawesome/free-brands-svg-icons';
import { faAngleLeft, faCaretDown, faPhoneAlt, faChevronLeft, faChevronRight, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Loader from '../loader/Loader';
import Axios from 'axios';
import $, { data } from 'jquery';
import Tooltips from '../tooltips/Tooltips';
import ModalConsentTele from '../modal/ModalConsentTele';
import useCustomDate from '../../hooks/useCustomDate';
import ModalTerms from "../../components/modal/ModalTerms";
import ModalPolicies from "../../components/modal/ModalPolicies";
import logoSocio from "../../assets/img/footer/leonisa-mobile.png";

registerLocale('es', es)
setDefaultLocale('es');
// setFirstDayOfWeek(Calendar.MONDAY); 


export default function Sheduling({ type, module, propsP, infoSheduling }) {
  let terCond = (
    <a href style={{ color: "#00448C", textDecoration: "underline", fontFamily: 'Scotia-regular', fontWeight: '400', fontSize: '14px' }}>
      términos y condiciones
    </a>
  );
  let polServ = (
    <a href style={{ color: "#00448C", textDecoration: "underline", fontFamily: 'Scotia-regular', fontWeight: '400', fontSize: '14px' }}>
      política de privacidad del servicio
    </a>
  );
  const { pathname } = propsP.location;
  const infoShedulingLegal = {
    tittle: '',
    paragraph1: '',
    paragraph2: '',
    buttonText: 'Agendar consultoría',
  }
  const [count, setCount] = useState(0);
  function defaultShedulingInfo() {
    return {
      id: '',
      date: null,
      hour: '',
      typeContact: '',
      infoContact: '',
      link:'',
    }
  }
  function defaultContactSelect() {
    return {
      iconSelect: '',
      faIcon: null,
      discriminatory: null,
    };
  }
  function defaultFormContact() {
    return {
      mailContact: '',
      numberContact: '',
    };
  }
  function defaultErrorsFormContact() {
    return {
      errorEmail: '',
      errorNumber: '',
    }
  }
  const [formContact, setFormContact] = useState(defaultFormContact);
  const [shedulingInfo, setShedulingInfo] = useState(defaultShedulingInfo);
  const [typeSheduling, setTypeSheduling] = useState(infoSheduling);
  const [errorsFormContact, setErrorsFormContact] = useState(defaultErrorsFormContact);
  const [showButton, setShowButton] = useState(false);
  const [step, setStep] = useState('agenda');
  const [loaderShow, setLoaderShow] = useState(false);
  const [stepCount, setStepCount] = useState(1);
  const [stateInput, setStateInput] = useState(false);
  const [horas, setHoras] = useState('');
  const { convertToFormatDate,convertToISOFormatTime } = useCustomDate();
  const hours =
    [
      {
        hour: 7,
        valueHour: '7:00',
        titleHour: '7:00 AM'
      }, {
        hour: 7.5,
        valueHour: '7:30',
        titleHour: '7:30 AM'
      }, {
        hour: 8,
        valueHour: '8:00',
        titleHour: '8:00 AM'
      }, {
        hour: 8.5,
        valueHour: '8:30',
        titleHour: '8:30 AM'
      }, {
        hour: 9,
        valueHour: '9:00',
        titleHour: '9:00 AM'
      }, {
        hour: 9.5,
        valueHour: '9:30',
        titleHour: '9:30 AM'
      }, {
        hour: 10,
        valueHour: '10:00',
        titleHour: '10:00 AM'
      }, {
        hour: 10.5,
        valueHour: '10:30',
        titleHour: '10:30 AM'
      }, {
        hour: 11,
        valueHour: '11:00',
        titleHour: '11:00 AM'
      }, {
        hour: 11.5,
        valueHour: '11:30',
        titleHour: '11:30 AM'
      }, {
        hour: 12,
        valueHour: '12:00',
        titleHour: '12:00 PM'
      }, {
        hour: 12.5,
        valueHour: '12:30',
        titleHour: '12:30 PM'
      }, {
        hour: 13,
        valueHour: '13:00',
        titleHour: '1:00 PM'
      }, {
        hour: 13.5,
        valueHour: '13:30',
        titleHour: '1:30 PM'
      }, {
        hour: 14,
        valueHour: '14:00',
        titleHour: '2:00 PM'
      }, {
        hour: 14.5,
        valueHour: '14:30',
        titleHour: '2:30 PM'
      }, {
        hour: 15,
        valueHour: '15:00',
        titleHour: '3:00 PM'
      }, {
        hour: 15.5,
        valueHour: '15:30',
        titleHour: '3:30 PM'
      }, {
        hour: 16,
        valueHour: '16:00',
        titleHour: '4:00 PM'
      }, {
        hour: 16.5,
        valueHour: '16:30',
        titleHour: '4:30 PM'
      }, {
        hour: 17,
        valueHour: '17:00',
        titleHour: '5:00 PM'
      }, {
        hour: 17.5,
        valueHour: '17:30',
        titleHour: '5:30 PM'
      }, {
        hour: 18,
        valueHour: '18:00',
        titleHour: '6:00 PM'
      }, {
        hour: 18.5,
        valueHour: '18:30',
        titleHour: '6:30 PM'
      },
    ];
  const [hourDay, setHourDay] = useState([]);
  const [currenDate, setCurrenDate] = useState(new Date());
  const [contactSelect, setContactSelect] = useState(defaultContactSelect);
  const [startDate, setStartDate] = useState(null);
  const [infoUser, setInfoUser] = useState({ nombres: '', apellidoPaterno: '' });
  const getYear2 = (date) => {
    return date.getFullYear();
  }
  const getMonth = (date) => {
    return date.getMonth();
  }
  const range = (start, end, step) => Array.from({ length: (end - start) / step + 1 }, (_, i) => start + (i * step));
  const years = range(getYear2(new Date()), getYear2(new Date()) + 11, 1);
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ];
  const [contactsUser, setContactsUser] = useState([]);
  const [routeSchedule, setRouteSchedule] = useState("");
  const [infoSala, setInfoSala] = useState({});

  const SetLogTab = () => {
      const logUserList = []
      if (shedulingInfo?.date) logUserList.push(addLog('Validación Servicio agendamiento', 'Seleccionó fecha', 'Home In', 'Exitoso'))
      if (shedulingInfo?.hour) logUserList.push(addLog('Validación Servicio agendamiento', 'Seleccionó hora', 'Home In', 'Exitoso'))
      logUserList.push(addLog('Validación Servicio agendamiento', 'Confirmar fecha y hora', 'Home In', 'Exitoso'))
      addLogList(logUserList)
  }

  useEffect(() => {
    if (count === 0) {
      setLoaderShow(true);
      Axios.get(`${urlAPI}/Profile/InfoGeneral`, { headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + getToken(), } })
        .then(scc => {
          if (scc.data.codigo === 0) {
            setInfoUser(scc.data.data);
            let parametrosSala = {               
              "nombrePart1": scc.data.data.nombres,
              "idExternoPart1": scc.data.data.identificacion,
              "nombrePart2": "Asesor",
              "idExternoPart2": "",
              "nombreAcomp": "",
              "textoBienvenida": "",
              "urlLogo": `${url}/assets/img/img-videollamada.svg`,
              "colorPrincipal": `${colorPrincipal}`,
              "tituloSala": "Bienvenido a tu consulta en lí­nea",
              "textoBotonBienvenida": "Comenzar",
              "textoLlamadaFinalizada": "Has terminado tu sesión, recuerda que puedes volver a agendar tu asistencia en cualquier momento.",
              "urlVideoLlamada": `${urlVideoLlamada}`,
              "urlRetornoFinalizacion": "",
              "pais": "Colombia",
              "sponsor": `${sponsor}`,
              "idExternoSala": `${idExternoSala}`,
              "TokenUser": `${TokenUser}`,
            };
            setInfoSala(parametrosSala);
            //console.log(parametrosSala);
          }
          Axios.get(`${urlAPI}/agendamientos/verContactos/${getUser().idUsuario}`, { headers: { 'Content-Type': 'application/json', Authorization: getToken() } })
            .then(contacts => {

              if (contacts.data.length == 0) {
                setContactsUser([
                  { tipoContacto: 'Whatsapp', contacto1: scc.data.data.numeroCelular, },
                  { tipoContacto: 'Celular', contacto1: scc.data.data.numeroCelular, },
                  { tipoContacto: 'Skype', contacto1: scc.data.data.correoElectronico, },
                  { tipoContacto: 'VideoLlamada', contacto1: scc.data.data.correoElectronico, } 
                ]);
              } else {
                setContactsUser(contacts.data);
              }
              if (type === "2047") setFormContact({ numberContact: scc.data.data.numeroCelular, mailContact: scc.data.data.correoElectronico, });
            })
        }).catch(err => {
          //console.log(err)
        });
      Axios.get(`${urlAPI}/agendamientos/agendas/${getUser().idUsuario}`, { headers: { 'Content-Type': 'application/json', Authorization: getToken() } })
        .then(listSheduling => {
          Axios.get(`${urlAPI}/agendamientos/agendasModulos/${module==='Orientacion'?'Orientacion psicologica':module}`, { headers: { 'Content-Type': 'application/json', Authorization: getToken() } })
            .then(typeSheduling => {
              if (listSheduling.data.data !== null) {
                filterSheduling(listSheduling.data.data, typeSheduling.data.data);
              }
              setLoaderShow(false);
            }).catch(rror => {
              //console.log(rror);
            });
        })
        .catch(error => {
          //console.log(error);
        });
    }
  }, [count]);

  const filterSheduling = (listShedulings, typeShedulings) => {
    let listFinal = [];
    listShedulings.forEach(sheduling => {
      typeShedulings.forEach((typeSheduling, i) => {
        if (sheduling.idAgendaTipo === typeSheduling.idAgendaTipo) {
          if (new Date(`${new Date(sheduling.fechaAgenda).toDateString()} ${sheduling.horaAgenda}`).toLocaleString('es-Co', { day: '2-digit', month: '2-digit', year: 'numeric' }) >= currenDate.toLocaleString('es-Co', { day: '2-digit', month: '2-digit', year: 'numeric' }) || new Date(sheduling.fechaAgenda).getFullYear() > currenDate.getFullYear()) {
            listFinal.push(sheduling);
          }
        }
      });
    });
    if (listFinal.length > 0) {
      setShedulingInfo({
        id: listFinal[0].idAgenda,
        date: new Date(listFinal[0].fechaAgenda),
        hour: new Date(`${new Date(listFinal[0].fechaAgenda).toDateString()} ${listFinal[0].horaAgenda}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }),
        typeContact: listFinal[0].DiscriminadorAgendamiento,
        infoContact: listFinal[0].contacto,
        link:listFinal[0].linkUsuario,
      })
      let classAct = listFinal[0].DiscriminadorAgendamiento === '1' ? ['activeContactW', faWhatsapp, 1] : listFinal[0].DiscriminadorAgendamiento === '2' ? ['activeContactS', faSkype, 2] : listFinal[0].DiscriminadorAgendamiento === '0' ? ['activeContactP', faPhoneAlt, 3] : '';
      setContactSelect({ faIcon: classAct[1], discriminatory: classAct[2] });
      setStep('check');
      setStateInput(false);
    }
  }
  const handleDate = (date) => {
    setStartDate(date);
    setShedulingInfo({ ...shedulingInfo, date: date });
    let hourCopy = hours;
    let hourFinalUsers = [], tempHoursFinal = [], repitedHours = [];

    if (type === "2047") {
      Axios.post(`${urlAPI}/agendamientos/verificarHorario`, JSON.stringify({
        "idAgendaTipo": parseInt(type),
        "fechaAgenda": `${date.getFullYear()}-${date.toLocaleString('es-Co', { month: '2-digit' })}-${date.toLocaleDateString('es-CO', { day: 'numeric' })}`,
      }), { headers: { 'Content-Type': 'application/json', Authorization: getToken() } }).then(succ => {
        if (succ.data.codigo === 0) {
          succ.data.data.forEach((e) => {
            hours.forEach((elem) => {
              if (elem.valueHour === new Date(`${new Date(e.fechaAgenda).toDateString()} ${e.horaAgenda}`).toLocaleTimeString('en-Us', { hour: 'numeric', minute: '2-digit' })) {
                hourFinalUsers.push(e.horaAgenda);
              }
            });
          });
          hourFinalUsers.forEach((value, index) => {
            tempHoursFinal = hourFinalUsers;
            tempHoursFinal.splice(index, 1);
            if (tempHoursFinal.indexOf(value) != -1 && repitedHours.indexOf(value) == -1) {
              repitedHours.push(value);
            }
          });
          if (repitedHours.length > 0) {
            hours.forEach((e, i) => {
              repitedHours.forEach((el, ind) => {
                if (e.valueHour === new Date(`${date.toLocaleDateString()} ${el}`).toLocaleTimeString('en-Us', { hour: 'numeric', minute: '2-digit', hour12: true })) {
                  hourCopy.splice(i, 1);
                }
              });
            });
          }
        }
        hoursFilter(date, hourCopy);
      }).catch(err => {
        //console.error(err);
      });
    }
    else {
      hoursFilter(date, hourCopy);
    }
    setStepCount(2);
  }

  const hoursFilter=(date,hourCopy)=>{
    let currentHour = parseInt(new Date().toLocaleTimeString('es-Co',{hour:'2-digit',hour12:false}));
    let currentMinutes = parseInt(new Date().toLocaleTimeString('es-Co',{minute:'2-digit',hour12:false}));
  
    let fechaAgenda = new Date(date.getFullYear(),date.getMonth(),date.getDate()).getTime();
    let fechaActual = new Date(currenDate.getFullYear(),currenDate.getMonth(),currenDate.getDate()).getTime();

    if(fechaAgenda > fechaActual){ 
      setHourDay(hourCopy); 
    }else if(fechaAgenda == fechaActual){
      if(currentHour >= 17) {
        setHourDay([])
      }else{
        let hours = currentMinutes >= 30 ? (currentHour+2.5) : (currentHour+2);
        setHourDay(hourCopy.filter(x=>x.hour > hours));
      } 
    }else{
      setHourDay([]);
    }
  }
  let checkedTerm = false;
  const handleFormContact = (e) => {
    let phoneCaracters = /[0-9 ]$/;
    if (e.target.name === "checkConctact") {
      setFormContact({ ...formContact, [e.target.name]: e.target.checked });
      checkedTerm = e.target.checked;
    } else {
      setFormContact({ ...formContact, [e.target.name]: e.target.value });
    }    
    if (e.target.name === 'mailContact') setErrorsFormContact({ ...errorsFormContact, errorEmail: e.target.value.split(/[@]/gi).length > 2 ? 'El correo debe contener un solo arroba (@).' : e.target.value.includes(' ') ? 'El correo no debe incluir espacios.' : '' });
    if (e.target.name === 'numberContact') setErrorsFormContact({ ...errorsFormContact, errorNumber: !e.target.value.startsWith(3) ? 'El número debe iniciar con 3.' : !phoneCaracters.test(e.target.value) ? 'No se permite el ingreso de letras ni caracteres especiales.' : e.target.value.includes(' ') ? 'El número celular no debe incluir espacios.' : e.target.value.length > 10 ? 'La longitud debe ser de 10 dígitos.' : '' });
    setShowButton(
      formContact.numberContact &&
      formContact.mailContact &&
      checkedTerm &&
      errorsFormContact.errorEmail === '' &&
      errorsFormContact.errorNumber === ''
      ? true
      : false
    );
  }
  const handleTime = (e) => {
    $('.btnHora').removeClass('btnHourSelect');
    document.getElementById(e).classList.add('btnHourSelect');
    $('#confirm').removeClass('d-none');
    setShedulingInfo({ ...shedulingInfo, hour: e });
  }
  const handleContact = (e) => {
    $('.btnContact').removeClass(['activeContactW', 'activeContactS', 'activeContactP']);
    let classAct = ['activeContactV', faVoicemail, 4];
    document.getElementById(e).classList.add(classAct[0]);
    setContactSelect({ iconSelect: e, faIcon: classAct[1], discriminatory: classAct[2] });
    let contact = contactsUser.find(x => x.tipoContacto === e);
    setShedulingInfo({ ...shedulingInfo, typeContact: classAct[2], infoContact: contact != undefined ? contact.contacto1 : '' });
  }
  const handleSaveSheduling = () => {
    const logUserList = [];
    logUserList.push(addLog('Validación Servicio agendamiento', 'Botón agendar', 'Home In', 'Exitoso'));
    addLogList(logUserList);
    
    let fechaAgenda = convertToFormatDate(shedulingInfo.date,'YYYY-MM-DD');
    let horaAgenda = convertToISOFormatTime(shedulingInfo.date, shedulingInfo.hour);
    let objSheduling = {
      DiscriminadorAgendamiento: `${shedulingInfo.typeContact}`,
      contacto: type === "2047"  ? JSON.stringify({ "contacto1": formContact.numberContact, "contacto2": formContact.mailContact }) : shedulingInfo.infoContact,
      fechaAgenda: fechaAgenda,
      horaAgenda: horaAgenda,
      idAgenda: shedulingInfo.id,
      idAgendaTipo: type,
      idUsuario: getUser().idUsuario,
      linkUsuario: "",
      linkAsesor: "",
      rutaAgenda: ""
    }

    if(((shedulingInfo.typeContact !== '' && shedulingInfo.infoContact) || showButton) && (!stateInput || showButton)){
      let actionAgenda = '';
      let metodo = '';
      if(shedulingInfo.id === ''){
        actionAgenda = 'AgregarAgenda';
        metodo = 'Agendar';
      }else{          
        actionAgenda = 'UpdateAgenda';
        metodo = 'Modificar';
      }
      setLoaderShow(true);
      //console.log("urlAPIVideoLlamada",urlAPIVideoLlamada,tokenVideoLlamada,infoSala)
      Axios.post(`${urlAPIVideoLlamada}/CallAPI/ObtenerLinks`, JSON.stringify(infoSala), { headers: { 'Content-Type': 'application/json', auth: `${tokenVideoLlamada}` } })
      .then(respVideo => {
        if(!typeSheduling.module !== "Telemedicina"){
          objSheduling.linkUsuario = respVideo.data.urlPart1;
          objSheduling.linkAsesor = respVideo.data.urlPart2;
          objSheduling.rutaAgenda = routeSchedule;
        }
        //console.log("objSheduling", objSheduling)
        Axios.post(`${urlAPI}/agendamientos/${actionAgenda}`, JSON.stringify(objSheduling), { headers: { 'Content-Type': 'application/json', Authorization: getToken() } })
          .then(success => {
            setLoaderShow(false);
            if (success.data.codigo === 0) {
              if(actionAgenda === "AgregarAgenda") {
                objSheduling.idAgenda = success.data.data.idAgenda;
              }
              setShedulingInfo({ ...shedulingInfo, id: objSheduling.idAgenda, link: objSheduling.linkUsuario });
              if(type === "2047"){
                Axios.get(`${urlAPI}/agendamientos/MensajeAgendarTelemedicina/${objSheduling.idAgenda}?metodo=${metodo}`, { headers: { 'Content-Type': 'application/json', Authorization: getToken() } });
              }else{
                Axios.get(`${urlAPI}/agendamientos/MensajeAgendar/${objSheduling.idAgenda}?metodo=${metodo}&linkAsesor=${objSheduling.linkAsesor}&linkUsuario=${objSheduling.linkUsuario}&rutaAgenda=${objSheduling.rutaAgenda}`, { headers: { 'Content-Type': 'application/json', Authorization: getToken() } });
              }

              setStep('new');
            }
          }).catch(error => {
            //console.log(error);
            setLoaderShow(false);
          });
      }).catch(error => {
        //console.log(error);
        setLoaderShow(false);
      });
    }
  }
  const handleDeleteSheduling = () => {
    const logUserList = [];
    logUserList.push(addLog('Validación Servicio agendamiento', 'Cancelar agendamiento', 'Home In', 'Exitoso'));
    addLogList(logUserList);
    Swal.fire({
      title: `<span class="shedulingDelete">${typeSheduling.alertCancelTitle}</span>`,
      text: typeSheduling.alertCancelText,
      padding: '23px 10px 23px 10px',
      width: 400,
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btnDeleteYes mb-4',
        cancelButton: 'btnDeleteNo mb-4',
        content: 'colorText',
      },
      cancelButtonColor: '#CF141B',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        setLoaderShow(true);
        Axios.post(`${urlAPI}/agendamientos/DeleteAgenda/${shedulingInfo.id}`, null, { headers: { 'Content-Type': 'application/json', Authorization: getToken() } })
          .then(deleteShe => {
            if (deleteShe.data.codigo === 0) {
              if(type === "2047"){
                Axios.get(`${urlAPI}/agendamientos/MensajeAgendarTelemedicina/${shedulingInfo.id}?metodo=${"Eliminar"}`, { headers: { 'Content-Type': 'application/json', Authorization: getToken() } });
              }else{
                Axios.get(`${urlAPI}/agendamientos/MensajeAgendar/${shedulingInfo.id}?metodo=${"Eliminar"}&linkAsesor=${shedulingInfo.linkAsesor}&linkUsuario=${shedulingInfo.linkUsuario}&rutaAgenda=${shedulingInfo.rutaAgenda}`, { headers: { 'Content-Type': 'application/json', Authorization: getToken() } });
              }
              setStepCount(1);
              setStep('delete');
              setShedulingInfo(defaultShedulingInfo);
              setLoaderShow(false);
            }
          })
          .catch(error => {
            //console.log(error);
            setLoaderShow(false);
          });
      }
    })
  }
  const handleValidator = (value) => {
    setShedulingInfo({...shedulingInfo, infoContact:value})

      if (!regexValidacionMail.test(value)) {
        setStateInput(true);
      } else {
        setStateInput(false);
      }
  }
  const volverRedirect = () => {
    switch (module) {
      case 'HojaVida':
        propsP.history.push('/s/desempleo/mejorar-hoja-de-vida');
        break;
      case 'HojaVida-1':
        propsP.history.push('/s/desempleo/mejorar-hoja-de-vida');
        break;
      case 'Entrevista':
        propsP.history.push('/s/desempleo/preparar-entrevistas');
        break;
      case 'Legal':
        propsP.history.push('/s/desempleo/asesoria-legal');
        break;
      case 'Branding':
        propsP.history.push('/ITT/auth/branding-digital/agenda');
        break;
      case 'Legal ITT':
        propsP.history.push('/ITT/auth/tu-asesoria-legal');
        break;
      case 'Seguridad':
        propsP.history.push('/fraude/auth/seguridad-informatica');
        break;
      case 'Telemedicina':
        propsP.history.push('/auth/inicio');
        break;
      case 'Orientacion':
        propsP.history.push('/auth/inicio');
        break;
      default:
        //console.log('Error')
        break;
    }
  }

  useEffect(() => {

    if (stepCount === 1) {
      setStartDate(null);
      try {
        document.getElementById('cardContentSheduling').classList.remove('widthCardSheduling');
        $('#divSheduling').addClass('col-lg-11').removeClass('col-lg-8');
        if (typeSheduling.module == 'Entrevista') {
          $('#fechaHoraText').css('marginLeft', '5.8rem')
        } else {
          $('#fechaHoraText').css('marginLeft', '3.8rem')
        }
        $('#textoBajo').removeClass('pt-5').addClass('pt-0');
        $('#hourSheduling').addClass('text-center').removeClass('zonaHorLeft');
        //$('#hourSheduling').addClass(['col-sm','text-center']).removeClass('col-9');
      } catch (error) {
      }
    } else if (stepCount === 2) {
      $('#textoBajo').removeClass('pt-0').addClass('pt-5');
      $('#divSheduling').removeClass('col-lg-11').addClass('col-lg-8');
      if (typeSheduling.module == 'Entrevista') {
        $('#fechaHoraText').css('marginLeft', '0px')
      } else {
        $('#fechaHoraText').css('marginLeft', '0px')
      }
      setShedulingInfo({ ...shedulingInfo, infoContact: '' });
      document.getElementById('cardContentSheduling').classList.add('widthCardSheduling');
      $('.btnHora').removeClass('btnHourSelect');
      // $('#confirm').addClass('d-none');
      $('#hourSheduling').addClass('zonaHorLeft').removeClass('text-center');
      //$('#hourSheduling').removeClass(['col-10','text-center']).addClass('col-9');

    }
  }, [stepCount]);
  useEffect(() => {
    if (module === 'Seguridad') {
      setRouteSchedule(url+"/auth/fraude/seguridad-informatica/agenda")
      propsP.history.push(step === 'info' ? '/fraude/auth/seguridad-informatica/agenda' : step === 'agenda' || step === 'edit' ? '/fraude/auth/seguridad-informatica/agenda/1' : step === 'new' ? '/fraude/auth/seguridad-informatica/agenda/1/exito' : step === 'delete' && '/fraude/auth/seguridad-informatica/agenda/1/cancelacion');
    }
    if (module === 'Ayuda') {
      setRouteSchedule(url+"/auth/ayuda/agenda-ayuda")
      propsP.history.push(step === 'agenda' || step === 'edit' ? '/auth/ayuda/agenda-ayuda' : step === 'new' ? '/auth/ayuda/agenda-ayuda/exito' : step === 'delete' ? '/auth/ayuda/agenda-ayuda/cancelacion' : step === 'check' && '/auth/ayuda/agenda-ayuda-check');
    }
    if (module === "Telemedicina") {
      setRouteSchedule(url+"/auth/medico/agenda")
      propsP.history.push(step === 'info' ? '/s/medico/agenda' : step === 'agenda' || step === 'edit' ? '/s/medico/agenda' : step === 'new' ? '/s/medico/agenda-exitosa' : step === 'delete' && '/s/medico/agenda-cancelada');
    }
    if (module === "Orientacion") {
      setRouteSchedule(url+"/s/orientacion/agenda")
      propsP.history.push(step === 'info' ? '/s/orientacion/agenda' : step === 'agenda' || step === 'edit' ? '/s/orientacion/agenda' : step === 'new' ? '/s/orientacion/agenda-exitosa' : step === 'delete' && '/s/orientacion/agenda-cancelada');
    }
    if (module === "HojaVida") {
      setRouteSchedule(url+"/auth/orientacion/agenda")
      propsP.history.push(step === 'agenda' || step === 'edit' ? '/s/desempleo/mejorar-hoja-de-vida/crear-hv/agenda' : step === 'new' ? '/s/desempleo/mejorar-hoja-de-vida/crear-hv/agenda-exitosa' : step === 'delete' && '/s/desempleo/mejorar-hoja-de-vida/crear-hv/agenda-cancelada');
    }
    if (module === "Entrevista") {
      setRouteSchedule(url+"/s/desempleo/preparar-entrevistas/agenda")
      propsP.history.push(step === 'info' ? '/s/desempleo/preparar-entrevistas/inicio' : step === 'agenda' || step === 'edit' ? '/s/desempleo/preparar-entrevistas/agenda' : step === 'new' ? '/s/desempleo/preparar-entrevistas/agenda-exitosa' : step === 'delete' && '/s/desempleo/preparar-entrevistas/agenda-cancelada');
    }
    if (module === "Legal") {
      setRouteSchedule(url+"/s/desempleo/asesoria-legal-servicio/agenda")
      propsP.history.push(step === 'info' ? '/s/desempleo/asesoria-legal' : step === 'agenda' || step === 'edit' ? '/s/desempleo/asesoria-legal-servicio/agenda' : step === 'new' ? '/s/desempleo/asesoria-legal-servicio/ok' : step === 'delete' && '/s/desempleo/asesoria-legal-servicio/agenda/cancelada');
    }
    if (module === "Legal ITT") {
      setRouteSchedule(url+"/ITT/auth/tu-asesoria-legal/agenda")
      propsP.history.push(step === 'info' ? '/ITT/auth/tu-asesoria-legal/agenda' : step === 'agenda' ? '/ITT/auth/tu-asesoria-legal/agenda/1' : step === 'edit' ? '/ITT/auth/tu-asesoria-legal/agenda/1' : step === 'new' ? '/ITT/auth/tu-asesoria-legal/agenda/exito' : step === 'delete' && '/ITT/auth/tu-asesoria-legal/agenda/cancelacion');
    }
    if (module === "Branding") {
      setRouteSchedule(url+"/ITT/auth/branding-digital/agenda")
      propsP.history.push(step === 'info' ? '/ITT/auth/branding-digital/agenda' : step === 'agenda' || step === 'edit' ? '/ITT/auth/branding-digital/agenda/2' : step === 'new' ? '/ITT/auth/branding-digital/agenda/exito' : step === 'delete' && '/ITT/auth/branding-digital/agenda/cancelacion');
    }
    if (module === "HojaVida-1") {
      setRouteSchedule(url+"/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda")
      propsP.history.push(step === 'info' ? '/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda-eleccion-hdv' : step === 'agenda' || step === 'edit' ? '/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda' : step === 'new' ? '/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda-exitosa' : step === 'delete' && '/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda-cancelada');
    }
    if (step === 'edit') {
      
      setShedulingInfo({
        ...shedulingInfo,
        date: null,
        hour: '',
        typeContact: '',
        infoContact: '',
        link:''
      });
      setStepCount(1);
    }
    if (step === 'info') {
      setShedulingInfo(defaultShedulingInfo);
    }
  }, [step]);

  return (
    <React.Fragment>
      {loaderShow && (<Loader show={loaderShow} />)}
      <div id="contenedorScheduling" className={module === "Legal" ? "paddingSheduling" : module === "Legal ITT" ? "paddingShedulingItt" : "contenerPaddin"}>
        {(step === 'agenda' || step === 'edit') && (
          <div className="container">
            <div className="row">
              {typeSheduling.tittleSheduling !== '' && (<h4 className="col-12 resp-agen pl-0" style={{ marginBottom: "6px", marginTop: "43px", color: "#474747", fontFamily: "Inter-Bold", fontSize: "24px" }}>{typeSheduling.tittleSheduling}</h4>)}
              {startDate === null && (<p className="textParagraph col-12" style={{ marginBottom: '36px' }}>{typeSheduling?.paragraphAdiccional}</p>)}
              <p id="textoBajo" className="textParagraph col-12 pl-0" style={{ marginBottom: '36px', marginTop: "-14px" }}>{typeSheduling.paragraph}</p>
              <Card className="col-12 col-xl-4 mb-2 mr-xl-2">
                <Card.Body>
                  <span className="textParagraphN" style={{ color: '#666666', display: 'block', paddingTop: '10px' }}>{infoUser.nombres} {infoUser.apellidoPaterno}</span>
                  {typeSheduling.typeTextSheduling}
                  <div style={{ marginTop: '29px' }}>
                    {startDate !== null && (
                      <span className="spanCalendar">
                        <img src={iconCalendar} alt="icono Calendario" style={{ marginBottom: "6px", marginRight: "14px", marginLeft: "4px" }} />
                        <span style={{ textTransform: "capitalize" }}>{startDate.toLocaleDateString('es-CO', { month: 'long' })}</span> {startDate.toLocaleDateString('es-CO', { day: '2-digit' })} de {startDate.toLocaleDateString('es-CO', { year: 'numeric' })}
                      </span>
                    )}
                    {stepCount === 3 && (
                      <React.Fragment>
                        <span className="spanCalendar"><img src={iconClock} alt="icono Reloj" style={{ marginBottom: "3px", marginRight: "14px", marginLeft: "4px" }} />{horas}</span>
                        {contactSelect.iconSelect !== '' && (<span className="spanCalendar"><FontAwesomeIcon icon={contactSelect.faIcon} style={{ fontSize: "20px", marginLeft: "4px", marginRight: "11px", marginBottom: "-2px", color: "#868B96" }} />{shedulingInfo.infoContact}</span>)}
                      </React.Fragment>
                    )}
                  </div>
                </Card.Body>
              </Card>
              <Card style={{ borderColor: '#D2D2D2' }} className="col-sm-12 col-xl-7 mb-2 p-0" id="cardContentSheduling" >
                {(stepCount === 2 || stepCount === 3) && (
                  <Card.Header >
                    <button className={typeSheduling?.classVolver} onClick={() => { setStepCount(stepCount - 1) }}><FontAwesomeIcon icon={faAngleLeft} style={{ marginRight: "12px", marginBottom: "-1px", fontSize: "17px" }} />Volver</button>
                  </Card.Header>
                )}
                <Card.Body className="card-bodyS">
                  {(stepCount === 1 || stepCount === 2) && (
                    <div className="row" >
                      <div className="col-sm-12 col-lg-12">
                        <span className={"textParagraphA hourDate" + typeSheduling?.classTitleSheduling} id="fechaHoraText">
                          Seleccione fecha  y hora
                        </span>
                      </div>
                      <div id="divSheduling" className="col-sm-12 col-lg-11" style={{ paddingBottom: '7%', textAlign: 'center', paddingTop: "18px", paddingLeft:'0px' }}>
                        <DatePicker renderCustomHeader={({ date, changeYear, changeMonth, }) => (
                          <div className="row justify-content-center m-2 calenMobilContent">
                            <select className="selectCalendar calenMobil col-sm-5" style={{cursor: 'pointer' }}
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }>
                              {months.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <select className="selectCalendar calenMobil col-sm-5" style={{cursor: 'pointer' }} value={getYear2(date)}
                              onChange={({ target: { value } }) => changeYear(value)}>
                              {years.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                          formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
                          locate="es"
                          calendarStartDay={1}
                          selected={startDate}
                          ppreSelection={startDate}
                          calendarClassName="backCalendar"
                          onChange={() => { }}
                          onSelect={date => handleDate(date)}
                          inline
                          minDate={currenDate}
                        />
                        <div id="hourSheduling" className="col-sm-12 mb-4 text-center" style={{ paddingTop: "2%", }}>
                          <span style={{ color: "#666666", fontSize: "16px", fontFamily: 'Inter-regular' }}><img src={world} className="tamanioMundo textParagraph" alt="mundoIcono" />Zona horaria GTM Bogotá: {currenDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}<FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: "8px", color: "#BABABA", display: module === "Ayuda" ? "none" : "" }} /></span>
                        </div>
                      </div>
                      {stepCount === 2 && (
                        <div className="col-sm-12 col-lg-4" id="divShedulingHour" style={{paddingRight: "0px", paddingLeft: "0px" }}>
                          {startDate != null && (<span className="datePipe"><span style={{ textTransform: "capitalize" }}>{startDate.toLocaleDateString('es-CO', { weekday: 'long' })}</span>, {startDate.toLocaleDateString('es-CO', { month: 'short' }).replace('.', '')} {startDate.toLocaleDateString('es-CO', { day: 'numeric' })} </span>)}
                          <div id="hoursBtn" className="scrollHour" style={{ height: "320px", overflowY: "scroll" }}>
                            {hourDay.map((e, i) => {
                              return (<button key={i} id={e.valueHour} className="btnHora" style={{ marginTop: "10px" }} onClick={() => { handleTime(e.valueHour); setHoras(e.titleHour) }}>{e.titleHour}</button>);
                            })}
                            {hourDay.length > 0 && (<button id="confirm" className="btnConfirmSelect d-none" onClick={() => { setStepCount(3); SetLogTab(); }}>Confirmar</button>)}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {stepCount === 3 && (

                    <div className="row" style={{ paddingTop: "20px", paddingLeft: "20px" }}>

                      {(type === "2047") ?
                        <React.Fragment>
                          <div className="col-sm-12">
                            <span className="textParagraph" >
                              Completa la siguiente información para poder finalizar el agendamiento.
                            </span>
                          </div>
                          <div className="col-sm-12 mt-4">
                            <label className='scheMedic'>Correo electrónico</label>
                            <input type="text" className="form-control inputContact2 scheMedic" name="mailContact" onChange={(e) => { handleFormContact(e) }} value={formContact.mailContact} />
                            {errorsFormContact.errorEmail !== '' && (<span className="errorMessageForm" style={{ display: 'block' }}>{errorsFormContact.errorEmail}</span>)}
                          </div>
                          <div className="col-sm-12 mt-4">
                            <label className='scheMedic'>Número de celular</label>
                            <input type="text" className="form-control inputContact2 scheMedic" name="numberContact" onChange={(e) => { handleFormContact(e) }} value={formContact.numberContact} />
                            {errorsFormContact.errorNumber !== '' && (<span className="errorMessageForm" style={{ display: 'block' }}>{errorsFormContact.errorNumber}</span>)}
                          </div>
                          <div className="col-sm-11 mt-4 text-left">
                            <div
                              className="checkbox-terminos"
                              style={{ marginTop: "auto" }}
                            >
                              <div
                                className="widthCheck"
                                style={{
                                  display: "inline-block",
                                  // width: "20%",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  id="checkbox-terminos"
                                  name="checkConctact"
                                  checked={formContact.checkConctact}
                                  onChange={(e) => {
                                    handleFormContact(e);
                                  }}
                                />
                                <label
                                  for="checkbox-terminos"
                                  style={{ top: "4px" }}
                                ></label>
                              </div>
                              {
                                <div
                                  className="widthPoliticas"
                                  style={{
                                    display: "inline-block",
                                    // width: "80%",
                                    lineHeight: 1,
                                  }}
                                >
                                  <span
                                    className="textShedulingTerm"
                                    // style={{ width: "110%" }}
                                  >
                                    Acepto los{" "}
                                    <span style={{
                                        color: "#007EFE",
                                        textDecorationLine: 'underline'
                                      }}
                                    >
                                      <ModalTerms
                                        title="términos y condiciones"                                      
                                      />
                                    </span>
                                    y la {" "} 
                                    <span style={{
                                        color: "#007EFE",
                                        textDecorationLine: 'underline'
                                      }}
                                    >
                                      <ModalPolicies
                                      title="política de privacidad del servicio"                                     
                                      /> 
                                    </span>                                    
                                  </span>
                                </div>
                              }
                            </div>
                          </div>   

                          <div className="col-sm-10">
                            {showButton ? (
                              <button
                                className="btnAzul btnTeleM"
                                style={{
                                  width: "265px",
                                  marginTop: "26px",
                                  marginBottom: "60px",
                                }}
                                onClick={() => {
                                  handleSaveSheduling();
                                }}
                              >
                                Agendar revisión
                              </button>
                            ) : (
                              <button
                                style={{
                                  width: "265px",
                                  height: "50px",
                                  marginTop: "26px",
                                  marginBottom: "60px",
                                  border: "none",
                                  borderRadius: "4px",
                                  fontFamily: "Arimo-bold",
                                }}
                                id="secondButton"
                                type="button"
                                className="mt-4 btn-secondary btnTeleM"
                              >
                                Agendar revisión
                              </button>
                            )}
                          </div>                       
                          {/*
                          <div className="col-sm-10">                           
                              <button className="btnAzul btnTeleM" style={{ width: "265px", marginTop: "26px", marginBottom: "60px" }} onClick={() => { handleSaveSheduling() }}>

                                Agendar revisión
                              </button>                            
                          </div>
                          */}
                          
                        </React.Fragment> :
                        <React.Fragment>
                          <div className="col-12" style={{marginBottom:"33px"}}>
                              <span className="textParagraph" >
                                Tu consultoría se realizará a traves de una videollamada.
                              </span>
                          </div>
                          <div className="boxContact pl-3">
                            <button id="VideoLlamada" style={{'width':'auto'}} className="btnContact col-12" onLoad={() => { handleContact('VideoLlamada') }}>
                              <img height="30px" src={logoSocio} alt="icon videollamada" />
                            </button>
                          </div>
                          <span className="messageError pl-3 d-none">Debes elegir un medio de contacto</span>
                          <div className="col-sm-10" style={{marginTop:"44px"}}>
                            <span className="textParagraph" style={{lineHeight:"normal"}}> 
                              En el siguiente correo recibirás el link de tu consultoría personalizada.
                            </span>
                          </div>
                          <div className="col-sm-10 col-lg-11" style={{ marginTop: "27px" }}>
                              <label style={{fontFamily:'Inter-regular',fontSize:'14px',color:'#363636'}}>Correo electrónico</label>
                              <input  onChange={(e)=>{handleValidator(e.target.value)}} name="infoContact" value={shedulingInfo.infoContact} className="form-control inputContact" type={contactSelect.iconSelect==='VideoLlamada'?"text":"number"} placeholder="Ingresa dato de contacto"/>
                              <span className={`messageError ${stateInput ? 'd-block' : 'd-none'}`}>Correo electrónico no válido.</span>                            
                          </div>
                          <div className="col-sm-10 col-lg-11">
                            <button className={`btnShedulingAzul ${stateInput ? 'disabled' : ''}`} disabled={stateInput} style={{ width: "265px", marginTop: "26px", marginBottom: "60px" }} onClick={() => { handleSaveSheduling() }}>
                                Agendar consultoría{stateInput}
                            </button>
                          </div>
                          
                        </React.Fragment>
                      }
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        )}
        {(step.includes('new') || step.includes('delete') || step.includes('check')) && (
          <div className="row">
            {(step.includes('new') || step.includes('delete')) ? (
              <React.Fragment>
                {typeSheduling.tittleSheduling !== '' && (<h4 className="col-12 resp-agen pl-0" style={{ marginBottom: "6px", marginTop: "43px", marginLeft: '35px', color: "#474747", fontFamily: "Inter-Bold", fontSize: "24px" }}>{typeSheduling.tittleSheduling}</h4>)}
                {(module !== 'Ayuda' && (
                  <div onClick={() => volverRedirect()} className="d-flex manitoCol pb-4 mt-4 " style={{ marginLeft: '36px' }}>
                    <div className="volver mr-3"> <FontAwesomeIcon icon={faChevronLeft} /></div>
                    <span>Volver</span>
                  </div>
                ))}
                <Card id="shedulingNice" className="col-sm-12 col-lg-11 shedulingNice px-0 px-md-2">
                  <Card.Body className="boxAlert">
                    <TypeAlert contentAlert={step.includes('new') ? typeSheduling.messageSuccess : typeSheduling.messageCancel} view="2" repite={() => { module === "Orientacion" ? setStep('agenda') : setStep('agenda') }} />
                     {(step.includes('new')) &&(
                        <React.Fragment>
                        {(module === 'Telemedicina') ? (
                          <CardService view="sheduling" typeSheduling={type} contactSelect={contactSelect} shedulingInfo={shedulingInfo} edit={() => { setStep('edit') }} deleteS={() => { handleDeleteSheduling() }} />
                        ):(
                          <CardService view="shedulingVideollamada" typeSheduling={type} contactSelect={contactSelect} shedulingInfo={shedulingInfo} edit={() => { setStep('edit') }} deleteS={() => { handleDeleteSheduling() }} />
                        )}
                        </React.Fragment>
                      )}
                  </Card.Body>
                </Card>
              </React.Fragment>
            ) : (
              <>
                {
                  module == 'Orientacion' && (
                    <div class="mt-5 pl-3">
                      <p style={{ fontFamily: 'inter-bold', fontSize: '24px' }}>
                        Programa tu consulta
                      </p>
                    </div>
                  )
                }
                <Card id="shedulingNice" className="col-sm-12 col-lg-11 shedulingNice p-3 mb-5">
                  {module === 'Telemedicina'? (
                    <CardService view="sheduling" typeSheduling={module} contactSelect={contactSelect} shedulingInfo={shedulingInfo} edit={() => { setStep('edit') }} deleteS={() => { handleDeleteSheduling() }} />
                  ):(
                    <CardService view="shedulingVideollamada" typeSheduling={module} contactSelect={contactSelect} shedulingInfo={shedulingInfo} edit={() => { setStep('edit') }} deleteS={() => { handleDeleteSheduling() }} />
                  )}
                </Card>
              </>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
