import React from "react";
import Alert from "react-bootstrap/Alert";
import iconCheck from "../../assets/img/icon/icon-check-green-001.svg";
import iconCheckCircle from "../../assets/img/icon/icon-check-green-002.svg";
import  ArrowLeft from "../../assets/img/home-out/Arrow-Derecho.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export default function TypeAlert(props){
    const {contentAlert,view,repite} = props;
    
    return(
        <React.Fragment>
            {view === "1" &&(
                <Alert variant={contentAlert.variant}>
                    {contentAlert.alertText}
                </Alert>
            )}
            {view === "2" &&(
                <React.Fragment>
                    <div className="col-12" style={{textAlign:"center"}}>
                        <img src={iconCheck} alt="iconCheck"/>
                        <br/>
                        <span className="textCheck">{contentAlert.checkText}</span>
                    </div>
                    <div className="col-12">
                        <Alert bsPrefix={contentAlert.class}  variant={contentAlert.variant}>
                            {contentAlert.alertText}
                        </Alert>
                    </div>
                    {contentAlert.module === "Desempleo"&&(
                        <div className="col-12 text-center mt-5 mb-3">
                            <p style={{fontFamily:"OpenSans", fontSize:"16px", color:"#58595B"}}>{contentAlert?.textAdditional}</p>
                        </div>
                    )}
                    {contentAlert?.showButton &&(
                        <div className="col-12" style={{textAlign:"center"}}>
                            <button className="btnShedulingVerde deleteClass" style={{width:"265px",marginTop:"20px",marginBottom:"60px"}} onClick={()=>{repite()}}>
                                {contentAlert.showButtonText}
                            </button>
                        </div>
                    )}
                    {contentAlert.class.includes("alertWarning") &&(
                        <div className="col-12" style={{textAlign:"center"}}>
                            <button className="btnShedulingVerde deleteClass" style={{width:"265px",marginTop:"20px",marginBottom:"60px"}} onClick={()=>{repite()}}>
                                {contentAlert.textButton}
                            </button>
                        </div>
                    )}
                    {(contentAlert.class.includes('alertEcoWarning')||contentAlert.class.includes("alertDesempleo"))&&(
                        <div className="col-12" style={{textAlign:"center"}}>
                        <button className="btnShedulingCancel" style={{padding:'0 20px',height:"46px", marginTop:"20px",marginBottom:"60px", fontFamily:"OpenSans", fontSize:"20px"}} onClick={()=>{repite()}}>
                            {contentAlert.textButton}
                        </button>
                    </div>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}