import React, { useState } from "react";
//import BlockUi from "react-block-ui";
import { Modal, Row, Col } from "react-bootstrap";
import loaderSpinner from "../../assets/img/Loader-bdb.gif";
import "react-block-ui/style.css";

export default function Loader(props) {
  const [show] = useState(props.show);
  return (
    <>
      {show && (
        <div>
          <Modal show={show} centered>
            <Modal.Body className="p-5">
              <h4 style={{ textAlign: "center" }}>
                Por favor espere, cargando...
              </h4>
              <Row>
                <Col></Col>
                <Col className="text-center">
                  <img
                    src={loaderSpinner}
                    style={{ width: 90, height: 90 }}
                    alt="spinner double ring"
                  />
                </Col>
                <Col></Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
}
