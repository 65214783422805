import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import copy from "../../assets/img/scotia-vida-img-028.svg";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { url, protocol } from "../../utils/config";

export default function ModalEmail(props) {
  const {showM,clickSave,onChange,value,clickClose,close} = props;
    function defaultFormValue(){
    return {
        correoElectronico:"",
    };
   }
   const [show, setShow] = useState(showM);
  const [form, setForm] = useState(defaultFormValue);
  const[disabled, setDisabled] = useState(true);
  const [background, setBackgraund] = useState("#CFCFCF");
  const [border, setBorder] = useState ("#CFCFCF");
  const [color, setColor] = useState ("#ffffff");
  const [resul, setResult] = useState (false);
  const [display, setDisplay ] = useState(true);
  const [visibility, setVisibility] = useState(false);
  const dobleEnter = (
    <>
      <br />
      <br />
    </>
  );
    
   const handleEmail = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  
   function validarCorreo(e){
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //console.log(re.test(e));
    setResult(re.test(e));
  }
  const validar = e => {
    if(value.correoElectronico.length ===0){
      validarCorreo(e.target.value);
      setDisabled(true);
      setBackgraund("#CFCFCF");
      setBorder("#CFCFCF");
      setColor("#FFFFFF");
    }
    else{
       validarCorreo(e.target.value);
      if(resul===false){
        setDisabled(false);
        setBackgraund("#CF141B");
        setBorder("#CF141B");
        setColor("#FFFFFF");
      }else{
        setDisabled(false);
        setBackgraund("#70A134");
        setBorder("#70A134");
        setColor("#FFFFFF");
      }
    }
  };

  const enviarDatos =() =>{
    setDisplay(false);
    clickSave();
    return;
  }
  
  
  return (

    <>
      <Modal
         show={show}
         onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
          <Modal.Header>
              <div >
                <button
                id="btnCierraModal"
                className="swal2-close-modal"
                onClick={close}
                style={{marginTop: "15px",marginRight: "57px"}}
                ><p>×</p></button>
            </div>
          </Modal.Header>
        <Modal.Body style={{width:"93%"}}>
            <div className="row"> 
            <div className="col-12" style={{padding: "29px 41px 36px"}}>
                <h4 className="mt-2" style={{color:"#323B4E",fontFamily:"Inter-bold", }}>Autorización informada para datos de menores de edad</h4>
                <p className="mt-5 styleLyrics-email">
                De acuerdo con la Ley Estatutaria 1581 de 2012 de Protección de Datos y con el Decreto 1377 de 
                2013, es necesaria la autorización expresa por parte del padre o tutor.
                {dobleEnter}
                Para tal efecto, puede compartir el link de autorización vía correo electrónico, ingresando el correo 
                del padre o tutor del menor.
                </p>
                <p style={{fontSize:"14px", color:"#363636", fontFamily:"Helvetica",marginBottom: '5px'}}>Correo electrónico</p>
                <div className="row">
                    <div className="col-lg-8 col-md-10 col-sm-12 p-0">
                        <input type="text" className="form-control" placeholder="Ingrese el correo electrónico" 
                        style={{width:"100%", height:"42px",
                        marginBottom:"10px",}}
                        value={value.correoElectronico}
                        name="correoElectronico"
                        onChange={(e)=>{onChange(e)}}
                        onKeyUp={(e) => {validar(e)}}
                        />
                    </div>
                    <div className="col-lg-4 col-md-10 col-sm-12">
                        <button className="btnMain" id="enviar" 
                        style={{
                            borderRadius:"4px",
                            width:"100%", 
                            margin:"0px",
                            display: display === true? "block" :"none",
                            backgroundColor: background, 
                            borderColor: border,
                            color:color
                        }}  
                        disabled={disabled}
                        onClick={()=>{enviarDatos()}}
                        >
                            Enviar
                        </button>
                        <button className="btnMain" id="enviado"
                        style={{
                            bordeRadius: "4px",
                            width:"100%", 
                            margin:"0px", 
                            display: display === false? "block" :"none",
                            background: "#73C056",
                            borderColor: "#73C056",
                            borderRadius: '5px'
                        }}  
                        >
                            Enviado
                        </button>
                    </div>
                </div>
                <p className="mt-4 styleLyrics">
                En caso de no poder enviar el correo electrónico, lo invitamos a copiar la url o link del formulario de 
                autorización ,que se encuentra a continuación.
                </p>
                <div className="row mt-4">
                    <div className="col-lg-8 col-md-10 col-sm-12 pl-0" >
                      {url==="https://vivesegura.com/autorizacion-menores"?(<p className="styleLyrics">https://vivesegura.com/autorizacion-menores</p>)
                      :(
                      <p className="styleLyrics">https://vivesegura.com/autorizacion-menores</p>
                      )}
                      
                    </div>
                    <div className="col-lg-3 col-md-10 col-sm-12 pl-0"  style={{ display: display===true? "block" :"none",}}>
                       {url==="pre.segurotesirve.com"?(
                       <CopyToClipboard text="https://vivesegura.com/autorizacion-menores">
                          <a style={{cursor: "pointer"}}><img src={copy} alt="copy" onClick={()=>{setVisibility(true)}} onBlur={()=>{setVisibility(false)}}/></a>
                       </CopyToClipboard>)
                      :(
                        <CopyToClipboard text="https://https://vivesegura.com/autorizacion-menores">
                          <a style={{cursor: "pointer"}}><img src={copy} alt="copy"  onClick={()=>{setVisibility(true)}}/></a>
                        </CopyToClipboard>
                      )
                      }
                        {/* <img src={copy} alt="Copy"/> */}
                        <p className="styleLyrics" style={{ marginTop: "-21px",marginLeft: "20px", whiteSpace: "pre"}}>Copiar datos de autorización</p>
                    </div>
                </div>
                <div className="col-12 text-center mt-4">
                    <button className="btnAzul" style={{borderRadius:"5px", width: "202px"}} onClick={clickClose} >Continuar</button>
                </div>
            </div>
            </div>
            <div className="row portpapers" style={{position:"absolute", bottom:"-3em",width:"100%",visibility:visibility===false?"hidden":"visible" }}>
              <div className="col-12" style={{background:"#4D4D4D", borderRadius:"6px"}}>
                  <span className="ml-5" style={{color:"#ffffff",lineHeight: 2.5}}>
                    Datos de autorización de menores copiados en el portapapeles
                  </span>
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
}