import React,{useState} from 'react';
import Modal from "react-bootstrap/Modal";

export default function ModalAutoMe(props){
    const { showM,infoMenor,clickSave,close } = props;
    const [show, setShow] = useState(showM);
    const handleClose = () => {setShow(false);}
//  const handleShow = () => setShow(true);
    const dobleEnter = (
        <>
          <br />
          <br />
        </>
    );
    const enterText = (
      <>
        <br />
      </>
    );
    //console.log(show);
    return( 
    <>
    {/* <span style={{cursor:"pointer"}} onClick={handleShow} className="hover mr-1 text-center disclamersFooter">
           Title
    </span> */}
    
    <Modal
      show={show}
      onHide={close}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
    >
        <Modal.Header>
        <div className="btnCierraModalPrincipal">
          <button id="btnCierraModal" 
          type="button" 
          className="swal2-close-modal btnModalAutoMeno" 
          style={{marginRight: "15px"}} 
          onClick={close}>
             <p style={{fontWeight:"bold"}}>×</p>
          </button>
        </div>
        </Modal.Header>
      <Modal.Body style={{width:"auto"}}>
          <div className="row"> 
          <div className="pt-3 marginModalAutoMeno" >
              <h4 style={{marginLeft:'34px',fontSize: "22px", color:"#323B4E",fontFamily:"Inter-Bold"}}>Autorización informada para datos de menores de edad</h4>
              <div className="bod-modal-bene" style={{ height:'381px', overflowY:'auto'}}>
              <p className="mt-5 styleLyrics mr-5" style={{ marginLeft: "35px", fontFamily:"Inter-regular"}}>
                De acuerdo con la Ley Estatutaria 1581 de 2012 de Protección de Datos y con el Decreto 1377 de 2013 y demás normas concordantes, <strong>al dar click en “aceptar” autorizo a CARDIF COLOMBIA SEGUROS GENERALES S.A  “CARDIF”, </strong> en calidad de padre, representante o tutor legal del menor:<strong> {infoMenor.nombres} {infoMenor.apellidos} </strong>, identificado con T.I. No.: <strong>{infoMenor.identificacion}</strong>, 
                que los datos incluidos en este documento para el ingreso como beneficiario del ecosistema de servicios, sean incorporados a una base de datos responsabilidad de CARDIF COLOMBIA SEGUROS GENERALES S.A., siendo tratados con la finalidad de cumplir los propósitos y fines de la prestación del servicio, para la cuales sus datos serán tratados, así: 
                i. Adelantar la gestión administrativa que CARDIF COLOMBIA SEGUROS GENERALES S.A. requiera respecto de sus clientes, aliados, proveedores, empleados y demás grupos de interés; 
                ii. Desarrollar estudios internos sobre los intereses de los Titulares, con el objetivo de comprender mejor las necesidades e intereses de los Titulares, para brindar un mejor servicio; 
                iii. Enviar correspondencia, correos electrónicos, contactar telefónicamente o por cualquier medio disponible a los Titulares, en desarrollo de actividades publicitarias, promocionales y de mercadeo enfocados a su actividad; 
                iv. Compartirlos o enviarlos a terceros con quienes CARDIF COLOMBIA SEGUROS GENERALES S.A. realice alianzas o celebre contratos, para fines comerciales relacionados con la ejecución de las actividades comprendidas dentro de su objeto social; y Llevar a cabo Transferencia y Trasmisión de los Datos Personales a terceros con quienes realice alianzas relacionadas con su objeto social, contrate estudios o les encargue el tratamiento de datos. 
                v. Estudiar internamente los hábitos de consumo de los bienes y servicios que ofrece y presta CARDIF COLOMBIA SEGUROS GENERALES S.A.  
                {dobleEnter}
                DATOS PERSONALES QUE UTILIZAMOS 
                { enterText}
                En cumplimiento de la Ley 1581 de 2012, se advierte al titular de la información que puede ejercer los derechos de acceso, corrección, supresión, revocación o reclamo por infracción sobre sus datos, mediante escrito dirigido a CARDIF COLOMBIA SEGUROS GENERALES  S.A.  a  la  dirección  de  correo  electrónico <a> atencionalcliente@cardif.com.co </a>, indicando en el asunto el derecho que desea ejercer.
                {enterText}  
                <ul>
                  <li>Datos de identificación </li>
                  <li>Datos de contacto</li>
                  <li>Fecha de nacimiento</li>
                  <li>Nombre completo</li>
                  <li>Correo electrónico</li>
                  <li>Edad</li>
                  <li>Teléfono fijo o teléfono celular</li>
                  <li>Lugar de nacimiento</li>
                </ul>
                {dobleEnter}
                 En cumplimiento de la Ley 1581 de 2012 se advierte al titular de la información que puede ejercitar los derechos de acceso, corrección, supresión, revocación o reclamo por infracción sobre sus datos, mediante escrito dirigido a CARDIF COLOMBIA SEGUROS GENERALES S.A., a la dirección de correo electrónico atencionalcliente@cardif.com.co, indicando en el asunto el derecho que desea ejercitar.  
              </p>
            </div>
            </div>
              <div className="col-12 text-center mt-3 mb-5">
                  <button className="btnAzul" style={{borderRadius:"5px", width: "202px",backgroundColor: '#323B4E'}} onClick={clickSave}>Aceptar</button>
              </div>
          </div>
      </Modal.Body>
    </Modal>
  </>
    );
}