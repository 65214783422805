/* eslint-disable jsx-a11y/alt-text */
import React, { createRef, useState } from "react";
import "./css/SchedulingTelemedicineSteps.css";
import "./css/SchedulingHistoric.css";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "../../hooks/useForm";
import { defaultFormPasswordHistory } from "../../interfaces/interfacesGenerales";
import { InputComponentPassword } from "../InputComponents/InputComponentPassword";
import image__pdf from "../../assets/img/webp/scheduling/image_incapacidad_pdf.webp";
import image__formula from "../../assets/img/webp/scheduling/image_formula_1.webp";
import { useEffect } from "react";
import { useAxios } from "../../hooks/useAxios";
import iconCheck from "../../assets/img/ic-check-green_2.svg";
import Swal from 'sweetalert2';
import {
  encryptData,
  encryptDecrypt,
  getToken,
} from "../../utils/validations";
import ReCAPTCHA from "react-google-recaptcha";
import sha256 from "sha256";
import { captchaKey, urlAPI } from "../../utils/config";
import Loader from "../loader/Loader";
import Axios from "axios";
import ModalGenric from "../modal/ModalGenric";
import { infoSheduling } from "../../services/ParamPhantom";

const SchedulingHistoric = ({typeHistory}) => {

  const type = typeHistory === 1 ? 'history-custom':'history';
  const [Step1, setStep1] = useState(false);
  const [Step2] = useState(false);
  const [Step3, setStep3] = useState(false);
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [showModalRecoveryPassword, setShowModalRecoveryPassword] =
    useState({
      step1: false,
      step2: false,
      step3: false
    });
  const [showModalPasswordFormula, setShowModalPasswordFormula] =
    useState(true);
  const [showModalPasswordHistorial, setShowModalPasswordHistorial] =
    useState(true);
  const { onChange, clave, claveReocvery, CorreoReocvery, claveRepit, CodeReocvery } = useForm(
    defaultFormPasswordHistory
  );
  const [stateInput] = useState(false);
  const [typeField] = useState(false);
  const recaptchaRef = createRef();
  // const [IncapaCidadPDF, setIncapaCidadPDF] = useState(false);
  // const [HistoraClinicaPDF, setHistoraClinicaPDF] = useState(false);
  // const [DataHistorial, setDataHistorial] = useState([]);
  // const [DataInfo, setDataInfo] = useState([]);
  const [loaderShow, setLoaderShow] = useState(true);
  const [ShowPDF, setShowPDF] = useState(false);
  const [DataPDF, setDataPDF] = useState("");
  // const [userSession, setUserSession] = useState({});
  const [cargaData, setCargaData] = useState(false)
  const [textCargando, setTextCargando] = useState(false)
  const [Data, setData] = useState(infoSheduling)
  const [dataURL, setDataURL] = useState("");
  // const [dataChart, setDataChart] = useState([{}])
  const { callBack } = useAxios();

  const CreatePassword = () => {
    if (claveRepit === clave) {
      setLoaderShow(true);
      callBack(
        "post",
        "/agendamientos/CreatePassword",
        defaultFormPasswordHistory,
        getToken()
      )
        .then((succ) => {
          setShowModalPassword(false)
          if (succ.codigo === 202) {
            defaultFormPasswordHistory.clave = clave;
            // setCargaData(true)
            if (succ) {
              setLoaderShow(false);
              Swal.fire({
                  imageUrl:iconCheck,
                  width: '27em',
                  height: '20em',
                  text: `Contraseña creada correctamente.`,
                  customClass: {
                      confirmButton: 'class_telemedicina',
                  },
                  confirmButtonText: 'Aceptar',
                  showCloseButton: true
              }).then((result)=>{
                setStep3(true);
              });
          }
          }
        })
        .catch((e) => {
          setShowModalPassword(false)
          setLoaderShow(false);
        });
    }
  };

  const RecoveryPassword = (pass) => {
    if (claveRepit === claveReocvery) {
      defaultFormPasswordHistory.clave = pass;
      callBack(
        "post",
        "/agendamientos/RecoveryPassword",
        defaultFormPasswordHistory,
        getToken()
      )
        .then((succ) => {
          if (succ.codigo === 202) {
            setShowModalRecoveryPassword({ ...showModalRecoveryPassword, "step1": false, "step2": false, "step3": false });
            setShowModalPasswordHistorial(true);
          }
        })
        .catch((e) => {
          setLoaderShow(false);
        });
    }
  };

  const getCode = () => {
    if (!!CorreoReocvery) {
      callBack(
        "post",
        "/agendamientos/RecoveryPassword/getCode",
        defaultFormPasswordHistory,
        getToken()
      )
        .then((succ) => {
          if (succ.codigo === 202) {
            setShowModalRecoveryPassword({ ...showModalRecoveryPassword, "step1": false, "step2": true, "step3": false });
          }
        })
        .catch((e) => {
          setLoaderShow(false);
        });
    } else {
      document.getElementById('alertInvalidEmail').classList.add('d-block')
    }
  };

  const postCode = () => {
    if (!!CodeReocvery) {
      callBack(
        "post",
        "/agendamientos/RecoveryPassword/postCode",
        defaultFormPasswordHistory,
        getToken()
      )
        .then((succ) => {
          if (succ.codigo === 202) {
            setShowModalRecoveryPassword({ ...showModalRecoveryPassword, "step1": false, "step2": false, "step3": true });
          } else {
            document.getElementById('alertInvalidCode').classList.add('d-block')
          }
        })
        .catch((e) => {
          setLoaderShow(false);
        });
    }
    else {
      document.getElementById('alertInvalidCode').classList.add('d-block')
    }
  };

  const validateFields = (e, discriminator) => {
    if (discriminator === 5) {
      defaultFormPasswordHistory.correo = CorreoReocvery;
      getCode();
      return
    }
    if (discriminator === 6) {
      defaultFormPasswordHistory.code = CodeReocvery;
      postCode();
      return
    }
    let password = btoa(
      encryptDecrypt(sha256(clave), e.substring(e.length - 10, e.length))
    );
    defaultFormPasswordHistory.clave = password;
    defaultFormPasswordHistory.token = e;
    defaultFormPasswordHistory.fecha_actualizacion = new Date();
    if (discriminator === 1) {
      CreatePassword();
    } else if (discriminator === 4) {
      let passwordRecovery = btoa(
        encryptDecrypt(sha256(claveReocvery), e.substring(e.length - 10, e.length))
      );
      RecoveryPassword(passwordRecovery)
    } else {
      UnlockPDF(discriminator);
    }
  };
  const UnlockPDF = (id) => {
    callBack(
      "post",
      "/agendamientos/unLockPDF",
      defaultFormPasswordHistory,
      getToken()
    )
      .then((succ) => {
        if (succ.codigo === 202) {
          // recaptchaRef.current.reset();
          if (id === 2) {
            // setIncapaCidadPDF(true);
            setShowModalPasswordFormula(false);
          } else {
            // setHistoraClinicaPDF(true);
            setShowModalPasswordHistorial(false);
          }
          setCargaData(true)
          setStep1(true)
        } else {

          document.getElementById('alertInvalidPass').classList.add('d-block')
        }
      })
      .catch((e) => {
        setLoaderShow(false);
      });
  };

  const GetReporteResumen = () => {

    callBack("get", "/agendamientos/obtenerReporteMes", "", getToken())
      .then((succ) => {
        if (succ?.data.length > 0) {
          // setDataChart(succ.data)
        }
      })
      .catch((e) => {
        // //console.log(e)
      }).finally(() => {
        setLoaderShow(false);
      })

  }

  useEffect(() => {
    window.scroll(0, 0);

    setShowModalPassword(true)
    callBack("get", "/agendamientos/HistoryValidaSecurity", "", getToken())
      .then((succ) => {
        setShowModalPassword(false);
        setLoaderShow(false)

        if (succ.codigo !== 404) {

          setStep3(true);

        } else {
          setLoaderShow(false);
          setShowModalPassword(true);
        }
      })
      .catch((e) => {
        setLoaderShow(false);
        setShowModalPassword(false);
      });
  }, []);

  useEffect(() => {

    if (cargaData) {
      setTextCargando(true)
      setLoaderShow(true);
      Promise.allSettled([
        Axios.get(`${urlAPI}/Profile/InfoGeneral`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          },
        }),
        callBack("get", "/agendamientos/GetInfoHistory", "", getToken()),
        callBack("get", "/agendamientos/historyScheduling", "", getToken()),
      ]).then((results) => {
        setShowModalPasswordHistorial(false)
        GetReporteResumen()
        const [
          { status: statusHistoriaAgenda, value: rptaHistoriaAgenda },
        ] = results;

        if (statusHistoriaAgenda.includes("fulfilled")) {
          if (
            rptaHistoriaAgenda.codigo === 202 &&
            rptaHistoriaAgenda.data.length !== 0
          ) {
            let lista = [];
            for (let i = 0; i < rptaHistoriaAgenda.data.length; i++) {
              let jsondata = JSON.parse(rptaHistoriaAgenda.data[i]);
              lista.push(jsondata.resources.map((dato) => ({
                endToken: jsondata.enduserToken,
                datosPdf: dato,
              })));
            }
            // setDataHistorial(lista);
          }
        }
      })
    }
  }, [cargaData])

  useEffect(() => {
    Axios.get(`${urlAPI}/Profile/InfoGeneral`, { headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + getToken(), } })
      .then(scc => {
        setData({ ...Data, documento: scc.data.data["identificacion"], nombres: scc.data.data["nombres"] })
        Data.documento = scc.data.data["identificacion"];
        Data.nombres = scc.data.data["nombres"];
        var data = encryptData(Data)
        setDataURL(data)
      })
  }, [])

  return (
    <>
      {loaderShow && <Loader show={loaderShow} />}

      <Row className="w-100 p-0 m-0">
        <Modal show={showModalPassword} centered>
          <Card className="card__password">
            <Row className="w-100 p-0 m-0">
              <Col className="col-12 text-center container__header__password">
                <p className="form-label mb-0">
                  Crea tu contraseña
                </p>
              </Col>
              <Col className="col-12 text-center mt-4">
                <p className="title__scheduling__black font__size__20">
                  Contraseña
                </p>
              </Col>
              <Col className="col-12 container__form__password">
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Contraseña nueva</Form.Label>
                    <InputComponentPassword
                      idInput="ContrasenaRegistro"
                      valueInput={clave}
                      TypeInput="password"
                      BtnState={stateInput}
                      funtioninput={({ target }) => {
                        onChange(target.value, "clave");
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Confirma tu contraseña</Form.Label>
                    <InputComponentPassword
                      className="inputClass"
                      idInput="ContrasenaConfirm"
                      placeholder=""
                      aria-label="ver contraseña"
                      aria-describedby="basic-addon2"
                      valueInput={claveRepit}
                      BtnState={typeField}
                      TypeInput="password"
                      funtioninput={({ target }) => {
                        onChange(target.value, "claveRepit");
                      }}
                    />
                  </Form.Group>
                  <Row>
                    <Col className="text-center">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={captchaKey}
                        onChange={(e) => validateFields(e, 1)}
                        className="d-none"
                      />
                      <Button
                        bsPrefix="button__primary__"
                        onClick={() => {
                          recaptchaRef.current.execute();
                        }}
                      >
                        Continuar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Modal>
        <Modal show={ShowPDF} onHide={() => setShowPDF(false)} centered className="urgente" >
          <object
            border="1"
            data={"data:application/pdf;base64," + DataPDF}
            type="application/pdf"
            width="100%"
            height="800"
          ></object>
        </Modal>
        <ModalGenric Show={showModalRecoveryPassword.step3} setShow={() => setShowModalRecoveryPassword({ ...showModalRecoveryPassword, "step3": false })} title="Recuperar clave">
          <Col className="col-12 text-center mt-4">
            <p className="title__scheduling__black font__size__20">
              Contraseña
            </p>
          </Col>
          <Col className="col-12 container__form__password">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Contraseña nueva</Form.Label>
                <InputComponentPassword
                  idInput="ContrasenaRegistro"
                  valueInput={claveReocvery}
                  TypeInput="password"
                  BtnState={stateInput}
                  funtioninput={({ target }) => {
                    onChange(target.value, "claveReocvery");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirma tu contraseña</Form.Label>
                <InputComponentPassword
                  className="inputClass"
                  idInput="ContrasenaConfirm"
                  placeholder=""
                  aria-label="ver contraseña"
                  aria-describedby="basic-addon2"
                  valueInput={claveRepit}
                  BtnState={typeField}
                  TypeInput="password"
                  funtioninput={({ target }) => {
                    onChange(target.value, "claveRepit");
                  }}
                />
              </Form.Group>
              <Row>
                <Col className="text-center">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={captchaKey}
                    onChange={(e) => validateFields(e, 4)}
                    className="d-none"
                  />
                  <Button
                    bsPrefix="button__primary__"
                    onClick={() => {
                      recaptchaRef.current.execute();
                    }}
                  >
                    Continuar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </ModalGenric>
        <ModalGenric Show={showModalRecoveryPassword.step1} setShow={() => setShowModalRecoveryPassword({ ...showModalRecoveryPassword, "step1": false })} title="Recuperar clave">
          <Col className="col-12 text-center mt-4">
            <p className="title__scheduling__black font__size__20">
              Ingresa tu correo
            </p>
            <span>
              Ingresa el correo que tienes registrado y te enviaremos un código para que puedas recuperar tu contraseña de Telemedicina.
            </span>
          </Col>
          <Col className="col-12 container__form__password">
            <Form>
              <Form.Group className="mb-3">
                <Form.Control
                  id="EmailRecovery"
                  value={CorreoReocvery}
                  type="email"
                  placeholder="usuario@mail.com"
                  onChange={({ target }) => {
                    onChange(target.value, "CorreoReocvery");
                  }}
                />
                <Form.Control.Feedback type="invalid" id="alertInvalidCode">
                  ¡Correo invalido!
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col className="text-center">
                  <Button
                    bsPrefix="button__primary__"
                    onClick={(e) => validateFields(e, 5)}
                  >
                    Enviar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </ModalGenric>
        <ModalGenric Show={showModalRecoveryPassword.step2} setShow={() => setShowModalRecoveryPassword({ ...showModalRecoveryPassword, "step2": false })} title="Recuperar clave">
          <Col className="col-12 text-center mt-4">
            <p className="title__scheduling__black font__size__20">
              Ingresa el código
            </p>
            <span>
              Ingresa el código que te enviamos via correo para crear tu nueva contraseña.
            </span>
          </Col>
          <Col className="col-12 container__form__password">
            <Form>
              <Form.Group className="mb-3">
                <Form.Control
                  id="CodeRecovery"
                  value={CodeReocvery}
                  type="number"
                  placeholder="123456"
                  onChange={({ target }) => {
                    onChange(target.value, "CodeReocvery");
                  }}
                />
                <Form.Control.Feedback type="invalid" id="alertInvalidCode">
                  ¡Código incorrecto!
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col className="text-center">
                  <Button
                    bsPrefix="button__primary__"
                    onClick={(e) => validateFields(e, 6)}
                  >
                    Continuar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </ModalGenric>
        {Step1 && (
          <>
            <Row className="w-100 p-0 m-0 BigContainer__Mobile BigConteiner__Display">
              <Col className="col-12 padding__ceromoble">
                {
                  !showModalPasswordHistorial && (
                    <iframe
                      src={`${process.env.REACT_APP_URL_PHANTOM}/${type}/${dataURL}`}
                      width="100%"
                      height="100vh"
                      className="Container__Iframe"
                      style={{ border: 'none', height: '990px', width: '100%', overflow: 'hidden' }}
                      allow="camera *;microphone *"
                      title="phantom"
                      allowusermedia
                    />
                  )
                }
              </Col>
            </Row>
          </>
        )}
        {Step2 && (
          <>
            <Modal
              show={showModalPasswordFormula}
              onHide={() => setShowModalPasswordFormula(false)}
              centered
            >
              <Card className="card__password">
                <Row className="w-100 p-0 m-0">
                  <Col className="col-12 text-center container__header__password">
                    <p className="form-label mb-0" style={{ color: '#fff' }}>
                      Incapacidades y fórmulas médicas
                    </p>
                  </Col>
                  <Col className="col-12 text-center mt-4">
                    <p className="title__scheduling__black font__size__20 mb-3">
                      Ingresa tu clave
                    </p>
                  </Col>
                  <Col className="col-12 container__form__password">
                    <Form>
                      <Form.Group className="mb-3">
                        <InputComponentPassword
                          idInput="ContrasenaRegistro"
                          valueInput={clave}
                          TypeInput="password"
                          BtnState={stateInput}
                          funtioninput={({ target }) => {
                            onChange(target.value, "clave");
                          }}
                        />
                      </Form.Group>
                      <Row>
                        <Col className="text-center">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={captchaKey}
                            onChange={(e) => validateFields(e, 2)}
                            className="d-none"
                          />
                          <Button
                            bsPrefix="button__primary__"
                            onClick={() => {
                              recaptchaRef.current.execute();
                            }}
                          >
                            Continuar
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Modal>
            {false && (
              <Row className="w-100 p-0 m-0 justify-content-center">
                <Col className="col-11 col-md-8 text-center">
                  <img className="image__pdf" src={image__pdf}></img>
                </Col>
              </Row>
            )}
          </>
        )}
        {Step3 && (
          <>
            <Modal
              show={showModalPasswordHistorial}
              onHide={() => setShowModalPasswordHistorial(false)}
              centered
            >
              <Card className="card__password">
                <Row className="w-100 p-0 m-0">
                  <Col className="col-12 text-center container__header__password">
                    <p className="form-label mb-0" style={{ color: '#fff' }}>
                      Habilitar información médica
                    </p>
                  </Col>
                  <Col className="col-12 text-center mt-4">
                    <p className="title__scheduling__black font__size__24 mb-2">
                      Ingresa tu contraseña
                    </p>
                    <p className="font__size__16">
                      Para ver o descargar tus fórmulas médicas e incapacidades, debes ingresar tu contraseña para el servicio de Telemedicina.
                    </p>
                  </Col>
                  <Col className="col-12 container__form__password">
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="password"
                          placeholder=""
                          className="inputClass gray"
                          valueInput={clave}
                          onChange={({ target }) => {
                            onChange(target.value, "clave");
                          }}
                        />
                        <Form.Control.Feedback type="invalid" id="alertInvalidPass">
                          ¡Clave incorrecta!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Row>
                        <Col className="col-12 text-center">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={captchaKey}
                            onChange={(e) => {
                              validateFields(e, 3)
                            }}
                            className="d-none"
                          />
                          <Button
                            bsPrefix="button__primary__ ancho"
                            onClick={() => {
                              recaptchaRef.current.execute();
                            }}
                            disabled={`${textCargando ? 'disabled' : ''}`}
                          >
                            {textCargando ? 'Cargando...' : 'Continuar'}
                          </Button>
                          <div>
                            <p className="mt-3" onClick={() => {
                              setShowModalPasswordHistorial(false);
                              setShowModalRecoveryPassword({ ...showModalRecoveryPassword, "step1": true })
                            }}
                              style={{ cursor: 'pointer' }}
                            >¿Olvidaste tu contraseña?</p>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Modal>
            {false && (
              <Row className="w-100 p-0 m-0 justify-content-center">
                <Col className="col-11 col-8 text-center">
                  <img className="image__formula" src={image__formula}></img>
                </Col>
              </Row>
            )}
          </>
        )}
      </Row>
    </>
  );
};
export default SchedulingHistoric;
