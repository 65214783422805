import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import ContainerService from "../../../../components/container-service/ContainerService";
import "../css/networkingStyle.css";
import CardEvent from "../../../../components/card-event/CardEvent";
import Axios from "axios";
import { urlAPI } from '../../../../utils/config';
import { getToken, getUser, parseDateHour } from '../../../../utils/validations';
import { Link, useHistory } from 'react-router-dom';
import CardService from '../../../../components/card-service/CardService';
import ModalNetworking from "../../../../components/modal/ModalNetworking";
import volver from "../../../../assets/img/score-financiero/volver-gray.svg";
import ReactPaginate from 'react-paginate';
import iconTrash from '../../../../assets/img/seguridad-informatica/icon-cancelar.svg';
import Swal from 'sweetalert2';
import { data } from 'jquery';
import ModalNetworkingEvents from '../../../../components/modal/ModalNetworkingEvents';
import { setDefaultLocale } from 'react-datepicker';
import { isMobile } from "react-device-detect";

function NetworkingEvents({ match }) {
    const [modalShow, setModalShow] = useState(false);
    const headers = { headers: { "Content-Type": "application/json", Authorization: "Bearer " + getToken() } };
    const linkbread = [{
        to: "/auth/inicio",
        tittle: "Inicio > "
    },
    {
        to: "/ITT/auth/networking",
        tittle: "Networking > ",

    },
    {
        to: "/ITT/auth/networking",
        tittle: "Servicios > ",

    },
    {
        to: "/ITT/auth/networking/servicios/eventos",
        tittle: "Eventos de networking",
    }
    ];
    const history = useHistory();
    const [step, setStep] = useState(undefined);
    const [listEvents, setListEvents] = useState([]);
    const [listEventsFinal, setListEventsFinal] = useState([]);
    const [showCard, setShowCard] = useState({ idCard: null, showCard: false });
    const [listCountry, setListCountry] = useState([]);
    const [modal, setModal] = useState(false);
    const [listInscription, setListInscription] = useState([]);
    const [selects, setSelects] = useState({ idCity: '', idEvent: '' });
    const [pageCount, setPageCount] = useState(0)
    const [Countco, setCountco] = useState(1);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(12);
    const [data, setData] = useState([]);
    const [numPrev, setnumPrev] = useState(3);
    const [numNext, setnumNext] = useState(1);
    const [textPag, settextPag] = useState('...');
    const [numCity, setnumCity] = useState(0);
    const [numEvent, setnumEvent] = useState(0);
    const [currenDate,setCurrenDate] = useState(new Date());



    const events = [
        {
            id: 6,
            text: 'Foro'
        },
        {
            id: 2,
            text: 'Seminario'
        },
        {
            id: 5,
            text: 'Demodays'
        },
        {
            id: 7,
            text: 'Charlas'
        }
    ];
    const [listTypeEvent, setListTypeEvent] = useState([]);

    const getListCountry = (dataCity) => {
        Axios.get(`${urlAPI}/Hoja/tipos/7`, headers)
            .then(({ data }) => {
                let listCountry = [], listTypeEvent = [];
                dataCity.forEach((e) => {
                    listCountry.push(data.data.find((element) => element.id == e.idCiudad));
                    listTypeEvent.push(events.find((ele) => parseInt(ele.id) === parseInt(e.idTipoEvento)));
                });
                setListTypeEvent(Array.from(new Set(listTypeEvent)));
                setListCountry(Array.from(new Set(listCountry)));
            }).finally();
    }
    let list
    const getListEvents = () => {
        let slice =[];
        let auxSlice = [];
        Axios.get(`${urlAPI}/networking/listaEventos/${getUser().idUsuario}`, headers)
            .then(({ data }) => {
                auxSlice = data.data;
                auxSlice.forEach((element, i)=>{
                    if (element.fechaFinalizacion !== '' && element.vigente===true) {
                        element.rutaImagen = `https://preapi.activatecontuseguro.com/Imagenes/Eventos/CamaraComercio.jpg`
                        const [dia1, mes1, anio1] = element.fechaFinalizacion.split("-")
                        let fechaFinEvento=new Date(anio1.split(" ")[0],mes1,dia1);
                        let fechaHoy=new Date()
                        if (fechaHoy<fechaFinEvento){
                            listEventsFinal.push(element);
                        }
                    }
                 });
                setListEventsFinal(listEventsFinal);
                getListCountry(listEventsFinal);
                slice = listEventsFinal.slice(Countco, Countco + perPage)  
                setListEvents(slice);
                setPageCount(Math.ceil(data.data.length / perPage));
            }).finally();
    }
    const handleSelects = (e) => {
        setSelects({ ...selects, [e.target.name]: e.target.value });
    }
    const handleDetailEvent = (idEvent) => {
        history.push(`/ITT/auth/networking/servicios/eventos/evento-ID`, idEvent);
    }
    const handleDeleteInscription = (id) => {
        Swal.fire({
            title: `<span class="shedulingDelete">Cancelación inscripción evento</span>`,
            text: "¿Estás seguro de cancelar tu inscripción?",
            padding: '23px 10px 23px 10px',
            width: 370,
            showCloseButton: true,
            showCancelButton: true,
            customClass: {
                confirmButton: 'col-3 btnDeleteYes mb-4',
                cancelButton: 'col-3 btnDeleteNo mb-4',
                content: 'colorText',
            },
            cancelButtonColor: '#CF141B',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                Axios.post(`${urlAPI}/networking/desahabilitarInscripcion/${id}`, null, headers)
                    .then((res) => {
                        let { data, codigo } = res.data;
                        if (codigo === 0) {
                            getListInscriptionsEvents();
                        }
                    }).finally();
            }
        });
    }
    useEffect(() => {
        // if (selects.idCity !== '' || selects.idEvent !== '') {
        //     setListEventsFinal(listEvents.filter(x => x.idCiudad === selects?.idCity || x.idTipoEvento === selects?.idEvent));
        // } else {
        //     setListEventsFinal(listEvents);
        // }
        getListEvents(numEvent, numCity)
    }, [offset, selects]);
    const getListInscriptionsEvents = () => {
        Axios.get(`${urlAPI}/networking/verNetworkingInscripcionesUsuarios`, headers)
            .then((res) => {
                let { data, codigo } = res.data;
                if (codigo === 0) {
                    setListInscription(data);
                }
            }).finally();
    }
    useEffect(() => {
        if (window.innerWidth <= 700) {
            setnumPrev(2)
            setnumNext(0)
            settextPag('..')
        }
    }, [])
    useEffect(() => {
        if (localStorage.getItem('modal') === 'true') {
            setModalShow(false)
        } else {
            setModalShow(true)
        }
        if (step === "tus-eventos") {
            getListInscriptionsEvents();
        } else {
            getListEvents(0, 0);
        }
    }, [step]);
    const parseDate = (date) => {
        let [month, day2, year] = date.split("-");
        let dateFull = new Date(`${day2}-${month}-${year}`);
        return (<span><span style={{ textTransform: "capitalize" }}>{dateFull.toLocaleString('es-Co', { weekday: 'long' })}</span>, {dateFull.toLocaleString('es-Co', { day: '2-digit' })} de <span style={{ textTransform: "capitalize" }}>{dateFull.toLocaleString('es-Co', { month: 'long' })}</span> {dateFull.getFullYear()}</span>);
    };
    useEffect(() => {
        if (history.location.pathname === '/ITT/auth/networking/servicios/eventos') {
            setModal(true);
        }
    }, [history.location.pathname]);
    const handlePageClick = (e) => {
        //console.log(e);
        setCountco(e.selected * perPage)
        const selectedPage = e.selected;
        setOffset(selectedPage + 1)
        
    };
    return (
        <>
            {
                modalShow && (
                    <ModalNetworkingEvents show={modalShow} ></ModalNetworkingEvents>
                )
            }
            <ContainerService tittle="Networking" styleTittle={{ fontSize: "34px", lineHeight: "56px", paddingRight: "10%" }} linkBread={linkbread}>
                {step === undefined && (
                    <Container>
                        <Row bsPrefix="resposive row">
                            <Col sm={12} className="redC-tittle">
                                <h3 className="tittleView">Construye tu red de contactos </h3>
                            </Col>
                            <Col sm={12}>
                                <p style={{ paddingTop: '2rem', paddingBottom: '1rem', fontFamily: "OpenSans" }}>
                                    Aquí, encontrarás todos los eventos, seminarios, foros, charlas y demás actividades que te permitirán ampliar tus conocimientos y porqué no, mejorar tu red de contactos.
                                </p>
                            </Col>
                            <Col sm={6}>
                                <h4 className="tittleView subTittleView" style={{ paddingBottom: '1.5rem' }}>Eventos del mes </h4>
                            </Col>
                            <Col sm={6}>
                                <button className="btnEventsP" style={{ paddingRight: "0%", paddingBottom: '1.5rem' }} onClick={() => { setStep("tus-eventos") }}>Tus eventos programados</button>
                            </Col>
                            <div className="lineSeparator col-sm-12" />
                        </Row>
                        <Row bsPrefix="row pl-4 pr-4 prl4">
                            <Col sm={12} lg={2} style={{ paddingLeft: "27px" }}>
                                <label className="labelFiltra">Filtra tus eventos:</label>
                            </Col>
                            <Col sm={12} lg={5} className="resp-select event" style={{ left: "12px" }}>
                                <label className="labelInputN" style={{ position: "relative", top: "20px" }}>Cuidad</label>
                                <select style={{ borderRadius: '0px' }} className="form-control styleSelect iconoArrow" name="idCity" onChange={(e) => { handleSelects(e); getListEvents(numEvent, e.target.value); setnumCity(e.target.value) }}>
                                    <option value="">selecciona una consulta</option>
                                    {listCountry.map((e, i) => {
                                        return (
                                            <option key={i} value={e?.id}>{e?.text}</option>
                                        );
                                    })}
                                </select>
                            </Col>
                            <Col sm={12} lg={5} className="resp-select event" style={{ left: "12px" }}>
                                <label className="labelInputN" style={{ position: "relative", top: "20px" }}>Tipo de evento</label>
                                <select style={{ borderRadius: '0px' }} className="form-control styleSelect iconoArrow" name="idEvent" onChange={(e) => { handleSelects(e); getListEvents(e.target.value, numCity); setnumEvent(e.target.value) }}>
                                    <option value="">selecciona una consulta</option>
                                    {listTypeEvent.map((e, i) => {
                                        return (<option key={i} value={e?.id}>{e?.text}</option>);
                                    })}
                                </select>
                            </Col>
                            <Col bsPrefix="col-sm-12 pt-4">
                                <Row>
                                    {listEvents.map((e, i) => {
                                        return (
                                            <div key={i} className="col-sm-4 pl-0 pt-4">
                                                <CardEvent infoCard={e} showButton={true} bsPrefix="eventNetworking" setShowCard={() => { setShowCard({ idCard: e.idEvento, showCard: !showCard.showCard }) }}>
                                                    <div className="d-flex justify-content-center mb-3 resp-agendar">
                                                        <button className="btnMainBorderBlue" onClick={() => { handleDetailEvent(e.idEvento) }}>Regístrate aquí</button>
                                                    </div>
                                                    {(showCard.idCard === e.idEvento && showCard.showCard === true) && (
                                                        <>
                                                            <div className="textCard">
                                                                <span style={{ color: '#454648', fontWeight: 'bold' }}>Fecha</span><br />
                                                                <span>{parseDate(e.fechaEvento)}</span>
                                                            </div>
                                                            <div className="textCard pt-4">
                                                                <span style={{ color: '#454648', fontWeight: 'bold' }}>Lugar</span><br />
                                                                <span style={{textDecoration:"underline" }}>{e.ubicacionEvento}</span>
                                                                {/* <span>Campus Virtual Cámara de comercio Bogotá</span> */}
                                                            </div>
                                                        </>)
                                                    }
                                                </CardEvent>

                                            </div>

                                        );
                                    })}
                             <div id="contentpage" style={{width:'100%',position:'relative',marginLeft: '-15px',height:'120px'}}>
                             <ReactPaginate
                                        previousLabel=" Anterior"
                                        nextLabel={"Siguiente "}
                                        breakLabel={textPag}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={numNext}
                                        pageRangeDisplayed={numPrev}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination net"}
                                        subContainerClassName={"pages pagination"}
                                        pageLinkClassName={'pocoyo'}
                                        activeClassName={"active"}
                                        initialPage={0}
                                        forcePage={0}
                                        />
                                    </div>
                                </Row>
                            </Col>
                            {/* <Col sm={12} lg={12} bsPrefix="col-sm-12 pt-4 contentMobileNet">
                                <Row>
                                    {listEventsFinal.length !==0 ? (
                                        <div style={{display:"contents"}}>
                                            {data}
                                            <div id="contentpage" style={{width:'100%',position:'relative',marginLeft: '-15px',height:'120px'}}>
                                                <ReactPaginate
                                                    previousLabel=" Anterior"
                                                    nextLabel={"Siguiente "}
                                                    breakLabel={textPag}
                                                    breakClassName={"break-me"}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={numNext}
                                                    pageRangeDisplayed={numPrev}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination net"}
                                                    subContainerClassName={"pages pagination"}
                                                    pageLinkClassName={'pocoyo'}
                                                    activeClassName={"active"}
                                                    initialPage={0}
                                                    forcePage={0}
                                                />
                                            </div>
                                        </div>
                                    ):(<Col></Col>)}
                                    
                                </Row>
                            </Col> */}
                        </Row>
                    </Container>
                )}
                {step === "tus-eventos" && (
                    <Container>
                        <Row>
                            <Col sm={12} className="mb-4 btnCB-re">
                                <button onClick={() => { setStep(undefined) }} className="btnCirculeBack"><img src={volver} style={{ paddingRight: '10px' }} alt="imgBack" />Volver</button>
                            </Col>
                            <Col sm={12}><h3 className="tittleView mb-3">Construye tu red de contactos</h3></Col>
                            <div className="col-sm-12" style={{ marginTop: "26px", marginBottom: "26px" }}>
                                <span className="textParagraph">La siguiente es la información de tus solicitudes:</span>
                            </div>
                            <Col sm={12}>
                                {listInscription.map((el, i) => {
                                    return (
                                        <CardService key={i} view="networking" shedulingInfo={{ place: el.lugarEvento, text: parseDateHour(el.fechaEvento, el.HoraEvento) }}>
                                            <button className="buttonCancel btnCancelEvent" onClick={() => { handleDeleteInscription(el.idInscripcion) }}><img src={iconTrash} alt="iconBasura" style={{ marginBottom: "4px", marginRight: "8px" }} />Cancelar</button>
                                        </CardService>
                                    );
                                })}
                            </Col>
                        </Row>
                    </Container>
                )}

            </ContainerService>
        </>
    );
}

export default NetworkingEvents;