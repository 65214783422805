import React, { useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { urlAPI } from '../../utils/config';
import { getToken, isActive, validateEmail, getUser, encryptDecrypt } from '../../utils/validations';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import sha256 from 'sha256';
import Loader from '../../components/loader/Loader';
import Input from "../../components/input/Input";
import $ from "jquery";
import { isEmpty } from "lodash";
import Axios from "axios";
import FormBeneficiary from "../../components/form-beneficiary/FormBeneficiary";
import CardBeneficiary from "../../components/card-beneficiary/CardBeneficiary";
import ModalAutoMeno from "../../components/modal/ModalAutoMeno";
import ModalEmail from "../../components/modal/ModalEmail";
import ModalEmailAdult from "../../components/modal/ModalEmailAdult";
import ModalSaveBene from "../../components/modal/ModalSaveBene";
import AuthorizationAdult from "../authorizations/AuthorizationAdult";
import ModalPreInscripcion from "../../components/modal/ModalPreInscripcion";
import { Pdf, pdfTelemedicina } from "../../components/pdf/Pdf";
import uuid from "react-uuid";
import infoActive from "../../assets/img/perfil/tabs/ico-tu-informacion-act.svg";
import infoInactive from "../../assets/img/perfil/tabs/ico-tu-informacion-inact.svg";
import datosActive from "../../assets/img/perfil/tabs/ico-tus-contactos-act.svg";
import datosInactive from "../../assets/img/perfil/tabs/ico-tus-contactos-inact.svg";
import passActive from "../../assets/img/perfil/tabs/ico-contrasena-inact.svg"
import passInactive from "../../assets/img/perfil/tabs/ico-contrasena-act.svg";
import beneficiaryActive from "../../assets/img/perfil/IC-beneficios-act.svg";
import beneficiaryInactive from "../../assets/img/perfil/icon_beneficiarios_inactive.svg";
import iconCheck from "../../assets/img/ico-exito.svg";
import iconCheckC from "../../assets/img/cambio-de-contrasena.svg";
import { ReactSortable } from "react-sortablejs";
import { Fragment } from "react";
import iconCanal from "../../assets/img/perfil/icon-canal.svg";
import iconCanal1 from "../../assets/img/perfil/icon-canal..svg";
import iconSkype from "../../assets/img/perfil/icon_skype.svg";
import iconWa from "../../assets/img/perfil/icon_whatsapp.svg";
import iconClose from "../../assets/img/perfil/icon_delete_close.svg";
import iconPhone from "../../assets/img/perfil/ico-phone-perfil.svg";
import * as CryptoJS from "crypto-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

export default function Profile(props) {
    function defaultFormValue() {
        return {
            nombres: "",
            apellidoPaterno: "",
            dicTipoDocumento: "",
            identificacion: "",
            correoElectronico: "",
            numeroCelular: ""
        };
    }

    function defaultFormPass() {
        return {
            claveAnterior: "",
            clave: "",
            claveConfirmacion: ""
        };
    }
    function defaultFormBeneficiary() {
        return {
            idBeneficiario: "",
            idUsuario: "",
            nombres: "",
            apellidos: "",
            dicTipoIdentificacionBen: 0,
            identificacion: "",
            dicPaisdeNacimiento: 0,
            fechaDeNacimiento: new Date(),
            correoElectronico: "",
            dicParentesco: 0,
            terminos: false,
            otro: "",
            servicio: "",
            numeroSesiones: 0
        };
    }
    function defaultShowFormEdit() {
        return { id: '', show: false };
    }

    const servicesList = [
        { value: 'Coursera - 1 Año', text: 'Educación Digital 1 año' },
        { value: 'Coursera - 3 Año', text: 'Educación Digital 3 años' },
        { value: 'seguridad', text: 'Seguridad Informática, Score de Crédito, Notificaciones Financieras' },
        { value: 'instafit', text: 'Salud y Bienestar' },
        { value: 'telemedicina', text: 'Telemedicina' }
    ]
    const json = [
        {
            id: 5,
            value: 'Cédula de Ciudadanía'
        },
        {
            id: 6,
            value: 'Cédula de Extranjería'
        },
        {
            id: 7,
            value: 'Pasaporte'
        }
    ]
    const [validateCt, setValidateCt] = useState({ contactoError: null });
    const [rollBack, setRollBack] = useState([]);
    const [infoProfile, setInfoProfile] = useState(defaultFormValue);
    const [documentTypesP, setDocumentTypesP] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [passChange, setPassChange] = useState(defaultFormPass);
    const [count] = useState(0);
    const [loaderShow, setLoaderShow] = useState(false);
    const [kinship, setKinship] = useState([]);
    const [country, setCountry] = useState([]);
    const [copyInfoBeneficiary, setCopyInfoBeneficiary] = useState({ servicio: '' });
    const [infoBeneficiary, setInfoBeneficiary] = useState(defaultFormBeneficiary);
    const [listInfoCardBeneficiary, setListInfoCardBeneficiary] = useState([]);
    const [showFormNew, setShowFormNew] = useState(false);
    const [showFormEdit, setShowFormEdit] = useState(defaultShowFormEdit);
    const [typeProfile] = useState("premium-swm");
    const [textCard, setTextCard] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showAutoModal, setShowAutoModal] = useState(false);
    const [showModalSave, setShowModalSave] = useState(false);
    const [showEmailAdult, setShowEmailAdult] = useState(false);
    const [showPreInsModal, setShowPreInsModal] = useState(false);
    const [addSocial, setAddSocial] = useState(false);
    const [stateCard, setStateCard] = useState({ state: false, type: '' });
    const { href } = window.location;
    const [emailAcu, setEmailAcu] = useState({ correoElectronico: '' });
    const [typeInput, setTypeInput] = useState(false);
    const [iconTab, setIconTab] = useState(1);
    const [servicesB, setServicesB] = useState([]);
    const [isSorting, setIsSorting] = useState();
    const [maxBeneficiary, setMaxBeneficiary] = useState(0);
    const [jsonBeneficiary, setJsonBeneficiary ] = useState(JSON.parse(getUser().perfilesBeneficiarios))
    const { step } = props.match.params;
    const [state, setState] = useState({
        list: [
            { id: -1, tipoConctacto: "Canal de contacto", contacto: "" },
        ]
    });
    const [formSocial, setFormSocial] = useState({ tipoSocial: "", contenidoSocial: "" });
    const onChangeSocial = (e) => {
        let patternPhone = /^3+[0-9]{9}$/;
        setFormSocial({ ...formSocial, [e.target.name]: e.target.value });
        if (e.target.name === 'tipoSocial') {
            if (e.target.value === 'Whatsapp' || e.target.value === 'Celular') {
                setTypeInput(true);
            }
            else {
                setTypeInput(false);
            }
        }
        if (e.target.name == 'contenidoSocial') {
            if (formSocial.tipoSocial === 'Whatsapp' || formSocial.tipoSocial === 'Celular') {
                let errorC = ""
                let patternPhone = /^3+[0-9]{9}$/;
                setFormSocial({ ...formSocial, [e.target.name]: e.target.value.substr(0, 10) });
                if (isEmpty(e.target.value) || isEmpty(e.target.value)) {
                    errorC = "Campos vacios"
                }
                else if (typeInput && !e.target.value.startsWith(3)) {
                    errorC = "El número debera iniciar en 3"
                }
                else if (typeInput && !patternPhone.test(e.target.value)) {
                    errorC = "Número invalido"
                }
                else if (!typeInput && !validateEmail(e.target.value)) {
                    errorC = "Correo electronico invalido"
                }
                setValidateCt({ contactoError: errorC });
            }
        }
    }
    const saveSocial = () => {

        if (validateCt.contactoError === '') {

            setAddSocial(false);
            let element = {
                tipoContacto: formSocial.tipoSocial,
                contacto: formSocial.contenidoSocial,
                idUsuario: getUser().idUsuario
            };
            createSocial(element);
            setFormSocial({ tipoSocial: "", contenidoSocial: "" })
        }
    }
    const createSocial = (form) => {
        try {
            Axios.post(`${urlAPI}/agendamientos/contacto`, JSON.stringify(form), { headers: { "Content-Type": "application/json", Authorization: getToken() } }
            ).then(succ => {
                getContact();
            }).catch(error => {

            });
        }
        catch (Exception) {

        }

    }
    const getContact = () => {
        try {
            Axios.get(`${urlAPI}/agendamientos/verContactos/${getUser().idUsuario}`, { headers: { "Content-Type": "application/json", Authorization: getToken() } }
            ).then(succ => {
                var aux = [{ id: -1, tipoConctacto: "Canal de contacto", contacto: "" },];
                succ.data.forEach((e, i) => {
                    var json = {
                        id: e.id,
                        tipoConctacto: e.tipoContacto,
                        contacto: e.contacto1,
                    }
                    aux.push(json);
                });
                setState({
                    list: aux
                });
            }).catch(error => {

            });
        }
        catch (Exception) {

        }
    }
    const deleteSocial = (e) => {
        Swal.fire({
            height: '18em',
            width: '31em',
            title: "Eliminar canal de contacto",
            text: `¿Estas seguro que quieres eliminar el canal de contacto?`,
            customClass: {
                cancelButton: 'cancelButtonSwal',
                confirmButton: 'confirmButtonSwal',
                closeButton: 'swal2-close-modal modP rof',
                popup: 'swalContact',
                title: 'titleSwal',
                content: 'contSwal'
            },
            confirmButtonText: 'Sí',
            cancelButtonText: 'No',
            showCancelButton: true,
            showCloseButton: true
        }).then((result) => {
            if (result.value) {
                Axios.post(`${urlAPI}/agendamientos/eliminarContacto/${e}`, null, { headers: { "Content-Type": "application/json", Authorization: getToken() } }
                ).then(succ => {
                    let item = state.list.findIndex(x => x.id == e)
                    state.list.splice(item, 1);
                    setFormSocial({ tipoSocial: "", contenidoSocial: "" })
                }).catch(error => {

                });
            }
        });
    }
    let listaPerfiles = [];
    let typeData = [1, 1008, 5, 1009];
    let servicosNR = [];
    const getListBeneficiarios = (tipo) => {
        try {
            Axios.get(`${urlAPI}/Beneficiario/traerBeneficiario`, { headers: { "Content-Type": "application/json", Authorization: getToken() } }
            ).then(succ => {
                setListInfoCardBeneficiary(succ.data.data);
                servicesList.forEach((e) => {
                    getUser().perfiles.forEach((r) => {
                        if (r.toLowerCase().includes(e.value.toLowerCase())) {
                            listaPerfiles.push(e);
                        }
                    });
                });
                servicosNR = [...new Set(listaPerfiles)];
                // succ.data.data.forEach((e, i) => {
                //     servicosNR = servicosNR.filter((x, y) => x.value !== e.servicio)
                // })
                setRollBack(listaPerfiles);
                if (tipo !== null) {
                    let edicion = servicesList.filter((x) => x.value === tipo)[0]
                    if (edicion !== undefined) {
                        servicosNR.push(edicion)
                    }
                }
                setServicesB([...servicosNR]);
            }).catch(error => {

            });
        } catch (Exception) {

        }
    }
    const obtenerMaxBenef = () => {

        var arrayHolder = [];
        jsonBeneficiary?.length > 0 && jsonBeneficiary.forEach(x => {
            //console.log(x);
            arrayHolder.push(parseInt(x.numberBeneficiaries));
            //console.log(arrayHolder);
        })
        if (arrayHolder.length === 0) {
            setMaxBeneficiary(5);
        }
        else {
            setMaxBeneficiary(Math.max(...arrayHolder))
        }

    }

    const updateServices = (service, stateInfo, servicePast) => {
        Axios.get(`${urlAPI}/Login/obtenerSession`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            },
        }).then(data => {
            let services = getUser().perfiles;
            // if (stateInfo === 'new') {
            //     if (service.includes("seguridad")) {
            //         ["seguridad", "notificaciones", "score"].forEach((e) => {
            //             services.splice(services.findIndex(x => x == e), 1);
            //         });
            //     } else {
            //         services.splice(services.findIndex(x => x.toLowerCase().includes(service.toLowerCase())), 1);
            //     }
            // }
            // else if (stateInfo === 'edit') {
            //     if (servicePast.includes("seguridad")) {
            //         services.push("seguridad", "notificaciones", "score");
            //         services.splice(services.findIndex(x => x.toLowerCase().includes(service.toLowerCase())), 1);
            //     } else if (service.includes("seguridad")) {
            //         ["seguridad", "notificaciones", "score"].forEach((e) => {
            //             services.splice(services.findIndex(x => x == e), 1);
            //         });
            //         services.push(servicePast);
            //     } else {
            //         services.push(servicePast);
            //         services.splice(services.findIndex(x => x.toLowerCase().includes(service.toLowerCase())), 1);
            //     }
            // }
            // else if (stateInfo === 'delete') {
            //     if (service.includes("seguridad")) {
            //         services.push("seguridad", "notificaciones", "score");
            //     } else {
            //         services.push(service);
            //     }
            // }
            data.data.perfiles = services;
            localStorage.setItem("user", CryptoJS.AES.encrypt(JSON.stringify(data.data), "eco_scotia"));
        });
    }

    useEffect(() => {
        //console.log(JSON.parse(getUser().perfilesBeneficiarios));
        // let info = window.location.pathname.split('configuracion-perfil/')
        // urlAnalitic(info[1])
        if (isActive() && count === 0) {
            // if (href.includes('#beneficiarios')) {
            //     $('#beneficiaryR-span').addClass('tabBlue');
            //     $("div .lineDefault").css({ 'margin-top': '30px' });
            //     document.getElementById('uncontrolled-tab-example-tab-beneficiarios').click();
            // } else {

            //     $('#infoPerfilR-span').addClass('tabBlue');
            // }
            setLoaderShow(true);
            Axios.get(`${urlAPI}/Profile/InfoGeneral`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getToken(),
                }
            }).then(succ => {
                setInfoProfile(succ.data.data);;
            }).catch(error => {
                //console.log(error);
            });
            getContact();
        }
        typeData.forEach((elemnt) => {
            getTypes(elemnt);
        });
        obtenerMaxBenef();
        getListBeneficiarios(null);
        setLoaderShow(false);
    }, [count]);

    const getTypes = (id) => {
        Axios.get(`${urlAPI}/Beneficiario/Tipos/${id}`, { headers: { "Content-Type": "application/json", Authorization: getToken() } }).then(succ => {

            if (id === 1) {
                setDocumentTypesP(succ.data.data);
                setDocumentTypes(succ.data.data);
            } else if (id === 1008) {
                //console.log(succ.data.data);
                setKinship(succ.data.data.sort((a, b) => a.id - b.id));

            } else if (id === 5) {
                setCountry(succ.data.data);
            }
        }).catch(error => {
            //console.log(error);
        });
    }

    const handleFormProfile = (e) => {
        if (e.target.name === "numeroCelular" && e.target.value.length > 10) {

        }
        else {
            setInfoProfile({ ...infoProfile, [e.target.name]: e.target.value });
        }
    }

    const handleFormPass = (e) => {
        setPassChange({ ...passChange, [e.target.name]: e.target.value });
    }

    const [validatePr, setValidatePr] = useState({
        nombreError: null,
        apellidoError: null,
        correoError: null,
        celularError: null
    });

    const [validatePs, setValidatePs] = useState({
        claveError: null,
        claveNError: null,
        claveCError: null,
    });

    const validatorForms = (type) => {
        const validacion = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
        const validacionCel = /^3+[0-9]{9}$/;
        const validacionPass = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{7,15}/;
        let nombreError = '';
        let apellidoError = '';
        let correoError = '';
        let celularError = '';
        let claveError = '';
        let claveNError = '';
        let claveCError = '';
        if (type.includes('profile')) {
            if (!infoProfile.nombres) {
                nombreError = 'El campo Nombres es obligatorio';
            }
            if (!validacion.test(infoProfile.nombres) && !isEmpty(infoProfile.nombres)) {
                nombreError = "Nombre no valido";
            }
            if (!infoProfile.apellidoPaterno) {
                apellidoError = 'El campo Apellidos es obligatorio';
            }
            if (!validacion.test(infoProfile.apellidoPaterno) && !isEmpty(infoProfile.apellidoPaterno)) {
                apellidoError = "Apellido no valido";
            }
            if (!infoProfile.correoElectronico) {
                correoError = 'El campo Correo es obligatorio';
            }
            if (!validateEmail(infoProfile.correoElectronico) && !isEmpty(infoProfile.correoElectronico)) {
                correoError = 'Correo no valido';
            }
            if (!infoProfile.numeroCelular) {
                celularError = 'El campo Teléfono celular es obligatorio';
            }
            if (!validacionCel.test(infoProfile.numeroCelular) && !isEmpty(infoProfile.numeroCelular)) {
                celularError = "Teléfono celular no valido. Ej: 3215558899";
            }
            setValidatePr({ nombreError, apellidoError, correoError, celularError });
            if (isEmpty(nombreError) && isEmpty(apellidoError) && isEmpty(correoError) && isEmpty(celularError)) {
                onSubmitProfile();
            }
        } else if (type.includes('pass')) {
            if (!passChange.claveAnterior) {
                claveError = 'El campo Contraseña Actual es obligatorio';
            }
            if (!passChange.clave) {
                claveNError = 'El campo Nuevo Contraseña es obligatorio';
            }
            if (!validacionPass.test(passChange.clave) && !isEmpty(passChange.clave) || passChange.clave.length > 16) {
                // claveNError = 'La nueva contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula. NO puede tener otros símbolos. Ejemplo:w3Unpocodet0d0';
                Swal.fire({
                    title: 'Advertencia',
                    text: `
                    La nueva contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula.
                    NO puede tener otros símbolos.
                    `,
                    icon: 'warning',
                    customClass: {
                        confirmButton: 'buttomConfirmContraseña',
                        content: 'textoDeSwal',
                        popup: 'swalModalContraseña',
                        title: 'tittlePass'
                    },
                    confirmButtonText: 'Aceptar'
                });
                return;
            }
            if (!passChange.claveConfirmacion) {
                claveCError = 'El campo Confirmación nueva contraseña es obligatorio';
            }
            if (passChange.clave !== passChange.claveConfirmacion && !isEmpty(passChange.claveConfirmacion)) {
                claveCError = 'Las constraseñas no coinciden';
            }
            setValidatePs({ claveError, claveNError, claveCError });
            if (isEmpty(claveError) && isEmpty(claveNError) && isEmpty(claveCError)) {
                onSubmitChangePass();
                document.getElementById("clave").style.content = null;

            }
        }
    }
    const onSubmitProfile = () => {
        setLoaderShow(true);
        Axios.post(`${urlAPI}/Profile/ActualizarDatosPersonales`, JSON.stringify(infoProfile), {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setLoaderShow(false);
            if (succ) {
                Swal.fire({
                    imageUrl: iconCheck,
                    width: '27em',
                    height: '20em',
                    text: `Has actualizado tus datos con éxito.`,
                    customClass: {
                        confirmButton: 'btnConfirmAzul',
                        closeButton: 'swal2-close-modal'
                    },
                    confirmButtonText: 'ACEPTAR',
                    showCloseButton: true
                });
            }
        }).catch(error => {
            //console.log(error);
        });
    }
    const onSubmitChangePass = () => {
        setLoaderShow(true);
        let jsonPass = { "claveAnterior": sha256(passChange.claveAnterior), "clave": sha256(passChange.clave) };
        Axios.post(`${urlAPI}/Profile/ActualizarPassword`, JSON.stringify(jsonPass), {
            headers: {
                Accept: "Application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setLoaderShow(false);
            if (succ.data.codigo === 0) {
                Swal.fire({
                    imageUrl: iconCheckC,
                    width: '27em',
                    height: '20em',
                    text: `Se ha cambiado tu contraseña con éxito.`,
                    customClass: {
                        confirmButton: 'btnConfirmAzul',
                        closeButton: 'swal2-close-modal closePass',
                        content: 'contSwalPass'
                    },
                    showCloseButton: true,
                    confirmButtonText: 'ACEPTAR'
                }).then((result) => {
                    setTimeout(() => {
                        document.getElementById("clave").value = "";
                        document.getElementById("claveAnterior").value = "";
                        document.getElementById("claveConfirmacion").value = "";

                    }, 200);
                });
            }
            if (succ.data.codigo === 1) {
                let title = 'Tu contraseña se encuentra dentro de las últimas registradas. Por seguridad, cámbiala a una no registrada.'
                if (succ.data.mensaje !== '') title = succ.data.mensaje
                Swal.fire({
                    icon: 'warning',
                    width: '30em',
                    title: title,
                    customClass: {
                        confirmButton: 'btnConfirmAzul'
                    },
                    confirmButtonText: 'ACEPTAR',

                });
            }
        }).catch(error => {
            //console.log(error);
        });
    }
    const urlAnalitic = (e) => {
        if (e === 'informacion-personal') {
            setTextCard(false);
            setIconTab(1);
        }
        else if (e === 'canales-contacto') {
            setTextCard(false);
            setIconTab(2);
        }
        else if (e === 'contrasena') {
            setTextCard(false);
            setIconTab(3);
        }
        else {
            setTextCard(true);
            setIconTab(4);
        }
        props.history.push('/auth/configuracion-perfil/' + e);
    }
    useEffect(() => {
        //console.log(getUser());
        if (props.location.pathname === '/auth/configuracion-perfil/contrasena') {
            $('#claveAnterior').attr('style', 'border-right:0px !important; border-top-right-radius:0px !important;border-bottom-right-radius:0px !important;');
            $('#claveConfirmacion').attr('style', 'border-right:0px !important; border-top-right-radius:0px !important;border-bottom-right-radius:0px !important;');
            $('#clave').attr('style', 'border-right:0px !important; border-top-right-radius:0px !important;border-bottom-right-radius:0px !important; ');
            $('.eyeB').attr('style', 'border-left:0px!important');
        }
    }, [props.location.pathname]);
    const closeForm = (e, i) => {
        setShowFormEdit({ id: i, show: false });
    }
    const handleDataPicker = (date) => {
        setInfoBeneficiary({ ...infoBeneficiary, fechaDeNacimiento: date });
    }
    const handleFormBeneficiary = (e) => {

        if (e.target.name === 'terminos') {
            setInfoBeneficiary({ ...infoBeneficiary, [e.target.name]: e.target.checked });
        }
        else if (e.target.name === 'dicTipoIdentificacionBen' && (e.target.value.toString() === '5' || e.target.value.toString() === '8' || e.target.value.toString() === '6' || e.target.value.toString() === '7')) {
            setInfoBeneficiary({ ...infoBeneficiary, [e.target.name]: e.target.value, 'dicPaisdeNacimiento': '103' });
            if (e.target.value.toString() === '8') {
                var services = servicesB;
                services.forEach((element, i) => {
                    if (element.value.includes("seguridad")) {
                        services.splice(i, 1);
                    }
                });
                setServicesB([...services]);
            } else {
                getListBeneficiarios(null);
            }
        }
        else {
            setInfoBeneficiary({ ...infoBeneficiary, [e.target.name]: e.target.value });
        }
    }
    const handleEmailModal = (e) => {
        setEmailAcu({ ...emailAcu, [e.target.name]: e.target.value });
    }
    const handleControlForm = (formType, e, i) => {
        if (formType === 'new') {
            setShowFormEdit({ id: '', show: false });
            setInfoBeneficiary(defaultFormBeneficiary);
            setShowFormNew(true);
            getListBeneficiarios(null)
        } else if (formType === 'edit') {
            setShowFormNew(false);
            setServicesB([...rollBack])
            getListBeneficiarios(e.servicio)
            setCopyInfoBeneficiary({ servicio: e.servicio });
            setInfoBeneficiary({
                idBeneficiario: e.idBeneficiario,
                idUsuario: e.idUsuario,
                nombres: e.nombres,
                apellidos: e.apellidos,
                dicTipoIdentificacionBen: e.dicTipoIdentificacionBen,
                identificacion: e.identificacion,
                dicPaisdeNacimiento: e.dicPaisdeNacimiento,
                fechaDeNacimiento: new Date(e.fechaDeNacimiento),
                correoElectronico: e.correoElectronico,
                dicParentesco: e.dicParentesco,
                terminos: true,
                otro: e.otro,
                servicio: e.servicio
            });

            setShowFormEdit({ id: i, show: true });
        }
    }
    const validatorSmall = () => {
        setShowEmailModal(false);
        setShowAutoModal(false);
        setShowModalSave(false);
        setShowEmailAdult(false);
        setShowPreInsModal(false);
        setShowEmailAdult(false);
        setEmailAcu({ correoElectronico: '' });
        var tipeDocument = infoBeneficiary.dicTipoIdentificacionBen.toString() === "8" ? true : false;
        // setListInfoCardBeneficiary(infoBeneficiary)
        if (tipeDocument && (infoBeneficiary.dicParentesco.toString() === "30048" || infoBeneficiary.dicParentesco.toString() === "30049")) {
            setShowAutoModal(true);
        } else if (tipeDocument && (infoBeneficiary.dicParentesco.toString() === "30051" || infoBeneficiary.dicParentesco.toString() === "30051")) {
            setShowEmailModal(true);
        } else {
            if (infoBeneficiary.dicTipoIdentificacionBen.toString() === "5" || infoBeneficiary.dicTipoIdentificacionBen.toString() === "6" || infoBeneficiary.dicTipoIdentificacionBen.toString() === '7') {
                setShowEmailAdult(true);
            } else {

                handleSaveBeneficiary('f');
            }
        }


    }
    const createPdf = (id) => {
        let obj = {
            idBeneficiario: id,
            html: Pdf(1, infoBeneficiary.nombres + ' ' + infoBeneficiary.apellidos, infoBeneficiary.identificacion),
            pdfTelemedicina: infoBeneficiary.servicio === "telemedicina" ? pdfTelemedicina(infoBeneficiary.nombres + ' ' + infoBeneficiary.apellidos, infoBeneficiary.identificacion) : '',
        };
        let parentesto = infoBeneficiary.dicParentesco.toString() === "30048" ? 'Tutor' : 'Padre';
        Axios.post(`${urlAPI}/Beneficiario/guardarAutorizacion`, JSON.stringify(obj), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
        }).catch(error => {
            //console.log(error);
        });
    }

    const sendMail = (idBeneficiario, email, link, keyLink, mood) => {
        let objSend = {
            idBeneficiario: idBeneficiario,
            email: email,
            link: link,
            keyLink: keyLink
        };
        Axios.post(`${urlAPI}/Beneficiario/${mood == 'kidEmail' ? 'envioCorreoMenores' : 'envioCorreoMayores'}`, JSON.stringify(objSend), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(success => {
        }).catch(error => {
            //console.log(error);
        });
    }


    useEffect(() => {
        if (stateCard.state && stateCard.type === 'kidEmail') {
            $(`#cardB-${listInfoCardBeneficiary.length - 1}`).removeClass('usuarioGris').addClass('usuarioNaranja');
            setTimeout(() => {
                $(`#cardB-${listInfoCardBeneficiary.length - 1}`).removeClass('usuarioNaranja').addClass('usuarioGris');
            }, 5000);
        } else if (stateCard.state && stateCard.type === 'normal') {
            $(`#cardB-${listInfoCardBeneficiary.length - 1}`).removeClass('usuarioGris').addClass('usuarioVerde');
            setTimeout(() => {
                $(`#cardB-${listInfoCardBeneficiary.length - 1}`).removeClass('usuarioVerde').addClass('usuarioGris');
            }, 5000);
        }

        setStateCard({ state: false, type: '' });
    }, [listInfoCardBeneficiary]);

    const handleSaveBeneficiary = (kid) => {
        var keyLink = uuid();
        var keyF = keyLink.replace(/[-]/gi, '');
        if (infoBeneficiary.idBeneficiario === '') {
            let elementSession = jsonBeneficiary.filter(x => {
                return x.serviceName.toLowerCase() === infoBeneficiary.servicio.toLocaleLowerCase()
            })
            //console.log(elementSession);
            //console.log(jsonBeneficiary);
            //console.log(infoBeneficiary.servicio);
            if (jsonBeneficiary.length === 0) {
                infoBeneficiary.numeroSesiones = 99;
            }
            else {
                if (infoBeneficiary.servicio.toLocaleLowerCase() == 'instafit') {
                    infoBeneficiary.numeroSesiones = '';
                } else {
                    infoBeneficiary.numeroSesiones = elementSession[0].numberSessionsHolder;
                }
            }
            setLoaderShow(true);
            Axios.post(`${urlAPI}/Beneficiario/insertarBeneficiario`, JSON.stringify(infoBeneficiary), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getToken(),
                }
            }).then(scc => {
                if (scc.data.codigo === 0) {
                    getListBeneficiarios(null);
                    if (kid === 'f') {
                    }
                    if (kid === 'kid') {
                        createPdf(scc.data.data);
                        setShowAutoModal(false);
                    } else if (kid === 'kidEmail') {
                        var link = btoa(encryptDecrypt(`vida<${scc.data.data}<ecosistema`, keyF));
                        sendMail(scc.data.data, emailAcu.correoElectronico, link, keyF, kid);
                    } else if (kid === 'adultEmail') {
                        var link = btoa(encryptDecrypt(`vida<${scc.data.data}<ecosistema`, keyF));
                        sendMail(scc.data.data, emailAcu.correoElectronico, link, keyF, kid);
                    }
                    updateServices(infoBeneficiary.servicio, 'new');
                    setShowFormNew(false);
                    setLoaderShow(false);
                    setShowEmailAdult(false)
                    setStateCard(true);
                    setStateCard({ state: true, type: kid === 'kid' || kid === 'kidEmail' ? 'kidEmail' : 'normal' });
                    setInfoBeneficiary(defaultFormBeneficiary);
                }
                else if (scc.data.codigo === 2) {
                    setLoaderShow(false);
                    Swal.fire({
                        icon: 'warning',
                        width: '30em',
                        title: `<span class="textAlert">${scc.data.mensaje}</span>`,
                        customClass: {
                            confirmButton: 'col-5 btnGuardar'
                        },
                        confirmButtonText: 'Aceptar',
                    });
                }
            }).catch(error => {
            });
        } else if (infoBeneficiary.idBeneficiario !== '') {
            setLoaderShow(true);
            Axios.post(`${urlAPI}/Beneficiario/editarBeneficiario`, JSON.stringify(infoBeneficiary), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getToken(),
                }
            }).then(succ => {
                if (succ.data.codigo === 0) {
                    getListBeneficiarios(null);
                    if (kid === 'f') {
                    }
                    else if (kid === 'kid') {
                        createPdf(infoBeneficiary.idBeneficiario, 'update');
                        setShowAutoModal(false);
                    } else if (kid === 'kidEmail') {
                        var link = btoa(encryptDecrypt(`vida<${infoBeneficiary.idBeneficiario}<ecosistema`, keyF));
                        sendMail(infoBeneficiary.idBeneficiario, emailAcu.correoElectronico, link, keyF, kid);
                    } else if (kid === 'adultEmail') {
                        var link = btoa(encryptDecrypt(`vida<${infoBeneficiary.idBeneficiario}<ecosistema`, keyF));
                        sendMail(infoBeneficiary.idBeneficiario, emailAcu.correoElectronico, link, keyF, kid);
                    }
                    updateServices(infoBeneficiary.servicio, 'edit', copyInfoBeneficiary.servicio);
                    setShowFormEdit({ id: showFormEdit.id, show: false });
                    setLoaderShow(false);
                    setStateCard({ state: true, type: kid === 'kid' || kid === 'kidEmail' ? 'kidEmail' : 'normal' });
                    setInfoBeneficiary(defaultFormBeneficiary);

                } else if (succ.data.codigo === 2) {
                    setLoaderShow(false);
                    Swal.fire({
                        icon: 'warning',
                        width: '30em',
                        title: `<span class="textAlert">${succ.data.mensaje}</span>`,
                        customClass: {
                            confirmButton: 'col-5 btnGuardar'
                        },
                        confirmButtonText: '',
                    });
                }

            }).catch(error => {

            });
        }
    }

    const handleDeleteBeneficiary = (e, i) => {
        if (e !== '') {
            Swal.fire({
                title: '<span class="BeneficiaryDelete">¿Segura que quieres eliminar este beneficiario de tu Ecosistema de Servicios?</span>',
                text: 'Esta acción no se puede deshacer. Para volverlo a ingresar debe pasar nuevamente por el proceso interno de autorización. Recuerda que este beneficiario se eliminará de tu Ecosistema de Seguros y no de tu póliza.',
                padding: '48px 20px',
                width: 480,
                showCancelButton: true,
                customClass: {
                    confirmButton: 'col-5 btnCancelar m-2',
                    cancelButton: 'col-5 btnGuardar m-2',
                    closeButton: 'closeModalBene',
                    content: 'deleteBen',
                    actions: 'footerBene'
                },
                showCloseButton: false,
                cancelButtonText: 'Sí, eliminar',
                confirmButtonText: 'Cancelar'
            }).then((result) => {
                if (!result.value) {
                    setLoaderShow(true);
                    Axios.post(`${urlAPI}/Beneficiario/eliminarBeneficiario`, JSON.stringify(e), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + getToken(),
                        }
                    }).then(succ => {
                        if (succ.data.codigo === 0) {
                            updateServices(e.servicio, 'delete');
                            setListInfoCardBeneficiary([]);
                            getListBeneficiarios(null);
                        }
                        setLoaderShow(false);

                    }).catch(error => {
                        setLoaderShow(false);
                    });
                }
            })
        } else {

        }
    }
    const handleSuccess = () => {
        setShowEmailModal(false);
        setShowPreInsModal(true);
    }
    const HandleSuccessAdult = () => {
        setShowEmailAdult(false);
        setShowModalSave(false);
    }
    useEffect(() => {
        if (isSorting) {
            document.querySelector("body").classList.add("dragging");
        } else {
            document.querySelector("body").classList.remove("dragging");
        }
    }, [isSorting]);
    return (
        <div className="container">
            {loaderShow && (<Loader show={loaderShow} />)}
            {showEmailModal && (<ModalEmail showM={showEmailModal} value={emailAcu} clickSave={() => { handleSaveBeneficiary('kidEmail') }} onChange={handleEmailModal} clickClose={() => { handleSuccess() }} close={() => { setShowEmailModal(false) }} />)}
            {showAutoModal && (<ModalAutoMeno showM={showAutoModal} clickSave={() => { handleSaveBeneficiary('kid') }} infoMenor={infoBeneficiary} close={() => { setShowAutoModal(false) }} />)}
            {showEmailAdult && (<ModalEmailAdult showM={showEmailAdult} value={emailAcu} clickSave={() => { handleSaveBeneficiary('adultEmail') }} onChange={handleEmailModal} clickClose={() => { HandleSuccessAdult() }} close={() => { setShowEmailAdult(false) }} />)}
            {showModalSave && (<ModalSaveBene showM={showModalSave} clickClose={() => { setShowModalSave(false) }} typeTitle="profile" />)}
            {showPreInsModal && (<ModalPreInscripcion showM={showPreInsModal} email={emailAcu.correoElectronico} clickClose={() => { setShowPreInsModal(false) }} />)}
            <div className="row">
                <div className="containerCrumbs">
                    <div className="margenCrumb" style={{ backgroundColor: "transparent", height: "198px" }}>
                        <div className="crumbModulo">
                            <Link to={``} onClick={() => {  }} style={{ textDecoration: 'none', color: 'white', fontWeight: '', marginLeft: "-37px" }}> </Link>
                            <span><b>
                                {/* {typeProfile.includes("premium-swm") ? 'Configuracion perfil' : 'Mi perfil'} */}
                            </b></span>
                        </div>
                        <div  >
                            <div style={{ marginTop: "3rem" }}>
                                <h2 style={{ color: "white", fontFamily: "Next", fontSize: "34px", marginTop: "48px", marginLeft: "-35px" }}>

                                    Configura tu perfil
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="containerInfo">

                    <div className="card cardInfoprofile col-lg-10" style={{ margin: "0 auto", maxWidth: "990px", marginTop: "-40px", marginBottom: "70px", width: "93%" }}>
                        <div className="  col-12 " style={{ padding: '30px 60px' }}>
                            {iconTab === 4 ? (<p className="textBen" >Aquí puedes conocer tu información personal y actualizarla.</p>)
                                : iconTab === 1 ? (<p className="textBen" >Aquí puedes conocer tu información personal y actualizarla.</p>)
                                    : iconTab === 2 ? (<p className="textBen" >Hola {infoProfile.nombres}, actualiza tus datos personales cuando lo consideres necesario.</p>)
                                        : (<p className="textBen" >Hola {infoProfile.nombres}, cuéntanos más sobre ti. Ingresa o actualiza tus datos cuando lo consideres necesario.</p>)}
                        </div>
                        <div className="card cardAcordeon col-12 col-lg-11 mt-3 m-auto">
                            <div >
                                <Tabs defaultActiveKey={step} onSelect={(e) => { urlAnalitic(e) }} id="uncontrolled-tab-example" className="contenedorTabsprofile row">
                                    <Tab eventKey="informacion-personal" id="ProfileTabId" title={<span id="infoPerfilR-span" style={{ fontFamily: "OpenSans" }} >{iconTab === 1 ? (<img className="mr-2" src={infoActive} />) : (<img className="mr-2" src={infoInactive} />)}Tu información</span>} tabClassName="lineTab  col-lg-3 col-md-3 col-sm-4 col-9">
                                        <div className="contAcordeon pt-5">
                                            <div className="lineDefault row p-4 mt-4">
                                                <div className="col-12">
                                                    <h4 className="tituloRegistro" style={{ fontFamily: "", color: "#666666" }}>
                                                        Tu información personal
                                                    </h4>
                                                    <br />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 mt-2">
                                                    <label className="labelPerfil">Nombres</label>
                                                    <input type="text" className="form-control inputSProfile" name="nombres" value={infoProfile.nombres} onChange={(e) => { handleFormProfile(e) }} />
                                                    <small className="form-text text-danger">{validatePr.nombreError}</small>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 mt-2">
                                                    <label className="labelPerfil">Apellidos</label>
                                                    <input type="text" className="form-control inputSProfile" name="apellidoPaterno" value={infoProfile.apellidoPaterno} onChange={(e) => { handleFormProfile(e) }} />
                                                    <small className="form-text text-danger">{validatePr.apellidoError}</small>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 mt-2 profileDisabled">
                                                    <label className="labelPerfil">Tipo de documento</label>
                                                    <div className="inputSelc" >
                                                        <select
                                                            disabled
                                                            name="dicTipoDocumento"
                                                            className="form-control inputSProfile"
                                                            id="dicTipoDocumento"

                                                            value={infoProfile.dicTipoDocumento}
                                                        >
                                                            {json.map((data) => {
                                                                return (
                                                                    data.id === infoProfile.dicTipoDocumento && (
                                                                        <option key={data.id} value={data.id}>
                                                                            {data.value}
                                                                        </option>
                                                                    )
                                                                )
                                                            }
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 mt-2 profileDisabled">
                                                    <label className="labelPerfil">Número de documento</label>
                                                    <input disabled type="number" className="form-control inputSProfile btblock" name="identificacion" value={infoProfile.identificacion} />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 mt-2">
                                                    <label className="labelPerfil">Correo electrónico</label>
                                                    <input type="email" className="form-control inputSProfile" name="correoElectronico" value={infoProfile.correoElectronico} onChange={(e) => { handleFormProfile(e) }} />
                                                    <small className="form-text text-danger">{validatePr.correoError}</small>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 mt-2">
                                                    <label className="labelPerfil">Teléfono celular</label>
                                                    <input type="text" className="form-control inputSProfile" name="numeroCelular" value={infoProfile.numeroCelular} onChange={(e) => { handleFormProfile(e) }} />
                                                    <small className="form-text text-danger">{validatePr.celularError}</small>
                                                </div>
                                                <div className="text-center col-12">
                                                    <button className="col-12 btnGuardarAmarillo mt-5 mb-3" onClick={() => { validatorForms('profile') }}>
                                                        Guardar cambios

                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="canales-contacto" title={<span id="contactsR-span" style={{ fontFamily: "OpenSans" }}>
                                        {iconTab === 2 ? (<img className="mr-2" src={datosActive} />) : (<img className="mr-2" src={datosInactive} />)}Tus datos de contacto</span>} tabClassName="lineTab  col-lg-3 col-md-3 col-sm-5 col-12">

                                        <div className="contAcordeon pt-5" >
                                            <div className="lineDefault row p-4 pb-5 mt-4 mb-4">
                                                <div className="col-12" >
                                                    <h4 className="tituloRegistro"  >
                                                        Tus datos de contacto
                                                    </h4>
                                                    <br />
                                                    <p className="textBen" >
                                                        Recuerda ingresar tus datos de contacto para poder realizar futuras asesorías.
                                                    </p>
                                                </div>
                                                <div className="col-12">
                                                    <ReactSortable
                                                        list={state.list}
                                                        setList={newState => setState({ list: newState })} animation="150"
                                                        onChoose={() => setIsSorting(true)}
                                                        onUnchoose={() => setIsSorting(false)}
                                                        forceFallback={true}
                                                    >
                                                        {state.list.map(item => (
                                                            <div key={item.id} className="cursor">
                                                                <div className="mr-3 ml- borderBotom" style={{ cursor: "pointer" }}>
                                                                    <div className={`card-bodyy borderBoto ${item.id === -1 ? "pr-0" : ""}`}>
                                                                        <FontAwesomeIcon className="mr-3" style={{ color: "#D8D8D8" }} icon={faBars} />
                                                                        <div style={{ marginRight: "20px", display: "inline-block" }}>
                                                                            {item.id === -1 ? (<img src={iconCanal1} style={{ width: "30px" }} />)
                                                                                : item.tipoConctacto === 'Skype' ? (<img src={iconSkype} />) :
                                                                                    item.tipoConctacto === 'Celular' ? (<img src={iconPhone} style={{ width: "28px" }} />) :
                                                                                        (<img src={iconWa} />)}
                                                                        </div>
                                                                        <span className="spanContact">{item.tipoConctacto}</span>
                                                                        {item.id === -1 && (
                                                                            <Fragment>
                                                                                <select className={`input ml-3 mr-2 mb-1 mt-1 col-lg-3 col-md-12 col-sm-12 col-12  ${addSocial === true ? "d-inline-block" : "d-none"}`}
                                                                                    placeholder="Selecciona un contacto" value={formSocial.tipoSocial} style={{ color: "#9B9B9B", width: "107px", fontSize: "13px" }}
                                                                                    name="tipoSocial" onChange={(e) => { onChangeSocial(e) }}>
                                                                                    <option value="">Selecciona un contacto</option>
                                                                                    <option value="Skype">Skype </option>
                                                                                    <option value="Celular">Celular </option>
                                                                                    <option value="Whatsapp"> Whatsapp</option>
                                                                                </select>
                                                                                <input className={`input ml-3 mb-1 mt-1 col-lg-3 col-md-12 col-sm-12 col-12 ${addSocial === true ? "d-inline-block" : "d-none"}`}
                                                                                    name="contenidoSocial" id="inputPlace" onChange={(e) => { onChangeSocial(e) }} value={formSocial.contenidoSocial}
                                                                                    placeholder={typeInput === false ? "Cuenta de contacto" : "Número de contacto"}
                                                                                    type="text" style={{ color: "#9B9B9B", width: "167px", fontSize: "13px" }} maxLength={typeInput && "10"} />
                                                                                {addSocial && (<small className="form-text text-danger" style={{ position: "absolute", bottom: 0, left: "35.5em" }}>{validateCt.contactoError}</small>)}
                                                                                <div className="col-lg-2 pr-0 pl-4" style={{ display: "inline-block", whiteSpace: "nowrap" }}>
                                                                                    <span onClick={() => { saveSocial() }} className={` text-left BlueColor fontt-weight-bold ${addSocial === false ? "d-none" : "d-inline-block"}`}  >Guardar</span>
                                                                                    <img style={{ position: "absolute" }} src={iconClose} className={`mt-2 ml-3 ${addSocial === false ? "d-none" : "d-inline-block"}`} onClick={() => { setAddSocial(false) }} />
                                                                                </div>
                                                                            </Fragment>)}
                                                                        {item.id === -1 ? (<button style={{ padding: "4px 11px", marginRight: "1.25rem" }} type="button" className={`btnAddS ${addSocial === true ? "d-none" : "d-inline"}`} onClick={() => { setAddSocial(true) }}>Añadir canal</button>) :
                                                                            (<div style={{ float: item.id !== -1 ? "right" : "" }}>
                                                                                <span className="mr-3 spanContact">{item.contacto}</span>
                                                                                <img src={iconClose} onClick={() => { deleteSocial(item.id) }} />
                                                                            </div>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </ReactSortable>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="contrasena" title={<span id="passR-span" className="spacing" style={{ fontFamily: "OpenSans" }}>
                                        {iconTab === 3 ? (<img className="mr-2" src={passInactive} />) : (<img className="mr-2" src={passActive} />)}Tu contraseña</span>} tabClassName="lineTab  col-lg-3 col-md-3 col-9">
                                        <div className="contAcordeon pt-4">
                                            <div className="lineDefault row p-4 mt-4" >
                                                <div className="col-12">
                                                    <h4 className="tituloRegistro " >
                                                        Modifica tu contraseña
                                                    </h4>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 sm-12  mt-2  fron" >
                                                    <Input
                                                        label="Contraseña actual"
                                                        placeholder="Ingresa tu contraseña actual"
                                                        type="password"
                                                        error={validatePs.claveErro === "" ? false : true}
                                                        errorText={validatePs.claveErro}
                                                        id="claveAnterior"
                                                        name="claveAnterior"
                                                        value={passChange.claveAnterior}
                                                        onChangaInput={handleFormPass}
                                                        className="plhd input inputChangeEye"

                                                    />
                                                </div>
                                                <div className="  col-12 col-md-6 col-l   -6 mt-2">
                                                    <Input
                                                        label="Nueva contraseña"
                                                        placeholder="Ingresa tu nueva contraseña"
                                                        type="password"
                                                        error={validatePs.claveNError === "" ? false : true}
                                                        errorText={validatePs.claveNError}
                                                        id="clave"
                                                        name="clave"
                                                        value={passChange.clave}
                                                        onChangaInput={handleFormPass}
                                                        className="form-control plhd input inputChangeEye"
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 mt-2">
                                                    <Input
                                                        label="Confirmación nueva contraseña"
                                                        placeholder="Ingresa tu nueva contraseña"
                                                        type="password"
                                                        error={validatePs.claveCError === "" ? false : true}
                                                        errorText={validatePs.claveCError}
                                                        id="claveConfirmacion"
                                                        name="claveConfirmacion"
                                                        value={passChange.claveConfirmacion}
                                                        onChangaInput={handleFormPass}
                                                        className="form-control plhd input inputChangeEye"
                                                    />
                                                </div>
                                                <div className="text-center col-12">
                                                    <button className="col-12 btnGuardarAmarillo mt-5 mb-3" onClick={() => { validatorForms('pass') }}>
                                                        GUARDAR CAMBIOS
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                        {getUser().perfiles.includes('Telemedicina b') ? (
                                            <Tab eventKey="beneficiarios" tabClassName="lineTab col-md-3 col-lg-3 col-9" title={<span id="beneficiaryR-span" className="spacing" style={{ fontFamily: "OpenSans" }} >
                                                {iconTab === 4 ? (<img className="mr-3" src={beneficiaryActive} />) : (<img className="mr-3" src={beneficiaryInactive} />)}Tus beneficiarios</span>}>
                                                <div className="contAcordeon pt-5">
                                                    <div className="lineDefault row p-lg-4 p-md-4 p-0 mt-4">

                                                        <div className="col-12" style={{ padding: '5px' }}>
                                                            <h4 className="tituloRegistro">
                                                                Mis beneficiarios del Ecosistema
                                                            </h4>
                                                            <br />
                                                            <p style={{ fontFamily: 'Inter-Regular' }}>
                                                                Aquí encontrarás los beneficiarios que hacen parte de tu seguro. Puedes actualizarlos y editarlos mientras tu póliza esté vigente.
                                                                Ten en cuenta que solo puedes editar los beneficiarios de tu Ecosistema de Servicios, no los de tu seguro.
                                                            </p>
                                                        </div>
                                                        {getUser().perfiles.length >= 0 && (
                                                            listInfoCardBeneficiary.length != undefined && (
                                                                listInfoCardBeneficiary.map((element, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            {showFormEdit.id === index && showFormEdit.show === true ? (
                                                                                <FormBeneficiary onChange={handleFormBeneficiary} handleDataPicker={handleDataPicker} value={infoBeneficiary} documentTypes={documentTypes} kinship={kinship} servicesB={servicesB} country={country} clickClose={() => { setShowFormEdit({ id: index, show: false }) }} clickSave={() => { validatorSmall() }} clickDelete={() => { handleDeleteBeneficiary(element, index) }} />
                                                                            ) : (
                                                                                <CardBeneficiary idView={index} typeView="notNone" beneficiary={element} clickEdit={() => { handleControlForm('edit', element, index) }} clickDelete={() => { handleDeleteBeneficiary(element, index) }} />
                                                                            )}
                                                                        </React.Fragment>
                                                                    );
                                                                })
                                                            )
                                                        )}
                                                        {(listInfoCardBeneficiary.length < 5) && (
                                                            showFormNew === false ? (
                                                                <CardBeneficiary typeView="none" click={(e) => { handleControlForm('new') }} />
                                                            ) : (
                                                                <FormBeneficiary onChange={handleFormBeneficiary} value={infoBeneficiary} handleDataPicker={handleDataPicker} documentTypes={documentTypes} servicesB={servicesB} kinship={kinship} country={country} clickClose={() => { setShowFormNew(false) }} clickSave={() => { validatorSmall() }} clickDelete={() => { handleDeleteBeneficiary('') }} />
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </Tab>
                                        ) : (
                                            <React.Fragment></React.Fragment>
                                        )}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}