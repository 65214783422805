import React, { useState, useEffect } from "react";
import { urlAPI } from '../../utils/config';
import { getToken } from '../../utils/validations';
import { isEmpty } from "lodash";
import Axios from "axios";
import Swal from "sweetalert2";
import { Pdf, pdfTelemedicina } from "../../components/pdf/Pdf";
import ModalSaveBene from "../../components/modal/ModalSaveBene";
import Loader from '../../components/loader/Loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function AuthorizationUnderAge(props) {
    //console.log(props);
    function defaultFormValue() {
        return {
            idBeneficiario: '',
            nombres: "",
            apellidos: "",
            dicParentesto: "29156",
            identificacion: "",
            dicTipoIdentificacion: 0,
            dicTipoDocumento: 0,
            nombresTutor: "",
            apellidosTutor: "",
            identificacionTutor: "",
            idAutorizacion: '',
            link: '',
            servicio: '',
        };
    }
    const [infoBeneficiary, setInfoBeneficiary] = useState(defaultFormValue);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [kinship, setKinship] = useState([]);
    const [show, setShow] = useState("none");
    const [disabled, setDisabled] = useState(true);
    const [background, setBackground] = useState("#D8D8D8");
    const [border, setBorder] = useState("#D8D8D8");
    const [color, setColor] = useState("#ffffff")
    const [cajaShow, setCajaShow] = useState("flex");
    const [count, setCount] = useState(0);
    const [loaderShow, setLoaderShow] = useState(false);
    const [showModalSave, setShowModalSave] = useState(false);
    const validacion = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
    const validacionDoc = /[0-9]/;
    const typeDic = [1, 1008, 1009];
    const { step } = props.match.params;
    const { showM,infoMenor,clickSave,close } = props;

    //console.log(props.match.params);
    useEffect(() => {
        if (count == 0 && (step !== '' || !step.includes('disclaimer'))) {
            getInfoBeneficiary();
            typeDic.forEach((e) => {
                getDiccionario(e);
            });
            setCount(count + 1);
            document.getElementById('CardBorder').setAttribute('style', 'border:1px solid rgb(217, 217, 217) !important;');
        }
        validatorForms();

    });

    const getInfoBeneficiary = () => {
        // //console.log('f');
        Axios.post(`${urlAPI}/Login/autorizacionMenores`, JSON.stringify({ link: step }), {
            headers: { "Content-Type": "application/json", }
        }).then(scc => {
            //console.log(scc);
            if (scc.data.codigo === 0) {
                setInfoBeneficiary({
                    idBeneficiario: scc.data.data.idBeneficiario,
                    nombres: scc.data.data.nombres,
                    apellidos: scc.data.data.apellidos,
                    nombresTutor: "",
                    apellidosTutor: "",
                    dicTipoIdentificacion: scc.data.data.dicTipoIdentificacionBen,
                    dicTipoDocumento: 0,
                    identificacionTutor: "",
                    identificacion: scc.data.data.identificacion,
                    dicParentesto: "29156",
                    idAutorizacion: scc.data.dataExtra,
                    link: step,
                    servicio: scc.data.data.servicio,
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Opss!',
                    text: 'Link vencido',
                    customClass: {
                        confirmButton: ' btnGuardar ',
                    },
                });
            }
        }).catch(error => {
            //console.log(error);
        });
    }
    const getDiccionario = (e) => {
        Axios.get(`${urlAPI}/Login/Tipos/${e}`, { headers: { "Content-Type": "application/json", } }).then(succ => {
            if (e === 1) {
                setDocumentTypes(succ.data.data);
            } else if (e === 1008) {
                setKinship(succ.data.data);
            }
        }).catch(error => {
            //console.log(error);
        });
    }
    const handleFormBeneficiary = e => {
        setInfoBeneficiary({ ...infoBeneficiary, [e.target.name]: e.target.value });
    }

    const [validatePr, setValidatePr] = useState({
        nombreError: '',
        apellidoError: '',
        nombresTutorError: '',
        apellidosTutorError: '',
        tipoError: '',
        tipoTutorError: '',
        identificacionError: '',
        identificacionTutorError: '',
        dicParentestoError: '',
    });
    const validarNombre = () => {
        let nombreError = '';
        if (!infoBeneficiary.nombres) {
            nombreError = 'El campo Nombres es obligatorio';
        }
        if (!validacion.test(infoBeneficiary.nombres) && !isEmpty(infoBeneficiary.nombres)) {
            nombreError = "Nombre no valido";
        }
        setValidatePr({ ...validatePr, nombreError });
        validatorForms();
    }
    const validarApellido = () => {
        let apellidoError = '';
        if (!infoBeneficiary.apellidos) {
            apellidoError = 'El campo Apellidos es obligatorio';
        }
        if (!validacion.test(infoBeneficiary.apellidos) && !isEmpty(infoBeneficiary.apellidos)) {
            apellidoError = "Apellido no valido";
        }
        setValidatePr({ ...validatePr, apellidoError });
        validatorForms();
    }
    const validarTipo = () => {
        let tipoError = '';
        if (infoBeneficiary.dicTipoIdentificacion === 0) {
            tipoError = 'El campo tipo de documento es obligatorio';
        }
        setValidatePr({ ...validatePr, tipoError });
        validatorForms();
    }
    const validarID = () => {
        let identificacionError = '';
        if (!infoBeneficiary.identificacion) {
            identificacionError = 'El campo identificacion es obligatorio';
        }
        if (!validacionDoc.test(infoBeneficiary.identificacion) && !isEmpty(infoBeneficiary.identificacion)) {
            identificacionError = "Identificacion no valido";
        }
        setValidatePr({ ...validatePr, identificacionError });
        validatorForms();
    }
    const validaIDTutor = () => {
        let identificacionTutorError = '';
        if (!infoBeneficiary.identificacionTutor) {
            identificacionTutorError = 'El campo identificacion es obligatorio';
        }
        if (!validacionDoc.test(infoBeneficiary.identificacionTutor) && !isEmpty(infoBeneficiary.identificacionTutor)) {
            identificacionTutorError = "identificacion no valido";
        }
        setValidatePr({ ...validatePr, identificacionTutorError });
        validatorForms();
    }
    const validarNombreTutor = () => {
        let nombresTutorError = '';
        if (infoBeneficiary.nombresTutor === '') {
            nombresTutorError = 'El campo Nombres es obligatorio';

        }
        if (!validacion.test(infoBeneficiary.nombresTutor) && !isEmpty(infoBeneficiary.nombresTutor)) {
            nombresTutorError = "Nombre no valido";
        }
        setValidatePr({ ...validatePr, nombresTutorError });
        validatorForms();
    }
    const validarApellidoTutor = () => {
        let apellidosTutorError = '';
        if (!infoBeneficiary.apellidosTutor) {
            apellidosTutorError = 'El campo Apellidos es obligatorio';
        }
        if (!validacion.test(infoBeneficiary.apellidosTutor) && !isEmpty(infoBeneficiary.apellidosTutor)) {
            apellidosTutorError = "Apellido no valido";
        }
        setValidatePr({ ...validatePr, apellidosTutorError });
        validatorForms();
    }
    const validarTipoTutor = () => {
        let tipoTutorError = '';
        if (infoBeneficiary.dicTipoDocumento.toString() === "0") {
            tipoTutorError = 'El campo Tipo de Documento es obligatorio';
        }
        setValidatePr({ ...validatePr, tipoTutorError });
        validatorForms();
    }
    const validarParentesco = () => {
        let dicParentestoError = '';
        if (infoBeneficiary.dicParentesto.toString() === 0) {
            dicParentestoError = 'El campo dicParentesto es obligatorio';
        }
        setValidatePr({ ...validatePr, dicParentestoError });
        validatorForms();
    }
    const validatorForms = () => {
        if (infoBeneficiary.nombres.trim() === "" || infoBeneficiary.apellidos.trim() === "" || infoBeneficiary.nombresTutor.trim() === "" || infoBeneficiary.apellidosTutor.trim() === "" || infoBeneficiary.dicTipoIdentificacion.toString() === "0" || infoBeneficiary.dicTipoDocumento.toString() === "0" || infoBeneficiary.identificacionTutor === "" || infoBeneficiary.dicParentesto.toString() === "0") {
            setBackground("#D8D8D8");
            setBorder("#D8D8D8");
            setColor("#ffffff");
            setDisabled(true);
        }
        else {
            setDisabled(false);
            setBackground("#323B4E");
            setBorder("#323B4E");
            setColor("#fff");
        }
    }

    const continuar = (count) => {
        if (count === 1) {
            setCajaShow("none");
            props.history.push(`/autorizacion-menores/disclaimer`);
        } else {
            setCajaShow("none");
            props.history.push(`/autorizacion-menores/disclaimer-telemedicina`);
        }

    }
    const dobleEnter = (
        <>
            <br />
            <br />
        </>
    );
    const enterText = (
        <>
          <br />
        </>
      );
    const handleSaveBeneficiary = () => {
        setLoaderShow(true)
        let parentesto = infoBeneficiary.dicParentesto.toString() === "29157" ? 'Tutor' : 'Padre';
        var date = new Date();
        var info = '';
        Axios.get('https://ipapi.co/json/?key=vgn91pD3PXJhodzX3llb7PGxJpyemvUhamyxnhm8fTSvNJ9J5P').then(succ => {
            info = JSON.stringify({
                pagina: "AutorizacionMenores",
                control: "Guardando informacion de la autorizacion",
                direccion_ip: succ.data.ip,
                ciudad_localizacion: succ.data.city,
                horaLocal: `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`,
                fechaLocal: `${date.getDate()}/${(date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}/${date.getFullYear()}`,
                extra: "nombreCompletoTutor: " + infoBeneficiary.nombresTutor + " " + infoBeneficiary.apellidosTutor +
                    ", identificacionTutor: " + infoBeneficiary.identificacionTutor + ", parentesto: " + parentesto,
            })
            let obj = {
                idAutorizacion: infoBeneficiary.idAutorizacion,
                idBeneficiario: infoBeneficiary.idBeneficiario,
                pdf: Pdf(0, infoBeneficiary.nombresTutor + ' ' + infoBeneficiary.apellidosTutor, infoBeneficiary.identificacionTutor),
                pdfTelemedicina: infoBeneficiary.servicio.includes("telemedicina") ? pdfTelemedicina(infoBeneficiary.nombres + ' ' + infoBeneficiary.apellidos, infoBeneficiary.identificacion) : '',
                link: infoBeneficiary.link,
                nombresTutor: infoBeneficiary.nombresTutor,
                apellidosTutor: infoBeneficiary.apellidosTutor,
                dicTipoDocumento: infoBeneficiary.dicTipoDocumento,
                identificacionTutor: infoBeneficiary.identificacionTutor,
                dicParentesto: infoBeneficiary.dicParentesto,
                log: info
            }
            Axios.post(`${urlAPI}/Login/guardarAutorizacionMenores`, JSON.stringify(obj), {
                headers: { "Content-Type": "application/json", }
            }).then(succe => {
                setLoaderShow(false)
                setShowModalSave(true);
            }).catch(error => {

            });
        }).catch();
    }
    const handleWindow = ()=>{
        window.close();
        setShowModalSave(false);
    }
    return (
        <React.Fragment>
            {loaderShow && (<Loader show={loaderShow} />)}
            {showModalSave && (<ModalSaveBene showM={showModalSave} clickClose={() => { handleWindow() }} typeTitle="AUTO" />)}
            <div className="container">
                <div className="row contentTituloAutorizacion containerCrumbs">
                    <p className="tituloAutorizacion" style={{ marginLeft: '17%' }}>
                        Registro de beneficiario menor de 18 años
                    </p>
                </div>
                <div className="containerInfo" style={{ marginTop: "-67px" }}>
                    <div id="CardBorder" className="card cardinfoBeneficiary col-lg-9 col-sm-12 " style={{ margin: "auto" }}>
                        <p style={{ fontFamily: "Inter-bold", fontSize: "22px", color: '#5D676C', marginBottom: "45px"}}>
                            Autorización informada para datos de menores de edad
                        </p>
                        <div className="border" style={{ padding: "1rem" }}>
                            {!step.includes('disclaimer') && (
                                <div className="row col-12 mt-2" style={{ margin: "0 auto" }}>
                                    <div className="col-12 mt-4">
                                        <p style={{ fontFamily: "inter-regular" }}>
                                            Gracias a tu seguro puedes inscribir a tus hijos menores de 18 años
                                            como beneficiarios del servicio Médico a un clic, que hace parte de
                                            tu Ecosistema. Por favor, diligencia todos los campos y recuerda
                                            validar muy bien la información que estás ingresando.
                                        </p>
                                        <p style={{ fontFamily: "inter-regular" }}>
                                            DATOS DEL MENOR DE EDAD
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 mt-2">
                                        <label className="textosBeneficiario-auto">Nombres</label>
                                        <input type="text" className="form-control" name="nombres" placeholder='Ingrese un nombre'
                                            value={infoBeneficiary.nombres}
                                            onChange={(e) => { handleFormBeneficiary(e) }}
                                            onKeyUp={() => { validarNombre() }}
                                            onClick={() => { validarNombre() }}
                                            disabled='disabled'
                                        />
                                        <small className="form-text text-danger">{validatePr.nombreError}</small>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 mt-2">
                                        <label className="textosBeneficiario-auto">Apellidos</label>
                                        <input type="text" className="form-control" name="apellidos" placeholder='Ingrese los apeliidos'
                                            value={infoBeneficiary.apellidos}
                                            onChange={(e) => { handleFormBeneficiary(e) }}
                                            onKeyUp={() => { validarApellido() }}
                                            onClick={() => { validarApellido() }}
                                            disabled='disabled'
                                        />
                                        <small className="form-text text-danger">{validatePr.apellidoError}</small>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-3 mt-2">
                                        <label style={{ whiteSpace: "nowrap" }} className="textosBeneficiario-auto">Tipo de documento</label>
                                        <select
                                            name="dicTipoIdentificacion"
                                            className="form-control select-css3 "
                                            id="dicTipoIdentificacion"
                                            onChange={(e) => { handleFormBeneficiary(e) }}
                                            value={infoBeneficiary.dicTipoIdentificacion}
                                            onClick={() => { validarTipo() }}
                                            disabled='disabled'
                                        >
                                            <option value="29162">T.I</option>
                                        </select>
                                        <small className="form-text text-danger">{validatePr.tipoError}</small>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-3 mt-2" style={{ /*paddingLeft: "30px"*/ }}>
                                        <label className="textosBeneficiario-auto" style={{ whiteSpace: 'pre' }}>Número de documento</label>
                                        <input type="number" className="form-control " name="identificacion" id="identificacion"
                                            value={infoBeneficiary.identificacion} placeholder='Ingresar No de documento'
                                            onChange={(e) => { handleFormBeneficiary(e) }}
                                            onKeyUp={() => { validarID() }}
                                            onClick={() => { validarID() }}
                                            disabled='disabled'
                                        />
                                        <small className="form-text text-danger">{validatePr.identificacionError}</small>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 mt-2">
                                        <label className="textosBeneficiario-auto">¿Cuál es su parentesco con el menor?</label>
                                        <select
                                            name="dicParentesto"
                                            id="dicParentesto"
                                            className="form-control mr-2 btnNuevoBeneficiario textosBeneficiarios selecticon"
                                            style={{ /*minWidth: "200px"*/ }}
                                            onChange={(e) => { handleFormBeneficiary(e) }}
                                            value={infoBeneficiary.dicParentesto}
                                            onBlur={() => { validarParentesco() }}
                                        >
                                            <option value="29156">Padres</option>
                                            <option value="29157">Tutor</option>
                                        </select>
                                        <small className="form-text text-danger">{validatePr.dicParentestoError}</small>
                                    </div>

                                    <div className="col-12 mt-4">
                                        <p>
                                            DATOS DEL PADRE O TUTOR
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 mt-2">
                                        <label className="textosBeneficiario-auto">Nombres</label>
                                        <input type="text" className="form-control" name="nombresTutor" placeholder='Ingrese un nombre'
                                            value={infoBeneficiary.nombresTutor}
                                            onChange={(e) => { handleFormBeneficiary(e) }}
                                            onKeyUp={() => { validarNombreTutor() }}
                                            onClick={() => { validarNombreTutor() }}
                                        />
                                        <small className="form-text text-danger">{validatePr.nombresTutorError}</small>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 mt-2">
                                        <label className="textosBeneficiario-auto">Apellidos</label>
                                        <input type="text" className="form-control" name="apellidosTutor" placeholder='Ingrese los apeliidos'
                                            value={infoBeneficiary.apellidosTutor}
                                            onChange={(e) => { handleFormBeneficiary(e) }}
                                            onKeyUp={() => { validarApellidoTutor() }}
                                            onClick={() => { validarApellidoTutor() }}
                                        />
                                        <small className="form-text text-danger">{validatePr.apellidosTutorError}</small>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-3 mt-2">
                                        <label style={{ whiteSpace: "nowrap" }} className="textosBeneficiario-auto">Tipo de documento</label>
                                        <select
                                            name="dicTipoDocumento"
                                            className="form-control select-css3 estiloSelect"
                                            id="dicTipoDocumentoAuth"
                                            value={infoBeneficiary.dicTipoDocumento}
                                            onChange={(e) => { handleFormBeneficiary(e) }}
                                            onClick={() => { validarTipoTutor() }}
                                        >
                                            <option value="0">Elegir</option>
                                            {documentTypes.map((document) => (
                                                <option key={document.id} value={document.id}>
                                                    {document.text}
                                                </option>
                                            ))}
                                        </select>
                                        <small className="form-text text-danger">{validatePr.tipoTutorError}</small>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-3 mt-2 estiloDocumento" style={{ paddingLeft: "30px", whiteSpace: "nowrap" }}>
                                        <label className="textosBeneficiario-auto" style={{ marginLeft: "3px" }}>Número de documento</label>
                                        <input
                                            type="number" className="form-control " name="identificacionTutor" id="identificacionTutor"
                                            value={infoBeneficiary.identificacionTutor} placeholder='Ingresar No de documento'
                                            onChange={(e) => { handleFormBeneficiary(e) }}
                                            onKeyUp={() => { validaIDTutor() }}
                                            onClick={() => { validaIDTutor() }}
                                        />
                                        <small className="form-text text-danger">{validatePr.identificacionTutorError}</small>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-5 mt-2">
                                        <div className="row pt-2">
                                            <button className="btnMain" style={{ width: "206px", borderColor: border, backgroundColor: background, padding: "10px 15px", borderRadius: "5px", fontSize: "16px", margin: "26px 20px", color: color, fontFamily: 'Inter-Bold', fontWeight: 100 }}
                                                onClick={() => { continuar(1) }} disabled={disabled}
                                            >
                                                Continuar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {step === 'disclaimer' && (
                                <div className="row col-12 mt-2" style={{ margin: "0 auto" }}>
                                    <div className="col-12 mt-4"> 
                                    
                                        <p style={{ fontFamily: "Inter-regular", fontSize:'14px', color:'#58595B', padding: '0px 10px'}}>                                          
                                            Lea atentamente la autorización informada para datos de menores de edad y haga clic en aceptar para finalizar el proceso.
                                            {dobleEnter}
                                            De acuerdo con la Ley Estatutaria 1581 de 2012 de Protección de Datos y con el Decreto 1377 de 2013 y demás normas concordantes, <strong>al dar click en “aceptar” autorizo a CARDIF COLOMBIA SEGUROS GENERALES S.A  “CARDIF”, </strong> en calidad de padre, representante o tutor legal del menor: <strong> {infoBeneficiary.nombres} {infoBeneficiary.apellidos}</strong>, identificado con T.I. No.: <strong> {infoBeneficiary.identificacion}</strong>,
                                            que sus datos incluidos en este documento para el ingreso como beneficiario del ecosistema de servicios, sean incorporados a una base de datos responsabilidad de CARDIF COLOMBIA SEGUROS GENERALES S.A., siendo tratados con la finalidad de cumplir los propósitos y fines de la prestación del servicio, para la cuales sus datos serán tratados, así: i. Adelantar la gestión administrativa que CARDIF COLOMBIA SEGUROS GENERALES S.A. requiera respecto de sus clientes, 
                                            aliados, proveedores, empleados y demás grupos de interés; ii. Desarrollar estudios internos sobre los intereses de los Titulares, con el objetivo de comprender mejor las necesidades e intereses de los Titulares, para brindar un mejor servicio; iii. Enviar correspondencia, correos electrónicos, contactar telefónicamente o por cualquier medio disponible a los Titulares, en desarrollo de actividades publicitarias, promocionales y de mercadeo enfocados a su actividad; iv. Compartirlos o enviarlos a terceros con quienes CARDIF COLOMBIA SEGUROS GENERALES S.A. realice alianzas o celebre contratos, para fines comerciales relacionados con la ejecución de las actividades comprendidas dentro de su objeto social; y Llevar a cabo Transferencia y Trasmisión de los Datos Personales a terceros con quienes realice alianzas relacionadas con su objeto social, contrate estudios o les encargue el tratamiento de datos. v. Estudiar internamente los hábitos de consumo de los bienes y servicios que ofrece y presta CARDIF COLOMBIA SEGUROS GENERALES S.A. 
                                            {dobleEnter}
                                            <strong style={{ fontFamily:'Inter-bold'}}>DATOS PERSONALES QUE UTILIZAMOS</strong>
                                            {dobleEnter}
                                            Para llevar a cabo las finalidades descritas en el presente aviso, CARDIF COLOMBIA SEGUROS GENERALES S.A. utilizará los siguientes Datos Personales de los cuales el usuario y/o visitante es Titular al ingresar a la página web [*] y al utilizar sus servicios: 
                                            {dobleEnter} 
                                            <ul>
                                                <li>Datos de identificación </li>
                                                <li>Datos de contacto</li>
                                                <li>Fecha de nacimiento</li>
                                                <li>Nombre completo</li>
                                                <li>Correo electrónico</li>
                                                <li>Edad</li>
                                                <li>Teléfono fijo o teléfono celular</li>
                                                <li>Lugar de nacimiento</li>
                                            </ul>
                                            { enterText}
                                            En cumplimiento de la Ley 1581 de 2012 se advierte al titular de la información que puede ejercitar los derechos de acceso, corrección, supresión, revocación o reclamo por infracción sobre sus datos, mediante escrito dirigido a CARDIF COLOMBIA SEGUROS GENERALES S.A., a la dirección de correo electrónico atencionalcliente@cardif.com.co, indicando en el asunto el derecho que desea ejercitar.  
                                        </p>
                                        <div className="col-12 col-md-12 col-lg-12 mt-2 mb-2">
                                            <div className="row justify-content-center pt-2">
                                                {infoBeneficiary.servicio.includes("telemedicina") ?
                                                    (<button onClick={() => { continuar(2) }} className="btnMain" style={{ width: "220px", height: "48px", backgroundColor: "#323B4E", padding: "10px 15px", borderRadius: "5px", fontSize: "16px", margin: "26px 20px", color: "#fff" }}>
                                                        Aceptar
                                                    </button>) :
                                                    (<button onClick={() => { handleSaveBeneficiary() }} className="btnMain" style={{ width: "220px", height: "48px", backgroundColor: "#323B4E", padding: "10px 15px", borderRadius: "5px", fontSize: "16px", margin: "26px 20px", color: "#fff" }}>
                                                        Aceptar
                                                    </button>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {step === "disclaimer-telemedicina" && (
                                <div className="row col-12 mt-2" style={{ margin: "0 auto" }}>
                                    <div className="col-12 mt-4">
                                        <p style={{ fontWeight: "700" }}>
                                            CONSENTIMIENTO INFORMADO CONSULTA TELEMEDICINA PARA MENOR DE EDAD
                                        </p>
                                    </div>
                                    <div className="col-12 mt-4" style={{ fontFamily: "Inter-regular" }}>
                                        <p>
                                            De acuerdo con la Ley Estatutaria 1581 de 2012 de Protección de Datos y con el Decreto 1377 de 2013 y demás normas concordantes al dar click en “aceptar”, en calidad de padre, representante o tutor legal del menor: <strong>{infoBeneficiary.nombres} {infoBeneficiary.apellidos}</strong>, identificado con T.I. No.: <strong>{infoBeneficiary.identificacion}</strong>, Declaro en nombre de mi representado, en pleno uso de mis facultades mentales que hemos recibido una explicación y descripción clara sobre el servicio de Consulta bajo la modalidad de Telemedicina que va a recibir, mediante la cual un Profesional de la Salud, haciendo uso de una herramienta de videollamada, hará una valoración del estado de salud del menor de edad, con el fin de obtener un concepto médico y recomendar el curso de acción que considere necesarios para la atención solicitada.
                                        </p>
                                        <p>
                                            Adicionalmente entendemos que este servicio no tiene como objeto la atención de casos de urgencias o emergencias vitales en las cuales pueda estar en riesgo la vida del paciente y entendemos claramente que en estos casos es necesario dirigirse a un centro de atención de urgencias médicas de forma inmediata.
                                        </p>
                                        <p>
                                            <strong>Derechos y deberes del paciente</strong><br />
                                            Entendemos que como usuario del servicio de telemedicina se tienen los siguientes derechos:
                                            <ul>- A recibir información sobre mi salud, trámites administrativos y demás requisitos para la prestación del servicio por medio de telemedicina.</ul>
                                            <ul>- A recibir un trato humanizado, cordial, respetuoso y privado.</ul>
                                            <ul>- A exigir respeto por mis creencias, religión, costumbres, hábitos e identidad sexual.</ul>
                                            <ul>- A presentar quejas, reclamos, sugerencias y felicitaciones de los servicios prestados.</ul>
                                            <ul>- A que mis datos sean tratados con reserva y confidencialidad.</ul>
                                        </p>
                                        <p>
                                            Adicionalmente, aceptamos y entendemos que se tienen los siguientes deberes irrevocables para el desarrollo de los servicios por medio de telemedicina:
                                            <ul>- Brindar información veraz y completa a los Profesionales de la Salud para el desarrollo de mi atención.</ul>
                                            <ul>- Cumplir con las políticas y reglas del prestador de los servicios de salud.</ul>
                                            <ul>- En caso de asistencia para menores de edad, contar con la presencia de uno de sus padres o tutor.</ul>
                                        </p>
                                        <p>
                                            <strong>Autorización uso de la información y consentimiento informado</strong><br />
                                            Manifiesto que WTA IPS, me ha informado que los datos personales solicitados durante el desarrollo de esta asistencia serán tratados para los fines relacionados con su objeto social y en especial para fines legales, contractuales, comerciales de acuerdo con lo establecido en nuestra Política de Tratamiento de Datos Personales y dando estricto cumplimiento con lo ordenado por la Ley 1581 de 2012 y el Decreto 1377 de 2013 de la República de Colombia.
                                        </p>
                                        <p>
                                            WTA IPS garantiza la confidencialidad, libertad, seguridad, veracidad, transparencia, acceso y circulación de mis datos y en caso de solicitudes respecto a los datos recolectados, puede seguir el procedimiento definido en la Política de Tratamiento de Datos Personales de la IPS que encuentra en nuestra página web. (https://wtaips.com)
                                        </p>
                                        <p>
                                            Si después de leer detenidamente esta información, está totalmente de acuerdo con lo aquí comunicado, por favor proceda a ingresar en el siguiente link para dar inicio al servicio de Telemedicina.
                                        </p>
                                        <div className="col-12 col-md-12 col-lg-12 mt-2 mb-2">
                                            <div className="row justify-content-center pt-2">
                                                <button onClick={() => { handleSaveBeneficiary() }} className="btnMain" style={{ width: "220px", height: "48px", backgroundColor: "#323B4E", padding: "10px 15px", borderRadius: "4px", fontSize: "14px", margin: "26px 20px" }}>
                                                    Aceptar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}