import React from "react";
import {Link} from "react-router-dom";
import Card from "react-bootstrap/Card";

export default function ContainerService(props){
    const {tittle,children,linkBread,styleTittle} = props;
    const styles={
        background: "#222222",
        padding: "0px",
        height: "235px",   
    };
    return(
        <div className="container">
            <div className="row">
                <div className="col-12 banner-style containerCrumbs" style={styles}>
                    {linkBread.length > 0 &&(<div className="crumbModulo breadCrumbs">
                        {linkBread.map((element,i)=>{
                            return(
                                <Link key={i} to={element.to} className="linkBread">{element.tittle}</Link>
                            );
                        })}
                    </div>)}
                    <div className='text-center'>
                        <div className='text-left'>
                            <h1 className="tittleService" id={tittle == "Networking"? "tittle-pricipal": tittle == 'Centro de ayuda'? "tittleLEft":(tittle == 'Médico a un clic' || tittle == 'Orientación psicológica')?'tittleMedi':''}>{tittle}</h1>
                        </div>
                    </div>
                </div>
                <div className="col-12" style={{background:'rgb(245, 245, 245)'}}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <Card className="contenedorCards cardServices col-lg-10 col-sm-12" style={{maxWidth:"1030px"}}>
                                    <Card.Body className="card-bodyS padd-text">
                                        {children}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
}