
function useCustomDate (){

    /*Retorna hora en formato YYYY-mm-ddT00:00:00.000Z*/
    const convertToISOFormat = (date, time)=>{
        //console.log(time)
        const [hour, minute ] = time.toString().split(':');
        const dateFormat = new Date(`${date.toDateString()} ${time}`);
        let year = dateFormat.getFullYear();
        let month = dateFormat.getMonth() + 1;
        let day = dateFormat.getDate();

        let formattedHour = parseInt(hour);

        if (time.includes('PM') && formattedHour !== 12){
            formattedHour += 12
        } else if (time.includes('AM') && formattedHour === 12){
            formattedHour = 0;
        }

        const isDate = new Date(Date.UTC(year, month - 1, day, formattedHour, parseInt(minute),0)).toISOString();

        return isDate.toString();

    }

    /*Retorna hora en formato HH:mm:ss*/
    const convertToISOFormatTime = (date, time)=>{
        let dateConverted = convertToISOFormat(date, time);
        return dateConverted.substring(11,19);
    }

    /*Retorna fecha en hora h:mm AM*/
    const convertFormatTime12H = (time)=>{
        if(!time.includes('AM') && !time.includes('PM')){
            const [hour, minute ] = time.split(':');
            if (hour >= 12) {
                let hora = hour === "12" ? parseInt(hour) :parseInt(hour) - 12
                return `${hora}:${minute} PM`;
            }else{
                return `${hour}:${minute} AM`;
            }
        }
        return time;
    }

    /*Retorna fecha en el formato deseado Ej: [MMMM YYYY],[DD de MMM de YYYY],[YYYY-MM-DD],[DD/MM/YYYY]*/
    const convertToFormatDate = (date, format=false)=>{
        if(!format) format = 'DD/MM/YYYY';

        let dateFormated = format;
        let months = ['','Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
        let months_short = ['','ene','feb','mar','abr','may','jun','jul','ago','sep','oct','nov','dic'];
        let days = ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sabado'];

        const dateFormat = new Date(date);
        let year = dateFormat.getFullYear();
        let month = dateFormat.getMonth() + 1;
        let day = dateFormat.getDate();
        let numberday = dateFormat.getDay();
        
        dateFormated = dateFormated.replace('YYYY',year);
        dateFormated = dateFormated.replace('MMMM',months[month]);
        dateFormated = dateFormated.replace('MMM',months_short[month]);
        dateFormated = dateFormated.replace('MM',month.toString().padStart(2, 0));
        dateFormated = dateFormated.replace('M',month);
        dateFormated = dateFormated.replace('DD',day.toString().padStart(2, 0));
        dateFormated = dateFormated.replace('D',day);
        dateFormated = dateFormated.replace('dddd',days[numberday]);
        
        return dateFormated.toString();
    }

  return {
    convertToISOFormat,
    convertToISOFormatTime,
    convertFormatTime12H,
    convertToFormatDate,
  }
}

export default useCustomDate