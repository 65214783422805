import React, { useEffect, useState } from 'react';
import ContainerService from '../../../../components/container-service/ContainerService';
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import "../css/networkingStyle.css";
import Axios from "axios";
import { urlAPI } from '../../../../utils/config';
import { getUser, getToken, parseDateHour } from '../../../../utils/validations';
import Edificio from '../../../../assets/img/icon/standard/ciudad.svg';
import Calendar from '../../../../assets/img/seguridad-informatica/calendar.svg';
import { useHistory, useParams } from 'react-router-dom';
import CardService from '../../../../components/card-service/CardService';
import TypeAlert from '../../../../components/alert/TypeAlert';
import volver from "../../../../assets/img/score-financiero/volver-gray.svg";
import Swal from 'sweetalert2';

function defaultDetailEvent() {
    return {
        conferencista: "",
        descripcionEvento: "",
        fechaCreacion: "",
        fechaEvento: "",
        horaEvento: "",
        idCiudad: "",
        idEvento: "",
        idTipoEvento: "",
        inscrito: null,
        lugarEvento: "",
        nombreConferencista: "",
        rutaImagen: "",
        vigente: null,
    }
}
function NetworkingEventDetail({ match }) {
    const headers = { headers: { "Content-Type": "application/json", Authorization: "Bearer " + getToken() } };
    let history = useHistory();
    const { page } = useParams();
    const linkbread = [{
        to: "/auth/inicio",
        tittle: "Inicio > "
    },
    {
        to: "/ITT/auth/networking",
        tittle: "Networking > ",

    },
    {
        to: "/ITT/auth/networking/servicios/eventos",
        tittle: "Servicios > ",
    },
    {
        to: `/ITT/auth/networking/servicios/eventos/evento-ID`,
        tittle: "Eventos de networking",
    },
    ];
    const [detailEvent, setDetailEvent] = useState(defaultDetailEvent);
    const [detailInscription, setDetailInscription] = useState({ idEvento: null, idInscripcionEvento: null })
    const [stateButton, setStateButton] = useState({ onClick: null, textButton: null });
    const [infoCard, setInfoCard] = useState({ place: null, text: null, isShowButtons: true });
    const [step, setStep] = useState('detalle-evento');
    const [contentAlert, setContentAlert] = useState({ checkText: 'Confirmación de inscripción éxitosa', variant: 'success', class: 'alertNetworking', alertText: 'Pronto recibirás un correo electrónico con la confirmación del evento programado.' });

    const getListEvents = (idEvent) => {
        window.scroll(0, 0);
        Axios.get(`${urlAPI}/networking/listaEventos/${1}`, headers)
            .then(({ data }) => {
                let eventDetail = data.data.find(x => parseInt(x.idEvento) === parseInt(idEvent));
                setDetailEvent(eventDetail);
                handleShowInscription(idEvent, eventDetail);
            }).finally();
    }

    const handleSubscription = (event) => {
        let obj = {
            idEvento: history.location.state,
            IdUsuario: getUser().idUsuario,
        }
        Axios.post(`${urlAPI}/networking/AddInscripcion`, obj, headers)
            .then((res) => {
                let { data, codigo } = res.data;
                if (codigo === 0) {
                    handleShowInscription(history.location.state, event);
                    historyChange(true)
                }
            }).finally();
    }
    const handleShowInscription = (id, eventDetail) => {
        //console.log(id,headers);
        Axios.get(`${urlAPI}/networking/verNetworkingInscripcionEvento/${id}`, headers)
            .then((res) => {
                //console.log(res);
                let { data, codigo } = res.data;
                //console.log(codigo);
                //console.log(eventDetail.lugarEvento);
                if (codigo === 0) {
                    let { idInscripcionEvento, idEvento } = data;
                    setDetailInscription({ ...detailInscription, idInscripcionEvento, idEvento });
                    setInfoCard({ ...infoCard, place: eventDetail.lugarEvento, text: parseDateHour(eventDetail.fechaEvento, eventDetail.horaEvento) });
                    setStateButton({ ...stateButton, onClick: () => { historyChange(true) }, textButton: 'Ver inscripción del evento' });
                } else {
                    setInfoCard({ ...infoCard, place: eventDetail.lugarEvento, text: parseDateHour(eventDetail.fechaEvento, eventDetail.horaEvento) });
                    setStateButton({ ...stateButton, onClick: () => { handleSubscription(eventDetail) }, textButton: 'Inscribirme a este evento' });
                }
            }).finally();
    }
    const historyChange = (id) => {
        if (id) {
            history.push('/ITT/auth/networking/servicios/eventos/evento-ID/exito', history.location.state);
            setStep("inscripcion-exitosa")
        } else {
            history.push('/ITT/auth/networking/servicios/eventos', history.location.state);
        }
    }
    const handleDeleteInscription = () => {
        Swal.fire({
            title: `<span class="shedulingDelete">Cancelación inscripción evento</span>`,
            text: "¿Estás seguro de cancelar tu inscripción?",
            padding: '23px 10px 23px 10px',
            showCloseButton: true,
            showCancelButton: true,
            customClass: {
                confirmButton: 'col-3 btnDeleteYes mb-4',
                cancelButton: 'col-3 btnDeleteNo mb-4',
                content: 'colorText',
            },
            cancelButtonColor: '#CF141B',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            //console.log(detailInscription);
            if (result.value) {
                Axios.post(`${urlAPI}/networking/desahabilitarInscripcion/${detailInscription.idInscripcionEvento}`, null, headers)
                    .then((res) => {
                        let { data, codigo } = res.data;
                        if (codigo === 0) {
                            setDetailInscription({ idEvento: null, idInscripcionEvento: null });
                            setContentAlert({ ...contentAlert, checkText: 'Tu inscripción ha sido cancelada con éxito', variant: 'success', class: 'alertNetworking', alertText: 'Si deseas asistir a un nuevo evento, haz clic a continuación.', showButton: true, showButtonText: 'VER EVENTOS DE NETWORKING' });
                            history.push('/ITT/auth/networking/servicios/eventos/evento-ID/cancelacion', history.location.state);
                        }
                    }).finally();
            }
        });
    }
    useEffect(() => {
        if (history.location.state && window.location.pathname==='/ITT/auth/networking/servicios/eventos/evento-ID') {
            setStep('detalle-evento');
            getListEvents(history.location.state);
        } else if(window.location.pathname==='/ITT/auth/networking/servicios/eventos/evento-ID/exito') {
            setStep("inscripcion-exitosa")
            getListEvents(history.location.state);
        }else if(window.location.pathname==='/ITT/auth/networking/servicios/eventos/evento-ID/cancelacion') {
            setStep("cancelacion-exitosa")
            getListEvents(history.location.state);
            setContentAlert({ ...contentAlert, checkText: 'Tu inscripción ha sido cancelada con éxito', variant: 'success', class: 'alertNetworking', alertText: 'Si deseas asistir a un nuevo evento, haz clic a continuación.', });

        }
        // if (step === "cancelacion-exitosa") {
        //     setContentAlert({ ...contentAlert, checkText: 'Tu inscripción ha sido cancelada con éxito', variant: 'success', class: 'alertNetworking', alertText: 'Si deseas asistir a un nuevo evento, haz clic a continuación.', showButton: true, showButtonText: 'VER EVENTOS DE NETWORKING ' });
        // }
    }, []);
    return (<>
        <ContainerService tittle="Networking" styleTittle={{ fontSize: "34px", lineHeight: "56px", paddingRight: "10%" }} linkBread={linkbread}>
            {step === "detalle-evento" && (
                <Container>
                    <Row bsPrefix="row p-4">
                        <Col sm={12}>
                            <h3 className="tittleView pb-5 pt-3">Construye tu red de contactos</h3>
                        </Col>
                        <Col sm={12} lg={6} className="p-0 pr-lg-1">
                            <Card style={{ height: '260px' }}>
                                <Card.Body className="p-2">
                                    <img src={detailEvent.rutaImagen} width="100%" height="242.6px" alt="imgDetailEvent" />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} lg={6} className="p-0 pl-lg-1">
                            <Card style={{ height: '260px' }}>
                                <Card.Body>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-1">
                                                <img src={Calendar} alt="imgCalendario" />
                                            </div>
                                            <div className="col-11">
                                                <span className="bodyDetailCard" >Fecha:</span>
                                                <span className="bodyDetailCard"> {parseDateHour(detailEvent.fechaEvento, detailEvent.horaEvento)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container" style={{ paddingTop: "10px" }}>
                                        <div className="row">
                                            <div className="col-1">
                                                <img src={Edificio}  alt="imgEdificio" />
                                            </div>
                                            <div className="col-11">
                                                <span className="bodyDetailCard" >Lugar:</span>
                                                <span className="bodyDetailCard">{detailEvent.lugarEvento}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cardButtonA'>
                                        <Button className="buttonEventDetail py-2 mt-3" onClick={stateButton.onClick}>{stateButton.textButton}</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} lg={12} className="p-0 pl-lg-1 pt-lg-4 textCard" >
                            <span style={{ color: "#58595B" }}>Descripción</span>
                            <p className="mt-3 textCard">
                                {detailEvent.descripcionEvento}
                            </p>
                            <span style={{ color: "#58595B" }}>Conferencista</span>
                            <p className="mt-3 textCard">
                                {detailEvent.nombreConferencista}
                            </p>
                        </Col>
                    </Row>
                </Container>
            )}
            {(step === "inscripcion-exitosa" || step === "cancelacion-exitosa") && (
                <Container>
                    <Row className="p-4">
                        <Col sm={12} className="mb-5">
                            <button onClick={() => { history.push("/ITT/auth/networking") }} className="btnCirculeBack"><img src={volver} style={{ paddingRight: '10px' }} alt="imgBack" />Volver</button>
                        </Col>
                        <Col sm={12} className="mb-5">
                            <h3 className="tittleView">Construye tu red de contactos </h3>
                        </Col>
                        <Col sm={12} className="borderCardContent">
                            <TypeAlert view="2" contentAlert={contentAlert} repite={() => { historyChange(false) }} />
                            {step === "inscripcion-exitosa" ?
                                <CardService view="networking" shedulingInfo={infoCard} deleteS={() => { handleDeleteInscription() }} />
                                :
                                <div className='text-center'>
                                    <button className="btn btnShedulingVerde" style={{boxShadow:'0px 0px 2px #00000010',width:'300px'}} onClick={() => { history.push("/ITT/auth/networking/servicios/eventos")}}     >
                                        VER EVENTOS DE NETWORKING
                                    </button>

                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            )}
        </ContainerService>
    </>);
}


export default NetworkingEventDetail;