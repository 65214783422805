import React, { useEffect, useState } from "react";
import "../../App.css";
import TooltipsHomeOut from '../tooltips-home/TooltipsHomeOut'
import { isActive } from "../../utils/validations";
import iconRegister from "../../assets/img/header/icon-register.svg"

function Background(props) {
    const [isLogged] = useState(isActive());
    const [to, setTo]=useState(70);

    let coords = 0

    useEffect(() => {
            if(window.location.pathname=='/registro-usuario-no-activo'){
                document.getElementById('body').style.overflow = 'scroll';
            }else{
            document.getElementById('body').style.overflow = 'hidden';
            }
            //console.log('ddd');
    }, [])

    let value = localStorage.getItem('finish') === '0'?1:localStorage.getItem('finish') === '1'?3:1
    const [step, setStep] = useState(value)
    return (
        <div>
            {window.location.pathname=='/registro-usuario-no-activo' ?(
                <>
                </>):(
                    <>
            {
                ((!isLogged) || (isLogged && window.innerWidth < 992)) && (
                    <div id="backDiv">
                        <i id="stepNext" className="d-none" onClick={() => { setStep(step + 1) }}></i>
                        <i id="stepBack" className="d-none" onClick={() => { setStep(1) }}></i>
                        {/* {//console.log(props.informationView)} */}
                        <TooltipsHomeOut informationView={props.informationView} id={props.id} showModal={props.showModal}/>
                        <div style={{ position: 'relative', }}>
                            {
                                ((window.location.pathname == '/' || window.location.pathname == '/Inicio' || window.location.pathname == '/inicio') && window.innerWidth > 992) && (
                                    <div className="divWhiteBack reg">
                                        <button className="btnBackRe" onClick={() => { window.location.href = '/registro' }} >
                                        <img className="icon-register" src={iconRegister} alt="registrate"></img>
                                            Regístrate
                                        </button>
                                    </div>
                                )

                            }
                            {
                                ((window.location.pathname == '/' || window.location.pathname == '/Inicio' || window.location.pathname == '/inicio') && window.innerWidth < 992) && (
                                    <div className="divWhiteBack reg moBtnUni"> 
                                        <button className="btnBackRe" style={{width: '90%'}} onClick={() => { window.location.href = '/registro' }} id="btnQUnirme">
                                            Quiero unirme 
                                        </button>
                                    </div>
                                )
                            }
                            <div className={props.showModal ?'':"shadowBack"}>
                            </div>
                        </div>
                    </div>
                )
            }
            </>
            )}
        </div>
    )
}
export default Background;