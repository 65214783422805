import React, { useState, useEffect } from "react";
import ContactHelp2 from "../../components/contact-help2/ContactHelp2";
import AsyncSelect from 'react-select/async';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { isMobile } from "react-device-detect";
import $ from "jquery";
//Imagenes
import plusMinus from "../../assets/img/faq/icon-arrow-up-green.svg";
import plusSign from "../../assets/img/faq/icon-arrow-down-gray.svg";

export default function FrecuentQuestions(props) {
  const [count, setCount] = useState(0);
  const [faqQuestion, setFaqQuestion] = useState([]);
  const [questionUser, setQuestionUser] = useState('');
  const [display, setDisplay] = useState(false);
  const [buscarTrue, setBuscarTrue] = useState(false)
  var aux = '';

  const colourStyles = {
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? 'rgb(245, 245, 245)' : 'white',
        color: isFocused ? '#808080' : '#808080',
        cursor: 'pointer',
        textAling: 'end',
        borderLeft: isFocused ? '3px solid #2A2A2A' : 'none',
        padding: "15px",
      };
    }
  };


  let faqQuestions =
    [{
      idCategoria: "1",
      value: "1",
      label: "¿Cómo hago para estar registrada?",
      Respuesta: <p style={{ fontFamily:'Inter-regular', fontSize:'16px', color:'#57595B'}}>Verificar que tu seguro se encuentre activo. Seguido a eso, continúa los siguientes pasos:<br />
        · Regístrate en el botón al inicio de esta página.<br />
        · Ingresa tu número de cédula, para personalizar tus servicios.<br />
        · Ingresa tu correo electrónico y número celular, para poder contactarte.<br />
        · Crea tu contraseña segura y disfruta de los servicios.
      </p>
    }, {
      idCategoria: "1",
      value: "2",
      label: "¿Mis servicios se activan inmediatamente adquiera el seguro?",
      Respuesta: "Debes esperar 3 días hábiles luego de adquirir tu seguro mientras personalizamos tus servicios. Si tus servicios no llegan a ser activados, comunícate al correo o líneas de atención que encuentras en este sitio."
    }, {
      idCategoria: "1",
      value: "3",
      label: "¿Puedo compartir los servicios con un amigo o familiar?",
      Respuesta: "Solo puedes compartir los servicios con tu cónyuge y este estará precargado si se encuentra dentro de la lista de tus beneficiarios. Además, dependiendo de la cobertura de tu seguro podrás agregar a tus hijos menores de 18 años como parte de los beneficiarios."
    },
    {
      idCategoria: "1",
      value: "4",
      label: "¿Cómo actualizo mis datos personales?",
      Respuesta: "En la sección “Mi Perfil” podrás editar tus datos. Recuerda que esta sección solo está habilitada para personas registradas."
    }, {
      idCategoria: "2",
      value: "5",
      label: "Solo tengo algunos servicios activos",
      Respuesta: "Personalizamos tu experiencia de acuerdo con tu perfil y el tipo de seguro adquirido, por lo tanto, podrás tener diferentes servicios activos"
    }, {
      idCategoria: "2",
      value: "6",
      label: "¿Debo utilizar los servicios en orden?",
      Respuesta: "No, los servicios se pueden utilizar en cualquier orden."
    }, {
      idCategoria: "2",
      value: "7",
      label: "¿Los servicios tienen algún costo?",
      Respuesta: "No, los servicios son totalmente gratuitos y son un beneficio adicional a las coberturas de tu seguro."
    },
    {
      idCategoria: "2",
      value: "8",
      label: "¿Puedo utilizar los servicios varias veces?",
      Respuesta: "Sí, puedes utilizar los demás servicios las veces que quieras.",
    },
    {
      idCategoria: "2",
      value: "9",
      label: "¿Por qué no puedo realizar más consultas médicas?",
      Respuesta: "Ten en cuenta que solo tienes acceso a 6 consultas por cada año de vigencia de tu seguro. Si aún no has utilizado las 6 consultas y presentas alguna falla, comunícate con nuestros canales de atención."
    },
    {
      idCategoria: "3",
      value: "10",
      label: "Mis servicios no están funcionando",
      Respuesta: "En caso que tus servicios presenten problemas, te invitamos a validar tu conexión a internet. Si la falla persiste contáctanos en nuestra líneas de atención para poder ayudarte."
    },
    {
      idCategoria: "4",
      value: "11",
      label: "Quiero saber más sobre los servicios y mi seguro",
      Respuesta: 'Al inicio de este sitio encontrarás una descripción detallada de cada uno de los servicios, pero, si quieres conocer más sobre tu seguro y coberturas, comunícate a nuestras líneas de atención.'
    }
    ];
  let tittle = [
    {
      id: "1",
      tittle: "Inquietudes sobre el registro",
      text: "Aquí te compartimos algunas de las dudas más frecuentes para que tengas todo claro sobre el registro. "
    },
    {
      id: "2",
      tittle: "Dudas sobre los servicios",
      text: "Si aún tienes dudas sobre los beneficios de tu seguro, aquí podemos ayudarte a resolverlas."
    },
    {
      id: "3",
      tittle: "Inconvenientes en el servicio",
      text: "En ocasiones, puede ser posible que se presenten fallas en los beneficios de tu Seguro, ¡Pero no te preocupes! Aquí encuentras la solución."
    },
    {
      id: "4",
      tittle: "Inquietudes sobre tu seguro",
      text: "Infórmate más sobre tu seguro y resuelve todas tus dudas."
    }
  ];
  const [tittleQuestion, setTittleQuestion] = useState([]);

  const handleSearchQuestion = (category) => {
    setFaqQuestion(faqQuestions.filter(x => x.idCategoria === category));
    //console.log('category', category)
    if (category != 0) {
      setBuscarTrue(false)
      setTittleQuestion(tittle.filter(x => x.id === category));
    }
    if (category === "1") {
      document.getElementById("primero").classList.replace("efecto", "clicEfecto");
      document.getElementById("segundo").classList.replace("clicEfecto", "efecto");
      document.getElementById("tercero").classList.replace("clicEfecto", "efecto");
      document.getElementById("cuarto").classList.replace("clicEfecto", "efecto");
    }
    if (category === "2") {
      document.getElementById("primero").classList.replace("clicEfecto", "efecto");
      document.getElementById("segundo").classList.replace("efecto", "clicEfecto");
      document.getElementById("tercero").classList.replace("clicEfecto", "efecto");
      document.getElementById("cuarto").classList.replace("clicEfecto", "efecto");
    }
    if (category === "3") {
      document.getElementById("primero").classList.replace("clicEfecto", "efecto");
      document.getElementById("segundo").classList.replace("clicEfecto", "efecto");
      document.getElementById("tercero").classList.replace("efecto", "clicEfecto");
      document.getElementById("cuarto").classList.replace("clicEfecto", "efecto");
    }
    if (category === "4") {
      document.getElementById("primero").classList.replace("clicEfecto", "efecto");
      document.getElementById("segundo").classList.replace("clicEfecto", "efecto");
      document.getElementById("tercero").classList.replace("clicEfecto", "efecto");
      document.getElementById("cuarto").classList.replace("efecto", "clicEfecto");
    }
    controlURL(category);
  };

  const controlURL = (category) => {
    if (category === "1") {
      props.history.push(`/preguntas-frecuentes/resultado-#Dudas-sobre-el-registro`);
    }
    if (category === "2") {
      props.history.push(`/preguntas-frecuentes/resultado-#Dudas-sobre-los-servicios`);
    }
    if (category === "3") {
      props.history.push(`/preguntas-frecuentes/resultado-#Fallas-del-servicio`);
    }
    if (category === "4") {
      props.history.push(`/preguntas-frecuentes/resultado-#Dudas-sobre-tu-seguro`);
    }
  }
  const filtroTitulo = (e) => {
    if (e != 0) {
      setTittleQuestion(tittle.filter(x => x.id === e));
      setBuscarTrue(true)
    }
    if (e === '1') {
      document.getElementById("primero").classList.replace("efecto", "clicEfecto");
      document.getElementById("segundo").classList.replace("clicEfecto", "efecto");
      document.getElementById("tercero").classList.replace("clicEfecto", "efecto");
      document.getElementById("cuarto").classList.replace("clicEfecto", "efecto");
    }
    if (e === '2') {
      document.getElementById("primero").classList.replace("clicEfecto", "efecto");
      document.getElementById("segundo").classList.replace("efecto", "clicEfecto");
      document.getElementById("tercero").classList.replace("clicEfecto", "efecto");
      document.getElementById("cuarto").classList.replace("clicEfecto", "efecto");
    }
    if (e === '3') {
      document.getElementById("primero").classList.replace("clicEfecto", "efecto");
      document.getElementById("segundo").classList.replace("clicEfecto", "efecto");
      document.getElementById("tercero").classList.replace("efecto", "clicEfecto");
      document.getElementById("cuarto").classList.replace("clicEfecto", "efecto");
    }
    if (e === '4') {
      document.getElementById("primero").classList.replace("clicEfecto", "efecto");
      document.getElementById("segundo").classList.replace("clicEfecto", "efecto");
      document.getElementById("tercero").classList.replace("clicEfecto", "efecto");
      document.getElementById("cuarto").classList.replace("efecto", "clicEfecto");
    }
  }
  const handleSearchQuestionUser = (e) => {
    //console.log('uno', e.length);
    if (e.length > 0) {
      $('.css-26l3qy-menu').addClass('d-block')
      setDisplay(true);
    }
    else {
      $('.css-26l3qy-menu').addClass('d-none')
      setDisplay(false);
    }
    // setQuestionUser(e.target.value);
  }
  const handleSearchQuestions = () => {
    var valor = '0';
    $('.css-26l3qy-menu').addClass('d-none')
    setFaqQuestion(faqQuestions.filter(x => x.label.toLowerCase().includes(questionUser.toLowerCase())));
    faqQuestions.forEach(element => {
      if (element.label === questionUser) {
        valor = element.idCategoria;
      }
    });
    filtroTitulo(valor);
    controlQuestionUrl();
  }

  const arrow = (id) => {
    setTypeField({ id: id, state: !typeField.state })
    if (id != typeField.id) {
      setTypeField({ id: id, state: true });
    }
  }

  const [typeField, setTypeField] = useState({ id: '', state: false });
  useEffect(() => {

    if (count === 0 && window.screen.width > 400) {
      window.scroll(0, 0);
      try {
        document.getElementById('inicio').click();
        props.history.push(`/preguntas-frecuentes/resultado-#Dudas-sobre-el-registro`);
      } catch (e) {

      }
      setCount(count + 1);
    }
    else if (isMobile) {
      setInterval(() => {
        controlMobile();
      }, 1000);
    }
  }, [count]);

  const controlMobile = () => {
    if ($('#dudas-registro').hasClass('active')) {
      //console.log("dudas activas");
      document.getElementById("primero").click();
    }
    else if ($('#dudas-servicio').hasClass('active')) {
      //console.log("dudas servicios")
      document.getElementById("segundo").click();
    }
    else if ($('#fallas').hasClass('active')) {
      //console.log("fallas");
      document.getElementById("tercero").click();
    }
    else if ($('#duda').hasClass('active')) {
      //console.log("dudas legales");
      document.getElementById("cuarto").click();
    }
  };

  const loadOptions = (questionUser, callback) => {
    setQuestionUser(questionUser);
    setTimeout(() => {
      callback(filterQuestion(questionUser));
    }, 1000);
  };

  const filterQuestion = (questionUser) => {
    let prueba = faqQuestions.filter(i =>
      i.label.toLowerCase().includes(questionUser.toLowerCase())
    );
    return prueba;
  };
  const handleItemSelectChange = inputValue => {
    //console.log('dos');
    setQuestionUser(inputValue.label);
  };

  const controlQuestionUrl = () => {
    aux = questionUser.replace(/[ ]/g, '-');
    aux = aux.replace(/[? ]/g, ' ');
    aux = aux.replace(/[¿]/g, '#');
    props.history.push(`/preguntas-frecuentes/resultado-${aux}`);
  }

  return (
    <div>
      <div className="container " style={{ backgroundImage: "linear-gradient(0deg,#fff -1%,#F9F9F9 100%)" }}>
        <h1 className="titlePFaq text-center" >
          {/* ¿Cómo podemos {isMobile?(<br/>):(<></>)} ayudarte? */}
          En este espacio resolvemos<br />
          algunas dudas frecuentes
        </h1>
        <div className="col-lg-6 offset-lg-3 mb-5 barraBuscar d-lg-none d-md-block d-block">
          <div className="contSearch">
            <AsyncSelect
              name="questionUser"
              className="form-control2 borderfaq photeSearch cajaSearch cajaPreguntas"
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              styles={colourStyles}
              values={questionUser}
              onInputChange={handleSearchQuestionUser}
              onChange={option => handleItemSelectChange(option)}
              placeholder={window.screen.width > 768?"Haz una pregunta...":"Escribe el tema o palabra clave"}
            ></AsyncSelect>
            <button className="btn-danger btnBuscar btnBuscarFaq " style={{ fontFamily: "OpenSans-regular" }} onClick={(e) => { handleSearchQuestions() }}>
              Buscar
            </button>
          </div>
        </div>
        <div className="col-lg-7 mb-5 barraBuscar d-lg-block d-md-none d-none m-auto">
          <AsyncSelect
            name="questionUser"
            className="form-control2  photeSearch cajaSearch cajaPreguntas"
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            styles={colourStyles}
            values={questionUser}
            onInputChange={handleSearchQuestionUser}
            onChange={option => handleItemSelectChange(option)}
            placeholder="Escribe el tema o palabra clave para resolver tu duda"
          ></AsyncSelect>
          <button className="btn-danger btnBuscar btnBuscarFaq" style={{ fontFamily: "OpenSans-regular" }} onClick={(e) => { handleSearchQuestions() }}>
            Buscar
          </button>
        </div>
        <p className="subTitleFaq text-center" style={{ marginTop: "5%", marginRight:'6.6%', marginBottom:'5%'}}>
          O  selecciona una categoría para encontrar rápidamente la ayuda que necesitas.
        </p>
        {(window.screen.width > 769) ? (
          <div className="row mb-5 mt-5 align-middle " id="frecuentQuestions" >
            <div className="col-12 col-lg-10 col-sm-3 offset-lg-1 text-center dudasContainer" style={{ display: "flex" }}>
              <div className="col-12 col-lg-3 col-md-12 mt-2 mb-2 efecto" id="inicio" onClick={() => { handleSearchQuestion('1') }}>
                <div className="card cajaCategoria efecto " id="primero">
                  <img className="dudasRegistro" alt="icono dudas registro" />
                  <h4 className="dudasTitle mt-3">
                    Inquietudes sobre el registro
                  </h4>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-12 mt-2 mb-2 efecto" onClick={() => { handleSearchQuestion('2') }}>
                <div className="card cajaCategoria efecto" id="segundo">
                  <img className="dudasServicios" alt="icono dudas del servicio" />
                  <h4 className="dudasTitle mt-3">
                    Dudas sobre los servicios
                  </h4>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-12 mt-2 mb-2 efecto" onClick={() => { handleSearchQuestion('3') }}>
                <div className="card cajaCategoria efecto" id="tercero">
                  <img className="fallasServicio" alt="icono fallas servicio" />
                  <h4 className="dudasTitle mt-3">
                    Inconvenientes en el servicio
                  </h4>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-12 mt-2 mb-2 efecto" onClick={() => { handleSearchQuestion('4') }}>
                <div className="card cajaCategoria efecto" id="cuarto">
                  <img className="dudasSeguro" alt="icono dudas seguro" />
                  <h4 className="dudasTitle mt-3">
                  Inquietudes sobre tu seguro
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <div id="carouselExampleIndicators" className="carousel slide" data-interval="false" data-ride="carousel">
                <ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active" id="dudas-registro" style={{ backgroundColor: "#D9D9D9" }}></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1" id="dudas-servicio" style={{ backgroundColor: "#D9D9D9" }}></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2" id="fallas" style={{ backgroundColor: "#D9D9D9" }}></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="3" id="duda" style={{ backgroundColor: "#D9D9D9" }}></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="col-lg-3">
                      <div className="row p-2" style={{ marginLeft: " 55px", display: "table" }} onClick={() => { handleSearchQuestion('1') }}>
                        <div className="cajaCategoria pt-4 pl-3 pr-3 text-center efecto" id="primero" style={{ border: "1px solid transparent" }}>
                          <img className="dudasRegistro" alt="icono dudas registro" />
                          <h4 style={{ marginTop: '15px', fontSize: "18px" }} className="card-title dudasTitle">
                            Dudas sobre el registro
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-lg-3">
                      <div className="row p-2" style={{ marginLeft: "55px", display: "table" }} onClick={() => { handleSearchQuestion('2') }}>
                        <div className="cajaCategoria pt-4 pl-3 pr-3 text-center efecto" id="segundo" style={{ border: "1px solid transparent" }}>
                          <img className="dudasServicios" alt="icono dudas del servicio" />
                          <h4 style={{ marginTop: '15px', fontSize: " 18px" }} className="card-title dudasTitle">
                            Dudas sobre los servicios
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-lg-3">
                      <div className="row p-2" style={{ marginLeft: "55px", display: "table" }} onClick={() => { handleSearchQuestion('3') }}>
                        <div className="cajaCategoria pt-4 pl-2 pr-2 text-center efecto" id="tercero" style={{ border: "1px solid transparent" }}>
                          <img className="fallasServicio" alt="icono fallas servicio" />
                          <h4 style={{ marginTop: '15px', fontSize: "18px" }} className="card-title dudasTitle">
                          Inconvenientes en el servicio
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-lg-3">
                      <div className="row p-2" style={{ marginLeft: "55px", display: "table" }} onClick={() => { handleSearchQuestion('4') }}>
                        <div className="cajaCategoria pt-4 pl-2 pr-2 text-center efecto" id="cuarto" style={{ border: "1px solid transparent" }}>
                          <img className="dudasSeguro" alt="icono dudas seguro" />
                          <h4 style={{ marginTop: '15px', fontSize: "18px" }} className="card-title dudasTitle">
                          Inquietudes sobre tu seguro
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}


        {!buscarTrue && (
          tittleQuestion.map((e, i) => {
            return (
              <React.Fragment>
                <h3 className="titleFaq text-center" style={{ marginTop: "4.5rem" }} key={i}>
                  {e.tittle}
                </h3>
              </React.Fragment>
            )
          })
        )}
        {buscarTrue && (
          <React.Fragment>
            <h3 className="titleFaq text-center" style={{ marginTop: "4.5rem" }}  >
              Resultado de tu búsqueda
            </h3>
          </React.Fragment>
        )}
      </div>
      <div className="container text-center mt-5" style={{ marginBottom: "6%" }}>
        {!buscarTrue && (
          tittleQuestion.map((e, i) => {
            return (
              <React.Fragment>
                <p className="textoPre" style={{ fontFamily: "Helvetica", color: "#58595B", fontSize: "16px" }} key={i}>
                  {e.text}
                </p>
              </React.Fragment>
            )
          })
        )}
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-9 col-sm-12">
          <Accordion defaultActiveKey="0">
            <Card>
              {faqQuestion.map((element, i) => {
                return (
                  <React.Fragment key={i}>
                    <Accordion.Toggle as={Card.Header} eventKey={i} onClick={() => { arrow(i) }} className={(typeField.id === i && typeField.state) ? "borderCardRegister pcHeader" : 'pcHeader'}>
                      <span style={{ fontFamily: "Inter-bold", color: "#414141", fontSize: "18px", lineHeight: '5px' }}>{element.label}</span><span style={{ float: 'right', color: "#212529", fontSize: "14px", cursor: "pointer" }} ><img className="imgFaq" src={typeField.id === i && typeField.state ? plusMinus : plusSign} /></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={i}>
                      <Card.Body className="subTitleFaq" style={{ lineHeight: "28px", fontFamily: 'Inter-regular' }}>
                        {element.Respuesta}
                      </Card.Body>
                    </Accordion.Collapse>
                  </React.Fragment>
                )
              })}
            </Card>
          </Accordion>
        </div>
      </div>
      <ContactHelp2 />
    </div>
  );
}
