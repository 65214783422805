import React, { useEffect, useRef, useState, } from 'react'
import { Button, Form, InputGroup, OverlayTrigger, Popover, Col, Row } from 'react-bootstrap'
import { passwordSecurity } from '../../utils/validations';
import eyetrue from "../../assets/img/eyetrue.svg";
import eyefalse from "../../assets/img/eyefalse.svg";
import "../../views/register/RegisterView.css";



export const InputComponents = ({ valueInput, funtioninput, TypeInput, textLabel, BtnState, idInput, placeholder, TypePassword, refPassword }) => {
    const popRef = useRef()
    const [width, setWidth] = useState(window.screen.width);
    const [position, setPosition] = useState('right')

    const onMouseHover = (number) => {
        setWidth(window.screen.width)
        if((popRef.current == undefined && number == 1) || (popRef.current != undefined && number == 2)){
            document.getElementById(`btContra-${idInput}`).click()
        }
    }
  const popover = (
    <Popover id={`popover-basic-${idInput}`} className="contPopover">
        <Row>
            <Col className="col-12 ">
                <p className="txtPopover">
                    Esta contraseña es exclusiva para el ingreso al ecosistema
                </p>
            </Col>
            <Col className="col-12 my-2">
                <p className="titPopover">
                    Tu contraseña requiere:
                </p>
            </Col>
            <Col className="col-12">
                <Row className="mt-1 align-items-center">
                    <Col className="col-2 p-0">
                    <div className={`${/[A-Z]/.test(valueInput) ? 'checkCorrecto' : 'checkErrado'}`}></div>
                    </Col>
                    <Col className="col-10">
                        <p className="txtPopover">
                            Una mayúscula
                        </p>
                    </Col>
                </Row>
                <Row className="mt-1 align-items-center">
                    <Col className="col-2 p-0">
                    <div className={`${/[a-z]/.test(valueInput)? 'checkCorrecto' : 'checkErrado'}`}></div>
                    </Col>
                    <Col className="col-10">
                        <p className="txtPopover">
                            Una minúscula
                        </p>
                    </Col>
                </Row>
                <Row className="mt-1 align-items-center">
                    <Col className="col-2 p-0">
                    <div className={`${/[0-9]/.test(valueInput) ? 'checkCorrecto' : 'checkErrado'}`}></div>
                    </Col>
                    <Col className="col-10">
                        <p className="txtPopover">
                            Un número
                        </p>
                    </Col>
                </Row>
                <Row className="mt-1 align-items-center">
                    <Col className="col-2 p-0">
                    <div className={`${valueInput !== undefined ?(valueInput.length > 7 && valueInput.length < 17 ? 'checkCorrecto' : 'checkErrado'):'checkErrado'}`}></div>
                    </Col>
                    <Col className="col-10">
                        <p className="txtPopover">
                            Minimo 8 y máximo 16 caracteres
                        </p>
                    </Col>
                </Row>
                <Row className="mt-1 align-items-center">
                    <Col className="col-2 p-0">
                    <div ref={popRef} className={`${/[$@$!%*?&¿/#,;+.'ñ-]/.test(valueInput) ? 'checkCorrecto' : 'checkErrado'}`}></div>
                    </Col>
                    <Col className="col-10">
                        <p className="txtPopover">
                            Un caracter especial (Ejemplo: *,$;#)
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>            
    </Popover>
  );

  useEffect(() => {
    if(width <= 768 && position != 'top'){
        setPosition('top')
    }else if(width > 768 && position != 'right')
    {
        setPosition('right')
    }

  }, [width])

  const [typeField, setTypeField] = useState(false);
  return (
    <>
        <InputGroup className="mb-2" id='inputGrupoReg'>
            <Row className='align-items-center w-100 p-0 m-0'>
                {
                    TypePassword !== "ActualizarClave" && (                            
                        <Col className='col-12 col-md-3 p-0'>
                            <Form.Label htmlFor="basic-url" className='label__register'>{textLabel}</Form.Label>                
                        </Col>
                    )
                }
                
                <Col className={`col-12 ${TypePassword !== "ActualizarClave" ? 'col-md-9' : '' }  p-0`}>                
                    {
                        TypeInput !== "password" && (
                            <>
                                <Form.Control
                                    name={textLabel}
                                    className='inputClass'
                                    aria-label="Documento" 
                                    type='text'
                                    value={valueInput}
                                    onChange={funtioninput}
                                    disabled={BtnState}
                                    id={idInput}
                                    placeholder={placeholder}
                                />
                            </>
                        )
                    }
                    {
                        TypeInput === "password" && (
                            <>
                                <Form.Control
                                    name={textLabel}
                                    className='inputClass'
                                    aria-label="Documento" 
                                    type={(typeField)?'text':TypeInput}
                                    value={valueInput}
                                    onChange={funtioninput}
                                    disabled={BtnState}
                                    id={idInput}
                                    placeholder={placeholder}
                                    onMouseOver={() => onMouseHover(1)}
                                    onMouseOut={() => onMouseHover(2)}
                                    onFocus = {() => onMouseHover(1)}
                                />
                                <div
                                    className="input-group-prepend passwordEye"
                                    onClick={() => setTypeField(!typeField)}
                                >
                                    <span id={`basic-addon-${idInput}`}>
                                    <img style={{color:"#BABABA"}} src={typeField ? eyetrue : eyefalse} />
                                    
                                    </span>
                                </div>
                                <div className="contBtContra">
                                    <OverlayTrigger
                                        // placement={position}
                                        trigger="click"
                                        overlay={popover}
                                    >
                                        <Button
                                            id={`btContra-${idInput}`}
                                            className="btContra"
                                            onClick={() => onMouseHover(0)}
                                            ref={refPassword != null && refPassword}
                                        >
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </>
                        )
                    }
                </Col>
            </Row>
        </InputGroup>
    </>
    )
}
