import Axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import cerrarModal from "../../assets/img/cardif-boton-cerrar.svg";
import { urlAPI } from "../../utils/config";
import { addLogs, getToken, getUser, isActive } from "../../utils/validations";

export default function ModalCyber() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  const dobleEnter = (
    <>
      <br />
      <br />
    </>
  );
  const onNavigateN = () =>{
    addLogs('Security','Navegando a página de Cyber Security');
    let _id = getUser().idUsuario
    Axios.get(`${urlAPI}/CyberInsurance/ObtenerLink?id=${_id}`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      }}).then(succ=>{
        let _url = succ.data.data;
        window.open(_url, "_blank");
      }).catch(error=>{
        //console.log(error);
    });
  }
  return (
    <>
    <span className="hover mr-1 text-left disclamersFooter col-10">
        <a onClick={handleShow} id="mdlCyber" style={{fontFamily: "Opensans-regular",cursor:"pointer",color: "#2A2A2A",fontSize:"16px", boxShadow:'#2a2a2a 0px -1px inset'}}>
        </a>
    </span>
      <Modal 
      show={show} 
      onHide={handleClose} 
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered>
      
      <Modal.Header>
        <div className="btnCierraModalPrincipal">
          <button
            id="btnCierraModal"
            type="button"
            className="swal2-close-modal btnPolicies"
            style={{outline:"none", marginRight:"12px"}}
            onClick={handleClose}
          ><p>×</p></button>
        </div>
        </Modal.Header>
        <Modal.Body>
        <div className="body-modal-t">
          <h4 className="tittle-modal-t">
          Autorización para el tratamiento de datos personales:
          </h4>
          <p className="overflow-scroll text-justify" >
            Autorizo de manera previa, expresa, e informada a CARDIF COLOMBIA SEGUROS GENERALES S.A.
            identificada con NIT 900.200.435-3 y domicilio principal en Bogotá D.C. o a quien 
            represente sus derechos, para que realice la recolección y almacenamiento, divulgación, 
            transferencia y transmisión, a nivel nacional e internacional y procesamiento mis datos 
            personales, entendidos como cualquier información vinculada o que pueda asociarse a mí y 
            que permita mi identificación (los “Datos Personales”), como lo son mi nombre, edad, género, 
            dirección, teléfono, correo electrónico, historia laboral, y número de documento de identidad, 
            todo lo anterior conforme a la Política de Tratamiento de la Información de Cardif Colombia 
            Seguros Generales (la “Política”), disponible en www.bnpparibascardif.com.co enlace “Privacidad”.
            { dobleEnter }
            La presente autorización se extiende para que CARDIF COLOMBIA SEGUROS GENERALES S.A. pueda 
            compartir la información con BOTECH Fraud Prevention & Intelligence S.L. (BOTECH), aliado a 
            través del cual podré instalar un software/agente de monitoreo de ciberseguridad en mis 
            dispositivos móviles (celulares con sistema operativo androide y portátiles con sistema 
            operativo windows o IOS) y computadores de escritorio (con sistema operativo windows o IOS), 
            el cual estará de manera silenciosa monitoreando amenazas que puedan comprometer mi seguridad 
            y la de mi información, ayudando a la prevención de fraudes financieros e informáticos; todo 
            conforme a lo establecido en las normas legales vigentes dentro del marco de la Ley 1581 de 
            2021, por la cual se dictan las disposiciones generales para la Protección de Datos Personales, 
            su Decreto Reglamentario 1377 de 2013 y del Sistema de Administración de Riesgos de Lavado de 
            Activos y Financiación al Terrorismo SARLAFT de CARDIF COLOMBIA SEGUROS GENERALES S.A.
            { dobleEnter }
            Declaro que he sido informado sobre mis derechos a presentar solicitudes de información, 
            actualización, supresión, revocatoria de autorización y/o rectificación de los Datos Personales, 
            y que cualquier consulta, solicitud, reclamo, queja y petición relacionada podré dirigirla a 
            atencionalcliente@cardif.com.co”
            { dobleEnter }
            CARDIF COLOMBIA SEGUROS GENERALES S.A., están comprometidas con el tratamiento leal, lícito, 
            confidencial y seguro de sus datos personales. Por favor consulte nuestra Política de 
            Tratamiento de Información en: www.bnpparibascardif.com.co en donde puede conocer sus derechos 
            constitucionales y legales así como la forma de ejercerlos.
          </p>
        </div>
          <div className="text-center" style={{margin:"5px"}}>
            <button
              type="button"
              onClick={(e)=>{handleClose();onNavigateN(e);}}
              className="btnModal pl-5 pr-5"
              style={{width:'176px'}}
            >
              ACEPTAR
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
