import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCalculator } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import $ from 'jquery';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import Swal from "sweetalert2";
import iconCheck from "../../assets/img/iconClock.svg";
import icon1 from "../../assets/img/salary/icono/bancolombia-icon_salary_001.svg"
import icon2 from "../../assets/img/salary/icono/bancolombia-icon_salary_003.svg"
import icon3 from "../../assets/img/salary/icono/bancolombia-icon_salary_005.svg"
import calculator from "../../assets/img/salary/icono/bancolombia-icon_salary_006.svg"
import { Chart } from "chart.js";
import Loader from "../../components/loader/Loader";
import { Link } from "react-router-dom";

export default function Salary(props) {
    const url = 'https://datamok.grupomok.com.co'
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiVXN1YXJpbyBBZG1pbiBJbmRpY2UgU2FsYXJpYWwiLCJhZG1pbiI6dHJ1ZX0.fapLz7N2XJYwllaMhQvSStl8UJmmy11_0baFfBtIYuU'

    const [area, setArea] = useState('');
    const [niveles, setNiveles] = useState('');
    const [cargosS, setCargosS] = useState('');
    const [tamano, setTamano] = useState('');
    const [cityS, setCityS] = useState('');

    const [loaderShow, setLoaderShow] = useState(false);

    const [_area, set_Area] = useState('');
    const [_nivel, set_Nivel] = useState('');
    const [_cargos, set_Cargos] = useState('');
    const [_salary, set_Salary] = useState('');
    const [_tamano, set_Tamano] = useState('');
    const [_city, set_City] = useState('');

    const [calculadora, setCalculadora] = useState(false);


    useEffect(() => {
        window.scroll(0, 0);
        getArea()
        numero()
    }, []);
    const getNivelJerarquico = (_area) => {
        if(_area !== ''){
            document.getElementById('areas').classList.remove('iputControlHvRed')
            document.getElementById('areas').classList.add('iputControlHv')
        }
        set_Area(_area)
        Axios.get(`${url}/obtenerSectores?pais=Colombia&area=${_area}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
                ,
            }
        }).then(succ => {
            setNiveles(succ.data)
        }).catch(error => {
            //console.log(error);
        });

    }
    const getCargos = (_area, _nivel) => {
        if((_nivel !=="" || _nivel !==null || _nivel !==undefined) && valid){
            document.getElementById('niveles').classList.add('iputControlHv')
            document.getElementById('niveles').classList.remove('iputControlHvRed')
        }
        // this.loader = true;
        set_Nivel(_nivel)
        Axios.get(`${url}/obtenerCargos?pais=Colombia&area=${_area}&sector=${_nivel}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
                ,
            }
        }).then(succ => {
            setCargosS(succ.data)
        }).catch(error => {
            //console.log(error);
        });
    }
    const getArea = () => {

        Axios.get(`${url}/obtenerAreas?pais=Colombia`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
                ,
            }
        }).then(succ => {
            setArea(succ.data)
        }).catch(error => {
            //console.log(error);
        });
    }
    const getTamanos = () => {
        let cargoX = document.getElementById('cargos')
        if(cargoX.value !==""){
            document.getElementById('cargos').classList.remove('iputControlHvRed')
            document.getElementById('cargos').classList.add('iputControlHv')
        }
        Axios.get(`${url}/obtenerTamano`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
                ,
            }
        }).then(succ => {
            setTamano(succ.data)
        }).catch(error => {
            //console.log(error);
        });
    }
    const getCiudades = (_area, _nivel, _cargo) => {
        Axios.get(`${url}/obtenerRegion?pais=Colombia&area=${_area}&sector=${_nivel}&cargo=${_cargo}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
                ,
            }
        }).then(succ => {
            setCityS(succ.data)
        }).catch(error => {
            //console.log(error);
        });
    }

    function replaceAll(text, busca, reemplaza) {
        while (text.toString().indexOf(busca) != -1)
            text = text.toString().replace(busca, reemplaza);
        return text;
    }
    const handleChange = (e) => {
        set_Salary(replaceAll(e.target.value, ".", ""))
    }
    const numero = () => {
        $("#salario").on({
            "focus": function (event) {
                $(event.target).select();
            },
            "keyup": function (event) {
                $(event.target).val(function (index, value) {
                    return value.replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{3})$/, '$1.$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                });
            }
        });
    }
    let grafico1 = {};
    const [grafico3, setGrafico3] = useState([]);
    const [grafico2, setGrafico2] = useState([]);
    let grafico3total = [];
    let grafico2total = [];
    let filtro = 'Promedio';
    let BarChart
    let cboFiltro

    const [Cnum1, setCnum1] = useState()

    const isBetween = (sConsul, sMx) => {
        if (sConsul <= (sMx + (sMx * 0.10)) && sConsul >= sMx) {
            return grafico1.maximo + (grafico1.maximo * 0.2)
        } else if (sConsul >= (sMx - (sMx * 0.10)) && sConsul <= sMx) {
            return grafico1.maximo + (grafico1.maximo * 0.2)
        } else {
            return grafico1.consultado + (grafico1.maximo * 0.5)
        }
        // return (sConsul - sMx) * (sConsul - (sMx*0.2)) <= (sMx +1000000) || (sConsul - sMx) * (sConsul - (sMx*0.1)) >= (sMx -1000000)
    }
    const isBetweenCont = (sConsul, sMx) => {
        if (sConsul <= (sMx + (sMx * 0.10)) && sConsul >= sMx) {
            return grafico1.maximo + (grafico1.maximo * 0.4)
        } else if (sConsul >= (sMx - (sMx * 0.10)) && sConsul <= sMx) {
            return grafico1.maximo + (grafico1.maximo * 0.4)
        } else {
            return grafico1.consultado + (grafico1.maximo * 0.5)
        }
        // return (sConsul - sMx) * (sConsul - (sMx*0.2)) <= (sMx +1000000) || (sConsul - sMx) * (sConsul - (sMx*0.1)) >= (sMx -1000000)
    }


    const formatter = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    })
    const [valid, setvalit] = useState(false);
    const saveData = (area, nivel, cargos, city, salary) => {
        let tamano = document.getElementById('tamanos')
        if (area === '' || nivel === '' || cargos === '' || city === '' || salary === '' || tamano.value ==='') {
            if(area === ''){
                document.getElementById('areas').classList.remove('iputControlHv')
                document.getElementById('areas').classList.add('iputControlHvRed')
            }
            if(nivel === '' ){
                document.getElementById('niveles').classList.remove('iputControlHv')
                document.getElementById('niveles').classList.add('iputControlHvRed')
            }
            if(cargos === ''){
                document.getElementById('cargos').classList.remove('iputControlHv')
                document.getElementById('cargos').classList.add('iputControlHvRed')
            }
            if(city === ''){
                document.getElementById('regiones').classList.remove('iputControlHv')
                document.getElementById('regiones').classList.add('iputControlHvRed')
            }
            if(tamano.value ===''){
                document.getElementById('tamanos').classList.remove('iputControlHv')
                document.getElementById('tamanos').classList.add('iputControlHvRed')
            }
            Swal.fire({
                width: '27em',
                height: '20em',
                title: 'Ops!',
                text: `Debes diligenciar los campos que son obligatorios.`,
                customClass: {
                    confirmButton: 'btnConfirmAzul',
                    closeButton: 'swal2-close-modal'
                },
                confirmButtonText: 'Aceptar',
                showCloseButton: true
            });
            setvalit(true)
            return
        }
        setLoaderShow(true);
        Axios.get(`${url}/obtenerValoresSalariales?pais=Colombia&area=${area}&sector=${nivel}&cargo=${cargos}&region=${city}&salario=${salary}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            }

        }).then(succ => {

            if (succ.data[0].JsonGrafico1 == "" || succ.data[0].JsonGrafico2 == "" || succ.data[0].JsonGrafico3 == "") {
                Swal.fire({
                    title: 'Ops!',
                    text: `No hay coincidencias para los registros seleccionados`,
                    type: 'error',
                    confirmButtonText: 'Aceptar'
                });
                return
            }
            mostrarForm();
            window.history.pushState(null, '', '/s/comparar-salario-servicio/resultados')
            setCargoSalario(1)
            document.getElementById('fmCal').classList.add('d-none')
            grafico1 = JSON.parse(succ.data[0].JsonGrafico1)[0];
            grafico2total = JSON.parse(succ.data[0].JsonGrafico2);
            grafico3total = JSON.parse(succ.data[0].JsonGrafico3);
            cboFiltro = [...new Set(grafico2total.map(item => item.tipo))];
            setGrafico2(grafico2total.filter(item => item.tipo == filtro));
            setGrafico3(grafico3total.filter(item => item.tipo == filtro));


            var innerHtml = '<table style="margin:15px; background-color: #ffffff;position:relative;box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2) !important;border-radius: 5px !important;"><tbody style="font-family:Arial;background-color: #ffffff">';
            innerHtml += '<tr><td style="font-weight:bold;color: #2A2A2A;font-size:16px" id="salario">Tu salario actual: ' + formatter.format(grafico1.consultado) + '</td></tr>';
            innerHtml += '<tr><td>&nbsp;</td></tr>';
            innerHtml += '<tr style="background-color: #ffffff"><td style="color: #757575;font-size:14px">Salario Min: ' + formatter.format(grafico1.minimo) + '</td></tr>';
            innerHtml += '<tr style="background-color: #ffffff"><td style="color: #757575;font-size:14px">Salario Promedio: ' + formatter.format(grafico1.promedio) + '</td></tr>';
            innerHtml += '<tr style="background-color: #ffffff"><td style="color: #757575;font-size:14px">Salario Max: ' + formatter.format(grafico1.maximo) + '</td></tr>';
            // if(grafico1.consultado < grafico1.maximo ){
            //     setCnum1(0)
            //     innerHtml += '<tr style="background-color: transparent !important;position:absolute;bottom:-10px;left:13px;"><td style="color: #757575;font-size:14px;">' ,(),  '</td></tr>';
            //     }else{
            //     setCnum1(1)
            //     innerHtml += '<tr style="background-color: transparent !important;position:absolute;top:50px;left:-10px;"><td style="color: #757575;font-size:14px;"><img src={icono} style="width:30px;height:30px;" alt="La imagen"/></td></tr>';
            // }
            innerHtml += '</tbody></table>';
            // let currency = new CurrencyFormat('es-CO');

            Chart.elements.Rectangle.prototype.draw = function () {
                var ctx = this._chart.ctx;
                var vm = this._view;
                var left, right, top, bottom, signX, signY, borderSkipped, radius;
                var borderWidth = vm.borderWidth;
                var cornerRadius = 20;

                if (!vm.horizontal) {
                    left = vm.x - vm.width / 2;
                    right = vm.x + vm.width / 2;
                    top = vm.y;
                    bottom = vm.base;
                    signX = 1;
                    signY = bottom > top ? 1 : -1;
                    borderSkipped = vm.borderSkipped || 'bottom';
                } else {
                    left = vm.base;
                    right = vm.x;
                    top = vm.y - vm.height / 2;
                    bottom = vm.y + vm.height / 2;
                    signX = right > left ? 1 : -1;
                    signY = 1;
                    borderSkipped = vm.borderSkipped || 'left';
                }

                if (borderWidth) {
                    var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
                    borderWidth = borderWidth > barSize ? barSize : borderWidth;
                    var halfStroke = borderWidth / 2;
                    var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
                    var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
                    var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
                    var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
                    if (borderLeft !== borderRight) {
                        top = borderTop;
                        bottom = borderBottom;
                    }
                    if (borderTop !== borderBottom) {
                        left = borderLeft;
                        right = borderRight;
                    }
                }

                ctx.beginPath();
                ctx.fillStyle = vm.backgroundColor;
                ctx.strokeStyle = vm.borderColor;
                ctx.lineWidth = borderWidth;
                var corners = [
                    [left, bottom],
                    [left, top],
                    [right, top],
                    [right, bottom]
                ];

                var borders = ['bottom', 'left', 'top', 'right'];
                var startCorner = borders.indexOf(borderSkipped, 0);
                if (startCorner === -1) {
                    startCorner = 0;
                }

                function cornerAt(index) {
                    return corners[(startCorner + index) % 4];
                }

                var corner = cornerAt(0);
                ctx.moveTo(corner[0], corner[1]);
                var nextCornerId
                for (var i = 1; i < 4; i++) {
                    corner = cornerAt(i);
                    nextCornerId = i + 1;
                    if (nextCornerId == 4) {
                        nextCornerId = 0
                    }

                    let nextCorner = cornerAt(nextCornerId);

                    let width = corners[2][0] - corners[1][0];
                    let height = corners[0][1] - corners[1][1];
                    let x = corners[1][0];
                    let y = corners[1][1];

                    let radius = cornerRadius;

                    if (radius > height / 2) {
                        radius = height / 2;
                    } if (radius > width / 2) {
                        radius = width / 2;
                    }

                    ctx.moveTo(x + radius, y);
                    ctx.lineTo(x + width - radius, y);
                    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
                    ctx.lineTo(x + width, y + height - radius);
                    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
                    ctx.lineTo(x + radius, y + height);
                    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
                    ctx.lineTo(x, y + radius);
                    ctx.quadraticCurveTo(x, y, x + radius, y);
                }

                ctx.fill();
                if (borderWidth) {
                    ctx.stroke();
                }
            };


            var canvas = document.getElementById("barChart");
            var ctx = canvas.getContext("2d");
            var gradientStroke = ctx.createLinearGradient(0, 0, 0, 500);
            gradientStroke.addColorStop(0, '#429321');
            gradientStroke.addColorStop(1, '#B4EC51');


            BarChart = new Chart(ctx, {
                type: 'bar',
                scaleOverride: true,
                scaleSteps: 10,
                scaleStepWidth: 50,
                scaleStartValue: 100000,
                drawTime: 'afterDatasetsDraw',
                yMin: 25,
                yMax: 26,
                options: {
                    cornerRadius: 20,
                    barRoundness: 0.8,
                    showTooltips: true,
                    tooltips: {
                        // Disable the on-canvas tooltip
                        enabled: false,
                        xPadding: 25,
                        yPadding: 25,
                        backgroundColor: '#ffffff',
                        custom: function (tooltipModel) {
                            // return;
                            // Tooltip Element
                            var tooltipEl = document.getElementById('chartjs-tooltip');
                            let Cnum1 = document.getElementById('btnId').value;

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip';
                                tooltipEl.innerHTML = '<table></table>';
                                document.body.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = "0";
                                return;
                            }

                            // Set caret Position
                            tooltipEl.classList.remove('above', 'below', 'no-transform');
                            if (tooltipModel.yAlign) {
                                tooltipEl.classList.add(tooltipModel.yAlign);
                            } else {
                                tooltipEl.classList.add('no-transform');
                            }

                            function getBody(bodyItem) {
                                return bodyItem.lines;
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                var titleLines = tooltipModel.title || [];
                                var bodyLines = tooltipModel.body.map(getBody);



                                var tableRoot = tooltipEl.querySelector('table');
                                tableRoot.innerHTML = innerHtml;
                            }

                            // `this` will be the overall tooltip
                            var position = this._chart.canvas.getBoundingClientRect();

                            // Display, position, and set styles for font 
                            tooltipEl.style.opacity = "1";
                            tooltipEl.style.position = 'absolute';
                            if (Cnum1 == '1') {
                                tooltipEl.style.left = (position.left + 60) + window.pageXOffset + tooltipModel.caretX + 'px';
                                tooltipEl.style.top = (position.top + 105) + window.pageYOffset + tooltipModel.caretY + 'px';

                            } else {
                                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                                tooltipEl.style.top = (position.top - 10) + window.pageYOffset + tooltipModel.caretY + 'px';
                            }
                            tooltipEl.style.boxShadow = '2px 2px 3px 1px rgba(0, 0, 0, 0.2)';
                            tooltipEl.style.borderRadius = '5px';
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = '20px';
                            tooltipEl.style.pointerEvents = 'none';
                            tooltipEl.style.backgroundColor = 'white';
                            tooltipEl.style.borderColor = 'lightgray';
                            tooltipEl.style.borderWidth = '2px';
                            tooltipEl.style.marginTop = '-170px';
                            tooltipEl.style.marginLeft = '-30px';
                            tooltipEl.style.minHeight = '160px';
                            tooltipEl.style.maxHeight = '162px';
                            return;

                        }
                    },
                    plugins: {
                        datalabels: {
                            display: false,
                            color: '#e2c3f7',
                            font: {
                                style: ' bold',
                            },
                        },
                    },
                    annotateDisplay: true,
                    annotateLabel: "<%= '$' + Math.round(v3,0) %>",
                    legend: {
                        display: false
                    },

                    yAxes: [{
                        ticks: {
                            beginAtZero: false,
                            callback: function (value, index, values) {
                                return '';
                            },
                        },
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                    }],
                    annotation: {
                        drawTime: 'beforeDatasetsDraw',
                        annotations: [
                            {
                                id: 'salarioMaxBox',
                                type: 'box',
                                xScaleID: 'x-axis-1',
                                yScaleID: 'y-axis-0',
                                xMin: 1,
                                xMax: 3,
                                yMin: 40000000,
                                yMax: 40000000 - 2000000,
                                backgroundColor: 'rgb(0, 145, 90, 0.2)',
                                //borderColor: 'rgb(255, 0, 0)',
                                borderWidth: 1
                            },
                            {
                                id: 'salarioMaxLine',
                                type: 'line',
                                mode: 'horizontal',
                                scaleID: 'y-axis-0',
                                value: 40000000 - 180000,
                                borderDash: [2, 4],
                                borderColor: 'rgb(0, 145, 90)',
                                borderWidth: 2,

                                label: {
                                    position: "left",
                                    enabled: true,
                                    content: `Salario Max: ${formatter.format(grafico1.maximo)}`,
                                    backgroundColor: 'rgba(255, 0, 0, 0.0)',
                                    fontStyle: "regular",
                                    fontColor: 'rgb(0, 145, 90)',
                                    yAdjust: 17,
                                    fontFamily: 'Gelion-Regular',
                                }
                            },
                            {
                                id: 'salarioPromedio',
                                type: 'line',
                                mode: 'horizontal',
                                scaleID: 'y-axis-0',
                                value: grafico1.consultado >= grafico1.maximo ? 40000000 - 600000 : grafico1.consultado <= grafico1.minimo ? 2400000 : grafico1.consultado == grafico1.promedio ? 20200000 : grafico1.consultado > grafico1.promedio ? (grafico1.consultado * 0.4) + 20000000 : grafico1.consultado < grafico1.promedio ? (grafico1.consultado * 0.2) + 8000000 : isBetweenCont(grafico1.consultado, grafico1.promedio),
                                borderDash: [2, 4],
                                borderColor: 'rgb(5, 106, 68)',
                                borderWidth: 2,
                                label: {
                                    position: "left",
                                    enabled: true,
                                    content: `Tu Salario actual: ${formatter.format(grafico1.consultado)}`,
                                    backgroundColor: 'rgba(255, 0, 0, 0.0)',
                                    fontStyle: "regular",
                                    fontColor: 'rgb(5, 106, 68)',
                                    yAdjust: grafico1.consultado <= grafico1.minimo || grafico1.consultado <= grafico1.promedio ? -17 : grafico1.consultado >= grafico1.maximo ? 35 : 17,
                                    fontFamily: 'Gelion-Regular',
                                }
                            },

                            {
                                id: 'salarioProm',
                                type: 'line',
                                mode: 'horizontal',
                                scaleID: 'y-axis-0',
                                value: 20000000,
                                borderDash: [2, 4],
                                borderColor: 'rgb(86, 86, 86)',
                                borderWidth: 1,
                                label: {
                                    position: "left",
                                    enabled: true,
                                    content: `Salario promedio: ${formatter.format(grafico1.promedio)}`,
                                    backgroundColor: 'rgba(255, 0, 0, 0.0)',
                                    fontStyle: "regular",
                                    fontColor: 'rgb(54, 54, 54)',
                                    yAdjust: 17,
                                    fontFamily: 'Gelion-Regular',
                                }
                            },

                            {
                                id: 'salarioMinBox',
                                type: 'box',
                                xScaleID: 'x-axis-1',
                                yScaleID: 'y-axis-0',
                                xMin: 1,
                                xMax: 3,
                                yMin: 2,
                                //yMax: 1700000,
                                yMax: 2000000,
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                //borderColor: 'rgb(255, 0, 0)',
                                borderWidth: 1
                            }, {
                                id: 'salarioMinLine',
                                type: 'line',
                                mode: 'horizontal',
                                scaleID: 'y-axis-0',
                                value: 2000000,
                                //value:1700000,
                                borderDash: [2, 3],
                                borderColor: 'rgb(217, 8, 8)',
                                borderWidth: 2,
                                label: {
                                    enabled: true,
                                    position: "left",
                                    content: `Salario Min: ${formatter.format(grafico1.minimo)} `,
                                    //src:'../../../../assets/img/itt/bancolombia-arrow-right-gray.svg',
                                    backgroundColor: 'rgba(255, 0, 0, 0.0)',
                                    fontStyle: "regular",
                                    fontColor: 'rgb(201, 10, 10)',
                                    yAdjust: 17,
                                }
                            }]
                    },

                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                display: false,
                                suggestedMin: 100000,
                                suggestedMax: 40000000,
                            }
                        }]
                    }
                },
                data: {
                    labels: ["", "", "", "", ""],
                    datasets: [{
                        label: 'Tu salario actual',
                        data: [0, 0, grafico1.consultado >= grafico1.maximo ? 40000000 - 600000 : grafico1.consultado <= grafico1.minimo ? 2400000 : grafico1.consultado == grafico1.promedio ? 20200000 : grafico1.consultado > grafico1.promedio ? (grafico1.consultado * 0.4) + 20000000 : grafico1.consultado < grafico1.promedio ? (grafico1.consultado * 0.2) + 8000000 : isBetweenCont(grafico1.consultado, grafico1.promedio)
                            , 0, 0],
                        backgroundColor: '#FF7F41',
                        barThickness: 30,
                    }]
                },

            });
            setLoaderShow(false);
            setCalculadora(true)
        }).catch(error => {
            //console.log(error);
        });
    }
    const styles={
        background: "#222222",
        padding: "0px",
        height: "220px",   
    };
    const scrollToElement = ($element) =>{
        $("#"+$element)[0].scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
    const colorconvert =(value) =>{
        if(value !== ""){
            document.getElementById("tamanos").classList.remove('iputControlHvRed');
            document.getElementById("tamanos").classList.add('iputControlHv');
        }
    }
    const regionCity =(value) =>{
        if(value !== ""){
            document.getElementById("regiones").classList.remove('iputControlHvRed');
            document.getElementById("regiones").classList.add('iputControlHv');
        }
    }
    // valueResult=0
    // confirmForm=false
    const [valueResult, setValueResult] = useState(false);
    const [confirmForm, setConfirmForm] = useState(false);
    const [cargoSalario, setCargoSalario] = useState(0);
    const mostrarForm = () => {
        window.scroll(0, 0);
        if(cargoSalario === 1){
            window.history.pushState(null, '', '/s/comparar-salario-servicio/tu-informacion')
            document.getElementById("fmCal").classList.remove('d-none');
            setCargoSalario(0);
        }
        else if(cargoSalario === 0){
            ////console.log('entra a esta');
            document.getElementById("fmCal").classList.add('d-none');
            setCargoSalario(1);
        }
        // if (confirmForm == false) {
        //     window.history.pushState(null, '', '/s/comparar-salario-servicio/tu-informacion')
        //     setConfirmForm(true)
        // } else {
        //     if (valueResult == 0) {
        //         window.history.pushState(null, '', '/s/comparar-salario-servicio/resultados')
        //         setValueResult(1)
        //     } else {
        //         if (calculadora) {
        //             window.history.pushState(null, '', '/s/comparar-salario-servicio/tu-informacion')
        //         } else {
        //             window.history.pushState(null, '', '/s/comparar-salario-servicio/resultados')
        //         }
        //     }
        //     document.getElementById("fmCal").classList.add('d-none');
        //     setConfirmForm(false)
        // }
    }
    return (
        <React.Fragment>
            {loaderShow && <Loader show={loaderShow} />}
                <div className="crumbModulo breadCrumbs" style={{position: 'absolute',width: '100%',zIndex: '99',paddingLeft: '9%'}}>
                    <Link  to="/auth/inicio" className="linkBread">inicio > </Link>
                    <Link  className="linkBread">Compara tu salario </Link> 
                       
                </div>
            {calculadora && (
                <div>
                    <div class="fabs d-none d-md-block d-lg-block">
                        <a id="prime" className="fab" onClick={() => { mostrarForm() }} data-backdrop="static">
                            <div style={{marginTop:"17px"}}>
                                Recalcular {" "}
                                <img src={calculator}/>
                            </div>
                        </a>
                    </div>
                    <div class="fabsmob d-block d-md-none d-lg-none">
                        <a id="prime" className="fab" onClick={() => { mostrarForm() }} data-backdrop="static">
                            <div style={{marginTop:"17px"}}>
                                Recalcular {" "}
                                <img src={calculator}/>
                            </div>
                        </a>
                    </div>
                </div>
            )}
             
            
            <div className="row" id="fmCal">
                <div className='fondoSalary col-lg-6 col-md-6 col-sm-12' >
                    <div className='fondoInterno w-100 mt-2'>
                        <h1 className='mb-5 whiteText whiteTextmb' style={{ fontFamily: 'cibSans-Bold',fontSize:'42px',marginTop: '75px' }}>
                            Compara tu salario
                        </h1>
                        <p className='whiteText' style={{ fontFamily:'OpenSans-regular',fontSize:'16px'}}>
                            ¿Sabes cómo está tu salario en el mercado?
                            <br />
                            <br />
                            Conoce y compara tu salario actual y la oferta de paquetes de 
                            remuneración en el mercado laboral, a través de un estudio* 
                            basado en datos reales por industria, cargo, carrera y 
                            experiencia entre otros.
                        </p>
                        <br />
                        <br />
                        <p style={{ fontSize: '12px',fontFamily:'OpenSans-regular' }} className='whiteText mt-5'>
                            *El estudio salarial está respaldado por la información suministrada por Mercer Colombia Ltda, 
                            basado en una muestra real de 428 empresas, evaluando 3000 cargos en 19 sectores económicos. 
                            Este estudio muestra una tendencia de una posible remuneración y no una garantía salarial para 
                            las personas que lo consultan.
                        </p>
                    </div>
                </div>
                <div id="formSalario" className="col-lg-6 col-md-6 col-sm-12 p-0">
                    <div className="formularioContenedor">
                        <section className="contentFormulario">
                            <form >
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="tituloModal mb-3" style={{fontSize:'22px'}}>Tu información</h3>
                                    </div>
                                    <div className="col-12 mb-3 pr-0">
                                        <p className="parrafoModal" style={{fontSize: '14px'}}>
                                            Ingresa la información salarial de tu perfil 
                                            profesional y compara tu salario con las diferentes industrias.  
                                        </p>
                                    </div>
                                    <div className="col-lg-6 col-md-6 cal-sm-12 form-group">
                                        <label className='textSalaryInput'>Tu salario actual</label>
                                        <input type="text" id="salario" maxlength="11" className="inputSalary form-control iputControlHv" DigitOnly
                                            name="salary" onChange={(e) => { handleChange(e); }}
                                            placeholder="Ingresa tu salario"
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 cal-sm-12 form-group">
                                        <label className='textSalaryInput'>Sector de la industria</label>
                                        <div className="iconDown">
                                            <select id="areas" name="areas"  onChange={(e) => { getNivelJerarquico(e.target.value) }}
                                                className="inputSalary form-control iputControlHv selecticon" placeholder="Selecciona el área" >
                                                <option value="">Seleccione un área</option>
                                                {area && (
                                                    area.map(data => {
                                                        return (
                                                            <option value={data.Text}>{data.Text}</option>
                                                        )
                                                    })
                                                )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 cal-sm-12 form-group">
                                        <label className='textSalaryInput'>Nivel jerárquico</label>
                                        <div className="iconDown">
                                            <select id="niveles" name="niveles" onChange={(e) => { getCargos(_area, e.target.value) }} className="inputSalary form-control iputControlHv selecticon"
                                                placeholder="Selecciona el Nivel"
                                                formControlName="cmbLevel">
                                                <option value="">Seleccione un nivel</option>
                                                {niveles && (
                                                    niveles.map(data => {
                                                        return (
                                                            <option value={data.Text}>{data.Text}</option>
                                                        )
                                                    })
                                                )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 cal-sm-12 form-group">
                                        <label className='textSalaryInput'>Cargo</label>
                                        <div className="iconDown">
                                            <select id="cargos" name="cargos"
                                                onChange={(e) => { getCiudades(_area, _nivel, e.target.value); getTamanos(); set_Cargos(e.target.value) }}
                                                className="inputSalary form-control iputControlHv selecticon" placeholder="Selecciona un Cargo" formControlName="cmbCargo">
                                                <option value="">Seleccione un cargo</option>
                                                {cargosS && (
                                                    cargosS.map(data => {
                                                        return (
                                                            <option value={data.Text}>{data.Text}</option>
                                                        )
                                                    })
                                                )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 cal-sm-12 form-group">
                                        <label className='textSalaryInput'>Tamaño empresa</label>
                                        <div className="iconDown">
                                            <select id="tamanos" name="tamanos" className="inputSalary form-control iputControlHv selecticon"
                                                onChange={(e) => { set_Tamano(e.target.value);colorconvert(e.target.value) }}
                                                placeholder="Selecciona el tamaño de la empresa" >
                                                <option value="">Seleccione un Tamaño</option>
                                                {tamano && (
                                                    tamano.map(data => {
                                                        return (
                                                            <option value={data.Text}>{data.Text}</option>
                                                        )
                                                    })
                                                )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 cal-sm-12 form-group">
                                        <label className='textSalaryInput'>Ciudad</label>
                                        <div className="iconDown">
                                            <select id="regiones" name="regiones" className="inputSalary form-control iputControlHv selecticon" placeholder="Selecciona la ciudad" onChange={(e) => { set_City(e.target.value);regionCity(e.target.value) }}>
                                                <option value="">Seleccione una Ciudad</option>
                                                {cityS && (
                                                    cityS.map(data => {
                                                        return (
                                                            <option value={data.Text}>{data.Text}</option>
                                                        )
                                                    })
                                                )
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-12 text-center mt-4">
                                        <button type="button" className="btnYellow" id="calSa" onClick={() => { saveData(_area, _nivel, _cargos, _city, _salary) }}
                                         style={{width: '310px',textTransform:'uppercase'}}>
                                            Comparar mi salario
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
            {cargoSalario == 1 && (
                <React.Fragment>
                    <div className="crumbModulo breadCrumbs" style={{position: 'absolute',width: '100%',zIndex: '99',paddingLeft: '9%'}}>
                    <Link  to="/auth/inicio" className="linkBread">inicio > </Link>
                    <Link  className="linkBread" to="/s/comparar-salario-servicio">Compara tu salario > </Link> 
                    <Link  className="linkBread">Resultado </Link>    
                </div>

                    <div style={{ backgroundColor: '#ebf0f6' }} >
                        <div class="row" style={{ marginTop: '-27px' }}>
                            <div style={{ backgroundColor: '#2A2A2A' }} class="col-lg-5 col-md-12" id="parrafo1">
                            <svg className='d-none d-md-block d-lg-block' xmlns="http://www.w3.org/2000/svg" width="123" height="31" viewBox="0 0 123 31"
                                style={{ position: 'absolute', bottom: '-1px', marginLeft: '15%', cursor: 'pointer' }}
                                onClick={() => { scrollToElement('parrafo2')}}>
                                <g fill="none" fillRule="evenodd">
                                    <path fill="#EEEEEE" 
                                        d="M-23 30.988H.35c12.703.333 25.026-6.059 36.97-19.177 17.916-19.676 37.881-11.268 47.57 0 9.688 11.27 21.03 19.177 38.401 19.177H151-23z" />
                                    <g transform="translate(49 5)">
                                        <ellipse cx="12.5" cy="11" fill="#2A2A2A" rx="12.5" ry="12" />
                                        <path fill="#fff" fillRule="nonzero"
                                            d="M7.865 8.964l-1.241 1.568 5.679 4.496 5.57-4.503-1.257-1.555-4.326 3.497z" />
                                    </g>
                                </g>
                            </svg>
                                <div class="row" style={{ paddingTop: '120px', paddingBottom: '183px' }}>
                                    <div class="col-lg-10 offset-lg-2 col-md-10 col-sm-8 offset-md-2 offset-sm-2" style={{ margin: 'auto' }}>
                                        <h1 class="tituloSalario textWhite">

                                            <img src={icon1} className="mr-2"/>
                                            {'    '} Comparación salarial
                                        </h1>
                                        <p class="parrafoSalario textWhite mt-5">
                                            Al diligenciar tu información comparamos tu salario actual y la oferta de
                                            paquetes de remuneración en el mercado laboral, a través de un estudio*
                                            basado en datos reales por industria, cargo, carrera y experiencia entre otros.
                                            <br /><br />
                                            En la gráfica podrás observar tu salario actual en comparación con el
                                            salario promedio, salario máximo o salario mínimo del mercado
                                            <br /><br />
                                            <span style={{ fontSize: '12px' }}>
                                                *El estudio salarial está respaldado por la información suministrada 
                                                por Mercer Colombia Ltda, basado en una muestra real de 428 empresas, 
                                                evaluando 3000 cargos en 19 sectores económicos. Este estudio muestra 
                                                una tendencia de una posible remuneración y no una garantía salarial 
                                                para las personas que lo consultan.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ backgroundColor: '#ebf0f6', paddingLeft: '10%', paddingTop: '10%' }}
                                class="col-lg-6 col-md-12 text-center">
                                <div style={{ textAlign: 'center' }} id="divCanvasChart1">

                                    <canvas id="barChart" width="300" height="300"></canvas>
                                    <input type="number" class="d-none" value={Cnum1} id="btnId"></input>
                            </div>
                            </div>
                        </div>
                    </div >
                    <div class="row" id="parrafo2">
                        <div style={{ backgroundColor: '#EEEEEE' }} class="col-lg-5 col-md-12">

                            <svg className='d-none d-md-block d-lg-block' xmlns="http://www.w3.org/2000/svg" width="123" height="31" viewBox="0 0 123 31"
                                style={{ position: 'absolute', bottom: '-1px', marginLeft: '15%', cursor: 'pointer' }}
                                onClick={() => { scrollToElement('parrafo3')}}>
                                <g fill="none" fillRule="evenodd">
                                    <path fill="#2A2A2A" 
                                        d="M-23 30.988H.35c12.703.333 25.026-6.059 36.97-19.177 17.916-19.676 37.881-11.268 47.57 0 9.688 11.27 21.03 19.177 38.401 19.177H151-23z" />
                                    <g transform="translate(49 5)">
                                        <ellipse cx="12.5" cy="12" fill="#EEE" rx="12.5" ry="12" />
                                        <path fill="#2A2A2A" fillRule="nonzero"
                                            d="M7.865 8.964l-1.241 1.568 5.679 4.496 5.57-4.503-1.257-1.555-4.326 3.497z" />
                                    </g>
                                </g>
                            </svg>
                            <div class="row" style={{ padding: '120px 2rem 183px 2rem' }}>
                                <div class="col-lg-10 offset-lg-2 col-md-10 col-sm-8 offset-md-2 offset-sm-2" style={{ margin: 'auto' }}>
                                    <h1 class="tituloSalario textWhite" style={{color:'#000'}}>
                                        <img src={icon2} className="mr-2"/>
                                        {'    '}  Análisis sectorial
                                    </h1>
                                    <p class="parrafoSalario textSector mt-5" style={{color:'#000'}}>
                                        Además de comparar tu salario con el mercado, en este espacio te mostramos la oferta de remuneración que
                                        tiene tu perfil en otras industrias, de esta forma puedes conocer a cuánto podrías aspirar si decides
                                        arriesgarte a un cambio.
                                        <br /><br />
                                        <span style={{ fontSize: '12px' }}>
                                            *El estudio salarial está respaldado por la información 
                                            suministrada por Mercer Colombia Ltda, basado en una muestra 
                                            real de 428 empresas, evaluando 3000 cargos en 19 sectores económicos.
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#f4faff', display: 'table', padding: '0% 15% 8% 3%', maxWidth:"101%"}}
                            class="col-lg-7 col-md-12 text-center">
                            <div class="row d-none">
                                <div class="col-lg-12">
                                    <div class="chart-title form-group d-block d-xl-none d-lg-none"
                                        style={{ width: '200px', zIndex: '20', left: '15px', fontWeight: 'bold', position: 'absolute', textAlign: 'left' }}>
                                        <label>Sector de la industria</label>

                                    </div>
                                    <div class="chart-title form-group d-none d-lg-block"
                                        style={{ width: '200px', zIndex: '20', left: '15px', fontWeight: 'bold', position: 'absolute', textAlign: 'left' }}>
                                        <label>Sector de la industria</label>
                                    </div>
                                </div>
                            </div>
                            <div class="chart-wrap col-lg-12 col-md-12">
                                <div id="dashboard-stats" class="chart bars-horizontal brand-primary horizontalBars"
                                    style={{ overflow: 'initial', paddingTop: '90px', position: 'relative', left: '30px' }}>
                                    <label class="labelSect">Sector de la industria</label>
                                    {grafico2 && (
                                        grafico2.map(data => {
                                            return (
                                                <div class="row mt-2" >
                                                    <div className='col-lg-7 col-md-7 col-sm-12 col-12 textRightSal' style={{ lineHeight: '1.1' }}>
                                                        <span class="label" style={{ marginTop: '-6px', right: '50%', textAlign: 'right', width: '52%', lineHeight: 'initial', fontSize: '14px' }}>
                                                            {data.industria}
                                                        </span>
                                                    </div>
                                                    <div class="bar-wrap col-lg-4 col-md-4 col-sm-12 col-12 p-0"
                                                        style={{ backgroundColor: '#E0E9F5', height: '5px', marginTop: '11px', borderRadius: '5px' }}>
                                                        <div class="bar"
                                                            style={{ backgroundColor: '#51c08a', width: data.avance + '%', height: '5px', borderRadius: '5px' }}>
                                                            {/* [style.width]="data.avance + '%'" [style.margin-left]="data.avance + '%'" */}
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-1 col-md-1 col-sm-12 col-12 mb-sm-3 textRightSal'>
                                                        <span className="numeroGrafico" style={{ top: '-10px', fontSize: '14px', borderRadius: '5px' }}>{data.valor}</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-12"></div>
                    </div>
                    <div class="row" id="parrafo3">
                        <div style={{ backgroundColor: '#2A2A2A' }} class="col-lg-5 col-md-12">
                            <div class="row" style={{ padding: '120px 2rem 183px 2rem' }}>
                                <div class="col-lg-10 offset-lg-2 col-md-10 col-sm-8 offset-md-2 offset-sm-2" style={{ margin: 'auto' }}>
                                    <h1 class="tituloSalario textWhite">
                                        <img src={icon3} className="mr-2"/>
                                        {'    '}   Plan de carrera
                                    </h1>
                                    <p class="parrafoSalario textWhite mt-5">
                                        En este espacio, puedes tener una guía de cómo 
                                        sería tu plan de carrera si continuas trabajando en 
                                        tu actual industria.
                                        <br /><br />
                                        <span style={{ fontSize: '12px' }}>
                                            *El estudio salarial está respaldado por la información 
                                            suministrada por Mercer Colombia Ltda, basado en una 
                                            muestra real de 428 empresas, evaluando 3000 cargos en 
                                            19 sectores económicos.
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#eeeeee', padding: '0% 15% 8% 3%' }}
                            class="col-lg-7 col-md-12 text-center">
                            <div class="row d-none">
                                <div class="col-lg-12">
                                    <div class="chart-title form-group d-block d-xl-none d-lg-none"
                                        style={{ width: '200px', zIndex: '20', left: '15px', fontWeight: 'bold', position: 'absolute', textAlign: 'left' }}>
                                        <label>Sector de la industria</label>

                                    </div>
                                    <div class="chart-title form-group d-none d-lg-block"
                                        style={{ width: '200px', zIndex: '20', left: '15px', fontWeight: 'bold', position: 'absolute', textAlign: 'left' }}>
                                        <label>Sector de la industria</label>
                                    </div>
                                </div>
                            </div>
                            <div class="chart-wrap col-lg-12 col-md-12">
                                <div id="dashboard-stats" class="chart bars-horizontal brand-primary horizontalBars"
                                    style={{ overflow: 'initial', paddingTop: '90px', position: 'relative', left: '30px' }}>
                                    <label class="labelSect">{' '}Nivel jeararquico</label>
                                    {grafico3 && (
                                        grafico3.map(data => {
                                            return (
                                                <div class="row mt-2" >
                                                    <div className='col-lg-7 col-md-7 col-sm-12 col-12 textRightSal' style={{ lineHeight: '1.1' }}>
                                                        <span class="label" style={{ marginTop: '-6px', right: '50%', textAlign: 'right', width: '52%', lineHeight: 'initial', fontSize: '14px' }}>
                                                            {data.industria}
                                                        </span>
                                                        <span class="label" style={{ marginTop: '-6px', right: '50%', textAlign: 'right', width: '52%', lineHeight: 'initial', fontSize: '14px' }}>
                                                            {data.nivel}
                                                        </span>
                                                    </div>
                                                    <div class="bar-wrap col-lg-4 col-md-4 col-sm-12 col-12 p-0"
                                                        style={{ backgroundColor: '#fff', height: '5px', marginTop: '11px', borderRadius: '5px' }}>
                                                        <div class="bar"
                                                            style={{ backgroundColor: '#FF7F41', width: data.avance + '%', height: '5px', borderRadius: '5px' }}>
                                                            {/* [style.width]="data.avance + '%'" [style.margin-left]="data.avance + '%'" */}
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-1 col-md-1 col-sm-12 col-12 mb-sm-3 textRightSal'>
                                                        <span className="numeroGrafico" style={{ top: '-10px', fontSize: '14px', borderRadius: '5px' }}>{data.valor}</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-12"></div>
                    </div>
                </React.Fragment>
            )
            }
        </React.Fragment >
    )
}