import React,{useRef} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

export default function Tooltips(props){
    const target = useRef(null);

    return(
        <>
        <OverlayTrigger
            key="top"
            placement="top"
            overlay={
            <Tooltip id={`tooltip-top`} bsPrefix={props.bsPrefix} >
                {props.children}
            </Tooltip>
            }
        >
          <Button id={props.idBtn}></Button> 
        </OverlayTrigger>{' '}
        </>
    );
}