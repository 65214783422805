import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function ModalScore(props) {
    const { title, disabled} = props;
    const [show, setShow] = useState(false);

    const handleClose = () => {
      setShow(false)
      // document.getElementById('checkbox-terminos').click()
    };
    const handleShow = () => setShow(true);

    const dobleEnter = (
        <>
          <br />
          <br />
        </>
      );

    return(
        <>
        <span style={{color:"#2A2A2A", textDecoration: "underline",cursor:"point"}} onClick={handleShow} className="hover mr-3 text-center">
             {title}
        </span>
        <Modal 
          show={show}
          onHide={handleClose} 
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
      >
        <Modal.Header>
        <div style={{ height: "40px"}}>
          <button
            id="btnCierraModal"
            type="button"
            className="swal2-close-modal"
            style={{marginTop: "56px", marginRight:"12px"}}
            onClick={handleClose}
          ><p>×</p></button>
        </div>
        </Modal.Header>
        <Modal.Body style={{ boxShadow:"0px 4px 16px 0px #00000020"}}>
          <div className="overflow-scroll body-modal-t">
          <h4 className="tittle-modal-t modalScore" >
          Autorización para la consulta, reporte y procesamiento de datos financieros a centrales de riesgo y procesamiento de datos personales:
          </h4>
          <p className="overflow-scroll text-justify modalScore-text" style={{fontFamily:"OpenSans-regular"}}>
            Autorizo de manera previa, expresa informada a CARDIF COLOMBIA SEGUROS GENERALES S.A. 
            identificada con NIT 900.200.435-3 y domicilio principal en Bogotá D.C. o a quien represente sus 
            derechos, a consultar, solicitar, suministrar, reportar, procesar y divulgar toda la información que se 
            refiere a mi comportamiento crediticio, financiero y comercial ante las 
            Centrales de Riesgo que administra la Asociación Bancaria y de Entidades 
            Financieras de Colombia  o a cualquier otro operador y/o fuente de 
            información legalmente establecido y para que realice la recolección, 
            almacenamiento, uso, divulgación,  transferencia y transmisión, a nivel nacional e internacional, 
            incluso a terceros ubicados en países que no proporcionen niveles adecuados de protección, 
            a que procese mis datos personales, entendidos como cualquier información vinculada o que 
            pueda asociarse a mí y que permita mi identificación (los “Datos Personales”), como lo son mi 
            nombre, edad, género, dirección, teléfono, correo electrónico, historia laboral,  
            y número de documento de identidad, todo lo anterior conforme a la Política de Tratamiento de la 
            Información de Cardif Colombia Seguros Generales (la “Política”), 
            disponible en www.bnpparibascardif.com.co enlace “Privacidad”.   
            { dobleEnter }
            Estas actividades se realizarán con el propósito de promocionar y mercadear productos y servicios 
            nuevos y existentes, enviarle comunicaciones y/o notificaciones a través de cualquier medio, 
            ofrecerle un mejor servicio, llevar a cabo encuestas de satisfacción y remitirle información 
            sobre sus reclamaciones.  
            { dobleEnter }
            Así mismo, autorizo, de manera previa, expresa e informada a CARDIF COLOMBIA SEGUROS GENERALES S.A.  
            para que consulte toda la información financiera, crediticia, comercial, de servicios y la proveniente 
            de otros países, atinente a mis relaciones comerciales que tenga con el Sistema Financiero, comercial y 
            de servicios, o de cualquier sector, tanto en Colombia como en el Exterior, en cualquier tiempo. 
            { dobleEnter }
            La presente autorización se extiende para que CARDIF COLOMBIA SEGUROS GENERALES S.A. pueda compartir información 
            con terceros públicos o privados, bien sea que éstos ostenten la condición de fuentes de información, operadores 
            de información o usuarios, con quienes yo tenga vínculos jurídicos de cualquier naturaleza, todo conforme a lo 
            establecido en las normas legales vigentes dentro del marco del Sistema de Administración de Riesgos de Lavado de 
            Activos y Financiación al Terrorismo SARLAFT de CARDIF COLOMBIA SEGUROS GENERALES S.A. 
            { dobleEnter }
            Declaro que he sido informado sobre mis derechos a presentar solicitudes de información, actualización, supresión, 
            revocatoria de autorización y/o rectificación de los Datos Personales, y que cualquier consulta, solicitud, reclamo, 
            queja y petición relacionada podré dirigirla a  atencionalcliente@cardif.com.co”. 
            { dobleEnter }
            CARDIF COLOMBIA SEGUROS GENERALES S.A., están comprometidas con el tratamiento leal, lícito, confidencial y 
            seguro de sus datos personales. Por favor consulte nuestra Política de Tratamiento de Información 
            en: <u>www.bnpparibascardif.com.co</u>  en donde puede conocer sus derechos constitucionales y legales así 
            como la forma de ejercerlos.  
            { dobleEnter }
            *El puntaje mostrado en este servicio NO es una garantía que las entidades financieras vayan a otorgar un crédito, 
            solamente corresponde al reporte suministrado por datacredito y no tiene relación alguna con el score que cada Banco 
            hace a sus clientes con sus propios puntajes y lineamientos.
          </p>
          </div>
          <div className="text-center">
            <button
              type="button"
              onClick={handleClose}
              className="btnModal pl-5 pr-5"
              style={{background: "#FFD200"}}
            >
              Aceptar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      </>
    );
}