import React from 'react'; 
import { getUser } from '../utils/validations';

export const infoSheduling = {
    typeSheduling: null,
    colorPrimary: "#323a4d",
    colorDisable: "#212529",
    serviceId: "1064",
    sponsorCode: 79,
    sponsor: process.env.REACT_APP_SPONSOR,
    idUsuario: getUser()?.idUsuario,
    nombres: "",
    correo: "",
    typeTextSheduling: ""
};