import React, { useEffect, useRef, useState, } from 'react'
import { Button, Form, InputGroup, OverlayTrigger, Popover, Col, Row } from 'react-bootstrap'
import { passwordSecurity } from '../../utils/validations';
import eyetrue from "../../assets/img/eyetrue.svg";
import eyefalse from "../../assets/img/eyefalse.svg";
import "../../views/register/RegisterView.css";
import './InputComponentPassword.css';



export const InputComponentPassword = ({ valueInput, funtioninput, TypeInput, textLabel, BtnState, idInput, placeholder, TypePassword }) => {
    const popRef = useRef()
    const [width, setWidth] = useState(window.screen.width);
    const [position, setPosition] = useState('right')

    const onMouseHover = (number) => {
        setWidth(window.screen.width)
        if ((popRef.current == undefined && number == 1) || (popRef.current != undefined && number == 2)) {
            document.getElementById(`btContraPassword-${idInput}`).click()
        }
    }
    const popover = (
        <Popover id={`popover-basic-${idInput}`} className="contPopover">
        <Row>
            <Col className="col-12 ">
                <p className="txtPopover">
                    Esta contraseña es exclusiva para el ingreso al ecosistema
                </p>
            </Col>
            <Col className="col-12 my-2">
                <p className="titPopover">
                    Tu contraseña requiere:
                </p>
            </Col>
            <Col className="col-12">
                <Row className="mt-1 align-items-center">
                    <Col className="col-2 p-0">
                    <div className={`${/[A-Z]/.test(valueInput) ? 'checkCorrecto' : 'checkErrado'}`}></div>
                    </Col>
                    <Col className="col-10">
                        <p className="txtPopover">
                            Una mayúscula
                        </p>
                    </Col>
                </Row>
                <Row className="mt-1 align-items-center">
                    <Col className="col-2 p-0">
                    <div className={`${/[a-z]/.test(valueInput) && valueInput !== undefined ? 'checkCorrecto' : 'checkErrado'}`}></div>
                    </Col>
                    <Col className="col-10">
                        <p className="txtPopover">
                            Una minúscula
                        </p>
                    </Col>
                </Row>
                <Row className="mt-1 align-items-center">
                    <Col className="col-2 p-0">
                    <div className={`${/[0-9]/.test(valueInput) ? 'checkCorrecto' : 'checkErrado'}`}></div>
                    </Col>
                    <Col className="col-10">
                        <p className="txtPopover">
                            Un número
                        </p>
                    </Col>
                </Row>
                <Row className="mt-1 align-items-center">
                    <Col className="col-2 p-0">
                    <div className={`${valueInput !== undefined ?(valueInput.length > 7 && valueInput.length < 17 ? 'checkCorrecto' : 'checkErrado'):'checkErrado'}`}></div>
                    </Col>
                    <Col className="col-10">
                        <p className="txtPopover">
                            Minimo 8 y máximo 16 caracteres
                        </p>
                    </Col>
                </Row>
                <Row className="mt-1 align-items-center">
                    <Col className="col-2 p-0">
                    <div ref={popRef} className={`${/[$@$!%*?&¿/#,;+.'ñ-]/.test(valueInput) ? 'checkCorrecto' : 'checkErrado'}`}></div>
                    </Col>
                    <Col className="col-10">
                        <p className="txtPopover">
                            Un caracter especial (Ejemplo: *,$;#)
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>            
    </Popover>
    );

    useEffect(() => {
        if (width <= 767 && position != 'top') {
            setPosition('top')
        } else if (width > 768 && position != 'right') {
            setPosition('right')
        }

    }, [width])

    const [typeField, setTypeField] = useState(false);
    return (
        <>
            <InputGroup className="mb-2" id='inputGrupoReg'>
                {
                    TypeInput !== "password" && (
                        <>
                            <Form.Control
                                name={textLabel}
                                className='inputClass'
                                aria-label="Documento"
                                type='text'
                                value={valueInput}
                                onChange={funtioninput}
                                disabled={BtnState}
                                id={idInput}
                                placeholder={placeholder}
                            />
                        </>
                    )
                }
                {
                    TypeInput === "password" && (
                        <>
                            <Form.Control
                                name={textLabel}
                                className='inputClass'
                                aria-label="Documento"
                                type={(typeField) ? 'text' : TypeInput}
                                value={valueInput}
                                onChange={funtioninput}
                                disabled={BtnState}
                                id={idInput}
                                placeholder={placeholder}
                                onMouseOver={() => onMouseHover(1)}
                                onMouseOut={() => onMouseHover(2)}
                                onFocus={() => onMouseHover(1)}
                            />
                            <div
                                id="inpEyePass"
                                className="input-group-prepend inputPassEye"
                                onClick={() => setTypeField(!typeField)}
                            >
                                <span id="basic-addon3">
                                    <img style={{ color: "#BABABA" }} src={typeField ? eyetrue : eyefalse} />
                                </span>
                            </div>
                            <div className="contBtContraPassword">
                                <OverlayTrigger
                                    placement={position}
                                    trigger="click"
                                    overlay={popover}
                                    flip={true}
                                >
                                    <Button
                                        id={`btContraPassword-${idInput}`}
                                        className="btContraPassword"
                                        onClick={() => onMouseHover(0)}
                                    >
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </>
                    )
                }
            </InputGroup>
        </>
    )
}
