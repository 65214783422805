import React,{useState} from "react";
import Modal from "react-bootstrap/Modal";

export default function ModalAutoData() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
        <span className="hover mr-1 text-left disclamersFooter col-10" style={{lineHeight: '1'}}>
           <a className="seleccion" style={{fontSize: '14px'}}>Acepto la</a> <a onClick={handleShow} style={{cursor:"pointer",color: "#006dbc",fontSize:"14px",textDecorationLine:"underline"}}>Autorización de uso de los datos e información de beneficiarios.</a>
        </span>
        
            <Modal
                show={show}
                onHide={handleClose}
                className="mt-5 text-center"
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered
            >
                <Modal.Header>
                    <div className="btnCierraModalPrincipal">
                        <button id="btnCierraModalData" 
                        type="button" 
                        className="swal2-close-modal" 
                        onClick={handleClose}>
                           <p>×</p>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body id='bodyData' style={{ margin: '0px 15%'}}>
                    <h3 className="tittle-modal-bene pt-1"
                    >Autorización de uso de los datos e información de beneficiarios</h3>
                    <div className="overflow-scroll body-modal-t pl-4 pr-4 pt-3" style={{
                        fontFamily: "Helvetica", fontSize: "14px", color: "#808285",
                        lineHeight: "1.8", height: "260px", textAlign:'left'}}
                    > 
                    De conformidad con las normas que regulan el Hábeas Data y el tratamiento de datos personales: 
                    En mi calidad de titular de la póliza y de los servicios del ecosistema, al registrar o actualizar 
                    información de mis beneficiarios del ecosistema, asumo la responsabilidad sobre la veracidad de los mismos. 
                    De igual manera, autorizo contactarlos, al número celular y/o correo electrónico registrado en la presente página, 
                    con fines promocionales y de usabilidad relacionados con el servicio de educación.
                    </div>
                    <div className="text-center mb-5" style={{marginTop:"-53px"}}>
                        <button type="button" onClick={handleClose} className="btnAzul" style={{ borderRadius: "5px",width: "176px",backgroundColor: '#323B4E',fontFamily:'Next' }}>
                            ACEPTAR
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}