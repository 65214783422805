import React, { useEffect, useState } from "react";
import ModalSaveBene from "../../components/modal/ModalSaveBene";
import Axios from "axios";
import Swal from "sweetalert2";
import { urlAPI } from '../../utils/config';
import { PdfAdult } from "../../components/pdf/PdfAdult";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight} from "@fortawesome/free-solid-svg-icons";

export default function AuthorizationAdult(props) {
    const [showModalSave,setShowModalSave] = useState(false);
    const [infoBeneficiary, setInfoBeneficiary] = useState(defaultFormValue);
    const [cajaShow, setCajaShow] = useState("flex");
    const { step } = props.match.params;
    const [count,setCount] = useState(0);

    function defaultFormValue(){
        return {
            idBeneficiario:'',
            nombres: "",
            apellidos: "",
            dicParentesto: "29156",
            dicTipoIdentificacion: "",
            identificacion: "",
            idAutorizacion:'',
            link:''
        };
    }
    useEffect(() => {
        if(count === 0){
            getInfoBeneficiary();
        }
    },[count]);

    const handleWindow = ()=>{
        window.close();
        setShowModalSave(false);
    }

    const handleSaveBeneficiary = () => {
        var date = new Date();
        var info = '';
        Axios.get('https://ipapi.co/json/?key=vgn91pD3PXJhodzX3llb7PGxJpyemvUhamyxnhm8fTSvNJ9J5P').then(succ=>{
            info = JSON.stringify({
                pagina:"Autorizacion Adulto",
                control:"Guardando informacion de la autorizacion",
                direccion_ip:succ.data.ip,
                ciudad_localizacion:succ.data.city,
                horaLocal: `${date.getHours()<10?'0'+date.getHours():date.getHours()}:${date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes()}`,
                fechaLocal: `${date.getDate()}/${(date.getMonth()+1)<10?`0${date.getMonth()+1}`:date.getMonth()+1}/${date.getFullYear()}`,
                extra: "nombreCompleto: " + infoBeneficiary.nombres + " " + infoBeneficiary.apellidos + 
                ", identificacion: " + infoBeneficiary.identificacion,
            })
        let obj = {
            idAutorizacion:infoBeneficiary.idAutorizacion,
            idBeneficiario:infoBeneficiary.idBeneficiario,
            pdf:PdfAdult(infoBeneficiary.nombres+' '+infoBeneficiary.apellidos,infoBeneficiary.identificacion),
            link:infoBeneficiary.link,
            log:info
        }
        setShowModalSave(true);
        Axios.post(`${urlAPI}/Login/guardarAutorizacionMenores`, JSON.stringify(obj), { 
            headers: { "Content-Type": "application/json", } 
        }).then(succe => {
        }).catch(error => {
        });
        }).catch();
    }

    const getInfoBeneficiary = () =>{
        Axios.post(`${urlAPI}/Login/autorizacionMenores`, JSON.stringify({ link: step }), {
            headers: { "Content-Type": "application/json", }
        }).then(scc => {
            if (scc.data.codigo === 0) {
                setInfoBeneficiary({
                    idBeneficiario:scc.data.data.idBeneficiario,
                    nombres: scc.data.data.nombres,
                    apellidos: scc.data.data.apellidos,
                    identificacion: scc.data.data.identificacion,
                    dicTipoIdentificacion: scc.data.data.dicTipoIdentificacionBen,
                    idAutorizacion:scc.data.dataExtra,
                    link:step,
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Opss!',
                    text: 'Link vencido'
                });
            }
        }).catch(error => {
            //console.log(error);
        });
    }
    useEffect(()=>{
        //console.log(infoBeneficiary);
    },[infoBeneficiary]);
    const dobleEnter = (
        <>
            <br />
            <br />
        </>
    );

    return (
        <React.Fragment>
             {showModalSave && (<ModalSaveBene showM={showModalSave} clickClose={()=>{handleWindow()}} typeTitle="AutoAdult"/>)}
             <div className="container">
                <div className="row contentTituloAutorizacion containerCrumbs">
                    <p className="tituloAutorizacion" style={{marginLeft:"17%"}}>
                        Beneficiario
                    </p>
                </div>
                <div className="containerInfo"  style={{marginTop:"-67px"}}>
                    <div id="CardBorder" className="card  cardAuthorAdult col-lg-9 offset-lg-1 col-sm-12 mb-5" style={{margin:"auto"}}>
                        <p style={{ fontFamily: "Inter-bold", fontSize: "22px", marginBottom: "45px", color:"#5D676C"}}>
                            Autorización informada términos y condiciones
                        </p>
                        <div className="AutoriPadding">
                            <p style={{fontFamily:"Inter-bold", fontSize:"14px", color:"#58595B"}}>
                                Declaración Legal
                            </p>
                            <p className="body-modal-adult">
                            Por favor lea con atención estos términos y condiciones de uso (los “Términos y Condiciones” o el
                            “Acuerdo”) antes de utilizar el servicios relacionados con el ecosistema de desempleo para
                            empleados, ecosistema de incapacidad total temporal para independientes y ecosistema de fraude
                            protección de cuentas y tarjetas (los “Servicios”), incluidas todas las herramientas, funcionalidades,
                            interfaces de usuario, software y contenidos que están disponibles a través la página web
                            www.vivesegura.com (el “Sitio”). Este Acuerdo establece los términos y condiciones legales y
                            vinculantes a los cuales usted, en su condición de usuario del Sitio y del Servicio, estará sometido (el
                            “Usuario”). Al usar el Servicio de cualquier manera, incluyendo, pero no limitado a, registrarse en el
                            Sitio, navegar a través del Sitio, contratar los Servicios, reproducir los contenidos audiovisuales
                            disponibles en el Sitio, que incluyen, pero no se limitan a, videos, presentaciones, test, ejercicios
                            específicos, entre otros (los “Contenidos”), el Usuario se obliga, de manera libre y voluntaria, al
                            cumplimiento de estos Términos y Condiciones.
                            {dobleEnter}
                            El Sitio es operado por CARDIF COLOMBIA SEGUROS GENERALES S.A., sociedad constituida
                            y existente conforme a las leyes de Colombia, con domicilio en Bogotá D.C.
                            {dobleEnter}
                            Estos Términos y Condiciones, sus eventuales modificaciones, y cualquier información contenida en
                            este Sitio, están sujetos a modificaciones sin aviso previo, que entrarán en vigencia a partir del
                            momento en que sean publicadas en el Sitio o notificadas expresamente al Usuario, lo que primero
                            ocurra, circunstancia que el Usuario entiende y acepta al utilizar este Sitio y al aceptar los presentes
                            Términos y Condiciones. En consecuencia, el uso del Sitio con posterioridad a la publicación de los
                            cambios realizados a los Términos y Condiciones constituye aceptación total de los mismos por parte
                            del Usuario. Es responsabilidad del Usuario revisar los Términos y Condiciones de manera periódica.
                            Toda la información que se brinda en este Sitio tiene validez únicamente dentro del territorio de la
                            República de Colombia.
                            {dobleEnter}
                            Autorizo:
                            <br/>
                            Nombre: {infoBeneficiary.nombres} {infoBeneficiary.apellidos} <br/>
                            {infoBeneficiary.dicTipoIdentificacion === 5?(
                            <span>Cédula de Ciudadanía: {infoBeneficiary.identificacion}</span>
                            ):infoBeneficiary.dicTipoIdentificacion === 6?(
                            <span>Cédula de extranjería: {infoBeneficiary.identificacion}</span>
                            ):(
                                <span>Pasaporte: {infoBeneficiary.identificacion}</span>
                            )}

                            </p>
                            <div className="col-lg-12 col-md-6 mt-2 text-center">
                                    <button className="btnMainAutoriz"
                                            onClick={() => { handleSaveBeneficiary() }}>
                                            Aceptar
                                    </button>
                            </div>
                        </div>
                     </div>
                     
                </div>
             </div>
        </React.Fragment>
    );
}
