import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContainerService from "../../../components/container-service/ContainerService";
import ModalAlerts from "../../../components/modal/ModalAlerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { urlAPI } from "../../../utils/config";
import { getUser, getToken } from "../../../utils/validations";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Gion from "../../../assets/img/icon/icon-check-green-002.svg"


function ButtonNotifications(props) {
    const { text, fondo, disabled, step, color, fuente, border, sombra } = props;
    const styles = {
        width: '350px',
        height: '50px',
        borderRadius: '30px',
        fontSize: '20px',
    };
    return (
        <button style={{ ...styles, background: fondo, color: color, fontFamily: fuente, border: border, boxShadow: sombra}} disabled={disabled} onClick={step}>
            {text}
        </button>
    )
}
function Confirm(props) {
    const { text, fondo, color } = props;
    return (
        <div className="mb-5 styleConfirm" style={{ background: fondo, color: color }}>
            <img src={Gion} style={{ width:'25px', marginTop:'-5px', marginRight: '10px'}}/>
            {/* <FontAwesomeIcon style={{ color: "#00915A", marginRight: "15px" }} icon={faCheck} /> */}
            {text}
        </div>
    )
}
function Important(props) {
    //console.log(props);
    const { text,paddingT,marginT } = props;
    const stylesDiv = {
        fontSize: '14px',
        color: '#666666',
        widht: '93%',
    };
    const stylesTittle = {
        fontFamily: 'OpenSans-bold',
        color: '#454648',
        marginLeft: '8px',
    };

    return (
        <div style={stylesDiv} >
            <p style={stylesTittle}>
                Importante:
            </p>
            <p style={{ fontFamily: 'OpenSans', color: '#454648', marginLeft:'8px'}}>
                {text}
            </p>
        </div>
    )
}

export default function AlertNotifications() {
    function defaultAlertNotification() {
        return {
            idUsuario: '',
            email: '',
            telefono: '',
            habilitado: null,
        };
    }
    const [step, setStep] = useState(1);
    const [colorChange, setColorChange] = useState("#747474")
    const [disabled, setDisabled] = useState(true);
    const [fondo, setFondo] = useState("#d8d8d8");
    const [textButton, setTextButton] = useState("ACTIVA TUS NOTIFICACIONES");
    const [codigo, setCodigo] = useState();
    const [fondoConfirm, setFondoConfirm] = useState('#FFFFFF');
    const [textConfirm, setTextConfirm] = useState("")
    const [colorConfirm, setColorConfirm] = useState("#034797")
    const [textImportant, setTextImportant] = useState("");
    const [fuente, setFuente] = useState('OpenSans-bold');
    const [sombra, setSombra] = useState('');
    const [border, setBorder] = useState('1.5px solid #292929');
    const [alertNotification, setAlertNotification] = useState(defaultAlertNotification)

    useEffect(() => {
        window.scrollTo(0, 0)
        getInfoGeneral();
        if (step === 2) {
            getStateNotifications();
        }
        stateCheck();
    }, [step])

    useEffect(() => {
        if (step === 2) {
            getStateNotifications();
        }
    }, [codigo])

    useEffect(() => {
        stateCheck();
    }, [codigo])

    const handleChange = (e) => {
        setAlertNotification({
            ...alertNotification,
            [e.target.name]: e.target.value,
        })
    }
    const stateCheck = () => {
        try {
            var check = document.getElementById('switch')
            if (codigo === 1) {
                check.checked = false;
            }
            else {
                check.checked = false;
            }

            if (!check.checked && codigo === 0) {
                setFondo('#b1b6ba');
                setDisabled(false);
                setTextButton('DESACTIVA TUS NOTIFICACIONES1')
                setColorChange("#747779")
                setBorder('0px')
                setFuente('Nunito-bold')
                setSombra('0px 1px 3px 0px #00000010')
            }
        } catch (errorCheck) {

        }
    }
    const stateConfirm = (id) => {
        if (id === 0) {
            setFondoConfirm('#FFFFFF')
            setTextConfirm("Tus notificaciones y alertas han sido activadas exitosamente.")
            setColorConfirm('#2A2A2A')
            setTextImportant("Recibirás notificaciones por medio de mensaje de texto y correo electrónico cada vez que se genere una obligación, cambios o consulta financiera asociada a tu número de cédula.")
        }
        else if (id === 1) {
            setFondoConfirm('#FFFFFF')
            setTextConfirm("Tus notificaciones y alertas han sido desactivadas exitosamente.")
            setColorConfirm('#2A2A2A')
            setTextImportant("Recibirás notificaciones por medio de mensaje de texto y correo electrónico cada vez que se genere una obligación, cambios o una consulta financiera asociada a tu número de cédula.")
        }
    }

    const disabledCheck = (e, value) => {
        var check = e.target.checked;
        if (value ===1) {
            if (check) {
                setDisabled(false)
                setFondo('#FFFFFF')
                setColorChange("#2A2A2A")
                setBorder('1.5px solid #2A2A2A')
                setFuente('OpenSans-bold')
            }
            else {
                setDisabled(true)
                setFondo('#d8d8d8')
                setColorChange("#747474")              
            }
        }
        else if (codigo ===0) {
            if (check) {
                setFondo('#FFD200');
                setDisabled(true);
                setTextButton('ACTIVA TUS NOTIFICACIONES')
                setColorChange("#2A2A2A")
                setFuente('Nunito-bold')  
                setBorder('none')            
            }
            else if(!check){
                setFondo('none');
                setDisabled(false);
                setTextButton('DESACTIVA TUS NOTIFICACIONES')
                setColorChange("#2A2A2A")
                setFuente('Nunito-bold')
                setBorder('1.5px solid #2A2A2A')
            }
        }
        else if (codigo === 1 ) {
            if (!check) {
                setFondo('#b1b6ba');
                setDisabled(true);
                setTextButton('ACTIVA TUS NOTIFICACIONES')
                setColorChange("#747779")
                setFuente('Nunito-bold')  
                setBorder('none')            
            }
            else if(check){
                setFondo('#FFD200');
                setDisabled(false);
                setTextButton('ACTIVA TUS NOTIFICACIONES')
                setColorChange("#2A2A2A")
                setFuente('Nunito-bold')
                setBorder('none') 
            }
        }
    }
    
    const getStateNotifications = (id) => {
        Axios.post(`${urlAPI}/alertaFraude/VerNotificacion?id=${parseInt(getUser().idUsuario)}`, null, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(res => {
            //console.log(res,"H")
            setCodigo(res.data.codigo)
            stateConfirm(res.data.codigo);
            //activas 0
            if (res.data.codigo === 0) {
                var check = document.getElementById('switch')
                if(check !== null ){
                    check.checked = true;
                }
                setFondo('#FFD200');
                setDisabled(false);
                setTextButton('ACTIVA TUS NOTIFICACIONES')
                setColorChange("#2a2a2a")
                setFuente('Nunito-bold')  
                setBorder('none')  
                setAlertNotification({
                    ...alertNotification,
                    habilitado: false
                })
            }
            //desactivadas 1
            else {
                var check = document.getElementById('switch')
                if(check !== null ){
                    check.checked = false;
                 }
                setFondo('#959595b0');
                setDisabled(true);
                setTextButton('ACTIVA TUS NOTIFICACIONES')
                setColorChange("#2929299e")
                setFuente('Nunito-bold')  
                setBorder('none')  
                setAlertNotification({
                    ...alertNotification,
                    habilitado: true,
                })
            }
            if (id === 3) {
                setStep(3);
            }
        })
    }
    const getInfoGeneral = () => {
        Axios.get(`${urlAPI}/Profile/InfoGeneral`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setAlertNotification({
                ...alertNotification,
                idUsuario: succ.data.data.idUsuario,
                email: succ.data.data.correoElectronico,
                telefono: succ.data.data.numeroCelular,
            })
        }).catch(error => {
            //console.error(error);
        });
    }
    const configStateNotifications = () => {
        Axios.post(`${urlAPI}/alertaFraude/Notificaciones`, JSON.stringify(alertNotification), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(res => {
            if (res.data.codigo === 0) {
                getStateNotifications(3);
            }


        })
    }
    const linkbread = [{
        to: "/auth/inicio",
        tittle: "Inicio > "
    },
    {
        to: "/auth/inicio",
        tittle: "Alertas financieras> ",
    },
    {
        to: "/auth/alertas-financieras/activacion",
        tittle: "Activación"
    }];

    return (
        <React.Fragment>
            <ContainerService tittle="Alertas y notificaciones financieras" styleTittle={{ fontSize: "34px", lineHeight: "56px", fontFamily: 'DTL-ArgoT-regular' }} linkBread={linkbread}>
                {step === 1 && (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12" style={{ padding: '30px 0px 30px 20px', fontFamily: 'Inter-regular' }}>
                                <div className="pb-4">
                                    <p style={{ fontFamily: 'OpenSans-regular', fontSize: '16px', color: '#666666', marginTop:'35px', marginLeft:'8px'}}>
                                        Aquí puedes configurar tus alertas de servicios y control de información financiera, recibiras un mensaje de alerta o
                                        correo electrónico con la notificación de apertura de nuevos productos financieros asociados a tu número de cedula.
                                        {/* En caso que exista alguna actualización recibirás un mensaje de texto 
                                y correo electrónico notificando dicha información. */}
                                    </p>
                                </div>
                                <div className="checkbox-terminos" style={{ marginTop: "15px", marginLeft:'8px'}}>
                                    <input type="checkbox" id="checkbox-alert" className="checkbox-terminos" name="alert" onClick={(e) => disabledCheck(e, 1)} />
                                    <label for="checkbox-alert"></label>
                                    <Link><ModalAlerts /></Link>
                                </div>
                                <div className="text-center pb-5">
                                    <div className="pt-5 botonNot">
                                            <ButtonNotifications disabled={disabled} fondo={fondo} color={colorChange} text={textButton} fuente={fuente} border={border} sombra={sombra} step={() => setStep(2)} />  
                                    </div>
                                </div>
                                <Important text={"Recibirás notificaciones por medio de mensaje de texto y correo electrónico cada vez que se genere una obligación, cambios o consulta financiera asociada a tu número de cédula."} />
                            </div>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-sm-12 col-md-12" style={{ padding: '30px 0px 30px 20px', color: '#808285' }}>
                                <div className="pb-5" style={{ fontFamily: 'OpenSans-regular', marginTop: '40px', marginLeft:'15px'}}>
                                    Para activar tus alertas y notificaciones confirma los siguientes datos:
                                </div>
                                <div className="d-lg-flex pb-5" style={{ fontSize: '14px' }}>
                                    <div className="col-sm-12 col-md-4 col-lg-5 pl-0 mr-2" style={{ marginRight: "30px", marginLeft:'15px'}} >
                                        <label className="labelAlerts label-text">Número de celular</label>
                                        <input type="number" style={{ marginTop: "-15px" }} className="form-control inputBorderNone" name="telefono" size="12" value={alertNotification.telefono} onChange={(e) => handleChange(e)} />
                                        <small className="form-text text-danger" ></small>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-5 pl-0 mr-2" style={{ marginRight: "30px" }} >
                                        <label className="labelAlerts label-text">Correo electrónico</label>
                                        <input type="email" style={{ marginTop: "-15px" }} className="form-control inputBorderNone" name="email" size="12" value={alertNotification.email} onChange={(e) => handleChange(e)} />
                                        <small className="form-text text-danger" ></small>
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2" style={{ marginRight: "30px", display: 'grid', marginLeft:'-18px'}} >
                                        <label className="labelAlerts mb-3">Activación</label>
                                        <label className="switch">
                                            <input type="checkbox" id="switch" className="success" onClick={(e) => disabledCheck(e)} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="text-center botonNot">
                                    <ButtonNotifications disabled={disabled} fondo={fondo} color={colorChange} text={textButton} fuente={fuente} border={border} sombra={sombra} step={() => { configStateNotifications() }} />
                                </div>
                            </div>
                        </div>
                    </div>   
                )}
                {step === 3 && (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 mobileCheck" style={{ padding: '30px 35px 65px' }}>
                                <div onClick={() => setStep(2)} className="d-flex manitoCol pb-4">
                                    <div className="volver mr-3"> <FontAwesomeIcon icon={faChevronLeft} /></div>
                                    <span>Volver</span>
                                </div>
                                <div className="col-12 mobileAlert">
                                    <Confirm text={textConfirm} fondo={fondoConfirm} color={colorConfirm} />
                                    <div className='importTextUnique'>
                                        <Important text={textImportant} paddingT='0px 30px' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ContainerService>
        </React.Fragment>
    );
}