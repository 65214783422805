import React, { createRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Input from "../../components/input/Input";
import ReCAPTCHA from "react-google-recaptcha";
import sha256 from "sha256";
import $ from "jquery";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from '../../components/loader/Loader';
import { isEmpty } from "lodash";
import { encryptDecrypt } from "../../utils/validations";
import { urlAPI, captchaKey } from "../../utils/config";
import Axios from 'axios';
import recoveryImage from "../../assets/img/cambio-de-contrasena.svg"
import * as CryptoJS from "crypto-js";


export default function PasswordRecovery(props) {
  const { step } = props.match.params;
  useEffect (() => {
    window.onpopstate = function(event) {
      setMessageObj({ show: false, message: '' });
    };
    if(step === 'restablecer'){
    document.getElementById('password').setAttribute('style','border-right:0px !important; border-top-right-radius:0px !important;border-bottom-right-radius:0px !important');
    document.getElementById('passwordComfirm').setAttribute('style','border-right:0px !important; border-top-right-radius:0px !important;border-bottom-right-radius:0px !important');
    $('.eyeB').attr('style','border-left:0px!important');
    }
  });

  const [form, setForm] = useState(defaultFormValue());
  const [messageObj, setMessageObj] = useState(defaultMessageValue());
  const [messageObjConfirm, setMessageObjConfirm] = useState(defaultMessageValue());
  const [fieldError, setFieldError] = useState(false);
  const [loaderShow, setLoaderShow] = useState(false);
  const recaptchaRef = createRef();
  const [btnDisabled,setbtnDisabled]=useState(true);
  function defaultFormValue() {
    return {
      document: "",
      captcha: "",
      code: "",
      id: "",
      password: "",
      passwordComfirm: "",
    };
  }

  function defaultMessageValue() {
    return {
      show: "",
      message: "",
    };
  }

  const onChange = (e, name) => {
    setForm({ ...form, [name]: e.target.value });
    setMessageObjConfirm({
      show: false,
      message: "",
    });
    if(name === "code"){
      if(e.target.value.length > 6){
        e.target.value = e.target.value.slice(0, e.target.maxLength)
      }
    }
    if (name === "password"){
      $("#span-password").text("Tu contraseña debe contener al menos 8 caracteres.");
      if (e.target.value.length < 8) {
        $("#span-password").addClass('d-none')
      }
      if(e.target.value.length > 0)
      {
        $('#barra').addClass('d-flex')
        $('#result').addClass('d-block')
        $('#barra').removeClass('d-none')
        checkStrength(e.target.value);
      }
      else{
        $('#barra').addClass('d-none')
        $('#result').addClass('d-none')
        $('#barra').removeClass('d-flex')
      }
    }

    if (name === "passwordComfirm" && e.target.value !== form.password){
      setMessageObjConfirm({
        show: true,
        message: "Tu contraseña no coincide",
      });
    }

    if (name === "passwordComfirm" || name === "password") {
      if(isEmpty(form.password) || isEmpty(form.passwordComfirm) || e.target.value.length <1){
        setbtnDisabled(true);
      }
      else{
        setbtnDisabled(false);
      }
    }

    if (name === "document"){
      if($.isNumeric(e.target.value))
      {
        setbtnDisabled(false);
        $('#document1').addClass('inputGreen');
        $('#document1').removeClass('inputRed');
        $('#document3').addClass('d-none');
        $('#document3').removeClass('d-block');
      }
      else{
        setbtnDisabled(true);
        $('#document1').addClass('inputRed');
        $('#document3').addClass('d-block');
        $('#document3').removeClass('d-none');
        $('#document1').removeClass('inputGreen');
      }
      if(e.target.value==="")
      {
        $('#document1').removeClass('inputRed');
        $('#document1').removeClass('inputGreen');
        $('#document3').removeClass('d-block');
        $('#document3').addClass('d-none');
      }
    }
    
    if (name === "code"){
      if (e.target.value.length > 5) {
        setbtnDisabled(false);
      } else {
        setbtnDisabled(true);
      }
    }
  };

  const handleRoute = (route) => {
    props.history.push(`/olvidaste-tu-contrasena/${route}`);
  };
  
  const handleRecovery = (e) => {
    onChangeCap(e);
    if (isEmpty(form.captcha)) {
      return;
    }
    else if (isEmpty(form.document)) {
      setMessageObj({ show: true });
      document.getElementById('capchatResetRecovery').click();
    }else {
      
      setLoaderShow(true);
      var key = CryptoJS.enc.Utf8.parse('7061737323313233');
      var iv = CryptoJS.enc.Utf8.parse('7061737323313233');
      var ident = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(form.document), key,
        {
          keySize: 256 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }).toString();
      let obj = {
        identificacion: ident,
        tokenCaptcha: form.captcha,
      };
      Axios.post(`${urlAPI}/Login/RecuperaContrasena`,JSON.stringify(obj),
      {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(succe=>{
        if(succe.data.codigo == 0){
          setLoaderShow(false);
          form.id = succe.data.data;
          handleRoute("codigo");
          recaptchaRef.current.execute();
        }else if(succe.data.codigo ===1){
          setLoaderShow(false);
          setMessageObj({
            show: true,
            message: "El numero de documento no coincide",
          });
        }else if(succe.data.codigo ===3){
          setLoaderShow(false);
          setMessageObj({
            show: true,
            message: "Se ha superado la cantidad de intentos de recuperación diarios",
          });
        }
      }).catch(error=>{
        setLoaderShow(false);
        //console.log(error);
      });
    }
  };

  const handleValidationCode = (e) => {
    onChangeCap(e);
    if (isEmpty(form.captcha)) {
      return;
    } 
    else if (isEmpty(form.code)) {
      setFieldError(true);
      document.getElementById('captchaResetValidationCode').click()
    }else {
      setLoaderShow(true);
      setFieldError(false);
      let obj = {
        code: form.code,
        tokenCaptcha: form.captcha,
        id: parseInt(form.id),
      };
      Axios.post(`${urlAPI}/Login/EnvioCodigo`, JSON.stringify(obj),{
        headers: {
          "Content-Type": "application/json",
      }}).then(succe=>{
        setLoaderShow(false);
        if (succe.data.mensaje.includes("captcha")) {
          document.getElementById('captchaResetValidationCode').click()
        } else if (succe.data.codigo === 1) {
          //console.log('data ', succe.data);
          setMessageObj({ show: true, message: 'Código inválido' });
          document.getElementById('code1').style.borderBottom = "1px solid #F90000"
        } else {
          setMessageObj({ show: false, message: '' });
          handleRoute("restablecer");
        }
      }).catch(error=>{
        //console.log(error);
      });
    }
  };

  const handleValidationPassword = (e) => {

    onChangeCap(e);

    let pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{7,15}/;

    if (isEmpty(form.password) || isEmpty(form.passwordComfirm)) {
      setMessageObj({
        show: true,
        message: "Debe diligenciar las contraseñas",
      });
    } else if (form.password !== form.passwordComfirm) {
      setMessageObj({
        show: true,
        message: "Tu contraseña no coincide",
      });
    } else if (
      !pattern.test(form.password) || form.password.length>16 ||
      !pattern.test(form.passwordComfirm) || form.passwordComfirm.length>16
    ) {
      // Verificacion de contraseñas con el patrón
      setMessageObj({
        show: true,
        message: ` La nueva contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula.
                   NO puede tener otros símbolos.
                   Ejemplo:
                   w3Unpocodet0d0`,
      });
    } else if (isEmpty(form.captcha)) {
      return;
    } else {
      setMessageObj({ show: true });
      setLoaderShow(true);
      let password = btoa(
        encryptDecrypt(
          sha256(form.password),
          form.captcha.substring(form.captcha.length - 10, form.captcha.length)
        )
      );
      let obj = {
        code: form.code,
        tokenCaptcha: form.captcha,
        id: parseInt(form.id),
        password,
      };
      Axios.post(`${urlAPI}/Login/CambiarContrasena`,JSON.stringify(obj),{headers: {
        "Content-Type": "application/json",
      }}).then(succ=>{
        setLoaderShow(false);
        if (succ.data.mensaje.includes("captcha")) {
          document.getElementById('captchaResetValidationPass').click();
        } else if (succ.data.codigo === 1) {
          setMessageObj({ show: true, message: succ.data.mensaje });
        } else {
          handleRoute("exito");
        }
      }).catch(error=>{
        //console.log(error);
      });
    }
  };
  const checkStrength = (password) => {
    var strength = 0;
    //If password contains both lower and uppercase characters, increase strength value.
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      strength += 1;

      $(".low-upper-case").addClass("text-success");
      $(".low-upper-case i").removeClass("fa-file-text").addClass("fa-check");
      $("#popover-password-top").addClass("hide");
    } else {
      $(".low-upper-case").removeClass("text-success");
      $(".low-upper-case i").addClass("fa-file-text").removeClass("fa-check");
      $("#popover-password-top").removeClass("hide");
    }

    //If it has numbers and characters, increase strength value.
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
      strength += 1;
      $(".one-number").addClass("text-success");
      $(".one-number i").removeClass("fa-file-text").addClass("fa-check");
      $("#popover-password-top").addClass("hide");
    } else {
      $(".one-number").removeClass("text-success");
      $(".one-number i").addClass("fa-file-text").removeClass("fa-check");
      $("#popover-password-top").removeClass("hide");
    }

    //If it has one special character, increase strength value.
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      strength += 1;
      $(".one-special-char").addClass("text-success");
      $(".one-special-char i").removeClass("fa-file-text").addClass("fa-check");
      $("#popover-password-top").addClass("hide");
    } else {
      $(".one-special-char").removeClass("text-success");
      $(".one-special-char i").addClass("fa-file-text").removeClass("fa-check");
      $("#popover-password-top").removeClass("hide");
    }

    if (password.length > 7) {
      strength += 1;
      $(".eight-character").addClass("text-success");
      $(".eight-character i").removeClass("fa-file-text").addClass("fa-check");
      $("#popover-password-top").addClass("hide");
    } else {
      $(".eight-character").removeClass("text-success");
      $(".eight-character i").addClass("fa-file-text").removeClass("fa-check");
      $("#popover-password-top").removeClass("hide");
    }

    // If value is less than 2

    if (strength < 2) {
      $("#result").removeClass();
      $("#password-strength").addClass("progress-bar bg-danger");

      $("#result").addClass("text-danger").text("Débil");
      $("#result").css('margin-left','2%')
      $("#password-strength").css("width", "10%");
    } else if (strength === 2) {
      $("#result").removeClass("text-danger");
      $("#password-strength").removeClass("progress-bar bg-danger");
      $("#password-strength").addClass("progress-bar bg-warning");
      $("#result").addClass("text-warning").text("Moderada");
      $("#result").css('margin-left','17%')
      $("#password-strength").css("width", "60%");
      return "Week";
    } else if (strength === 4) {
      $("#result").removeClass("text-warning");
      $("#result").addClass("strong");
      $("#password-strength").removeClass("progress-bar bg-warning");
      $("#password-strength").addClass("progress-bar bg-success");
      $("#result").addClass("text-success").text("Fuerte");
      $("#result").css('margin-left','25%')
      $("#password-strength").css("width", "100%");

      return "Strong";
    }
  };

  function onChangeCap(value) {
    form.captcha = value;
  }

  return (
    <div className="row justify-content-center divContent" style={{ background: "#f8f8f8", marginRight: "0px", marginLeft:"0px" }}>
      {loaderShow &&(
        <Loader show={loaderShow}/>
      )}
      <div 
        className="col-11 col-md-6 mt-5 mb-5" 
        style={{ maxWidth: "500px", marginLeft:"auto", marginRight:"auto" }}
      >
        <Card id="loginCard">
          <Card.Body>
            {step === undefined && (
              <Form className="p-3" onSubmit={(e)=>{e.preventDefault();}}>
                <h4 className="text-center mb-3 font-weight-normal" style={{fontFamily:"Next",color:'#323B4E',fontSize:'22',fontWeight:'400 !important', fontSize:'22px'}}>
                  ¿Olvidaste tu contraseña?
                </h4>
                <div className="subtitleBlack mt-4 mb-4 text-center">
                  <p style={{color:"#8E8E93",fontFamily:'Inter-regular', fontSize:'14px', fontWeight:'400',lineHeight:'22px'}}>
                    Ingresa tu número de documento. La información para
                    recuperar tu contraseña será enviada a tu número de celular
                    y correo electrónico registrado.
                  </p>
                </div>
                {messageObj.show && (
                    <div className="row warning-message subtitleBlack ml-1 mr-1">
                      <div className="col-2 mt-2">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="orangeBrandColor"
                        />
                      </div>
                      <div className="col-10 mt-2">
                        <p>{messageObj.message}</p>
                      </div>
                    </div>
                  )}
                <Input
                  label="Número de documento"
                  placeholder="Ingresa tu número de documento"
                  type="text"
                  error={messageObj.show}
                  errorText="Ingresa tu número de documento"
                  id="document"
                  name="document"
                  /*className="numeroDocumentoform"*/
                  onChangaInput={onChange}
                  autocomplete="off"
                />
                {/* <p class="d-none" id="document3" style={{color:'#FA5E5B',fontFamily:'OpenSans-bold',fontSize:'12px'}}>Ingresa un número válido</p> */}
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={captchaKey}
                  onChange={(e) => {handleRecovery(e);}}
                  className="d-none"
                />
                <button className="d-none" id="capchatResetRecovery" onClick={()=>{recaptchaRef.current.reset()}}></button>
                <div style={{padding: '0px 9px 0px 7px'}}>
                  <Button style={{fontFamily:"Next"}} variant="danger" type="button" className="mt-4 btnLogin" disabled={btnDisabled} block onClick={() => {recaptchaRef.current.execute();setbtnDisabled(true)}}>
                    ENVIAR
                  </Button>

                </div>
                <div className="text-center mt-5">
                  <a
                    className="hover blackBrandColor"
                    href="/inicia-sesion"
                    style={{color:"#585857",fontFamily:'Inter-regular',fontSize:'14px',fontWeight:'400', textDecoration:'none'}}
                  >
                    Regresar
                  </a>
                </div>
              </Form>
            )}
            {step === "codigo" && (
              <Form className="py-4 px-2" onSubmit={(e)=>{e.preventDefault()}}>
                <p className="text-center mb-3" style={{fontSize:"22px",fontFamily:"Next",color:'#323B4E', fontWeight:'400'}}>
                  Código de verificación
                </p>
                <div className="subtitleBlack mt-5 mb-4 mx-2 text-center">
                  <p style={{fontFamily:'Inter-regular',fontWeight:'400',fontSize:'14px',lineHeight:'20px',color:'#8E8E93',padding: '0px 10px', marginTop:'-15px'}}>
                  Por favor, ingresa el código que recibiste en tu número de celular o correo electrónico
                  </p>
                </div>

                <Input
                  label="Código de verificación"
                  placeholder="Ingresa el código"
                  type="text"
                  error={fieldError}
                  errorText="Ingresa el código"
                  id="code"
                  name="code"
                  onChangaInput={onChange}
                />
                {messageObj.show &&(
                    <span className="errorMessageForm">{messageObj.message}</span>
                  )}
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={captchaKey}
                  className="d-none"
                  onChange={(e) => handleValidationCode(e)}
                />
                <button id="captchaResetValidationCode" className="d-none" onClick={()=>{recaptchaRef.current.reset()}}></button>
                <Button type="submit" style={{width:"50%", margin:"auto",borderRadius:'25px'}} className="mt-4 mb-4 btnLogin verfBoton" block disabled={btnDisabled} onClick={() => {recaptchaRef.current.execute();setbtnDisabled(true)}}>
                  VERIFICAR
                </Button>
              </Form>
            )}
            {step === "restablecer" && (
              <Form
                className="p-4"
                onSubmit={(e) =>{e.preventDefault()}}
              >
                <h4 className="text-center mb-3 font-weight-normal" style={{fontFamily:"Next", fontSize:'24px',color:'#323B4E'}}>
                  Restablecer contraseña
                </h4>
                <div className="subtitleBlack text-center respOlv">
                  <p style={{fontFamily:'Inter-regular',fontWeight:'400',fontSize:'14px',lineHeight:'20px',color:'#8E8E93'}}>
                    Ingresa una nueva contraseña para recuperar el acceso a tu
                    cuenta.
                  </p>
                </div>

                <Input
                  label="Nueva contraseña"
                  placeholder="Ingresa tu contraseña"
                  type="password"
                  error={false}
                  errorText="Ingresa tu contraseña"
                  id="password"
                  name="password"
                  onChangaInput={onChange}
                  autocomplete="off"
                />
                <span style={{ fontSize: '12px', marginLeft:'7px', fontWeight: 'normal'}} id="span-password">Tu contraseña debe contener al menos 8 caracteres.</span>
                <div className="progress d-none mt-2" id="barra">
                    <div
                      id="password-strength"
                      className="progress-bar progress-bar-success"
                      role="progressbar"
                      aria-valuenow="40"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "0%" }}
                    ></div>
                </div>
                <p>
                  <span style={{ fontSize: 12 }} id="result"></span>
                </p>
                <Input
                  label="Repetir contraseña"
                  placeholder="Ingresa tu contraseña"
                  type="password"
                  error={messageObjConfirm.show}
                  errorText={messageObjConfirm.message}
                  id="passwordComfirm"
                  name="passwordComfirm"
                  onChangaInput={onChange}
                  autocomplete="off"
                />
                <p><span style={{ fontSize: '13px', fontFamily:'Inter-regular', marginLeft:'7px'}} id="span-passwordComfirm"></span></p>
                
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={captchaKey}
                  onChange={(e) => handleValidationPassword(e)}
                  className="d-none"
                />
                <button id="captchaResetValidationPass" className="d-none" onClick={()=>{recaptchaRef.current.reset()}}></button>
                <div style={{padding: '0px 9px 0px 7px'}}>
                  <Button style={{borderRadius:'25px', fontFamily:'Next', fontWeight:'600', fontSize:'16px',textTransform: 'uppercase'}}  variant="danger btnLogin " type="submit" className="mt-4 btnGuarOlv" block disabled={btnDisabled} onClick={()=>{recaptchaRef.current.execute();}}>
                    CAMBIAR CONTRASEÑA
                  </Button>
                </div>
              </Form>
            )}
            {step === "exito" && (
              <div className="p-4 pb-4" onSubmit={() => handleRoute("codigo")}>
                <h4 className="text-center mb-3 font-weight-normal" style={{fontFamily:'Next',fontSize:'24px',color:'#323B4E'}}>
                Restablecer contraseña
                </h4>
                <div className="text-center m-5">
                  <img
                    src={recoveryImage}
                    className="geenBrandColor"
                    alt="icon green brand color"
                  />
                </div>
                <div
                  className="subtitleBlack mt-4 text-center"
                  style={{marginBottom:"80px"}}
                >
                  <p style={{ fontFamily:'Inter-regular',fontWeight:'400', fontSize:'14px',color:'#666666',marginBottom: '5px'}}>
                    Tu contraseña se ha modificado correctamente.
                  </p>
                  <p >
                    <a href="/inicia-sesion" style={{ fontFamily:'Inter-regular',fontWeight:'600', fontSize:'14px',color:'#585857',textDecoration:'underline'}}>
                    Haz clic aquí para iniciar sesión
                    </a>{" "}
                  </p>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
        <div style={{height: '6rem',}}></div>
      </div>
    </div>
  );
}
