import React, { useEffect, useState }from "react";
import Tooltip from "react-bootstrap/Tooltip";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faChevronRight,
    faChevronLeft,
 } from "@fortawesome/free-solid-svg-icons";

export default function TooltipsHome(props){
    const { informationView, view, firstTime, closeTooltips} = props;
    const [ count, setCount ] = useState(0);
    const [info, setInfo] = useState(informationView.filter(x=>x.id === count));

    useEffect(()=>{
        if(count === 0){
                document.getElementById("tooltip-top").style.bottom='5px';
                document.getElementById("tooltip-top").style.left="85px";
            $("#tooltip-top").before('<style>.tooltipsHome::before{bottom:-14px;left:24px;}</style>');
        }else if (count === 1){
            document.getElementById("tooltip-top").style.bottom='35px';
                document.getElementById("tooltip-top").style.right="106px";
                document.getElementById("tooltip-top").style.left="auto";
            $("#tooltip-top").before('<style>.tooltipsHome::before{bottom:135px;left:24px;}</style>');

        }else if (count === 2){
            if(view === "1"){
                // $("#card-first").addClass('col-lg-8')
                // $("#card-sec").removeClass('col-lg-12').addClass("col-lg-4");
            }
            document.getElementById("tooltip-top").style.bottom="35px";
            document.getElementById("tooltip-top").style.right="-16%";
            $("#tooltip-top").before('<style>.tooltipsHome::before{bottom:135px;left:24px;}</style>');

        }        
    },[count]);

    const step = () =>{
        if(count < informationView.length){
            setInfo(informationView.filter(x=>x.id === count+1));
            setCount(count+1);
        }else if (count === 1){

        }
    }

    const back = () =>{
        if(count > 0 ){
                setInfo(informationView.filter(x=>x.id === count-1));
                setCount(count-1);
        }
    }

    const close = (id) =>{
        setInfo(informationView.filter(x=>x.id === id));
        closeTooltips();
    }

    return(
        <div className="row">
            {info.map((e,i)=>{
                return(
                    <>
                        <div  className="col-12">
                            <Tooltip id={`tooltip-top`} bsPrefix="tooltipsHome" className="flechaInicio" style={{margin: '0px'}}>
                                <div className="text-right mb-3">
                                    <FontAwesomeIcon icon={faTimes} className="closee manitoCol" onClick={()=>{close(4)}}/>
                                </div>
                                <p className="paragraph">{e.text}</p>
                                <div className="row">
                                    <div className="col-lg-6 text-left pl-0" style={{fontFamily: 'Inter-regular'}}>
                                    {e.step}
                                    </div>
                                    <div className="col-lg-6 text-right pr-0">
                                        {e.arrowLeft === "S"?(
                                            <FontAwesomeIcon icon={faChevronLeft} className="closee manitoCol" style={{marginRight: "20px"}} onClick={()=>{back()}}/>
                                        ):(<></>)}
                                        {e.arrowRight === "S"?(
                                        <FontAwesomeIcon icon={faChevronRight} className="closee manitoCol" onClick={()=>{step()}}/>
                                        ):(<></>)}
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                    </>
                );
            })}
        </div>
    );
}