import React, { useState,useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {Container, Row, Col} from 'react-bootstrap';
import cerrarModal from "../../assets/img/cardif-boton-cerrar.svg";
import NetworkingEvents from "../../views/services-ITT/networking/events/NetworkingEvents";
import $ from 'jquery'

export default function(props){
    const { title, text }=props;
    const [show, setShow] = useState(props.show);
    const handleShow = () => {
        setShow(false)
        localStorage.setItem('modal','true')
    }
    const [step, setStep] = useState(undefined);

   useEffect(()=>{
       //console.log($('.modal-content').addClass('modalNetw'));
   },[])

    return(
        <>
          <Modal 
          show={show} 
          aria-labelledby="contained-modal-title-vcenter "
          size='lg'
          centered
          >
            <Modal.Header>
             <div style={{ height: "0px"}}>
              <button
                id=""
                type="button"
                className="swal2-close-modal bntModalNet"
                onClick={handleShow}
              ><p>×</p></button>
             </div>
            </Modal.Header>
            <Modal.Body className="modal-networking" style={{marginTop:"47px"}}>
                <div className="text-center"  style={{paddingBottom:"2%"}}>
                <p className="title-CC">
                    Eventos Cámara de Comercio
                </p>
                </div>
                <div className="text"  style={{paddingBottom:"5%"}}>
                <p className="text-CC">
                La información contenida en este sitio web es de carácter meramente informativo y corresponde a las agendas de eventos públicos organizados por terceros. CARDIF COLOMBIA SEGUROS GENERALES S.A. no otorga ninguna garantía de que la información proporcionada en este sitio web sea correcta y esté completa y actualizada, así como tampoco interviene en la organización y desarrollo de los eventos publicados.
                </p> 
                </div>
                <div className="text-center" style={{paddingBottom:"9%"}}>
                    <button className="btnMainBluePsico btn-acep" onClick={handleShow}> 
                        ACEPTAR
                    </button>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
}