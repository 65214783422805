import React, { Component } from 'react';
import ContainerService from '../../components/container-service/ContainerService';
import ServiceInfo from '../../components/service-info/ServiceInfo';
import logo1 from '../../assets/img/imgService/img-card-girl.png';
import logo from '../../assets/img/imgService/img-card-man.png';

export default function CurriculumHome() {
    const linkbread = [{
        to: "/auth/inicio",
        tittle: "Inicio > "
    },
    {
        to: "/s/desempleo/mejorar-hoja-de-vida",
        tittle: "Mejorar mi hoja de vida > ",
    },
    {
        to: "/s/desempleo/mejorar-hoja-de-vida",
        tittle: "Servicios",
    }];
    return (
        <React.Fragment>
            <ContainerService tittle="Mejora tu hoja de vida " styleTittle={{ fontSize: "34px", lineHeight: "56px", fontFamily: 'DTL-ArgoT-regular' }} linkBread={linkbread}>
                
            <ServiceInfo
                    imageUrl={logo}
                    tittle='¿Cómo diseñar una hoja de vida?'
                    textButton="DISÉÑALA"
                    subTittle='Descubre lo fácil que es crear y personalizar tu hoja de vida, diligencia tus datos y diséñala según tu estilo.'
                    linkButton='/s/desempleo/mejorar-hoja-de-vida/crear-hv/datos-personales'
                    
                    imageUrl1={logo1}
                    tittle1='¿Tienes una hoja de vida y la quieres mejorar?'
                    textButton1="MEJÓRALA"
                    subTittle1='Compártenos tu hoja de vida y un experto en selección te ayudará a potencializar lo mejor de tu perfil.'
                    linkButton1='/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda-eleccion-hdv'

                    
                    subTittleEco='Selecciona la opción que más se ajuste a lo que necesitas: ya sea diseñar una hoja desde cero o solicitar que un experto revise una ya existente.'
                >

                </ServiceInfo>
            </ContainerService>
        </React.Fragment>
    );
}

