import React from 'react';
import Card from "react-bootstrap/Card";

export default function CardEvent({children,infoCard,setShowCard,showButton,bsPrefix,classN,}) {
    return(
        <Card className={classN}>
            <Card>
                <Card.Img variant="top" src={infoCard.rutaImagen} bsPrefix={bsPrefix} />
                <div className="ajusteImagenNet">{infoCard.conferencista}</div>
                {showButton &&(<button className="btnDetail" onClick={()=>{setShowCard()}}>+</button>)}
            </Card>
            <Card.Body>
                <Card.Text className="mobileCursosFinan">
                    {children}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}