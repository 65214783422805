import React, { useEffect, useState } from "react";
import ContainerService from "../../../components/container-service/ContainerService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { urlAPI } from "../../../utils/config";
import {getUser, isActive, getToken } from "../../../utils/validations";
import Swal from 'sweetalert2';
import Sheduling from "../../../components/sheduling/Sheduling";
import { getSchedules } from '../../../utils/validations';
import {  useHistory } from "react-router-dom";
import $ from 'jquery';
export default function BrandingDigital(props){

    const [jsonEmpresa, setJsonEmpresa] = useState(jsonEmpresaTemplate);
    const [empresa, setEmpresa] = useState(empresaTemplate);
    const [checkIdentidad, setCheckIdentidad] = useState(checkIdentidadTemplate);
    const [sector, setSector] = useState([]);
    const [valid, setValid] = useState(Boolean);
    const [step, setStep] = useState('info');
    const [nameFile, setNameFile] = useState();
    const [imageSrc, setImageSrc] = useState();
    const [errorName, setErrorName] = useState(false);
    const [errorMail, setErrorMail] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [requiredName, setRequiredName] = useState(false);
    const [requiredSector, setRequiredSector] = useState(false);
    const [requiredMail, setRequiredMail] = useState(false);
    const [requiredPhone, setRequiredPhone] = useState(false);
    const [labelFile, setLabelFile] = useState(false);

    function jsonEmpresaTemplate() {
        return {
            idUsuario: getUser().idUsuario,
            idSector: 0,
            nombreEmpresa: '',
            descripcionEmpresa: '',
            mailEmpresarial: '',
            paginaWeb: '',
            identidadDigital : '{"marcaCheck":false,"logoCheck":false,"paginaWebCheck":false,"portafolioCheck":false,"redesSocialesCheck":false}',
            nombreSector: '',
        }
    }
    function empresaTemplate(){
        return {
            idEmpresa: 0,
            idLogo: '',
            logoEmpresa: '',
            fechaHoraCreacion: new Date(),
            vigente: false,
            nombreArchivo: 'Selecciona el archivo',
            rutaImagen: '',
            
        }
    }
    function checkIdentidadTemplate(){
        return {
            marcaCheck:false,
            logoCheck:false,
            portafolioCheck:false,
            paginaWebCheck:false,
            redesSocialesCheck:false,
        }
    }

    let empresaFull = [];



    let sectorAux=[];
    useEffect(() => {
        Axios.get(`${urlAPI}/Hoja/tipos/${3}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setSector(succ.data.data);
            sectorAux = succ.data.data;
        }).catch(error => {
            //console.log(error);
        });


    }, []);


  const handleInputChange = (e) => {
    //console.log(e.target.files);
    let nameFileImg = e.target.files[0].name
    setEmpresa(empresa =>({ ...empresa, nombreArchivo: nameFileImg }));
    setLabelFile(true);
    setNameFile(e.target.value.split('\\'))
    let file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    let reader = new FileReader();
    reader.onload = _handleReaderLoaded.bind(e);
    reader.readAsDataURL(file);
    }

    const _handleReaderLoaded = (e) => {
    let reader = e.target;
    let logoID = reader.result.split("base64,")[0] + "base64,";
    let empresaLogo = reader.result.split("base64,")[1];


    setEmpresa(empresa =>({ ...empresa, rutaImagen: reader.result }));
    setEmpresa(empresa =>({ ...empresa, idLogo: logoID }));
    setEmpresa(empresa =>({ ...empresa, logoEmpresa: empresaLogo }));

  }


  const sectorInputChange = (e) =>{
    let nameSector = $('select[name="idSector"] option:selected').text();
    setJsonEmpresa(jsonEmpresa =>({ ...jsonEmpresa, nombreSector: nameSector }));

  }

  const cleanRequired = () =>{
      setRequiredPhone(false);
      setRequiredName(false);
      setRequiredMail(false);
      setRequiredSector(false);
  }

  const checkFull = (e) => {
    setCheckIdentidad(checkIdentidad =>({ ...checkIdentidad, marcaCheck: $('#checkbox-marca').is(':checked') }));
    setCheckIdentidad(checkIdentidad =>({ ...checkIdentidad, logoCheck: $('#checkbox-logo').is(':checked') }));
    setCheckIdentidad(checkIdentidad =>({ ...checkIdentidad, portafolioCheck: $('#checkbox-portafolio').is(':checked') }));
    setCheckIdentidad(checkIdentidad =>({ ...checkIdentidad, paginaWebCheck: $('#checkbox-web').is(':checked') }));
    setCheckIdentidad(checkIdentidad =>({ ...checkIdentidad, redesSocialesCheck: $('#checkbox-redes').is(':checked') }));
  }

    const fullFormContact = (e) => {
        setJsonEmpresa({ ...jsonEmpresa, [e.target.name]: e.target.value });
        setJsonEmpresa(jsonEmpresa =>({ ...jsonEmpresa, identidadDigital: '{"marcaCheck":'+checkIdentidad.marcaCheck+',"logoCheck":'+checkIdentidad.logoCheck+',"paginaWebCheck":'+checkIdentidad.paginaWebCheck+',"portafolioCheck":'+checkIdentidad.portafolioCheck+',"redesSocialesCheck":'+checkIdentidad.redesSocialesCheck+'}' }));
    }

    const validation = (e,date) =>{
        if(date === 'name'){
            if(e.target.value === '' || e.target.value === null || e.target.value === NaN){
                setErrorName(true);
            }
            else{
                setErrorName(false);
            }
        }
        else if(date === 'mail'){
            let patternMail = /^[a-zA-Z0-9_.-]+@[^ÑñáéíóúÁÉÍÓÚ]+.[com,org,co,net,es,gov]/
            if(!patternMail.test(e.target.value) || e.target.value === '' || e.target.value === null || e.target.value === NaN){
                setErrorMail(true);
            }
            else{
                setErrorMail(false);
            }
        }
        else if(date === 'phone'){
            let patternPhone = /^3+[0-9]{9}$/;
            if(!patternPhone.test(e.target.value) || e.target.value === '' || e.target.value === null || e.target.value === NaN){
                setErrorPhone(true);
            }
            else{
                setErrorPhone(false);
            }
        }
    }

    const getDataForm = () =>{
        empresaFull = Object.assign(jsonEmpresa,empresa);
    }

    const save = ()=>{

        if(errorName || errorMail || errorPhone){
            //console.log('ERROR EN EL FORMULARIO');
        }
        else if(jsonEmpresa.idSector === 0 || jsonEmpresa.nombreEmpresa === '' || jsonEmpresa.mailEmpresarial === '' ){
            jsonEmpresa.idSector === 0 ? setRequiredSector(true): setRequiredSector(false);
            jsonEmpresa.nombreEmpresa === '' ? setRequiredName(true): setRequiredName(false);
            jsonEmpresa.mailEmpresarial === '' ? setRequiredMail(true): setRequiredMail(false);
        }
        else{
            getDataForm();
            let cadena = JSON.stringify(empresaFull);
    
            Axios.post(`${urlAPI}/itt/RegistrarBranding` ,JSON.stringify(empresaFull),  { headers: { "Content-Type": "application/json", Authorization: getToken() }}
            ).then(succ=>
            {
                setStep('Agenda');
            }).catch(error => 
            {
                //console.log('No funciona');
            });
        }

    }

    const linkbread=[{
        to:"/auth/inicio",
        tittle:"Inicio > "
    }, 
    {
        to:"/ITT/auth/branding-digital/agenda",
        tittle:"Branding digital > Consultoría "
    },
    // {
    //     to: step === 'form'?"/ITT/auth/branding-digital/agenda/1":'',
    //     tittle:step === 'form'?"Formulario > ":''
    // },
    // {
    //     to:step === 'Agenda'?"/ITT/auth/branding-digital/agenda/2":'',
    //     tittle: step === 'Agenda'?"Agenda":''
    // }
    ];

     useEffect(()=>{
        let sheduling;
        let res = new Promise((resolve)=>{
            sheduling= getSchedules([{value:"2043"}]);
            resolve(sheduling);
        }).then((res)=>{
            setStep(res?"Agenda":"info");
        });
     },[]);

     const history = useHistory();
     useEffect(()=>{
        window.scroll(0, 0);
         if(window.location.pathname === "/ITT/auth/branding-digital/agenda"){
            setStep('info');
         }
         else if(window.location.pathname === "/ITT/auth/branding-digital/agenda/1"){
            setStep('form');
         }
     },[history.location.pathname]);

     useEffect(()=>{
         if(step === 'info'){
            props.history.push("/ITT/auth/branding-digital/agenda");
         }
         else if(step === 'form'){
             props.history.push("/ITT/auth/branding-digital/agenda/1");
         }
     },[step]);

    const infoSheduling = {
        paddingSheduling: 'contenerPadding',
        typeSheduling: 2043,
        classTitleSheduling: 'fechahoraTitulo',
        classVolver: 'btnBackGrey',
        typeTextSheduling:<h5 className="typeShedulingText">Asesoría Branding digital  </h5>,
        module:'Branding',
        tittleSheduling:'',
        paragraph:'Para prestarte un servicio adecuado por favor confirma los siguientes datos:',
        alertCancelTitle:'Cancelación de agenda',
        alertCancelText:'¿Estás seguro de cancelar tu asesoría?',
        tooltipsClass:'tooltipShedulingEco',
        messageSuccess:{
          module:'Seguridad',
          variant:'success',
          checkText:'Confirmación de consultoría éxitosa',
          alertText:'Recibirás una notificación via email y SMS confirmando tu cita',
          class:'alert alertSuccessEcosistemas',
        },
        messageCancel:{
          variant:'success',
          checkText:'Tu consultoría ha sido cancelada con éxito',
          alertText:'Si estás interesado en programar una nueva consultoría en Branding Digital, haz clic aquí.',
          class:'alert alertEcoWarning',
          textButton: "AGENDAR NUEVA CONSULTORÍA"
        }
    };

    return(
        <React.Fragment>
            <ContainerService tittle="Branding digital" classLine="tittleService brDigital" styleTittle={{fontSize:"34px",fontFamily:'DTL-ArgoT-regular'}} linkBread={linkbread}>
                {step === 'info' &&(
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12" id="resp-tittle" style={{padding:'50px 0px 30px 20px', fontFamily:'Inter-regular'}}>
                                <div className="pb-4 colorLetters" >
                                    <p className="mb-4">
                                        ¿Tienes una idea de negocio o marca pero no sabes cómo impulsarla?
                                    </p>
                                    <p className="mb-4">
                                        Tranquilo! gracias a tu seguro, programa una consultoría en branding digital, aquí. Haz clic y amplía la información relacionada con tu negocio.
                                    </p>
                                    <div className="text-center mt-5">
                                        <button className="btnMainBlack mb-4 resp-agendar" id="btn-resp" style={{width:"300px", boxShadow: '0px 0px 2px #00000080 '}} onClick={()=>setStep('form')} >
                                            <span className="mr-2" style={{textTransform:'uppercase', fontSize:'20px'}} >Agendar consultoría</span>
                                        </button>
                                    </div>
                                    <p className="mb-4" style={{fontSize:"14px"}}>
                                        <span style={{fontWeight:"bold"}}>Importante: </span> La consultoría es prestada por Doctor Marketing, empresa especializada en estrategias digitales.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 'form' &&(
                    <div className="container-fluid">
                    <div className="row">
                        <div className="col-12" id="resp-tittle" style={{padding:'50px 0px 30px 20px', fontFamily:'Inter-regular'}}>
                            <div className="pb-4 colorLetters row">
                                <div className="col-12">
                                    <p className="re-text"> 
                                    Para tener una consultoría más efectiva y poder hacer parte de nuestra comunidad de emprendores, cuéntanos acerca de tu negocio:
                                    </p>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mt-2">
                                    <label className="labelPerfil">Nombre de la empresa</label>
                                    <input type="text" className={errorName == true || requiredName == true ?"form-control iputControlHvRed":"form-control iputControlHv"} name="nombreEmpresa" placeholder="Ingresa el nombre de tu empresa"
                                    onChange={(e) => { fullFormContact(e); validation(e,'name');}} onKeyDown={() => cleanRequired()} value={jsonEmpresa.nombreEmpresa} />
                                    <div className="invalid-feedback" style={{ display: errorName == true ? 'block' : 'none' }}>
                                            <p style={{ color: "#ff0000" }}>Nombre invalido</p>
                                    </div>
                                    <div className="invalid-feedback" style={{ display: requiredName == true ? 'block' : 'none' }}>
                                            <p style={{ color: "#ff0000" }}>Campo obligatorio</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mt-2">
                                    <label className="labelPerfil">Sector de la empresa</label> 
                                    <div  style={{position:"relative"}}>
                                        <select name="idSector"className={requiredSector === true ?"form-control btblock iputControlHvRed iconoArrow":"form-control btblock iputControlHv iconoArrow"} id="dicSectorEmpresa" placeholder="Selecciona un sector" 
                                         onChange={(e) => { fullFormContact(e); sectorInputChange(e); validation(e,'sector')}} onKeyDown={() => cleanRequired()}
                                            value={jsonEmpresa.idSector}>
                                            <option value={0} >
                                                Selecciona el sector de la empresa
                                            </option>
                                                {
                                                    sector.map(data => {
                                                        return (
                                                            <option key={data.id} value={data.id}>
                                                                {data.text}
                                                            </option>
                                                        )
                                                    })
                                                }
                                        </select>
                                        <div className="invalid-feedback" style={{ display: requiredSector === true ? 'block' : 'none' }}>
                                            <p style={{ color: "#ff0000" }}>Seleccione un sector</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mt-2 mb-3" style={{paddingTop:"2%"}}>
                                    <label className="labelPerfil">Correo electrónico de tu empresa</label>
                                    <input type="text" className={requiredMail === true || errorMail === true ?"form-control iputControlHvRed":"form-control iputControlHv"} name="mailEmpresarial" placeholder="Ingresa el correo electrónico"
                                    onChange={(e) => { fullFormContact(e); validation(e,'mail') }} onKeyDown={() => cleanRequired()} value={jsonEmpresa.mailEmpresarial}/>
                                    <div className="invalid-feedback" style={{ display: errorMail == true ? 'block' : 'none' }}>
                                        <p style={{ color: "#ff0000" }}>Correo invalido</p>
                                    </div>
                                    <div className="invalid-feedback" style={{ display: requiredMail == true ? 'block' : 'none' }}>
                                        <p style={{ color: "#ff0000" }}>Campo obligatorio</p>
                                    </div>
                                </div>
                                <div className="col-12 my-4 p-3">
                                    <p style={{color:"#58595B",fontFamily:"OpenSans"}}>
                                        En tu emprendimiento, actualmente cuentas con:
                                    </p>
                                </div>
                                <div style={{color:"#777777", padding:"0%"}} className="col-12 mb-3 checkGrid row pl-3">
                                    <div className="chkBr" style={{width:'11%'}}>
                                        <input type="checkbox" id="checkbox-marca" className="checkbox-terminos mr-2 mobileMargin " name="marcaCheck" 
                                        onChange={(e) => { checkFull(e) }} value={checkIdentidad.marcaCheck}/>
                                        <label className="mr-1 mobileMarca mobileCheck" for="checkbox-marca">Marca</label>
                                    </div>
                                    <div className="chkBr" style={{width:'11%'}}>
                                        <input type="checkbox" id="checkbox-logo" className="checkbox-terminos mr-2 mobileMargin" name="logoCheck"
                                        onChange={(e) => { checkFull(e) }} value={checkIdentidad.logoCheck} />
                                        <label className="mr-3 mobileLogo mobileCheck" for="checkbox-logo">Logo</label>
                                    </div>
                                    <div className="chkBr" style={{width:'14%'}}>
                                        <input type="checkbox" id="checkbox-portafolio" className="checkbox-terminos mr-2 mobileMargin" name="portafolioCheck"
                                        onChange={(e) => { checkFull(e) }} value={checkIdentidad.portafolioCheck} />
                                        <label className="mr-1 mobilePortafolio mobileCheck" for="checkbox-portafolio">Portafolio</label>
                                    </div>
                                    <div className="chkBr" style={{width:'16%'}}>
                                        <input type="checkbox" id="checkbox-web" className="checkbox-terminos mr-2 mobileMargin" name="paginaWebCheck" 
                                        onChange={(e) => { checkFull(e) }} value={checkIdentidad.paginaWebCheck}/>
                                        <label className="mr-1 mobileWeb mobileCheck" for="checkbox-web">Página web</label>
                                    </div>
                                    <div className="chkBr" id="chkBr1" style={{width:'25%'}}>
                                        <input type="checkbox" id="checkbox-redes" className="checkbox-terminos mr-2 mobileMargin" name="redesSocialesCheck" 
                                        onChange={(e) => { checkFull(e) }} value={checkIdentidad.redesSocialesCheck}/>
                                        <label className="mr-1 mobileRedes mobileCheck" for="checkbox-redes">Redes sociales</label>
                                    </div>
                                </div>                              
                                <div className="col-md-6 col-xs-12 ">
                                    <div style={{marginTop:"3px"}} className="form-group whiteSpace">
                                        <label for="formGroupExampleInput" className="labelPerfil">
                                            ¿Quieres compartirnos el logo de tu empresa? <span className="labelPerfil" style={{fontSize:"14px"}}>(opcional)</span>
                                        </label>
                                    </div>
                                    <div className="custom-file form-group" style={{marginTop:'-10px'}}>
                                        <input type="file" accept="image/*" className="custom-file-input  form-control d-none" id="inputPhoto" lang="es" name="file"
                                        style={{marginTop:"0px"}}  onChange={(e)=>{handleInputChange(e)}}/>
                                        <input type="button" className=" form-control iputControlHv" onClick={(e)=>{document.getElementById('inputPhoto').click()}} style={{ lineHeight:"2",paddingTop:"12px",border: 'none', textAlign:'left' }} for="customFileLang" value={empresa.nombreArchivo} />
                                        {/* <label className="custom-file-label  form-control spaceInput" style={{ display: labelFile == true ? 'block' : 'none', lineHeight:"2",paddingTop:"12px",border: 'none' }} for="customFileLang">{empresa.nombreArchivo}</label> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mt-2">
                                    <label className="labelPerfil">Página web de tu empresa</label>
                                    <input type="text" className="form-control btblock iputControlHv" name="paginaWeb" placeholder="Ingresa la dirección del sitio web"
                                    onChange={(e) => { fullFormContact(e) }} value={jsonEmpresa.paginaWeb}/>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12 mt-5">
                                    <label className="labelPerfil mb-2" style={{paddingLeft:"9px"}}>¿Qué servicios o productos ofreces?</label>
                                   
                                    <textarea className="btblock textTarea borderArea px-2 py-3 w-100" name="descripcionEmpresa" placeholder="Haz una breve descripción..."
                                    onChange={(e) => { fullFormContact(e) }} value={jsonEmpresa.descripcionEmpresa} style={{height:'123px'}}></textarea>
                                   
                                </div>
                                    <button className="btnMainBlack mt-5 mb-3" id="btn-resp1" onClick={()=>{save()}} style={{margin:'auto',textTransform:'uppercase',width:'288px', fontSize:"20px"}}>
                                        Continuar
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
                )}
                {step === 'Agenda' &&(
                    <Sheduling key={1} type="2043" module="Branding" propsP={props} infoSheduling={infoSheduling}/>
                )}
            </ContainerService>
        </React.Fragment>
    );
}