import React, { useEffect } from "react"
import Iframe from "react-iframe";
import { useLocation } from 'react-router-dom';
import ContainerService from "../../components/container-service/ContainerService";
import "./VideoCall.css";
import { isActive } from "../../utils/validations";
import { useHistory } from "react-router-dom";	

const VideoCall = (props) => {
  
  const location = useLocation();
  //console.log(props);//console.log(location)
  //console.log(props.location.state)
  const history = useHistory();
  let cont = 0;
  useEffect(() => {    
    if (!isActive()) {
      history.push("/inicio-sesion");
    }console.log(props)
  },[]);
  useEffect(() => {  
    setInterval(() => {document.body.click()},1000);
  });
  
  return (
    <ContainerService classPadding="paddingsVideo" classLine="tittleService" tittleSize="34px" styleTittle={{fontSize:"34px"}} linkBread="" Background={"linear-gradient(90deg, #0040A8 74%,#026ED3 )"}>
      <>
      <div className="row px-0">
        <div className="col-12">
          <div className="videoCallContainer">
            {props.location.state ? (
                <Iframe
                    url={props.location.state}
                    id="myframe"
                    allow="camera; microphone"
                    className="iframe-videollamada"
                    display="block"
                    position="relative"
                    scrolling="no"
                />
            ):(
                <div className="containerMessageVideo">
                  <p className="text-center col-lg-12 messageNoCall">¡ Genera tu agendamiento para disfrutar de una consultoria online !</p>
                </div>
            )}
          </div>
        </div>
        
      </div> 
      <div className="row">
        <div className="col-12 text-center text-md-left">
          <button className="btnGuardar mt-0 mb-4" onClick={() => { history.push("/auth/inicio") }}>
              Volver al Inicio
          </button>
        </div> 
      </div>   
      </>  
    </ContainerService>
  )
};

export default VideoCall;
