import Axios from 'axios'
import { urlAPI } from '../utils/config'

export const useAxios = () => {
    const callBack = async(metodo, endpoint, body, aut) => {
       let res = await Axios({
            method: metodo,
            url: urlAPI + endpoint,
            headers: { 
              "Content-Type": "application/json",
              Authorization: !!aut?"Bearer " + aut:"",
             },
            data: metodo.toLowerCase() !== 'get'&&JSON.stringify(body)
          }).then((succ)=>{
            return succ.data
          }).catch((err)=>{
            return err.response
          })
        return res
    }
  return {
    callBack
  }
}
