import React from 'react';
import Modal from "react-bootstrap/Modal";
import icon from "./../../assets/img/bancolombia-ico-reloj.svg";

export default function ModalInactivity(props){
    const { showM, clickClose } = props;

    return (
        <>
        <Modal
        show={showM}
        aria-labelledby="contained-modal-title-vcenter"
        size="md"
        centered
        >
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                        <img
                        style={{width: "42px", height:"42px", color:"red",verticalAlign:"middle" }}
                        src={icon}
                        />
                        <h5 className="modal-title text-center" style={{marginTop: "4%",fontFamily:'Inter-bold',color:'#333333'}}>
                            Tu sesión ha expirado
                        </h5>
                        <p className="text-center" style={{marginTop: "3%", fontFamily:"Helvetica", color:'#808285'}}>
                            Hemos cerrado tu sesión por inactividad.
                        </p>
                        <div className="text-center">
                            <button className="btn mb-4"
                                    style={{
                                    background: "#323B4E",
                                    border: "1px solid #323B4E",
                                    borderRadius: '4px',
                                    color: "#FFFFFF",
                                    fontWeight: 600,
                                    width: "185px",
                                    height: "45px",
                                    }} onClick={()=>{clickClose()}}> 
                                Aceptar
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
}
