import React, { useState, useEffect } from "react";
import ContainerService from '../../../components/container-service/ContainerService';
import { getUser, isActive, getToken } from '../../../utils/validations';
import Sheduling from "../../../components/sheduling/Sheduling";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
import { urlAPI } from "../../../utils/config";
import Swal from "sweetalert2";
import $ from 'jquery';
import FontAwesome from "react-fontawesome";
import PDF from "../../../assets/img/hoja-vida/av-villas-ico-pdf.svg"
import ck from "../../../assets/img/hoja-vida/ico-check-green-001.svg"
import { faChevronLeft, faChevronRight, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../components/loader/Loader";

export default function CurriculumScheduling(props) {
    function CurriculumFile() {
        return {
            idUsuario: getUser().idUsuario,
            imagenBase64: '',
            tipoArchivo: '',
            idImagen: '',
            formatoImg: '',
        }

    }
    const [step, setStep] = useState(1);
    const [imgRtaDB, setimgRtaDB] = useState(CurriculumFile);
    const [imgRtaDB1, setimgRtaDB1] = useState(Boolean)

    const linkbread = [{
        to: "/auth/inicio",
        tittle: "Inicio > "
    },
    {
        to: "/s/desempleo/mejorar-hoja-de-vida",
        tittle: "Mejorar mi hoja de vida > ",
    },
    {
        to: "/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda-eleccion-hdv",
        tittle: "Mejorar >",
    },
    {
        tittle:step === 2 ?"Agenda":'',
    }];

    const history = useHistory();
    useEffect(() => {
        if (window.location.pathname === "/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda-eleccion-hdv") {
            setStep(1);
            $("#ctnAgenda").prop('disabled', true);
        }
        setbtnloader(0)
        // getPhotoCurriculum();
    }, [history.location.pathname]);

    const infoSheduling = {
        paddingSheduling: 'contenerPadding',
        typeSheduling: 2024,
        classTitleSheduling: 'fechahoraTitulo',
        classVolver: 'btnBackGrey',
        typeTextSheduling: <h5 className="typeShedulingText">Consultoría mejora de hoja de vida</h5>,
        module: 'HojaVida-1',
        tittleSheduling: 'Mejora y potencializa tu hoja de vida.',
        paragraph: 'Para prestarte un servicio adecuado por favor confirma los siguientes datos:',
        alertCancelTitle: 'Cancela tu asesoría',
        alertCancelText: '¿Estás seguro de cancelar tu consultoría?',
        tooltipsClass: 'tooltipShedulingEco',
        messageSuccess: {
            module: 'Seguridad',
            variant: 'success',
            checkText: 'Confirmación de consultoría éxitosa',
            alertText: 'La revisión de tu hoja de vida se agendó exitosamente',
            class: 'alert alertSuccessEcosistemas',
        },
        messageCancel: {
            module: "Desempleo",
            variant: 'success',
            checkText: 'Tu consultoría ha sido cancelada con éxito',
            alertText: 'Si deseas programar una nueva consultoría, haz clic a continuación.',
            class: 'alert alertDesempleo',
            textButton: "AGENDAR REVISIÓN"
        }
    };
    const pdfDoc = {
        opacity: 0,
        position: 'absolute',
        zIndex: 1,
        width: '25%;',
        height: '50px',
        cursor: 'pointer',
        top: '0px',
        width: '200px'

    };
    const [btnloader, setbtnloader] = useState(0);
    const handleFormContact1 = (e) => {

        let estado;
        estado = $('#chkHVC').is(':checked');
        if (estado) {
            setLoaderShow(true)
            if (loaderShow) {
                return (
                    <Loader show={loaderShow} />
                )
            }
            document.getElementById("pdfSubido").classList.add('d-none');
            Axios.get(`${urlAPI}/agendamientos/verificarHV`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getToken(),
                }
            }).then(succ => {
                setLoaderShow(false)
                if (succ.data.codigo == 1) {
                    Swal.fire({
                        title: 'Ops!',
                        text: 'No hay una hoja de vida creada',
                        type: 'error',
                        customClass:'hvnshcCurr',
                        confirmButtonText: 'Aceptar'
                    });
                    $("#chkHVC").prop('checked', false);
                    $("#ctnAgenda").prop('disabled', true);
                    handleFormContact1()
                    if (btnloader === 1) {
                    } else {
                        setbtnloader(0);
                        document.getElementById("pdfSubido").classList.remove('d-none');
                    }
                }
                if (succ.data.codigo === 0) {
                    document.getElementById("pdfSubido").classList.add('d-none');
                    let agen = document.getElementById('ctnAgenda')
                    agen.disabled = false
                }
            }).catch(error => {
                //console.log(error);
            });
        } if (btnloader === 0) {
            document.getElementById("pdfSubido").classList.remove('d-none');
            $("#ctnAgenda").prop('disabled', true);
        }
    }
    const [loaderShow, setLoaderShow] = useState(false);

    const getTypeCulum = () => {
        let estado;
        estado = $('#chkHVC').is(':checked');
        setLoaderShow(true)
        if (loaderShow) {
            return (
                <Loader show={loaderShow} />
            )
        }
        Axios.get(`${urlAPI}/agendamientos/verificarHV`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            //console.log(succ);
            if ((succ.data.codigo != 1 && estado) || btnloader === 1) {
                setLoaderShow(false)
                setStep(2)
            } if ((succ.data.codigo == 1 || !estado) && btnloader === 0) {
                setLoaderShow(false)
                Swal.fire({
                    title: 'Ops!',
                    text: 'Debe diligenciar alguna de las opciones anteriores.',
                    type: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }

        }).catch(error => {
            //console.log(error);
        });
    }

    const savePhotoCurriculum = () => {
        Axios.post(`${urlAPI}/Hoja/subirArchivo`, JSON.stringify(imgRtaDB), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken()
            }
        }).then(succ => {
            $("#chkHVC").attr("disabled", true);

            setbtnloader(1);
            document.getElementById('pdfSubido').classList.add('d-none');
        }).catch(error => {
            //console.log(error);
        });
    }
    const [nameFilePDF, setnameFilePDF] = useState('')
    const handleInputChange = (e) => {
        let nameFileImg = e.target.files[0].type
        if (nameFileImg.split('/')[1] === 'pdf' || nameFileImg.split('/')[1] === 'PDF') {
            setnameFilePDF((e.target.files[0].name).toString())
            imgRtaDB.tipoArchivo = "PDF"
            imgRtaDB.formatoImg = nameFileImg.split('/')[1]
            let file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
            let reader = new FileReader();
            reader.onload = _handleReaderLoaded.bind(e);
            reader.readAsDataURL(file);
            let agen = document.getElementById('ctnAgenda')
            agen.disabled = false
        } else {
            nameFileImg = "";
            Swal.fire({
                title: 'Aviso',
                text: 'El formato no es correcto',
                customClass:"btnYellowMdlCrr",
                type: 'success',
                confirmButtonText: 'Aceptar'
            });
        }
    }
    const [pdf_, setpdf_] = useState('');
    const _handleReaderLoaded = (e) => {
        let reader = e.target;
        imgRtaDB.imagenBase64 = reader.result.split('base64,')[1];
        setpdf_('data:application/pdf;base64, ' + imgRtaDB.imagenBase64);
        progresbar(true)
        savePhotoCurriculum();
    }
    const [pbar, setPbar] = useState(0)
    let countInt = 9;
    const progresbar = (e) => {
        setTimeout(function(){
            countInt = countInt + e;
            stopProgressBar(countInt)
        },200)
    }
    const stopProgressBar = (e) =>{
        if(e > 100){
            setPbar(100)
        }else{
            setPbar(e) 
            let Aumento = 1
            progresbar(Aumento);
        }
    }
    const descargarPdf = (id) => {
        if (id === 1) {
            const downloadLink = document.createElement("a");
            const fileName = "CurriculumVitae.pdf";

            downloadLink.href = pdf_;
            downloadLink.download = fileName;
            downloadLink.click();
        } else {
            document.getElementById('closemyModal').click()
        }

    }
    return (
        <React.Fragment>
            <ContainerService tittle="Mejora tu hoja de vida " classLine="tittleService" styleTittle={{ fontSize: "34px", fontFamily: 'cibSans-Bold' }} linkBread={linkbread}>
                {step === 1 && (
                    <div className="col-lg-12 p-lg-5 p-sm-0">
                        <div className="col-12 pl-0 mb-4 text-left" onClick={(e) => { document.getElementById('volverHv').click()}}>
                            <Link to='/s/desempleo/mejorar-hoja-de-vida' className="d-none" id="volverHv"></Link>
                            <div className='backButtom'>
                                <FontAwesomeIcon icon={faChevronLeft} className="chevronLeft" />
                            </div>
                            <button className="btn btn-defaul" style={{ color: "#949494" }}  >
                                Volver
                            </button>
                        </div>
                        <h2 style={{fontSize: '28px',color:'#2A2A2A', fontFamily:'cibSans-Bold'}} className="mb-5">Mejora y potencializa tu hoja de vida.  </h2>
                        <p className="mt-3" style={{ width: "94%",fontFamily:'OpenSans-regular',color:'#58595B', display: nameFilePDF !== '' ? 'none' : '' }}>
                            Un experto en recursos humanos revisará tu hoja de vida para sacar el mayor provecho a tu perfil. escoge alguna de las siguiente opciones para iniciar tu agenda.
                        </p>
                        <p className="mt-3 mb-5" style={{ width: "94%", fontFamily:'OpenSans-regular',color:'#58595B', display: nameFilePDF === '' ? 'none' : '' }}>
                            Un experto en recursos humanos revisará tu hoja de vida para sacar el mayor provecho a tu perfil.
                        </p>
                        {
                            nameFilePDF !== '' &&
                            <div style={{ width: '94%', display: nameFilePDF === '' ? 'none' : 'block' }}>
                                <div className="col-12 mt-3 d-lg-flex d-md-flex d-none" style={{ background: '#F9F9F9', height: '50px', display: nameFilePDF === '' ? 'none' : 'block' }}>
                                    <div style={{ width: '90%', display: 'flex' }} c>
                                        <div style={{ width: '5%' }}>
                                            <img src={PDF} style={{ width: '22px', height: '28px', margin: '10px' }} />
                                        </div>
                                        <p style={{ width: '90%', marginTop: '16px', marginLeft: '12px', fontSize: '12px', color: '#747474' }}>{nameFilePDF}</p>
                                        <p style={{ width: '90%', marginTop: '3px', marginLeft: '40%', fontSize: '12px', color: '#747474', display: pbar === 100 ? '' : 'none' }}>Archivo completado <img src={ck} style={{ width: '26px', height: '24px', margin: '10px' }} /></p>
                                    </div>
                                    <div style={{ width: '10%' }}>
                                        <input type='submit' data-target="#myModal" data-toggle="modal" style={{ width: '100%', height: '100%', background: '#F3F3F3', color: '#747474', fontSize: '12px', border: '1px', display: pbar === 100 ? '' : 'none' }} value='Ver archivo' />
                                    </div>
                                </div>
                                <div className=" mt-3 hvProgress progress" style={{ display: pbar === 100  ? 'none' : '' }}>
                                    <div className="progress-bar" role="progressbar" style={{ width: pbar  + '%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div class="col-12 mt-3 pl-0 pr-0 d-lg-none d-md-none d-block" style={{ background: 'rgb(249, 249, 249)', height: '250px' }}>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                            <img src={PDF} style={{ width: '54px', height: '57px', margin: '10px' }} />
                                        </div>
                                        <p style={{ marginLeft: '64px', fontSize: '12px', color: 'rgb(116, 116, 116)', textAlign: 'center' }}>
                                            {nameFilePDF}
                                        </p>
                                        <p style={{ fontSize: '12px', color: 'rgb(116, 116, 116)', marginLeft: '48px' }}>
                                            Archivo completado
                                            <img src={ck} style={{ width: '26px', height: '24px', margin: '10px' }} />
                                        </p>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <input type="submit" data-target="#myModal" data-toggle="modal" value="Ver archivo" style={{ width: '100%', height: '100%', background: 'rgb(243, 243, 243)', color: 'rgb(116, 116, 116)', fontSize: '12px', border: '1px', marginTop: '30px', height: '50px' }} />
                                    </div>
                                </div>
                                <div className="mt-3" style={{ width: '100%', display: pbar !== 100 ? 'none' : '', textAlign:'center' }}>
                                    <button type="button" id="ctnAgenda1" className="btnYellow" onClick={() => { getTypeCulum() }} style={{ width: '230px', fontSize: '16px',fontFamily: 'OpenSans-bold' }}>
                                        CONTINUAR AGENDA
                                    </button>
                                </div>
                            </div>
                        }
                        <div className="col-lg-12" style={{ display: nameFilePDF !== '' ? 'none' : 'flex' }}>
                            <div className={`form-inline ${window.innerWidth <= 426?'mt-2':'mt-5'} mb-5`}>
                                <div className={`form-check ${window.innerWidth <= 426?'mb-3':'mb-2 mr-2'} d-flex `} id="checkCrrsc">
                                    <input type="checkbox" id="chkHVC" className="form-check-input " onChange={(e) => { handleFormContact1(e) }} />
                                    <label for="chkHVC" className="form-check-input checkSalario clsshvch" style={{ color: '#777777' }}>Usar mi hoja de vida creada.</label><br />
                                </div>
                                <button type="button" className="manito mr-2 btnMain2Gray " id="pdfSubido" style={{ width: '265px', fontWeight: '100', height: '50px', textTransform:'uppercase' }} onClick={()=> {document.getElementById('filepdfx').click()}}>
                                    <input className="d-none" type="file" name="file" accept="application/pdf" id="filepdfx" style={pdfDoc} onChange={(e) => { handleInputChange(e) }} />
                                    Cargar nueva hoja de vida
                                </button>
                                <button type="button" id="ctnAgenda" className="btnYellow" onClick={() => { getTypeCulum(); }} style={{ width: '230px', fontSize: '16px', fontFamily: 'OpenSans-bold' }}>
                                    CONTINUAR AGENDA
                                </button>
                            </div>
                        </div>
                        <div id="myModal" class="modal fade" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <iframe src={pdf_} width="100%" height="600px" title="pdf"></iframe>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-default" onClick={(e) => { descargarPdf(1) }}>Descargar</button>
                                            <button type="button" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <Sheduling key={1} type="2023" module="HojaVida-1" propsP={props} infoSheduling={infoSheduling} />
                )}
            </ContainerService>
        </React.Fragment>
    );
}