import React from "react";
import faPhone from '../../assets/img/faq/bancolombia-icon-faq-005.svg';
import faComment  from '../../assets/img/faq/bancolombia-icon-faq-006.svg';

export default function ContactHelp2(pros) {
  return (
    <div
      className="container-fluid"
      style={{ background: "#ffffff", fontSize: 12, paddingLeft: "0px", paddingRight: "0px" }}
    >
      <div className="row">
        <div className="col-12 mb-5 mt-5"></div>
        <div className="col-10 offset-1 rectangle mb-5" style={{borderBottom:'4px solid #323B4E'}}>
          <div className="mt-5 mb-5">
            <div className="row text-center">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <img src={faPhone} size="2x" className="mb-2" alt="icono Faq"/>
                <p className="mt-3 mb-2 grayColor" style={{ fontFamily: "Next", color:"#2A2A2A",fontSize:"16px"}}>
                  Llama a nuestras líneas de atención
                </p>
                <p className="grayColor">
                  <span  className="grayColor d-sm-none d-md-none d-lg-block d-none" style={{fontSize:"16px",fontFamily: 'helvetica'}}>
                    Bogotá: (601)743 1460.  Nacional: 01 8000 930 131
                  </span>
                  <span  className="grayColor d-lg-none d-md-block d-block" style={{fontSize:"16px",fontFamily: 'helvetica'}}>
                  Bogotá: (601)743 1460<br/>  Nacional: 01 8000 930 131
                  </span>
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 black border-left">
                <img
                  src={faComment}
                  size="2x"
                  className="mb-2"
                  alt="icono Faq"
                />
                <p className="grayColor mt-3 mb-2" style={{ fontFamily: "Next", color:"#2A2A2A", fontSize:"16px" }}>
                  Escríbenos un correo electrónico
                </p>
                <a
                  href="mailto:comunicaciones@vivesegura.com"
                  className="grayColor mailContactHelp"
                  style={{ color: "#58595B" }}
                >
                  comunicaciones@vivesegura.com
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
        <div className="fondoGris d-none d-lg-block"></div>
      </div>
    </div>
  );
}
