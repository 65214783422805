import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {FormGroup,Input,Label} from "reactstrap";
import { Link } from "react-router-dom";
import cerrarModal from "../../assets/img/cardif-boton-cerrar.svg";
import { getUser, isActive } from "../../utils/validations";

export default function ModalTerms(props) {
  const { title } = props;
  const [show, setShow] = useState(false);
  const [isLogged] = useState(isActive());
  const handleShow = () => setShow(true);
  const [btnAccept, setbtnAccept] = useState(0);
  const handleClose = () => {
    setbtnAccept(0); 
    setShow(false)
  };
  const cambiobtnAccept = e=>{
    setbtnAccept(1);
    //console.log(btnAccept)
  }

  const dobleEnter = (
    <>
      <br />
      <br />
    </>
  );
  const enterText = (
    <>
      <br />
    </>
  );
  const dobleEspacio = (
    <>
      &nbsp;
      &nbsp;
    </>
  );

  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={handleShow} className="hover mr-lg-3 mr-md-0 text-center TipoLetra">
        {title}
      </span>

      <Modal
        className="mobileModal"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered>

        <Modal.Header>
          <div className="btnCierraModalPrincipal">
            <button
              id="btnCierraModal"
              type="button"
              className="swal2-close-modal btnPolicies"
              style={{ outline: "none", marginRight: "12px" }}
              onClick={handleClose}
            ><p>×</p></button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="body-modal-t">
            <h3 className="tittle-modal-t">
              Políticas de privacidad
            </h3>
            <p className="overflow-scroll text-justify" style={{ fontFamily:'Helvetica'}}>
              CARDIF COLOMBIA SEGUROS GENERALES S.A. identificada con NIT 900.200.435-3 y domicilio principal en Bogotá D.C, es una entidad comprometida con la protección de la privacidad y de toda información que pueda asociarse o relacionarse con personas naturales y jurídicas, determinadas o determinables (los “Datos Personales”), a la cual tenga acceso en el desarrollo de sus actividades y funciones. Al acceder y navegar en la red del portal, se reciben, recolectan, utilizan, administran, analizan, segmentan, indexan, transmiten, transfieren, almacenan y, en general, procesan Datos Personales tales como los de identificación (nombre, cédula, edad, género), de contacto (teléfono, correo electrónico, dirección), historia laboral, información financiera, fecha de nacimiento, profesión, salario actual, nombre de la empresa en la que trabaja, correo electrónico corporativo, número de celular corporativo, números de créditos, saldos, información de su tarjeta de crédito e información que podrá ser obtenida en el curso de la utilización de nuestros servicios y para el desarrollo de nuestras actividades y funciones.  
              {dobleEnter}
              Sus Datos Personales son muy importantes para nosotros y serán tratados con base en los principios que establece la Ley 1581 de 2012 y se utilizarán únicamente para las finalidades señaladas a continuación. 
              {dobleEnter}
              <strong>ÁMBITO DE APLICACIÓN DE LA POLÍTICA</strong>
              {enterText}
              La presente Política de Tratamiento de la Información (la “Política”) está dirigida a: usuarios y potenciales usuarios que utilicen la página web vivesegura.com y que de cualquier manera estén utilice la página web y cuyos Datos Personales estén siendo o vayan a ser tratados por COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A.  (los “Titulares”). La Política tiene como propósito describir los mecanismos y procedimientos para proteger los derechos de los Titulares; identificar al área que atenderá las consultas, preguntas, reclamos y quejas, y, finalmente, describir las finalidades y los tipos de Tratamiento (según se define más adelante) a los cuales se someterán los Datos Personales. 
              {dobleEnter}
              Esta Política se aplicará a todo Tratamiento realizado dentro del territorio de la República de Colombia por COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. y, en lo que corresponda, con aquellos terceros con los que COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. acuerde todo o parte de la realización de cualquier actividad relacionada con el Tratamiento de Datos Personales respecto de los cuales COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S y CARDIF COLOMBIA SEGUROS GENERALES S.A.  actúa como Responsable (según se define más adelante). 
              {dobleEnter}
              La Política se aplicará a los terceros con quienes COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S y CARDIF COLOMBIA SEGUROS GENERALES S.A.  eventualmente suscriba contratos de Transmisión, con el fin de que tales terceras personas conozcan las obligaciones que les aplicarán, las finalidades a las cuales se deben someter y los estándares de seguridad y confidencialidad que deben adoptar cuando realicen el Tratamiento por cuenta de COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. 
              {dobleEnter}
              <strong>DEFINICIONES</strong>
              {enterText} 
              Las expresiones que estén entre paréntesis, subrayadas y escritas con mayúsculas iniciales en esta Política tendrán el significado que se les otorga antes de los paréntesis. Los términos no definidos tendrán el significado que la ley o la jurisprudencia aplicable en Colombia les otorgue. A pesar de lo anterior, a continuación, se definen los términos más relevantes de esta Política:
              {dobleEnter}
              a) <strong>Autorización:</strong> Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de Datos Personales.
              {dobleEnter}
              b) <strong>Autorizado:</strong> Es COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A.  y todas las personas bajo la responsabilidad de COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. que en virtud de la Autorización y de este Política, tienen legitimidad para realizar el Tratamiento.
              {dobleEnter}
              c) <strong>Base de Datos:</strong> Conjunto organizado de Datos Personales que sea objeto de Tratamiento, electrónico o no, cualquiera que fuere la modalidad de su formación, almacenamiento, organización y acceso. COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. mantendrá de manera separada las Bases de Datos en las que tenga la calidad de Encargado, de aquellas en las que tenga la condición de Responsable.
              {dobleEnter}
              d) <strong>Dato Personal:</strong> Es información de cualquier tipo, vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables, como lo pueden ser datos de identificación (nombre, cédula, edad, género), de contacto (teléfono, correo electrónico, dirección), información financiera y otros datos.
              {dobleEnter}
              e) <strong>Dato Público:</strong> Dato Personal calificado como tal según los mandatos de la ley o de la Constitución Política y aquel que no sea semiprivado, privado o sensible. Son considerados datos públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión u oficio y a su calidad de comerciante o de servidor público y aquellos que puedan obtenerse sin reserva alguna. Por su naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y boletines oficiales y sentencias judiciales debidamente ejecutoriadas que no estén sometidas a reserva. 
              {dobleEnter}
              f) <strong>Datos Sensibles:</strong> Es el Dato Personal que podría afectar la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de posición, así como los datos relativos a la salud, a la vida sexual, y los datos biométricos.
              {dobleEnter}
              g) <strong>Encargado del Tratamiento:</strong> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de Datos Personales por cuenta del Responsable del Tratamiento.
              {dobleEnter}
              h) <strong>Legitimado:</strong> Son aquellas personas que pueden ejercer los derechos del Titular, tales como el mismo Titular, sus causahabientes, representantes, apoderados y aquellos que, por estipulación a favor de otro o para otro, estén acreditados, siempre que puedan acreditar su condición. 
              {dobleEnter}
              i) <strong>Ley:</strong> Es la Ley 1581 de 2012, el Decreto 1074 de 2015, la Sentencia C-748 de 2011, la jurisprudencia aplicable de la Corte Constitucional y la regulación expedida por el gobierno en relación con la materia.
              {dobleEnter}
              j) <strong>Manual:</strong> Es el documento en el cual están consignados las políticas y procedimientos para asegurar el cumplimiento de la Ley. 
              {dobleEnter}
              k) <strong>Responsable del Tratamiento:</strong> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la Base de Datos y/o el Tratamiento de los Datos Personales. Para efectos del presente documento, el Responsable del tratamiento será COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A.
              {dobleEnter}
              l) <strong>Titular:</strong> Es la persona natural a quien se refieren los Datos Personales, cuya información podrá reposar en una Base de Datos y quien es el sujeto del derecho de hábeas data.
              {dobleEnter}
              m) <strong>Transferencia:</strong> Es el Tratamiento que implica el envío de la información o de los Datos Personales a un receptor, que es Responsable y se podrá encontrar fuera o dentro del territorio de la República de Colombia. En la Transferencia el receptor actuará en calidad de Responsable y no estará sujeto a los términos y condiciones de esta Política. 
              {dobleEnter}
              n) <strong>Transmisión:</strong> Es el Tratamiento que implica la comunicación de los Datos Personales dentro o fuera del territorio de la República de Colombia para que el Encargado realice el Tratamiento de tales Datos Personales por cuenta del Responsable. En la Transmisión el receptor actuará en calidad de Encargado y se someterá a la Política o a los términos establecidos en el contrato de Transmisión correspondiente.
              {dobleEnter}
              o) <strong>Tratamiento:</strong> Es toda operación o procedimiento sistemático, electrónico o no, incluso a través de herramientas como web bugs, cookies, spiders, web crawlers y web beacons, que permita la recolección, conservación, ordenamiento, almacenamiento, modificación, indexación, perfileo, relacionamiento, uso, circulación, análisis, segmentación, anonimización, compendio, evaluación, bloqueo, destrucción y, en general, el procesamiento de Datos Personales, así como también su entrega a terceros a través de comunicaciones, consultas, interconexiones, cesiones, mensajes de datos y otros medios que sirvan al efecto.
              {dobleEnter}
              <strong>PRINCIPIOS</strong>
              {enterText}
              En todo Tratamiento realizado por COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A., los Encargados y/o terceros a quienes se les Transmitan Datos Personales, se les dará cumplimiento integral de los principios establecidos en la Ley y los a continuación enunciados: 
              {dobleEnter}
              <strong>Principio de legalidad:</strong> no habrá tratamiento de información personal de los clientes sin observar las reglas establecidas en la normatividad vigente.
              {dobleEnter}
              <strong>Principio de finalidad:</strong> la incorporación de datos a las bases físicas o digitales de COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. deberá obedecer a una finalidad legítima, la cual será oportunamente informada al titular en la cláusula de autorización para el tratamiento y en la política de privacidad.
              {dobleEnter}
              <strong>Principio de libertad:</strong> COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. realizará tratamiento de Datos Personales de sus clientes únicamente cuando cuenten con la autorización de estos en los términos del art. 3 literal a de la Ley 1581 del 2012 y el capítulo II del Decreto 1377 del 2013.
              {dobleEnter}
              <strong>Principio de veracidad y calidad:</strong> COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. propenderá porque la información de sus clientes sea veraz y se encuentre actualizada, para lo cual dispondrá de medios eficientes para la actualización y rectificación de los Datos Personales. Igualmente, COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. se abstendrá de llevar a cabo el tratamiento de la información cuando existan dudas sobre la calidad o veracidad de la misma. 
              {dobleEnter}
              <strong>Principio de transparencia:</strong> dentro de los mecanismos que se establezcan para el ejercicio de los derechos de los titulares de la información personal, se garantizará al titular y a sus causahabientes, así como a los terceros autorizados por este, el acceso a la información sobre Datos Personales que le conciernan.
              {dobleEnter}
              <strong>Principio de acceso y circulación restringida:</strong> COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. se compromete a garantizar que únicamente personas autorizadas podrán acceder a la información personal. Así mismo, su circulación se limitará al ejercicio de las finalidades autorizadas por el usuario. COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES dispondrá de medios contractuales para garantizar la confidencialidad y circulación restringida de la información. 
              {dobleEnter}
              <strong>Principio de seguridad:</strong> COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. adelantará todas las medidas técnicas, administrativas y humanas para garantizar que la información personal de los titulares almacenada en bases de datos físicas o digitales no circule o personas no autorizadas accedan a ella. 
              {dobleEnter}
              <strong>DATOS PERSONALES QUE UTILIZAMOS</strong>
              {enterText}
              Para llevar a cabo las finalidades descritas en el presente aviso, COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. utilizará los siguientes Datos Personales de los cuales el usuario y/o visitante es Titular al ingresar a la página web vivesegura.com y al utilizar sus servicios: 
              {dobleEnter} &bull; datos de identificación 
              {enterText} &bull; datos de contacto 
              {enterText} &bull; fecha de nacimiento 
              {enterText} &bull; profesión 
              {enterText} &bull; salario actual 
              {enterText} &bull; nombre de la empresa en la que trabaja,
              {enterText} &bull; correo electrónico corporativo
              {enterText} &bull; número celular corporativo
              {enterText} &bull; información bancaria
              {enterText} &bull; números de créditos 
              {enterText} &bull; saldos 
              {enterText} &bull; datos sobre su historia laboral
              {dobleEnter}
              COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. no recolectará, incorporará ni almacenará datos sensibles de sus clientes a menos que exista una autorización previa del titular de la información. Solo se solicitará la autorización mencionada cuando sea necesaria y proporcional para la ejecución de la relación contractual con el titular, siempre y cuando la ley exija o permita acceder a información sensible de este. 
              {dobleEnter}
              <strong>DERECHOS DE LOS TITULARES</strong>
              {enterText}
              De acuerdo con la Ley, los Titulares tienen los siguientes derechos:
              {dobleEnter}
              <table className="tablePolicies">
                <tr>
                  <th>Derecho</th>
                  <th>Descripción</th>
                </tr>
                <tr>
                  <td style={{width: '200px', textAlign:'center'}}>Actualización</td>
                  <td>Actualizar los Datos Personales que reposen en las Bases de Datos de COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S, y CARDIF COLOMBIA SEGUROS GENERALES S.A.  para mantener su integridad y veracidad.</td>
                </tr>
                <tr>
                  <td  className='text-center'>Conocimiento y Acceso</td>
                  <td>Conocer y acceder a sus Datos Personales almacenados por COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A.  o los Encargados. Este acceso se permitirá de forma gratuita según solicitud al menos una vez al mes.</td>
                </tr>
                <tr>
                  <td className='text-center'>Prueba </td>
                  <td>Solicitar prueba de la Autorización otorgada a COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A., salvo que la Ley indique que dicha Autorización no es necesaria o que la misma haya sido validada con arreglo a lo establecido en el artículo 10 del Decreto 1377 (artículo 2.2.2.25.2.7 del Decreto 1074 de 2015).</td>
                </tr>
                <tr>
                  <td className='text-center'>Queja</td>
                  <td>Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a la Ley cuando se haya agotado el requisito de procedibilidad y acudir en primera instancia a COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A.</td>
                </tr>
                <tr>
                  <td className='text-center'>Rectificación </td>
                  <td>Rectificar la información y los Datos Personales que estén bajo el control de COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A.</td>
                </tr>
                <tr>
                  <td className='text-center'>Revocación</td>
                  <td>Solicitar la revocatoria de la Autorización, siempre y cuando no exista un deber legal o una obligación de carácter contractual en cabeza del Titular con COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A., según la cual el Titular no tenga el derecho de solicitar la supresión de sus Datos Personales.</td>
                </tr>
                <tr>
                  <td className='text-center'>Solicitud</td>
                  <td>Presentar solicitudes ante COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A.  o el Encargado respecto del uso que les han dado a sus Datos Personales.</td>
                </tr>
                <tr>
                  <td className='text-center'>Supresión</td>
                  <td>Solicitar la supresión de sus Datos Personales de las Bases de Datos de COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A.  siempre y cuando no exista un deber legal o una obligación de carácter contractual en cabeza del Titular con TUYA S.A. y CARDIF COLOMBIA SEGUROS GENERALES S.A., según la cual el Titular no tenga el derecho de solicitar la supresión de sus Datos Personales.</td>
                </tr>
              </table>
              {enterText}
              <strong>AUTORIZACIONES Y AVISOS.</strong>
              {enterText}
              Todo Tratamiento debe estar precedido por la obtención de la Autorización. Para ello, COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. Y CARDIF COLOMBIA SEGUROS GENERALES S.A., sus empleados y Autorizados, previo a la recolección de los Datos Personales, deberán obtener la Autorización suscrita por el Titular y conservar una copia de este documento para consultas futuras. COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. Y CARDIF COLOMBIA SEGUROS GENERALES S.A. en su condición de Responsable, ha dispuesto de los mecanismos necesarios para obtener la Autorización de los Titulares garantizando en todo caso que sea posible verificar el otorgamiento de dicha Autorización.
              {dobleEnter}
              La Autorización puede constar en un documento físico, electrónico, grabación de la voz o cualquier otro formato que permita garantizar su posterior consulta, o mediante un mecanismo técnico o tecnológico idóneo mediante el cual se pueda concluir de manera inequívoca, que, de no haberse surtido una conducta del Titular, los Datos Personales nunca hubieren sido capturados y almacenados en la Base de Datos.
              {dobleEnter}
              La autorización del Titular no será necesaria cuando se trate de:
              {enterText}
              a. Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judiciales;
              {enterText}
              b. Datos de naturaleza pública;
              {enterText}
              c. Casos de urgencia médica o sanitaria;
              {enterText}
              d. Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos;
              {enterText}
              e. Datos relacionados con el Registro Civil de las Personas.
              {dobleEnter}
              COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. Y CARDIF COLOMBIA SEGUROS GENERALES S.A. podrá obtener la Autorización para el Tratamiento de Datos Personales, mediante diferentes medios, entre ellos electrónico, mensaje de datos, internet, sitios web, o en cualquier otro formato que en todo caso permita la obtención del consentimiento mediante conductas inequívocas, a través de las cuales se concluya sin lugar a dudas que de no haberse surtido la misma por parte del Titular o la persona legitimada para ello, los Datos Personales no se hubieran almacenado o recolectado en la Base de Datos. Dicha Autorización será solicitada por COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. Y CARDIF COLOMBIA SEGUROS GENERALES S.A.  de manera previa al Tratamiento de los Datos Personales.
              {dobleEnter}
              Para conocer nuestras Políticas de Tratamiento de Datos Personales y los cambios sustanciales que se produzcan cada una de ellas, se encuentra publicadas en la siguiente página web:  www.bnpparibascardif.com.co, en las cuales podrá llevar a cabo la consulta de su interés.  
              {dobleEnter}
              <strong>FINALIDADES DEL TRATAMIENTO DE DATOS PERSONALES</strong>
              {enterText}
              CARDIF COLOMBIA SEGUROS GENERALES S.A., en el curso de sus actividades comerciales, Tratará los Datos Personales. Los Datos Personales Tratados por CARDIF COLOMBIA SEGUROS GENERALES S.A. deberán someterse únicamente a las finalidades que se señalan a continuación o las que sean aceptadas por los Titulares en el momento de la recolección de los Datos Personales. Asimismo, los Encargados o terceros que tengan acceso a los Datos Personales en virtud de la Ley, contrato u otro documento vinculante, realizarán el Tratamiento para la consecución de las siguientes finalidades: 
              {dobleEnter}
              Los Datos Personales que Tratará CARDIF COLOMBIA SEGUROS GENERALES S.A. serán utilizados para:
              {enterText}
              i. Adelantar la gestión administrativa que CARDIF COLOMBIA SEGUROS GENERALES S.A.  requiera respecto de sus clientes, aliados, proveedores, empleados y demás grupos de interés;
              {enterText}
              ii. Desarrollar estudios internos sobre los intereses de los Titulares, con el objetivo de comprender mejor las necesidades e intereses de los Titulares, para brindar un mejor servicio;
              {enterText}
              iii. Enviar correspondencia, correos electrónicos, contactar telefónicamente o por cualquier medio disponible a los Titulares, en desarrollo de actividades publicitarias, promocionales y de mercadeo enfocados a su actividad;
              {enterText}
              iv. Compartirlos o enviarlos a terceros con quienes CARDIF COLOMBIA SEGUROS GENERALES S.A.   realice alianzas o celebre contratos, para fines comerciales relacionados con la ejecución de las actividades comprendidas dentro de su objeto social; y Llevar a cabo Transferencia y Trasmisión de los Datos Personales a terceros con quienes realice alianzas relacionadas con su objeto social, contrate estudios o les encargue el tratamiento de datos.
              {dobleEnter}
              <strong>COMPARTIR INFORMACIÓN CON TERCEROS</strong>
              {enterText}
              Es posible que para cumplir con la relación contractual que COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A. sostenga con el titular de la información, esta sea entregada o compartida con proveedores para las finalidades autorizadas por el titular o las previstas en la ley, tales como profesionales de la salud, call centers, distribuidores y profesionales en prevención, y personas naturales o jurídicas que presten servicios profesionales para prestar correctamente la atención. Siempre que su información sea entregada o compartida con proveedores, COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A.  se asegurará de establecer unas condiciones que vinculen al proveedor a esta Política de tal forma que la información personal de los clientes se encuentre protegida. 
              {dobleEnter}
              <strong>AUTORIZACIÓN USO DE TESTIMONIO</strong>
              {enterText}
              Al dar click en “aceptar” autorizo a CARDIF COLOMBIA SEGUROS GENERALES S.A “CARDIF” a utilizar mi nombre junto con el testimonio y/o comentario publicado en la plataforma de Coursera. Esta autorización la otorgo a CARDIF, con el objeto de que indistintamente pueda utilizarlos con fines publicitarios, en ediciones impresas, digitales y en la Internet.
              {dobleEnter}
              <strong>CONFIDENCIALIDAD Y MEDIDAS DE SEGURIDAD</strong>
              {enterText}
              La confidencialidad y protección de los Datos Personales es una prioridad para COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A., por lo que la empresa ha establecido y conservado medidas de seguridad administrativas, técnicas y físicas para evitar el daño, pérdida, alteración, destrucción, así como el uso, acceso o divulgación indebida.
              {dobleEnter}
              Adicionalmente, y en cumplimiento al principio de seguridad establecido en la ley COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A.  ha adoptado y adoptará las medidas técnicas, tecnológicas, humanas y administrativas que sean del caso para otorgar seguridad a los registros salvaguardando su confidencialidad, integridad y disponibilidad.
              {dobleEnter}
              <strong>PROCEDIMIENTOS PARA EJERCER LOS DERECHOS DE LOS TITULARES</strong>
              {enterText}
              a. Consultas:
              {enterText}
              COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A.  recibirá y resolverá las consultas del Titular, Legitimados o los representantes de menores de edad relativas a:
              {enterText}
              &bull; ¿Cuáles son los Datos Personales del Titular que reposan en las Bases de Datos de COMPAÑÍA DE COSMETICOS VOTRE PASSION S.A.S. y CARDIF COLOMBIA SEGUROS GENERALES S.A.?
              {enterText}
              &bull; ¿Cuál es el Tratamiento al cual están sujetos los Datos Personales?  
              {enterText}
              &bull; ¿Cuáles son las finalidades que pretenden satisfacer?
              {enterText}
              Tales consultas deberán ser enviadas por escrito a la siguiente dirección de correo electrónico: atencionalcliente@cardif.com.co.  CARDIF COLOMBIA SEGUROS GENERALES S.A. guardará prueba de la consulta y su respuesta.
              {dobleEnter}
              La consulta será atendida en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma. 
              {dobleEnter}
              Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado, expresando los motivos de la demora y señalando la fecha en que se atenderá la consulta, la cual en ningún caso podrá superar los diez (10) días hábiles siguientes al vencimiento del primer término. 
              {dobleEnter}
              b. Reclamos:
              {enterText}
              CARDIF COLOMBIA SEGUROS GENERALES S.A.  recibirá y resolverá reclamos formulados por el Titular, los Legitimados o los representantes de menores de edad respecto de: 
              {enterText}
              &bull; Datos Personales Tratados por CARDIF COLOMBIA SEGUROS GENERALES S.A.  que deben ser objeto de corrección, actualización o supresión;
              {enterText}
              &bull; El presunto incumplimiento de cualquiera de las obligaciones de CARDIF COLOMBIA SEGUROS GENERALES S.A.  según la Ley.
              {dobleEnter}
              Tales reclamos deberán ser enviados por escrito a la siguiente dirección de correo electrónico: atencionalcliente@cardif.com.co. CARDIF COLOMBIA SEGUROS GENERALES S.A. guardará prueba del reclamo y su respuesta.   
              {dobleEnter}
              c. Recepción de Solicitudes:
              {enterText}
              Estará en cabeza de CARDIF COLOMBIA SEGUROS GENERALES S.A. recibir las solicitudes de información. Esta se encargará de recibir las solicitudes de los Titulares, para el ejercicio de los derechos de acceso, consulta, rectificación, actualización, supresión y revocatoria a que se refiere la Ley 1581 de 2012. A nivel interno, se han establecido procedimientos para la oportuna y eficaz atención de las consultas y reclamos teniendo en cuenta los responsables de las diferentes bases de datos. 
              {dobleEnter}
              <strong>VIGENCIA DEL TRATAMIENTO DE LOS DATOS</strong>
              {enterText}
              La información suministrada por los titulares permanecerá almacenada por el término necesario para el cumplimiento de los fines para los cuales fue incorporada. 
              {dobleEnter}
              <strong>MODIFICACIONES</strong>
              {enterText}
              CARDIF COLOMBIA SEGUROS GENERALES S.A. se reserva el derecho de modificar las normas de confidencialidad y protección de datos con el fin de adaptarlas a nuevos requerimientos de tipo legal, jurisprudencial, técnico y, en general, cuando sea necesario para prestar un mejor servicio.
              {/* {dobleEnter}
              <strong>AUTORIZACIÓN</strong>
              {enterText}
              De conformidad con lo dispuesto en este documento, otorga autorización como titular del derecho a CARDIF COLOMBIA SEGUROS GENERALES S.A para el uso y tratamiento de sus datos personales para los fines informados en la presente POLITICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES, salvo en los casos exceptuados por la ley.
              {dobleEnter}
              Al dar <strong>click en “aceptar”</strong>, está suministrando su consentimiento previo, expreso e informado para obligarse bajo los términos de esta Política, la cual podrá consultar en   www.bnpparibascardif.com.co enlace “Privacidad”. */}
            </p>
          </div>
        
       
            <div className="text-center" style={{ margin: "5px" }}>
            <button
              type="button"
              onClick={handleClose}
              className="btnModal mt-0 pl-5 pr-5"
            >
              ACEPTAR
            </button>
          </div>
          
        </Modal.Body>
      </Modal>
    </>
  );
}
