import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";

export default function ModalFirstPsico(props) {
    const { showM, clickClose,TypeRes } = props;
    return (
        <>
            <Modal
                show={showM}
                aria-labelledby="contained-modal-title-vcenter"
                size="md"
                centered
                
            >
                <Modal.Header>
                <div className="btnCierraModalPrincipal">
                  <button
                    id="btnCierraModal"
                    type="button"
                    className="swal2-close-modal"
                    style={{marginTop: "15px", outline:'none'}}
                    onClick={() => { clickClose() }}
                  ><p>×</p></button>
                </div>
                </Modal.Header>
                <Modal.Body style={{width:'416px',height: '338px'}}>
                    <div className="container" >
                        <div className="row">
                            
                            {TypeRes === 'incorrecta' && (
                                <div className="col-12 text-center">
                                    <img
                                        style={{ width: "52px", height: "52px", color: "red", verticalAlign: "middle" }}
                                        src={require('../../assets/img/img_test_psico/firstTest/error.svg')}
                                    />
                                    <h5 className="modal-title text-center" style={{ marginTop: "4%",fontFamily:'cibSans-Bold',color: '#292929' }}>
                                        Respuesta incorrecta
                                </h5>
                                    <p className="text-center" style={{ marginTop: "10%", fontFamily: "OpenSans",color:'#8E8E93' }}>
                                        Has seleccionado una respuesta incorrecta,
                                        inténtalo nuevamente.
                                </p>
                                    <div className="text-center">
                                        <button className="btn "
                                            style={{
                                                borderRadius: "25px",
                                                background: "#FFF",
                                                border: "1px solid #242424",
                                                color: "#242424 ",
                                                fontFamily: 'OpenSans',
                                                fontWeight: 'bold',
                                                minWidth: "179px",
                                                width: "185px",
                                                height: "45px",
                                                paddingTop: "2px",
                                                boxShadow: "0px 0px 0px 0px rgba(0,123,132,0)",
                                            }} onClick={() => { clickClose() }} >
                                            Aceptar
                                        </button>
                                    </div>
                                </div>
                            )}
                            {TypeRes === 'correcta' && (
                                <div className="col-11 m-auto text-center">
                                    <img
                                        style={{ width: "52px", height: "52px", color: "red", verticalAlign: "middle" }}
                                        src={require('../../assets/img/img_test_psico/firstTest/check-green.svg')}
                                    />
                                    <h5 className="modal-title text-center" style={{ marginTop: "4%",fontFamily:'cibSans-Bold',color: '#292929' }}>
                                        Respuesta correcta
                                    </h5>
                                    <p className="text-center" style={{ marginTop: "10%", fontFamily: "OpenSans",color:'#8E8E93' }}>
                                        Has seleccionado la respuesta correcta del ejercicio.
                                    </p>
                                    <div className="text-center">
                                        <button className="btn "
                                            style={{
                                                borderRadius: "25px",
                                                background: "#FFF",
                                                border: "1px solid #242424",
                                                color: "#242424 ",
                                                fontFamily: 'OpenSans',
                                                fontWeight: 'bold',
                                                minWidth: "179px",
                                                width: "185px",
                                                height: "45px",
                                                paddingTop: "2px",
                                                boxShadow: "0px 0px 0px 0px rgba(0,123,132,0)",
                                            }} onClick={() => { clickClose() }} >
                                            Aceptar
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
