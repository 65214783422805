import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function ModalConsentTele(props){
    const { title,view,info,style } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dobleEnter = (
    <>
      <br />
      <br />
    </>
  );
  const enter = (
    <>
      <br />
    </>
  );

  return (
    <React.Fragment>
        <a style={style} onClick={handleShow} className="hover mr-1 text-left disclamersFooter col-10">
          {title}
        </a>
      <Modal 
          show={show}
          onHide={handleClose} 
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
      >
        <Modal.Header>
        <div style={{ height: "40px"}}>
          <button
            id="btnCierraModal"
            type="button"
            className="swal2-close-modal btnTerms"
            onClick={handleClose}
          ><p>×</p></button>
        </div>
        </Modal.Header>
        <Modal.Body>
          <div className="body-modal-terms">
          {view === "1"?(
          <React.Fragment>
            <h4 className="tittle-modal-t" >CONSENTIMIENTO INFORMADO CONSULTA TELEMEDICINA</h4>
            <p className="overflow-scroll textTele">
            Declaro en pleno uso de mis facultades mentales que he recibido una explicación y descripción clara sobre el servicio de Consulta bajo la modalidad de Telemedicina que voy a recibir, mediante la cual un Profesional de la Salud, haciendo uso de una herramienta de videollamada, hará una valoración de mi estado de salud, con el fin de obtener un concepto médico y recomendar el curso de acción que considere necesarios para la atención solicitada. 
            {dobleEnter}
            Adicionalmente entiendo que este servicio no tiene como objeto la atención de casos de urgencias o emergencias vitales en las cuales pueda estar en riesgo la vida del paciente y entiendo claramente que en estos casos debo dirigirme a un centro de atención de urgencias médicas de forma inmediata.
            {dobleEnter}
            Usted podrá ser evaluado (a) y tratado (a) por un profesional médico desde un lugar remoto; como cualquier procedimiento médico, se presentan beneficios y riesgos potenciales, a saber:
            {dobleEnter}
            <b>Beneficios.</b>
            {dobleEnter}
            - Valoración con el médico para iniciar tratamiento sin asistir a la sede. {enter}
            - Mejores resultados y tratamientos menos costosos, dada la inmediatez del servicio.{enter} 
            - Alternativa para frenar la propagación de virus altamente transmisibles. {enter}
            - Disminución de costos y riesgos de desplazamiento. {enter}
            - Obtener su formulación médica, solicitud de exámenes y certificados médicos en formato digital con validez para uso inmediato. {enter}
            - Seguimiento continuo de su condición de salud desde la comodidad de su casa. 
            {dobleEnter}
            <b>Riesgos.</b>
            {dobleEnter}
            - La información puede no ser suficiente para para dar lugar a una consulta concluyente por parte del médico. {enter}
            - La evaluación y el tratamiento médicos podrían demorarse debido a deficiencias o fallas de los equipos. {enter}
            - Necesidad de valoración presencial por parte del médico. 
            {dobleEnter}
            <b>Derechos y deberes del paciente.</b>
            {dobleEnter}
            Entiendo que como usuario de nuestro servicio de telemedicina cuento con los siguientes derechos: 
            {dobleEnter}
            - A recibir información sobre mi salud, trámites administrativos y demás requisitos para la prestación del servicio por medio de telemedicina. {enter}
            - A recibir un trato humanizado, cordial, respetuoso y privado. {enter}
            - A exigir respeto por mis creencias, religión, costumbres, hábitos e identidad sexual. {enter}
            - A presentar quejas, reclamos, sugerencias y felicitaciones de los servicios prestados. {enter}
            - A que mis datos sean tratados con reserva y confidencialidad. 
            {dobleEnter}
            Adicionalmente, acepto y entiendo que tengo los siguientes deberes irrevocables para el desarrollo de los servicios por medio de telemedicina:
            {dobleEnter}
            - Brindar información veraz y completa a los Profesionales de la Salud para el desarrollo de mi atención. {enter}
            - Cumplir con las políticas y reglas del prestador de los servicios de salud. {enter}
            - En caso de asistencia para menores de edad, contar con la presencia de uno de sus padres o tutor. 
            {dobleEnter}
            <b>Al aceptar este consentimiento, entiendo lo siguiente:</b>
            {dobleEnter}
            1. Entiendo que puedo esperar los beneficios del uso de Telemedicina en mi atención médica, pero que los resultados, como en la atención medica habitual, no pueden ser garantizados, pues la labor médica es de medios y no de resultados. {dobleEnter}
            2. Entiendo que La medicina no es una ciencia exacta y puede tener diversos resultados de acuerdo o conforme al tratamiento al que será sometido el paciente. No se pueden garantizar los resultados y puede haber errores en el diagnóstico y el diagnóstico puede verse correlacionado con otros resultados de acuerdo con el tratamiento realizado.  {dobleEnter}
            3. Entiendo que no todos los motivos de consulta pueden ser resueltos a distancia por telemedicina y es el médico quien decidirá si el motivo de consulta puede ser atendido a distancia, pudiendo definir de ser necesario y de acuerdo con la evolución del paciente, que la atención en la modalidad de telemedicina no es suficiente para emitir recomendaciones con certeza e indicar una atención presencial, domiciliaria o de urgencias. {dobleEnter}
            4. Entiendo que tengo la opción de rechazar o retirar mi consentimiento para el uso de la telemedicina en cualquier momento sin afectar mi derecho a recibir atención o tratamiento en un futuro. {dobleEnter}
            5. Entiendo que tengo derecho a inspeccionar y/o examinar toda la información obtenida y registrada en el curso de una interacción de Telemedicina y puedo recibir copias de esta información. {dobleEnter}
            6. Entiendo que los métodos alternativos de atención médica de salud pueden estar disponibles incluida la interacción presencial y que puedo elegir otra alternativa en cualquier momento. {dobleEnter}
            7. Comprendo que las normas que protegen la Reserva, confidencialidad, custodia, seguridad y tratamiento de mis datos personales e información de salud, aplican a la telemedicina y que ninguna información, obtenida en el uso de la telemedicina, la cual me identifica, será revelada a terceros no autorizados por las normas sin mi autorización. {dobleEnter}
            8. Comprendo y declaro que se me ha informado que, para efectos de brindarme atención médica, autorizo expresamente a la IPS GRUPO MOK para recolectar, clasificar, almacenar, utilizar, archivar y cualquier otra modalidad de tratamiento a mis Datos Personales, incluyendo mis Datos Sensibles, conforme a las finalidades establecidas en la Política de Tratamiento de Datos Personales de GRUPO MOK anexo al presente consentimiento, la cual puede ser modificada por la IPS sin previo aviso; en caso que el paciente sea menor de 18 años y/o incapaz, su representante legal autoriza expresamente a la IPS GRUPO MOK para recolectar, clasificar, almacenar, utilizar, archivar y cualquier otra modalidad de tratamiento a sus Datos Personales, incluyendo sus Datos Sensibles conforme a las finalidades establecidas en la Política de Tratamiento de Datos Personales de GRUPO MOK anexo al presente consentimiento, la cual puede ser modificada por la IPS sin previo aviso. {dobleEnter}
            9. Entiendo, acepto y autorizo, como titular de mis datos biométricos y en particular de mi imagen, a la IPS GRUPO MOK al tratamiento de las imágenes que me sean tomadas en el desarrollo del objeto de la prestación del servicio de telemedicina. {dobleEnter}
            10. Entiendo, acepto y autorizo a la IPS GRUPO MOK para transferir y/o transmitir mis datos personales con la finalidad específica de cumplir con la prestación del servicio de telemedicina. {dobleEnter}
            11. Entiendo y acepto que la IPS GRUPO MOK no grabará la consulta, quedando como único registro de la misma la Historia Clínica del paciente, la cual será resguardada con los niveles de seguridad exigidos por la Constitución Política y las leyes sobre esa naturaleza.  {dobleEnter}
            12. Fui informado de que, en caso de querer actualizar, rectificar o suprimir los datos que he suministrado, puedo enviar un correo electrónico a la dirección protecciondedatos@grupomok.com, donde se le dará trámite a mi solicitud de conformidad con lo establecido en la Ley 1581 de 2012 y demás normas concordantes. 
            {dobleEnter}
            <b>En caso de que el paciente sea menor de 18 años y/o incapaz, este consentimiento será aceptado por su representante legal</b>
            {dobleEnter}
            He comprendido todo lo anterior, y por ende otorgo mi Consentimiento en nombre del paciente menor de edad y/o incapaz, para que se le preste el servicio de Consulta Externa bajo la modalidad de Telemedicina. 
            {dobleEnter}
            Si después de leer detenidamente esta información, está totalmente de acuerdo con lo aquí comunicado, por favor proceda a ingresar en el siguiente link para dar inicio al servicio de Telemedicina.
            </p>
          </React.Fragment>):(
          <React.Fragment>
            <h4 className="tittle-modal-bene pt-1 ml-0">CONSENTIMIENTO INFORMADO CONSULTA TELEMEDICINA PARA MENOR DE EDAD</h4>
            <p className="overflow-scroll  text-justify">
              De acuerdo con la Ley Estatutaria 1581 de 2012 de Protección de Datos y con el Decreto 1377 de 2013 y demás normas concordantes al dar click en “aceptar”, en calidad de padre, representante o tutor legal del menor: <strong>{info.nombres} {info.apellidos}</strong>, identificado con T.I. No.: <strong>{info.identificacion}</strong>, Declaro en nombre de mi representado, en pleno uso de mis facultades mentales que hemos recibido una explicación y descripción clara sobre el servicio de Consulta bajo la modalidad de Telemedicina que va a recibir, mediante la cual un Profesional de la Salud, haciendo uso de una herramienta de videollamada, hará una valoración del estado de salud del menor de edad, con el fin de obtener un concepto médico y recomendar el curso de acción que considere necesarios para la atención solicitada.
            </p>
            <p className="overflow-scroll  text-justify">
              Adicionalmente entendemos que este servicio no tiene como objeto la atención de casos de urgencias o emergencias vitales en las cuales pueda estar en riesgo la vida del paciente y entendemos claramente que en estos casos es necesario dirigirse a un centro de atención de urgencias médicas de forma inmediata.
            </p>
            <p className="overflow-scroll  text-justify">
              <strong>Derechos y deberes del paciente</strong><br/>
              Entendemos que como usuario del servicio de telemedicina se tienen los siguientes derechos:
              <ul>- A recibir información sobre mi salud, trámites administrativos y demás requisitos para la prestación del servicio por medio de telemedicina.</ul>
              <ul>- A recibir un trato humanizado, cordial, respetuoso y privado.</ul>
              <ul>- A exigir respeto por mis creencias, religión, costumbres, hábitos e identidad sexual.</ul>
              <ul>- A presentar quejas, reclamos, sugerencias y felicitaciones de los servicios prestados.</ul>
              <ul>- A que mis datos sean tratados con reserva y confidencialidad.</ul>
            </p>
            <p className="overflow-scroll  text-justify">
              Adicionalmente, aceptamos y entendemos que se tienen los siguientes deberes irrevocables para el desarrollo de los servicios por medio de telemedicina:
              <ul>- Brindar información veraz y completa a los Profesionales de la Salud para el desarrollo de mi atención.</ul>
              <ul>- Cumplir con las políticas y reglas del prestador de los servicios de salud.</ul>
              <ul>- En caso de asistencia para menores de edad, contar con la presencia de uno de sus padres o tutor.</ul>
            </p>
            <p className="overflow-scroll  text-justify">
              <strong>Autorización uso de la información y consentimiento informado</strong><br/>
              Manifiesto que WTA IPS, me ha informado que los datos personales solicitados durante el desarrollo de esta asistencia serán tratados para los fines relacionados con su objeto social y en especial para fines legales, contractuales, comerciales de acuerdo con lo establecido en nuestra Política de Tratamiento de Datos Personales y dando estricto cumplimiento con lo ordenado por la Ley 1581 de 2012 y el Decreto 1377 de 2013 de la República de Colombia.
            </p>
            <p className="overflow-scroll  text-justify">
              WTA IPS garantiza la confidencialidad, libertad, seguridad, veracidad, transparencia, acceso y circulación de mis datos y en caso de solicitudes respecto a los datos recolectados, puede seguir el procedimiento definido en la Política de Tratamiento de Datos Personales de la IPS que encuentra en nuestra página web. (https://wtaips.com)
            </p>
            <p className="overflow-scroll  text-justify">
              Si después de leer detenidamente esta información, está totalmente de acuerdo con lo aquí comunicado, por favor proceda a ingresar en el siguiente link para dar inicio al servicio de Telemedicina.
            </p>
          </React.Fragment>)}
          
          </div>
          <div className="text-center">
          <button className="btnModal pl-5 pr-5" onClick={handleClose}>
            Aceptar
          </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}