import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";

export default function ModalSaveBene(props) {
    const { showM, clickClose, typeTitle } = props;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow= () => setShow(true);
    return (
      <React.Fragment>
         <a className="hover mr-1 text-left disclamersFooter col-10" onClick={handleShow} style={{cursor:"pointer",color: "#006dbc",fontSize:"14px",textDecorationLine:"underline",paddingLeft:"28px"}}>Cambio de Beneficiarios</a>
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          className="contendeorModalBeneficiario"
        >
          <Modal.Body style={{width: "102%", borderRadius: "8px !important"}}>
            <div className="container">
              <div className="row">
                <div className="pr-4 pl-4 pb-4 pt-2" style={{paddingTop:".5rem !important"}}>
                  <div className="text-center mb-2">
                    <h4 className="tittle-modal-bene pt-1" >
                      Cambio de Beneficiarios
                    </h4>
                    <p className="pl-4 pr-4 pt-3" style={{fontFamily: "Helvetica", fontSize: "14px", color: "#808285",lineHeight: "1.8", height: "190px", textAlign:'left'}}>
                        De conformidad las normas que regulan el Hábeas Data y el tratamiento de datos personales: En mi calidad de titular de los servicios del ecosistema, -al registrar o realizar cambios que impliquen modificaciones en la información de los beneficiarios-, asumo la responsabilidad sobre la veracidad de los mismos, a la vez que autorizo a contactar a éstos, al número de teléfono que registro en la presente página, a efectos de suministrarles información de su designación y de las condiciones del servicio.
                    </p>
                    <button className="btnAzul" style={{ borderRadius: "5px",width: "176px",backgroundColor: '#323B4E',fontFamily:'Next' }} onClick={handleClose}>Aceptar</button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }