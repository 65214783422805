import * as CryptoJS from "crypto-js";
import { urlAPI, urlInstafit } from '../utils/config';
import Axios from "axios";
import Jwt from "jsonwebtoken";
import React from 'react';

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export function onNavigate(e, _coursera) {
  if(e!=null)e.preventDefault();
  Axios.get(`${urlAPI}/coursera/obtenerLink?id=${getUser()["idUsuario"]}&coursera=${_coursera}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then(succ => {
    window.open(succ.data, "_blank");
  }).catch(error => {
    //console.log(error);
  });
};

export const getSchedules = async (typeShedulings) => {
  const currenDate = new Date();
  var listFinal = [];
  let res = await Axios.get(`${urlAPI}/agendamientos/agendas/${getUser().idUsuario}`, { headers: { 'Content-Type': 'application/json', Authorization: getToken() } })
  const { data } = res.data;
  if (data != null) {
    data.forEach((sheduling, i) => {
      typeShedulings.forEach((typeSheduling, i) => {
        if (sheduling.idAgendaTipo.toString() === typeSheduling.value) {
          if (new Date(`${new Date(sheduling.fechaAgenda).toDateString()} ${sheduling.horaAgenda}`).toLocaleString('es-Co', { day: '2-digit', month: '2-digit', year: 'numeric' }) >= currenDate.toLocaleString('es-Co', { day: '2-digit', month: '2-digit', year: 'numeric' }) || new Date(sheduling.fechaAgenda).getFullYear() > currenDate.getFullYear()) {
            listFinal.push(sheduling);
            //console.log(listFinal.length !== 0 ? "true" : "false")
            return true;
          }
        }
      });
    });
  } else {
    return false;
  }
  return listFinal.length > 0 ? true : false;
};


export function irInstafit(e) {
  if(e!=null)e.preventDefault();
  Axios.get(`${urlAPI}/Profile/InfoGeneral`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then(infoProfile => {
    Axios.get(`https://ipapi.co/json/?key=vgn91pD3PXJhodzX3llb7PGxJpyemvUhamyxnhm8fTSvNJ9J5P`, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then(succ => {
      let url = urlInstafit;
      let obj = {
        email: infoProfile.data.data.correoElectronico.toLowerCase(),
        api_version: "v3.3",
        country: succ.data["country_name"],
        country_code: succ.data["country_code_iso3"],
        country_purchase: null,
        genre: "hombre",
        language: "es",
        name: infoProfile.data.data.nombres,
        platform: 3,
      };
      (async () => {
        let succ = await Axios.get(`${urlAPI}/instanfit/InstanfitAutenticacionLink`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          },
        });
        if (succ.data === "email ya está en uso") {
          url = url + getUser()["instafitUrl"];
        } else {
          url = succ.data;
        }
        setTimeout(function(){
          window.open(url, "_blank");
        }, 3000) 
      })();
    }).catch(error => {
      //console.log(error);
    });
  });
};
export function irInstafit1(e) {
  if(e!=null)e.preventDefault();
  Axios.get(`${urlAPI}/Profile/InfoGeneral`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then(infoProfile => {
    Axios.get(`https://ipapi.co/json/?key=vgn91pD3PXJhodzX3llb7PGxJpyemvUhamyxnhm8fTSvNJ9J5P`, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then(succ => {
      let url = urlInstafit;
      let obj = {
        email: infoProfile.data.data.correoElectronico.toLowerCase(),
        api_version: "v3.3",
        country: succ.data["country_name"],
        country_code: succ.data["country_code_iso3"],
        country_purchase: null,
        genre: "hombre",
        language: "es",
        name: infoProfile.data.data.nombres,
        platform: 3,
      };
      (async () => {
        let succ = await Axios.post(`${urlAPI}/instanfit/InstanfitAutenticacionLinkVentas`, JSON.stringify(obj), {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          },
        });
        if (succ.data === "email ya está en uso") {
          url = url + getUser()["instafitUrl"];
        } else {
          url = succ.data;
        }
        window.open(url, "_blank");
      })();
    }).catch(error => {
      //console.log(error);
    });
  });
};

export function irTributi(e) {
  if(e!=null)e.preventDefault();
  Axios.get(`${urlAPI}/tributi/obtenerLink?id=${getUser()["idUsuario"]}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then(succ => {
    window.open(succ.data.data, "_blank");
  }).catch(error => {
    //console.log(error);
  });
}
export function encryptDecrypt(password, keyPass) {
  var key = keyPass.split("");
  var output = [];

  for (var i = 0; i < password.length; i++) {
    var charCode = password.charCodeAt(i) ^ key[i % key.length].charCodeAt(0);
    output.push(String.fromCharCode(charCode));
  }
  return output.join("");
}

export function isTokenExpiration() {
  var tokenJwt = localStorage.getItem("jwt");
  var jwtError = null;
  Jwt.verify(tokenJwt, 'Db', function (err) {
    if (err) {
      jwtError = err;
    }
  });
  return jwtError !== null ? jwtError.name === 'TokenExpiredError' ?? true : false;
}

export function getUser() {
  var user = null;
  if (isActive()) {
    user = JSON.parse(
      CryptoJS.AES.decrypt(
        window.localStorage.getItem("user"),
        "eco_scotia"
      ).toString(CryptoJS.enc.Utf8)
    );
    user["idUsuario"] = window.atob(user["idUsuario"]);
  }
  return user;
}
export function isActive() {
  return window.localStorage.getItem("token") &&
    window.localStorage.getItem("user")
    ? true
    : false;
}
export function closeSession() {
  window.localStorage.clear();
  window.location.href = "/";
}

export function getToken() {
  return CryptoJS.AES.decrypt(
    window.localStorage.getItem("token"),
    "eco_scotia"
  ).toString(CryptoJS.enc.Utf8);
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/inicio");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function addLogs(pagina, control, extra) {
  var logUser = {
    idUsuario: 0,
    ciudad_localizacion: '',
    fechaServidor: '',
    horaServidor: '',
    fechaLocal: '',
    horaLocal: '',
    direccion_ip: '',
    pagina: pagina,
    control: control,
    extra: extra,
  };
  if (getUser() === null) {
    logUser.idUsuario = 0
  } else {
    logUser.idUsuario = getUser().idUsuario;
  }
  let date = new Date();
  logUser.fechaLocal = `${date.getDate()}/${(date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}/${date.getFullYear()}`;
  logUser.horaLocal = `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
  Axios.get('https://ipapi.co/json/?key=vgn91pD3PXJhodzX3llb7PGxJpyemvUhamyxnhm8fTSvNJ9J5P'
  ).then(succ => {
    logUser.direccion_ip = succ.data.ip;
    logUser.ciudad_localizacion = succ.data.city;
    Axios.post(`${urlAPI}/logUser/inserta`, JSON.stringify(logUser), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      }
    }).then(success => {

    }).catch(error => {
      //console.log(error);
    });
  }).catch(error => {
    //console.log(error);
  });
}

export function addLogList(arrParamLogUser = [{}]){
  
  const logUserArray = []
  let isHomeIn = false;
  const defaultLogUser = () => {
    return{
      idUsuario:0,
      ciudad_localizacion:'',
      fechaServidor:'',
      horaServidor:'',
      fechaLocal:'',
      horaLocal:'',
      direccion_ip:'',
      pagina:'',
      control:'',
      extra:'',
      resultado:'',
      identificador:''
    }
  }

  arrParamLogUser.forEach((log) => {
    const {pagina,control,extra,resultado,identificador} = log
    const logUser = defaultLogUser();
    logUser.pagina = pagina
    logUser.control = control
    logUser.extra = extra
    logUser.resultado = resultado
    logUser.identificador = identificador
    if(getUser() === null){
      logUser.idUsuario = 0
    }else{
      logUser.idUsuario = getUser().idUsuario;
    }
    let date = new Date();
    logUser.fechaLocal = `${date.getDate()}/${(date.getMonth()+1)<10?`0${date.getMonth()+1}`:date.getMonth()+1}/${date.getFullYear()}`;
    logUser.horaLocal = `${date.getHours()<10?'0'+date.getHours():date.getHours()}:${date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes()}`; 
    logUserArray.push(logUser)
    if (logUser.extra !== "Home In") isHomeIn = false;
    else isHomeIn = true;
  });
  
  if(logUserArray.length <= 0) return

  getGeoMok().then(succ=>
    {
      logUserArray.forEach((log)=>{
          log.direccion_ip = succ.data.ip
          log.ciudad_localizacion = succ.data.city
        })
        if (isHomeIn) {
          Axios.get(`${urlAPI}/Profile/InfoGeneral`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + getToken(),
            }
          }).then(info => {
            logUserArray.forEach((log) => {
              log.identificador = info.data.data.identificacion
            })
          }).catch(error => {
          });
        }
        Axios.post(`${urlAPI}/logUser/insertaLista`,JSON.stringify(logUserArray),{
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          }
        }).then(success=>{
        }).catch(error=>{
          //console.log(error);
        });
    }).catch(error=>
    {
      //console.log(error);
    });
  
}

export function getNavegator() {
  let listNavigator = ['safari', 'chrome', 'edg', 'opera', 'msie', 'firefox'];
  var dispositivo = navigator.userAgent.split(" ");
  var infoNavegator = navigator.userAgent.toLowerCase();
  var final;
  listNavigator.forEach((e, i) => {
    if (infoNavegator.includes(e)) {
      final = `${e.charAt(0).toUpperCase() + e.slice(1)} en ${dispositivo[1].substr(1) === 'Linux;' ? 'Android' : dispositivo[1].substr(1)}`;
    }
  });
  return final;
}

export function parseDateHour(date, hour) {
  let [month, day2, year] = date.split("-");
  let dateFull = new Date(`${day2}-${month}-${year?.split(" ")[0]} ${hour}`);
  return (<><span style={{ textTransform: "capitalize" }}>{dateFull.toLocaleString('es-Co', { weekday: 'long' })}</span>, {dateFull.toLocaleString('es-Co', { day: '2-digit' })} de <span style={{ textTransform: "capitalize" }}>{dateFull.toLocaleString('es-Co', { month: 'long' })}</span> {dateFull.getFullYear()} <br /> <span >A partir de las <span style={{ textTransform: "capitalize" }}>{dateFull.toLocaleString('es-CO', { hour: 'numeric', minute: '2-digit', hour12: true })}</span></span></>);
};

export function passwordSecurity(texto,dis){
  let cadena
  switch (dis) {
    case dis = 1:
      cadena ="0123456789";
      break;
    case dis = 2:
      cadena ="abcdefghyjklmnñopqrstuvwxyz";
      break;
    case dis = 3:
      cadena ="abcdefghyjklmnñopqrstuvwxyz".toUpperCase();
      break;
    case dis = 4:
      cadena ="\@$!%*?&¿/#,;¡ñ-+.'";
      break;
    default:
      break;
  }
  if(texto !== undefined){
    for(var i=0; i<texto.length; i++){
      if (cadena.indexOf(texto.charAt(i),0)!=-1){
        return 1;
      }
     }
  }
  return 0;

}

export function validatePassword(pass) {
  const re =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&¿/#,;+.'ñ-])([A-Za-z\d$@$!%*?&¿/#,;+.'ñ-]|[^ ]){8,16}$/;
  return re.test(pass);
}

export function addLog (pagina, control, extra, resultado, identificador = 0) {
  let identificacion = localStorage.getItem('ccin') !== null ? localStorage.getItem('ccin') : identificador;
  const logUserObj = {
      pagina: pagina,
      control: control,
      extra: extra,
      resultado: resultado,
      identificador: identificacion,
  }

  return logUserObj;
}

export const encryptData = (data) => {
  if (typeof data !== 'object') {
        throw new Error('Los datos deben ser un objeto JavaScript.');
  }

  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
  const base64Text = btoa(ciphertext);
  return base64Text;
}


export const getGeoMok = async () => {
  try {
      return await Axios.get(`https://geomok.grupomok.com.co/json`,{
        timeout: 2500
      });
  } catch (error) {
      return {data:{
          "ip":"10.30.40.132",
          "version":"IPv4",
          "city":"",
          "country":"",
          "country_code":"",
          "country_calling_code":"",
          "time_zone":"",
          "navegator":"Chrome",
          "operating_system":"Windows"
      }}
  }
};