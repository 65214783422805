import { useState } from 'react';
import { validateEmail, addLogList, validatePassword } from '../utils/validations';

export const useForm = (formulario) => {
    const [state, setState] = useState(formulario);
    const [errorPhone, setErrorPhone] = useState('')
    const [errorEmail, setErrorEmail] = useState('')
    const [errorTerminos, setErrorTerminos] = useState('')
    const [errorPoliticas, setErrorPoliticas] = useState('')
    const [errorPassword, setErrorPassword] = useState(false);
    
    const addLog = (pagina, control, extra, resultado, identificador) =>{
        const logUserObj = {
        pagina:pagina,
        control:control,
        extra:extra,
        resultado:resultado,
        identificador:identificador,
        }
        return logUserObj
    }
    const onChange = (value, campo) => {
        setState({
            ...state,
            [campo]: value
        });
    }
    const onChangeEmail = (value, type) => {
        value = value?.replace(' ','');
        setState({ ...state, correo: value});
        if(type) return
        if(value.includes('**')) value = ''
        
        let messageError = ''
        messageError = 
        value === '' ? 'Debe completar el campo' :
        !validateEmail(value) ? 'El correo no es valido':
        value.split(/[@]/gi).length > 2 ? 'El correo debe contener un solo arroba (@).' :
        value.includes(' ') ? 'El correo no debe incluir espacios.' : ''
        setState({ ...state, correo: value});
        setErrorEmail(messageError)
    }
    const onChangePhone = (value, type) => {
        setState({...state,telefono: value});
        if(type) return
        if(value.includes('**')) value = ''
        value = value.replace(/[^0-9]/g,'');
        let phoneCaracters = /[0-9 ]$/;
        let messageError = '';
        messageError = 
        value === '' ? 'Debe completar el campo' :
        !value.startsWith(3) ? 'El número ingresado es inválido, debe iniciar con 3.' :
        value.includes(' ') ? 'El número celular no debe incluir espacios.':
        value.length != 10 ? 'La longitud debe ser de 10 dígitos.' :
        !phoneCaracters.test(value) ? 'El número ingresado es inválido.' : ''
        setState({...state,telefono: value});
        setErrorPhone(messageError)
    }
    const onChangeTerminos = (value) => {
        setState({ ...state, chkTC: value });
        let messageError = ''
        messageError = value ? '' :  'Debe aceptar los términos y condiciones'
        setErrorTerminos(messageError)
    }
    const onChangePoliticas = (value) => {
        setState({ ...state, chkPL: value });
        let messageError = ''
        messageError = value ? '' :  'Debe aceptar las política de privacidad del servicio'
        setErrorPoliticas(messageError)
    }
    const validateFieldsRegister = (documento) =>{
        const logUserList = []
        logUserList.push(addLog('Validación Boton Crear Cuenta', `Boton Crear Cuenta`, 'Registro', 'Exitoso', documento))
        let validate = true
        if(errorPhone != '' || state.telefono == ''){
            logUserList.push(addLog('Validación Telefono', `Invalido : ${state.telefono}`, 'Registro', 'Error', documento));
            onChangePhone(state.telefono)
            validate = false
        }
            
        if(errorEmail != ''  || state.correo == ''){
            logUserList.push(addLog('Validación Correo', `Invalido : ${state.correo}`, 'Registro', 'Error', documento));
            onChangeEmail(state.correo)
            validate = false;
        }
        
        if(errorTerminos  || !state.chkTC){
            logUserList.push(addLog('Validación Acepta Terminos', `Invalido : ${state.chkTC}`, 'Registro', 'Error', documento));
            onChangeTerminos(state.chkTC)
            validate = false;
        }
        
        if(errorPoliticas  || !state.chkPL){
            logUserList.push(addLog('Validación Acepta Politicas', `Invalido : ${state.chkPL}`, 'Registro', 'Error', documento));
            onChangePoliticas(state.chkPL)
            validate = false;
        }
            
        if(!validatePassword(state.clave)  || !state.clave){
            logUserList.push(addLog('Validación Contraseña', `Invalido : ${false}`, 'Registro', 'Error', documento));
            setErrorPassword(true);
            validate = false;
        }
        
        addLogList(logUserList);
        return validate;

    }
    const validateFormRegister = (type, document) => {
        const logUserList = []
        switch (type){
            case 1: 
                logUserList.push(addLog('Validación Modal Espera', 'Validado', 'Registro', 'Exitoso', document))
                break;
            case 2:
                logUserList.push(addLog('Validación Formulario Registro', 'Formulario de Registro Cargado', 'Registro', 'Exitoso', document))
                break;
        }
        addLogList(logUserList);
    }
    

    return {
        ...state,
        setState,
        errorPhone,
        errorEmail,
        errorTerminos,
        errorPoliticas,
        errorPassword,
        setErrorPassword,
        onChange,
        onChangeEmail,
        onChangePhone,
        onChangeTerminos,
        onChangePoliticas,
        validateFieldsRegister,
        validateFormRegister,
    }
}