import React,{useState} from "react";
import Tooltips from "../tooltips/Tooltips";
import borrar from "../../assets/img/icon/standard/delete.svg";
import flechaAbajo from "../../assets/img/scotia-vida-img-025.svg";
import agregar from "../../assets/img/icon/standard/scotiabank-ico-new-user.svg";


export default function CardBeneficiary(props) {
    const { typeView, beneficiary,click,clickEdit,clickDelete,idView } = props;
    const [info,setInfo] = useState(beneficiary);
    var toolText = 
    <p style={{color:"#FFFFFF",paddingLeft:"5px",fontFamily: 'inter-regular'}}>
        Si tu beneficiario es un hijo menor de 18 años, te solicitaremos una autorización expresa por parte de sus tutores o padres, para acceder al servicio<a style={{color:"#FFFFFF",paddingLeft:"5px",fontFamily: 'inter-bold'}}>Médico a un clic.</a>
        </p> 
    return (
        <React.Fragment>
            {typeView === 'notNone' && (
                <div id={`cardB-${idView}`} className="col-12 mt-2 usuarioGris pb-2 pt-2">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-sm-12 col-lg-6 pl-0"style={{position:"relative"}} >
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                    <span className="textosBeneficiarios mr-3">
                                        {info.nombres} {info.apellidos}
                                    </span>
                                    </div>
                                    <div className="col-lg-2 col-md-12 alingText">
                                        <Tooltips keyTool={1} idBtn="btnIconInfoBeneficiary" bsPrefix="tooltip-change">{toolText}</Tooltips>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <div className="row contentJustify">
                                    <div className="d-inline mr-3">
                                        <button className="btnEliminarBeneficiario mr-4" onClick={clickDelete}>
                                            <span className="mr-3">
                                                <img className="imgEliminar" alt="rectangulo" />
                                            </span>
                                            <span className="textosBeneficiarios">Eliminar</span>
                                        </button>
                                    </div>
                                    <div className="d-inline">
                                        <button style={{ minWidth: "68px" }}  className="btnEditarBeneficiario textosBeneficiarios" onClick={clickEdit}>
                                            Editar o confirmar
                                        </button>
                                        <button className="btnDown" onClick={clickEdit}></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {typeView === 'none' && (
                <div className="col-12 mt-2 usuarioNuevo pt-2 pb-2">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col">
                                <span className="textosBeneficiarios mr-3">
                                    Sin beneficiario
                            </span>
                            </div>
                            <div className="col">
                                <div className="row justify-content-end estilo-agregar">
                                    <div className="d-inline" style={{cursor: 'pointer'}} onClick={()=>{click(true)}}>
                                        <img className="mr-1" src={agregar} alt="Nuevo beneficiario" />
                                            <a style={{ minWidth: "180px",color: '#58595B' }} className="estilo-agregar btnNuevoBeneficiario textosBeneficiarios pl-2" >Agregar nuevo beneficiario</a>
                                        <button className="btnDown" ></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}