import React, { useState, useEffect } from "react";
import { urlAPI } from "../../utils/config";
import { getUser, getToken, addLog, addLogList } from "../../utils/validations";
import CardService from "../../components/card-service/CardService";
import Axios from "axios";
import Tooltips from "../../components/tooltips-home/TooltipsHome";
import { isMobile } from "react-device-detect";

import Loader from "../../components/loader/Loader";

import ModalHomeIn from '../../components/modal/ModalHomeIn';
import { set } from "lodash";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import ModalCyber from "../../components/modal/ModalCyberSecurity";
import { Carousel } from "react-bootstrap";



export default function HomeIn() {
  const [userSession, setUserSession] = useState({});
  const [count, setCount] = useState(0);
  const [loaderShow, setLoaderShow] = useState(false); 
  const [validationsFirst, setValidationsFirst] = useState(true);
  const [update, setUpDate] = useState(false);
  const [showBeneSmall, setShowBeneSmall] = useState(false);  
  const [identificationUser, setIdentificationUser] = useState('0')
  const [infoCards, setInfoCards] = useState([])
    

  const informationView = [{
    id: 0,
    text: "Al hacer clic en alguno de nuestros servicios, podrás comenzar a usarlos.",
    step: "1 de 3",
    arrowLeft: "N",
    arrowRight: "S",
  }, {
    id: 1,
    text: "Aquí puedes acceder a cualquiera de tus servicios de forma rápida. ",
    step: "2 de 3",
    arrowLeft: "S",
    arrowRight: "S",
  }, {
    id: 2,
    text: "Aquí puedes actualizar tu información personal.",
    step: "3 de 3",
    arrowLeft: "S",
    arrowRight: "N",
  }]
  useEffect(() => {
    const logUserList = [];
    if (count === 0) {
      setLoaderShow(true);
      Axios.get(`${urlAPI}/Profile/InfoGeneral`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        }
      }).then(succ => {
        setIdentificationUser(succ.data.data.identificacion)
        logUserList.push(addLog('Validación Home In', 'Servicios cargados', 'Login', 'Exitoso'));
        setUserSession(succ.data.data);
        if (succ.data.codigo == 0 && !window.localStorage.getItem('smaller')) {
          setShowBeneSmall(succ.data.data.dicTipoDocumento === 8 && true);
        }
        Axios.get(`${urlAPI}/Profile/Primeravez`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          }
        }).then(succ => {
          setValidationsFirst(succ.data.data);
        }).catch(error => {
          //console.log(error);
        });
      }).catch(error => {
        //console.log(error);
      });
      // var infoAux = [];
      // //console.log(getUser().perfiles);
      //   getUser().perfiles.forEach((e,i)=>{
      //     //console.log(e);
      //     if(e !== 'beneficiario'){
      //       infoAux.push(informationCards.find(x=>x.idCard.toLowerCase().trim().includes(e.toLowerCase().trim())));
      //     }
      //     if( e.includes('Telemedicina b') && !e.includes('telemedicina')){
      //       //console.log('uno');
      //       infoAux.push(informationCards.find(x=>x.idCard == 'telemedicina'));
      //     }
      //     if (e == 'orientaciones medicas y psicologicas'){
      //       infoAux.push(informationCards.find(x=>x.idCard == 'orientaciones'));
      //     }
      // });
      // infoAux = infoAux.filter(function(dato){
      //   return dato != undefined
      // });
      // var infoCardsVida = []
      // infoAux.forEach((e)=>{
      //   if(e != undefined)
      //   {
      //     if(e.idCard==="telemedicina"){infoCardsVida[1]=e}
      //     else if(e.idCard==="instafit"){infoCardsVida[0]=e}
      //     else if(e.idCard==="tributi"){infoCardsVida[2]=e}
      //     else if(e.idCard==="orientaciones"){infoCardsVida[3]=e}
      //     else if(e.idCard==="Coursera V" || e.idCard==="Instafit b" ){
      //       infoCardsVida.push(e)
      //     }

      //   }})      
      setLoaderShow(false);
      //Se arma el array con los servicios que llegan del back.
      var productos = getUser().perfiles;
      productos.forEach((e) => {
        var obj = JSON.parse(e);
        infoCards.push(obj);
      });
      setTimeout(() => {
        if (productos.length === 0) {
          logUserList.push(addLog('Validación Home In', 'Servicios cargados', 'Login', 'Error', identificationUser))
        }
        addLogList(logUserList)
      }, 2000);
    }
  }, [count]);

  const closeTooltips = () => {
    Axios.get(`${urlAPI}/Profile/ActualizaPrimeravez`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      }
    }).then(succ => {
      setUpDate(succ.data);
    }).catch(error => {
      //console.log(error);
    });
  }
  return (
    <React.Fragment>
      <Link className="d-none">
        <ModalCyber />
      </Link>
      {loaderShow && (<Loader show={loaderShow} />)}
      {showBeneSmall && (<ModalHomeIn showM={showBeneSmall} clickClose={() => { setShowBeneSmall(false) }} />)}
      <div className="container">
        <div className="row" style={{ backgroundColor: "#F5F5F5" }}>
          <div className="col-lg-1"></div>
          <div className="col-lg-9 mt-3 mb-5 body-CardServices marginCardServices" >
            <div className="row mt-3">
              <div className="col-12"><h1 className="titulosCardsservicios mb-4 mt-4 marginCard">Hola, {userSession.nombres}</h1></div>
              <div className="col-12" style={{ marginBottom: "30px" }}>
                <p className="mt-2 marginCard" style={{ fontFamily: "Helvetica" }}>
                  {/*
                    getUser().perfiles.includes("Coursera V") || getUser().perfiles.includes("Instafit b")?
                    'Sabemos que eres única y, por eso, hemos personalizado tu Ecosistema de Servicios. Te protegemos y acompañamos en algunos de los momentos más importantes de tu vida. Aquí encuentras los servicios a los que puedes acceder gracias a tu seguro.'
                    :
                    'Descubre cómo, a través de tu Ecosistema de Servicios, la tranquilidad y la protección están al alcance de un clic. Estos son los servicios que tienes disponibles, solo por tener tu seguro vigente.'
                  */}
                  {
                    'Sabemos que eres única y, por eso, hemos personalizado tu Ecosistema de Servicios. Te protegemos y acompañamos en algunos de los momentos más importantes de tu vida. Aquí encuentras los servicios a los que puedes acceder gracias a tu seguro.'
                  }
                </p>
              </div>
            </div>
            {validationsFirst && !isMobile ? (
              <Tooltips closeTooltips={() => { closeTooltips() }} informationView={informationView} view={getUser().perfiles.length > 1 ? "2" : "1"} />
            ) : (<></>)}
            <div className="row">

            {infoCards.length > 0 ? (
                <Fragment>
                  {infoCards.map((e, i) => {
                    return (
                      <Fragment>
                        {
                          isMobile ?
                            <Fragment>
                              <Carousel key={e.name} interval={"5000"} controls={false} className="mt-5" touch={true}>
                                {e.service.map((s) => {
                                  return (
                                    <Carousel.Item key={s.servicename} className="carousel-item" >
                                      <CardService content={s} view={"1"} typeTooltip={"Service"} ecosystem={e.ecosystemname} />
                                    </Carousel.Item>
                                  )
                                })
                                }
                              </Carousel>
                            </Fragment>
                            :
                            e.service.map((s) => {
                              return (
                                  <CardService key={s.servicename} content={s} view={"1"} typeTooltip={"Service"} ecosystem={e.ecosystemname} ></CardService>
                              )
                            })
                        }
                      </Fragment>
                    );
                  })}
                </Fragment>) : null}
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </React.Fragment>
  );
}
