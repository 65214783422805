import React, { useRef, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import ContactHelp from "../../components/contact-help/ContactHelp";
import moreInfo from "../../assets/img/home-out-003.svg";
import imgPrincipal from "../../assets/img/home-out/bam-home-out-001.png";
import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import ModalCookies from "../../components/modal/ModalCookies";
import HomeServices from "../home/HomeServices";
import gif from '../../assets/img/home-out/gif/Banner-beneficios.gif'
import gifMobile from '../../assets/img/home-out/gif/Banner-beneficios-movil.jpg'
import { isMobile } from "react-device-detect";
import Background from '../../components/background/backgroung';
import ArrowLeft from "../../assets/img/home-out/Arrow-Derecho.png";
import imgFondoMob from "../../assets/img/home-out/mobile/ImageMobile.png";
import textittles from "../../assets/img/home-out/gif/textMobi.PNG";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#3434354",
    display: "flex",
    flexDirection: "column",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

let polServ = (
  <span style={{ color: "#FFF", textDecoration: "underline", fontFamily: 'OpenSans' }}>
    Política de privacidad y manejo de cookies.
  </span>
);
function cerrarCookies() {
  document.getElementById("contenedorCookies").style.display = "none";
}


export default function Home(props) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const classes = useStyles();
  const [form, setForm] = useState(formDefault());
  const [messageObj, setMessageObj] = useState(defaultMessageValue());
  const [width, setWidth] = useState(window.screen.width);

  function defaultMessageValue() {
    return {
      show: "",
    };
  }

  function ConoceMas() {
    window.scroll(750, 750);
  }

  function ConoceMasEE() {
    window.scroll(10, 10);
  }
  function formDefault() {
    return {
      type: "5",
      doc: "",
    };
  }

  const onChange = (e, name) => {
    setForm({ ...form, [name]: e.target.value });
  };
  const sumbit = (e) => {
    e.preventDefault();
    if (isEmpty(form.type) || isEmpty(form.doc)) {
      setMessageObj({ show: "Por favor ingresa los datos requeridos." });
    } else {
      window.location.href = `/registro?type=${form.type}&doc=${form.doc}`;
      setMessageObj({ show: "" });
    }
  };
  const informationView = [{
    id: 0,
    text: <p className="TexToolRegister">Para empezar a disfrutar de los servicios que tenemos para ti, haz clic en el botón <strong>”REGÍSTRATE”</strong>.</p>,
    step: "",
    arrowLeft: "N",
    arrowRight: "S",
    left: '82px',
    top: '96px',
    topIcon: '-9px',
    leftIcon: '46%',
    Mobleft: '16%',
    Mobtop: '365px',
    MobtopIcon: '155px',
    MobleftIcon: '46%',
    height: '165px',
    Mobheight: '165px',
  }]
  return (
    <div style={{ background: "#FFFFFF" }}>
      {/* <Background informationView={informationView} id={'home'}/> */}
      <div style={{ position: 'relative' }}>
        
        <img src={gif} className="d-lg-block d-md-block d-sm-none d-none" style={{ width: '100%' }} />
        {/* <img src={gifMobile} className="d-lg-none d-md-none d-sm-block d-block" style={{ width: '100%' }} /> */}
        <button className="btnUnirmeGif" onClick={() => { window.location.href = '/registro' }}></button>
        {width <768 && (
          <>
          <div className="camImgMobile">
              <img src={imgFondoMob} className="fondoMobi"/>
            <div className="InfoMobi text-center">
              <img src={textittles} className="tittlesSty"/>
              {/* <h1 className="tittleOne">CUIDADO Y PROTECCIÓN</h1>
              <h4 className="tittleTwo">EN CADA DÍA DE TU VIDA</h4>
              <p className="parrMobile">Bienvenida al ecosistema de<br/> Servicios creados para ti, solo<br/> por activar tu seguro</p> */}
            </div>
            {/* <div className="btnQUMobi"> */}
              <button className="btnUnirmeMob" onClick={() => { window.location.href = '/registro' }}>QUIERO UNIRME</button>
            {/* </div> */}
          </div>
          </>
        )}
        <div className="tab-content cookies-content" id="contenedorCookies">
          <div id="coockies" className="justify-content-center d-flex">
            <div
              style={{
                backgroundColor: "transparent",
                width: "100%",
                zIndex: 9,
                padding: "0px 10px 10px 15px"
              }}
            >
              <div className="cookiealert-container " style={{ margin: '0 auto' }}>
                <div>
                  <div className="row">
                    <div className="col-1" style={{ padding: '0px' }}></div>
                    <div className="col-8" style={{ padding: '0px' }}>
                      <p style={{ color: " #D8D8D8", fontSize: 14, fontFamily: 'Helvetica', width: '100.1%' }}>
                        Este sitio usa cookies y otras tecnologías de seguimiento para fines de rendimiento,
                        análisis y marketing. Al usar este sitio web, acepta este uso. Obtenga más información
                        sobre estas tecnologías en la{" "}
                        <ModalCookies title={polServ} />
                      </p>
                    </div>
                    <div>
                      <div className="justify-content-left d-flex mb-4" style={{ position: "relative", left: "30px" }}>
                        <button
                          type="button"
                          name="button"
                          className="btnTop2 acceptcookies"
                          style={{
                            color: "#fff",
                            width: 165,
                            height: 42,
                            border: "solid 1px #fff",
                            backgroundColor: "#ffffff00",
                            fontSize: '16px'
                          }}
                          data-dismiss="modal"
                          onClick={(e) => cerrarCookies()}
                        >
                          Aceptar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" >
          <div className="row more-info d-lg-block d-md-none d-sm-none d-none mb-4">
            <div className="mb-2 scrollConoceMasDiv">
              <img
                className="scrollConoceMas"
                src={moreInfo}
                alt="scrollConoceMas"  
                onClick={() => ConoceMas()}
              />
            </div>
            <div >
              <span className="info-text" >Conoce más</span>
            </div>
          </div>
        </div>

      </div>

      <HomeServices />

      <div className="container-fluid" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
        <div className="fondo">
          <div className="text-center" style={{ paddingTop: '1px' }}>
            {
              window.screen.width > 768 ?
                <h3 className="text-center col-lg-12 mt-5 tituloTresPasos" style={{ marginBottom: '80px' }}>
                  ¿Qué debo hacer para estar registrada?
                </h3>

                :
                <h3 className="text-center col-lg-12 mt-5 tituloTresPasos" style={{ marginBottom: '80px', fontSize: '26px' }}>
                  ¿Qué debo tener en cuenta para registrarme?
                </h3>
            }
          </div>
          <div className="row pb-5 mt-5 justify-content-center d-flex pddmbcard" style={{ maxWidth: '1366px', margin: 'auto' }}>
            <div className="col-sm-0"></div>
            <div className="col-lg-3 col-md-12 col-sm-12 mb-4">
              <div className="cardTresPasos redondeado" style={{ height: '100%', backgroundColor: "#FFFFFF" }}>
                <div class="contentImgHomeOut primerPaso-img" style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                </div>
                <div className="number numero">1</div>
                <div className="card-body pb-5 ml-2 " style={{ minHeight: '200px', padding: '0.005rem 1.35rem' }}>
                  <h4 className="tc" style={{ marginBottom: '0' }}>
                    Activa tu seguro
                    <br />
                    <br />
                  </h4>
                  <p className="card-text-tresPasos" style={{ color: '#474747', width: '95%' }}>
                    Debes tener un seguro activo de BNP Paribas Cardif para acceder sin costo a tu Ecosistema de Servicios.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 mb-4">
              <div className="redondeado ml-1 mr-1" style={{ height: '100%', backgroundColor: "#FFFFFF" }}>
                <div
                  className="contentImgHomeOut segundoPaso-img"
                  style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                </div>
                <div className="number numero">2</div>
                <div className="card-body pb-5 ml-2" style={{ minHeight: '200px', padding: '0.005rem 1.45rem' }}>
                  <h4 className="tc" style={{ marginBottom: '0' }}>
                    Regístrate
                    <br />
                    <br />
                  </h4>
                  <p className="card-text-tresPasos" style={{ color: '#474747', width: '102%' }}>
                    Diligencia el formulario que nos ayudará a conocerte más. Solo haz clic en el botón “Regístrate” e ingresa tu cédula, correo electrónico y
                    número celular.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 mb-4">
              <div className="redondeado" style={{ height: '100%', backgroundColor: "#FFFFFF" }}>
                <div
                  className="contentImgHomeOut tercerPaso-img"
                  style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                </div>
                <div className="number numero">3</div>
                <div className="card-body pb-5 ml-2" style={{ minHeight: '200px', padding: '0.005rem 0.8rem' }}>
                  <h4 className="tc" style={{ marginBottom: '0' }}>
                    Bienvenida a tu Ecosistema de Servicios
                  </h4>
                  <p className="card-text-tresPasos" style={{ color: '#474747', width: '100%' }}>
                    Después de registrarte y, dependiendo del tipo de seguro que tengas, podrás hacer uso de los servicios personalizados.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-0"></div>
          </div>
        </div>
      </div>


      <ContactHelp />
    </div>
  );
}
