import { countBy, truncate } from 'lodash';
import React , { useState, useEffect }from 'react';
import {  isActive, getToken} from '../../../utils/validations';
import ContainerService from "../../../components/container-service/ContainerService";
import ModalScore from "../../../components/modal/ModalScore";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion  from "react-bootstrap/Accordion";
import ProgressBar from "react-bootstrap/ProgressBar";
import Axios from "axios";
import { urlAPI } from '../../../utils/config';
import Loader from "../../../components/loader/Loader";



import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faChevronDown, faChevronUp   } from "@fortawesome/free-solid-svg-icons";

import iconDiagnostic from "../../../assets/img/score-financiero/icon-diagnostic.svg"
import iconScore from "../../../assets/img/score-financiero/icon-score.svg";
import volver from "../../../assets/img/score-financiero/volver-gray.svg";
import flecha from "../../../assets/img/score-financiero/arrow-up-gray.svg";
import menos from "../../../assets/img/score-financiero/ico-menos.svg";


export default function ScoreFinancial() {
    const [ state, setState ] = useState(false);
    const [stateButton, setStateButton] = useState(true) ;
    let [ cont, setCont] = useState(1);
    const [loaderShow,setLoaderShow] = useState(false);
    const [ contScore, setContScore ] = useState("");
    const [ form, setForm ] = useState({});
    const [ answer, setAnswer ] = useState({
        experienciaCrediticia: [],
        estadoPortafolio:  [],
        endeudamiento: [],
        habitoPago: [],
    });

    useEffect(()=>{
        window.scroll(0,0);
        
        if (window.location.pathname === "/fraude/auth/score-financiero" )
        {
           step(1)
        }
    });

    const linkbread=[{
        to:"/auth/inicio",
        tittle:"Inicio > "
    },
    {
        to:"/fraude/auth/score-financiero",
        tittle:"Tu score financiero >",
    },
    {
        to: cont === 2?"/fraude/auth/score-financiero/consulta/resultado":"",
        tittle: cont === 2?"Consulta":""
    }];

    const validarCheck = (e) =>{
        setState(e.target.checked);

    }

    const handleValidator = () =>{
        if(state){
            setStateButton(true);
        }else{
            setStateButton(false);
        }
    }

    const stepScore = (id) =>{
        setCont(3);
        setContScore(id);
        setLoaderShow(true);
        if(isActive()){
            setLoaderShow(true);
            Axios.get(`${urlAPI}/Profile/InfoGeneral`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getToken(),
                }
            }).then(succ => {
                let obj = {
                    "password": "Kq4@ubwMSxr=fS#S",
                    "username": "cardif_usr_prd",
                    "grant_type": "password",
                    "documentType": "1",
                    "document": succ.data.data.identificacion,
                    "lastName": succ.data.data.nombres.toString()+"  "+succ.data.data.apellidoPaterno.toString(),
                }
                Axios.post(`${urlAPI}/alertaFraude/experian/html`,JSON.stringify(obj),{
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                    }
                }).then(res =>{
                    //console.log(res.data.diagnosticInfo)
                    setAnswer({
                        experienciaCrediticia: res.data.diagnosticInfo.experienciaCrediticia.calificaciones,
                        estadoPortafolio:  res.data.diagnosticInfo.estadoPortafolio.calificaciones,
                        endeudamiento:  res.data.diagnosticInfo.endeudamiento.calificaciones,
                        habitoPago: res.data.diagnosticInfo.habitoPago.calificaciones,
                    });
                }).catch(errorRes =>{
                    //console.log(errorRes);
                });

            }).catch(error => {
                //console.log(error);
            });
        }
        setLoaderShow(false);
    }

    const step = (id) =>{
        setCont(id);
        if(isActive()){
            setLoaderShow(true);
            Axios.get(`${urlAPI}/Profile/InfoGeneral`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getToken(),
                }
            }).then(succ => {

                window.history.pushState(null,'','/fraude/auth/score-financiero/consulta/resultado')
                let obj = {
                    "document": succ.data.data.identificacion,
                    "lastName": succ.data.data.nombres.toString()+"  "+succ.data.data.apellidoPaterno.toString(),
                }
                //console.log(obj);
                Axios.post(`${urlAPI}/alertaFraude/experian/html`,JSON.stringify(obj),{
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                    }
                }).then(html =>{
                    setForm(html);
                    //console.log(html.data.scoreHTML);
                    document.getElementById("score").innerHTML = html.data.scoreHTML
                }).catch(errorhtml =>{
                    //console.log(errorhtml);
                }) 

            }).catch(error => {
                //console.log(error);
            });
        }
        setLoaderShow(false);
    }


    return(
        <React.Fragment>
            {loaderShow &&(<Loader show={loaderShow}/>)}
             <ContainerService tittle="Score Financiero" tittleSize="34px" linkBread={linkbread}>
            {cont === 1 &&(
                <div className="marginScore" >
                <p className="textScore" style={{ padding: "27px 0px 0px 0px"}}>
                Gracias a tu Seguro “Protección integral”  podrás saber con exactitud cuál es tu actual puntaje en materia crediticia.
                </p>
               <div className="checkbox-terminos" style={{ marginTop: "8%" }}>
                        <input type="checkbox" id="checkbox-terminos" name="terminos" checked={state} onChange={validarCheck} onClick={handleValidator}/>
                        <label for="checkbox-terminos"></label>
                        <a className="ml-3 textScore" style={{color:"#777777"}}>Autorizo la <ModalScore title="consulta, reporte y procesamiento de datos financieros a centrales de riesgo" disabled={stateButton}/></a>
                </div>
                <div className="text-center  mt-5 mb-5"><button className="btn btnScore" style={{ color:'#003ea9' ,backgroundColor: stateButton ? "#D8D8D8" : "#FFF", padding:"11px"}} disabled={stateButton} type="button" onClick={()=>{step(2)}}>COMENZAR</button></div>
                <p className="textScore" style={{fontSize: "14px", fontFamily:"OpenSans-regular"}}>
                    <strong style={{color:"#454648", fontFamily:"OpenSans-bold"}}>Importante:</strong>
                    <br/><br/>
                    El score es suministrado por Datacrédito; Central de Información Crediticia, con 40 años de experiencia en el mercado de soluciones de información. 
                    <br/><br/>
                    {/* El puntaje mostrado en este servicio NO es una garantía que las entidades financieras vayan a otorgar<br/>
                    un crédito, solamente corresponde al reporte suministrado por datacredito y no tiene relación alguna<br/>
                    con el score que cada Banco hace a sus clientes con sus propios puntajes y lineamientos. */}
                </p>
            </div>
            )}
            {cont === 2 &&(
                     <div className="marginScoreGraph">
                         <p  className="textScore mb-5 ml-2 conoText" style={{ padding:"15px 0px 10px 0px"}}> Conoce tu puntaje financiero y aprende cómo mejorar tu perfil crediticio:</p>
                          <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <Card className="card mb-5 cardScoreDetalle1">
                                 <Card.Header className="titleCardsScore colorBack">
                                    <img src={iconScore} alt="Score" className="mr-2"/>Score de crédito
                                 </Card.Header>
                                 <Card.Body>
                                     <div id="score"></div>
                                 </Card.Body>
                                 <Card.Body>
                                     <p className="textScore" style={{color:"#58595B",fontSize:"12px"}}>
                                     *Importante: El score de crédito es un valor numérico entre 150 y 950
                                     </p>
                                 </Card.Body>
                            </Card>
                         </div> 
                         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <Card className="card mb-5 cardScoreDetalle" style={{ boxShadow: "none"}}>
                                 <Card.Header className="titleCardsScore colorBack">
                                 <img src={iconDiagnostic} alt="diagnostico" className="mr-2"/> Diagnóstico y recomendaciones
                                 </Card.Header>
                                 <Card.Body className="mobCard" style={{margin:"2px 17px 25px 17px"}}>
                                    <p className="textScore" style={{color:"#666666",marginBottom:"40px"}}>Conoce el diagnostico de tu score:</p>
                                    <ListGroup className="botonera list-group-flush" style={{ borderRadius: "4px"}}>
                                        <ListGroup.Item id="borderCard2" className="textListGroup score-cuadro mobileOpt" onClick={()=>{stepScore("1")}}>
                                            <div className="row">
                                                <div className="col-10 ">Estado de tu portafolio</div>
                                                <div className="col-2 text-right styleBtn"><FontAwesomeIcon icon={faAngleRight} style={{color:"#00448C"}} /></div>
                                            </div>
                                        </ListGroup.Item> 
                                        <ListGroup.Item className="textListGroup score-cuadro mobileOpt" onClick={()=>{stepScore("0")}}>
                                        <div className="row">
                                            <div className="col-10">Experiencia crediticia</div>
                                            <div className="col-2 text-right  styleBtn"><FontAwesomeIcon icon={faAngleRight} style={{color:"#00448C"}} /></div>
                                        </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="textListGroup score-cuadro mobileOpt" onClick={()=>{stepScore("2")}}>
                                        <div className="row">
                                            <div className="col-10">Endeudamiento</div>
                                            <div className="col-2 text-right  styleBtnc"><FontAwesomeIcon icon={faAngleRight} style={{color:"#00448C"}} /></div>
                                        </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="textListGroup score-cuadro optionScore1 mobileOpt" onClick={()=>{stepScore("3")}}>
                                        <div className="row">
                                            <div className="col-10 ">Hábitos de pago</div>
                                            <div className="col-2 text-right  styleBtnc"><FontAwesomeIcon icon={faAngleRight} style={{color:"#00448C"}} /></div>
                                        </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                 </Card.Body>
                            </Card>
                         </div>
                        </div>
                     </div>
            )}
            {cont === 3 &&(
                 <div >
                    <div onClick={()=>{step(2)}} id="btnVolver" className="col-lg-2 col-sm-4 col-md-3 text-left manitoCol mt-3" style={{fontFamily:"OpenSans-Regular", color:"#949494", fontSize: "14px"}}>
                        <img src={volver} alt="volver" className="mr-2 " /> Volver
                    </div>
                    <div style={{margin: "40px 20px"}}>
                        <p id="textPunt" className="textListGroup mb-5 ml-3">Conoce tu puntaje financiero y aprende cómo mejorar tu perfil crediticio:</p>
                        <div className="row ">
                            <div className="col-lg-12 cajaOpt">
                                <Accordion className="styleAcc" defaultActiveKey={contScore}>
                                    <Card className="mb-3">
                                        <Card.Header classNamw="headerScore" id="minScore" style={{marginBottom:"0px",border:"1px solid #D5D5D5"}}>
                                            <Accordion.Toggle style={{background: "white", border:" 1px solid white",width: "100%",textAlign:"left"}} variant="link" eventKey="0">
                                                <div className="row">
                                                    <div className="col-10 marginLeftScoreTitle cardTitleScore respCard">Experiencia crediticia</div>
                                                    <div className="col-1 text-right styleIcon"><img src={flecha} alt="arrow"/></div>
                                                </div>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0" id="showBorder">
                                            <Card.Body id="cardPadding" className="card-body-score"> 
                                                {answer.experienciaCrediticia.map((e,i)=>(
                                                    <div className="row spacioCard" style={{minHeight: "58px"}}>
                                                         <div className="col-lg-6 col-md-5 col-sm-12 textScore itemList" >
                                                                <ul>
                                                                    <li>{e.texto}</li>
                                                                </ul>
                                                         </div>
                                                         <div className="col-lg-5 col-md-6 col-sm-12" style={{paddingRight:"3%", marginLeft:"40px", marginTop:"6px"}}>
                                                            {e.calificacionLetra === "E"?(
                                                                <div className="progress_score progress classProgress-good" style={{height: "1.2rem"}}>
                                                                    <div className="progress-bar " role="progressbar" style={{width: "100%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span className="text-center">Excelente</span></div>
                                                              </div>
                                                            ):e.calificacionLetra === "B"?(
                                                                <div className="progress_score progress classProgress-regular" style={{height: "1.2rem"}}>
                                                                    <div className="progress-bar " role="progressbar" style={{width: "50%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span className="text-center">Bueno</span></div>
                                                              </div>
                                                            ):e.calificacionLetra === "R" &&(
                                                                <div className="progress_score progress classProgress-bad" style={{height: "1.2rem"}}>
                                                                    <div className="progress-bar " role="progressbar" style={{width: "30%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span className="text-center">Regular</span></div>
                                                              </div>
                                                            )}
                                                         </div>
                                                    </div>
                                                ))}
                                                <Card id="cardScore" className="mt-3 ml-3 mr-3 mb-3 fondoCard">
                                                    <Card.Header className="tituloScoreCard fondoCard">
                                                        <div className="row">
                                                            <div id="styleMobile" className="col-10 classAlertNoti cardTitleScore">¿Cómo mejorar mi experiencia crediticia?</div>
                                                            <div className="col-1 text-right classAlertIco"><img src={menos} alt="icono menos"/></div>
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body id="cuadroStyle" className="body-card-score m-4" style={{overflowY: "auto", height: "338px"}}>
                                                    <p className="tituloScoreCard cardFuente" style={{lineHeight:"14px", color:"#454648"}}>Recomendaciones importantes</p>
                                                    {answer.experienciaCrediticia.map((e,i)=>(
                                                        <p className="textScore textCuadro" style={{color:"#808285"}}>
                                                            {e.textoMejoramiento}
                                                        </p>
                                                     ))}
                                                    </Card.Body>
                                                </Card>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card className="mb-3">
                                        <Card.Header id="minScore" style={{marginBottom:"0px",border:"1px solid #D5D5D5"}}>
                                            <Accordion.Toggle style={{background: "white", border:" 1px solid white",width: "100%", textAlign:"left"}} variant="link" eventKey="1">
                                            <div className="row">
                                                    <div className="col-10 marginLeftScoreTitle cardTitleScore respCard">Estado de tu portafolio</div>
                                                    <div className="col-1  text-right styleIcon"><img src={flecha} alt="arrow"/></div>
                                            </div>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1" id="showBorder">
                                        <Card.Body id="cardPadding" className="card-body-score">
                                                {answer.estadoPortafolio.map((e,i)=>(
                                                    <div className="row">
                                                         <div className="col-lg-6 col-md-5 col-sm-12 textScore">
                                                                <ul>
                                                                    <li>{e.texto}</li>
                                                                </ul>
                                                         </div>
                                                         <div className="col-lg-5 col-md-5 col-sm-12 " style={{paddingRight:"3%", marginLeft:"40px"}}>
                                                            {e.calificacionLetra === "E"?(
                                                                <div className="progress_score progress classProgress-good" style={{height: "1.2rem"}}>
                                                                    <div className="progress-bar " role="progressbar" style={{width: "100%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span className="text-center">Excelente</span></div>
                                                                </div>
                                                            ):e.calificacionLetra === "B"?(
                                                                <div className="progress_score progress classProgress-regular" style={{height: "1.2rem"}}>
                                                                    <div className="progress-bar " role="progressbar" style={{width: "50%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span className="text-center">Bueno</span></div>
                                                              </div>
                                                            ):e.calificacionLetra === "R" &&(
                                                                <div className="progress_score progress classProgress-bad" style={{height: "1.2rem"}}>
                                                                    <div className="progress-bar " role="progressbar" style={{width: "30%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span className="text-center">Regular</span></div>
                                                              </div>
                                                            )}
                                                         </div>
                                                    </div>
                                                ))}
                                               
                                                <Card id="cardScore" className="mt-3 ml-3 mr-3 mb-3 fondoCard">
                                                    <Card.Header className="tituloScoreCard fondoCard ">
                                                        <div className="row">
                                                            <div id="styleMobile" className="col-10 classAlertNoti cardTitleScore">¿Cómo mejorar mi portafolio?</div>
                                                            <div className="col-1 text-right classAlertIco"><img src={menos} alt="icono menos"/></div>
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body id="cuadroStyle" className="m-4 body-card-score" style={{overflowY: "auto", height: "338px"}}>
                                                    <p className="tituloScoreCard" style={{lineHeight:"14px", color:"#454648"}}>Recomendaciones importantes</p>
                                                    {answer.estadoPortafolio.map((e,i)=>(
                                                        <p className="textScore textCuadro" style={{color:"#808285"}}>
                                                             {e.textoMejoramiento}
                                                        </p>
                                                     ))}
                                                    </Card.Body>
                                                </Card>
                                                
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card className="mb-3">
                                        <Card.Header id="minScore" style={{marginBottom:"0px",border:"1px solid #D5D5D5"}}>
                                            <Accordion.Toggle style={{background: "white", border:" 1px solid white",width: "100%", textAlign:"left"}} variant="link" eventKey="2">
                                            <div className="row">
                                                    <div className="col-10 marginLeftScoreTitle cardTitleScore respCard">Endeudamiento</div>
                                                    <div className="col-1 text-right styleIcon"><img src={flecha} alt="arrow"/></div>
                                            </div>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="2" id="showBorder">
                                        <Card.Body id="cardPadding" className="card-body-score">
                                                {answer.endeudamiento.map((e,i)=>(
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-5 col-sm-12 textScore">
                                                                <ul>
                                                                    <li>{e.texto}</li>
                                                                </ul>
                                                         </div>
                                                         <div className="col-lg-5 col-md-5 col-sm-12" style={{ marginLeft:"40px"}}>
                                                            {e.calificacionLetra === "E"?(
                                                                <div className="progress_score progress classProgress-good" style={{height: "1.2rem"}}>
                                                                    <div className="progress-bar " role="progressbar" style={{width: "100%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span className="text-center">Excelente</span></div>
                                                                </div>
                                                            ):e.calificacionLetra === "B"?(
                                                                <div className="progress_score progress classProgress-regular" style={{height: "1.2rem"}}>
                                                                    <div className="progress-bar " role="progressbar" style={{width: "50%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span className="text-center">Bueno</span></div>
                                                              </div> 
                                                            ):e.calificacionLetra === "R" &&(
                                                                <div className="progress_score progress classProgress-bad" style={{height: "1.2rem"}}>
                                                                    <div className="progress-bar " role="progressbar" style={{width: "30%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span className="text-center">Regular</span></div>
                                                                </div>
                                                            )}
                                                         </div>
                                                    </div>
                                                ))}
                                                <Card id="cardScore" className="mt-3 ml-3 mr-3 mb-3 fondoCard">
                                                    <Card.Header className="tituloScoreCard fondoCard">
                                                        <div className="row">
                                                            <div id="styleMobile" className="col-10 classAlertNoti cardTitleScore deudaCard"> ¿Cómo mejorar mi experiencia de endeudamiento?</div>
                                                            <div className="col-1 text-right classAlertIco"><img src={menos} alt="icono menos"/></div>
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body id="cuadroStyle" className="m-4" >
                                                    <p className="tituloScoreCard " style={{lineHeight:"14px", color:"#454648"}}>Recomendaciones importantes</p>
                                                    {answer.endeudamiento.map((e,i)=>(
                                                         <p className="textScore textCuadro" style={{color:"#808285"}}>
                                                            {e.textoMejoramiento}
                                                        </p>
                                                     ))}
                                                    </Card.Body>
                                                </Card>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card className="mb-3">
                                        <Card.Header className="habBorder" id="minScore" style={{marginBottom:"0px",border:"1px solid #D5D5D5"}}>
                                            <Accordion.Toggle style={{background: "white", border:" 1px solid white",width: "100%", textAlign:"left"}} variant="link" eventKey="3">
                                            <div className="row">
                                                    <div className="col-10 marginLeftScoreTitle cardTitleScore respCard"> Hábitos de pago</div>
                                                    <div className="col-1 text-right styleIcon"><img src={flecha} alt="arrow"/></div>
                                            </div>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="3" id="showBorder">
                                        <Card.Body id="cardPadding" className="card-body-score">
                                                {answer.habitoPago.map((e,i)=>(
                                                    <div className="row">
                                                          <div className="col-lg-6 col-md-5 col-sm-12 textScore">
                                                                <ul>
                                                                    <li>{e.texto}</li>
                                                                </ul>
                                                         </div>
                                                         <div className="col-lg-5 col-md-5 col-sm-12 " style={{ marginLeft:"40px"}}>
                                                            {e.calificacionLetra === "E"?(
                                                               <div className="progress_score progress classProgress-good" style={{height: "1.2rem"}}>
                                                                     <div className="progress-bar " role="progressbar" style={{width: "100%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span className="text-center">Excelente</span></div>
                                                                </div>
                                                            ):e.calificacionLetra === "B"?(
                                                                <div className="progress_score progress classProgress-regular" style={{height: "1.2rem"}}>
                                                                    <div className="progress-bar " role="progressbar" style={{width: "50%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span className="text-center">Bueno</span></div>
                                                              </div> 
                                                            ):e.calificacionLetra === "R" &&(
                                                                <div className="progress_score progress classProgress-bad" style={{height: "1.2rem"}}>
                                                                    <div className="progress-bar " role="progressbar" style={{width: "30%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ><span className="text-center">Regular</span></div>
                                                                </div>
                                                            )}
                                                         </div>
                                                    </div>
                                                ))}
                                                <Card  id="cardScore" className="mt-3 ml-3 mr-3 mb-3 fondoCard">
                                                    <Card.Header className="tituloScoreCard fondoCard">
                                                        <div className="row">
                                                            <div id="styleMobile" className="col-10 classAlertNoti cardTitleScore">¿Cómo mejorar mi hábito de pago?</div>
                                                            <div className="col-1 text-right classAlertIco"><img src={menos} alt="icono menos"/></div>
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body id="cuadroStyle" className="m-4" >
                                                    <p className="tituloScoreCard" style={{lineHeight:"14px", color:"#454648"}}>Recomendaciones importantes</p>
                                                    {answer.habitoPago.map((e,i)=>(
                                                         <p className="textScore textCuadro" style={{color:"#808285"}}>
                                                            {e.textoMejoramiento}
                                                        </p> 
                                                     ))}
                                                    </Card.Body>
                                                </Card>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                 </div>
            )} 
        </ContainerService>
        </React.Fragment>
    );
}