import React, { useEffect, useRef, useState } from "react";
import logo from '../../assets/img/imgService/entreview-man-service-1.png';
import logo1 from '../../assets/img/imgService/entreview-man-service-2.png';
import ContainerService from "../../components/container-service/ContainerService";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import Sheduling from "../../components/sheduling/Sheduling";

export default function Psico(props) {
    const [ step, setStep ] = useState(1);

    const linkbread = [{
        to: "/auth/inicio",
        tittle: "Inicio > "
    },
    {
        to: "/s/desempleo/preparar-entrevistas",
        tittle: "Prepara tus entrevistas > ",
    },
    {
        to: "/s/desempleo/preparar-entrevistas",
        tittle: "Agenda simulación entrevistas",
    }
    ];

    const infoSheduling = {
        typeSheduling: 2024,
        classTitleSheduling: 'fechahoraTitulo',
        classVolver: 'btnBackGrey',
        typeTextSheduling:<h5 className="typeShedulingText" style={{width: "92%"}}>Simulación de entrevista laboral</h5>,
        module:'Entrevista',
        tittleSheduling:'Agenda una simulación de entrevista. ',
        paragraph:"Para prestarte un servicio adecuado por favor confirma los siguientes datos:",
        alertCancelTitle:'Cancelación de agenda',
        alertCancelText:'¿Estás seguro de cancelar tu asesoría?',
        tooltipsClass:'tooltipShedulingEco',
        messageSuccess:{
          module:'Seguridad',
          variant:'success',
          checkText:'Confirmación de simulación éxitosa',
          alertText:'La simulación de entrevista se agendó exitosamente',
          class:'alert alertSuccessEcosistemas',
        },
        messageCancel:{
          module: "Desempleo",
          variant:'success',
          checkText:'Tu consultoría ha sido cancelada con éxito',
          alertText:'Si deseas programar una nueva consultoría, haz clic a continuación.',
          class:'alert alertDesempleo',
          textButton: "AGENDAR SIMULACIÓN"
        }
    };

    const CardServices =[{
        image:logo,
        title:"Agenda una simulación de entrevista",
        subTittle:"La primera impresión nunca se olvida, por eso prepárate para llevar con éxito una entrevista de trabajo.",
        textButton:"AGENDAR SIMULACIÓN",
        linkButton:"",
        onClick:(e)=>{setStep(2)}
    },{
        image:logo1,
        title:<>Identifica tus aptitudes <br/>laborales<br/></>,
        subTittle:"Entrena y mejora tus habilidades y las aptitudes necesarias para desarrollar las pruebas que te ayudarán a conseguir tu próximo trabajo. Conoce tus fortalezas antes de participar en un proceso de selección, por medio de un conjunto de pruebas psicotécnicas, que te ayudarán a identificar tus habilidades y aptitudes.",
        textButton:"INICIAR PRUEBAS",
        linkButton:"/s/desempleo/preparar-entrevistas/pruebas",
    }]
    const history = useHistory();
    useEffect(()=>{
        if(window.location.pathname === "/s/desempleo/preparar-entrevistas"){
            setStep(1);
        }
    },[history.location.pathname]);
    return (
        <React.Fragment >
            <ContainerService tittle="Prepara entrevistas" tittleSize="34px" styleTittle={{ fontSize: "34px" }} linkBread={linkbread}>
                {step === 1 &&(
                <div className="p-lg-5 p-md-5 p-0">
                    <div className="row pr-lg-5 pr-sm-0">
                        <div className="paddingCardsPsico col-12 mt-3 d-lg-flex d-md-flex d-sm-block mgPsico" style={{ borderRadius: "14px;" }}>
                           {CardServices.map((evento,i)=>{
                               return(
                                   <div className="col-lg-6 col-md-6 col-sm-12 p-2">
                                        <Card className=" border mgPsico" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                                       <Card.Img variant="top" src={evento.image}/>
                                       <Card.Body style={{ minHeight: "394px", padding: '30px' }}>
                                           <Card.Title className="mb-3" style={{ fontFamily: "cibSans-Bold", fontSize: "22px", color: "#333333" }}>
                                               {evento.title}
                                            </Card.Title>
                                           <Card.Text style={{ fontSize: "16px", color: "#666", width:"93%",fontFamily: 'OpenSans' }}>
                                               {evento.subTittle}
                                            </Card.Text>
                                       </Card.Body>
                                       <Card.Footer  className="mb-4" style={{backgroundColor:"transparent", borderTop:"1px solid transparent", textAlign:"center"}}>
                                          {evento.linkButton === ""?(
                                            <button className="btnMainBluePsico" style={{ margin: '0px !important', width: '80%', marginBottom: '30px !important', fontWeight: "400", borderRadius: '25px' }} onClick={(e)=>{evento.onClick(e)}}>
                                                {evento.textButton}
                                            </button>
                                          ):(
                                            <button className="btnMainBluePsico" style={{ margin: '0px !important', width: '80%', marginBottom: '30px !important', fontWeight: "400", borderRadius: '25px' }}>
                                                <Link to={evento.linkButton} className=" LinkPsico" type="button"
                                                style={{ margin: ' 0px', width: '80%', fontWeight: "400", borderRadius: '25px' }}>
                                                {evento.textButton}
                                                </Link>
                                            </button>
                                          )}
                                       </Card.Footer>
                                   </Card>
                                   </div>
                               )
                           })}
                        </div>
                    </div>
                </div>
                )}
                {step === 2 &&(
                   <Sheduling key={1} type="2024" module="Entrevista" propsP={props} infoSheduling={infoSheduling}/> 
                )}
            </ContainerService>
        </React.Fragment>
    )
}