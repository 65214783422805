import React, { useEffect, useState} from "react";
import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Header from "./components/header/Header";
import Home from "./views/home/Home";
import Footer from "./components/footer/Footer";
import Register from "./views/register/RegisterView";
import Login from "./views/login/Login";
import PasswordRecovery from "./views/login/PasswordRecovery";
import PasswordUpdate from "./views/login/PasswordUpdate";

import HomeIn from "./views/home/HomeIn";
import Axios from "axios";
import { urlAPI } from "./utils/config";
import * as CryptoJS from "crypto-js";
import { Idle } from "idlejs/dist";
import Jwt from "jsonwebtoken";
import { isActive,
        getToken,
        getUser, 
        closeSession, 
        isTokenExpiration , 
        getNavegator, 
        encryptDecrypt  } from "./utils/validations";
import AuthorizationUnderAge from "./views/authorizations/AuthorizationUnderAge";
import AuthorizationAdult from "./views/authorizations/AuthorizationAdult";
import FrecuentQuestions from "./views/faq/FrecuentQuestions";
import Profile from "./views/profile/Profile";
import ModalInactivity from "./components/modal/ModalInactivity";
import Help from "./views/help/Help";

// Vida
import ShedulingTelemedic from "./views/sheduling-telemedic/ShedulingTelemedic";

// Vida
import SchedulingOrientation from "./views/scheduling-orientation/orientation";

// Desempleo
import PsicoTest from "./views/psico/psicoTest";
import CurriculumVitae from "./views/curriculum-desempleo/curriculum-vitae/curriculum-vitae";
import CurriculumHome from "./views/curriculum-desempleo/curriculum-home";
import Salary from "./views/salary/salary";
import PsicoSheduling from "./views/psico/psico";
import Advisory from "./views/advisory-desmpleo/advisory";
import Joboffers from "./views/job-offers-desempleo/job-offers";
import CurriculumScheduling from "./views/curriculum-desempleo/curriculum-scheduling/curriculum-scheduling";
// ITT
import BrandingDigital from "./views/services-ITT/branding-digital/Branding-digital";
import CoworkingService from "./views/services-ITT/coworking/Coworking-service";
import Coworking from "./views/services-ITT/coworking/Coworking-scheduling";
import Legal from "./views/services-ITT/legal/Legal-scheduling";
import NetworkingServices from "./views/services-ITT/networking/NetworkingServices";
import NetworkingEvents from "./views/services-ITT/networking/events/NetworkingEvents";
import NetworkingEventDetail from "./views/services-ITT/networking/events/NetworkingEventDetail";
// import AdminNetworking from "./views/services-ITT/networking/events/AdminNetworking";


// Fraude
import AlertNotifications from "./views/services-fraud/alerts/alert-notifications";
import ShedulingFraud from "./views/services-fraud/sheduling-fraud/ShedulingFraud";
import ScoreFinancial from "./views/services-ITT/score-financial/ScoreFinancial";
import SaludFinanciera from "./views/services-fraud/salud-financiera/SaludFinanciera";
import FinanzasPersonales from "./views/services-fraud/finanzas-personales/FinanzasPersonales";
import NetworkingCommunity from "./views/services-ITT/networking/community/NetworkingCommunity";

import VideoCall from "./views/videocall/VideoCall";

//HISTORIAL TELEMEDICINA PHANTOM
import historicTelemdic from "./views/sheduling-telemedic/ShedulingTelemedicine";


function App(props) {
  const [modalShow,setModalShow] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('sesioon') != null) {
      setModalShow(true)
    }
    var linkBase = window.location.pathname.split('/');
    let data = {
      token: linkBase[2]
    }
    let obj = {
      ipUsuario: ' ',
      link: linkBase[1]
    };
    if(linkBase[1] != ''){
    Axios.post(`${urlAPI}/Login/seguridadSesion`, JSON.stringify(obj), {
      headers: { "Content-Type": "application/json", }
    }).then(succ => {
      if (succ.data.codigo === 0) {
        window.close();
      }
    }).catch(error => {
      //console.log(error);
    });
  }
  if(linkBase[1] === 'out-link'){
    var tokenJwt = Jwt.sign({data:'creacionToken'},'Db',{expiresIn:360});
    localStorage.setItem("jwt",tokenJwt);
    Axios.post(`${urlAPI}/LoginIntegracion/LeerLink`,JSON.stringify(data),{
      headers: { 
        "Content-Type": "application/json"
      }
    }).then(succ1 => {
      localStorage.setItem("token", CryptoJS.AES.encrypt(succ1.data.data.token, "eco_scotia"));
      if(succ1.data.codigo === 0){
        Axios.get(`${urlAPI}/Login/obtenerSession`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + succ1.data.data.token,
          },
        }).then(success=>{
          localStorage.setItem(
            "user",
            CryptoJS.AES.encrypt(JSON.stringify(success.data), "eco_scotia")
          );
          var dispo = getNavegator();
          Axios.get('https://ipapi.co/json/?key=vgn91pD3PXJhodzX3llb7PGxJpyemvUhamyxnhm8fTSvNJ9J5P'
          ).then(succ=>{
            //console.log(decodeURIComponent(escape(window.atob( success.data.idUsuario ))),' descr');
          var encrip = btoa(encryptDecrypt(`${succ.data.ip}<${success.data.idUsuario}`,succ.data.ip));
          let obj = {
                  ipUsuario:succ.data.ip,
                  dispositivo:dispo,
                  link:encrip
          }
          Axios.post(`${urlAPI}/Seguridad/EnvioCorreoDispositivo`,JSON.stringify(obj),{ headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + succ1.data.data.token,
                }
                }).then(succRes=>{
                  window.location.href = `/auth/inicio`;
                }).catch(error=>{
            
                });
              }).catch(error=>{
                //console.log(error);
            });
        }).catch(error=>{
          //console.log(error);
        });
      }
    }).catch(error => {
      //console.log(error);
    });
  }
  
    if (isActive()) {
      Axios.get(`${urlAPI}/Login/Parametros/16`, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then(succ => {
        setInterval(() => {
          Axios.post(`${urlAPI}/Token/RefreshToken`, JSON.stringify({ usuario: btoa(' '), password: '', sitioUsuario: '', sitioPassword: '', tipoDocumento: '', idTipoDocumento: '', tokenCaptcha: '', ip: '' }), {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + getToken(),
            }
          }).then(succ => {
            localStorage.setItem(
              "token",
              CryptoJS.AES.encrypt(String(succ.data.token), "eco_scotia")
            );
            var tokenJwt = Jwt.sign({data:'creacionToken'},'Db',{expiresIn:360});
            localStorage.setItem("jwt",tokenJwt);
          }).catch(error => {
            //console.log(error);
          });
        }, 300000);
      }).catch(error => {
        //console.log(error);
      });
      setInterval(() => {
        Axios.get('https://ipapi.co/json/?key=vgn91pD3PXJhodzX3llb7PGxJpyemvUhamyxnhm8fTSvNJ9J5P').then(scc => {
          let objLink = {
            ipUsuario: scc.data.ip,
          };
          Axios.post(`${urlAPI}/Seguridad/validarIP`, JSON.stringify(objLink), {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + getToken(),
            }
          }).then(succss => {
            if (succss.data.codigo === 1) {
              //closeSession();
            }
          }).catch(error => {
            //console.log(error);
          });
        }).catch(error => {
          //console.log(error);
        });

      }, 10000);
    }
    const idle = new Idle()
      .whenNotInteractive()
      .within(10)
      .do(() => {
        if (isActive() && !window.location.pathname.includes("medico")) {
          localStorage.setItem('sesioon','1')
          setModalShow(true);
        }
      })
      .start();
    if(isActive() && isTokenExpiration() && !window.location.pathname.includes("medico")) {
        closeSession();
    }
  });
  
  let storage
  // useEffect(() => {
  //   let activo = isActive()
  //   if (activo) {
  //     window.addEventListener("beforeunload", function (event) {
  //       event.preventDefault();
  //       if (localStorage.getItem('user') !== null) {
  //         closeSession();
  //         localStorage.setItem('val', '1');
          
  //       }
  //     });
  //   }else{
  //     storage = localStorage.getItem('val')
  //     if (storage !== null) {
  //       setModalShow(true)
  //     }
  //   }
  // },[]);
  return (
    <BrowserRouter>
      <div className="App" style={{height: '100vh'}}>
        <Header />
        {modalShow && (<ModalInactivity showM={modalShow} clickClose={()=>{closeSession()}}></ModalInactivity>)}
        <Switch >
            {isActive()?(
              <>
                <Route path="/auth/inicio" exact component={HomeIn} />

                <Route path={[
                '/s/desempleo/preparar-entrevistas/pruebas',
                // '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/resultados',
                
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-partes',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-partes/empezada',
                's/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-partes/finalizada',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-partes-resultado',

                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-numeros',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-numeros/empezada',
                's/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-numeros/finalizada',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-numeros/resultados',

                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-razonamiento',
                's/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-razonamiento/empezada',
                's/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-razonamiento/finalizada',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-razonamiento/resultado',

                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-percepcion',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-percepcion/empezada',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-percepcion/finalizada',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-percepcion/resultados',

                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-liderazgo',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-liderazgo/empezada',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-liderazgo/finalizada',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-liderazgo/resultado',

                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-estilo-gestion',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-estilo-gestion/finalizada',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-estilo-gestion/seecion1',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-estilo-gestion/seecion2',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-estilo-gestion/seecion3',
                '/s/desempleo/preparar-entrevistas/pruebas/mis-pruebas/p-estilo-gestion/finalizada',
                ]}
                exact component={PsicoTest}/>

                <Route path={[
                  "/s/comparar-salario-servicio",
                  "/s/comparar-salario-servicio/tu-informacion",
                  "/s/comparar-salario-servicio/resultados",
              ]}exact component={Salary} />

                <Route path={[
                  '/s/desempleo/mejorar-hoja-de-vida/crear-hv/datos-personales',
                '/s/desempleo/mejorar-hoja-de-vida/crear-hv/educacion',
                '/s/desempleo/mejorar-hoja-de-vida/crear-hv/experiencia-laboral',
                '/s/desempleo/mejorar-hoja-de-vida/crear-hv/diseño',
                '/s/desempleo/mejorar-hoja-de-vida/crear-hv/finalizada',
                '/s/desempleo/mejorar-hoja-de-vida/crear-hv/agenda',
                '/s/desempleo/mejorar-hoja-de-vida/crear-hv/agenda-exitosa',
                '/s/desempleo/mejorar-hoja-de-vida/crear-hv/agenda-cancelada'
                  ]} exact component={CurriculumVitae}/>
                  
                <Route path="/s/desempleo/mejorar-hoja-de-vida" exact component={CurriculumHome}/>
                <Route path={[
                "/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda-eleccion-hdv",
                "/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda",
                "/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda-exitosa",
                "/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda-cancelada"
                ]} exact component={CurriculumScheduling}/>
                <Route path={[
                  "/s/desempleo/asesoria-legal", //
                  "/s/desempleo/asesoria-legal-servicio/agenda",
                  "/s/desempleo/asesoria-legal-servicio/ok", 
                  "/s/desempleo/asesoria-legal-servicio/agenda/cancelada"
                  ]} exact component={Advisory}/>
                <Route path="/s/desempleo/ofertas-laborales-inicio" exact component={Joboffers}/>
                <Route path={[
                  "/s/desempleo/preparar-entrevistas",
                "/s/desempleo/preparar-entrevistas/agenda",
                "/s/desempleo/preparar-entrevistas/agenda-exitosa",
                "/s/desempleo/preparar-entrevistas/agenda-cancelada"
                ]} exact component={PsicoSheduling}/>
                <Route path={[
                  "/s/orientacion",
                "/s/orientacion/agenda",
                "/s/orientacion/agenda-exitosa",
                "/s/orientacion/agenda-cancelada"
                ]} exact component={SchedulingOrientation}/>
                <Route path={[
                  "/s/medico",
                "/s/medico/agenda",
                "/s/medico/agenda-exitosa",
                "/s/medico/agenda-cancelada"
                ]} exact component={ShedulingTelemedic}/>
              <Route path={["/telemedicina-historial","/psicologia-historial"]} exact component={historicTelemdic} />
              
                <Route path={[
                  "/ITT/auth/branding-digital",
                  "/ITT/auth/branding-digital/agenda",
                  "/ITT/auth/branding-digital/agenda/1",
                  "/ITT/auth/branding-digital/agenda/2",
                  "/ITT/auth/branding-digital/agenda/exito",
                  "/ITT/auth/branding-digital/agenda/cancelacion"
                  ]} exact component={BrandingDigital}/>
                {/*Networking*/}
                {/* <Route path={["/admin"]} exact component={AdminNetworking}/> */}
                <Route path={["/ITT/auth/networking/servicios","/ITT/auth/networking"]} exact component={NetworkingServices}/>
                <Route path={["/ITT/auth/networking/servicios/eventos-registrados","/ITT/auth/networking/servicios/eventos"]} exact component={NetworkingEvents}/>
                <Route path={["/ITT/auth/networking/servicios/eventos/evento-ID"]} exact component={NetworkingEventDetail}/>
                <Route path={["/ITT/auth/networking/servicios/eventos/evento-ID/exito"]} exact component={NetworkingEventDetail}/>
                <Route path={["/ITT/auth/networking/servicios/eventos/evento-ID/cancelacion"]} exact component={NetworkingEventDetail}/>
                <Route path={["/ITT/auth/networking/servicios/comunidad-emprendimientos"]} exact component={NetworkingCommunity}/>
                {/*Networking end*/}
                {/* <Route path="/s/itt/coworking/inicio" exact component={CoworkingService}/>
                <Route path={["/s/itt/coworking/agenda", "/s/itt/coworking/agenda-exitosa", "/s/itt/coworking/agenda-cancelada"]} exact component={Coworking}/> */}
                <Route path={[
                  "/ITT/auth/tu-asesoria-legal",
                  '/ITT/auth/tu-asesoria-legal/agenda',
                  "/ITT/auth/tu-asesoria-legal/agenda/1",
                  "/ITT/auth/tu-asesoria-legal/agenda/exito",
                  "/ITT/auth/tu-asesoria-legal/agenda/cancelacion"
                  ]} exact component={Legal}/>

                <Route path={["/s/fraude/salud-financiera/inicio"]} exact component={SaludFinanciera}/>
                <Route path={[
                  "/fraude/auth/score-financiero/consulta",
                  "/fraude/auth/score-financiero",
                  "/fraude/auth/score-financiero/consulta/resultado", 
                  "/fraude/auth/score-financiero/consulta/resultado/recomendaciones"
                  ]}  exact component={ScoreFinancial} />
                <Route path={["/s/fraude/finanzas-personales"]} exact component={FinanzasPersonales}/>
                <Route path={[
                  "/fraude/auth/alertas-financieras/activacion",
                  "/fraude/auth/alertas-financieras/activacion/1",
                  "/fraude/auth/alertas-financieras/activacion/1/deshabilitado",
                  "/fraude/auth/alertas-financieras/activacion/1/habilitado"
                ]} exact component={AlertNotifications} />
                <Route path={[
                  "/fraude/auth/seguridad-informatica",
                  "/fraude/auth/seguridad-informatica/agenda",
                  "/fraude/auth/seguridad-informatica/agenda/1",
                  "/fraude/auth/seguridad-informatica/agenda/1/exito",
                  "/fraude/auth/seguridad-informatica/agenda/1/cancelacion"
                  ]} exact component={ShedulingFraud}/>
                <Route path={[
                  "/auth/ayuda/lineas-atencion",
                  "/auth/ayuda/agenda-ayuda",
                  "/auth/ayuda/agenda-ayuda/exito",
                  "/auth/ayuda/agenda-ayuda/cancelacion",
                  "/auth/ayuda/agenda-ayuda-check"
                  ]} exact component={Help}/>
                <Route path="/auth/configuracion-perfil/:step?" exact component={Profile} />
                <Route path="/videollamada" exact component={VideoCall} />
                {/* <Route path={["/auth/telemedicina-agenda","/auth/telemedicina-agenda/1","/auth/telemedicina-agenda/exito","/auth/telemedicina-agenda/cancelacion","/auth/telemedicina-agenda-check"]} exact component={ShedulingTelemedic} /> */}
              </>
            ) : (
              <>
                <Route path={["/inicio",""]} exact component={Home}/>
                <Route path="/registro/:step?" exact component={Register} />
                <Route path={["/registro-usuario-no-activo", "/registro-exitoso"]} exact component={Register} />
                <Route path={["/inicia-sesion","/inicio-sesion/control-block"]} exact component={Login} />
                <Route path="/olvidaste-tu-contrasena/:step?" exact component={PasswordRecovery} />
                <Route path={["/preguntas-frecuentes/resultado-:step?"]} exact component={FrecuentQuestions} />
                <Route path="/autorizacion-menores/:step?" exact component={AuthorizationUnderAge}/>
                <Route path="/autorizacion-beneficiario/:step?" exact component={AuthorizationAdult}/>
                
                <Route path="/actualizar-clave" exact component={PasswordUpdate} />
              </>
            )}
        </Switch>
        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
