import React, { useEffect, useState } from "react";
import { urlAPI } from "../utils/config";
const URL_TYPE_DOC = `${urlAPI}/Login/Tipos/1`
export const useDocumentType = ({documentTypes}) => {
    const[documents, setDocuments] = useState([])
    useEffect(()=>{
        if (documentTypes) return
        fetch(`${URL_TYPE_DOC}`, { 
            headers: { "Content-Type": "application/json", }
        }).then(res => res.json())
        .then(response => {
            const {data} = response
            setDocuments(data)
        })
    },[documentTypes])

    return {documents}
}