import React from 'react'
import { Card } from 'react-bootstrap'

export const ContenedorComponent = (props) => {
    const { children } = props
    return (
        <Card className="col-12 col-md-10 col-lg-11 col-xl-10 m-auto p-0 card__register__width">
            <Card.Body className='card-body-register'>
                {children}
            </Card.Body>
        </Card>
    )
}