import React from "react";
import logoVigilado from "../../assets/img/footer/footer-vigilado.svg";
import logoMobile from "../../assets/img/footer/leonisa-mobile.png";
import logoBancolombia from "../../assets/img/header/logos-header.png";
import separador from "../../assets/img/footer/separador.svg";
import segurosAlfa from "../../assets/img/footer/Logo-footer-Seguros-Alfa.png";
import logoCardifMobile from "../../assets/img/footer/Logo-footer-mobile-Bnp-Cardif.svg";
import logoAval from "../../assets/img/footer/Logo-Aval.svg";

import ModalTerms from "../modal/ModalTerms";
import ModalPolicies from "../modal/ModalPolicies";
// import ModalQuestion from "../../components/modal/ModalQuestion";

import phone from "../../assets/img/footer/icon-telephone.svg";
export default function Footer() {

  return (
    <>
      <div className="border-top">
        <div className="grayColor" style={{ background: "#EFEFEF" }}>
          <div className="row pb-3">
            <div className="col-1 pr-0">
              <img src={logoVigilado} alt="logoVigilado" className="mt-3" style={{ marginLeft: "15px" }} />
            </div>
            <div className="col-11 pt-5 pl-0" style={{ fontSize: 12 }}>
              <div className="logosFooter row" style={{paddingBottom:"40px"}}>
                <div className="d-none d-lg-inline col-4 p-0" href="/inicio" >
                  <img src={logoBancolombia} alt="Colpatria" width='328px' />

                </div>
                <div className="d-lg-none mb-3" href="/inicio" style={{ margin: 'auto' }}>
                  <img src={logoMobile} alt="Banco bogota" className="pb-3 d-block" width="122px" />
                  <img src={logoCardifMobile} alt="Paribas Cardif" className='d-block' />
                </div>
                <div className="legal col-xl-8 col-lg-12 text-center text-right">
                  <span  ></span>
                  <ModalTerms title="Términos y condiciones" />
                  <br className='d-lg-none d-md-none' />
                  <br className='d-lg-none d-md-none' />
                  <ModalPolicies title="Políticas de privacidad" />
                  <div className="padd09 bnp">
                    <span className="txtParibas" style={{ fontFamily: "OpenSans" }}>
                      BNP Paribas Cardif Derechos reservados Copyright © 2021
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center">
                <div className="padd09 d-inline-block">
                  <span style={{ color: "#8E8E93" }}>
                    En caso de requerir información adicional o presentar algún
                    inconveniente, comunícate con nuestras líneas de atención:&nbsp;&nbsp;&nbsp;
                  </span>

                </div>
                <br className="d-lg-none d-md-block d-sm-block" />
                <br className="d-lg-none d-md-block d-sm-block" />
                <strong style={{ color: '#474747' }}>
                  <img src={phone} alt="phone"></img>
                  <a>
                    &nbsp;&nbsp;&nbsp; Bogotá: (601)743 1460. Resto del país: 01 8000 930 131
                  </a>{" "}
                </strong>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 text-center p-3 grayColor"
          style={{ fontSize: 11, backgroundColor: "#F4F4F4", zIndex: -1, color: "#8E8E93" }}
        >
          Para que vivas una mejor experiencia y visualizar nuestros servicios,
          te recomendamos usar los navegadores de internet “Web Chrome 43”,
          “Firefox 38”, “Safari 8” o sus versiones más actualizadas.
        </div>
      </div>
    </>
  );
}
