export const defaultFormRegister = {
    documento: '',
    nombre: '',
    telefono:'',
    correo:'',
    clave: '',
    chkTC: false,
    chkPL: false
};
export const defaultFormPasswordHistory = {
    id:'',
	clave:'',
	idUsuario:'',
	fecha_creacion: new Date(),
	fecha_actualizacion: new Date(),
    vigente: true
};
export const defaultFormMoksysCase = {
    data:[],
    succeeded: true,
    codeError:'',
    message:''
};