import React, {useState} from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";



export default function ServiceInfo(props) {
    const { tittleEco, subTittleEco, imageUrl, tittle, subTittle, textButton, linkButton, imageUrl1, tittle1, subTittle1, textButton1, linkButton1 } = props;
    const [count, setCount] = useState(1);
    return (
        <React.Fragment>
            <div className="p-lg-5 p-md-5 p-0">
                {tittleEco !== undefined &&
                    (
                        <h4 className="mb-5" style={{ fontFamily: 'DTL-ArgoT-regular' }}>{tittleEco}</h4>
                    )
                }
                <p className={tittle ==="¿Cómo diseñar una hoja de vida?"?"cardTitleText":""}>{subTittleEco}</p>
                <div className="row p-lg-0 p-md-0 p-0">
                    <div className={tittle ==="¿Cómo diseñar una hoja de vida?"?"paddingCardsPsico col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3 pDdMob":"paddingCardsPsico col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3 pl-0"} style={{ borderRadius: "14px;" }}>
                        <div className="card card-widget widget-user">
                            <div className="widget-user-header text-white"
                                style={{ backgroundImage: "url(" + imageUrl + ")",backgroundSize: 'cover',height: "150px"}}>
                            </div>
                            <div className="card-body" style={{ minHeight: "240px", padding: '30px', width: tittle ==="¿Cómo diseñar una hoja de vida?"?"88%":"", margin:'auto' }}>
                                <div style={{ minHeight: "70px", width:tittle ==="¿Cómo diseñar una hoja de vida?"?"283px":"" }}>
                                    <h3 className="mb-3" style={{ fontFamily: tittle ==="¿Cómo diseñar una hoja de vida?"?'cibSans-Bold':"GoogleSans", fontSize: tittle ==="¿Cómo diseñar una hoja de vida?"?"20px":"22px", color: "#333333" }}>
                                        {tittle}
                                    </h3>
                                </div>
                                <p style={{ fontSize: "16px", color: "#58595B",fontFamily: tittle ==="¿Cómo diseñar una hoja de vida?"?'OpenSans':'' }}>
                                    {subTittle}
                                </p>
                            </div>
                            <div className="text-center mb-5">
                                <Link to={linkButton} >
                                    <button className="btnMainBlack"
                                        style={{ margin: ' 0px !important', width: '80%', marginBottom: '30px !important', fontWeight: "400", borderRadius: '25px' }}
                                    >
                                        {textButton}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={tittle ==="¿Cómo diseñar una hoja de vida?"?"paddingCardsPsico col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3 pDdMob":"paddingCardsPsico col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3 "} style={{ borderRadius: "14px;" }}>
                        <div className="card card-widget widget-user">
                            <div className="widget-user-header text-white"
                                style={{ backgroundImage: "url(" + imageUrl1 + ")",backgroundSize: 'cover',height: "150px" }}>

                            </div>
                            <div className="card-body" style={{ minHeight: "240px", padding: '30px' }}>
                                <div style={{ minHeight: "70px", }}>
                                    <h3 className="mb-3" style={{ fontFamily: tittle ==="¿Cómo diseñar una hoja de vida?"?'cibSans-Bold':"GoogleSans", fontSize: tittle ==="¿Cómo diseñar una hoja de vida?"?"20px":"22px", color: "#333333" }}>
                                        {tittle1}
                                    </h3>
                                </div>
                                <p style={{ fontSize: "16px", color: "#58595B",fontFamily: tittle ==="¿Cómo diseñar una hoja de vida?"?'OpenSans':'', width: tittle ==="¿Cómo diseñar una hoja de vida?"?'87%':'' }}>
                                    {subTittle1}
                                </p>
                            </div>
                            <div className="text-center mb-5">
                                <Link to={linkButton1} >

                                    <button className="btnMainBlack"
                                        style={{ margin: ' 0px !important', width: '80%', marginBottom: '30px !important', fontWeight: "400", borderRadius: '25px' }}
                                    >
                                        {textButton1} 
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}