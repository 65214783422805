import React, { useState, useEffect } from "react";
import SchedulingHistoric from "./SchedulingHistoric";
import { Card, Button, Col, Row } from "react-bootstrap";
import './css/SchedulingTelemedicineSteps.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom";

const SchedulingTelemedicineSteps = ({ esHistorial }) => {

    const history = useHistory();
    const [MedicHistory] = useState(esHistorial)
    const [typeHistory, setTypeHistory] = useState();// 0 medicina general - 1 psicologia

    useEffect(()=>{
        window.scroll(0, 0);
         if(window.location.pathname === "/psicologia-historial"){
            setTypeHistory(1);
         }
         else if(window.location.pathname === "/telemedicina-historial"){
            setTypeHistory(0);
         }
     },[history.location.pathname]);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 banner-style containerCrumbs styles_contenedor">
                        <div className="crumbModulo breadCrumbs">
                            <Link key={"lin_bread_1"} to={"/auth/inicio"} className="linkBread">{'inicio >'} {typeHistory === 1 ? 'Psicología':'Telemedicina'}</Link>
                        </div>
                        <div className='text-center'>
                            <div className='text-left'>
                                <h1 className="tittleService" id="Telemedicina">{typeHistory === 1 ? 'Psicología':'Telemedicina'}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-12" style={{ background: 'rgb(245, 245, 245)' }}>
                        <Row className="justify-content-center w-100 p-0 m-0">
                            <Col className="col-11 col-md-10 col-xl-9" style={{ marginTop: '0' }}>
                                <Card className="card__telemedicine" style={{ marginTop: '-60px' }}>
                                    <Row className="w-100 p-0 m-0 justify-content-center">
                                        {
                                            MedicHistory && (
                                                <SchedulingHistoric typeHistory={typeHistory}/>
                                            )
                                        }
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        {
                            MedicHistory && (
                                <>
                                    <Row className="justify-content-center w-100 p-0 m-0">
                                        <Col className="col-11 col-md-10 col-xl-9 container__button__back mt-4">
                                            <Button
                                                onClick={() => { history.goBack() }}
                                                bsPrefix="button__secondary__icon__left"
                                            >
                                                Volver
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
export default SchedulingTelemedicineSteps;