import React, { useEffect, useState } from 'react';
import ContainerService from '../../../components/container-service/ContainerService';
import "../../../App";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEdit, faGlobe, faMapMarkerAlt, faTimes, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';
import Axios from "axios";
import { urlAPI } from '../../../utils/config';
import { getUser, isActive, getToken } from '../../../utils/validations';
import Swal from 'sweetalert2';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import DatePicker from "react-datepicker";
import Swiper from 'react-id-swiper';
import iconCheck from "../../../assets/img/ic-check-green.svg";
import $, { data } from 'jquery';
import Sheduling from "../../../components/sheduling/Sheduling";
import { useHistory } from "react-router-dom"
import Loader from "../../../components/loader/Loader";
import logo1 from '../../../assets/img/imgService/hojadevida.svg';
import iconClose from "../../../assets/img/perfil/icon_delete_close.svg";
import editIco from "../../../assets/img/icon/bancolombia-ico-editar-hv.svg";
import deleteIco from "../../../assets/img/icon/bancolombia-ico-eliminar-hv.svg";
import { ReactSortable } from "react-sortablejs";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function CurriculumVitae(props) {

    useEffect(() => {
        if (window.location.pathname === "/s/desempleo/mejorar-hoja-de-vida/crear-hv/datos-personales" && stepTab === 2) {
            window.scroll(0, 0);
            setStepTab(1);
            setStepCount(1);
        }
    })

    function defaultFormContact() {
        return {
            nombres: '',
            apellidoPaterno: '',
            dicTipoDocumento: '',
            identificacion: '',
            IdPlantillaAplicada: '',
            correoElectronico: '',
            telefonoCelular: '',
            dicDepartamento: '',
            dicCiudadResidencia: '',
            profesión: '',
            fechaNacimiento: null,
            genero: '',
            salary: '',
            pais: '',
            paisesTrabajar: [],
            residenceAddress: '',
            descripcionPerfilProfesional: '',
            anioExperiencia: '',
            idiomaNivel: '',
            rutaFoto: '',
            redesSociales: ''
        };
    }
    function defaultFormEduc() {
        return {
            institucionEducativa: '',
            dicNivelEducativo: '',
            tituloObtenido: '',
            dicAreaEstudio: '',
            estadoEstudio: '',
            dicCiudadEstudio: '',
            fechaInicio: null,
            fechaFin: '',
            idUsuario: getUser().idUsuario,
            idDatosPersonales: '',
            dicIntensidadEstudio: '',
            dicTipoEstudio: '',
            otroEstudio: '',
            esComplementario: '',
            dicPaisExtranjero: '',
            descripcionEducacion: ''
        };
    }
    function defaultFormEducomp() {
        return {
            institucionEducativa: '',
            dicNivelEducativo: '',
            tituloObtenido: '',
            dicAreaEstudio: '',
            estadoEstudio: '',
            dicCiudadEstudio: '',
            fechaInicio: null,
            fechaFinal: '',
            idDatosPersonales: '',
            idUsuario: getUser().idUsuario,
            dicIntensidadEstudio: '',
            dicTipoEstudio: '',
            otroEstudio: '',
            esComplementario: true,
            dicPaisExtranjero: '',
            descripcionEducacion: ''
        };
    }
    function defaultFormInfolaboral() {
        return {
            empresa: '',
            cargo: '',
            funcioneslogros: '',
            fechaIngreso: null,
            fechaSalida: null,
            currentJob: '',
            idDatosPersonales: '',
            habilidadyaptitud: [],
        };
    }
    function CurriculumFile() {
        return {
            idUsuario: getUser().idUsuario,
            imagenBase64: '',
            tipoArchivo: '',
            idImagen: '',
            formatoImg: '',
        }

    }
    function nuevoIdioma() {
        return {
            id: '',
            nivel: ''
        }
    }
    const pattern = /^(([a-zA-Z ]))$/
    let [dataTypeCurilum, setdataTypeCurilum] = useState([]);
    // let [dataLoadInfo, setdataLoadInfo] = useState(defaultFormContact);
    const [formContact, setFormContact] = useState(defaultFormContact);
    const [formStudy, setformStudy] = useState(defaultFormEduc);
    const [formInfoLab, setformInfoLab] = useState(defaultFormInfolaboral);
    const [imgRtaDB, setimgRtaDB] = useState(CurriculumFile)
    const [formStudyComp, setformStudyComp] = useState(defaultFormEducomp);
    const [dataTypeProfetion, setdataTypeProfetion] = useState([]);
    const [dataTypeTemplate, setdataTypeTemplate] = useState([]);
    const [dataTypeIdioma, setddataTypeIdioma] = useState([]);
    const [IntensidadHoraria, setIntensidadHoraria] = useState([]);
    const [dataTypeStudy, setdataTypeStudy] = useState([]);
    const [dataTypeEducation, setdataTypeEducation] = useState([]);
    const [dataTypeArea, setdataTypeArea] = useState([]);
    const [Habilidades, setHabilidades] = useState([]);
    const [dataTypeSalary, setdataTypeSalary] = useState([]);
    const [dataTypePais, setdataTypePais] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [startDateLab, setStartDateLab] = useState(null);
    const [startDateLabF, setStartDateLabF] = useState(null);
    const [starfechaInicio, setfechaInicio] = useState(null);
    const [starfechaFinal, setfechaFinal] = useState(null);
    const [starfechaInicio1, setfechaInicio1] = useState(null);
    const [starfechaFinal1, setfechaFinal1] = useState(null);
    const [dataCity, setdataCity] = useState([]);
    const [City, setCity] = useState([]);
    const [StepCount, setStepCount] = useState(1);
    const [ErrocorreoElectronico, setErrocorreoElectronico] = useState("");
    const [ErroName, setErroName] = useState("");
    const [ErroLastName, setErroLastName] = useState("");
    const [ErrorDAdress, setErrorDAdress] = useState("");
    const [ErrorCityRe, setErrorCityRe] = useState("");
    const [ErrorDepa, setErrorDepa] = useState("");
    const [ErrorGenero, setErrorGenero] = useState("");
    const [ErrorNaci, setErrorNaci] = useState("");
    const [ErrorDocumento, setErrorDocumento] = useState("");
    const [ErrorNumId, setErrorNumId] = useState("");
    const [ErroPhone, setErroPhone] = useState("");
    const [stepTab, setStepTab] = useState(1);
    const [dataCityresult, setdataCityresult] = useState([]);
    const [paises, setpaises] = useState([]);
    const [dataCurriculunInfo, setdataCurriculunInfo] = useState([]);
    const [dataIdioma, setdataIdioma] = useState(nuevoIdioma);
    const [dataCurriculunInfoCo, setdataCurriculunInfoCo] = useState([]);
    const [InfoLab, setDataInfoLab] = useState([]);
    const [isValid, setisValid] = useState(Boolean);
    const [loaderShow, setLoaderShow] = useState(false);
    const [vdt, setvdt] = useState(false);
    const [vdt1, setvdt1] = useState(false);
    const [vdt4, setvdt4] = useState(false);
    const [vdt3, setvdt3] = useState(false);
    const [contadorIdioma, setcontadorIdioma] = useState([]);
    const [showInfoIdiomas, setShowInfoIdiomas] = useState(false);
    const [isSorting, setIsSorting] = useState();
    const [saveEdit, setSaveEdit] = useState([])
    const [state, setState] = useState({
        list: [
            { id: 1, icono: 'faLinkedinIn', idTexto: 'SpLink', idGuardar: 'faLinkedinInId', idLink: 'btnLink', inputGuardar: 'txtLinked' },
            { id: 2, icono: 'faFacebookF', idTexto: 'SpFace', idGuardar: 'inputguardar', idLink: 'btnFace', inputGuardar: 'txtFace' },
            { id: 3, icono: 'faGlobe', idTexto: 'SpBlog', idGuardar: 'faBlogId', idLink: 'btnBlog', inputGuardar: 'txtBlog' },
        ]
    });


    const anio = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 },
    { id: 11 }, { id: 12 }, { id: 13 }, { id: 14 }, { id: 15 }, { id: 16 }, { id: 17 }, { id: 18 }, { id: 19 }, { id: 20 },
    { id: 21 }, { id: 22 }, { id: 23 }, { id: 24 }, { id: 25 }, { id: 26 }, { id: 27 }, { id: 28 }, { id: 29 }, { id: 30 },
    { id: 31 }, { id: 32 }, { id: 33 }, { id: 34 }, { id: 35 }, { id: 36 }, { id: 37 }, { id: 38 }, { id: 39 }, { id: 40 },
    { id: 41 }, { id: 42 }, { id: 43 }, { id: 44 }, { id: 45 }, { id: 46 }, { id: 47 }, { id: 38 }, { id: 39 }, { id: 50 }];
    const jsonColores = [
        { "idColor": 1, "nombreColor": "Negro", "colorHex": "#7b8189", "colorBBDD": "#7b8189" },
        { "idColor": 2, "nombreColor": "Azul", "colorHex": "#0c79bc", "colorBBDD": "#0c79bc" },
        { "idColor": 3, "nombreColor": "Verde", "colorHex": "#017c73", "colorBBDD": "#017c73" },
        { "idColor": 4, "nombreColor": "Morado", "colorHex": "#412c9b", "colorBBDD": "#412c9b" },
        { "idColor": 5, "nombreColor": "Naranja", "colorHex": "#ee7200", "colorBBDD": "#ee7200" }
    ]
    const jsonTextoTipos = [
        { "idTipoTexto": 1, "nombreTipoTexto": "Helvetica", "tipoTextoStyle": "Helvetica" },
        { "idTipoTexto": 2, "nombreTipoTexto": "Calibri", "tipoTextoStyle": "Calibri" },
        { "idTipoTexto": 3, "nombreTipoTexto": "Georgia", "tipoTextoStyle": "Georgia" },
        { "idTipoTexto": 4, "nombreTipoTexto": "Verdana", "tipoTextoStyle": "Verdana" },
        { "idTipoTexto": 5, "nombreTipoTexto": "Tahoma", "tipoTextoStyle": "Tahoma" }
    ]
    const jsonNivel = [
        { "id": 1, "text": "Básico" },
        { "id": 2, "text": "Intermedio" },
        { "id": 3, "text": "Avanzado" }
    ]

    const [curriculumArchivos, setcurriculumArchivos] = useState({});
    useEffect(() => {
        setLoaderShow(true);
        getTypeCulum();
        getPhotoCurriculum();
        getPais();
        tabPersonalizacion();
        getTypeCity();
        getTypeProfe();
        getAspSalary();
        getArea();
        getNivelEducativo();
        getCity();
        getIdioma();
        getTipoEstudio();
        getIntensidadHoraria();
        getHabilidades();
        getPdfTemplates();
        validatorForm();
        removeSwiper();
        $("#swiper-button-prev").attr("disabled", true);
    }, []);
    const json = [
        {
            id: 5,
            value: 'Cédula de Ciudadanía'
        },
        {
            id: 6,
            value: 'Cédula de Extranjería'
        },
        {
            id: 7,
            value: 'Pasaporte'
        }
    ]
    const range = (start, end, step) => Array.from({ length: (start - end) / step + 1 }, (_, i) => end + (i * step));
    const getYear2 = (date) => {
        return new Date(date).getFullYear();
    }
    const getMonth = (date) => {
        return new Date(date).getMonth();
    }
    const years = range(getYear2(new Date()), getYear2(new Date()) - 80, 1);
    const months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];
    let isvalid1 = Boolean;
    let pasa = false
    const validatorForm = () => {
        $('#telefonoCelular').keypress(function (tecla) {
            if (tecla.charCode < 48 || tecla.charCode > 57) return false;
        });
        $('#numberInput').keypress(function (tecla) {
            if (tecla.charCode < 48 || tecla.charCode > 57) return false;
        });
        $('#nombres').keypress(function (tecla) {
            if ((tecla.charCode < 97 || tecla.charCode > 122) && (tecla.charCode < 65 || tecla.charCode > 90) && (tecla.charCode != 27) && (tecla.charCode != 241)) return false;
        });
        $('#apellidoPaterno').keypress(function (tecla) {
            if ((tecla.charCode < 97 || tecla.charCode > 122) && (tecla.charCode < 65 || tecla.charCode > 90) && (tecla.charCode != 27) && (tecla.charCode != 241)) return false;
        });
    }
    const mostrar = () => {
        if (formContact.nombres != "" &&
            formContact.apellidoPaterno != "" &&
            formContact.correoElectronico != "" &&
            formContact.dicTipoDocumento != "" &&
            formContact.identificacion != "" &&
            formContact.telefonoCelular != "" &&
            formContact.fechaNacimiento != "" &&
            formContact.departamento != "" &&
            formContact.dicCiudadResidencia != ""
        ) {
            setErroName('')
            setErroLastName('')
            setErrorDocumento('')
            setErrorNumId('')
            setErroPhone('')
            setErrorNaci('')
            setErrorGenero('')
            setErrorDepa('')
            setErrorCityRe('')
            setErrorGenero('')
            pasa = true
        }
        // //console.log(pasa," validacion");
        if (pasa) {
            RtaCtr();
        }
        var regex = /[\w-\.]{2,}@([\w-]{2,}\.)*([\w-]{2,}\.)[\w-]{2,4}/;
        if (regex.test($('#correoElectronico').val().trim())) {

            setErrocorreoElectronico('');
        } else if (!regex.test($('#correoElectronico').val().trim())) {
            setErrocorreoElectronico('La direccón de correo no es válida');
        }
        if (formContact.nombres == "") {
            setErroName('Campo requerido.')
        }
        if (formContact.apellidoPaterno == "") {
            setErroLastName('Campo requerido.')
        }
        if (formContact.dicTipoDocumento == "") {
            setErrorDocumento('Campo requerido.')
        }
        if (formContact.identificacion == "") {
            setErrorNumId('Campo requerido.')
        }
        if (formContact.telefonoCelular == "") {
            setErroPhone('Campo requerido.')
        }
        if (formContact.fechaNacimiento == "") {
            setErrorNaci('Campo requerido.')
        }
        if (formContact.genero == "") {
            setErrorGenero('Campo requerido.')
        }
        if (formContact.departamento == "") {
            setErrorDepa('Campo requerido.')
        }
        if (formContact.dicCiudadResidencia == "") {
            setErrorCityRe('Campo requerido.')
        }
        if (formContact.direccionResidencia == "") {
            setErrorDAdress('Campo obligatorio, ingresa una dirección de residencia valida')
        } else if (formContact.direccionResidencia == "" ||
            formContact.dicCiudadResidencia == "" ||
            formContact.departamento == "" ||
            formContact.genero == "" ||
            formContact.genero == "" ||
            formContact.fechaNacimiento == "" ||
            formContact.telefonoCelular == "" ||
            formContact.identificacion == "" ||
            formContact.dicTipoDocumento == "" ||
            formContact.apellidoPaterno == "" ||
            formContact.nombres == ""
        ) {
            Swal.fire({
                title: 'Ops!',
                text: `Debes diligenciar los campos que son obligatorios.`,
                type: 'error',
                customClass: 'btnModalHv',
                confirmButtonText: 'Aceptar'
            });
        }

    }
    const dataIdioma2 = false
    const [addComple, SetAddComple] = useState(false)
    const validinfoEd = (id2) => {
        //console.log(id2, ' el id que llega');
        if (id2 === 1) {
            if (formStudy.institucionEducativa !== '' &&
                formStudy.dicNivelEducativo !== '' &&
                formStudy.tituloObtenido !== '' &&
                formStudy.dicAreaEstudio !== '' &&
                formStudy.estadoEstudio !== '' &&
                formStudy.dicCiudadEstudio !== '' &&
                formStudy.fechaInicio !== ''
            ) {
                var f1 = new Date(starfechaInicio)
                var f2 = new Date(starfechaFinal)
                // //console.log(f1.toString(),f2,starfechaInicio);
                if ($('#Estado').prop('checked') && f1 >= f2) {
                    if (f1 > f2) {
                        Swal.fire({
                            width: '27em',
                            height: '20em',
                            text: `La fecha de salida no debe ser menor a la fecha de ingreso.`,
                            customClass: {
                                confirmButton: 'btnConfirmAzul',
                                closeButton: 'swal2-close-modal'
                            },
                            confirmButtonText: 'Aceptar',
                            showCloseButton: true
                        });
                    } else {
                        Swal.fire({
                            width: '27em',
                            height: '20em',
                            text: `La fecha de salida no debe ser igual a la fecha de ingreso.`,
                            customClass: {
                                confirmButton: 'btnConfirmAzul',
                                closeButton: 'swal2-close-modal'
                            },
                            confirmButtonText: 'Aceptar',
                            showCloseButton: true
                        });
                    }
                        
                }
                 else {
                    saveInfoEducativa();
                }
            } else {
                Swal.fire({
                    title: 'Ops!',
                    text: `Debes diligenciar los campos que son obligatorios.`,
                    type: 'error',
                    customClass: 'btnModalHv',
                    confirmButtonText: 'Aceptar'
                });
            }
        }
        else if (id2 === 2) {
            saveInfoEducativa()
        }
        else if (id2 === 3) {
            // //console.log(formStudyComp);
            if (
                (formStudyComp.institucionEducativa !== '' &&
                    formStudyComp.tituloObtenida !== '' &&
                    formStudyComp.dicAreaEstudio !== '' &&
                    formStudyComp.estadoEstudio !== '' &&
                    formStudyComp.dicCiudadEstudio !== '' &&
                    formStudyComp.fechaInicio !== '' &&
                    formStudyComp.dicIntensidadEstudio !== '' &&
                    formStudyComp.descripcionEducacion !== '') &&
                ((formStudyComp.dicTipoEstudio === '1616' && formStudyComp.otroEstudio !== '')
                    || (formStudyComp.dicTipoEstudio !== '1616' && formStudyComp.otroEstudio === ''))

            ) {
                var f1 = new Date(starfechaInicio1)
                var f2 = new Date(starfechaFinal1)
                if ($('#Estado1').prop('checked') && f1 >= f2) {
                    if (f1 > f2) {
                        Swal.fire({
                            width: '27em',
                            height: '20em',
                            text: `La fecha de salida no debe ser menor a la fecha de ingreso.`,
                            customClass: {
                                confirmButton: 'btnConfirmAzul',
                                closeButton: 'swal2-close-modal'
                            },
                            confirmButtonText: 'Aceptar',
                            showCloseButton: true
                        });
                    } else {
                        Swal.fire({
                            width: '27em',
                            height: '20em',
                            text: `La fecha de salida no debe ser igual a la fecha de ingreso.`,
                            customClass: {
                                confirmButton: 'btnConfirmAzul',
                                closeButton: 'swal2-close-modal'
                            },
                            confirmButtonText: 'Aceptar',
                            showCloseButton: true
                        });
                    }
                        
                }
                else {
                    saveInfoEducativa1()
                }
            } else {
                Swal.fire({
                    title: 'Ops!',
                    text: `Debes diligenciar los campos que son obligatorios.`,
                    type: 'error',
                    customClass: 'btnModalHv',
                    confirmButtonText: 'Aceptar'
                });
            }

        }
        else if (id2 === 4) {
            if (
                formInfoLab.empresa !== '' &&
                formInfoLab.cargo !== '' &&
                formInfoLab.funcioneslogros !== '' &&
                formInfoLab.fechaIngreso !== ''
            ) {
                var f1 = new Date(startDateLab)
                var f2 = new Date(startDateLabF)
                if (!$('#chkTA').is(':checked') && f1 >= f2) {
                    if (f1 > f2) {
                        Swal.fire({
                            width: '27em',
                            height: '20em',
                            text: `La fecha de salida no debe ser menor a la fecha de ingreso.`,
                            customClass: {
                                confirmButton: 'btnConfirmAzul',
                                closeButton: 'swal2-close-modal'
                            },
                            confirmButtonText: 'Aceptar',
                            showCloseButton: true
                        });
                    } else {
                        Swal.fire({
                            width: '27em',
                            height: '20em',
                            text: `La fecha de salida no debe ser igual a la fecha de ingreso.`,
                            customClass: {
                                confirmButton: 'btnConfirmAzul',
                                closeButton: 'swal2-close-modal'
                            },
                            confirmButtonText: 'Aceptar',
                            showCloseButton: true
                        });
                    }
                        
                } else {

                    saveInfoLaboral()
                    Subdatalab = true
                    setvdt(false);
                    setvdt1(false);
                }
            } else {
                Swal.fire({
                    title: 'Ops!',
                    text: `Debes diligenciar los campos que son obligatorios.`,
                    type: 'error',
                    customClass: 'btnModalHv',
                    confirmButtonText: 'Aceptar'
                });
            }

        }
        else if (id2 === 5) {
            if ((dataIdioma.id !== "" && dataIdioma.id !== undefined !== undefined && dataIdioma.id !== 0) && (dataIdioma.nivel !== "" && dataIdioma.nivel !== undefined !== undefined && dataIdioma.nivel !== 0)) {
                setcontadorIdioma([...contadorIdioma, dataIdioma])
                var resutJson = []
                contadorIdioma.map((e) => {
                    if (e.id !== "") {
                        resutJson.push(e)
                    }
                })
                resutJson.push(dataIdioma)
                formContact.idiomaNivel = JSON.stringify(resutJson);
                postDataCurriculum()
            }
            else {
                Swal.fire({
                    title: 'Ops!',
                    text: `Debes diligenciar los campos que son obligatorios.`,
                    type: 'error',
                    customClass: 'btnModalHv',
                    confirmButtonText: 'Aceptar'
                });
            }
        }
        else if (id2 === 6) {
            formStudy.institucionEducativa = ''
            formStudy.dicNivelEducativo = ''
            formStudy.tituloObtenido = ''
            formStudy.dicAreaEstudio = ''
            formStudy.estadoEstudio = ''
            formStudy.dicCiudadEstudio = ''
            formStudy.fechaInicio = ''
            Subdatalab = false
            InfoLab.length = 0
            setvdt(true);
            setvdt1(true);
        }
        else if (id2 === 10) {
            if (formStudy.institucionEducativa !== '' &&
                formStudy.dicNivelEducativo !== '' &&
                formStudy.tituloObtenido !== '' &&
                formStudy.dicAreaEstudio !== '' &&
                formStudy.estadoEstudio !== '' &&
                formStudy.dicCiudadEstudio !== '' &&
                formStudy.fechaInicio !== ''
            ) {
                var f1 = new Date(starfechaInicio)
                var f2 = new Date(starfechaFinal)
                if ($('#Estado').prop('checked') && f1 >= f2) {
                    if (f1 > f2) {
                        Swal.fire({
                            width: '27em',
                            height: '20em',
                            text: `La fecha de salida no debe ser menor a la fecha de ingreso.`,
                            customClass: {
                                confirmButton: 'btnConfirmAzul',
                                closeButton: 'swal2-close-modal'
                            },
                            confirmButtonText: 'Aceptar',
                            showCloseButton: true
                        });
                    } else {
                        Swal.fire({
                            width: '27em',
                            height: '20em',
                            text: `La fecha de salida no debe ser igual a la fecha de ingreso.`,
                            customClass: {
                                confirmButton: 'btnConfirmAzul',
                                closeButton: 'swal2-close-modal'
                            },
                            confirmButtonText: 'Aceptar',
                            showCloseButton: true
                        });
                    }
                        
                }else{

                    editInfoEducativa()
                }
            } else {
                Swal.fire({
                    title: 'Ops!',
                    text: `Debes diligenciar los campos que son obligatorios.`,
                    type: 'error',
                    customClass: 'btnModalHv',
                    confirmButtonText: 'Aceptar'
                });
            }
            SetIdEdu(0)
        }
        else if (id2 === 11) {
            if (
                (formStudyComp.institucionEducativa !== '' &&
                    formStudyComp.tituloObtenida !== '' &&
                    formStudyComp.dicAreaEstudio !== '' &&
                    formStudyComp.estadoEstudio !== '' &&
                    formStudyComp.dicCiudadEstudio !== '' &&
                    formStudyComp.fechaInicio !== '' &&
                    formStudyComp.dicIntensidadEstudio !== '' &&
                    formStudyComp.descripcionEducacion !== '') &&
                ((formStudyComp.dicTipoEstudio === '1616' && formStudyComp.otroEstudio !== '')
                    || (formStudyComp.dicTipoEstudio !== '1616' && formStudyComp.otroEstudio === ''))
            ) {
                var f1 = new Date(starfechaInicio1)
                var f2 = new Date(starfechaFinal1)
                if ($('#Estado1').prop('checked') && f1 >= f2) {
                    if (f1 > f2) {
                        Swal.fire({
                            width: '27em',
                            height: '20em',
                            text: `La fecha de salida no debe ser menor a la fecha de ingreso.`,
                            customClass: {
                                confirmButton: 'btnConfirmAzul',
                                closeButton: 'swal2-close-modal'
                            },
                            confirmButtonText: 'Aceptar',
                            showCloseButton: true
                        });
                    } else {
                        Swal.fire({
                            width: '27em',
                            height: '20em',
                            text: `La fecha de salida no debe ser igual a la fecha de ingreso.`,
                            customClass: {
                                confirmButton: 'btnConfirmAzul',
                                closeButton: 'swal2-close-modal'
                            },
                            confirmButtonText: 'Aceptar',
                            showCloseButton: true
                        });
                    }
                        
                } else {
                    setvdt3(false);
                    editInfoComplementaria()
                }
            } else {
                Swal.fire({
                    title: 'Ops!',
                    text: `Debes diligenciar los campos que son obligatorios.`,
                    type: 'error',
                    customClass: 'btnModalHv',
                    confirmButtonText: 'Aceptar'
                });
            }
            SetIdEdu(0)
        }
        else if (id2 === 12) {
            if (
                formInfoLab.empresa !== '' &&
                formInfoLab.cargo !== '' &&
                formInfoLab.funcioneslogros !== '' &&
                formInfoLab.fechaIngreso !== ''
            ) {
                var f1 = new Date(startDateLab)
                var f2 = new Date(startDateLabF)
                if (!$('#chkTA').is(':checked') && f1 >= f2) {
                    if (f1 > f2) {
                        Swal.fire({
                            width: '27em',
                            height: '20em',
                            text: `La fecha de salida no debe ser menor a la fecha de ingreso.`,
                            customClass: {
                                confirmButton: 'btnConfirmAzul',
                                closeButton: 'swal2-close-modal'
                            },
                            confirmButtonText: 'Aceptar',
                            showCloseButton: true
                        });
                    } else {
                        Swal.fire({
                            width: '27em',
                            height: '20em',
                            text: `La fecha de salida no debe ser igual a la fecha de ingreso.`,
                            customClass: {
                                confirmButton: 'btnConfirmAzul',
                                closeButton: 'swal2-close-modal'
                            },
                            confirmButtonText: 'Aceptar',
                            showCloseButton: true
                        });
                    }
                        
                } else {
                    setvdt4(false);
                    editInfoLaboral();
                }
            } else {
                Swal.fire({
                    title: 'Ops!',
                    text: `Debes diligenciar los campos que son obligatorios.`,
                    type: 'error',
                    customClass: 'btnModalHv',
                    confirmButtonText: 'Aceptar'
                });
            }
            SetIdEdu(0)
        }
        else if (id2 === 13) {
            formStudyComp.institucionEducativa = ''
            formStudyComp.tituloObtenido = ''
            formStudyComp.dicAreaEstudio = ''
            formStudyComp.estadoEstudio = ''
            formStudyComp.dicCiudadEstudio = ''
            formStudyComp.fechaInicio = ''
            formStudyComp.dicIntensidadEstudio = ''
            formStudyComp.descripcionEducacion = ''
            formStudyComp.dicTipoEstudio = ''
            setvdt3(true)
            SetAddComple(true)
        }
    }
    const infoSheduling = {
        paddingSheduling: 'contenerPadding',
        typeSheduling: 2023,
        classTitleSheduling: 'fechahoraTitulo',
        classVolver: 'btnBackGrey',
        typeTextSheduling: <h5 className="typeShedulingText">Consultoría mejora de hoja de vida</h5>,
        module: 'HojaVida',
        tittleSheduling: 'Crea tu hoja de vida.',
        paragraph: 'Para prestarte un servicio adecuado por favor confirma los siguientes datos:',
        alertCancelTitle: 'Cancela tu asesoría',
        alertCancelText: '¿Estás seguro de cancelar tu consultoría?',
        tooltipsClass: 'tooltipShedulingEco',
        messageSuccess: {
            module: 'Seguridad',
            variant: 'success',
            checkText: 'Confirmación de consultoría éxitosa',
            alertText: 'La revisión de tu hoja de vida se agendó exitosamente',
            class: 'alert alertSuccessEcosistemas',
        },
        messageCancel: {
            module: "Desempleo",
            variant: 'success',
            checkText: 'Tu consultoría ha sido cancelada con éxito',
            alertText: 'Si deseas programar una nueva consultoría, haz clic a continuación.',
            class: 'alert alertDesempleo',
            textButton: "AGENDAR REVISIÓN"
        }
    };
    const getTypeCity = () => {
        Axios.get(`${urlAPI}/Hoja/tipos/1006`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setdataCity(succ.data.data);
        }).catch(error => {
            //console.log(error);
        });
    }
    const getCity = () => {
        Axios.get(`${urlAPI}/Hoja/tipos/7`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setCity(succ.data.data);
        }).catch(error => {
            //console.log(error);
        });
    }
    const getTypeCityResult = (_id) => {
        Axios.get(`${urlAPI}/Login/CiudadesDepartamento/${_id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setdataCityresult(succ.data.data);
        }).catch(error => {
            //console.log(error);
        });
    }
    const getNivelEducativo = () => {
        Axios.get(`${urlAPI}/Hoja/tipos/6`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setdataTypeEducation(succ.data.data);
        }).catch(error => {
            //console.log(error);
        });
    }
    const getArea = () => {
        Axios.get(`${urlAPI}/Hoja/tipos/3`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setdataTypeArea(succ.data.data);
        }).catch(error => {
            //console.log(error);
        });
    }
    const getIdioma = () => {
        Axios.get(`${urlAPI}/Hoja/tipos/10`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setddataTypeIdioma(succ.data.data);
            formatearIdioma()
        }).catch(error => {
            //console.log(error);
        });
    }

    const getTypeProfe = () => {

        Axios.get(`${urlAPI}/Hoja/tipos/2`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setdataTypeProfetion(succ.data.data);
        }).catch(error => {
            //console.log(error);
        });
    }
    const getAspSalary = () => {
        Axios.get(`${urlAPI}/Hoja/tipos/4`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setdataTypeSalary(succ.data.data);
        }).catch(error => {
            //console.log(error);
        });
    }
    let pais
    let habilidades
    const getPais = () => {
        Axios.get(`${urlAPI}/Hoja/tipos/5`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setdataTypePais(succ.data.data);
            pais = succ.data.data
        }).catch(error => {
            //console.log(error);
        });
    }
    let Subdata = false;
    let SubdataCo = false;
    let Subdatalab = false;
    const [imgRtaDB1, setimgRtaDB1] = useState('')
    let rutafta;
    const getPhotoCurriculum = () => {
        Axios.get(`${urlAPI}/Hoja/mostrarImagen/${getUser().idUsuario}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            if (succ.data == "No tiene foto disponible") {
                setimgRtaDB1(logo1);
            } else {
                setimgRtaDB1("data:image/jpeg;base64," + succ.data);
            }
            // //console.log(imgRtaDB1.toString(), ' que recibe la imagen');
        }).catch(error => {
            //console.log(error);
        });
    }
    const getTypeCulum = () => {
        Axios.get(`${urlAPI}/Hoja/TraeDatosPersonalesIdUser/${getUser().idUsuario}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setFormContact(succ.data.data);
            setLoaderShow(false);
            setformStudy({ ...formStudy, idDatosPersonales: succ.data.data.idDatosPersonales })
            setformStudyComp({ ...formStudyComp, idDatosPersonales: succ.data.data.idDatosPersonales })
            setformInfoLab({ ...formInfoLab, idDatosPersonales: succ.data.data.idDatosPersonales })
            getPhotoCurriculum();
            if (succ.data.data.idiomaNivel !== "" && succ.data.data.idiomaNivel !== undefined && succ.data.data.idiomaNivel !== null) {
                setcontadorIdioma(JSON.parse(succ.data.data.idiomaNivel))
            }
            if (succ.data.data.redesSociales !== null) {

                if (succ.data.data.redesSociales.length > 0) {
                    for (let y = 0; y = succ.data.data.redesSociales.length; y++) {
                        socialRedrr[y] = succ.data.data.redesSociales.split(',')[y]
                    }
                }
            }
            if (succ.data.data.EntHojaVidaInformacionEducativa.length > 0) {
                document.getElementById('EducacionIt').classList.add('item-wrap-exito')
                let vl = succ.data.data.EntHojaVidaInformacionEducativa.map(data => { return (data.esComplementario) })
                for (let z = 0; z < succ.data.data.EntHojaVidaInformacionEducativa.length; z++) {
                    setdataCurriculunInfoCo(succ.data.data.EntHojaVidaInformacionEducativa.filter(x => x.esComplementario == true))

                    setdataCurriculunInfo(succ.data.data.EntHojaVidaInformacionEducativa.filter(x => x.esComplementario == false))


                }

            } if (succ.data.data.EntHojaVidaInformacionEducativa.length === 0) {
                document.getElementById('EducacionIt').classList.remove('item-wrap-exito')
            }
            if (succ.data.data.EntExperienciaLaboral.length > 0) {
                Subdatalab = true
                document.getElementById('ExperienciaIt').classList.add('item-wrap-exito')
                document.getElementById('labinfo').classList.remove('d-none')
                document.getElementById('labinfo').classList.add('d-block')

                setDataInfoLab(succ.data.data.EntExperienciaLaboral);
            }
            if (succ.data.data.EntExperienciaLaboral.length === 0) {
                Subdatalab = false
                document.getElementById('ExperienciaIt').classList.remove('item-wrap-exito')
                document.getElementById('labinfo').classList.remove('d-block')
                document.getElementById('labinfo').classList.add('d-none')
            }
            if (succ.data.data.paisesTrabajar !== null && succ.data.data.paisesTrabajar !== undefined) {

                let value = JSON.parse(succ.data.data.paisesTrabajar)
                for (let index = 0; index < value.length; index++) {
                    updateCountryInitial(value[index])
                }
            }
            // updateCountry()

        }).catch(error => {
            //console.log(error);
        });
    }
    const getTipoEstudio = () => {
        Axios.get(`${urlAPI}/Hoja/tipos/8`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setdataTypeStudy(succ.data.data);
        }).catch(error => {
            //console.log(error);
        });
    }
    const getIntensidadHoraria = () => {
        Axios.get(`${urlAPI}/Hoja/tipos/9`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setIntensidadHoraria(succ.data.data);
        }).catch(error => {
            //console.log(error);
        });
    }
    const getPdfTemplates = () => {
        Axios.get(`${urlAPI}/Hoja/plantillas`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setdataTypeTemplate(succ.data);
        }).catch(error => {
            //console.log(error);
        });
    }
    const getHabilidades = (e) => {
        Axios.get(`${urlAPI}/Hoja/tipos/1004`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setHabilidades(succ.data.data);
            habilidades = succ.data.data
            // let value1 = JSON.parse(succ.data.data.habilidadyaptitud)
            // for (let index = 0; index < value1.length; index++) {
            //     updatehabilidadesInitial(value1[index])
            //     }
        }).catch(error => {
            //console.log(error);
        });
    }
    const getInfoEducativa = () => {
        Axios.post(`${urlAPI}/Hoja/InsertaEstudioPrincipal/${getUser.idUsuario}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken()
            }
        }).then(succ => {
            Swal.fire({
                imageUrl: iconCheck,
                width: '27em',
                height: '20em',
                text: `Tus datos se han guardado con éxito.`,
                customClass: {
                    confirmButton: 'btnConfirmAzul',
                    closeButton: 'swal2-close-modal'
                },
                confirmButtonText: 'Aceptar',
                showCloseButton: true
            });
        }).catch(error => {
            //console.log(error);
        });
    }
    const handleDate = (date) => {
        var strDate = date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
        setStartDate(date);
        setFormContact({ ...formContact, fechaNacimiento: strDate.toString() });
    }
    const handleDate1 = (date_) => {
        let strDate = date_.getFullYear() + "-" + ((date_.getMonth() + 1) < 10 ? '0' + (date_.getMonth() + 1) : (date_.getMonth() + 1)) + "-" + (date_.getDate() < 10 ? '0' + date_.getDate() : date_.getDate())
        ////console.log(strDate.toString()," del primero la srt y la date_ ", date_);
        setfechaInicio(date_);
        setformStudy({ ...formStudy, fechaInicio: strDate.toString() });
    }
    const handleDate2 = (date_) => {
        let strDate1 = date_.getFullYear() + "-" + ((date_.getMonth() + 1) < 10 ? '0' + (date_.getMonth() + 1) : (date_.getMonth() + 1)) + "-" + (date_.getDate() < 10 ? '0' + date_.getDate() : date_.getDate())
        ////console.log(strDate1.toString()," la srt y la date_ ", date_);
        setfechaFinal(date_);
        setformStudy({ ...formStudy, fechaFin: strDate1.toString() });
    }
    const handleDate3 = (date_) => {
        var strDate = date_.getFullYear() + "-" + ((date_.getMonth() + 1) < 10 ? '0' + (date_.getMonth() + 1) : (date_.getMonth() + 1)) + "-" + (date_.getDate() < 10 ? '0' + date_.getDate() : date_.getDate())
        setfechaFinal1(date_);
        setformStudyComp({ ...formStudyComp, fechaFin: strDate.toString() });
    }
    const handleDate5 = (date_) => {
        var strDate = date_.getFullYear() + "-" + ((date_.getMonth() + 1) < 10 ? '0' + (date_.getMonth() + 1) : (date_.getMonth() + 1)) + "-" + (date_.getDate() < 10 ? '0' + date_.getDate() : date_.getDate())
        setfechaInicio1(date_);
        setformStudyComp({ ...formStudyComp, fechaInicio: strDate.toString() });
    }
    const handleDate4 = (date) => {
        var strDate = date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
        setStartDateLab(date);
        setformInfoLab({ ...formInfoLab, fechaIngreso: strDate.toString() });
    }
    const handleDate6 = (date) => {
        var strDate = date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
        setStartDateLabF(date);
        setformInfoLab({ ...formInfoLab, fechaSalida: strDate.toString() });
    }
    const [pdf_, setpdf_] = useState('');
    const postDataCurriculum = () => {
        // //console.log(formContact.idiomaNivel,' plantilla', formContact);
        Axios.post(`${urlAPI}/Hoja/UpdateDatosPersonales`, JSON.stringify(formContact), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken()
            }
        }).then(succ => {
            if (StepCount !== 4) {

                Swal.fire({
                    imageUrl: iconCheck,
                    width: '27em',
                    height: '20em',
                    text: `Tus datos se han guardado con éxito.`,
                    customClass: {
                        confirmButton: 'btnConfirmAzul',
                        closeButton: 'swal2-close-modal'
                    },
                    confirmButtonText: 'Aceptar',
                    showCloseButton: true
                });
            }
            let value = cp.replace('#', '')
            // //console.log(value,' value');
            Axios.get(`${urlAPI}/Hoja/actualizarPlantilla/` + (idPl + 1) + `?user=` + getUser().idUsuario + `&tipoTexto=` + tp + `&colorHv=` + value, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getToken()
                }
            }).then(data => {
                Axios.get(`${urlAPI}/Hoja/PdfJson/${getUser().idUsuario}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken()
                    }
                }).then(data => {
                    // //console.log(data,' data plantilla');

                    setpdf_('data:application/pdf;base64, ' + data.data);
                    document.querySelector("iframe").src = pdf_;
                }).catch(error => {
                    //console.log(error);
                });
            })
        }).catch(error => {
            //console.log(error);
        });
    }
    const postDataCurriculum1 = () => {
        // //console.log(formContact.idiomaNivel,' plantilla', formContact);
        let rtaAct;
        if (formContact.idiomaNivel.length !== 0 || formContact.idiomaNivel.length !== undefined) {
            rtaAct = 1
        } else {
            rtaAct = 2
        }
        Axios.post(`${urlAPI}/Hoja/UpdateDatosPersonales`, JSON.stringify(formContact), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken()
            }
        }).then(succ => {
            // //console.log(succ.data,' guarda de nuevo');
            // succ.data
            if (rtaAct === 2) {
                Swal.fire({
                    title: 'Eliminado!',
                    text: 'El Registro fue eliminado Correctamente',
                    type: 'success',
                    customClass: 'btnModalHv',
                    confirmButtonText: 'Aceptar'
                });
            }
            if (rtaAct === 1) {
                Swal.fire({
                    title: 'Guardado',
                    text: 'El Registro fue Guardado Correctamente',
                    type: 'success',
                    confirmButtonText: 'Aceptar'
                });
            }


        }).catch(error => {
            //console.log(error);
        });
    }
    const deleteInfoEducativa = (e) => {
        Swal.fire({
            title: '¿Seguro quieres eliminar tu estudio?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            customClass: 'mdlDelete',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            //   //console.log(result.value);
            if (result.value) {
                Axios.post(`${urlAPI}/Hoja/NoVigenteEstudio/${e}`, null, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                    }
                }).then(succ => {
                    //console.log(succ.data);
                    Swal.fire({
                        title: 'Eliminado!',
                        text: 'El Registro fue eliminado Correctamente',
                        type: 'success',
                        customClass: 'btnModalHv',
                        confirmButtonText: 'Aceptar'
                    });
                    dataCurriculunInfo.length = dataCurriculunInfo.length - 1;
                    CleanInput(1);
                    getTypeCulum()
                }).catch(error => {
                    //console.log(error);
                });

            }

        })
    }
    const CleanInput = (id) => {
        if (id === 1) {
            formStudy.institucionEducativa = "";
            formStudy.dicNivelEducativo = "";
            formStudy.tituloObtenido = "";
            formStudy.dicAreaEstudio = "";
            formStudy.estadoEstudio = "";
            formStudy.dicCiudadEstudio = "";
            formStudy.fechaInicio = "";
            formStudy.fechaFin = "";
            formStudy.dicIntensidadEstudio = "";
            formStudy.dicTipoEstudio = "";
            formStudy.otroEstudio = "";
            formStudy.dicPaisExtranjero = "";
            formStudy.descripcionEducacion = "";
        }
        else if (id === 2) {
            formStudyComp.institucionEducativa = '';
            formStudyComp.dicNivelEducativo = '';
            formStudyComp.tituloObtenido = '';
            formStudyComp.dicAreaEstudio = '';
            formStudyComp.estadoEstudio = '';
            formStudyComp.dicCiudadEstudio = '';
            formStudyComp.fechaInicio = null;
            formStudyComp.fechaFinal = '';
            formStudyComp.dicIntensidadEstudio = '';
            formStudyComp.dicTipoEstudio = '';
            formStudyComp.otroEstudio = '';
            formStudyComp.dicPaisExtranjero = '';
            formStudyComp.descripcionEducacion = '';
        }
    }
    const deleteInfoEducativa1 = (e) => {
        Swal.fire({
            title: '¿Seguro quieres eliminar tu estudio complementario?',
            type: 'question',
            showCancelButton: true,
            customClass: 'mdlDelete',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                Axios.post(`${urlAPI}/Hoja/NoVigenteEstudio/${e}`, null, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                    }
                }).then(succ => {
                    // //console.log(succ.data);
                    Swal.fire({
                        title: 'Eliminado!',
                        text: 'El Registro fue eliminado Correctamente',
                        type: 'success',
                        customClass: 'btnModalHv',
                        confirmButtonText: 'Aceptar'
                    });
                    dataCurriculunInfoCo.length = dataCurriculunInfoCo.length - 1;
                    CleanInput(2);
                    getTypeCulum()
                    SubdataCo = false
                }).catch(error => {
                    //console.log(error);
                });

            }

        })
    }
    const deleteInfoIdioma = (_id) => {
        Swal.fire({
            title: '¿Seguro quieres eliminar tu idioma?',
            type: 'question',
            showCancelButton: true,
            customClass: 'mdlDelete',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {

                let info = dataTypeIdioma.findIndex(x => x.id == _id);
                contadorIdioma.splice(info, 1);
                formContact.idiomaNivel = "[" + contadorIdioma.splice(info, '') + "]";
                // //console.log(formContact.idiomaNivel,' splice');
                contadorIdioma.length = 0
                postDataCurriculum1()
            }
        });
    }
    const deleteInfoLaboral = (e) => {
        Swal.fire({
            title: '¿Seguro quieres eliminar tu experiencia laboral?',
            text: 'no podra revertir este cambio.',
            type: 'question',
            showCancelButton: true,
            customClass: 'mdlDelete',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {

                getTypeCulum()
                Axios.post(`${urlAPI}/Hoja/NoVigenteLaboral/${e}`, null, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                    }
                }).then(succ => {
                    InfoLab.length = 0
                    Swal.fire({
                        title: 'Eliminado!',
                        text: 'El Registro fue eliminado Correctamente',
                        type: 'success',
                        customClass: 'btnModalHv',
                        confirmButtonText: 'Aceptar'
                    });
                },
                    (error) => {
                        //console.log(error);

                    }
                );
            }
        });
    }
    const saveInfoEducativa1 = () => {
        Axios.post(`${urlAPI}/Hoja/InsertaEstudioComplementario`, JSON.stringify(formStudyComp), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken()
            }
        }).then(succ => {
            Swal.fire({
                imageUrl: iconCheck,
                width: '27em',
                height: '20em',
                text: `Tus datos se han guardado con éxito.`,
                customClass: {
                    confirmButton: 'btnConfirmAzul',
                    closeButton: 'swal2-close-modal'
                },
                confirmButtonText: 'Aceptar',
                showCloseButton: true
            });
            defaultFormEducomp();
            if (succ.data.mensaje === "Estudio complementario agregado") {
                CleanInput(2);
                setvdt3(false)
                getTypeCulum()
            }
        }).catch(error => {
            //console.log(error);
        });
    }
    const saveInfoEducativa = () => {
        // //console.log(JSON.stringify(formStudy), ' info educativa');
        Axios.post(`${urlAPI}/Hoja/InsertaEstudioPrincipal`, JSON.stringify(formStudy), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken()
            }
        }).then(succ => {
            // //console.log(succ.data);
            getTypeCulum()
            Swal.fire({
                imageUrl: iconCheck,
                width: '27em',
                height: '20em',
                text: `Tus datos se han guardado con éxito.`,
                customClass: {
                    confirmButton: 'btnConfirmAzul',
                    closeButton: 'swal2-close-modal'
                },
                confirmButtonText: 'Aceptar',
                showCloseButton: true
            });
            setvdt(false);
        }).catch(error => {
            //console.log(error);
        });
    }
    const descargarPdf = (id) => {
        if (id === 1) {
            const downloadLink = document.createElement("a");
            const fileName = "CurriculumVitae.pdf";

            downloadLink.href = pdf_;
            downloadLink.download = fileName;
            downloadLink.click();
        } else {
            document.getElementById('closemyModal').click()
        }

    }
    const saveInfoLaboral = () => {
        Axios.post(`${urlAPI}/Hoja/InsertaExperienciaLaboral`, JSON.stringify(formInfoLab), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken()
            }
        }).then(succ => {
            Swal.fire({
                imageUrl: iconCheck,
                width: '27em',
                height: '20em',
                text: `Tus datos se han guardado con éxito.`,
                customClass: {
                    confirmButton: 'btnConfirmAzul',
                    closeButton: 'swal2-close-modal'
                },
                confirmButtonText: 'Aceptar',
                showCloseButton: true
            });
            getTypeCulum()
        }).catch(error => {
            //console.log(error);
        });
    }
    const savePhotoCurriculum = () => {
        Axios.post(`${urlAPI}/Hoja/subirArchivo`, JSON.stringify(imgRtaDB), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken()
            }
        }).then(succ => {
            Swal.fire({
                title: 'OK',
                text: 'La foto fue cargada.',
                type: 'success',
                confirmButtonText: 'Aceptar'
            });
            getPhotoCurriculum();
        }).catch(error => {
            //console.log(error);
        });
    }
    const [idCity, setIdCity] = useState([])
    const [idhabilidades, setIdhabilidades] = useState([])
    let value1 = []
    const updateCountryInitial = (e) => {
        if (pais != undefined) {

            let value = pais.filter((x) => { return (x.id == e) })
            let final = value.map(data => { return (data.text) })
            setpaises(paises => [...paises, { value: final.toString() }])
        }
    }
    const updatehabilidadesInitial = (e) => {
        if (habilidades != undefined) {
            let value = habilidades.filter((x) => { return (x.id == e) })
            let final = value.map(data => { return (data.text) })
            setHabilidades(habilidades => [...habilidades, { value: final.toString() }])
        }
    }
    const updateCountry = (e) => {
        let value = dataTypePais.filter((x) => { return (x.id == e.target.value) })
        let final = value.map(data => { return (data.text) })
        setpaises(paises => [...paises, { value: final.toString() }])
        let idpais = parseInt(e.target.value)
        setIdCity([...idCity, idpais])
    }
    const updatehabilidades = (e) => {
        let value = Habilidades.filter((x) => { return (x.id == e.target.value) })
        let final = value.map(data => { return (data.text) })
        setHabilidades(habilidades => [...habilidades, { value: final.toString() }])
        let idhabilidad = parseInt(e.target.value)
        setIdhabilidades([...idhabilidades, idhabilidad])
    }
    const handleFormContact = (e) => {
        let num;
        setFormContact({ ...formContact, [e.target.name]: e.target.value });

        num = document.getElementById("departamento");
        getTypeCityResult(num.value);
    }
    const handleFormContact1 = (e) => {
        let estado;
        setformStudy({ ...formStudy, [e.target.name]: e.target.value });
        estado = $('#Estado').is(':checked');
        if (estado) {
            estado = document.getElementById("Finalizardiv").classList.remove('d-none');
            estado = document.getElementById("Finalizardiv").classList.add('d-block');
        } else {
            estado = document.getElementById("Finalizardiv").classList.remove('d-block');
            estado = document.getElementById("Finalizardiv").classList.add('d-none');
        }
    }
    const handleFormContact3 = (e) => {
        let estado = false;
        setformInfoLab({ ...formInfoLab, [e.target.name]: e.target.value });
        estado = $('#chkTA').is(':checked');
        // //console.log(estado,' esto es el estado');
        if (InfoLab.length === 0) {

            if (estado && estado !== null) {
                estado = document.getElementById("fNal").classList.remove('d-block');
                estado = document.getElementById("fNal").classList.add('d-none');
            } else if (!estado && estado !== null) {
                estado = document.getElementById("fNal").classList.remove('d-none');
                estado = document.getElementById("fNal").classList.add('d-block');
            }
        }
    }
    const handleFormContact2 = (e) => {
        let estado, estado1;
        setformStudyComp({ ...formStudyComp, [e.target.name]: e.target.value });
        estado = $('#Estado').is(':checked');
        estado1 = $('#Estado1').is(':checked');
        if (estado1) {
            estado = document.getElementById("Finalizardiv1").classList.remove('d-none');
            estado = document.getElementById("Finalizardiv1").classList.add('d-block');
        } else {
            estado = document.getElementById("Finalizardiv1").classList.remove('d-block');
            estado = document.getElementById("Finalizardiv1").classList.add('d-none');
        }
    }
    const handleIdioma = (e) => {
        setdataIdioma({ ...dataIdioma, [e.target.name]: e.target.value });
        // //console.log(dataIdioma,' idioma');
    }
    const ctrRta = (id) => {
        if (id === 1) {
            setStepCount(1)
            window.history.pushState(null, '', '')
            document.getElementById('DEducacion').classList.remove('current');
            document.getElementById('hTabTrabajos1').classList.remove('current');
            document.getElementById('hTabDiseno1').classList.remove('current');
        } else if (id === 2) {
            setStepCount(2);
            window.history.pushState(null, '', '/s/desempleo/mejorar-hoja-de-vida/crear-hv/educacion')
            document.getElementById('DEducacion').classList.add('current');
            document.getElementById('hTabTrabajos1').classList.remove('current');
            document.getElementById('hTabDiseno1').classList.remove('current');
        } else if (id === 3) {
            setStepCount(3);
            window.history.pushState(null, '', '/s/desempleo/mejorar-hoja-de-vida/crear-hv/experiencia-laboral')
            document.getElementById('hTabTrabajos1').classList.add('current');
            document.getElementById('DEducacion').classList.remove('current');
            document.getElementById('hTabDiseno1').classList.remove('current');
        } else if (id === 4) {
            setStepCount(4);
            tabPersonalizacion();
            window.history.pushState(null, '', '/s/desempleo/mejorar-hoja-de-vida/crear-hv/diseño')
            document.getElementById('DEducacion').classList.remove('current');
            document.getElementById('hTabTrabajos1').classList.remove('current');
            document.getElementById('hTabDiseno1').classList.add('current');
        }
        else if (id === 5) {
            setStepCount(5)
            window.history.pushState(null, '', '/s/desempleo/mejorar-hoja-de-vida/crear-hv/finalizada')
        }
    }
    const RtaCtr = () => {
        if (StepCount === 1) {
            for (let index = 0; index < idCity.length; index++) {
                value1.push(idCity[index])
            }
            formContact.paisesTrabajar = JSON.stringify(value1)
            postDataCurriculum();
            setStepCount(2)
            window.history.pushState(null, '', '/s/desempleo/mejorar-hoja-de-vida/crear-hv/educacion')
            document.getElementById('DEducacion').classList.add('current');
        }
        else if (StepCount === 2) {
            setStepCount(3)
            window.history.pushState(null, '', '/s/desempleo/mejorar-hoja-de-vida/crear-hv/experiencia-laboral')
            document.getElementById('DEducacion').classList.remove('current');
            document.getElementById('hTabTrabajos1').classList.add('current');
        } else if (StepCount === 3) {
            prenetx()
            setStepCount(4)
            getTypeCulum();
            tabPersonalizacion();
            window.history.pushState(null, '', '/s/desempleo/mejorar-hoja-de-vida/crear-hv/diseño')
            document.getElementById('hTabTrabajos1').classList.remove('current');
            document.getElementById('hTabDiseno1').classList.add('current');
        } else if (StepCount === 4) {
            postDataCurriculum();
            setStepCount(5)
        }
        else {
            Swal.fire({
                imageUrl: iconCheck,
                width: '27em',
                height: '20em',
                text: `Todos los campos son obligatorios.`,
                customClass: {
                    confirmButton: 'btnConfirmAzul',
                    closeButton: 'swal2-close-modal'
                },
                confirmButtonText: 'Aceptar',
                showCloseButton: true
            });
        }
    }
    const prenetx = () => {
        let swiper = document.getElementById('swiper2')
        if (swiper != null) {
            swiper.classList.add('swiper-slide-active')
        }

    }
    const params = {
        initialSlide: 3,
        effect: 'coverflow',
        grabCursor: true,
        observer: true,
        observeParents: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 1,
        coverflowEffect: {
            rotate: 0,
            stretch: 120,
            depth: 60,
            modifier: 1,
            slideShadows: false,
        },
    }
    const editInfoEducativa = () => {
        Axios.post(`${urlAPI}/Hoja/EditarEstudioPrincipal`, JSON.stringify(formStudy), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken()
            }
        }).then(succ => {
            Swal.fire({
                imageUrl: iconCheck,
                width: '27em',
                height: '20em',
                text: `La información fue actualizada con éxito.`,
                customClass: {
                    confirmButton: 'btnConfirmAzul',
                    closeButton: 'swal2-close-modal',
                    popup:'scloshh'
                },
                confirmButtonText: 'Aceptar',
                showCloseButton: true
            });
            setvdt(false);
            getTypeCulum();
        }).catch(error => {
            //console.log(error);
        });

    }
    const editInfoComplementaria = () => {
        Axios.post(`${urlAPI}/Hoja/EditarEstudioComplementario`, JSON.stringify(formStudyComp), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken()
            }
        }).then(succ => {
            // //console.log(succ);
            Swal.fire({
                imageUrl: iconCheck,
                width: '27em',
                height: '20em',
                text: `La información fue actualizada con éxito.`,
                customClass: {
                    confirmButton: 'btnConfirmAzul',
                    closeButton: 'swal2-close-modal'
                },
                confirmButtonText: 'Aceptar',
                showCloseButton: true
            });
            getTypeCulum();
        }).catch(error => {
            //console.log(error);
        });

    }
    const editInfoLaboral = () => {
        Axios.post(`${urlAPI}/Hoja/EditaExperienciaLaboral`, JSON.stringify(formInfoLab), {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken()
            }
        }).then(succ => {
            // //console.log(succ);
            Swal.fire({
                imageUrl: iconCheck,
                width: '27em',
                height: '20em',
                text: `La información fue actualizada con éxito.`,
                customClass: {
                    confirmButton: 'btnConfirmAzul',
                    closeButton: 'swal2-close-modal'
                },
                confirmButtonText: 'Aceptar',
                showCloseButton: true
            });
            getTypeCulum();
        }).catch(error => {
            //console.log(error);
        });

    }
    const removeSwiper = () => {
        let screen = window.matchMedia("(max-width: 525px)");
        let styleT = document.getElementsByClassName('swiper-container swiper-container-initialized swiper-container-horizontal')[0]
        // //console.log(screen.matches,'pantalla', styleT);
        if (screen.matches) {
            styleT.className = 'swiper-container-initialized swiper-container-horizontal swipe1'
        }
    }
    const clickNavigation = (id) => {
        // let idNext = [];

        if (id == 1) {
            // idNext.push(document.getElementsByClassName('swiper-slide-active'))
            var testElements = document.getElementsByClassName('swiper-slide-active');
            var testDivs = Array.prototype.filter.call(testElements, function (testElement) {
                return testElement.nodeName === 'DIV';
            });
            let mapeoNext = testDivs.map(data => { return (data.id) })
            if (mapeoNext !== undefined && mapeoNext !== null) {
                $("#swiper-button-prev").attr("disabled", false);
                let siguientecar = parseInt(mapeoNext[0].split('swiper')[1])
                document.getElementById(mapeoNext[0]).classList.replace('swiper-slide-active', 'swiper-slide-prev');
                if (mapeoNext[0] !== 'swiper0') {
                    document.getElementById('swiper' + (siguientecar - 1)).classList.remove('swiper-slide-prev');
                }
                if (document.getElementById('swiper' + (siguientecar + 1)) !== null) {
                    let numI = 217;
                    let numf = numI - 65;
                    numI = numf;
                    document.getElementById('swiper' + (siguientecar + 1)).classList.replace('swiper-slide-next', 'swiper-slide-active');
                    let styleT = document.getElementsByClassName('swiper-wrapper')[0]
                    styleT.className = 'swiper-wrapper swiper-containert' + (siguientecar + 1)
                }
                if (document.getElementById('swiper' + (siguientecar + 2)) !== null) {

                    document.getElementById('swiper' + (siguientecar + 2)).classList.add('swiper-slide-next');
                }
                if (mapeoNext[0] === 'swiper3') {
                    $("#swiper-button-next").attr("disabled", true);
                }
                if (mapeoNext[0] !== 'swiper3') {
                    $("#swiper-button-next").attr("disabled", false);
                }
            }

            // //console.log(mapeoNext);
        }
        if (id == 2) {
            // idNext.push(document.getElementsByClassName('swiper-slide-active'))
            var testElements = document.getElementsByClassName('swiper-slide-active');
            var testDivs = Array.prototype.filter.call(testElements, function (testElement) {
                return testElement.nodeName === 'DIV';
            });
            // //console.log(testDivs);
            let mapeoNext = testDivs.map(data => { return (data.id) })
            if (mapeoNext !== undefined && mapeoNext !== null) {
                $("#swiper-button-next").attr("disabled", false);
                // //console.log('tro dijo la mudaa');


                let siguientecar = parseInt(mapeoNext[0].split('swiper')[1])
                document.getElementById(mapeoNext[0]).classList.replace('swiper-slide-active', 'swiper-slide-next');
                if (document.getElementById('swiper' + (siguientecar + 1)) !== null) {
                    // //console.log('que hprra mas developererhdfw','swiper' + (siguientecar + 1));
                    document.getElementById('swiper' + (siguientecar + 1)).classList.remove('swiper-slide-next');
                }
                if (document.getElementById('swiper' + (siguientecar - 1)) !== null) {
                    let numI = 217;
                    let numf = numI - 65;
                    // //console.log('que hprra mas developererhdfw1','swiper' + (siguientecar - 1));
                    numI = numf;
                    document.getElementById('swiper' + (siguientecar - 1)).classList.replace('swiper-slide-prev', 'swiper-slide-active');
                    let styleT = document.getElementsByClassName('swiper-wrapper')[0]
                    styleT.className = 'swiper-wrapper swiper-containert1' + (siguientecar + 1)
                }
                if (document.getElementById('swiper' + (siguientecar - 2)) !== null) {

                    // //console.log('que hprra mas developererhdfw2','swiper' + (siguientecar + 2));
                    document.getElementById('swiper' + (siguientecar - 2)).classList.add('swiper-slide-prev');
                }
                if (mapeoNext[0] === 'swiper1') {
                    $("#swiper-button-prev").attr("disabled", true);
                }

            }

            // //console.log(mapeoNext);
        }
    }
    const [IdEdu, SetIdEdu] = useState(0)
    const EditEduInfo = (idInfo) => {

        setvdt(true);
        setvdt1(true);
        SetIdEdu(idInfo)
        let IdInfoEd = dataCurriculunInfo.filter(x => x.idInformacionEducativa == idInfo);
        formStudy.idInformacionEducativa = idInfo
        formStudy.institucionEducativa = (IdInfoEd.map(data => { return (data.institucionEducativa) })).toString()
        formStudy.dicNivelEducativo = (IdInfoEd.map(data => { return (data.dicNivelEducativo) })).toString()
        formStudy.tituloObtenido = (IdInfoEd.map(data => { return (data.tituloObtenido) })).toString()
        formStudy.dicAreaEstudio = (IdInfoEd.map(data => { return (data.dicAreaEstudio) })).toString()
        formStudy.estadoEstudio = (IdInfoEd.map(data => { return (data.estadoEstudio) })).toString()
        formStudy.dicCiudadEstudio = (IdInfoEd.map(data => { return (data.dicCiudadEstudio) })).toString()
        formStudy.fechaInicio = (IdInfoEd.map(data => { return (data.fechaInicio) })).toString()
        $("#dicAreaEstudio").val((IdInfoEd.map(data => { return (data.dicAreaEstudio) })).toString())
        $("#dicCiudadEstudio").val((IdInfoEd.map(data => { return (data.dicCiudadEstudio) })).toString())
        handleDate1(new Date(IdInfoEd.map(data => { return (data.fechaInicio) }).toString()))
        let stateStu = formStudy.estadoEstudio === 'Finalizado' ? 'Estado' :
            formStudy.estadoEstudio === 'En proceso' ? 'proceso' :
                formStudy.estadoEstudio === 'Aplazado' ? 'aplazado' : ''
        // if(stateStu != ''){
        //     $('#'+stateStu).prop('checked', true)
        //     let estado = $('#Estado').is(':checked');
        //     if (estado) {
        //         estado = document.getElementById("Finalizardiv").classList.remove('d-none');
        //         estado = document.getElementById("Finalizardiv").classList.add('d-block');
        //         handleDate2(new Date(IdInfoEd.map(data => { return (data.fechaFin) }).toString()))
        //     }else{
        //         estado = document.getElementById("Finalizardiv").classList.remove('d-block');
        //         estado = document.getElementById("Finalizardiv").classList.add('d-none');
        //     }
        // }

        formStudy.dicCiudadEstudio = (IdInfoEd.map(data => { return (data.dicCiudadEstudio) })).toString()
        setLoaderShow(false);
    }
    const EditEduInfo1 = (idInfo) => {
        setvdt3(true);
        SetIdEdu(idInfo)
        let IdInfoEd = dataCurriculunInfoCo.filter(x => x.idInformacionEducativa == idInfo);
        formStudyComp.idInformacionEducativa = idInfo
        formStudyComp.institucionEducativa = (IdInfoEd.map(data => { return (data.institucionEducativa) })).toString()
        formStudyComp.dicIntensidadEstudio = (IdInfoEd.map(data => { return (data.dicIntensidadEstudio) })).toString()
        formStudyComp.dicTipoEstudio = (IdInfoEd.map(data => { return (data.dicTipoEstudio) })).toString()
        formStudyComp.descripcionEducacion = (IdInfoEd.map(data => { return (data.descripcionEducacion) })).toString()
        formStudyComp.dicNivelEducativo = (IdInfoEd.map(data => { return (data.dicNivelEducativo) })).toString()
        formStudyComp.tituloObtenido = (IdInfoEd.map(data => { return (data.tituloObtenido) })).toString()
        formStudyComp.dicAreaEstudio = (IdInfoEd.map(data => { return (data.dicAreaEstudio) })).toString()
        formStudyComp.estadoEstudio = (IdInfoEd.map(data => { return (data.estadoEstudio) })).toString()
        formStudyComp.dicCiudadEstudio = (IdInfoEd.map(data => { return (data.dicCiudadEstudio) })).toString()
        handleDate5(new Date(IdInfoEd.map(data => { return (data.fechaInicio) }).toString()))
        let stateStu = formStudyComp.estadoEstudio === 'Finalizado' ? 'Estado1' :
            formStudyComp.estadoEstudio === 'En proceso' ? 'proceso1' :
                formStudyComp.estadoEstudio === 'Aplazado' ? 'aplazado1' : ''
        // if(stateStu != ''){
        //     $('#'+stateStu).prop('checked', true)
        //     let estado = $('#Estado1').is(':checked');
        //     if (estado) {
        //         estado = document.getElementById("Finalizardiv1").classList.remove('d-none');
        //         estado = document.getElementById("Finalizardiv1").classList.add('d-block');
        //         handleDate3(new Date(IdInfoEd.map(data => { return (data.fechaFin) }).toString()))
        //     }else{
        //         estado = document.getElementById("Finalizardiv1").classList.remove('d-block');
        //         estado = document.getElementById("Finalizardiv1").classList.add('d-none');
        //     }
        // }
    }
    const editIdioma = (e) => {
        setvdt4(true)
        setSaveEdit(e.id)
        setdataIdioma({ id: e.id, nivel: e.nivel });
        let numerito = e.nivel
        document.querySelector('#IdUni3')
        $('#IdUni' + numerito).prop('checked', true)
        setShowInfoIdiomas(true);
    }
    const EditLabInfo = (idInfo) => {
        Subdatalab = false
        SetIdEdu(idInfo)
        InfoLab.length = 0
        setvdt(true);
        setvdt1(true);
        let IdInfoEd = InfoLab.filter(x => x.idExperienciaLaboral == idInfo);
        formInfoLab.idExperienciaLaboral = idInfo
        formInfoLab.cargo = (IdInfoEd.map(data => { return (data.cargo) })).toString()
        formInfoLab.empresa = (IdInfoEd.map(data => { return (data.empresa) })).toString()
        formInfoLab.funcioneslogros = (IdInfoEd.map(data => { return (data.funcioneslogros) })).toString()
        formInfoLab.fechaIngreso = (IdInfoEd.map(data => { return (data.fechaIngreso) })).toString()
        formInfoLab.fechaSalida = (IdInfoEd.map(data => { return (data.fechaSalida) })).toString()
        // handleDate4(new Date(IdInfoEd.map(data => { return (data.fechaIngreso) }).toString()))
        // if (formInfoLab.fechaSalida !== null || (formInfoLab.fechaSalida.split('T')[0]).toString() !== '0001-01-01') {
        //     handleDate6(new Date(IdInfoEd.map(data => { return (data.fechaSalida) }).toString()))
        // }else{
        //     document.getElementById("fNal").classList.add('d-none');
        //     $('#chkTA').prop('checked', true)
        // }
    }
    const linkbread = [{
        to: "/auth/inicio",
        tittle: "Inicio > "
    },
    {
        to: "/s/desempleo/mejorar-hoja-de-vida",
        tittle: "Mejorar mi hoja de vida >",
    },
    {
        to: "/s/desempleo/mejorar-hoja-de-vida",
        tittle: "Servicios > "
    },
    {
        to: "/s/desempleo/mejorar-hoja-de-vida/crear-hoja-vida/datos-personales",
        tittle: "Diseña tu hoja de vida "
    },
    {
        to: stepTab === 2 ? "/s/desempleo/mejorar-hoja-de-vida/perfeccionar-hv/agenda" : '',
        tittle: stepTab === 2 ? "> Agenda" : '',
    }];
    const Myphoto = {
        backgroundColor: '#2A2A2A',
        width: '24px',
        height: '24px',
        position: 'absolute',
        bottom: '-11px',
        left: '40%',
        fill: '#fff',
        borderRadius: '100%'
    };
    const LabelFile = {
        fontSize: '1.25rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        pointereEvents: 'none',
        cursor: 'pointer',
        lineHeight: '42px',
        textAlign: 'center',
        width: '24px',
        height: '24px',
        color: "#FFF",
        marginTop: '-10px',
        marginLeft: '0px'
    };
    const inputfile = {
        opacity: 0,
        position: 'absolute',
        zIndex: 1,
        width: '24px',
        height: '24px',
        cursor: 'pointer',
    };
    const formAre = {
        backgroundColor: '#fbfbfb',
        padding: '15px',
        lineHeight: '50px'
    }
    const txtTitle = {
        color: '#2A2A2A',
        fontFamily: 'OpenSans',
        fontSize: '14px'
    }
    const TxtLabel = {
        color: '#808285'
    }
    let idPl;
    let cp;
    let tp;
    const tabPersonalizacion = () => {
        // //console.log(formContact,'data 2');
        let indexPlantilla = 0
        let html = "";
        let cpI;
        let img = document.getElementById("imgHV").style.backgroundImage;
        if (cp == "" || cp == undefined) {
            cpI = "#7b8189"
        } else {
            cpI = cp;
        }
        for (let i = 0; i <= dataTypeTemplate.length - 1; i++) {
            html = dataTypeTemplate[i].htmlPlantilla;
            html = html.replace(/#Nombre/g, formContact.nombres + " " + formContact.apellidoPaterno);//reemplazamos los nombres y los apellidos.
            html = html.replace(/#identificacion/g, formContact.identificacion);
            html = html.replace(/#nacimiento/g, formContact.fechaNacimiento != null ? formContact.fechaNacimiento.toString() : "");
            html = html.replace(/#direccion/g, formContact.direccionResidencia);
            html = html.replace(/#fono/g, formContact.telefonoCelular);
            html = html.replace(/#email/g, formContact.correoElectronico);
            html = html.replace(/#descripcion/g, formContact.descripcionPerfilProfesional);
            html = html.replace(/#colorHV/g, cpI);
            html = html.replace(/#imagen/g, img.replace('url("', '').replace('")', ''));

            let education = "";
            if (formStudy.length > 0) {
                formStudy.forEach(element => {
                    education += `${element.tituloObtenido} - ${element.institucionEducativa} - ${element.fechaInicio} - ${element.fechaFin} /`;
                });
            }

            $("#planFrame" + dataTypeTemplate[i].idPlantilla)
                .contents()
                .find("html")
                .html(html);
            $("#planFrame" + dataTypeTemplate[i].idPlantilla)
                .contents()
                .find("html")
                .attr("style", "zoom:0.3;overflow:hidden; word-break: break-all;");
            $("#planFrame" + dataTypeTemplate[i].idPlantilla)
                .contents()
                .find(".row, .col")
                .attr("style", "margin: 0 !important; padding: 0 !important");
            $("#planFrame4")
                .contents()
                .find(".row, .col")
                .attr("style", "margin: 20px 0 !important;");
            $("#planFrame4")
                .contents()
                .find(".t")
                .attr("style", "font-size: 1rem !important; margin-top: -34px !important");
            $("#planFrame4")
                .contents()
                .find("img")
                .attr("style", "width: 50% !important; height: 50% !important; margin-top: 6px !important; position: absolute !important; top: 0 !important; right: 0 !important; ");
            $("#planFrame4")
                .contents()
                .find(".rayita")
                .attr("style", "margin: 5px 0 !important; max-height: 136px !important;min-height: 116px !important; overflow: hidden !important;");
            $("#planFrame4")
                .contents()
                .find(".nb")
                .attr("style", "font-size: 12px !important;");
            $("#planFrame" + dataTypeTemplate[i].idPlantilla)
                .contents()
                .find("h5, li, p")
                .attr("style", "font-size: 12px");
            $("#planFrame" + dataTypeTemplate[i].idPlantilla)
                .contents()
                .find("svg")
                .attr("style", "width: 90% !important");
        }

    }

    const seleccionarPlantilla = () => {
        if ($('#swiper0').hasClass('swiper-slide-active')) {
            idPl = 0
        }
        if ($('#swiper1').hasClass('swiper-slide-active')) {
            idPl = 1
        }
        if ($('#swiper2').hasClass('swiper-slide-active')) {
            idPl = 2
        }
        if ($('#swiper3').hasClass('swiper-slide-active')) {
            idPl = 3
        }
        if ($('#swiper4').hasClass('swiper-slide-active')) {
            idPl = 4
        }
        if ($('#primerTabColor1').hasClass('active')) {
            cp = "#7b8189"
            document.getElementById('plTipoG').classList.remove('d-none')
            document.getElementById('plTipoG').classList.add('d-block')
        }
        if ($('#primerTabColor2').hasClass('active')) {
            cp = "#0c79bc"
            document.getElementById('plTipoG').classList.remove('d-none')
            document.getElementById('plTipoG').classList.add('d-block')
        }
        if ($('#primerTabColor3').hasClass('active')) {
            cp = "#017c73"
            document.getElementById('plTipoG').classList.remove('d-none')
            document.getElementById('plTipoG').classList.add('d-block')
        }
        if ($('#primerTabColor4').hasClass('active')) {
            cp = "#412c9b"
            document.getElementById('plTipoG').classList.remove('d-none')
            document.getElementById('plTipoG').classList.add('d-block')
        }
        if ($('#primerTabColor5').hasClass('active')) {
            cp = "#ee7200"
            document.getElementById('plTipoG').classList.remove('d-none')
            document.getElementById('plTipoG').classList.add('d-block')
        }
        if ($('#primerTabTexto1').hasClass('active')) {
            tp = "Helvetica"
        }
        if ($('#primerTabTexto2').hasClass('active')) {
            tp = "Calibri"
        }
        if ($('#primerTabTexto3').hasClass('active')) {
            tp = "Georgia"
        }
        if ($('#primerTabTexto4').hasClass('active')) {
            tp = "Verdana"
        }
        if ($('#primerTabTexto5').hasClass('active')) {
            tp = "Tahoma"
        }
        let idx = $('#swiper1').hasClass('swiper-slide-active')
        setTimeout(() => {
            seleccionarTipoTexto(idPl, cp, tp);
        }, 500);
        document.getElementById('plntllColor').classList.remove('d-none')
        document.getElementById('plntllColor').classList.add('d-block')
        tabPersonalizacion();
    }
    //   tipoTexto, color,
    const seleccionarTipoTexto = (idP, cp, tp) => {
        // //console.log(idP, cp, tp,' datos plantilla');
        formContact.IdPlantillaAplicada = idP
        var img = document.getElementById("imgHV").style.backgroundImage;
        var html = "";
        var colorPlantilla;
        if (cp !== "") {
            colorPlantilla = cp;
        } else {
            colorPlantilla = "#7b8189"
        }
        html = dataTypeTemplate[idP].htmlPlantilla;
        html = html.replace(/#Nombre/g, formContact.nombres + " " + formContact.apellidoPaterno);//reemplazamos los nombres y los apellidos.
        html = html.replace(/#identificacion/g, formContact.identificacion);
        html = html.replace(/#nacimiento/g, formContact.fechaNacimiento != null ? formContact.fechaNacimiento.toString() : "");
        html = html.replace(/#direccion/g, formContact.direccionResidencia);
        html = html.replace(/#fono/g, formContact.telefonoCelular);
        html = html.replace(/#email/g, formContact.correoElectronico);
        html = html.replace(/#descripcion/g, formContact.descripcionPerfilProfesional);
        html = html.replace(/#colorHV/g, colorPlantilla);
        html = html.replace(/#imagen/g, img.replace('url("', '').replace('")', ''));

        $("#frameTipoTexto").contents().find("html").html(html);
        $("#frameTipoTexto")
            .contents()
            .find("html")
            .attr(
                "style",
                "zoom:0.3;padding:25px;overflow:hidden;word-break: break-all;font-family:'" + tp + "'"
            );
        $("#frameColorPlantilla").contents().find("html").html(html);
        $("#frameColorPlantilla")
            .contents()
            .find("html")
            .attr(
                "style",
                "zoom:0.3;padding:25px;overflow:hidden;word-break: break-all;font-family:'" + tp + "'"
            );
        $("#planFrame1")
            .contents()
            .find(".st")
            .attr(
                "style",
                "padding-left:26px;"
            );
        $("#frameColorPlantilla")
            .contents()
            .find(".st")
            .attr(
                "style",
                "padding-left:26px;"
            );
        $("#planFrame2")
            .contents()
            .find("h1")
            .attr(
                "style",
                "font-size:16px !important;"
            );
        // $("#planFrame4").contents().find("html").html(html);
        $("#planFrame4")
            .contents()
            .find("html")
            .attr(
                "style",
                "zoom:0.3;padding:20px;overflow:hidden;word-break: break-all;font-family:'" + tp + "'"
            );
        // $("#planFrame4").contents().find("html").html(html);
        $("#planFrame4")
            .contents()
            .find(".nb, .t, .st")
            .attr(
                "style",
                "font-size:19px;"
            )
        // $("#planFrame4")
        //   .contents()
        //   .find(".rayita")
        //   .attr(
        //     "style",
        //     "padding: 40px 0 !important"
        //   );
        $("#frameColorPlantilla")
            .contents()
            .find(".rayita")
            .attr(
                "style",
                "margin-top:50px;"
            );
        $("#planFrame5")
            .contents()
            .find(".nb, .d")
            .attr(
                "style",
                "width:53%;right:5px"
            );
    }

    const handleInputChange = (e) => {
        // //console.log(e.target.files);
        let nameFileImg = e.target.files[0].type
        imgRtaDB.tipoArchivo = "imagen"
        imgRtaDB.formatoImg = nameFileImg.split('/')[1]
        // //console.log(e.target.files[0].name);
        // //console.log(e.target.result);
        let file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(e);
        reader.readAsDataURL(file);
    }
    const _handleReaderLoaded = (e) => {
        let reader = e.target;
        rutafta = reader.result.toString()
        // //console.log(reader);
        // setimgRtaDB1(reader.result);
        imgRtaDB.imagenBase64 = reader.result.split('jpeg;base64,')[1];
        setimgRtaDB1("data:image/jpeg;base64," + imgRtaDB.imagenBase64);
        // //console.log(reader.result.split('jpeg;base64,')[1], 'json imagen');
        savePhotoCurriculum();
        // getTypeCulum();
        // //console.log(reader.result);
        // //console.log(e);
    }
    const SocialRed = (id) => {
        if (id === 1) {
            document.getElementById('btnLink').classList.add('d-none')
            document.getElementById('faLinkedinInId').classList.remove('d-none')
            document.getElementById('faLinkedinInId').classList.add('d-block')
        }
        if (id === 2) {
            document.getElementById('btnFace').classList.add('d-none')
            document.getElementById('inputguardar').classList.remove('d-none')
            document.getElementById('inputguardar').classList.add('d-block')
        }
        if (id === 3) {
            document.getElementById('btnBlog').classList.add('d-none')
            document.getElementById('faBlogId').classList.remove('d-none')
            document.getElementById('faBlogId').classList.add('d-block')
        }
        if (id === 4) {
            document.getElementById('faLinkedinInId').classList.remove('d-block')
            document.getElementById('faLinkedinInId').classList.add('d-none')
            document.getElementById('btnLink').classList.remove('d-none')
        }
        if (id === 5) {
            document.getElementById('faFaceInId').classList.remove('d-block')
            document.getElementById('faFaceInId').classList.add('d-none')
            document.getElementById('btnFace').classList.remove('d-none')
        }
        if (id === 6) {
            document.getElementById('faBlogId').classList.remove('d-block')
            document.getElementById('faBlogId').classList.add('d-none')
            document.getElementById('btnBlog').classList.remove('d-none')
        }
        if (id === 7) {
            document.getElementById('SpLink1').classList.remove('d-inline-block')
            document.getElementById('SpLink1').classList.add('d-none')
            document.getElementById('btnLink').classList.remove('d-none')
        }
        if (id === 8) {
            document.getElementById('SpLink1').classList.remove('d-inline-block')
            document.getElementById('SpLink1').classList.add('d-none')
            document.getElementById('btnLink').classList.remove('d-none')
        }
        if (id === 9) {
            document.getElementById('SpBlog1').classList.remove('d-inline-block')
            document.getElementById('SpBlog1').classList.add('d-none')
            document.getElementById('btnBlog').classList.remove('d-none')
        }
    }

    const socialRedrr = [];
    const SaveRedSocia = (id) => {
        if (id === 1) {
            let linked = document.getElementById('txtLinked')
            if (linked.value != null && linked.value != '') {
                document.getElementById('btnLink').classList.add('d-none')
                document.getElementById('faLinkedinInId').classList.add('d-none')
                document.getElementById('faLinkedinInId').classList.remove('d-block')
                document.getElementById('btnCloseLink').classList.add('d-block')
                document.getElementById('btnCloseLink').classList.remove('d-none')
                socialRedrr[0] = linked.value;
                document.getElementById('SpLink1').classList.remove('d-none');
                document.getElementById('SpLink1').classList.add('d-inline-block');
                $('#SpLink').text(socialRedrr[0]);
                formContact.redesSociales = socialRedrr
            } else {
                Swal.fire({
                    title: 'Ups!',
                    text: 'El campo no puede estar vacío',
                    type: 'success',
                    customClass: 'btnModalHv',
                    confirmButtonText: 'Aceptar'
                });
            }
            // //console.log(socialRedrr, ' arreglo y variable ', formContact.redesSociales);
        }
        if (id === 2) {
            let face = document.getElementById('txtFace')
            if (face.value != null && face.value != '') {
                document.getElementById('btnFace').classList.add('d-none')
                document.getElementById('inputguardar').classList.add('d-none')
                document.getElementById('inputguardar').classList.remove('d-block')
                socialRedrr[1] = face.value;
                formContact.redesSociales = socialRedrr
                document.getElementById('SpFace1').classList.remove('d-none');
                document.getElementById('SpFace1').classList.add('d-inline-block');
                $('#SpFace').text(socialRedrr[1]);
            } else {
                Swal.fire({
                    title: 'Ups!',
                    text: 'El campo no puede estar vacío',
                    customClass: 'btnModalHv',
                    type: 'success',
                    confirmButtonText: 'Aceptar'
                });
            }
            // //console.log(socialRedrr, ' arreglo y variable ', formContact.redesSociales);
        }
        if (id === 3) {
            let blog = document.getElementById('txtBlog')
            if (blog.value != null && blog.value != '') {
                document.getElementById('btnBlog').classList.add('d-none')
                document.getElementById('faBlogId').classList.add('d-none')
                document.getElementById('faBlogId').classList.remove('d-block')
                socialRedrr[2] = blog.value;
                formContact.redesSociales = socialRedrr
                document.getElementById('SpBlog1').classList.remove('d-none');
                document.getElementById('SpBlog1').classList.add('d-inline-block');
                $('#SpBlog').text(socialRedrr[2]);
            } else {
                Swal.fire({
                    title: 'Ups!',
                    text: 'El campo no puede estar vacío',
                    customClass: 'btnModalHv',
                    type: 'success',
                    confirmButtonText: 'Aceptar'
                });
            }
            // //console.log(socialRedrr, ' arreglo y variable ', formContact.redesSociales);
        }
    }
    const formatearFecha = (fecha) => {
        if (fecha !=  null) {
            let f = fecha.split('T')[0];
            let f2 = f.split('-');
            let mes = f2[1];
            let anio = f2[0];
            switch (mes) {
                case '01':
                    mes = 'Enero';
                    break;
                case '02':
                    mes = 'Febrero';
                    break;
                case '03':
                    mes = 'Marzo';
                    break;
                case '04':
                    mes = 'Abril';
                    break;
                case '05':
                    mes = 'Mayo';
                    break;
                case '06':
                    mes = 'Junio';
                    break;
                case '07':
                    mes = 'Julio';
                    break;
                case '08':
                    mes = 'Agosto';
                    break;
                case '09':
                    mes = 'Septiembre';
                    break;
                case '10':
                    mes = 'Octubre';
                    break;
                case '11':
                    mes = 'Noviembre';
                    break;
                case '12':
                    mes = 'Diciembre';
                    break;
            }
            return mes + ' ' + anio;
        }
    }
    const DeleteHabilidades = (id) => {
        document.getElementById('bagde' + id).style.display = 'none'

    }
    const DeletePais = (id) => {
        document.getElementById('pais' + id).style.display = 'none'

    }
    const formatearIdioma = (Idioma) => {
        let IdioamaTxt = dataTypeIdioma.filter(x => x.id == Idioma)
        // //console.log(IdioamaTxt.map(data=>{return(data.text)}));
        return IdioamaTxt.map(data => { return (data.text) })

    }
    const formatearNivel = (Nivel) => {
        let NivelTxt = jsonNivel.filter(x => x.id == Nivel)
        // //console.log(NivelTxt.map(data=>{return(data.text)}));
        return NivelTxt.map(data => { return (data.text) })

    }
    return (

        <React.Fragment>
            {
                loaderShow && (
                    <Loader show={true} />
                )
            }
            <ContainerService tittle="Mejora tu hoja de vida" classLine="tittleService" styleTittle={{ fontSize: "34px", fontFamily: 'DTL-ArgoT-regular' }} linkBread={linkbread}>
                <section>
                    {stepTab === 1 && (
                        <>
                            <div style={{ padding: '6%' }} id="MjCrr" hidden={StepCount !== 5 ? false : true} >
                                <h2 id="CreaHV" className="text-secondary mb-5" >Crea tu hoja de vida. </h2>
                                <div className="multi-step mb-5" id="MlStep">
                                    <ul className="multi-step-list nav" role="tablist" style={{ flexWrap: 'nowrap', overflowX: 'auto' }}>
                                        <li className="multi-step-item current" id="Dpersonales" onClick={(e) => { ctrRta(1) }}>
                                            <a data-toggle="pill" href="#tabMiPerfil" id="hTabMiPerfil" role="tab"
                                                aria-controls="custom-content-below-home" aria-selected="true">
                                                <div className="item-wrap item-wrap-exito">
                                                    <span className="item-title">
                                                        <i className="fas fa-check" ></i>
                                                        &nbsp;&nbsp;
                                                        Datos personales
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="multi-step-item" id="DEducacion" onClick={(e) => { ctrRta(2) }}>
                                            <a data-toggle="pill" href="#tabEducacion" id="hTabEducacion" >
                                                <div className="item-wrap" id="EducacionIt">
                                                    <span className="item-title">
                                                        <i className="fas fa-check" ></i>
                                                        &nbsp;&nbsp;
                                                        Educación
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="multi-step-item" id="hTabTrabajos1" onClick={(e) => { ctrRta(3) }}>
                                            <a data-toggle="pill" href="#tabTrabajos" id="hTabTrabajos" role="tab"
                                                aria-controls="custom-content-below-home" aria-selected="true">
                                                <div className="item-wrap" id="ExperienciaIt">
                                                    <span className="item-title">
                                                        <i className="fas fa-check"></i>
                                                        &nbsp;&nbsp;
                                                        Experiencia laboral
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="multi-step-item" id="hTabDiseno1" onClick={(e) => { ctrRta(4) }}>
                                            <a data-toggle="pill" href="#tabDiseno" id="hTabDiseno" role="tab"
                                                aria-controls="custom-content-below-home" aria-selected="true">
                                                <div className="item-wrap">
                                                    <span className="item-title">
                                                        <i className="fas fa-check"></i>
                                                        &nbsp;&nbsp;
                                                        Personalización
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <hr />
                                <div className="tab-content InfoPro" hidden={StepCount === 1 ? false : true}>
                                    <div className="tab-pane fade active show d-block" role="tabpanel" aria-labelledby="custom-content-below-home-tab">
                                        <h5 className="mb-3 col-12" style={txtTitle}>Información básica  </h5>
                                        <div className="row mr-3">
                                            <div className="col-lg-12 col-sm-12 col-md-12 align-items-stretch ml-3" style={{ paddingLeft: '0px', paddingRight: '0px', border: '1px lightgray dashed' }}>
                                                <div className="card bg-light">
                                                    <div className="card-body" style={{ padding: '30px' }}>
                                                        <div className="row">
                                                            <div className="col-lg-3 lg-sm-12 mbhv1">
                                                                <div className="containera">
                                                                    <div id="imgHV" className="outer my_class" style={{ backgroundSize: 'cover', backgroundImage: 'url(' + imgRtaDB1 + ')', height: '94px', width: '103px', backgroundRepeat: "no-repeat", margin: 'auto', position: 'relative' }}>
                                                                        <div className="inner" style={Myphoto}>
                                                                            <input className="inputfile manito" type="file" id="inputPhoto" name="file" accept="image/*" style={inputfile} onChange={(e) => { handleInputChange(e) }} />
                                                                            <label style={LabelFile}>
                                                                                +
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-9 lg-sm-12">
                                                                <h5 style={{ fontFamily: 'OpenSans-bold', fontSize: '14px', color: '#363636' }}>Carga tu foto</h5>
                                                                <ul className="list-unstyled psUl" style={{ color: '#58595B', fontSize: '12px' }}>
                                                                    <li>1. Utiliza una foto reciente con fondo claro.</li>
                                                                    <li>2. Evita usar fotos grupales.</li>
                                                                    <li>3. Selecciona una foto en donde estés de frente.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3 col-12 mt-3">
                                            <p style={{ fontFamily: 'OpenSans-regular', fontSize: '12px', color: '#58595B' }}>* Campos obligatorios</p>
                                        </div>
                                        <div className="row mt-3 mb-5">
                                            <div className="form-group col-md-6">
                                                <label className="labelPerfil" style={TxtLabel}>Nombres *</label>
                                                <input name="nombres"
                                                    type="text" className={ErroName !== '' ? "form-control iputControlHvRed" : "form-control iputControlHv"} id="nombres" placeholder="Nombres" onChange={(e) => { handleFormContact(e) }} value={formContact.nombres} />
                                                <div className="invalid-feedback" style={{ display: ErroName !== '' ? 'block' : 'none' }}>
                                                    <p style={{ color: "#ff0000" }}>{ErroName}</p>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="labelPerfil" style={TxtLabel}>Apellidos *</label>
                                                <input name="apellidoPaterno" id="apellidoPaterno"
                                                    formControlName="paternalLastName" type="text" className={ErroLastName !== '' ? "form-control iputControlHvRed" : "form-control iputControlHv"} placeholder="Apellidos" onChange={(e) => { handleFormContact(e) }} value={formContact.apellidoPaterno} />
                                                <div className="invalid-feedback" style={{ display: ErroLastName !== '' ? 'block' : 'none' }}>
                                                    <p style={{ color: "#ff0000" }}>{ErroLastName}</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <label className="labelPerfil" style={TxtLabel}>Tipo de documento *</label>
                                                <div style={{ position: "relative" }}>
                                                    <select disabled name="dicTipoDocumento" className={ErrorDocumento !== '' ? "form-control btblock iputControlHvRed iconoArrow" : "form-control btblock iputControlHv iconoArrow"} id="dicTipoDocumento" value={formContact.dicTipoDocumento} >
                                                        {
                                                            json.map(dicTipoDocumento => {
                                                                return (
                                                                    dicTipoDocumento.id == formContact.dicTipoDocumento ?
                                                                        <option value={formContact.dicTipoDocumento}>
                                                                            {dicTipoDocumento.value}
                                                                        </option>
                                                                        : null
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="invalid-feedback" style={{ display: ErrorDocumento !== '' ? 'block' : 'none' }}>
                                                    <p style={{ color: "#ff0000" }}>{ErrorDocumento}</p>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="labelPerfil" style={TxtLabel}>Número de documento</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" name="identificacion" id="identificacion" formControlName="document" id="numberInput" className={ErrorNumId !== '' ? "form-control iputControlHvRed" : "form-control iputControlHv"} disabled placeholder="N° Documento" onChange={(e) => { handleFormContact(e) }} value={formContact.identificacion} />
                                                </div>
                                                <div className="invalid-feedback" style={{ display: ErrorNumId !== '' ? 'block' : 'none' }}>
                                                    <p style={{ color: "#ff0000" }}>{ErrorNumId}</p>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="labelPerfil" style={TxtLabel}>Correo electrónico *</label>
                                                <div className="input-group">
                                                    <input formControlName="email" name="correoElectronico" id="correoElectronico" pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
                                                        type="text" className={ErrocorreoElectronico !== '' ? "form-control iputControlHvRed" : "form-control iputControlHv"} placeholder="Correo electrónico" onChange={(e) => { handleFormContact(e) }} value={formContact.correoElectronico} />
                                                    <div className="invalid-feedback" style={{ display: ErrocorreoElectronico !== '' ? 'block' : 'none' }}>
                                                        <p style={{ color: "#ff0000" }}>{ErrocorreoElectronico}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="from-group col-md-6">
                                                <label for="phone" className="labelPerfil" style={TxtLabel}>Teléfono celular *</label>
                                                <div className="input-group">
                                                    <input formControlName="phone" name="telefonoCelular"
                                                        type="text" className={ErroPhone !== '' ? "form-control iputControlHvRed" : "form-control iputControlHv"} maxlength="10" id="telefonoCelular" DigitOnly
                                                        aria-describedby="basic-addon3" placeholder="Teléfono celular" onChange={(e) => { handleFormContact(e) }} value={formContact.telefonoCelular} />
                                                    <div className="invalid-feedback" style={{ display: ErroPhone !== '' ? 'block' : 'none' }}>
                                                        <p style={{ color: "#ff0000" }}>{ErroPhone}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="labelPerfil" style={TxtLabel}>Fecha de nacimiento *</label>
                                                <div className="input-group RctCurr">
                                                    <DatePicker renderCustomHeader={({ date, changeYear, changeMonth, }) => (
                                                        <div className="row justify-content-center m-2">
                                                            <select className="selectCalendar col-sm-6" style={{ marginLeft: "15px", cursor: 'pointer' }}
                                                                value={months[getMonth(date)]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(months.indexOf(value))
                                                                }>
                                                                {months.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <select className="selectCalendar col-sm-5" style={{ cursor: 'pointer' }} value={getYear2(date)}
                                                                onChange={({ target: { value } }) => changeYear(value)}>
                                                                {years.map(option => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    )}
                                                        selected={startDate}
                                                        ppreSelection={startDate}
                                                        onChange={() => { }}
                                                        onSelect={date => handleDate(date)}
                                                        locate="es"
                                                        className={ErrorNaci !== '' ? "form-control iputControlHvRed selectionCalendar" : "form-control iputControlHv selectionCalendar"}
                                                        calendarClassName="backCalendarCW"
                                                        dateFormatCalendar="MMMM"
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholderText="Dia/Mes/Año"
                                                        maxDate={(new Date())}
                                                    />
                                                </div>
                                                <div className="invalid-feedback" style={{ display: ErrorNaci !== '' ? 'block' : 'none' }}>
                                                    <p style={{ color: "#ff0000" }}>{ErrorNaci}</p>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="labelPerfil" style={TxtLabel}>Género *</label>
                                                <div className="form-check p-1 pl-3" style={{ marginTop: '9px' }}>
                                                    <input formControlName="genero" value="M" style={{ top: '8px' }}
                                                        className="form-check-input" type="radio" name="genero" id="r-male" onChange={(e) => { handleFormContact(e) }} />
                                                    <label className="form-check-label" for="r-male">&nbsp;Masculino</label>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input formControlName="genero" value="F" style={{ top: '8px' }}
                                                        className="form-check-input" type="radio" name="genero" id="r-female" onChange={(e) => { handleFormContact(e) }} />
                                                    <label className="form-check-label" for="r-female">&nbsp;Femenino</label>
                                                    <div className="invalid-feedback" style={{ display: ErrorGenero !== '' ? 'block' : 'none' }}>
                                                        <p style={{ color: "#ff0000" }}>{ErrorGenero}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label className="labelPerfil" style={TxtLabel}>Departamento *</label>
                                                <div className="input-group mb-3">
                                                    <select name="dicDepartamento" id="departamento" className="form-control iputControlHv iconoArrow" value={formContact.dicDepartamento} onChange={(e) => { handleFormContact(e) }}>
                                                        <option value={0} style={{ color: '#9B9B9B' }} >
                                                            Seleccionar
                                                        </option>
                                                        {
                                                            dataCity.map(data => {
                                                                return (
                                                                    <option key={data.id} value={data.id}>
                                                                        {data.text}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="invalid-feedback" style={{ display: ErrorDepa != '' ? 'block' : 'none' }}>
                                                    <p style={{ color: "#ff0000" }}>{ErrorDepa}</p>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label className="labelPerfil" style={TxtLabel}>Ciudad *</label>
                                                <div className="input-group mb-3">
                                                    <select name="dicCiudadResidencia" id="ciudad" className="form-control iputControlHv iconoArrow" value={formContact.dicCiudadResidencia} onChange={(e) => { handleFormContact(e) }}>
                                                        <option value={0} style={{ color: '#9B9B9B' }}>
                                                            Seleccionar
                                                        </option>
                                                        {dataCityresult.length != 0 && (
                                                            dataCityresult.map(data => {
                                                                return (
                                                                    <option key={data.id} value={data.id}>
                                                                        {data.text}
                                                                    </option>
                                                                )
                                                            })

                                                        )
                                                        }
                                                    </select>
                                                </div>
                                                <div className="invalid-feedback" style={{ display: ErrorCityRe != '' ? 'block' : 'none' }}>
                                                    <p style={{ color: "#ff0000" }}>{ErrorCityRe}</p>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="labelPerfil" style={TxtLabel}>Dirección de residencia *</label>
                                                <div className="input-group">
                                                    <input formControlName="residenceAddress" type="text" name="residenceAddress"
                                                        className="form-control iputControlHv" placeholder="Dirección de residencia" onChange={(e) => { handleFormContact(e) }} value={formContact.residenceAddress} />
                                                    <div className="invalid-feedback" style={{ display: ErrorDAdress != '' ? 'block' : 'none' }}>
                                                        <p style={{ color: "#ff0000" }}>{ErrorDAdress}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="col-12" />
                                        <h6 className="mb-3 col-12" style={txtTitle}>Datos complementarios</h6>
                                        <div className="row mb-5">
                                            <div className="form-group col-md-6">
                                                <label className="labelPerfil" style={TxtLabel}>¿Cuál es tu Profesión?</label>
                                                <div className="input-group mb-3">
                                                    <select name="profesión" id="profesión" className="form-control iputControlHv iconoArrow" value={formContact.profesión} onChange={(e) => { handleFormContact(e) }}>
                                                        <option value="" >
                                                            Selecciona tu profesión*
                                                        </option>
                                                        {
                                                            dataTypeProfetion.map(data => {
                                                                return (
                                                                    <option key={data.id} value={data.id}>
                                                                        {data.text}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="invalid-feedback">
                                                    <p >Campo requerido.</p>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="labelPerfil" style={TxtLabel}>Años de experiencia</label>
                                                <div className="input-group mb-3">
                                                    <select name="anioExperiencia" id="anioExperiencia" className="form-control disabled iputControlHv iconoArrow" value={formContact.anioExperiencia} onChange={(e) => { handleFormContact(e) }}>
                                                        <option value={0} style={{ color: '#9B9B9B' }}>
                                                            Selecciona una opción
                                                        </option>
                                                        {
                                                            anio.map(data => {
                                                                return (
                                                                    <option key={data.id} value={data.id}>
                                                                        {data.id}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="invalid-feedback">
                                                    <p>Campo requerido.</p>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="labelPerfil" style={TxtLabel}>
                                                    Aspiración salarial
                                                </label>
                                                <div className="input-group mb-3 ">
                                                    <select name="salary" id="salary" className="form-control disabled iputControlHv iconoArrow" value={formContact.salary} onChange={(e) => { handleFormContact(e) }}>
                                                        <option value="" >
                                                            Selecciona una opción
                                                        </option>
                                                        {
                                                            dataTypeSalary.map(data => {
                                                                return (
                                                                    <option key={data.id} value={data.id}>
                                                                        {data.text}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="invalid-feedback">
                                                    <p>Campo requerido.</p>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6 chkcrrvta3" id="hvCheck">
                                                <label className="labelPerfil" style={TxtLabel}>Movilidad laboral (opcional) </label><br />
                                                <div className="row" >
                                                    <div class="col-12 pl-0 row">
                                                        <div className="col-1">
                                                            <input type="checkbox" formControlName='canChange' id="canChange" />
                                                        </div>
                                                        <div className="col-10 p-0">
                                                            <label for="canChange" className="labelPerfil" style={{ paddingLeft: '5px' }}>
                                                                Puedo mudarme a otra ciudad o país
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div class="col-12 pl-0 row">
                                                        <div className="col-1">
                                                            <input type="checkbox" formControlName='canTrip' id="canTrip" />
                                                        </div>
                                                        <div className="col-10 p-0">
                                                            <label for="canTrip" className="labelPerfil" style={{ paddingLeft: '5px' }}>
                                                                Puedo viajar a otra ciudad o país
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="labelPerfil" style={TxtLabel}>Países en los que tienes permiso de trabajo (opcional)</label>
                                                <select name="pais" id="pais" className="form-control disabled iputControlHv iconoArrow" value={formContact.pais} onChange={(e) => { handleFormContact(e); updateCountry(e) }}>
                                                    <option value="" style={{ color: '#9B9B9B', fontFamily: 'OpenSans', fontSize: '16px' }}>
                                                        Selecciona países*
                                                    </option>
                                                    {
                                                        dataTypePais.map(data => {

                                                            return (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.text}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>&nbsp;</label>
                                                <div className="form-control formAre"
                                                    style={formAre}>
                                                    {paises.length != 0 && (
                                                        paises.map(data => {

                                                            return (
                                                                data.value != paises.value ?
                                                                    <span id={'pais' + data.value} style={{ whiteSpace: 'nowrap' }}
                                                                        className="badge-curriculum mano">
                                                                        {data.value}
                                                                        <span className="ml-2" style={{ fontFamily: 'Inter-regular', cursor: 'pointer' }} onClick={() => DeletePais(data.value)}>x</span>
                                                                    </span>
                                                                    : null
                                                            )

                                                        })
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="mt-3 labelPerfil" style={{ marginLeft: '15px', fontSize: '13px' }}>Descripción de tu perfil</h6>
                                        <div className="col-12 mb-5">
                                            <textarea type="text" formControlName="descripcion" id="descripcionPerfilProfesional" className="iputControlHv"
                                                onChange={(e) => { handleFormContact(e) }} value={formContact.descripcionPerfilProfesional} name="descripcionPerfilProfesional"
                                                style={{ padding: '1% 2%', border: '1px solid #BABABA', width: '100%', color: 'rgb(155, 155, 155)', height: '40px', overflow: 'hidden' }}
                                                maxlength="500" placeholder="Haz una breve descripción de tu perfil">
                                            </textarea>
                                        </div>
                                        <hr />
                                        <h6 className="mb-3 col-12" style={txtTitle}>Redes Sociales</h6>
                                        <ReactSortable
                                            list={state.list}
                                            setList={(newState) => { setState({ list: newState }) }} animation="150"
                                            onChoose={() => setIsSorting(true)}
                                            onUnchoose={() => setIsSorting(false)}
                                            forceFallback={true}
                                        >
                                            {state.list.map(item => {
                                                return (
                                                    <div className="border-bottom p-2" >
                                                        <div style={{ display: ' inline-block' }} id="CtnLink">
                                                            <i className="fas fa-bars mr-3 drag" style={{ color: '#d9d9d9' }}></i>
                                                        </div>
                                                        <FontAwesomeIcon className="mr-3" style={{ color: "#D8D8D8" }} icon={faBars} />
                                                        <div style={{ display: 'inline-block', background: item.icono === 'faLinkedinIn' ? '#d8d8d8' : item.icono === 'faFacebookF' ? '#037ccd' : '#27d475' }}
                                                            className="text-center circulo">
                                                            <FontAwesomeIcon icon={item.icono === 'faLinkedinIn' ? faLinkedinIn : item.icono === 'faFacebookF' ? faFacebookF : faGlobe} style={{ color: '#fff', fontSize: '20px', marginTop: '10px' }} />
                                                        </div>
                                                        <div class="d-none" id={item.idTexto + '1'} style={{ marginLeft: '73%' }}>
                                                            <span className="ml-3 textSocial mr-3" id={item.idTexto}></span>
                                                            <span style={{ display: 'inline-block' }}>
                                                                <img id={'btnCloseLink'} src={iconClose} onClick={() => { SocialRed(item.id + 6) }} />
                                                            </span>
                                                        </div>
                                                        <div className="right" style={{ display: 'inline-block', float: 'right' }}>
                                                            <button
                                                                type="button" className="btn-vinculo" id={item.idLink} onClick={(e) => { SocialRed(item.id) }}>
                                                                Vinculo
                                                            </button>
                                                            <div className="text-left d-none" id={item.idGuardar} >
                                                                <input type="text" className="input ml-3 inputguardar"
                                                                    placeholder="Introduce tu url o web" id={item.inputGuardar} />
                                                                <span className="Guardar ml-3" onClick={(e) => { SaveRedSocia(item.id) }}>Guardar</span>
                                                                <img className='ml-3' id={'btnCloseLink'} src={iconClose} onClick={() => { SocialRed(item.id + 3) }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                        </ReactSortable>
                                        <div className="col-md-12 text-center mb-5 mt-5" style={{ width: '256px', paddingLeft: '0px', margin: 'auto' }}>
                                            <button className="btnYellow btn-next mb-5" style={{ width: '100%' }} onClick={(e) => { mostrar(); window.scroll(0, 0); }}>
                                                GUARDAR Y CONTINUAR
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content" id="tabEducacion" hidden={StepCount === 2 ? false : true}>
                                    <h5 className="mb-3 col-12" style={txtTitle}> Información educativa  </h5>
                                    <div className={dataCurriculunInfo.length !== 0 ? "col-lg-12 col-sm-12 col-md-12 align-items-stretch dvitm row contenShadow" : "col-lg-12 col-sm-12 col-md-12 align-items-stretch dvitm row"} style={{ display: !vdt ? 'flex' : 'none' }}>
                                        {((dataCurriculunInfo.length != 0 && dataCurriculunInfo != undefined) || !vdt) && (
                                            dataCurriculunInfo.map(data => {
                                                return (
                                                    <div className="bg-white border-bottom col-12 padCont" style={{ width: '100%' }}>
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-9 col-md-9 col-sm-9    col-7" style={{ lineHeight: '0.5' }}>
                                                                    <span className="studyTitle">{data.institucionEducativa}</span><br />
                                                                    <span className="studyBody">{data.tituloObtenido}</span><br />
                                                                    <span className="studyBody">
                                                                        {formatearFecha(data.fechaInicio)}
                                                                        {
                                                                            data.estadoEstudio === 'Finalizado' && (
                                                                                <React.Fragment>
                                                                                    - <span>{formatearFecha(data.fechaFin)}</span>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                    </span><br />
                                                                </div>
                                                                <div class="col-3 padlef13">
                                                                    <button class="btn btn-sm btn-outline-warning btn-block btn-study borderbtnBlack" type="button" onClick={(e) => { setLoaderShow(true); EditEduInfo(data.idInformacionEducativa) }}>
                                                                        <img src={editIco} className="mr-2" />
                                                                        <span style={{ fontSize: '12px' }} className="EditHv">Editar</span>
                                                                    </button>
                                                                    <button class="btn btn-sm btn-outline-danger btn-block btn-study btnYellowDelete" type="button" onClick={(e) => { deleteInfoEducativa(data.idInformacionEducativa) }}>
                                                                        <img src={deleteIco} />
                                                                        <span style={{ fontSize: '12px' }} >Eliminar</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            })
                                        )

                                        }
                                        {
                                            dataCurriculunInfo.length !== 0 && (
                                                <div class="col-12" style={{ position: 'relative' }}>

                                                    <div className="modal-footer" style={{ right: '0px', borderTop: 'none' }}>
                                                        <button class="btnMainBlack mt-3" onClick={(e) => { validinfoEd(6) }} style={{ width: '280px' }}>
                                                            AGREGAR NUEVO ESTUDIO
                                                        </button>

                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        ((dataCurriculunInfo.length === 0 && !Subdata) || vdt) && (
                                            <React.Fragment>
                                                <div className="row mt-3 mb-5">
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Institución educativa</label>
                                                        <input name="institucionEducativa"
                                                            type="text" className="form-control iputControlHv" id="institucionEducativa" placeholder="Ingresa el nombre de la institución*" onChange={(e) => { handleFormContact1(e) }} value={formStudy.institucionEducativa} />
                                                        <div className="invalid-feedback">
                                                            <p >Campo requerido.</p>
                                                            <p>Maximo 30 caracteres para este campo.</p>
                                                            <p >Campo de solo letras</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Nivel educativo</label>
                                                        <div className="input-group mb-3 ">
                                                            <select name="dicNivelEducativo" id="dicNivelEducativo" className=" form-control disabled iputControlHv iconoArrow" value={formStudy.dicNivelEducativo} onChange={(e) => { handleFormContact1(e) }}>
                                                                <option value={0} style={{ color: '#9B9B9B', fontFamily: 'OpenSans', fontSize: '16px' }}>
                                                                    Selecciona un nivel educativo
                                                                </option>
                                                                {
                                                                    dataTypeArea.map(data => {
                                                                        return (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.text}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="invalid-feedback">
                                                            <p>Campo requerido.</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Título obtenido</label>
                                                        <input name="tituloObtenido"
                                                            type="text" className=" form-control iputControlHv" id="tituloObtenido" placeholder="Ingresa el título que obtuviste*" onChange={(e) => { handleFormContact1(e) }} value={formStudy.tituloObtenido} />
                                                        <div className="invalid-feedback">
                                                            <p >Campo requerido.</p>
                                                            <p>Maximo 30 caracteres para este campo.</p>
                                                            <p >Campo de solo letras</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Área de estudio</label>
                                                        <select name="dicAreaEstudio" id="dicAreaEstudio" className="form-control iconoArrow iputControlHv" value={formContact.dicAreaEstudio} onChange={(e) => { handleFormContact1(e) }}>
                                                            <option value={0} style={{ color: '#9B9B9B', fontFamily: 'OpenSans', fontSize: '16px' }}>
                                                                Ingresa el área de estudio
                                                            </option>
                                                            {
                                                                dataTypeArea.map(data => {
                                                                    return (
                                                                        <option key={data.id} value={data.id}>
                                                                            {data.text}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Estado</label>
                                                        <div className="form-check stadoHv" style={{ display: 'flex' }} >
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <input formControlName="gender"
                                                                    className="form-check-input" type="radio" value="Finalizado" name="estadoEstudio" id="Estado" onChange={(e) => { handleFormContact1(e) }} />
                                                                <label className="form-check-label" for="Estado">&nbsp;Finalizado</label>
                                                            </div>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <input formControlName="gender" className="form-check-input" type="radio" value="En proceso" name="estadoEstudio" id="proceso" onChange={(e) => { handleFormContact1(e) }} />
                                                                <label className="form-check-label" for="proceso">&nbsp;En proceso</label>
                                                            </div>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <input formControlName="gender" className="form-check-input" type="radio" value="Aplazado" name="estadoEstudio" id="aplazado" onChange={(e) => { handleFormContact1(e) }} />
                                                                <label className="form-check-label" for="aplazado">&nbsp;Aplazado</label>
                                                            </div>
                                                            <div className="invalid-feedback">
                                                                <p >Campo requerido.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Ciudad donde estudiaste</label>
                                                        <select name="dicCiudadEstudio" id="dicCiudadEstudio" className=" form-control disabled iconoArrow iputControlHv" value={formContact.dicCiudadEstudio} onChange={(e) => { handleFormContact1(e) }}>
                                                            <option value={0} style={{ color: '#9B9B9B', fontFamily: 'OpenSans', fontSize: '16px' }}>
                                                                Selecciona una ciudad*
                                                            </option>
                                                            {
                                                                City.map(data => {
                                                                    return (
                                                                        <option key={data.id} value={data.id}>
                                                                            {data.text}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Fecha inicio</label>
                                                        <div className="input-group RctCurr">
                                                            <DatePicker renderCustomHeader={({ date, changeYear, changeMonth, }) => (
                                                                <div className=" row justify-content-center m-2">
                                                                    <select className="selectCalendar col-sm-6" style={{ marginLeft: "15px", cursor: 'pointer' }}
                                                                        value={months[getMonth(date)]}
                                                                        onChange={({ target: { value } }) =>
                                                                            changeMonth(months.indexOf(value))
                                                                        }>
                                                                        {months.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <select className="selectCalendar col-sm-5" style={{ cursor: 'pointer' }} value={getYear2(date)}
                                                                        onChange={({ target: { value } }) => changeYear(value)}>
                                                                        {years.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}
                                                                selected={starfechaInicio}
                                                                ppreSelection={starfechaInicio}
                                                                onChange={() => { }}
                                                                onSelect={date => handleDate1(date)}
                                                                locate="es"
                                                                className="form-control selectionCalendar iputControlHv"
                                                                calendarClassName="backCalendarCW"
                                                                dateFormatCalendar="MMMM"
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="Dia/Mes/Año"
                                                                maxDate={(new Date())}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6 d-none" id="Finalizardiv">
                                                        <label className="labelPerfil" style={TxtLabel}>Fecha finalización</label>
                                                        <div className="input-group RctCurr">
                                                            <DatePicker renderCustomHeader={({ date, changeYear, changeMonth, }) => (
                                                                <div className="row justify-content-center m-2">
                                                                    <select className=" selectCalendar col-sm-6" style={{ marginLeft: "15px", cursor: 'pointer' }}
                                                                        value={months[getMonth(date)]}
                                                                        onChange={({ target: { value } }) =>
                                                                            changeMonth(months.indexOf(value))
                                                                        }>
                                                                        {months.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <select className="selectCalendar col-sm-5" style={{ cursor: 'pointer' }} value={getYear2(date)}
                                                                        onChange={({ target: { value } }) => changeYear(value)}>
                                                                        {years.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}
                                                                selected={starfechaFinal}
                                                                ppreSelection={starfechaFinal}
                                                                onChange={() => { }}
                                                                onSelect={date => handleDate2(date)}
                                                                locate="es"
                                                                className="form-control selectionCalendar iputControlHv"
                                                                calendarClassName="backCalendarCW"
                                                                dateFormatCalendar="MMMM"
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="Dia/Mes/Año"
                                                                maxDate={(new Date())}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                    <div className="modal-footer" style={{ display: vdt || dataCurriculunInfo.length === 0 ? '' : 'none', border: 'none' }} >
                                        {
                                            (vdt1) && (
                                                <>
                                                    <button id="btn1" type="submit" className="btnYellow  mb-5" style={{ marginBottom: '0rem', display: dataCurriculunInfo.length !== 0 ? '' : 'none' }} onClick={(e) => { validinfoEd(IdEdu != 0 ? 10 : 1) }} >Guardar</button>
                                                </>
                                            )
                                        }
                                        {
                                            ((dataCurriculunInfo.length === 0 && !Subdata) || vdt1) && (
                                                <button id="btn2" type="submit" className="btnYellow mb-5" style={{ marginBottom: '0rem', display: dataCurriculunInfo.length === 0 ? '' : 'none' }} onClick={(e) => { validinfoEd(1) }} >Guardar</button>
                                            )
                                        }
                                    </div>

                                    <hr className="mt-5" />
                                    <div className="row mt-3 mb-5">
                                        <h5 className="mb-3 col-12" style={txtTitle}>Idiomas </h5>
                                        <div className={contadorIdioma.length !== 0 ? "col-lg-12 col-sm-12 col-md-12 align-items-stretch dvitm row contenShadow" : "col-lg-12 col-sm-12 col-md-12 align-items-stretch dvitm row"} style={{ display: vdt4 ? 'none' : 'flex' }}>
                                            {((contadorIdioma.length !== 0 && contadorIdioma.length !== undefined) || !vdt4) && (
                                                contadorIdioma.map(data => {
                                                    return (
                                                        <div className="bg-white border-bottom col-12 padCont" style={{ width: '100%' }}>
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-lg-9 col-md-9 col-sm-9   col-7" style={{ lineHeight: '0.5' }}>
                                                                        <span className="studyTitle">{formatearIdioma(data.id)}</span><br />
                                                                        <span className="studyBody">{'Nivel ' + formatearNivel(data.nivel)}</span><br />
                                                                    </div>
                                                                    <div class="col-3 padlef13">
                                                                        <button class="btn btn-sm btn-outline-warning btn-block btn-study borderbtnBlack" type="button" onClick={(e) => { editIdioma(data) }}>
                                                                            <img src={editIco} className="mr-2" />
                                                                            <span style={{ fontSize: '12px' }} className="EditHv">Editar</span>
                                                                        </button>
                                                                        <button class="btn btn-sm btn-outline-danger btn-block btn-study btnYellowDelete" type="button">
                                                                            <img src={deleteIco} />
                                                                            <span style={{ fontSize: '12px' }} onClick={(e) => { deleteInfoIdioma(data.id) }}> Eliminar</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )

                                                })
                                            )
                                            }
                                            {
                                                contadorIdioma.length !== 0 && (
                                                    <div class="col-12" style={{ position: 'relative' }}>
                                                        <div className="modal-footer" style={{ right: '0px',borderTop: 'none' }}>
                                                            <button class="btnMainBlack mt-3" onClick={(e) => { setvdt4(true) }} style={{ width: '280px' }}>
                                                                AGREGAR NUEVO IDIOMA
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {
                                            (contadorIdioma.length === 0 || vdt4) && (
                                                <React.Fragment>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Idioma</label>
                                                        <div className="input-group mb-3 ">
                                                            <select name="id" id="Idioma" className="form-control iputControlHv iconoArrow" value={dataIdioma.id} onChange={(e) => { handleIdioma(e) }}>
                                                                <option value={0} style={{ color: '#9B9B9B', fontFamily: 'OpenSans', fontSize: '16px' }}>
                                                                    Selecciona un idioma
                                                                </option>
                                                                {
                                                                    dataTypeIdioma.map(data => {
                                                                        return (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.text}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="invalid-feedback">
                                                            <p>Campo requerido.</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Nivel alcanzado</label>
                                                        <div className="form-check stadoHv" style={{ display: 'flex' }} >
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <input formControlName="gender" className="form-check-input" type="radio" value="1" id="Basico" name="nivel" onChange={(e) => { handleIdioma(e) }} />
                                                                <label className="form-check-label" for="Basico">&nbsp;&nbsp;Básico</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <input formControlName="gender" className="form-check-input" type="radio" id="Intermedio" value="2" name="nivel" onChange={(e) => { handleIdioma(e) }} />
                                                                <label className="form-check-label" for="Intermedio">&nbsp;&nbsp;Intermedio</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <input formControlName="gender" className="form-check-input" type="radio" id="Avanzado" value="3" name="nivel" onChange={(e) => { handleIdioma(e) }} />
                                                                <label className="form-check-label" for="Avanzado">&nbsp;&nbsp;Avanzado</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </div>
                                                            <div className="invalid-feedback">
                                                                <p >Campo requerido.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )

                                        }

                                        <div className="modal-footer w-100" style={{ border: 'none' }}>
                                            <button type="submit" className={contadorIdioma.length === 0 ? "btnYellow mb-5" : 'd-none'} style={{ marginBottom: '0rem' }} onClick={(e) => { validinfoEd(5) }} >Guardar</button>
                                        </div>
                                    </div>

                                    <hr />
                                    <h5 className="mb-3 col-12" style={txtTitle}> Estudios complementarios  </h5>
                                    <div className={dataCurriculunInfoCo.length !== 0 ? "col-lg-12 col-sm-12 col-md-12 align-items-stretch dvitm row contenShadow mb-5" : "col-lg-12 col-sm-12 col-md-12 d-flex align-items-stretch dvitm row"} style={{ display: vdt3 ? 'none' : '' }}>
                                        {((dataCurriculunInfoCo.length !== 0 && dataCurriculunInfoCo !== undefined) && !vdt3) && (

                                            dataCurriculunInfoCo.map(data => {
                                                return (
                                                    <div className="bg-white border-bottom col-12 padCont" style={{ width: '100%' }}>
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-9 col-md-9 col-sm-9 col-7" style={{ lineHeight: '0.5' }}>
                                                                    <span className="studyTitle">{data.institucionEducativa}</span><br />
                                                                    <span className="studyBody">{data.tituloObtenido}</span><br />
                                                                    <span className="studyBody">
                                                                        {formatearFecha(data.fechaInicio)}
                                                                        {
                                                                            data.estadoEstudio == 'Finalizado' && (
                                                                                <React.Fragment>
                                                                                    - <span>{formatearFecha(data.fechaFin)}</span>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                    </span><br />
                                                                </div>
                                                                <div class="col-3 padlef13">
                                                                    <button class="btn btn-sm btn-outline-warning btn-block btn-study borderbtnBlack" type="button" onClick={(e) => { EditEduInfo1(data.idInformacionEducativa) }}>
                                                                        <img src={editIco} className="mr-2" />
                                                                        <span style={{ fontSize: '12px' }} className="EditHv">Editar</span>
                                                                    </button>
                                                                    <button class="btn btn-sm btn-outline-danger btn-block btn-study btnYellowDelete" type="button">
                                                                        <img src={deleteIco} />
                                                                        <span style={{ fontSize: '12px' }} onClick={(e) => { deleteInfoEducativa1(data.idInformacionEducativa) }}> Eliminar</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            })
                                        )
                                        }
                                        {
                                            (dataCurriculunInfoCo.length !== 0 && !vdt3) && (
                                                <div class="col-12" style={{ position: 'relative' }}>

                                                    <div className="modal-footer" style={{ right: '0px',borderTop: 'none' }}>
                                                        <button class="btnMainBlack mt-3" style={{ width: '280px' }}
                                                            onClick={(e) => {validinfoEd(13);  }}>
                                                            AGREGAR NUEVO ESTUDIO
                                                        </button>

                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        (dataCurriculunInfoCo.length === 0 || vdt3) && (
                                            <React.Fragment>
                                                <div className="row mt-3 mb-5">
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Institución educativa</label>
                                                        <input name="institucionEducativa" value={formStudyComp.institucionEducativa} onChange={(e) => { handleFormContact2(e) }}
                                                            type="text" className="form-control iputControlHv" id="institucionCo" placeholder="Ingresa el nombre de la institución*" />
                                                        <div className="invalid-feedback">
                                                            <p >Campo requerido.</p>
                                                            <p>Maximo 30 caracteres para este campo.</p>
                                                            <p >Campo de solo letras</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Tipo de estudio</label>
                                                        <div className="input-group mb-3">
                                                            <select name="dicTipoEstudio" id="dicTipoEstudio"
                                                                className="form-control  iputControlHv iconoArrow"
                                                                value={formStudyComp.dicTipoEstudio} onChange={(e) => { handleFormContact2(e) }}>
                                                                <option value={0} style={{ color: '#9B9B9B', fontFamily: 'OpenSans', fontSize: '16px' }}>
                                                                    Selecciona un nivel educativo
                                                                </option>
                                                                {
                                                                    dataTypeStudy.map(data => {
                                                                        return (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.text}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="invalid-feedback">
                                                            <p>Campo requerido.</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Título obtenido</label>
                                                        <input name="tituloObtenido"
                                                            type="text" className="form-control iputControlHv" id="tituloObtenido" placeholder="Ingresa el título obtenido*" value={formStudyComp.tituloObtenido} onChange={(e) => { handleFormContact2(e) }} />
                                                        <div className="invalid-feedback">
                                                            <p >Campo requerido.</p>
                                                            <p>Maximo 30 caracteres para este campo.</p>
                                                            <p >Campo de solo letras</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Otro estudio</label>
                                                        <input name="otroEstudio"
                                                            disabled={formStudyComp.dicTipoEstudio === '1616' ? false : true}
                                                            type="text" className="form-control iputControlHv" id="otroEstudio"
                                                            placeholder="Ingresa el tipo de estudio"
                                                            value={formStudyComp.otroEstudio} onChange={(e) => { handleFormContact2(e) }} />
                                                        <div className="invalid-feedback">
                                                            <p >Campo requerido.</p>
                                                            <p>Maximo 30 caracteres para este campo.</p>
                                                            <p >Campo de solo letras</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Área de curso</label>
                                                        <select name="dicAreaEstudio" id="dicAreaEstudio" className="form-control iputControlHv iconoArrow" value={formStudyComp.dicAreaEstudio} onChange={(e) => { handleFormContact2(e) }}>
                                                            <option value={0} style={{ color: '#9B9B9B', fontFamily: 'OpenSans', fontSize: '16px' }}>
                                                                Ingresa el área del curso
                                                            </option>
                                                            {
                                                                dataTypeArea.map(data => {
                                                                    return (
                                                                        <option key={data.id} value={data.id}>
                                                                            {data.text}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Intensidad horaria</label>
                                                        <div className="input-group mb-3">
                                                            <select name="dicIntensidadEstudio" id="dicIntensidadEstudio" className="form-control iputControlHv iconoArrow" value={formStudyComp.dicIntensidadEstudio} onChange={(e) => { handleFormContact2(e) }}>
                                                                <option value="" style={{ color: '#9B9B9B', fontFamily: 'OpenSans', fontSize: '16px' }}>Selecciona la intensidad horaria*</option>
                                                                {
                                                                    IntensidadHoraria.map(data => {
                                                                        return (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.text}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="invalid-feedback">
                                                            <p>Campo requerido.</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Ciudad donde estudiaste</label>
                                                        <select name="dicCiudadEstudio" id="dicCiudadEstudio" className="form-control iconoArrow iputControlHv" value={formStudyComp.dicCiudadEstudio} onChange={(e) => { handleFormContact2(e) }}>
                                                            <option value={0} style={{ color: '#9B9B9B', fontFamily: 'OpenSans', fontSize: '16px' }}>
                                                                Selecciona una ciudad*
                                                            </option>
                                                            {
                                                                City.map(data => {
                                                                    return (
                                                                        <option key={data.id} value={data.id}>
                                                                            {data.text}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Estado</label>
                                                        <div className="form-check stadoHv" style={{ display: 'flex' }}>
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <input formControlName="gender"
                                                                    className="form-check-input" type="radio" value="Finalizado" name="estadoEstudio" id="Estado1" onChange={(e) => { handleFormContact2(e) }} />
                                                                <label className="form-check-label" for="Estado1">&nbsp;Finalizado</label>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <input formControlName="gender" className="form-check-input" type="radio" value="En proceso" name="estadoEstudio" id="proceso1" onChange={(e) => { handleFormContact2(e) }} />
                                                                <label className="form-check-label" for="proceso1">&nbsp;En proceso</label>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <input formControlName="gender" className="form-check-input" type="radio" value="Aplazado" name="estadoEstudio" id="aplazado1" onChange={(e) => { handleFormContact2(e) }} />
                                                                <label className="form-check-label" for="aplazado1">&nbsp;Aplazado</label>
                                                            </div>
                                                            <div className="invalid-feedback">
                                                                <p >Campo requerido.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label className="labelPerfil" style={TxtLabel}>Fecha inicio</label>
                                                        <div className="input-group RctCurr">
                                                            <DatePicker renderCustomHeader={({ date, changeYear, changeMonth, }) => (
                                                                <div className="row justify-content-center m-2">
                                                                    <select className="selectCalendar col-sm-6" style={{ marginLeft: "15px", cursor: 'pointer' }}
                                                                        value={months[getMonth(date)]}
                                                                        onChange={({ target: { value } }) =>
                                                                            changeMonth(months.indexOf(value))
                                                                        }>
                                                                        {months.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <select className="selectCalendar col-sm-5" style={{ cursor: 'pointer' }} value={getYear2(date)}
                                                                        onChange={({ target: { value } }) => changeYear(value)}>
                                                                        {years.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}
                                                                selected={starfechaInicio1}
                                                                ppreSelection={starfechaInicio1}
                                                                onChange={() => { }}
                                                                onSelect={date => handleDate5(date)}
                                                                locate="es"
                                                                className="form-control selectionCalendar iputControlHv"
                                                                calendarClassName="backCalendarCW"
                                                                dateFormatCalendar="MMMM"
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="Dia/Mes/Año"
                                                                maxDate={(new Date())}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6 d-none" id="Finalizardiv1">
                                                        <label className="labelPerfil" style={TxtLabel}>Fecha finalización</label>
                                                        <div className="input-group RctCurr">
                                                            <DatePicker renderCustomHeader={({ date, changeYear, changeMonth, }) => (
                                                                <div className="row justify-content-center m-2">
                                                                    <select className="selectCalendar col-sm-6" style={{ marginLeft: "15px", cursor: 'pointer' }}
                                                                        value={months[getMonth(date)]}
                                                                        onChange={({ target: { value } }) =>
                                                                            changeMonth(months.indexOf(value))
                                                                        }>
                                                                        {months.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <select className="selectCalendar col-sm-5" style={{ cursor: 'pointer' }} value={getYear2(date)}
                                                                        onChange={({ target: { value } }) => changeYear(value)}>
                                                                        {years.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}
                                                                selected={starfechaFinal1}
                                                                ppreSelection={starfechaFinal1}
                                                                onChange={() => { }}
                                                                onSelect={date => handleDate3(date)}
                                                                locate="es"
                                                                className="form-control selectionCalendar iputControlHv"
                                                                calendarClassName="backCalendarCW"
                                                                dateFormatCalendar="MMMM"
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="Dia/Mes/Año"
                                                                maxDate={(new Date())}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-5">
                                                    <h6 className="labelPerfil" >Descripción de tu perfil</h6>
                                                    <textarea type="text" formControlName="descripcion" id="descripcionPerfilProfesional" name="descripcionEducacion" value={formStudyComp.descripcionEducacion} className="iputControlHv"
                                                        onChange={(e) => { handleFormContact2(e) }} style={{ padding: '1% 2%', border: '1px solid #BABABA', minHeight: '30px', width: '100%', color: 'rgb(155, 155, 155)', overflow: 'hidden', height: '30px' }}
                                                        maxlength="500" placeholder="Haz una breve descripción de tu perfil">
                                                    </textarea>
                                                </div>
                                                <div className="modal-footer" style={{ border: 'none' }}>
                                                    <button type="submit" className="btnYellow mb-5" style={{ marginBottom: '0rem', display: !vdt3 || addComple ? '' : 'none' }} onClick={(e) => { validinfoEd(IdEdu != 0?11:3) }} >Guardar</button>
                                                    <button type="submit" className="btnYellow mb-5" style={{ marginBottom: '0rem', display: vdt3 && !addComple ? '' : 'none' }} onClick={(e) => { validinfoEd(11) }} >Guardar</button>
                                                </div>

                                            </React.Fragment>
                                        )
                                    }
                                    <div className={dataCurriculunInfoCo.length !== 0 ? "col-md-12 text-center mb-5 mt-5" : "col-md-12 text-center mb-5"} style={{ width: '256px', paddingLeft: '0px', margin: 'auto' }}>
                                        <button className="btnYellow btn-next mb-5" style={{ width: '100%' }} onClick={(e) => { RtaCtr(); window.scroll(0, 0); }}>
                                            GUARDAR Y CONTINUAR
                                        </button>
                                    </div>
                                </div>
                                <div className="tab-content" id="tabEducacion" hidden={StepCount === 3 ? false : true}>
                                    <h5 className="mb-3 col-12" style={txtTitle}>Información de experiencia laboral </h5>
                                    <div id="labinfo" className="scrollbarstyle container mb-2 d-none contenShadow "
                                        style={{ maxHeight: '400px', height: '100%', overflowY: 'auto', }}>
                                        <div className={`row ${InfoLab.length !== 0 ? 'd-flex' : 'd-none'} align-items-stretch`}>
                                            <div className="col-lg-12 col-sm-12 col-md-12 d-flex align-items-stretch bg-white mb-3 " style={{ width: '100%',}}>
                                                <div className="p-1 w-100" >
                                                    {
                                                        (InfoLab.length !== 0) && (
                                                            InfoLab.map(data => {
                                                                return (
                                                                    <div className="bg-white border-bottom col-12 padCont" style={{ width: '100%' }}>
                                                                        <div>
                                                                            <div className="row">
                                                                                <div className="col-lg-9 col-md-9 col-sm-9    col-7" style={{ lineHeight: '0.5' }}>
                                                                                    <span className="studyTitle">{data.empresa}</span><br />
                                                                                    <span className="studyBody">{data.cargo}</span><br />
                                                                                    <span className="studyBody">
                                                                                        {formatearFecha(data.fechaIngreso)}
                                                                                        {
                                                                                            // data.estadoEstudio == 'Finalizado' &&(
                                                                                            //     <React.Fragment>
                                                                                            //         - <span>{formatearFecha(data.fechaFin)}</span>
                                                                                            //     </React.Fragment>
                                                                                            // )
                                                                                        }
                                                                                    </span><br />
                                                                                </div>
                                                                                <div class="col-3 padlef13">
                                                                                    <button class="btn btn-sm btn-outline-warning btn-block btn-study borderbtnBlack" type="button" onClick={(e) => { EditLabInfo(data.idExperienciaLaboral) }}>
                                                                                        <img src={editIco} className="mr-2" />
                                                                                        <span style={{ fontSize: '12px' }} className="EditHv" >Editar</span>
                                                                                    </button>
                                                                                    <button class="btn btn-sm btn-outline-danger btn-block btn-study btnYellowDelete" type="button" onClick={(e) => { deleteInfoLaboral(data.idExperienciaLaboral) }}>
                                                                                        <img src={deleteIco} />
                                                                                        <span style={{ fontSize: '12px' }} >Eliminar</span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>)
                                                            })
                                                        )
                                                    }
                                                    {
                                                        InfoLab.length !== 0 && (
                                                            <div class="col-12" style={{ position: 'relative' }}>
                                                                <div className="modal-footer p-0" style={{ right: '0px',borderTop: 'none' }}>
                                                                    <button class="btnMainBlack mt-3" style={{ width: '280px' }} onClick={(e) => { validinfoEd(6) }}>
                                                                        AGREGAR NUEVA EXPERIENCIA
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (!Subdatalab && InfoLab.length === 0) && (
                                            <React.Fragment>
                                                <div className="row mt-3 mb-5">
                                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                        <label className="labelPerfil" style={TxtLabel} >Empresa</label>
                                                        <input name="empresa"
                                                            type="text" className="form-control iputControlHv" id="empresa" placeholder="Ingresa el nombre de la empresa*" onChange={(e) => { handleFormContact3(e) }} value={formInfoLab.empresa} />
                                                        <div className="invalid-feedback">
                                                            <p >Campo requerido.</p>
                                                            <p>Maximo 30 caracteres para este campo.</p>
                                                            <p >Campo de solo letras</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                        <label className="labelPerfil" style={TxtLabel} >Cargo</label>
                                                        <input name="cargo"
                                                            type="text" className="form-control iputControlHv" id="cargo" placeholder="Ingresa el cargo que desempeñaste*" onChange={(e) => { handleFormContact3(e) }} value={formInfoLab.cargo} />
                                                        <div className="invalid-feedback">
                                                            <p >Campo requerido.</p>
                                                            <p>Maximo 30 caracteres para este campo.</p>
                                                            <p >Campo de solo letras</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <textarea type="text" formControlName="descripcion" className="iputControlHv" id="funcioneslogros" name="funcioneslogros" onChange={(e) => { handleFormContact3(e) }} value={formInfoLab.funcioneslogros}
                                                            style={{ padding: '1% 2%', border: '1px solid #BABABA', minHeight: '66px', width: '100%', color: 'rgb(155, 155, 155)', }}
                                                            maxlength="500" placeholder="Describe las funciones de tu cargo">
                                                        </textarea>
                                                    </div>
                                                    <div className="form-group col-lg-6 col-md-6 col-sm-12 d-lg-flex d-sm-block">
                                                        <div className="col-lg-6 col-md-6 col-sm-12 mr-2 p-0">
                                                            <label className="labelPerfil" style={TxtLabel} >Fecha de ingreso</label>
                                                            <div className="input-group RctCurr">
                                                                <DatePicker renderCustomHeader={({ date, changeYear, changeMonth, }) => (
                                                                    <div className="row justify-content-center m-2">
                                                                        <select className="selectCalendar col-sm-6" style={{ marginLeft: "15px", cursor: 'pointer' }}
                                                                            value={months[getMonth(date)]}
                                                                            onChange={({ target: { value } }) =>
                                                                                changeMonth(months.indexOf(value))
                                                                            }>
                                                                            {months.map(option => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        <select className="selectCalendar col-sm-5" style={{ cursor: 'pointer' }} value={getYear2(date)}
                                                                            onChange={({ target: { value } }) => changeYear(value)}>
                                                                            {years.map(option => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                )}
                                                                    selected={startDateLab}
                                                                    ppreSelection={startDateLab}
                                                                    onChange={() => { }}
                                                                    onSelect={date => handleDate4(date)}
                                                                    locate="es"
                                                                    className="form-control selectionCalendar iputControlHv"
                                                                    calendarClassName="backCalendarCW"
                                                                    dateFormatCalendar="MMMM"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="Dia/Mes/Año"
                                                                    maxDate={(new Date())}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 p-0" id="fNal" >
                                                            <label className="labelPerfil" style={TxtLabel} >Fecha finalización</label>
                                                            <div className="input-group RctCurr">
                                                                <DatePicker renderCustomHeader={({ date, changeYear, changeMonth, }) => (
                                                                    <div className="row justify-content-center m-2">
                                                                        <select className="selectCalendar col-sm-6" style={{ marginLeft: "15px", cursor: 'pointer' }}
                                                                            value={months[getMonth(date)]}
                                                                            onChange={({ target: { value } }) =>
                                                                                changeMonth(months.indexOf(value))
                                                                            }>
                                                                            {months.map(option => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        <select className="selectCalendar col-sm-5" style={{ cursor: 'pointer' }} value={getYear2(date)}
                                                                            onChange={({ target: { value } }) => changeYear(value)}>
                                                                            {years.map(option => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                )}
                                                                    selected={startDateLabF}
                                                                    ppreSelection={startDateLabF}
                                                                    onChange={() => { }}
                                                                    onSelect={date => handleDate6(date)}
                                                                    locate="es"
                                                                    className="form-control selectionCalendar iputControlHv"
                                                                    calendarClassName="backCalendarCW"
                                                                    dateFormatCalendar="MMMM"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="Dia/Mes/Año"
                                                                    maxDate={(new Date())}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-check pl-3 col-12" id="forChk">
                                                        <input type="checkbox" formcontrolname="currentJob" className="mr-1" name="currentJob" id="chkTA" value="trabajo aqui" onChange={(e) => { handleFormContact3(e) }} />
                                                        <label for="chkTA">
                                                            <span style={{ color: '#777777', fontSize: '14px' }}>Trabajo actualmente aquí</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="modal-footer" style={{ border: 'none' }}>
                                                <button type="submit" className="btnYellow mb-5" style={{ marginBottom: '0rem', display: !vdt3 || addComple ? '' : 'none' }} onClick={(e) => { validinfoEd(IdEdu != 0?12:4) }} >Guardar</button>
                                                    <button type="submit" className="btnYellow mb-5" style={{ marginBottom: '0rem', display: vdt3 && !addComple ? '' : 'none' }} onClick={(e) => { validinfoEd(12) }} >Guardar</button>
                                                    {/* <button type="submit" className="btnYellow mb-5" style={{ marginBottom: '0rem' }} onClick={(e) => { validinfoEd(4) }} >Guardar</button> */}
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                    <div className="row" style={{paddingTop: '40px'}}>
                                        <div className="col-12 m-auto" style={{  borderTop: '1px solid #D5D5D5' }} />
                                        <h5 className="mb-5 col-12 mt-2" style={txtTitle}>Habilidades y aptitudes</h5>
                                        <div className="form-group col-md-6">
                                            <h5 className="labelPerfil">Habilidades y aptitudes</h5>
                                            <div className="input-group mb-3">
                                                <select name="habilidadyaptitud" id="habilidadyaptitud" className="form-control iputControlHv iconoArrow" value={formContact.habilidadyaptitud} onChange={(e) => { handleFormContact3(e); updatehabilidades(e) }}>
                                                    <option value={0} style={{ color: '#9B9B9B', fontFamily: 'OpenSans', fontSize: '16px' }}>
                                                        Habilidades y aptitudes
                                                    </option>
                                                    {
                                                        Habilidades.map(data => {
                                                            return (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.text}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>&nbsp;</label>
                                            <div className="form-control txtAreaCurr">
                                                {Habilidades.length !== 0 && (
                                                    Habilidades.map(data => {

                                                        return (
                                                            data.value != Habilidades.value ?
                                                                <span id={'bagde' + data.value} style={{ whiteSpace: 'nowrap' }}
                                                                    className="badge-curriculum mano">
                                                                    &nbsp;&nbsp;&nbsp; {data.value}
                                                                    <span className="ml-2" style={{ fontFamily: 'Inter-regular', cursor: 'pointer' }} onClick={() => DeleteHabilidades(data.value)}>x</span>
                                                                </span>
                                                                : null
                                                        )

                                                    })
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center mb-5" style={{ width: '256px', paddingLeft: '0px', margin: 'auto' }}>
                                        <button className="btnYellow btn-next mb-5" style={{ width: '100%' }} onClick={(e) => { RtaCtr(); window.scroll(0, 0); }}>
                                            GUARDAR Y CONTINUAR
                                        </button>
                                    </div>
                                </div>
                                <div className="tab-content" id="tabEducacion" hidden={StepCount === 4 ? false : true}>
                                    <p className="mb-4 col-12" style={txtTitle}>Personaliza tu hoja de vida</p>
                                    <p style={{ marginLeft: '13px', color: '#333333', fontSize: '14px', marginBottom: '-30px' }}>1. Selecciona un estilo de plantilla</p>
                                    <div className="col-12 clsshv1">
                                        <div className="containerPlants" style={{ margin: 'auto', width: '100%' }}>
                                            <div className="card bg-light mb-5 mt-5 sbclssHv1" id="hv1">
                                                <div className="card-body text-center">
                                                    <button className="swiper-button-next d-lg-block d-md-block d-sm-none d-none" id="swiper-button-next" onClick={(e) => { clickNavigation(1) }} style={{ border: 'none', backgroundColor: 'transparent', width: '16px', height: '18px' }}></button>
                                                    <button className="swiper-button-prev d-lg-block d-md-block d-sm-none d-none" id="swiper-button-prev" onClick={(e) => { clickNavigation(2) }} style={{ border: 'none', backgroundColor: 'transparent', width: '16px', height: '18px' }}></button>
                                                    <Swiper className="ClssMobHv" style={{ transform: 'translate3d(203px, 0px, 0px)' }}
                                                        {...params} >
                                                        {
                                                            dataTypeTemplate.map((data, index) => {
                                                                return (
                                                                    <div className="swiper-slide" id={'swiper' + index}>
                                                                        <iframe style={{ height: '100%' }} id={'planFrame' + data.idPlantilla}></iframe>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Swiper>
                                                    <button className="btnMainBlack mt-5" style={{ width: '217px', fontFamily: 'OpenSans' }} onClick={(e) => { seleccionarPlantilla() }}>
                                                        Seleccionar plantilla
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-none" id="plntllColor">
                                        <p className="ml-2">2. Selecciona un color de plantilla</p>
                                        <div className="col-lg-11 col-sm-12" style={{ margin: 'auto', width: '100%' }}>
                                            <div className="card bg-light mb-5 mt-5" id="hv2">
                                                <div className="row w-100 colorPlant" style={{ height: '405px' }}>
                                                    <div className="col-lg-4 col-md-5 p-0">
                                                        <div className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                                                            {
                                                                jsonColores.map(data => {

                                                                    return (
                                                                        <a className="nav-link nav-link-personalizacion tabColor" id={"primerTabColor" + data.idColor} onClick={(e) => { seleccionarPlantilla() }}
                                                                            data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home">
                                                                            <div className="row" style={{ filter: 'opacity(0.5)' }}>
                                                                                <div className="colorsito mr-2"
                                                                                    style={{
                                                                                        display: 'block', background: 'linear-gradient(to right bottom, ' + data.colorBBDD + ' 50%, #252932 50%)',
                                                                                        width: '20px',
                                                                                        height: '20px',
                                                                                        marginTop: '25px'
                                                                                    }}>
                                                                                </div>
                                                                                <span style={{ display: 'block' }}>{data.nombreColor}</span>
                                                                            </div>
                                                                        </a>
                                                                    )

                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 ">
                                                        <div className="tab-content" id="v-pills-tabContent">
                                                            <div className="tab-pane show active text-center" id="v-pills-home" role="tabpanel"
                                                                aria-labelledby="v-pills-home-tab">
                                                                <iframe title="plantilla color hv" id="frameColorPlantilla"></iframe>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-none" id="plTipoG">
                                        <p className="ml-2">3. Selecciona una tipografía para la plantilla</p>
                                        <div className=" col-lg-11 col-sm-12" style={{ margin: 'auto', width: '100%' }}>
                                            <div className="card bg-light mb-5 mt-5" id="hv2">
                                                <div className="row w-100">
                                                    <div className="col-lg-3 col-md-12 p-0">
                                                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                            {
                                                                jsonTextoTipos.map(data => {

                                                                    return (
                                                                        <a className="nav-link-personalizacion nav-link tabTipoTexto" id={"primerTabTexto" + data.idTipoTexto} onClick={(e) => { seleccionarPlantilla() }}
                                                                            data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home">
                                                                            <span className="ml-5" style={{ display: 'block' }}>{data.nombreTipoTexto}</span>
                                                                        </a>
                                                                    )

                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9 col-md-12">
                                                        <div className="tab-content" id="v-pills-tabContent">
                                                            <div className="tab-pane show active text-center" id="v-pills-home" role="tabpanel"
                                                                aria-labelledby="v-pills-home-tab">
                                                                <iframe id="frameTipoTexto" title="Tipo de texto"></iframe>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-center mb-5">
                                            <button className="btnYellow mb-5" style={{ width: '256px' }} onClick={(e) => { RtaCtr(); ctrRta(5); window.scroll(0, 0); }}>
                                                GUARDAR Y CONTINUAR
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="MjCrr2" hidden={StepCount === 5 ? false : true}>
                                <div className="col-12 p-5">
                                    <h5 class="col-md-12" style={{ fontFamily: 'cibSans-Bold', fontSize: '28px' }}> Crea tu hoja de vida. </h5>
                                    <div className="row mb-5">
                                        <div className="col-md-12">
                                            <div className="alert alert-success mt-4" role="alert" style={{ backgroundColor: 'rgb(238, 255, 230, 0.6)', height: '28%', padding: '0.5rem 1.5rem', fontSize: '14px', borderRadius: '0px' }}>
                                                <FontAwesomeIcon icon={faCheck} style={{ color: '#00915A' }} className="mr-3" />Has creado tu hoja de vida con éxito
                                                <span style={{ float: 'right', fontSize: '24px', lineHeight: '19px', color: '#828282' }}>×</span>
                                            </div>
                                            <p className="mt-4">Puedes agendar una revisión de tu hoja de vida por uno de nuestros expertos
                                                en selección para mejorar tu perfil laboral y encontrar nuevas y mejores oportunidades laborales.   </p>
                                        </div>
                                        <div className="col-md-12 text-center mt-4">
                                            <button type="button" id="schCurr" className="btnYellow mr-2" style={{ width: '238px' }} onClick={() => { setStepTab(2) }}>
                                                AGENDAR REVISIÓN
                                            </button>
                                            <button type="button" class="btnMainBlack ml-2" data-toggle="modal"
                                                data-target="#myModal" style={{ width: '238px' }} id="MdlHv">
                                                DESCARGAR HOJA DE VIDA
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="myModal" className="modal fade" role="dialog">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal" id="closemyModal">&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            <iframe title="Modal descarga" src={pdf_} width="100%" height="600px"></iframe>
                                            <div className="modal-footer" >
                                                <button type="button" class="btn btn-default" onClick={(e) => { descargarPdf(1) }}>Descargar</button>
                                                <button type="button" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {stepTab === 2 && (
                        <Sheduling key={1} type="2023" module="HojaVida" propsP={props} infoSheduling={infoSheduling} />
                    )}
                </section>
            </ContainerService>
        </React.Fragment>
    );
}
