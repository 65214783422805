import Axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import ContainerService from '../../components/container-service/ContainerService';
import { urlAPI } from '../../utils/config';
import { getToken, getUser, isActive } from '../../utils/validations';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faDollarSign, faFilter, faMapMarkerAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import joboffers from "../../assets/img/job-offers-desempleo/bdb-joboffers-offers-ico-blue.svg";
import filtro from "../../assets/img/job-offers-desempleo/bancolombia-ico-ofertas-02.svg";
import elempleo from "../../assets/img/job-offers-desempleo/elempleo.png";
import '../psico/psicoStyle.css'
import ReactPaginate from 'react-paginate';
import Loader from "../../components/loader/Loader";
import Search from "../../assets/img/icon/standard/ico-ofertas-002.svg"
import Clean from "../../assets/img/icon/standard/ico-ofertas-003.svg"
import Location from "../../assets/img/icon/standard/ico-ofertas-004.svg"
import Edif from "../../assets/img/icon/standard/ico-ofertas-005.svg"
import Coing from "../../assets/img/icon/standard/ico-ofertas-006.svg"
import Down from "../../assets/img/home-out-003.svg";


export default function Joboffers() {
    function defaultFormoOffers() {
        return {
            sectores: '',
            Ciudad: '',
            Rango: '',
            txtOrden: '',
            txtCargo: '',
        };
    }
    const [formOffers, setFormOffers] = useState(defaultFormoOffers);
    const [dataCity, setdataCity] = useState([]);
    const [dataSectores, setdataSectores] = useState([]);
    const [dataSalarios, setdataSalarios] = useState([]);
    const [dataOfertas, setdataOfertas] = useState([]);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(5);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [loaderShow, setLoaderShow] = useState(false);
    const [userNameJob, setUserNameJob] = useState("");

    useEffect(() => {
        // if (isActive()) {
            Axios.get(`${urlAPI}/Profile/InfoGeneral`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
              }
            }).then(({data}) => {
              //console.log(data.data.nombres);
              setUserNameJob(data.data.nombres)
            //   const [nombre] = nombres.split(" ");
            //   setUserSession(nombre);
            }).catch(error => {
              //console.log(error);
            });
        //   }
        getCiudades();
        getSectores();
        getSalarios();
        getOfertas();
    }, [offset]);
    const linkbread = [{
        to: "/auth/inicio",
        tittle: "Inicio > "
    },
    {
        to: "/s/desempleo/ofertas-laborales/inicio",
        tittle: "Conoce ofertas laborales",
    }];
    const getCiudades = () => {
        Axios.get(`${urlAPI}/oferta/ciudades`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setdataCity(succ.data);
        }).catch(error => {
            //console.log(error);
        });
    }
    const getSalarios = () => {
        Axios.get(`${urlAPI}/oferta/salarios`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setdataSalarios(succ.data);
        }).catch(error => {
            //console.log(error);
        });
    }
    const getSectores = () => {
        Axios.get(`${urlAPI}/oferta/sectores`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            setdataSectores(succ.data);
        }).catch(error => {
            //console.log(error,);
        });
    }
    let numerPages = 0
    const getOfertas = () => {
        let sector = document.getElementById('sectores');
        sector = sector.value;
        let Ciudad = document.getElementById('Ciudad');
        Ciudad = Ciudad.value
        let txtCargo = document.getElementById('txtCargo');
        txtCargo = txtCargo.value;
        let Rango = formOffers.Rango
        let cantidad = '2000';
        if (sector === "" || sector === undefined) {
            sector = "."
        }
        if (Ciudad === "" || Ciudad === undefined) {
            Ciudad = "."
        }
        if (txtCargo === "" || txtCargo === undefined) {
            txtCargo = "."
        }
        if (Rango === "" || Rango === undefined) {
            Rango = "."
        }
        //console.log(Rango, ' rangooooo');
        setLoaderShow(true);
        Axios.get(`${urlAPI}/oferta/ofertas?sector=` + sector + `&ciudad=` + Ciudad + `&salario=` + Rango + `&cantidad=` + cantidad + `&busqueda=` + txtCargo, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            }
        }).then(succ => {
            //console.log(succ,' data');
            let slice = succ.data.slice(offset, offset + perPage)
            const postData = slice.map((pd, index) =>
                <React.Fragment>
                    <div className="card mb-4 cardOferta">
                        <div className="card-header offerJob " style={{ minHeight: '65px', paddingTop: '18px' }}>
                            <div className="row">
                                <div className="col-md-8 col-sm-12 title-card">
                                    <span>
                                        {pd.tituloOferta}
                                    </span>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <span className="float-lg-right mt-1 float-sm-left sub-Card">
                                        <span style={{ fontSize: '12px', color: '#696969' }}>
                                            Publicado
                                            <label className="date-Card" style={{ fontSize: '12px' }}>
                                                {formatearFecha(pd.fechaPublicacionOferta)}</label>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body cuerpoOferta">
                            <img src={elempleo} style={{ width: '100px', maxhHeight: '100px', marginLeft: '16px', marginTop: '14px' }} />
                            <br /><br />
                            <p className='subtitle-card'>
                                Descripción general
                            </p>
                            <p className='subtitle-card'>
                                {pd.descripcionOferta}
                            </p>
                        </div>
                        <div className="card-footer bg-transparent" style={{ padding: '20px 30px' }}>
                            <div className="row">
                                <div className="" style={{ padding: '5px', minWidth: '90px' }}>
                                    <label style={{ color: '#58595B', fontSize: '16px' }} className="float-lg-left">
                                        <img src={Location} />
                                        &nbsp;
                                        {pd.ciudadOferta}
                                    </label>
                                </div>
                                <div className="col-xl-4 col-lg-12 col-md-12 " style={{ padding: '5px', }}>
                                    <label style={{ color: '#58595B', fontSize: '16px' }} className="float-lg-left">
                                        <img src={Edif} />
                                        &nbsp;
                                        {pd.area}
                                        <a className="hiddenInfo" style={{ visibility: 'hidden' }}>Otras</a>
                                    </label>
                                </div>
                                <div className="col-xl-3 col-lg-12 col-md-12" style={{ padding: '5px', }}>
                                    <label style={{ color: '#58595B' }} className="float-lg-left mb-3">
                                        <img src={Coing} />
                                        &nbsp;
                                        {pd.salarioOferta.includes('$') && (
                                            pd.salarioOferta + ' M'
                                        )}
                                        {!pd.salarioOferta.includes('$') && (
                                            pd.salarioOferta
                                        )}
                                    </label>
                                </div>
                                <div className="col-xl-3 col-lg-12 col-md-12 dtxtMobl" style={{ padding: '5px' }}>
                                    <button className='btnPost'>
                                        <a className='texto-btn' href={pd.link} target='_blank'>
                                            POSTULARME
                                        </a>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </React.Fragment>)
            setData(postData)
            let numerPages = postData
            setPageCount(Math.ceil(succ.data.length / perPage))
            setdataOfertas(succ.data);
            setLoaderShow(false);
        }).catch(error => {
            //console.log(error, " Datos back ");
        });
    }
    const handlePageClick = (e) => {
        setLoaderShow(true);
        const selectedPage = e.selected;
        setOffset(selectedPage + 1)
        setLoaderShow(false);
    };
    const handleFormOffers = (e) => {
        //console.log(e.target.value,' carge');
        setFormOffers({ ...formOffers, [e.target.name]: e.target.value });
    }

    
        
    const formatearFecha = (fecha) => {
        let f = fecha.split('T')[0];
        let f2 = f.split('-');
        let dia = f2[2];
        let mes = f2[1];
        let anio = f2[0];
        switch (mes) {
            case '01':
                mes = 'Ene';
                break;
            case '02':
                mes = 'Feb';
                break;
            case '03':
                mes = 'Mar';
                break;
            case '04':
                mes = 'abr';
                break;
            case '05':
                mes = 'May';
                break;
            case '06':
                mes = 'Jun';
                break;
            case '07':
                mes = 'Jul';
                break;
            case '08':
                mes = 'Ago';
                break;
            case '09':
                mes = 'Sep';
                break;
            case '10':
                mes = 'Oct';
                break;
            case '11':
                mes = 'Nov';
                break;
            case '12':
                mes = 'Dic';
                break;
        }
        return dia + ' ' + mes + ' ' + anio;
    }

    return (
        <React.Fragment>
            {loaderShow && <Loader show={loaderShow} />}
            <ContainerService tittle="Conoce ofertas laborales" classLine="tittleService" styleTittle={{ fontSize: "34px", lineHeight: "56px", fontFamily: 'DTL-ArgoT-regular' }} linkBread={linkbread}>
                <section className="cardsPerfil mb-5">
                    <div className="col-12 mt-4">
                        <div className="tab-pane fade show active p-2" id="pills-info" role="tabpanel" aria-labelledby="pills-home-tab">
                            <p className="pCabeceraModulo  txt-modulo col-12 mt-5">
                                Hola {userNameJob},
                            </p>
                            <p className="pCabeceraModulo mb-4 txt-modulo col-12">
                                A continuación encontrarás las mejores ofertas laborales vigentes en el mercado, seleccionadas según tu perfil.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-12 col-sm-12 mt-4">
                                <div className="row">
                                    <div className="col-md-12 col-xs-12 filterJobs pt-2 pb-2" style={{ borderRadius: '4px' }}>
                                        <div className="titleFilters">
                                            <img src={filtro} className="mr-3" />
                                            <span>Filtros</span>
                                        </div>
                                        <div className="col-md-12 col-xs-12 noPadding">
                                            <div className="form-group camp-input iconDow">
                                                <label className='textJobs camp-input' for="typeId">Ciudad</label>
                                                <select name="Ciudad" id="Ciudad" className="input-texto form-control inputBorderNone disabled iconDow" value={formOffers.Ciudad} onChange={(e) => { handleFormOffers(e) }}>
                                                    <option value="" selected="true">
                                                    Selecciona una ciudad 
                                                    </option>
                                                    {
                                                        dataCity.map(data => {
                                                            return (
                                                                <option key={data.Ciudad} value={data.Ciudad}>
                                                                    {data.Ciudad}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-xs-12 noPadding">
                                            <label className='textJobs camp-input iconDow' for="typeId">Industria</label>
                                            <select name="sectores" id="sectores" className="input-texto form-control inputBorderNone disabled iconDow" value={formOffers.sectores} onChange={(e) => { handleFormOffers(e) }}>
                                                <option value="" selected="true">
                                                    Selecciona una industria
                                                </option>
                                                {
                                                    dataSectores.map(data => {
                                                        return (
                                                            <option key={data.Sector} value={data.Sector}>
                                                                {data.Sector}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-12 col-xs-12 noPadding mt-3">
                                            <div className="form-group ofjobMobile" id="RgSl">
                                                <label className='textJobs' for="typeId mt-2 mb-2" >Rango salarial (en millones)</label>
                                                <div style={{ width: '100%', height: '590px' }} id="Rango" value={formOffers.Rango} onChange={(e) => { handleFormOffers(e) }}>
                                                    {
                                                        dataSalarios.map(data => {
                                                            return (
                                                                data.Salario !== 'Confidencial' &&(
                                                                <div className="form-check mt-3 inputJob" style={{ height: '20px' }}>
                                                                    <input className="form-check-input checkSalario" name="Rango" style={{ top: '5px' }} id={data.Salario} type="checkbox" value={data.Salario} />
                                                                    <label className="form-check-input checkSalario" for={data.Salario}><span >{data.Salario}</span></label>
                                                                   
                                                                   
                                                                </div>
                                                                )
                                                            );
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-xs-12 noPadding">
                                            <a className="btn btnfiltrarjob btnVisitar btn-block mt-4 mb-4" onClick={(e) => { getOfertas() }} 
                                            style={{fontFamily:'OpenSans-regular'}}>
                                                Aplicar filtros
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-12 col-sm-12 mt-4 ofjobMobile">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group iconStep" id="txtOrden1">
                                            <label className='orderSelect'>Organizar</label>
                                            <select id="txtOrden" name="txtOrden" className="form-control inputBorderNone disabled more-recient" value={formOffers.txtOrden} onChange={(e) => { handleFormOffers(e) }}>
                                                <option value="1" selected="true">Más reciente</option>
                                                <option value="2">Menos reciente</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <div className="input-group mb-3">
                                            <input type="text" id="txtCargo" name="txtCargo"
                                                className="form-control search-form fa-fonticons"
                                                placeholder="¿Qué cargo estás buscando?"
                                                style={{ paddingRight: '40px' }} value={formOffers.txtCargo} onChange={(e) => { handleFormOffers(e) }} />
                                            <div className="input-group-append">
                                                <a className="btn" style={{ float: 'right', position: 'absolute', right: '0px', zIndex: 999 }}>
                                                    <img className="btn-search " src={Search} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-xs-12 mb-4c resultsJobs ofjobMobile">
                                        <div className="alert alert-success" role="alert" style={{ display: 'none' }}>
                                            <b><span></span></b>
                                            <br />
                                            <span></span>
                                        </div>
                                        {
                                            <div className="App">
                                                {data}
                                                <ReactPaginate
                                                    previousLabel="  Anterior"
                                                    nextLabel={"Siguiente  "}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    pageLinkClassName={'pocoyo'}
                                                    activeClassName={"active"} />
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ContainerService>
        </React.Fragment>
    );
}