import React from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap'

const ModalGenric = ({children, setShow, Show, title}) => {
    return (
        <Modal
            show={Show}
            onHide={setShow}
            size="sm"
            centered
        >
            <Card className="card__password">
                <Row className="w-100 p-0 m-0">
                    <Col className="col-12 text-center container__header__password">
                        <p className="form-label mb-0" style={{ color: '#fff' }}>
                            {title}
                        </p>
                    </Col>
                    {
                        children
                    }
                </Row>
            </Card>
        </Modal>
    )
}

export default ModalGenric