import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";

export default function ModalSaveBene(props) {
    const { showM, clickClose } = props;
    const [show, setShow] = useState(showM);
    const handleClose = () => setShow(false);
    const acceptTerm = ()=>{
        localStorage.setItem('smaller','true');
        handleClose();
    }
    return (
      <React.Fragment>
        <Modal
          show={show}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          className="contentModalHomeSmallBene"
        >
          <Modal.Body style={{width: "102%", borderRadius: "8px !important"}}>
            <div className="container">
              <div className="row">
                <div className="pr-4 pl-4 pb-4 pt-2" style={{paddingTop:".5rem !important"}}>
                  <div className="text-center mb-2">
                    <h4 className="mt-2" style={{ fontSize: "22px", fontFamily: "Next", color: "#323B4E",maxWidth: "100%", fontWeight:"bold" }}>
                      Beneficiario de menor a mayor edad
                    </h4>
                    <p className="mt-2 " style={{maxWidth:"100%", fontFamily:"Helvetica" ,color:"#808285", fontSize:"14px",textAlign:'justify', padding:'0px 10px'}}>
                    Estimado  beneficiario,  tenga  en  cuenta  que  en  caso  de  haber  ingresado  a  los  servicios  del  ecosistema siendo  menor  de  edad,  al  momento  de  cumplir  18  años  y  continuar  utilizando  el  servicio,  se  pedirá  la autorización  directa  de  tratamiento  de  datos,  según  nuestra  POLÍTICA  DE  TRATAMIENTO  DE  LA INFORMACIÓN (PTI) que se encuentra disponible en CARDIF COLOMBIA  SEGUROS  GENERALES  (la  “Política”),  disponible  en  www.bnpparibascardif.com.co  enlace “Privacidad”. En  caso  de  no estar  de  acuerdo  con  estas por favor absténgase  de utilizar este servicio y comuníquese con nuestras líneas de atención para proceder con la eliminación de sus datos:    
                    </p>
                    <ul>
                        <li style={{fontFamily:'Helvetica',color:'#808285', fontSize:"14px",textAlign:'justify'}}>Correo: atencionalcliente@cardif.com.co </li>
                        <li style={{fontFamily:'Helvetica',color:'#808285', fontSize:"14px",textAlign:'justify'}}>Teléfono en Bogotá: 307 70 32  - Resto del país: 01 8000 122532  </li>
                    </ul>
                    <button className="btnAzul" style={{ borderRadius: "5px", width: "50.5%", fontSize:"17px", fontWeight:"700"}} onClick={()=>{acceptTerm()}}>Aceptar</button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }