import React, { useEffect, useState } from "react";
import ContainerService from "../../components/container-service/ContainerService";
import { infoSheduling } from "../../services/ParamPhantom";
import Axios from "axios";
import { urlAPI } from "../../utils/config";
import { getToken } from "../../utils/validations";
import { ScheduleTeleNew } from "../../components/sheduling/ScheduleTele/ScheduleTeleNew";

export default function ShedulingTelemedic(){
    const [DataFirst, setDataFirst] = useState(infoSheduling)

    const linkbread=[{
        to:"/auth/inicio",
        tittle:"Inicio > "
    },
    {
        to:"/s/medico/agenda",
        tittle:"Médico a un clic ",
    }];

    useEffect(() => {
        Axios.get(`${urlAPI}/Profile/InfoGeneral`, { headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + getToken(), } })
            .then(scc => {
                setDataFirst({
                    ...DataFirst,
                    typeSheduling: '2047',
                    typeTextSheduling: 'Medicina General',
                    nombres: scc.data.data["nombres"],
                    correo: scc.data.data["correoElectronico"],
                    documento: scc.data.data["identificacion"],
                })
                DataFirst.typeSheduling = '2047';
                DataFirst.typeTextSheduling = 'Medicina General';
                DataFirst.nombres = scc.data.data?.nombres;
                DataFirst.correo = scc.data.data?.correoElectronico;
                DataFirst.documento= scc.data.data.identificacion;
            })
        setDataFirst(DataFirst)
    }, [])
    return(
        <ContainerService  tittle="Médico a un clic" styleTittle={{fontSize:"34px"}} linkBread={linkbread}>
            {
                DataFirst?.typeSheduling && (
                    <ScheduleTeleNew key={1} type="2047" module="Telemedicina" infoSheduling={DataFirst} />
                )
            }
        </ContainerService>  
    );
}
