import { useState, useEffect } from 'react'
import * as CryptoJS from "crypto-js";
import Swal from 'sweetalert2';
import { useAxios } from './useAxios'
import { defaultFormRegister } from '../interfaces/interfacesGenerales';
import { encryptDecrypt, addLogList, getGeoMok } from '../utils/validations';
import sha256 from "sha256";
import { obtenerIni } from '../utils/functionRegister';
import { useDocumentType } from './useDocumentType';

export const useRegister = () => {

 const [stateInput, setStateInput] = useState(false)
 const { callBack } = useAxios()
 const [loaderShow, setLoaderShow] = useState(false);
 const [ documentTypes, setDocumentTypes ] = useState();
 const {documents} = useDocumentType({documentTypes})
 const [preRegister] = useState({
  IdentificationType:5,
  Identification:'',
  TokenCaptcha:'',
  Ip:''
 })
 const [ loginForm ] = useState({
  UserId:'',
  Identification:'',
  Phone:'',
  Email:'',
  Password:'',
  Ip:'',
  TokenPassword: '',
  TokenCaptcha:'',
 })

 const [ DataSesion ] = useState({
  UserId: '',
  Identification: '',
  Ip: ''
})

const [messageModal, setMessageModal] = useState({
  tittle:'Usuario ya registrado',
  text:'Accede a tus servicios',
  button:'ACCEDER A MI CUENTA',
})

const addLog = (pagina, control, extra, resultado, identificador) =>{
  const logUserObj = {
  pagina:pagina,
  control:control,
  extra:extra,
  resultado:resultado,
  identificador:identificador,
  }
  return logUserObj
}


const createLogRegister = (document) => {
    const logUserList = []
    logUserList.push(addLog('Validación Contraseña', 'Validada', 'Registro', 'Exitoso', document))
    logUserList.push(addLog('Validación Correo', 'Validado', 'Registro', 'Exitoso', document))
    logUserList.push(addLog('Validación Telefono', 'Validado', 'Registro', 'Exitoso', document))
    logUserList.push(addLog('Validación Acepta Terminos', 'Validado', 'Registro', 'Exitoso', document))
    logUserList.push(addLog('Validación Acepta Politicas', 'Validado', 'Registro', 'Exitoso', document))
    addLogList(logUserList)
}

const createLogPreRegister = (documentType,document) => {
  const logUserList = []
  let documentTypeName = documentTypes.filter(x => x.id == documentType)
  logUserList.push(addLog('Validacion Tipo Documento', 'Tipo Documento Ingresado - ' + documentTypeName[0]?.text, 'Preregistro', 'Exitoso', document))
  logUserList.push(addLog('Validacion Documento', 'Documento Ingresado', 'Preregistro', 'Exitoso', document))
  logUserList.push(addLog('Validacion Boton Consulta Tus Datos', 'Validado', 'Preregistro', 'Exitoso', document))
  addLogList(logUserList);
}

 const key = CryptoJS.enc.Utf8.parse('7061737323313233');
 const iv = CryptoJS.enc.Utf8.parse('7061737323313233');

 const preRegisterUser =(documentType,document,captcha)=>{
    
    if (!document) {
      setLoaderShow(false)
      Swal.fire({
          title: 'Documento obligatorio',
          customClass:{
            title:'txtDoc'
          },
          confirmButtonText:'Aceptar',
          
      });
      return
    }
    setLoaderShow(true)
    getGeoMok().then(succ =>{
    preRegister.IdentificationType = documentType?parseInt(documentType):5;
    loginForm.TokenPassword = captcha;
    preRegister.Ip = succ.data.ip;
    preRegister.TokenCaptcha = captcha;
    preRegister.Identification = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(document), key,
    {
      keySize: 256 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    createLogPreRegister(preRegister.IdentificationType, document)
    callBack('post','/register/preregisteruser',preRegister)
    .then((suss)=>{
      if (suss.hasOwnProperty("StatusCode") && suss.StatusCode !== 200) {
        Swal.fire({
          title: suss.Message.includes('existente') ? messageModal.tittle : suss.Message,
          customClass:{
            title:'txtDoc'
          },
          text: suss.Message.includes('existente') ? messageModal.text : '',
          confirmButtonText: suss.Message.includes('existente') ? messageModal.button : 'ACEPTAR'
        }).then(() => {
          if (suss.Message.includes('existente')){
            window.location.href = '/inicia-sesion'
          }
        });
        return
      }
      if(suss.hasOwnProperty("UserResponse")){
        if (suss.StatusMessage.StatusCode === 200) {
            setStateInput(true)
            loginForm.UserId = suss.UserResponse.UserId;
            DataSucc(suss.UserResponse)
        }
      }
    }).finally(()=>{
      setLoaderShow(false)
    })
  })
  }
  
  const DataSucc = (obj) =>{
    defaultFormRegister.nombre = obj.FirstName +" "+ obj.LastName;
    defaultFormRegister.correo = obj.Email;
    defaultFormRegister.telefono = obj.Phone;
 }

 const loginUser= (token, identification) => {
  getGeoMok().then(succ =>{
  DataSesion.UserId= loginForm.UserId;
  DataSesion.Ip = succ.data.ip;
  DataSesion.Identification = identification
  callBack('post','/login/loginwithtoken',DataSesion,token)
  .then((res)=>{
      localStorage.setItem("token", CryptoJS.AES.encrypt(res.data, "eco_scotia"));
      obtenerIni(res.data)
    }).catch((err)=>{
      setLoaderShow(false)
      window.location.href = `/inicia-sesion`;
    })
  })
 }

 const registerUser =(phone, email, pass, document, captcha)=>{
    createLogRegister(document);
    setLoaderShow(true);
    getGeoMok().then(succ =>{
    loginForm.Phone = phone;
    loginForm.Email = email;
    loginForm.Ip = succ.data.ip;
    loginForm.Identification = document;
    loginForm.TokenCaptcha = captcha;
    let password = btoa(
      encryptDecrypt(
        sha256(pass),
        loginForm.TokenPassword.substring(
          loginForm.TokenPassword.length - 10,
          loginForm.TokenPassword.length
        )));
    loginForm.Password = password
    callBack('post','/register/registeruser',loginForm)
    .then((succ)=>{
      if(succ.hasOwnProperty("TokenLogin")){
        if (succ.StatusMessage.StatusCode === 200) {
          loginUser(succ.TokenLogin, document)
        }
      }else{
        setLoaderShow(false)
      }
    }).catch((err)=>{
      setLoaderShow(false)
    })
  })
    setStateInput(false)
 }

 useEffect(() => {
  if(!documentTypes && documents.length > 0){
      setDocumentTypes(documents)
  }
}, [documents])
 
  return {
    stateInput,
    loaderShow,
    preRegisterUser,
    registerUser
  }
}
