import { urlAPI } from "./config";
import * as CryptoJS from "crypto-js";
import Jwt from "jsonwebtoken";
import Axios from "axios";
import { encryptDecrypt, getGeoMok, getNavegator, getUser } from "./validations";

export const obtenerIni = (token) => {
    Axios.get(`${urlAPI}/Login/obtenerSession`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }).then(success => {
        localStorage.setItem(
          "user",
          CryptoJS.AES.encrypt(JSON.stringify(success.data), "eco_scotia")
        );
        var dispo = getNavegator();
        getGeoMok().then(succ => {
          var encrip = btoa(encryptDecrypt(`${succ.data.ip}<${getUser().idUsuario}`, succ.data.ip));
          let obj = {
            ipUsuario: succ.data.ip,
            dispositivo: dispo,
            link: encrip
          }
          Axios.post(`${urlAPI}/Seguridad/EnvioCorreoDispositivo`, JSON.stringify(obj), {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          }).then(succ => {
            var tokenJwt = Jwt.sign({ data: 'creacionToken' }, 'Db', { expiresIn: 360 });
            localStorage.setItem("jwt", tokenJwt);
            window.location.href = "/auth/inicio";
          }).catch(error => {
            // setLoaderShow(false);
          });
        }).catch(error => {
          // setLoaderShow(false);
          //console.log(error);
        });
      }).catch(error => {
        //console.log(error);
        window.location.href = "/inicia-sesion";
      });
  }