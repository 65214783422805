import React, { useState, useEffect } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import eyetrue from "../../assets/img/home-out/eyetrue.svg";
import eyefalse from "../../assets/img/home-out/eyefalse.svg"

export default function (props) {
  const [weight, setWeight] = useState(false);
  const {
    maxlength,
    id,
    name,
    onChangaInput,
    label,
    placeholder,
    type,
    error,
    errorText,
    value,
  } = props;

  useEffect(()=>{
    if(window.location.pathname ==='/inicia-sesion' || window.location.pathname === "/olvidaste-tu-contrasena" || window.location.pathname === "/olvidaste-tu-contrasena/codigo"){
      setWeight(true);
    }
    else{
      setWeight(false);
    }
  });

  const [typeField, setTypeField] = useState(false);
  return (
    <div className="mt-3">
      {weight ?(
        <label className={label == 'Número de documento' || label === 'Contraseña' || label ==="Código de verificación" || label==="Nueva contraseña" || label==="Repetir contraseña"?'Cinp weightLabel mb-0':"weightLabel"} htmlFor={id} >
          {label}
        </label> 
      ):(
        <label className="labelInput regisInp" htmlFor={id}>
        {label}
      </label>
      )}
      <div className="input-group inputLogin" id={id+"1"}>
        {type !== "password" &&  type !== "phone" && type !== "documentRg" &&(
          <input
            type={type}
            maxLength={maxlength}
            className={ 'Cinp1 form-control pt-0 pb-0'} 
            id={id}
            name={name}
            onChange={(e) => onChangaInput(e, name)}
            onKeyUp={(e) => onChangaInput(e, name)} 
            aria-describedby="basic-addon3" 
            placeholder={placeholder}
            maxLength={id === "code"?8:''}
            style={{ fontSize: 30, height: "calc(1.5em + .75rem + 5px)"}}
            value={value}
          />
        )}
        {type === "documentRg" && (
          <input
            type={type}
            maxLength={maxlength}
            className={ 'Cinp1 form-control'} 
            id={id}
            name={name}
            onChange={(e) => onChangaInput(e, name)}
            onKeyUp={(e) => onChangaInput(e, name)}
            aria-describedby="basic-addon3"
            placeholder={placeholder}
            style={{ fontSize: 14, height: "calc(1.5em + .75rem + 5px)" }}
            value={value}
          />
        )}
        {type === "phone" && (
           <input
           type={type}
           maxLength={maxlength}
           className="Cinp1 form-control"
           id={id}
           name={name}
           onChange={(e) => onChangaInput(e, name)}
           onKeyUp={(e) => onChangaInput(e, name)}
           aria-describedby="basic-addon3"
           placeholder={placeholder}
           style={{ fontSize: 14, height: "calc(1.5em + .75rem + 5px)" }}
           value={value}
         />
        )}
        {type === "password" && (
          <>
            <input
              type={typeField ? "text" : type}
              className={label === 'Contraseña' || label==="Nueva contraseña" || label==="Repetir contraseña" ?'Cinp1 inputPss form-control':"inputPass form-control"}
              id={id}
              name={name}
              onChange={(e) => onChangaInput(e, name)}
              onKeyUp={(e)=>onChangaInput(e, name)}
              aria-describedby="basic-addon3"
              placeholder={placeholder}
              style={{ fontSize: 14, height: "calc(1.5em + .75rem + 5px)", fontFamily:'Helvetica'}}
              value={value}
            />
            <div 
              className="input-group-prepend inputPassEye" 
              onClick={() => setTypeField(!typeField)}
            >
              <span className="eyeB" id="basic-addon3" >
                <img style={{color:"#BABABA"}} src={typeField ? eyetrue : eyefalse} />
              </span>
            </div>
          </>
        )}
      </div>
      {error && <small className="text-danger">{errorText}</small>}
    </div>
  );
}
