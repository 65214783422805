import React, { createRef, useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ReCAPTCHA from "react-google-recaptcha";
import sha256 from "sha256";
import $ from "jquery";
import Loader from '../../components/loader/Loader';
import * as CryptoJS from "crypto-js";
import { isEmpty } from "lodash";
import { encryptDecrypt, getUser, getNavegator, addLogList, getGeoMok } from "../../utils/validations";
import { urlAPI, captchaKey, regexValidacionPassword } from "../../utils/config";
import Axios from 'axios';
import ModalTerms from "../../components/modal/ModalTerms";
import ModalPolicies from "../../components/modal/ModalPolicies";
import Jwt from "jsonwebtoken";
import { useHistory } from "react-router-dom";
import { InputComponents } from '../../components/InputComponents/InputComponents';
import './PasswordUpdate.css';

export default function PasswordUpdate() {
    const history = useHistory();
    useEffect(() => {
        window.onpopstate = function (event) {
            setMessageObj({ show: false, message: '' });
        };
    });

    const [form, setForm] = useState(defaultFormValue());
    const [messageObj, setMessageObj] = useState(defaultMessageValue());
    const [loaderShow, setLoaderShow] = useState(false);
    const recaptchaRef = createRef();
    const recaptchaRefPostUpdatePass = createRef();
    const [errorPassword, setErrorPassword] = useState('');
    const [errorAceptaTerminos, setErrorAceptaTerminos] = useState('');
    const [errorAceptaPolitica, setErrorAceptaPolitica] = useState('');
    const refPassword = useRef();

    let terCond = (
        <a href style={{ color: "#454648", textDecoration: "underline", fontFamily: 'Scotia-regular', fontWeight: '400', fontSize: '14px' }}>
            términos y condiciones
        </a>
    );
    let polServ = (
        <a href style={{ color: "#454648", textDecoration: "underline", fontFamily: 'Scotia-regular', fontWeight: '400', fontSize: '14px' }}>
            política de privacidad del servicio
        </a>
    );

    function defaultFormValue() {
        let localUser = window.localStorage.getItem("u_act");
        let user = "";
        let identificacion = "";
        let id = "";
        if(localUser !== null){
          user = JSON.parse(CryptoJS.AES.decrypt(localUser, "eco_scotia").toString(CryptoJS.enc.Utf8));
          identificacion = user.identificacion;
          id = user.id;
        }
        return {
          document: identificacion,
          captcha: "",
          code: "",
          id: id,
          password: "",
          passwordComfirm: "",
          chkTC: "",
          chkPL: "",
        };
    }

    useEffect (() => {
      if(isEmpty(form.id)){
        history.push("/inicia-sesion");
      }
    },[]);

    function defaultMessageValue() {
        return {
            show: "",
            message: "",
        };
    }

    const onChangePassword = (value, name) => {
        setErrorPassword('');
        setForm({ ...form, [name]: value });
        
        if (name === "password") {
            if (value.length > 8) {
                $("#span-password").text(" ");
            }
            else {
                $("#password").focus(function () {
                    $("#span-password").text("Tu contraseña debe contener al menos 8 caracteres.");
                });

            }
            if (value.length > 0) {
                $('#barra').addClass('d-flex')
                $('#result').addClass('d-block')
                $('#barra').removeClass('d-none')
                checkStrength(value);
            }
            else {
                $('#barra').addClass('d-none')
                $('#result').addClass('d-none')
                $('#barra').removeClass('d-flex')
            }
        }

        if (name === "passwordComfirm") {
            if (value === form.clave) {
                $('#passwordComfirm').removeClass('errorInput');
                $("#span-passwordComfirm").text(" ");
            }
            else {
                $('#passwordComfirm').addClass('errorInput');
                $("#span-passwordComfirm").addClass("text-danger").text("Tu contraseña no coincide");
            }
        }
    };

    const onChangeTerminos = (value) => {
        setForm({ ...form, chkTC: value });
    }
    const onChangePolitica = (value) => {
        setForm({ ...form, chkPL: value });
    }

    const validarForm = () => {
        let pattern = regexValidacionPassword;
        let messageError = '';
        let logUserList = [];
        setErrorPassword('');
        if (isEmpty(form.password) || isEmpty(form.passwordComfirm)) {
            messageError = "Debe diligenciar las contraseñas";
        } else if (form.password !== form.passwordComfirm) {
            messageError = "Tu contraseña no coincide";
        } else if (
            !pattern.test(form.password) || form.password.length < 8 || form.password.length > 16 ||
            !pattern.test(form.passwordComfirm) || form.passwordComfirm.length < 8 || form.password.length > 16
        ) { // Verificacion de contraseñas con el patrón         
            messageError = ` La nueva contraseña debe tener mínimo 8 y máximo 16 caracteres y estar compuesta por lo menos de una minúscula, una mayúscula y un carácter especial.
                   Ejemplo: *,$;#`;
        }
        let messageErrorPassword = messageError;
        setErrorPassword(messageError);

        onChangeTerminos(form.chkTC);
        onChangePolitica(form.chkPL);
        if (!form.chkTC) {           
            messageError = 'Debe aceptar los términos y condiciones';
            setErrorAceptaTerminos(messageError);
        } else {            
            setErrorAceptaTerminos("");
        }
        if (!form.chkPL) {
            messageError = 'Debe aceptar las politicas de privacidad';
            setErrorAceptaPolitica(messageError);
        } else {            
            setErrorAceptaPolitica("");
        }

        if (messageError === '') {
            logUserList.push(addLog('Validación Boton Actualización Contraseña', `Boton Actualización Contraseña`, 'UpdatePassword', 'Exitoso', form.document));
            addLogList(logUserList)
            return true;
        } else {
            logUserList.push(addLog('Validación Campos Invalidos', `Password : ${(isEmpty(messageErrorPassword) && !isEmpty(form.password)) ? 'Validado' : form.password}, Terminos : ${form.chkTC.toString()}, Política : ${form.chkPL.toString()}`, 'UpdatePassword', 'Error', form.document));
            addLogList(logUserList)
            return false;
        }
    }

    const addLog = (pagina, control, extra, resultado, identificador) => {
        const logUserObj = {
            pagina: pagina,
            control: control,
            extra: extra,
            resultado: resultado,
            identificador: identificador,
        }

        return logUserObj;
    }

    const handleUpdatePassword = (e) => {
        onChangeCap(e);
        if (isEmpty(form.captcha)) {
            return;
        }

        if (validarForm()) {
            setMessageObj({ show: false });

            let password = btoa(
                encryptDecrypt(
                    sha256(form.password),
                    form.captcha.substring(form.captcha.length - 10, form.captcha.length)
                )
            );
            let obj = {
                code: "",
                tokenCaptcha: form.captcha,
                id: form.id,
                identificacion: form.document,
                password,
            };
            setLoaderShow(true);
            Axios.post(`${urlAPI}/Login/ActualizarContrasena`, JSON.stringify(obj), {
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(succ => {
                setLoaderShow(false);
                if (succ.data.mensaje.includes("captcha")) {
                    document.getElementById('captchaResetValidationPass').click();
                } else if (succ.data.codigo === 1) {
                    setMessageObj({ show: true, message: succ.data.mensaje });
                } else {
                    localStorage.removeItem('u_act');
                    const btnExecuteUpdatePassCaptcha = document.getElementById("btnExecuteUpdatePassCaptcha");
                    btnExecuteUpdatePassCaptcha.click();
                }
            }).catch(error => {
                //console.log(error);
            });
        }
    };

    const handleLogIn = (e) => {
        let password = btoa(
            encryptDecrypt(
                sha256(form.password),
                e.substring(e.length - 10, e.length)
            )
        );
        setLoaderShow(true);
        getGeoMok().then(succ => {
            let obj = {
                usuario: form.document,
                password: password,
                tokenCaptcha: e,
                ip: succ.data.ip,
            }; 
            Axios.post(`${urlAPI}/Login/loginuser`, JSON.stringify(obj), {
                headers: { "Content-Type": "application/json" }
            }).then(succ => {//console.log(succ)
                localStorage.setItem("token",CryptoJS.AES.encrypt(succ.data.data, "eco_scotia"));
                let tokenJwt = Jwt.sign({ data: 'creacionToken' }, 'Db', { expiresIn: 360 });
                localStorage.setItem("jwt", tokenJwt);
                obtenerIni(succ.data.data);
            }).catch(error => {
                setLoaderShow(false);
                //console.log(error)
            });
        }).catch(error => {
            setLoaderShow(false);
            //console.log(error);
        });
    }

    const obtenerIni = (token) => {
        Axios.get(`${urlAPI}/Login/obtenerSession`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }).then(success => {
            localStorage.setItem(
              "user",
              CryptoJS.AES.encrypt(JSON.stringify(success.data), "eco_scotia")
            );
            let dispo = getNavegator();
            getGeoMok().then(succ => {
              let encrip = btoa(encryptDecrypt(`${succ.data.ip}<${getUser().idUsuario}`, succ.data.ip));
              let obj = {
                ipUsuario: succ.data.ip,
                dispositivo: dispo,
                link: encrip
              }
              Axios.post(`${urlAPI}/Seguridad/EnvioCorreoDispositivo`, JSON.stringify(obj), {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                }
              }).then(succ => {
                window.location.href = `/auth/inicio`;
              }).catch(error => {
    
              });
            }).catch(error => {
              //console.log(error);
            });
          }).catch(error => {
            //console.log(error);
          });
      }

    const checkStrength = (password) => {
        let strength = 0;
        //If password contains both lower and uppercase characters, increase strength value.
        if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
            strength += 1;

            $(".low-upper-case").addClass("text-success");
            $(".low-upper-case i").removeClass("fa-file-text").addClass("fa-check");
            $("#popover-password-top").addClass("hide");
        } else {
            $(".low-upper-case").removeClass("text-success");
            $(".low-upper-case i").addClass("fa-file-text").removeClass("fa-check");
            $("#popover-password-top").removeClass("hide");
        }

        //If it has numbers and characters, increase strength value.
        if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
            strength += 1;
            $(".one-number").addClass("text-success");
            $(".one-number i").removeClass("fa-file-text").addClass("fa-check");
            $("#popover-password-top").addClass("hide");
        } else {
            $(".one-number").removeClass("text-success");
            $(".one-number i").addClass("fa-file-text").removeClass("fa-check");
            $("#popover-password-top").removeClass("hide");
        }

        //If it has one special character, increase strength value.
        if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
            strength += 1;
            $(".one-special-char").addClass("text-success");
            $(".one-special-char i").removeClass("fa-file-text").addClass("fa-check");
            $("#popover-password-top").addClass("hide");
        } else {
            $(".one-special-char").removeClass("text-success");
            $(".one-special-char i").addClass("fa-file-text").removeClass("fa-check");
            $("#popover-password-top").removeClass("hide");
        }

        if (password.length > 7) {
            strength += 1;
            $(".eight-character").addClass("text-success");
            $(".eight-character i").removeClass("fa-file-text").addClass("fa-check");
            $("#popover-password-top").addClass("hide");
        } else {
            $(".eight-character").removeClass("text-success");
            $(".eight-character i").addClass("fa-file-text").removeClass("fa-check");
            $("#popover-password-top").removeClass("hide");
        }

        // If value is less than 2

        if (strength < 2) {
            $("#result").removeClass();
            $("#password-strength").addClass("progress-bar bg-danger");

            $("#result").addClass("text-danger").text("Débil");
            $("#password-strength").css("width", "10%");
        } else if (strength === 2) {
            $("#result").removeClass("text-danger");
            $("#password-strength").removeClass("progress-bar bg-danger");
            $("#password-strength").addClass("progress-bar bg-warning");
            $("#result").addClass("text-warning").text("Moderada");
            $("#password-strength").css("width", "60%");
            return "Week";
        } else if (strength === 4) {
            $("#result").removeClass("text-warning");
            $("#result").addClass("strong");
            $("#password-strength").removeClass("progress-bar bg-warning");
            $("#password-strength").addClass("progress-bar bg-success");
            $("#result").addClass("text-success").text("Fuerte");
            $("#password-strength").css("width", "100%");

            return "Strong";
        }
    };

    function onChangeCap(value) {
        form.captcha = value;
    }

    return (
        <div className="row" style={{ background: "#f8f8f8", marginRight: "0px", marginLeft: "0px" }}>
            {loaderShow && (
                <Loader show={loaderShow} />
            )}
            <div
                className="col-12 col-md-10 mt-5 mb-5"
                style={{ maxWidth: "500px", marginLeft: "auto", marginRight: "auto" }}
            >
                <Card>
                    <Card.Body>
                        <Form
                            className="p-2 p-md-4"
                            onSubmit={(e) => { e.preventDefault() }}
                        >
                            <h4 className="text-center mb-3 font-weight-bold tituloRegistro1">
                                Restablecer contraseña
                            </h4>
                            <div className="subtitleBlack mt-4 mb-4 px-0 pl-md-4 pr-md-3 text-center">
                                <p style={{ fontFamily: 'OpenSans', fontWeight: '400', fontSize: '14px', lineHeight: '20px', color: '#666666' }}>
                                    Ingresa una nueva contraseña para recuperar el acceso a tu
                                    cuenta.
                                </p>
                            </div>

                            <label className="labelInput">Nueva contraseña</label>
                            <InputComponents
                                idInput="password"
                                textLabel="Nueva contraseña"
                                valueInput={form.password}
                                TypeInput="password"
                                BtnState={false}
                                funtioninput={({ target }) => { onChangePassword(target.value, 'password') }}
                                placeholder="Ingresa tu contraseña"
                                TypePassword="ActualizarClave"
                                refPassword={refPassword}
                            />
                            <label className="labelInput">Repetir contraseña</label>
                            <InputComponents
                                idInput="passwordComfirm"
                                textLabel="Repetir contraseña"
                                valueInput={form.passwordComfirm}
                                TypeInput="password"
                                BtnState={false}
                                funtioninput={({ target }) => { onChangePassword(target.value, 'passwordComfirm') }}
                                placeholder="Ingresa tu contraseña"
                                TypePassword="ActualizarClave"
                                refPassword={refPassword}
                            />
                            
                            {(errorPassword !== '') && (
                                <span className="errorMessageForm" style={{marginLeft:'0px'}}>{errorPassword}</span>
                            )}
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={captchaKey}
                                onChange={(e) => handleUpdatePassword(e)}
                                className="d-none"
                            />
                            <button id="captchaResetValidationPass" className="d-none" onClick={() => { recaptchaRef.current.reset() }}></button>

                            <ReCAPTCHA
                                ref={recaptchaRefPostUpdatePass}
                                size="invisible"
                                sitekey={captchaKey}
                                onChange={(e) => handleLogIn(e)}
                                className="d-none"
                            />
                            <button hidden id="btnExecuteUpdatePassCaptcha" onClick={() => recaptchaRefPostUpdatePass.current.execute()} />

                            <p style={{ textAlign: "left", fontFamily: 'OpenSans', fontSize: "14px", lineHeight: "18px", color: "#58595B", marginBottom: "20px", marginTop: "20px", padding: '0 3px' }}>
                                Esta contraseña es exclusiva para el ingreso de la página del ecosistema
                            </p>
                            <div className="checkbox-terminos" style={{ fontSize: 14, color: "#777777", fontFamily: "Inter-regular", marginRight: '2%' }}>
                                <input
                                    className="checkbox-terminos"
                                    type="checkbox"
                                    id="chkTC"
                                    name="chkTC"
                                    onChange={(e) => onChangeTerminos(e.target.checked)}
                                />
                                <label className="checkbox-terminos" htmlFor="chkTC">

                                </label>
                                <span style={{ marginLeft: '4%', fontFamily: 'Scotia-regular', color: '#777777', fontSize: '14px', fontWeight: '400', }}> Acepto los </span>
                                {" "}
                                <ModalTerms title={terCond} component='register' />{
                                    errorAceptaTerminos !== '' && (<><br /><span className="errorMessageForm w-100" style={{ marginLeft: '7%' }}>{errorAceptaTerminos}</span></>)
                                }
                            </div>
                            <div className="checkbox-terminos" style={{ fontSize: 14, color: "#777777", fontFamily: "Inter-regular", marginRight: '2%' }}>
                                <input
                                    className="checkbox-terminos"
                                    type="checkbox"
                                    id="chkPL"
                                    name="chkPL"
                                    onChange={(e) => onChangePolitica(e.target.checked)}
                                />
                                <label className="checkbox-terminos"
                                    htmlFor="chkPL"
                                >

                                </label>
                                <span style={{ marginLeft: '4%', fontFamily: 'Scotia-regular', color: '#777777', fontSize: '14px', fontWeight: '400', }}> Acepto la </span>
                                {" "}
                                <ModalPolicies title={polServ} component='register'/>
                                {
                                    errorAceptaPolitica !== '' && (
                                        <><br /> <span className="errorMessageForm w-100" style={{ marginLeft: '7%' }}>{errorAceptaPolitica}</span></>
                                    )

                                }
                            </div>
                            <Button style={{ borderRadius: '25px', fontFamily: 'OpenSans', fontWeight: '600', fontSize: '16px', textTransform: 'uppercase' }} variant="danger btnLogin " type="submit" className="mt-4" block onClick={() => { recaptchaRef.current.execute(); }}>
                                Cambiar contraseña
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}
