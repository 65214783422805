import React from "react";
import ContainerService from "../../components/container-service/ContainerService";
import Sheduling from "../../components/sheduling/Sheduling";

export default function SchedulingOrientation(props){
    const linkbread=[{
        to:"/auth/inicio",
        tittle:"Inicio > "
    },
    {
        to:"/s/medico/agenda",
        tittle:"Orientación psicológica",
    }];
    const infoSheduling = {
        typeSheduling: 2050,
        classTitleSheduling: 'fechahoraTitulo',
        classVolver: 'btnBackGrey',
        typeTextSheduling:<h5 className="typeShedulingText" style={{width: "92%"}}>Consulta Orientación Psicológica</h5>,
        module:'Orientacion',
        tittleSheduling:'Programa tu consulta',
        paragraph:"Selecciona la fecha, hora y medio para que seas contactada por un profesional de la salud.",
        alertCancelTitle:'Cancelación de consulta',
        alertCancelText:'¿Seguro quieres cancelar tu consulta?  ',
        tooltipsClass:'tooltipShedulingEco',
        messageSuccess:{
          module:'Seguridad',
          variant:'success',
          checkText:'Se ha confirmado tu consulta',
          alertText:'En tu correo electrónico y teléfono celular registrado, recibirás un mensaje que contiene un link con el acceso directo a la consulta de orientación psicológica.   ',
          class:'alert alertSuccessEcosistemas',
        },
        messageCancel:{
          module: "Desempleo",
          variant:'success',
          checkText:'Cancelación de tu consulta',
          alertText:'Tu consulta ha sido cancelada.',
          class:'alert alertDesempleoHelp',
          textButton: "AGENDAR"
        }
    };
    return(
        <ContainerService  tittle="Orientación psicológica" styleTittle={{fontSize:"34px"}} linkBread={linkbread}>
            <Sheduling key={1} type="2050" module="Orientacion" propsP={props} infoSheduling={infoSheduling}/> 
        </ContainerService>  
    );
}