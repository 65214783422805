import React, { useState, createRef,useEffect } from "react";
import { useHistory } from "react-router";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Input from "../../components/input/Input";
import ReCAPTCHA from "react-google-recaptcha";
import * as CryptoJS from "crypto-js";
import Loader from "../../components/loader/Loader";
import TitularG from "../../assets/img/scotia-vida-ico-titular-gris.svg";
import TitularR from "../../assets/img/scotia-vida-ico-titular-rojo.svg";
import BeneficiarioG from "../../assets/img/scotia-vida-ico-beneficiario-gris.svg";
import Advertencia from "../../assets/img/icon/ico-close.svg";
import BeneficiarioR from "../../assets/img/scotia-vida-ico-beneficiario-rojo.svg";
import orangeClose from "../../assets/img/registro/alert-orange-close.svg";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import { encryptDecrypt,getNavegator,getUser } from "../../utils/validations";
import { urlAPI, captchaKey } from "../../utils/config";
import sha256 from "sha256";
import Axios from "axios";
import Jwt from "jsonwebtoken";
import  ArrowLeft from "../../assets/img/home-out/Arrow-Derecho.png";
import Background from '../../components/background/backgroung';
import $ from "jquery";

export default function Login(props) {
  const history = useHistory();
  const { pathname } = props.location;
  const { step } = props.match.params;
  const [form, setForm] = useState(defaultFormValue());
  const [messageObj, setMessageObj] = useState(defaultMessageValue());
  const [loaderShow, setLoaderShow] = useState(false);
  const [beneficiarioP,setBeneficiarioP] = useState(false);
  const [tituloP,setTituloP] = useState(false);
  const recaptchaRef = createRef();
  
  function defaultFormValue() {
    return {
      document: "",
      password: "",
      captcha: "",
    };
  }

  function defaultMessageValue() {
    return {
      show: "",
      message: "",
    };
  }
  const onChange = (e, name) => {
    //recaptchaRef.current.execute();
    setForm({ ...form, [name]: e.target.value });
  };
  useEffect(()=>{
    if(pathname ==='/inicia-sesion'){
      document.getElementById('document').setAttribute('style',"font-family: 'OpenSans' !important; color:#9B9B9B;");
      document.getElementById('password').setAttribute('style','border-right:0px !important; border-top-right-radius:0px !important;border-bottom-right-radius:0px !important;font-family: OpenSans; color:#9B9B9B !important;');
      // document.getElementById('basic-addon3').setAttribute('style','border-left:0px!important');
    }
  });
  let interval
  const handleSubmit = (e) => {
    onChangeCap(e);
    if (isEmpty(form.document) || isEmpty(form.password)) {
      setMessageObj({ show: true, message: "Debe completar los campos" });          
      document.getElementById('resetCaptchaL').click();
    } else if (isEmpty(form.captcha)) {
      return;
    } else {
      setLoaderShow(true);
      const saltPassword = btoa(
        encryptDecrypt(
          sha256(form.password),
          form.captcha.substring(form.captcha.length - 10, form.captcha.length)
        )
      );
      const user = form.document;
      let obj = {
        usuario: user,
        password: saltPassword,
        tokenCaptcha: form.captcha,
        ip: "",
      };
      Axios.post(`${urlAPI}/login/loginuser`, JSON.stringify(obj),
        {
          headers: { "Content-Type": "application/json" },
        }).then(success => {
          setLoaderShow(false)
            setMessageObj({ show: false, message: '' })
            if(success.data.hasOwnProperty("mensaje")){
              if(success.data.mensaje.includes('Actualizar Clave')){
                let user = {
                  id : success.data.mensajeExtra,
                  identificacion : form.document
                };
                localStorage.setItem("u_act", CryptoJS.AES.encrypt(JSON.stringify(user), "eco_scotia"));
                history.push("/actualizar-clave");
              }else{
                localStorage.setItem("token", CryptoJS.AES.encrypt(success.data.data, "eco_scotia"));
                let tokenJwt = Jwt.sign({ data: 'creacionToken' }, 'Db', { expiresIn: 360 });
                localStorage.setItem("jwt", tokenJwt);
                obtenerIni(success.data.data);
              }
            }else if(success.data.hasOwnProperty("StatusCode")){
              let message = success.data.Message
              if(message.includes("captcha")){
                recaptchaRef.current.execute()
              }
              else{
                setMessageObj({ show: true, message: message });
              }
            }
        }).catch(error => {
          setLoaderShow(false);
        });
    }
  };
  const pauseInterval=()=>{
    clearInterval(interval);
    props.history.push("/inicia-sesion");
  }
  const obtenerIni= (token) =>{
    Axios.get(`${urlAPI}/Login/obtenerSession`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }).then(success=>{
      localStorage.setItem(
        "user",
        CryptoJS.AES.encrypt(JSON.stringify(success.data), "eco_scotia")
      );
      var dispo = getNavegator();
      Axios.get('https://ipapi.co/json/?key=vgn91pD3PXJhodzX3llb7PGxJpyemvUhamyxnhm8fTSvNJ9J5P'
      ).then(succ=>{
      var encrip = btoa(encryptDecrypt(`${succ.data.ip}<${getUser().idUsuario}`,succ.data.ip));
      let obj = {
              ipUsuario:succ.data.ip,
              dispositivo:dispo,
              link:encrip
      }
      Axios.post(`${urlAPI}/Seguridad/EnvioCorreoDispositivo`,JSON.stringify(obj),{ headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
            }).then(succ=>{
              window.location.href = `/auth/inicio`;
            }).catch(error=>{
        
            });
          }).catch(error=>{
            //console.log(error);
        }
      );
    }).catch(error=>{
      //console.log(error);
    });
  }
  function onChangeCap(value) {
    form.captcha = value;
  }
  const informationView = [
    {
      id: 1,
      text: "Escribe tu número de documento sin puntos ni espacios.",
      step: "1 de 2",
      arrowLeft: "N",
      arrowRight: "S",
      left: '69%',
      top: '240px',
      topIcon: '60px',
      leftIcon: '95.9%',
      Mobleft: '21%',
      Mobtop: '365px',
      MobtopIcon: '-9px',
      MobleftIcon: '46%',
      width: '295px',
      height: '124px',
      Mobheight: '165px'
    }, {
      id: 2,
      text: <p>Ingresa la contraseña que creaste anteriormente y luego haz clic en el botón <strong>“INICIAR SESIÓN”</strong></p>,
      step: "2 de 2",
      arrowLeft: "S",
      arrowRight: "N",
      left: '9%',
      top: '330px',
      topIcon: '60px',
      leftIcon: '-3.3%',
      Mobleft: '21%',
      Mobtop: '450px',
      MobtopIcon: '-9px',
      MobleftIcon: '46%',
      width: '305px',
      height: '145px',
      Mobheight: '193px'
    }
  ]
  $('#document').on('input', function () {
    //console.log("ingreso");
  this.value = this.value.replace(/[^0-9]/g, '');
  });
  return (
    <>
      {loaderShow && <Loader show={loaderShow} />}
      {pathname === "/inicia-sesion" && (
        <div className="row justify-content-center divContent" style={{ background: "#f8f8f8", marginRight: "0px", marginLeft:"0px" }}>
          {/* <Background informationView={informationView} id={'login'} /> */}
          <div 
            className="col-11 col-md-6 mt-5"
            style={{ maxWidth: step==="multiusuario"? "490px":"490px" , marginLeft:"auto", marginRight:"auto" }}
          >
            <Card id="loginCard">
              <Card.Body>
                <Form
                  className="p-2"
                  onSubmit={(e)=>{e.preventDefault()}}
                >
                  <p className="text-center mb-3 tituloRegistro1" style={{color:"#323B4E",fontFamily:'Next', marginTop:'5px', fontSize:'22px'}}>
                    Inicia sesión
                  </p>
                  <div className="subtitleBlack mt-4 mb-4">
                    <p style={{textAlign:"center",fontFamily:'Inter-regular',fontWeight:'400',fontSize:"14px",lineHeight:"18px",color:"#58595B", marginBottom:"7%"}}>
                    ¡Bienvenida! Comienza a disfrutar los servicios de tu seguro
                    </p>
                  </div>

                  {messageObj.show && (
                    <div className="row subtitleBlack messageError" style={{background:'#FDEAAE' ,borderRadius: '4px'}}>
                      <div className="col-10 mt-2 warningCar">
                        <p style={{ color: '#FC6E0D'}}>{messageObj.message}</p>
                      </div>
                    </div>
                  )}
                  <div id="login1" style={{ padding: '0px' }} className="col-12 ">
                  <Input
                    label="Número de documento"
                    placeholder="Ingresa tu número de documento"
                    type="number"
                    error={false}
                    errorText="Ingresa tu número de documento"
                    id="document"
                    name="document"
                    onChangaInput={onChange}
                    className="placeholderTexts"
                    autocomplete="off"
                  />
                  </div>
                  <div id="login2" style={{ padding: '0px' }} className="col-12 ">
                  {/* <div className="loginInput"> */}
                  <Input
                    label="Contraseña"
                    placeholder="Ingresa tu contraseña"
                    type="password" 
                    error={false}
                    errorText="Ingresa tu contraseña"
                    id="password"
                    name="password"
                    onChangaInput={onChange}
                    autocomplete="off"
                  />
                  {/* </div> */}
                  </div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={captchaKey}
                    className="d-none"
                    onChange={(e)=>{handleSubmit(e)}}
                  />
                  <button id="resetCaptchaL" type="button" className="d-none" onClick={()=>{recaptchaRef.current.reset();}}> </button>
                  
                  <input
                    type="button"
                    className="mt-4 btnInicio block"
                    value="INICIAR SESIÓN"
                    onClick={() => {
                      recaptchaRef.current.execute();
                    }}
                  />
                   
                  <div className="text-center" style={{marginTop:'35px', marginBottom:'5px'}}>
                    <a
                      className="hover blackBrandColor"
                      href="/olvidaste-tu-contrasena"
                      style={{textDecoration:"underline",fontFamily:'Inter-regular', fontSize:'14px',color:'#58595B'}}
                    >
                      ¿Olvidaste tu contraseña?
                    </a>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-12 mt-3 mb-5">
            <p className="text-center textcuentLogin">
              ¿Aún no tienes una cuenta?{" "}
              <a className="hover underlineRed" href="/registro" style={{color:"#585857",fontFamily:'Inter-regular',fontWeight:'400',fontSize:'14px'}}>
                Regístrate aquí
              </a>
            </p>
          </div>
        </div>
      )}
      {pathname === "/inicio-sesion/control-block" && (
        <div className="row divContent" style={{ background: "#f8f8f8",}}>
          <div className="col-lg-5  col-md-6  col-sm-10  col-12 ml-auto mr-auto mt-5 mb-5" style={{maxWidth:'454px'}}>
            <Card id="loginCard">
              <Card.Body>
                <Form className="p-2">
                  <h4 className="text-center mb-3" style={{color:"#323B4E", fontFamily:'Next', marginTop:'10px'}}>
                    Inicia sesión 
                  </h4>
                  <div className="subtitleBlack mt-4 mb-4">
                    <p style={{textAlign:"center",fontSize:"14px", fontFamily:'Inter-regular'}}>
                    Bienvenida, disfruta los servicios digitales de tu seguro.
                    </p>
                  </div>

                  <div className="col-12 row warning-message warningMessageBlock subtitleBlack mr-1 ml-1" style={{background: '#FDEAAE'}}>
                    <div className="col-12 mt-2 row" style={{ marginLeft:'5px'}}>
                      {/* <div className="cerrarIconLogin">
                        {<img src={Advertencia}/>}
                      </div> */}
                      <p style={{color: "#FC6E0D", fontSize:"14px", fontFamily:"Inter-regular",fontSize: '13px', marginTop:'8px'}}>
                       Has bloqueado tu cuenta, superaste el número de intentos permitidos para acceder.
                      </p>
                      <p style={{color: "#FC6E0D", fontSize: "14px", fontFamily:"Inter-regular",fontSize: '13px'}}>
                         Si olvidaste tu contraseña, {" "}
                        <a
                          className="underlineOrange"
                          href="/olvidaste-tu-contrasena"
                          style={{color: "#FC6E0D"}}
                        >
                          Restablécela aquí
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="text-center"
                   style={{ marginTop:'35px', marginBottom:'15px'}}>
                    <a
                      className="hover underlineBlack"
                      style={{color:"#585857", fontFamily:'Inter-regular'}}
                      onClick={()=>{pauseInterval()}}
                    >
                      Regresar
                    </a>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      
    </>
  );
}
