import React from 'react'

export default function ErrorMessageComponent ({message, className}){
    return (
        <>
            <div className='row'>
                <div className='col-12 col-md-3 p-0'>

                </div>
                <div className={`col-12 col-md-9 p-0 pb-2 errorMessage ${className}`}>
                    <span className='errorMessageForm w-100'>{message}</span>
                </div>
            </div>
        </>
    )
}