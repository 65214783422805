import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import './css/ModalTermsVideoCall.css'

export default function ModalTermsVideoCallPhan() {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        dialogClassName='uno'
        style={{ padding: '10px' }}
      >
        <Modal.Header>
          <div style={{ height: "40px" }}>
            <button
              id="btnCierraModal"
              type="button"
              className="swal2-close-modal btnTerms ajusteXmodal"
              onClick={handleClose}
            ><p>×</p></button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="body-modal scrollbarstyle">
            <h4>Términos y condiciones</h4>        
            <p>
              Declaración Legal
            </p>            
            <p>
              Agradecemos leer con detenimiento los términos y condiciones (en adelante TyC) antes de utilizar el presente ecosistema ya que para utilizar la plataforma es necesaria su aceptación. Si usted no está de acuerdo con estos TyC, debe abstenerse de utilizar esta plataforma web.
            </p>            
            <p>  
              La plataforma en cualquier momento puede modificar los TyC, cumpliéndose sus efectos después de la publicación. El empleo continuado de la plataforma se entenderá como aceptación de dichas modificaciones.
            </p>            
            <p>  
              Además de aceptar los presentes TyC, el usuario, antes de utilizar los servicios, debe aceptar la política de privacidad y tratamiento de datos personales. Al emplear la plataforma, el usuario acepta que queda vinculado a los TyC y acepta la política de privacidad y tratamiento de datos personales.
            </p>            
            <p>  
              Es importante recordar que nuestros servicios no constituyen un asesoramiento legal y en ningún caso generan una relación abogado-cliente la información suministrada por este medio es de carácter general e informativo y no busca resolver situaciones específicas ni particulares, si necesita consultoría jurídica especializada para un problema específico, o si su consulta requiere de la elaboración de un concepto jurídico para conocer las distintas alternativas de solución, o de la representación legal  ante autoridades judiciales  o administrativas usted deberá contratar los servicios de un abogado.
            </p>            
            <p>  
              La plataforma tiene la facultad para enviar comunicaciones de servicios, de contenido administrativo o de cualquier tipo relacionada con el servicio que sea necesario para el desarrollo del servicio. Cualquier nueva funcionalidad que modifique, aumente o mejore el servicio actual, incluyendo el lanzamiento de nuevas propiedades, estará sujeta a estos tyc.
            </p>            
            <p>  
              “La plataforma” en ningún caso asumirá responsabilidades por información o comunicación del usuario. El usuario es totalmente responsable de la información que establezca en el uso de esta plataforma.
            </p>            
            <p>
              Estos servicios se entenderán como una opinión general de orden legal la cual no compromete a las partes intervinientes en la plataforma, por otro lado, en ningún caso los servicios están destinados a la atención de procesos judiciales de ninguna índole, elaboración de documentos o representación judicial o extrajudicial.  
            </p>            
            <p>  
              En ningún caso la plataforma se obliga a garantizar la funcionalidad o los efectos de los servicios ofrecidos. El usuario es responsable de revisar el contenido de los servicios prestados y determinar si cubren sus necesidades. Así mismo, no se garantizará la disponibilidad, acceso y continuidad del funcionamiento de los servicios.
              Cardif no se hace responsable de ninguna pérdida, lesión, reclamo, responsabilidad o daño relacionado con el uso de la información recibida en este ecosistema o de cualquier sitio vinculado a este, ya sea por errores u omisiones en el contenido. El uso que el cliente realice con la información recibida será de su exclusiva responsabilidad por su propia cuenta y riesgo.
            </p>
            
            <p className="mt-5">
              <b>  Modalidades de servicios: </b>
            </p>

            <p>
              La plataforma permitirá a los usuarios, según corresponda principalmente hacer uso de opiniones de tipo legal en cada uno de los ecosistemas según el servicio agendado.
              Estos servicios se entenderán como una opinión de orden legal la cual no compromete a las partes intervinientes en la plataforma, por otro lado, en ningún caso los servicios están destinados a la atención de procesos judiciales de ninguna índole, elaboración de documentos o representación judicial o extrajudicial.  
              En ningún caso la plataforma se obliga a garantizar la funcionalidad o los efectos de los servicios ofrecidos. El usuario es responsable de revisar el contenido de los servicios prestados y determinar si cubren sus necesidades. Así mismo, no se garantizará la disponibilidad, acceso y continuidad del funcionamiento de los servicios.
            </p>            
            <p>
              Al adquirir algún servicio, el usuario entiende y acepta qué:
            </p> 

              <ol type="a">
                <li> El horario y fecha para el servicio con el consultor o abogado, serán decididos con el usuario según los horarios disponibles</li>
                <li> La opinión legal del abogado o consultor del servicio en la plataforma se podrá realizar a través del link del servicio y se realizará a través del aplicativo (por lo que el usuario debe contar con conexión estable a internet).</li>
                <li> El abogado o consultor esperará en la llamada hasta quince (15) minutos al usuario, cumplido el término anterior sin que asista el usuario se entenderá por finalizada y cumplida la llamada.</li>
                <li> Una vez agotado el tiempo de la llamada, el sistema la terminará automáticamente.</li>
                <li> Toda la información que el usuario utilice dentro de la videollamada, está protegida por secreto profesional entre usuario y/o abogado o consultor y, por lo tanto, será tratada de manera confidencial.</li>
                <li> En la consulta solo se absolverán las inquietudes del usuario relacionadas con el servicio adquirido. </li>
                <li> Las preguntas o inquietudes del usuario se resolverán de forma verbal (no se entregará documento escrito).</li>
                <li> Ni la plataforma, ni los abogados o consultores que responden la consulta serán responsables de ninguna manera por los perjuicios relacionados o derivados de la información incompleta, inexacta o equívoca que sea proporcionada por el usuario.</li>
                <li> El abogado o consultor está en la libertad de no seguir adelante con la consulta del usuario y podrá dar por terminada la llamada web ya sea por utilización improperios, palabras denigrantes o el uso de calificativos en su contra. </li>
              </ol>   
            
            <p className="mt-2">
              <b>SI NO ESTÁ DE ACUERDO CON ESTOS TÉRMINOS Y CONDICIONES, NO UTILICE ESTE SITIO.</b>
            </p>  
          </div>
          <div className="text-center">
            <button
              style={{ width: "192px" }}
              type="button"
              onClick={handleClose}
              className="btnModal mb-3 mt-3"
            >
              ACEPTAR
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
