import React,{useState} from "react";
import Modal from "react-bootstrap/Modal";

export default function ModalAlerts() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dobleEnter = (
        <>
          <br />
          <br />
        </>
      );
    return (
        <>
        <span className="hover mr-1 text-left disclamersFooter col-10">
           <a className="seleccion">Autorizo la </a> <a onClick={handleShow} style={{fontFamily: "Opensans-regular",cursor:"pointer",color: "#2A2A2A",fontSize:"16px", boxShadow:'#2a2a2a 0px -1px inset'}}>
                consulta, reporte y procesamiento de datos financieros a centrales de riesgo
            </a>
        </span>
        
            <Modal
                show={show}
                onHide={handleClose}
                className="mt-5 text-center"
                aria-labelledby="contained-modal-title-vcenter"
                style={{paddingLeft: "30px", paddingRight: "30px", overflow:'hidden', top:'-45px'}}
                size="lg"
                centered
            >
                <Modal.Header>
                <div className="btnCierraModalPrincipal">
                  <button
                    id="btnCierraModal"
                    type="button"
                    className="swal2-close-modal"
                    style={{marginTop: "15px", outline:'none'}}
                    onClick={handleClose}
                  ><p>×</p></button>
                </div>
                </Modal.Header>
                <Modal.Body style={{ margin:'auto', width:'100%', boxShadow:'0px 4px 16px 0px #00000020'}}>
                    <h4 className="tittle-modal-t pt-4" style={{textAlign:'initial',paddingLeft:'80px', paddingRight:'75px', color: "#2A2A2A", fontSize: '24px', marginLeft: '-15px'}}>
                    Autorización para la consulta, reporte y procesamiento de datos financieros a centrales de riesgo:</h4>
                    <div className="overflow-scroll body-modal-t pt-1" style={{
                        fontFamily: "OpenSans-regular", fontSize: "14px", color: "#808285",
                        lineHeight: "1.429", height: "260px", textAlign:'left', marginLeft: '-40px'}}
                    > 
                        Autorizo de manera expresa e irrevocable a CARDIF COLOMBIA SEGUROS GENERALES
                        S.A. o a quien represente sus derechos, a consultar, solicitar, suministrar, reportar, procesar y divulgar toda la información que se refiere a mi comportamiento crediticio, financiero y comercial ante las Centrales de Riesgo que administra la Asociación Bancaria y de Entidades Financieras de Colombia  o a cualquier otro operador y/o fuente de
                        información legalmente establecido. Así mismo, autorizo, expresa e irrevocablemente a CARDIF COLOMBIA SEGUROS GENERALES S.A.  para que consulte toda la información financiera, crediticia, comercial, de servicios y la proveniente de otros países, atinente a mis relaciones comerciales que tenga con el Sistema Financiero, comercial y de servicios, 
                        o de cualquier sector, tanto en Colombia como en el Exterior, en cualquier tiempo. PARÁGRAFO: La presente autorización se extiende para que CARDIF COLOMBIA SEGUROS GENERALES S.A. pueda compartir información con terceros públicos o privados, bien sea que éstos ostenten la condición de fuentes de información, operadores de información o usuarios,
                        con quienes yo tenga vínculos jurídicos de cualquier naturaleza, todo conforme a lo establecido en las normas legales vigentes dentro del marco del Sistema de Administración de Riesgos de Lavado de Activos y Financiación al Terrorismo SARLAFT de CARDIF COLOMBIA SEGUROS GENERALES S.A.

{/* 
                        Autorizo de manera previa, expresa informada a SEGUROS ALFA S.A., identificada 
                        con NIT 860.031.979-8. y domicilio principal en Bogotá D.C., SEGUROS DE VIDA ALFA 
                        S.A. identificada con NIT 860.503.617-3. y domicilio principal en Bogotá D.C., y 
                        CARDIF COLOMBIA SEGUROS GENERALES S.A. identificada con NIT 900.200.435-3 y domicilio 
                        principal en Bogotá D.C. o a quien represente sus derechos, a consultar, solicitar, 
                        suministrar, reportar, procesar y divulgar toda la información que se refiere a mi 
                        comportamiento crediticio, financiero y comercial ante las Centrales de Riesgo que 
                        administra la Asociación Bancaria y de Entidades Financieras de Colombia  o a 
                        cualquier otro operador y/o fuente de información legalmente establecido y para 
                        que realice la recolección, almacenamiento, uso, divulgación, transferencia y 
                        transmisión, a nivel nacional e internacional, incluso a terceros ubicados en países 
                        que no proporcionen niveles adecuados de protección, a que procese mis datos personales, 
                        entendidos como cualquier información vinculada o que pueda asociarse a mí y que permita 
                        mi identificación (los “Datos Personales”), como lo son mi nombre, edad, género, dirección, 
                        teléfono, correo electrónico, historia laboral, y número de documento de identidad, todo 
                        lo anterior conforme a la Política de Tratamiento de la Información de Cardif Colombia 
                        Seguros Generales (la “Política”), disponible en www.bnpparibascardif.com.co enlace 
                        “Privacidad”.
                        {dobleEnter}
                        Estas actividades se realizarán con el propósito de promocionar y mercadear productos
                        y servicios nuevos y existentes, enviarle comunicaciones y/o notificaciones a través 
                        de cualquier medio, ofrecerle un mejor servicio, llevar a cabo encuestas de satisfacción 
                        y remitirle información sobre sus reclamaciones. 
                        {dobleEnter}
                        Así mismo, autorizo, de manera previa, expresa e informada a SEGUROS ALFA S.A, SEGUROS DE 
                        VIDA ALFA S.A. y CARDIF COLOMBIA SEGUROS GENERALES S.A.  para que consulte toda la 
                        información financiera, crediticia, comercial, de servicios y la proveniente de otros 
                        países, atinente a mis relaciones comerciales que tenga con el Sistema Financiero, comercial 
                        y de servicios, o de cualquier sector, tanto en Colombia como en el Exterior, en cualquier tiempo.  
                        {dobleEnter}
                        La presente autorización se extiende para que SEGUROS ALFA S.A, SEGUROS DE VIDA ALFA S.A. y CARDIF 
                        COLOMBIA SEGUROS GENERALES S.A. pueda compartir información con terceros públicos o privados, 
                        bien sea que éstos ostenten la condición de fuentes de información, operadores de información o 
                        usuarios, con quienes yo tenga vínculos jurídicos de cualquier naturaleza, todo conforme a 
                        lo establecido en las normas legales vigentes dentro del marco del Sistema de Administración de 
                        Riesgos de Lavado de Activos y Financiación al Terrorismo SARLAFT de SEGUROS ALFA S.A, SEGUROS DE 
                        VIDA ALFA S.A. y CARDIF COLOMBIA SEGUROS GENERALES S.A
                        {dobleEnter}
                        Declaro que he sido informado sobre mis derechos a presentar solicitudes de información, actualización,
                        supresión, revocatoria de autorización y/o rectificación de los Datos Personales, y que cualquier 
                        consulta, solicitud, reclamo, queja y petición relacionada podré dirigirla a servicioalcliente@segurosalfa.com.co o
                        atencionalcliente@cardif.com.co” 
                        {dobleEnter}
                        SEGUROS ALFA S.A., SEGUROS DE VIDA ALFA S.A.) y CARDIF COLOMBIA SEGUROS GENERALES S.A., están comprometidas con el 
                        tratamiento leal, lícito, confidencial y seguro de sus datos personales. Por favor consulte nuestra Política de 
                        Tratamiento de Información en: www.segurosalfa.com.co y www.bnpparibascardif.com.co en donde puede conocer sus 
                        derechos constitucionales y legales así como la forma de ejercerlos.
                        {dobleEnter}
                        *El puntaje mostrado en este servicio NO es una garantía que las entidades financieras vayan a otorgar un crédito, 
                        solamente corresponde al reporte suministrado por Datacrédito y no tiene relación alguna con el score que cada 
                        Banco hace a sus clientes con sus propios puntajes y lineamientos. */}
                    </div>
                    <div className="text-center mb-1 mt-1" style={{marginTop:"-53px"}}>
                        <button type="button" onClick={handleClose} className="btnModal pl-5 pr-5" style={{backgroundColor:"#FFD200", color:"#2A2A2A", fontWeight: 'bold'}}>
                            Aceptar
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}