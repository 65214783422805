import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CardEvent from '../../../../components/card-event/CardEvent';
import ContainerService from '../../../../components/container-service/ContainerService';
import ReactPaginate from 'react-paginate';
import { urlAPI } from '../../../../utils/config';
import { getToken } from '../../../../utils/validations';
import iconCommunity from "../../../../assets/img/itt/bancolombia-icon_networking.svg"
import "../css/networkingStyle.css";

function NetworkingCommunity() {
    const linkbread=[{
        to:"/auth/inicio",
        tittle:"Inicio > "
    },
    {
        to:"/ITT/auth/networking",
        tittle:"Networking > ",
    },
    {
        to:"/ITT/auth/networking/servicios",
        tittle:"Servicios > ",
    },
    {
        to:"/ITT/auth/networking/servicios/comunidad-emprendimientos",
        tittle:"Comunidad de emprendedores",
    }];
    function defaultListCommunity() {
        return {descripcionEmpresa: "",
        fechaHoraCreacion: null,
        idEmpresa: 42,
        idSector: null,
        idUsuario: null,
        mailEmpresarial: null,
        nombreEmpresa: "",
        paginaWeb: null,
        rutaImagen: "",
        telefonoEmpresarial: "",
        vigente: null}
    }
    const headers = { headers: {"Content-Type": "application/json",Authorization: "Bearer " + getToken()}};
    const [listCommunity,setListCommunity]=useState([defaultListCommunity]);
    const [listSector,setListSector] = useState([]);
    const [selects,setSelects] = useState({idSector:''});
    const [listCommunityFinal,setListCommunityFinal] = useState([]);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(9);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [Countco, setCountco] = useState(0)
    const [texti, setTexti] = useState();
    const [numPrev, setnumPrev] = useState(3);
    const [numNext, setnumNext] = useState(1);
    const [textPag, settextPag] = useState('...');
    const [sector, setSector] = useState(0);

    const history = useHistory();

    let list
    const getListCommunity=(id)=>{
        Axios.get(`${urlAPI}/itt/listaEmpresas`,headers)
        .then((res)=>{
            let {data,codigo}=res.data;
            //console.log(sector,id);
            if (id != 0) {
                list = data.filter(x=>x.vigente === true && x.idSector === parseInt(id));
                //console.log('perro',list);
            }else{
                list = data.filter(x=>x.vigente===true)
                //console.log('perro 1',list);
            }
            getListSector(data);
            //console.log(data);
            
            if(codigo === 0){
                setListCommunity(list);
                setListCommunityFinal(list);
            }
            
            let slice = list.slice(Countco, Countco + perPage)
            const postData = slice.map((element, i)=>{
                return(
                    <React.Fragment>
                        <div key={i} className="col-sm-4 pt-4 d-flex respon-card">
                            <CardEvent  infoCard={element} className="p-2" showButton={false} bsPrefix="eventNetworking">
                                <div className="textCardCommunity">
                                    <span style={{color:'#292929',fontFamily:'cibSans-Bold'}}>{element.nombreEmpresa}</span><br/>
                                    <p className="spanCommunity mt-3 mb-4">{element.descripcionEmpresa}</p>
                                </div>
                                <div className="textCardCommunity" style={{borderTop:'1px solid #BABABA'}}>
                                    <span className="subTittleCommunity" style={{color:'#292929',fontSize:'16px'}}>Datos de contacto</span><br/>
                                    <span className="subTittleCommunity" style={{fontFamily:'Opensans',fontWeight:"400"}}>Teléfonos:</span><br/>
                                    <span className="spanCommunity">{element.telefonoEmpresarial}</span><br/>
                                    <span className="subTittleCommunity" style={{fontFamily:'Opensans',fontWeight:"400"}}>Correo electrónico:</span>
                                    <span className="spanCommunity">{element.mailEmpresarial}</span><br/>
                                    <span className="subTittleCommunity" style={{fontFamily:'Opensans',fontWeight:"400"}}>Página web:</span>
                                    <a href={element.paginaWeb} target="_blank" className="spanCommunity" style={{color:"#2A83EB"}}>{element.paginaWeb}</a>
                                </div>
                            </CardEvent>
                        </div>
                    </React.Fragment>
                )
            })
            //console.log(postData.length);
            setData(postData);
            setPageCount(Math.ceil(list.length / perPage));
            if (list.length <= 9 && list.length >= 1) {
                document.getElementById('contentpage1').style.display='none'
            }else if (list.length > 9){
                document.getElementById('contentpage1').style.display='block'
            }
        }).finally();
    }
    const getListSector = (dataE)=>{
        Axios.get(`${urlAPI}/Hoja/tipos/3`,headers)
        .then(({data})=>{
            let listCountry = [];
            dataE.forEach((e)=>{
                listCountry.push(data.data.find((element)=>element.id == e.idSector));
            });
            //console.log(listCountry);
            setListSector(Array.from(new Set(listCountry)));
        }).finally();
    }
    const handleSelects =(e)=>{
        let otro = listSector.filter(x=>x.id == e.target.value)
        setTexti(otro.map(data=>data.text));
        //console.log(otro);
    }
    useEffect(()=>{
        if(window.innerWidth <=700){
            setnumPrev(2)
            setnumNext(0)
            settextPag('..')
        }
    },[])
    useEffect(()=>{
        // if(selects.idSector !== ''){
        //     setListCommunityFinal(listCommunity.filter(x=>x.idSector === parseInt(selects?.idSector)));
        // }else{
        //     setListCommunityFinal(listCommunity);
        // }
        getListCommunity(sector);
    },[offset, selects]);
    const handlePageClick = (e) => {
        setCountco(e.selected * 6)
        const selectedPage = e.selected;
        setOffset(selectedPage + 1)
    };
    return(
        <>
            <ContainerService tittle="Networking" styleTittle={{fontSize:"34px",paddingRight:"10%"}} linkBread={linkbread}>
                <Container>
                <Row bsPrefix="row" className="resposive">
                    <Col sm={12} className="redC-tittle">
                        <h3 className="tittleView">Construye tu red de contactos</h3>
                    </Col>
                    <Col sm={12}>
                        <p style={{paddingTop:'2rem',paddingBottom:'1rem'}}>
                            Amplía tus redes de contactos, conéctate con empresas, emprendedores y freelance (servicios de terceros) y materializa fácilmente tus ideas y necesidades de negocio. 
                        </p>
                    </Col>
                    <Col sm={12}>
                        <h4 className="tittleView" style={{paddingBottom:'1.5rem',}}>Comunidad de emprededores</h4>
                        <div className="lineSeparator"/>
                    </Col>
                </Row>
                <Row bsPrefix="row pl-4 pr-4 prl4">
                    <Col sm={12} lg={5} className="resp-select sector-resTT" style={{marginTop: "-3%"}}>
                        <label style={{color: "#808285", position:"relative", top:"18px", fontSize:"14px"}}>Sector</label>
                        <select className="resele form-control iputControlHv styleSelect iconoArrow" name="idSector" onChange={(e)=>{setSector(e.target.value);handleSelects(e);getListCommunity(e.target.value);}}>
                            <option value="">Selecciona el sector</option>
                            {listSector.map((e,i)=>{
                                return(
                                  <option key={i} value={e.id}>{e.text}</option>
                                );
                            })}
                        </select>
                    </Col>
                    {listCommunityFinal.Length !==0 && (
                        <>
                            <Col sm={12} lg={7} className="question" style={{marginTop: "-3%"}}>
                                <span style={{color: "#58595B"}} className="spanParteA">¿Aún no estás en nuestra comunidad?</span><button className="btnGroupCommunity btnParteA"  onClick={()=>{history.push("/ITT/auth/branding-digital/agenda")}}>Haz parte aquí</button>
                            </Col>
                            <Col sm={12} style={{marginTop: "3%"}}>
                                <h3 className="tittleView subTitle sector-resTT">Sector {texti}</h3>
                            </Col>
                        </>
                    )}
                    <Col sm={12} className={data.length >=1 && data.length <=9?'pb-5':''}>
                            <Row>
                                {
                                data.length !==0 ? (
                                    <div style={{display:"contents"}}>
                                         {data}
                                         <div id="contentpage1" style={{width:'100%',position:'relative',marginLeft: '-15px',height:'120px'}}>
                                                <ReactPaginate
                                                    previousLabel=" Anterior"
                                                    nextLabel={"Siguiente "}
                                                    breakLabel={textPag}
                                                    breakClassName={"break-me"}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={numNext}
                                                    pageRangeDisplayed={numPrev}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination net"}
                                                    subContainerClassName={"pages pagination"}
                                                    pageLinkClassName={'pocoyo'}
                                                    activeClassName={"active"}
                                                    initialPage={0}
                                                    forcePage={0}
                                                />
                                            </div>
                                    </div> 
                                ):(
                                    <Col sm={12} className="text-center" style={{paddingBottom: "11%", paddingTop: "7%"}}>
                                        <img src={iconCommunity} alt="icon emprendimientos"/>
                                        <p className="textNullCommunity mt-5">
                                            <span style={{color:"#292929", fontFamily:"OpenSans-bold"}}>Aún no hay información publicada.</span>
                                            <br/>
                                            <br/>
                                            <span style={{fontFamily:"OpenSans",color:"#5E5E5E"}}>En esta sección podrás conocer nuevas ideas de negocio y de ésta manera ampliar tu red de contactos.</span>
                                        </p>
                                        <button className="btnMainBlack mt-3 Cuen-empre">CUÉNTANOS DE TU EMPRENDIMIENTO</button>
                                    </Col>
                                )} 
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </ContainerService>
        </>
        );
}

export default NetworkingCommunity;