import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import CheckGreen from "../../assets/img/ic-check-green.svg";

export default function ModalSaveBene(props) {
  const { showM, clickClose, typeTitle } = props;
  const [show, setShow] = useState(showM);
  const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  // const dobleEnter = (
  //     <>
  //       <br />
  //       <br />
  //     </>
  // );
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        className="contendeorModalBeneficiario"
      >
        {/* <Modal.Header>
          <div >
            <button
              id="btnCierraModal"
              className="swal2-close"
              onClick={handleClose}
              style={{ marginTop: "34px" }}
            >×</button>
          </div>
        </Modal.Header> */}
        <Modal.Body style={{width: "102%", borderRadius: "8px !important"}}>
          <div className="container">
            <div className="row">
              <div className="pr-4 pl-4 pb-4 pt-2" style={{paddingTop:".5rem !important"}}>
                <div className="text-center mb-2">
                  <h4 className="mt-2" style={{ fontSize: "22px", fontFamily: "Inter-bold", color: "#323B4E",maxWidth: "100%" }}>
                    { typeTitle === "profile"?('Beneficiario ingresado exitosamente'):('Autorización completada con éxito')}
                  </h4>
                  <img style={{ margin: "30px 0 20px 0" }} src={CheckGreen} alt="success" />
                  { typeTitle =="AutoAdult"?(
                  <p className="mt-2" style={{maxWidth:"96%",textAlign:"justify", fontFamily:"Inter-regular" ,color:"#808285", fontSize:"14px"}}>
                    Todos los beneficiarios pre inscritos estarán sujetos a un proceso de verificación, 
                    de máximo 2 días hábiles, una vez se reciba la autorización de tratamiento de datos.
                  </p>):(
                  <p className="mt-2" style={{maxWidth:"100%", fontFamily:"Inter-regular" ,color:"#808285", fontSize:"14px"}}>
                    Procederemos a realizar la verificación de datos de tus hijos menores de edad. 
                    En un plazo máximo de dos días hábiles, recibirás la confirmación o notificación del registro.
                  </p>
                  )}
                  <button className="btnMain" style={{ borderRadius: "4px", width: "50.5%", fontSize:"17px", fontWeight:"700",backgroundColor: '#323B4E'}} onClick={clickClose}>
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}