import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link,useHistory } from "react-router-dom";
import iconTrash from '../../assets/img/seguridad-informatica/icon-cancelar.svg';
import iconEdit from '../../assets/img/seguridad-informatica/icon-editar.svg';
import iconCalendar from '../../assets/img/seguridad-informatica/calendar.svg';
import iconClock from '../../assets/img/seguridad-informatica/icon-clock.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { onNavigate, irInstafit, irTributi, addLog, addLogList } from "../../utils/validations";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import ciudad from "../../assets/img/icon/standard/ciudad.svg";
import Tooltips from "../tooltips/Tooltips";
import Axios from "axios";
import Swal from 'sweetalert2';
import ModalTermsVideoCall from "../../components/modal/ModalTermsVideoCall";
import { urlAPI } from "../../utils/config";
import dayjs from "dayjs";
import "dayjs/locale/es";
import useCustomDate from "../../hooks/useCustomDate";
import nextIcon from "../../assets/img/home-in/Flecha.svg"
import iconPlus from '../../assets/img/home-in/plus-border.webp';

export default function CardService(props) {
    const { children, content, view, shedulingInfo, edit, deleteS, contactSelect, typeSheduling, serviceInfo, shedulingCW, ecosystem } = props;
    const tooltipMedico = <p className="mt-2" style={{ fontFamily: "Inter-regular", fontSize: "12px", color: "#FFFFFF" }}>Dispones de 6 consultas médicas durante la vigencia del seguro.</p>;
    const [horaag, sethoraag] = useState('')
    const history = useHistory();
    const { convertFormatTime12H } = useCustomDate();
    const [errorAceptaTerminos, setErrorAceptaTerminos] = useState('');
    const [checkTer, setCheckTer] = useState('');
    const [btnTele, setBtnTele] = useState(false);
    const [telemedicina_Services, setTelemedicina_Services] = useState(false)
	
	const onChangeTerms = (e) => {
        setCheckTer(e.target.checked);
    };
    

    useEffect(() => {
        if (shedulingInfo) {
            sethoraag(convertFormatTime12H(shedulingInfo.hour)); 
        }
    }, [])

    function ValidacionVideollamada(){
        setErrorAceptaTerminos("");
        if(!checkTer){
            setErrorAceptaTerminos("Debe aceptar los términos y condiciones");
            return;
        }
        let totalMinutes = 0
        let HourFloat = parseFloat(shedulingInfo.hour.split(":")[0]);
        let MinFloat = parseFloat(shedulingInfo.hour.split(":")[1]);
        if(shedulingInfo.hour.includes("PM") && HourFloat != 12)
            HourFloat += 12;
        
        totalMinutes = (HourFloat*60) + MinFloat;
        //console.log("TOTAL Minutos",totalMinutes)
        let fecha1 = dayjs(shedulingInfo.date).add(parseFloat(totalMinutes),'minute');
        let fecha2 = dayjs();
        
        if(fecha1.diff(fecha2, 'minutes') > 5){
            Swal.fire({
                title: "Videollamada no disponible",
                text: "El acceso se habilitará 5 minutos antes de la hora del agendamiento en la fecha asignada.",
                confirmButtonText: "Aceptar",
                customClass: {
                    confirmButton: 'classconfirmar'
                }
            });
        }else if(fecha1.diff(fecha2, 'minutes') <= -60){
            Swal.fire({
                title: "Videollamada no disponible",
                text: "La videollamada caducó, debe realizar un nuevo agendamiento.",
                confirmButtonText: "Aceptar",
                customClass: {
                    confirmButton: 'classconfirmar'
                },
            });
        }else{
            history.push({
                pathname: '/videollamada',
                state: shedulingInfo.link 
            });
        }
    }

    function encodeBase64v1(data){
        return Buffer.from(data).toString('base64');
    }
      
    const [disableButton,setDisableButton] = useState(false)
      
      
    const validarServicio = (servicios,acccion,link="",title="", ecosystem="") =>{
        servicios = servicios === "courserabie" ? "coursera" : servicios;
        const logUserList = []
        logUserList.push(addLog('Validación Servicio', ecosystem + " / " + servicios + " / Card", 'Home In', 'Exitoso'))
        addLogList(logUserList)

        if(link != ""){
            history.push(link);
        }else{              
            if(acccion.includes('onNavigate') && servicios.includes("coursera")){
                var codigoCousera = acccion.split('|');
                onNavigate(null,codigoCousera[1])
            }else if(acccion.includes('irInstafit') || servicios.includes("instafit")){
                irInstafit(null)
            }else if(acccion.includes('irTributi')){
                irTributi(null);
            }/*else if(acccion.includes('irEducacionFinanciera')){
                irEducacionFinanciera(null);
            }*/
        }
    }

    return (
        <React.Fragment>
            {view === "1" && (
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mt-4 d-flex marginCard" >
                    <div className="card border cardHeight" style={{ borderRadius: "8px" }}>
                        <img className="card-img-top" src={"/assets/img/home-in/"+content?.image} alt={"Imagen " + content?.title} />
                        <div className="card-body" style={{ padding: "1.25rem 1.25rem 0px 1.25rem" }}>
                            <div className="card-title mb-4">
                                <div className="row">
                                    <div className="col-10" style={{ paddingLeft: "0px" }}>
                                        <h5 style={{ fontFamily: "Next", fontSize: "20px", color: "#414141", width: "114%" }}>
                                            {content.title}
                                        </h5>
                                    </div>
                                    {content.servicename === "telemedicina" ?(
                                        <div className="col-1 text-right">
                                            <Tooltips keyTool={1} idBtn="btnIcoCardService" bsPrefix="tooltipCardService" className="text-center">{tooltipMedico} </Tooltips>
                                        </div>
                                    ):(<></>)}
                                    {/* {content.servicename === "Coursera - 1 año"|| content.servicename === "Coursera - 3 año"?(
                                    <div className="col-1 text-right">
                                    <Tooltips  keyTool={1} idBtn="btnIcoCardService" bsPrefix="tooltipCardService" className="text-center">{content.servicename === "Coursera - 1 año"? tooltipCoursera1: tooltipCoursera3}</Tooltips>
                                    </div>
                                ):(<></>)} */}
                                    {/* {content.title === "Estudia con Coursera"?(
                                    <div className="col-1 text-right">
                                    <Tooltips  keyTool={1} idBtn="btnIcoCardService" bsPrefix="tooltipCardService" className="text-center">{tooltipCoursera3}</Tooltips>
                                    </div>
                                ):(<></>)} */}
                                </div>
                            </div>
                            <p className="card-text" style={{ fontFamily: "Helvetica", fontSize: "15px", color: "#58595B", width: "102%", paddingRight: "11px" }}>{content.description}</p>
                            {
                                (content.servicename === "telemedicina" ) && (
                                    <div style={{ zIndex: '100', background: 'rgb(0, 0, 0)', position: 'absolute', top: window.innerWidth < 768 ? '55%' : '63%', left: '0%', width: '100%', borderRadius: '6px', display: !telemedicina_Services && 'none' }}>
                                        <ul style={{ listStyle: 'none', margin: '20px auto', paddingLeft: '10px' }}>
                                            <li><Link className='color_texto_Card link__tele' style={{ listStyle: 'none', cursor: 'pointer', color: '#fff' }} onClick={(e) => { validarServicio(content.servicename, content.method, content.link, content.title, ecosystem) }} data={'abc'}>{content.servicename === "telemedicina" ? 'Telemedicina con médico general' : 'Agenda tu consulta'}{" "}<img src={nextIcon} alt="flecha" /> </Link></li>
                                            {content.servicename === "telemedicina" && (
                                            <li><Link className='color_texto_Card link__tele' to={content.servicename === "telemedicina" ? '/telemedicina-historial' : '/psicologia-historial'} style={{listStyle: 'none',cursor:'pointer',color:'#fff'}}>{content.servicename === "telemedicina" ? 'Mi historial de Telemedicina' : 'Historial de Psicología'} {" "}<img src={nextIcon} alt="flecha"/></Link></li>
                                            )}
                                        </ul>
                                    </div>
                                )
                            }
                        </div>
                        {
                            (content.servicename === "telemedicina" ) && (
                                <div className="card-footer maResbtn" style={{ background: "#fff", borderTop: "#fff", borderRadius: "8px", marginBottom: "20px", marginTop: "15px" }}>
                                    <div className="row"
                                        onClick={() => {
                                            setBtnTele(!btnTele)
                                            setTelemedicina_Services(!telemedicina_Services)
                                        }
                                        }
                                    >
                                        <div className="col-10">
                                            <div
                                                style={{ textAlign: 'left', cursor: 'pointer' }}
                                                className={`buttonServices_tele btn__tele ${btnTele && 'btn__tele-active'}`}

                                            >
                                                Da clic a continuación para escoger tu tipo de consulta
                                            </div>
                                        </div>
                                        <img src={iconPlus} width={30} height={30} className="iconHealth" style={{ cursor: 'pointer' }} alt="Telemedicina servicios"
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {(content.servicename !== "telemedicina" )  && (
                        <div className="card-footer" style={{ background: "#fff", borderTop: "#fff", borderRadius: "8px", marginBottom: "20px", marginTop: "15px" }}>
                            {content.link === "" ? (
                            <button className="btnCard" 
                            onClick={(e)=>{validarServicio(content.servicename,content.method,"",content.title, "Vida")}}
                            >Comienza aquí</button>) : (
                            <button className="btnCard"
                            onClick={(e)=>{validarServicio(content.servicename,null,content.link,content.title, "Vida")}}
                            >
                                
                                    Comienza aquí
                                    {/* </Link> */}
                                </button>)}
                        </div>
                           )}
                    </div>
                </div>
            )}
            {view === "2" && (
                <div className="col-lg-12 col-md-10 col-sm-10 mt-4">
                    <div className="card mb-3 shadow" style={{ borderRadius: "6px" }}>
                        <div className="row no-gutters">
                            <div className="col-md-4"  >
                                <img src={content.image} alt="alt-card" style={{ height: "fit-content", width: "100%" }} />
                            </div>
                            <div className="col-md-8" style={{ padding: "11px 19.5px 14px 13.5px" }}>
                                <div className="card-body cardM" style={{ padding: "0px",borderRadius: '0px' }}>
                                    <h5 className="card-title" style={{ fontFamily: "Next", paddingTop: '2px', color: "#414141", }} >
                                        {content.title}
                                    </h5>
                                    <p className="card-text mt-4" style={{ fontFamily: "OpenSans", fontSize: "15px", color: "#58595B" }}>
                                        {content.description}
                                    </p>
                                    {content.link === "" ? (
                                    <button className="btnCard" 
                                    disabled={disableButton}
                            onClick={(e)=>{validarServicio(content.servicename,content.method,"",content.title, ecosystem)}}
                                    >Comienza aquí</button>) : (
                                    <button 
                                    style={{ fontFamily: "OpenSans", fontSize: "16px", color: '#0040A8', fontWeight: 'bold' }} 
                                    disabled={disableButton}
                            onClick={(e)=>{validarServicio(content.servicename,null,content.link,content.title, ecosystem)}}
                                    >Comienza aquí</button>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {view === "sheduling" && (
                <React.Fragment>
                    
                    <div  style={{ marginTop: "26px", marginBottom: "3.5rem" }}>
                        {typeSheduling === "2045" ?
                            <span className="textParagraph" style={{ color: "#57595B" }}>La siguiente es la información de tu solicitud agendada:</span>
                            :
                            <span className="textParagraph" style={{ color: "#57595B" }}>La siguiente es la información de tu solicitud agendada:</span>
                        }
                    </div>
                    <div >
                        <Card style={{ border: "none" }}>
                            <Card.Body className="cardSheduling">
                                <ul className="listSheduling">
                                    {typeSheduling === "2036" && (<li><span className="spanCalendar" style={{ marginBottom: '7px' }}><img src={ciudad} alt="Icono Ciudad" style={{ marginBottom: "3px", marginRight: "14px", marginLeft: "4px" }} />{shedulingCW.text}</span></li>)}
                                    <li><span className="spanCalendar" style={{ marginBottom: '7px' }}><img src={iconCalendar} alt="icono Calendario" style={{ marginRight: "14px", marginLeft: "4px" }} /><span style={{ textTransform: "capitalize" }}>{shedulingInfo.date.toLocaleDateString('es-CO', { month: 'long' })}</span> {shedulingInfo.date.toLocaleDateString('es-CO', { day: '2-digit' })} de {shedulingInfo.date.toLocaleDateString('es-CO', { year: 'numeric' })}</span></li>
                                    <li><span className="spanCalendar" style={{ marginBottom: '7px' }}><img src={iconClock} alt="icono Reloj" style={{ marginBottom: "3px", marginRight: "14px", marginLeft: "4px" }} />{horaag}</span></li>
                                    {(typeSheduling !== "Telemedicina" && typeSheduling !== "2036") || (typeSheduling !== "Orientacion") && (<li><span className="spanCalendar" style={{ marginBottom: '7px' }}><FontAwesomeIcon icon={contactSelect?.faIcon} style={{ fontSize: "20px", marginLeft: "4px", marginRight: "11px", marginBottom: "-2px", color: "#868B96" }} />{shedulingInfo?.infoContact.split('@', 1)}</span></li>)}
                                </ul>
                            </Card.Body>
                            <Card.Footer className="cardButtonSheduling">
                                <Row >
                                    <Col className="col-12 col-md-3 col-xl-2 marginBtSheduleCancel">
                                        <button className="buttonCancel" onClick={() => { deleteS() }}>
                                            Cancelar
                                        </button>
                                    </Col>
                                    <Col className="col-12 col-md-3 col-xl-2 marginBtSheduleEdit">
                                        <button className="buttonEdit" onClick={() => { edit() }}>                                    
                                            Editar
                                        </button>
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </div>
                </React.Fragment>
            )}
            {view === "service" && (
                <div className="card card-widget widget-user rounded-0" style={{ maxWidth: "378px" }}>
                    <div className="widget-user-header text-white" style={{ height: "auto" }}>
                        <img className="card-img-top" src={serviceInfo.imageUrl} alt="CardImage" />
                    </div>
                    <div className="card-body" style={{ padding: '1.25rem', minHeight: '9rem' }}>
                        <div style={{ minHeight: "50px", }}>
                            <h3 className="mb-3" style={{ fontFamily: "cibSans-bold", color: "#2A2A2A", fontSize: serviceInfo.tittle === "Eventos de networking" || serviceInfo.tittle === "Comunidad de emprendedores" ? "20px" : "" }}>
                                {serviceInfo.tittle}
                            </h3>
                        </div>
                        <p style={{ fontSize: "16px", color: "#58595B" }}>
                            {serviceInfo.subTittle}
                        </p>
                    </div>
                    <div className="text-center mb-5 ">
                        <Link to={serviceInfo.linkButton} >
                            <button className="btnMainBlack resp-agendar" style={{ fontFamily: 'OpenSans-bold', margin: ' 0px !important', width: '80%', marginBottom: '30px !important', fontWeight: "400", borderRadius: '25px', fontSize: serviceInfo.textButton === "Conocer eventos" || serviceInfo.textButton === "Conocer la comunidad" ? "20px" : "" }}>
                                {serviceInfo.textButton}
                            </button>
                        </Link>
                    </div>
                </div>
            )}
            {view === "networking" && (
                <React.Fragment>
                    {shedulingInfo?.isShowButtons && (<div className="col-12" style={{ marginTop: "26px", marginBottom: "20px" }}>
                        <span className="textParagraph">La siguiente es la información de tu solicitud:</span>
                    </div>)}
                    <div className="col-12">
                        <Card style={{ border: "none" }}>
                            <Card.Body className="cardSheduling" style={{ border: '1px solid #DBE4EF', background: '#F6F8FA' }}>
                                <ul className="listSheduling">
                                    <li>
                                        <span className="spanCalendar" style={{ marginBottom: '7px' }}>
                                            <div style={{ float: 'left' }}>
                                                <img src={iconCalendar} alt="icono Calendario" style={{ marginRight: "14px", marginLeft: "4px" }} />
                                            </div>
                                            <div style={{ display: 'inline-block' }}>
                                                {shedulingInfo.text}
                                            </div>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="spanCalendar" style={{ marginBottom: '7px' }}>
                                            <div style={{ float: 'left' }}>
                                                <img src={ciudad} alt="Icono Ciudad" style={{ marginBottom: "3px", marginRight: "14px", marginLeft: "4px" }} />
                                            </div>
                                            <div >
                                                {shedulingInfo.place}
                                            </div>
                                        </span>
                                    </li>
                                </ul>
                                {children}
                            </Card.Body>
                            {shedulingInfo?.isShowButtons && (<Card.Footer className="cardButtonSheduling" style={{ border: '1px solid #DBE4EF', background: '#F6F8FA' }}>
                                <button className="buttonCancel" onClick={() => { deleteS() }}><img src={iconTrash} alt="iconBasura" style={{ marginBottom: "4px", marginRight: "8px" }} />Cancelar</button>
                            </Card.Footer>)}
                        </Card>
                    </div>
                </React.Fragment>
            )}
            {view==="shedulingVideollamada"&&(
                <React.Fragment>
                    <div>
                        <h3 className="textParagraph col-12" >La siguiente es la información de tu solicitud:</h3>
                    </div>
                    <div className="col-12 mt-4">
                        <Card style={{border:"none"}}>
                        <Card.Body className="cardSheduling" style={{padding:"0.8rem"}}>
                        <ul className="listSheduling px-0 px-md-2">
                            {typeSheduling === "2036" &&(<li><span className="spanCalendar" style={{marginBottom:'7px'}}><img src={ciudad} alt="Icono Ciudad" style={{marginBottom:"3px",marginRight: "14px",marginLeft: "4px"}}/>{shedulingCW.text}</span></li>)}
                            <li><span className="spanCalendar" style={{marginBottom:'7px'}}><img src={iconCalendar} alt="icono Calendario" style={{marginRight: "14px",marginLeft: "4px"}}/><span style={{textTransform:"capitalize"}}>{shedulingInfo.date.toLocaleDateString('es-CO',{month: 'long'})}</span> {shedulingInfo.date.toLocaleDateString('es-CO',{day: '2-digit'})} de {shedulingInfo.date.toLocaleDateString('es-CO',{year:'numeric'})}</span></li>
                            <li><span className="spanCalendar" style={{marginBottom:'7px'}}><img src={iconClock} alt="icono Reloj" style={{marginBottom:"3px",marginRight: "14px",marginLeft: "4px"}}/>{horaag}</span></li>
                            {typeSheduling !== "Telemedicina"&& typeSheduling !== "2036" &&(<li><span className="spanCalendar" style={{marginBottom:'7px'}}><FontAwesomeIcon icon={contactSelect?.faIcon} style={{fontSize:"20px",marginLeft: "4px",marginRight: "11px",marginBottom: "-2px",color:"#868B96"}}/>{shedulingInfo?.infoContact}</span></li>)}
                            <div className="d-flex align-items-start mt-4">
                                <div className="px-1">
                                    <input
                                    className="checkbox-terminos"
                                    type="checkbox"
                                    name="chkTC"
                                    onChange={(e) => onChangeTerms(e)}
                                    style={{width:"20px",height:"20px",borderRadius:"8px"}}
                                    />
                                </div>
                                <div className="pl-2 text-left" style={{ color: '#777777', fontSize: '14px', fontWeight: '400' }}>
                                    <span className='tipoLetra'> He leí­do y acepto los</span> <ModalTermsVideoCall title="términos y condiciones" /> <span className='tipoLetra'> de uso de la aplicación. </span> 
                                </div>                    
                            </div>
                            {(errorAceptaTerminos !== '') && (                                
                                <span className="errorMessageForm my-3">{errorAceptaTerminos}</span>	                            
                            )}
                            {typeSheduling !== "2036" &&(<li><button className="btnShedulingVerde mt-4" type="button" onClick={()=>{ValidacionVideollamada()}}>Videollamada</button></li>)}
                        </ul> 
                        
                        </Card.Body>
                        <Card.Footer className="cardButtonSheduling">
                            <button className="buttonCancel" onClick={()=>{deleteS()}}>Cancelar</button>
                            <button className="buttonEdit" onClick={()=>{edit()}}>Editar</button>
                        </Card.Footer>
                        </Card>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
