import React, {useState, useEffect} from "react";
import ContainerService from "../../../components/container-service/ContainerService";
import Sheduling from "../../../components/sheduling/Sheduling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {  useHistory } from "react-router-dom";
import { getSchedules } from '../../../utils/validations';

export default function ShedulingFraud(props){
    const [step , setStep ] = useState(1);
    const linkbread=[{
            to:"/auth/inicio",
            tittle:"Inicio > "
        },
        {
            to:"/auth/inicio",
            tittle:"Consultoría legal > ",
        },
        {
            to:"/auth/seguridad-informatica/agenda",
            tittle:"Consultoría"
    }];

    const infoSheduling = {
        typeSheduling:'2044',
        classTitleSheduling: 'fechahoraTitulo',
        classVolver: 'btnBackGrey',
        typeTextSheduling:<h5 className="typeShedulingText">Consultoria en <br/> seguridad informática</h5>,
        module:'Seguridad',
        tittleSheduling:'',
        paragraph:'Para prestarte un servicio adecuado por favor confirma los siguientes datos:.​',
        alertCancelTitle:'Cancelación de agenda',
        alertCancelText:'¿Estás seguro de cancelar tu consultoría?',
        tooltipsClass:'tooltipShedulingEco',
        messageSuccess:{
          module:'Seguridad',
          variant:'success',
          checkText:'Confirmación de consultoría éxitosa',
          alertText:'Recibirás una notificación via email y SMS confirmando tu cita',
          class:'alert alertSuccessEcosistemas',
        },
        messageCancel:{
          variant:'success',
          checkText:'Tu consultoría ha sido cancelada con éxito',
          alertText:'Si deseas programar una nueva asesoria en seguridad informática, haz clic a continuación.',
          class:'alert alertEcoWarning',
          textButton: "AGENDAR NUEVA ASESORÍA"
        }
    };
    const history = useHistory();
    useEffect(()=>{
        if(window.location.pathname === "/fraude/auth/seguridad-informatica"){
            setStep(1);
        }
    },[history.location.pathname]);

    useEffect(()=>{
        let sheduling;
        let res = new Promise((resolve)=>{
            sheduling= getSchedules([{value:"2043"}]);
            resolve(sheduling);
        }).then((res)=>{
            //console.log(res);
            setStep(res?2:1);
        });
     },[]);

    return(
        <ContainerService  tittle="Seguridad informática" styleTittle={{fontSize:"34px"}} linkBread={linkbread}>
               { step === 1 &&(
               <div id="menu">
                    <div style={{paddingLeft:'10px'}}>
                        <h3 className="mt-4 titleSecurity">Protege tu información en internet</h3>
                        <p className="pSecurity" style={{paddingTop:"5%"}}>Confirma el día, canal y hora en la cual quieres ser contactado.</p>
                        <p className="pSecurity" style={{paddingBottom:"4%"}}>Recuerda que la consultoría solo se podrá prestar en temas relacionados con seguridad informática.</p>
                    </div>
                    <div className="row justify-content-center">
                        <button className="btn btnShedulingVerde btnCyberSe mb-5" style={{boxShadow:'0px 0px 2px #00000010',marginRight:"50px"}} onClick={()=>{setStep(2)}}>
                            AGENDAR REVISIÓN
                        </button>
                    </div>
                </div>
               )}
               {step === 2 &&(
                   <Sheduling key={1} type="2044" module="Seguridad" propsP={props} infoSheduling={infoSheduling}/>
               )}
        </ContainerService>            
    );
};