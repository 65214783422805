export const urlAPI = process.env.REACT_APP_URLAPI;
export const url = process.env.REACT_APP_URL;
export const captchaKey = "6LdbnDEaAAAAAMlFHpXAXcQhKiMII6cxCfhtGMd_";
export const urlInstafit =
  "https://dev.instafit.com//user/week_plans?session_token=";
export const urlDevInstafit = "https://dev.instafit.com/api";

//Expresiones regulares
export const regexValidacion = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
export const regexValidacionPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&¿/#,;])([A-Za-z\d$@$!%*?&¿/#,;]|[^ ]){8,16}$/;
export const regexPasswordStrength = /([a-z].*[A-Z])|([A-Z].*[a-z])/;
export const regexValidacionMail = /^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/;

/*Config Videollamada*/
export const colorPrincipal = "#323B4E";
export const sponsor = "Leonisa";
export const urlVideoLlamada = process.env.REACT_APP_VIDEOCALL_URL;
export const urlAPIVideoLlamada = process.env.REACT_APP_VIDEOCALL_URLAPI;
export const idExternoSala = "";
export const TokenUser = "6938940cd7eff6b02c92cd10503bf96f";
export const tokenVideoLlamada = "VlcxMFYxVXhXblJWYm5CcVRURndObFpxU2xkTk1YQjBWMVJLVDFaR1ZURlViRkpUWlZad2RGZHRNVkpTTURSNVYydGtZV1Z0VW5KV2ExcFdUVEpTTkZreGFFWlBWa0pWV1hwT1VGSkhaQT09";
/*FIN Config Videollamada*/