import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import "./ShedulingTelemedicine.css";
import SchedulingTelemedicineSteps from "../../components/sheduling/SchedulingTelemedicineSteps";



const ShedulingTelemedicine =()=>{
    const [historial] = useState(window.location.pathname.includes('historial')?true:false)
    

    return( 
        <React.Fragment>
            <Container className="container__telemedicine">
                <Row className="w-100 h-100 m-0 p-0 justify-content-center">
                    <SchedulingTelemedicineSteps  esHistorial={historial}/>
                </Row>
            </Container>
        </React.Fragment>
    );
};
export default ShedulingTelemedicine;