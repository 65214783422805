import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";


export default function ModalEmailAdult(props) {
    const {showM,clickSave,onChange,value,clickClose,close} = props;
    const[disabled, setDisabled] = useState(true);
    const [background, setBackgraund] = useState("#CFCFCF");
    const [border, setBorder] = useState ("#CFCFCF");
    const [color, setColor] = useState ("#ffffff");
    const [resul, setResult] = useState (false);
    const [show, setShow] = useState(showM);
    const [display, setDisplay ] = useState(true);

    function validarCorreo(e){
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setResult(re.test(e));
    }

    function defaultFormValue(){
        return {
            correoElectronico:"",       
        };
       }
    
    const dobleEnter = (
        <>
          <br />
          <br />
        </>
      );

      const validar = e => {
        if(value.correoElectronico.length ===0){
          validarCorreo(e.target.value);
          setDisabled(true);
          setBackgraund("#CFCFCF");
          setBorder("#CFCFCF");
          setColor("#FFFFFF");
        }
        else{
           validarCorreo(e.target.value);
          if(resul===false){
            setDisabled(false);
            setBackgraund("#CF141B");
            setBorder("#CF141B");
            setColor("#FFFFFF");
           }else{
             setDisabled(false);
             setBackgraund("#323B4E");
             setBorder("#323B4E");
             setColor("#fff");
           }
        }
      };
    
      const enviarDatos =() =>{
        setDisplay(false);
        clickSave();
        return;
      }


    return(
        <Modal
            show={show}
            onHide={close}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
            centered
      >
          <Modal.Header>
              <div >
                <button
                id="btnCierraModal"
                className="swal2-close-modal"
                onClick={close}
                style={{marginTop: "19px",marginRight: "73px"}}
                ><p>×</p></button>
            </div>
          </Modal.Header>
          <Modal.Body style={{width:"93%"}}>
            <div className="row"> 
            <div className="col-12" style={{padding: "29px 41px 36px"}}>
                <h4 className="mt-2" style={{color:"#323B4E",fontFamily:"Inter-Bold"}}>Autorización beneficiarios</h4>
                <p className="mt-5 styleLyrics-email" style={{color:"#808285", textAlign:"justify"}}>
                Estimado cliente, le informamos que para que sus beneficiarios puedan hacer efectivos los 
                servicios del ecosistema, es necesario que cada uno de ellos autorice el tratamiento de sus 
                datos personales ingresando al siguiente link: Link a la política de tratamiento de datos.
                {dobleEnter}
                Es necesario que tenga en cuenta que una vez nos sea otorgada la autorización, se realizarán las validaciones pertinentes y una vez sea habilitado, se procederá con la activación de los servicios. Para tal efecto, comparta el link de autorización vía correo electrónico, ingresando el correo del beneficiario.
                </p>
                <p style={{fontSize:"14px", color:"#363636", fontFamily:"helvetica",marginBottom:'5px'}}>Correo electrónico</p>
                <div className="row">
                    <div className="col-lg-8 col-md-10 col-sm-12" style={{marginLeft:"-14px"}}>
                        <input type="text" className="form-control" placeholder="Ingrese el correo electrónico" 
                        style={{width:"100%", height:"42px",
                        marginBottom:"10px",}}
                        value={value.correoElectronico}
                        name="correoElectronico"
                        onChange={(e)=>{onChange(e)}}
                        onKeyUp={(e) => {validar(e)}}
                        />
                    </div>
                    <div className="col-lg-4 col-md-10 col-sm-12">
                        <button className="btnAzul" id="enviar" 
                        style={{
                            borderRadius:"4px",
                            width:"100%", 
                            margin:"0px",
                            display: display === true? "block" :"none",
                            backgroundColor: background, 
                            borderColor: border,
                            color:color
                        }}  
                        disabled={disabled}
                        onClick={()=>{enviarDatos()}}
                        >
                            Enviar
                        </button>
                        <button className="btnAzul" id="enviado"
                        style={{
                            bordeRadius: "4px",
                            width:"100%", 
                            margin:"0px", 
                            display: display === false? "block" :"none",
                            background: "#70A134",
                            borderColor: "#70A134"
                        }}  
                        >
                            Enviado
                        </button>
                    </div>
                </div>
                <div className="col-12 text-center mt-4">
                    <button className="btnAzul" style={{borderRadius:"5px", width: "202px",backgroundColor: '#323B4E'}} onClick={clickClose} >Continuar</button>
                </div>
            </div>
            </div>
        </Modal.Body>
      </Modal>
    );
}
