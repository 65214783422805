import React, { Fragment, useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { urlAPI } from "../../utils/config";
import logos from "../../assets/img/header/logos-header.png";
import logosMobile from "../../assets/img/header/logos-header-mobile.png"; // ../../assets/img/footer/leonisa-mobile.png
import iconRegister from "../../assets/img/header/icon-register.svg"
import iconCerrarMobile from "../../assets/img/header/cerrar-mobile.svg";
import { Link, useHistory } from "react-router-dom";
import { faBars, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isActive, closeSession, getToken, getUser, onNavigate, irInstafit, irTributi, addLog, addLogList } from "../../utils/validations";
import { isMobile } from "react-device-detect";
import Axios from "axios";
import { stubTrue } from "lodash";
import iconArrowS from "../../assets/img/ic-arrow-header.svg";


export default function Header() {
  const history = useHistory();
  const [toggle, setToggle] = useState(false);
  const [isLogged] = useState(isActive());
  const [userSession, setUserSession] = useState({});
  const [count, setCount] = useState(0);
  const [show, setShow] = useState("block");
  const [showVida, setShowVida] = useState("none");
  const [showDesempleo, setShowDesempleo] = useState("none");
  const [showITT, setShowITT] = useState("none");
  const [showFraude, setShowFraude] = useState("none");
  const [refresh, setRefresh] = useState(isActive() && (getUser().perfiles));
  const [infoCards, setInfoCards] = useState([])  
  const styleButton = {
    border: 'none',
    background: 'transparent',
    paddingLeft: '0',
  }

  const coberturas = () => {
    let servicios = getUser().perfiles
    setShowDesempleo(servicios.includes("Desempleo") ? 'block' : 'none');
    setShowITT(servicios.includes("Itt") ? 'block' : 'none');
    setShowFraude(servicios.includes("Fraude") ? 'block' : 'none');
    setShowVida(servicios.includes("vida") ? 'block' : 'none');
  }
  const loadServices = () =>{
    setInfoCards([]);
    let productos = getUser().perfiles;
    let arrProd = [];
    productos.forEach((e) => {
        var obj = JSON.parse(e);
        arrProd.push(obj);        
    });
    setInfoCards(arrProd);   
  }
  const onNavigateN = () => {
    let _id = getUser().idUsuario
    Axios.get(`${urlAPI}/CyberInsurance/ObtenerLink?id=${_id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      }
    }).then(succ => {
      let _url = succ.data.data;
      window.open(_url, "_blank");
    }).catch(error => {
      //console.log(error);
    });
  }
  function showItems(e) {
    if (e === "vida") {
      setShowVida(showVida === "block" ? 'none' : 'block');
      setShowDesempleo("none");
      setShowITT("none");
      setShowFraude("none");

    } else if (e === "Desempleo") {
      setShowVida("none");
      setShowDesempleo(showDesempleo === "block" ? 'none' : 'block');
      setShowITT("none");
      setShowFraude("none");
    } else if (e === "Itt") {
      setShowVida("none");
      setShowDesempleo("none");
      setShowITT(showITT === "block" ? 'none' : 'block');
      setShowFraude("none");
    } else if (e === "Fraude") {
      setShowVida("none");
      setShowDesempleo("none");
      setShowITT("none");
      setShowFraude(showFraude === "block" ? 'none' : 'block');
    }
  }
  useEffect(() => {
    if (isActive()) {
      Axios.get(`${urlAPI}/Profile/InfoGeneral`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        }
      }).then(({ data }) => {        
        localStorage.setItem("ccin", data.data.identificacion);
        const { nombres } = data.data;
        const [nombre] = nombres.split(" ");
        setUserSession(nombre);
      }).catch(error => {
        //console.log(error);
      });
    }
    if (isMobile && isActive()) {
      setShow("none");
      document.getElementById("navegadorMobile").classList.add("logoMobileposition")
    }
    if (isMobile && isActive()) {
      setShow("none");
      document.getElementById("menuUsuariomobile").classList.add("menuUsuariotammobile")
    }
    if ((window.location.href.includes("inicia-sesion") || window.location.href.includes("registro/") || window.location.href.includes("olvidaste-tu-contrasena")) && window.screen.width > 769) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [count]);
  function controlNav() {
    if (!isLogged) {
      setToggle(false);
    }
  }

  function encodeBase64v1(data){
    return Buffer.from(data).toString('base64');
  }
  
  const [disableButton,setDisableButton] = useState(false)
  
  
  const validarServicio = (servicios,acccion,link="",title="", ecosystem="") =>{
    servicios = servicios === "courserabie" ? "coursera" : servicios;
    const logUserList = []
    logUserList.push(addLog('Validación Servicio', ecosystem + " / " + servicios + " / Header", 'Home In', 'Exitoso'))
    addLogList(logUserList)

    if(link != ""){
        history.push(link);
    }else{              
        if(acccion.includes('onNavigate') && servicios.includes("coursera")){
            var codigoCousera = acccion.split('|');
            onNavigate(null,codigoCousera[1])
        }else if(acccion.includes('irInstafit') || servicios.includes("instafit")){
            irInstafit(null)
        }else if(acccion.includes('irTributi')){
            irTributi(null);
        }/*else if(acccion.includes('irEducacionFinanciera')){
            irEducacionFinanciera(null);
        }*/
    }
}

  const SetLogTab = (type) => {
    const logUserList = []
    if (type === "Ayuda") logUserList.push(addLog('Validación Servicio', 'Ayuda / Header', 'Home In', 'Exitoso'))
    if (type === "Blog") logUserList.push(addLog('Validación Servicio', 'Blog / Header', 'Home In', 'Exitoso'))
    if (type === "Perfil") logUserList.push(addLog('Validación Servicio', 'Perfil / Header', 'Home In', 'Exitoso'))
    if (type === "Beneficiarios") logUserList.push(addLog('Validación Servicio', 'Beneficiarios / Header', 'Home In', 'Exitoso'))
    addLogList(logUserList)
  }

  const [iconMobile, setIconMobile] = useState(true);
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      sticky="top"
      className="pt-2 pb-2 navMobileheight headerStyle" style={{ boxShadow: "0px 3px 4px 0px rgb(0 0 0 / 14%)" }}
    >
      <Navbar.Brand >
        <Link onClick={controlNav} to={isLogged ? `/auth/inicio` : "/"}>
          <div className="logoContainer">
            <a className="navbar-brand logoBanco d-none d-lg-block">
              <img src={logos} className="logoHeader" alt="Leonisa" style={{ zIndex: "1" }} />
            </a>
            <a id="navegadorMobile" className="navbar-brand logoBanco d-block d-lg-none">
              <img src={logosMobile} className="logoHeader logoMobileheight" alt="Leonisa" style={{ zIndex: "1", marginLeft: isLogged ? "20px" : 0 }} />
            </a>
          </div>
        </Link>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" id="menuUsuariomobile" className={(window.location.pathname.includes('autorizacion-menores') || (window.location.pathname.includes('autorizacion-menores') && window.screen.width > 800) || window.screen.width > 800) ? 'd-none' : 'd-block menuUsuario'} style={{ marginRight: "20px" }}>
        {isLogged ? (
          <div>
            <FontAwesomeIcon icon={faBars} className={iconMobile ? "d-block" : "d-none"} onClick={() => setIconMobile(false)} style={{ transform: 'scaleY(-0.8)', color: "#222" }} />
            <img src={iconCerrarMobile} alt="cerrar" className={!iconMobile ? "d-block" : "d-none"} onClick={() => setIconMobile(true)} style={{ marginTop: '-15px' }} />
          </div>
        ) : (
          <div>
            <FontAwesomeIcon icon={faBars} className={iconMobile ? "d-block" : "d-none"} onClick={() => setIconMobile(false)} style={{ transform: 'scaleY(-0.8)', color: "#222" }} />
            <img src={iconCerrarMobile} alt="cerrar" className={!iconMobile ? "d-block" : "d-none"} onClick={() => setIconMobile(stubTrue)} style={{ marginTop: '-15px' }} />
          </div>
        )}
      </Navbar.Toggle>

      <Navbar.Collapse id="responsive-navbar-nav"
        className="menuResponsive"
        style={{ marginTop: '-8px', zIndex: "-10", backgroundColor: "white", width: "100%", boxShadow: isLogged ? "0px 4px 4px 0px rgb(0 0 0 / 14%)" : null }}>
        <Nav className="mr-auto"></Nav>
        <Nav className={window.location.pathname === '/autorizacion-menores' ? 'd-none' : ''} style={{ lineHeight: '40px' }}>
          {isLogged && window.screen.width > 800 && (
            <Link
              eventKey={2}
              to="/auth/inicio"
              className="text-center"
              style={{ display: "block", paddingTop: '11px', textDecoration: "none" }}>
              <div>
                <div>
                  <span className="mb-1 text-center spanHeader">
                    Inicio
                  </span>
                </div>
              </div>
            </Link>
          )}
          {isLogged && window.screen.width > 800 && (
            <NavDropdown
              title="Nuestros servicios"
              id="collasible-nav-dropdown"
              style={{ width: "182px", textAlign: "center", color: "#2a2a2a", paddingTop: "3.5px", paddingBottom: "13px", fontFamily: "OpenSans" }}
              className="mr-3 rightMobile navDivContainer " 
              onClick={()=> loadServices()}
            >
              <div style={{ display: "grid", width: "260px", height: 'auto' }} id="accordion">
              {infoCards.map((e, i) => {
                    return (
                      <Fragment>
                        {e.service.map((service) => {
                          return (
                            <NavDropdown.Item className="direccionamiento ourServices"  >
                                  {service.link === "" ? (
                                    <Link 
                                      onClick={(e) => { validarServicio(service.servicename, service.method, "", service.title, e.ecosystemname) }}
                                      className="direccionamiento">
                                    {service.title}
                                    </Link>
                                  ) : ( 
                                      <Link 
                                        onClick={(e) => { validarServicio(service.servicename, "", service.link, service.title, e.ecosystemname) }}
                                        className="direccionamiento">
                                      {service.title}
                                      </Link>
                                  )}
                            </NavDropdown.Item>
                                )
                          })} 
                      </Fragment>
                    )
                  })
              }
              </div>
          
            </NavDropdown>
          )}
          {isLogged && window.screen.width > 800 && (
            <Nav.Link
              eventKey={2}
              className="text-center"
              style={{ display: "block", paddingTop: '11px' }}>
              <Link to="/auth/ayuda/lineas-atencion" className="mb-1 text-center spanHeader" style={{ color: '#323B4E' }} onClick={() => { SetLogTab("Ayuda"); }}>
                Ayuda
              </Link>
            </Nav.Link>
          )}

          {isLogged && (
            <>
              {isMobile && window.screen.width < 800 ? (
                <table className="d-block ml-3">
                  <tbody>
                    <tr>
                      <span className="mb-1" style={{ color: "#726f70", marginTop: "47px" }}></span>
                      <Link eventKey={2} to="/auth/inicio" className="text-center" style={{ display: "block", paddingTop: '10px' }}>
                        <div>
                          <div className="alignStart">
                            <span className="mb-1 text-center spanHeader">
                              Inicio
                            </span>
                          </div>
                        </div>
                      </Link>
                    </tr>
                    <tr>
                      <NavDropdown
                        title="Nuestros servicios"
                        id="collasible-nav-dropdown"
                        style={{ width: "160px", color: '#2a2a2a', textAlign: "left", fontFamily: "OpenSans", float: "left", marginLeft: "39px" }}
                        className="mr-3 mobileBoxCard" onClick={() => { setRefresh(getUser().perfiles); coberturas(); loadServices() }}
                      >
                        <div id="divServices">
                      
                        {infoCards.map((e, i) => {
                          return (
                            <Fragment>
                              {e.service.map((service) => {
                                return (
                                  <NavDropdown.Item className="direccionamiento ourServices"  >
                                    {service.link === "" ? (
                                      <Link 
                                        onClick={(e) => { validarServicio(service.servicename, service.method, "", service.title, e.ecosystemname) }}
                                        className="direccionamiento">
                                      {service.title}
                                      </Link>
                                    ) : ( 
                                        <Link 
                                          onClick={(e) => { validarServicio(service.servicename, "", service.link, service.title, e.ecosystemname) }}
                                          className="direccionamiento">
                                        {service.title}
                                        </Link>
                                    )}
                                  </NavDropdown.Item>
                                  )
                                })} 
                            </Fragment>
                            )
                          })
                        }
                        </div>                        
                      </NavDropdown>
                    </tr>
                    <tr>
                      <Nav.Link eventKey={2} className="text-start alignStart" style={{ display: "block", paddingTop: '10px' }}>
                        <Link to="/auth/ayuda/lineas-atencion" className="mb-1 text-center spanHeader" onClick={() => {SetLogTab("Ayuda"); }}>
                          Ayuda
                        </Link>
                      </Nav.Link>
                    </tr>
                    <tr>
                      <NavDropdown
                        title={`Hola, ${userSession}`}
                        id="collasible-nav-dropdown"
                        className="mr-3 icon-user mobileIcon"
                        style={{ marginLeft: "39px", float: "left", color: '#2a2a2a', fontFamily: "Next" }}
                      >

                        <NavDropdown.Item
                          className="mt-2"
                          eventKey={2} onClick={() => setIconMobile(true)}
                        >
                          <Link
                            className="link"
                            onClick={() => { SetLogTab("Perfil"); }}
                            to={isLogged ? `/auth/configuracion-perfil/informacion-personal` : `/auth/inicio`}>
                            Mi perfil
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={(e) => closeSession()}
                          className="mt-2 mb-2"
                          style={{ cursor: 'pointer' }}
                        >
                          Cerrar sesión
                        </NavDropdown.Item>
                      </NavDropdown>

                    </tr>
                  </tbody>
                </table>
              ) : (
                <table>
                  <tbody>
                    <tr>
                      <td style={{ paddingTop: '12px' }}>

                      </td>
                      <span className="mb-1" style={{ color: "#726f70" }}>
                        <NavDropdown
                          title={`Hola, ${userSession}`}
                          id="collasible-nav-dropdown"
                          className="mr-3 icon-user mobileIcon"
                          style={{ marginLeft: "20px", float: "left", color: '#726F70', fontFamily: "Next", paddingTop: '3px' }}
                        >
                          <div id="user-menu" style={{ width: '12rem'}}>
                            <NavDropdown.Item className="mt-2" eventKey={2} onClick={() => setIconMobile(true)} >
                              <Link
                                className="link"
                                onClick={() => { SetLogTab("Perfil"); }}
                                to={isLogged ? `/auth/configuracion-perfil/informacion-personal` : `/auth/inicio`}>
                                Mi perfil
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              onClick={(e) => closeSession()}
                              className="mt-2 mb-2"
                              style={{ cursor: 'pointer' }}
                            >
                              Cerrar sesión
                            </NavDropdown.Item>

                          </div>
                        </NavDropdown>
                      </span>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              )}
            </>
          )}
          {!toggle && !isLogged && (
            <>
              <Nav.Link
                eventKey={2}
                href="/"
                className="text-center mr-3"
                style={{ display: "block" }}
              >
                <div>
                  <div className="alignStart">
                    <span className="mb-1 text-center spanHeader">
                      Inicio
                    </span>
                  </div>
                </div>
              </Nav.Link>
              <Nav.Link
                eventKey={2}
                href="/preguntas-frecuentes/resultado-#Dudas-sobre-el-registro"
                className="text-center mr-3"
                style={{ display: "block" }}
              >
                <div>
                  <div className="alignStart">
                    <span className="mb-1 text-center spanHeader">
                      Preguntas frecuentes
                    </span>
                  </div>
                </div>
              </Nav.Link>
              <Nav.Link
                eventKey={2}
                href="/inicia-sesion"
                className="text-center mr-3"
                style={{ display: "block" }}
              >
                <div>
                  <div className="sesionMobile alignStart">
                    <span className="mb-1 text-center spanHeader">
                      Iniciar sesión
                    </span>
                  </div>
                </div>
              </Nav.Link>
              <Nav.Link

                className="btn  px-3 btn-register btnRegisterMobile"
                eventKey={2}
                href="/registro"
                style={{
                  background: "#222221",
                  border: "none",
                  color: "#fff",
                  minWidth: "179px",
                  width: "185px",
                  height: "45px",
                  paddingTop: "10px",
                  margin: "auto",
                  boxShadow: "0px 0px 0px 0px rgba(0,123,132,0)",
                  fontFamily: 'Next'
                }}
              >
                <img src={require('../../assets/img/header/icon-register.svg')} className="mr-2" />
                Regístrate {""}
              </Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
      <Link id="goOut" to='/' onClick={() => { !isLogged && (window.reload()) }} className="d-none" />
    </Navbar >
  );
}
