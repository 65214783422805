import React, { useEffect, useState } from "react";
import Salud from "../../assets/img/home-out/entrenador-digital.svg"
import SaludBdB from "../../assets/img/home-out/meidco-aun-clic.svg"
import Orientacion from "../../assets/img/home-in/orientacion/orientacion-psicologica.svg"
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";

function Cards(props) {
    const { content, index } = props;
    return (
        <Card className="col-lg-6 col-md-6 col-sm-6 col-10 mr-4 shadowServices" id="cardHomeout" style={{ paddingTop: `${content.paddingTop}px`, marginTop: '60px' }}>
            <Card.Body className="row p-0 m-0">
                <div className="col-12 p-0 text-center" style={{ height: '110px' }}>
                    <img src={content.imageContent} style={{ height: '100px' }} />
                </div>
                <div className='col-12' id="textCardService" style={{ padding: '15px 5px', marginLeft: '5px' }}>
                    <p style={{ fontFamily: "Next", fontSize: "20px", color: '#242424', }}>
                        {content.tittleContent}
                    </p>
                    <p style={{ fontSize: "16px", color: "#474747", width: "95%", fontFamily: "Helvetica", }}>
                        {content.textContent}
                    </p>
                </div>
            </Card.Body>
            <Card.Footer style={{ backgroundColor: "transparent", borderTop: "1px solid transparent" }}>
                <button className="btnCard" id="btnCard12k" style={{ fontFamily: "OpenSans", bottom: "30px", border: 'none' }}>
                    <Link to="/registro" style={{ fontFamily: "Next" }}>
                        Comienza aquí
                    </Link>
                </button>
            </Card.Footer>
        </Card>
    )
}

export default function HomeServices() {
    const copy = [

        {
            id: 1,
            imageContent: Salud,
            tittleContent: 'Entrenador Digital',
            textContent: 'Ejercítate cuando y donde quieras, con rutinas personalizadas hechas por los mejores instructores.',
            paddingTop: 30,
            position: 'absolute',
        },
        {
            id: 2,
            imageContent: SaludBdB,
            tittleContent: 'Médico a un clic',
            textContent: 'Tu salud no debe esperar, por eso, podrás acceder a consultas médicas desde tu celular o computador.',
            paddingTop: 30,
            position: 'absolute',
        },
        {
            id: 3,
            imageContent: Orientacion,
            tittleContent: 'Orientación psicológica a un clic',
            textContent: 'Accede a orientaciones psicológicas desde tu celular o computador.',
            paddingTop: 30,
            position: 'absolute',
        }
    ]
    const [propsRecorrido, setPropsRecorrido] = useState(copy);
    const recorrido=(value)=>{
        if(value === "before"){
            let aux=propsRecorrido[2];
            propsRecorrido.pop();
            propsRecorrido.unshift(aux);
            setPropsRecorrido([...propsRecorrido]);
        }
        else if(value === "after"){
            let aux=propsRecorrido[0];
            propsRecorrido.shift();
            propsRecorrido.push(aux);
            setPropsRecorrido([...propsRecorrido]);
        }
        
    }
    const recorridoMobile = (value) => {
        let aux = [];
        let aux2 = [];
        copy.map((e, i) => {
            if (i === value) {
                aux = copy[value];
            }
            else {
                aux2.push(e);
            }
        })
        aux2.unshift(aux);
        setPropsRecorrido(aux2)
    }

    return (
        <div className="container imFondoHome" style={{ marginTop: '-1px', minHeight: '710px' }}>
            <div className="container-fluid card servicesContent marginCardSer" id="fdoLeo" style={{ paddingRight: '0px', height: '100%',border: 'none' }}>
                <div className="row" style={{ width: '100%' }}>
                    <div className="col-lg-5 col-md-12 col-sm-12 col-12 ptText" >
                        <h1 className="mb-5 mobileSizeTittle mobileAjuste" style={{ fontFamily: 'Next', color: '#FFFFFF' }}>
                            Encuentra servicios <br />personalizados
                        </h1>
                        <p style={{ color: '#FFFFFF'}} className=" servicesTextMobile">
                            En nuestra plataforma encontrarás opciones 
                            para cuidar de tu salud en cualquier momento de tu vida.
                            <br />
                            Los servicios se habilitarán según las coberturas de tu seguro.
                        </p>
                        <div className="btnRecorrido d-lg-block d-md-none d-sm-none d-none" style={{paddingTop: '56px'}}>
                            <button onClick={() => recorrido('before')} className="mr-4 beforeServices">
                            </button>
                            <button onClick={() => recorrido('after')} className="afterServices">
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-sm-12 col-12 ptTarg pr-0" style={{ top: '-30px' }}>
                        <div id="contentScroll" className="cambios cardServicesHome  col-lg-12 col-md-12 col-sm-12 col-12 " style={{ display: 'inline-flex' }}>
                            {propsRecorrido.map((event, index) => {
                                return (<Cards content={event} key={index} index={index} />);
                            })}
                        </div>
                    </div>
                    <div className="d-lg-none d-md-block d-sm-block d-block col-12 text-center" style={{ height: '50px', color: '#068468' }}>
                        <button onClick={() => recorridoMobile(0)} className="recorridoCircle"></button>
                        <button onClick={() => recorridoMobile(1)} className="recorridoCircle"></button>
                        <button onClick={() => recorridoMobile(2)} className="recorridoCircle"></button>
                    </div>
                </div>

            </div>
        </div>
    );
}
