import React, { useState, useRef, useEffect} from 'react'
import './RegisterView.css'
import { Button, Row, Col, InputGroup } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import { ContenedorComponent } from '../../components/ContenedorComponent/ContenedorComponent'
import { InputComponents } from '../../components/InputComponents/InputComponents'
import { useForm } from '../../hooks/useForm'
import { useRegister } from '../../hooks/useRegister'
import ModalTerms from '../../components/modal/ModalTerms'
import ModalPolicies from "../../components/modal/ModalPolicies";
import ReCAPTCHA from 'react-google-recaptcha'
import { captchaKey, urlAPI } from '../../utils/config'
import Loader from '../../components/loader/Loader'
import { defaultFormRegister } from '../../interfaces/interfacesGenerales'
import ErrorMessageComponent from '../../components/ErrorMessage/ErrorMessageComponent';
import { useDocumentType } from '../../hooks/useDocumentType';

const terCond = (
    <a href style={{ color: "#005BAB", textDecoration: "underline", fontFamily: 'Scotia-regular', fontWeight: '400', fontSize: '14px' }}>
      términos y condiciones
    </a>
);
const polServ = (
    <a href style={{ color: "#005BAB", textDecoration: "underline", fontFamily: 'Scotia-regular', fontWeight: '400', fontSize: '14px' }}>
      política de privacidad del servicio
    </a>
);

export default function RegisterView() {
    const { onChange, onChangeEmail, 
         documento,onChangePhone,
         nombre, telefono, 
         correo, clave, 
         onChangeTerminos,
         onChangePoliticas, documentType, 
         validateFieldsRegister,
         validateFormRegister,
         errorPhone,
        errorEmail,
        errorTerminos,
        errorPoliticas,
        errorPassword,
        setErrorPassword,
    } = useForm(defaultFormRegister)
    const { stateInput, preRegisterUser, loaderShow, registerUser } = useRegister();
    const [validatePhone, setValidatePhone] = useState(false)
    const [validateEmail, setValidateEmail] = useState(false)
    const recaptchaRef = useRef();
    const recaptchaRegister = useRef();
    const btnCreateRef = useRef();
    const [ documentTypes, setDocumentTypes ] = useState();
    const {documents} = useDocumentType({documentTypes})
    const refPassword = useRef()

    useEffect(()=>{
        if(stateInput && btnCreateRef.current != undefined)validateFormRegister(2, documento)
       }, [stateInput])
    
    if(defaultFormRegister.telefono != '' && !validatePhone) {
        onChangePhone(defaultFormRegister.telefono, true)
        setValidatePhone(true)
    }
    if(defaultFormRegister.correo != '' && !validateEmail && (validatePhone || defaultFormRegister.telefono == '')) {
        onChangeEmail(defaultFormRegister.correo, true)
        setValidateEmail(true)
    }

    useEffect(()=>{
        if (errorPassword != undefined && errorPassword && refPassword.current != undefined){
            var validatePop = refPassword.current.hasAttribute('aria-describedby');
            if (!validatePop) refPassword.current.click()
            setErrorPassword(false)
        }
       }, [errorPassword])

    useEffect(() => {
        if(!documentTypes && documents.length > 0){
            setDocumentTypes(documents)
        }
    }, [documents])

  return (
    <Row className='p-2 bgView justify-content-center m-0 pt-5 pb-5'>
        {loaderShow && (<Loader show={loaderShow} />)}
            <ContenedorComponent>
                <div className='row'>
                    <div className='col-12 col-lg-8 contForm'>
                        <p className="text-center mb-2 font-weight-bold tituloRegistro1">
                            Regístrate para acceder a tu Ecosistema
                        </p>
                        <div className="subtitleBlack mt-2 mb-4 text-center">
                            <p>
                                Bienvenido, disfruta los servicios digitales de tu seguro.
                            </p>
                        </div>
                        <InputGroup className="mb-2" id='inputGrupoReg'>
                            <Row className='p-0 m-0 align-items-center w-100'>
                                <Col className="col-12 col-md-3 px-0">
                                    <Form.Label htmlFor="basic-url" className="label__register">Documento de identificación:</Form.Label>                                
                                </Col>
                                <Col className="col-12 col-md-9 px-0">
                                    <Row className='p-0 m-0'>
                                        <Col className='col-4 px-0'>
                                            <select
                                                name="documentType"
                                                className="form-control select-css"
                                                id="documentType"
                                                onChange={({target}) => onChange(target.value, "documentType")}
                                                value={documentType}
                                                style={{ paddingRight: "44px" }}
                                                disabled={stateInput}
                                            >
                                                {!!documentTypes && documentTypes.map((document) => (
                                                    <option key={document.id} value={document.id}>
                                                        {document.text}
                                                    </option>
                                                ))}
                                            </select>                                        
                                        </Col>
                                        <Col className='col-8 pl-2 pr-0'>
                                            <Form.Control
                                                name='Documento'
                                                className='inputClass'
                                                aria-label="Documento" type="number"
                                                value={documento}
                                                onChange={({ target }) => { onChange(target.value, 'documento') }}
                                                disabled={stateInput}
                                                id="DocuemntoRegistro"
                                                placeholder='Ingresa tu número de documento'
                                            />                                        
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </InputGroup>
                        {
                            (!stateInput)&&(
                                <div className='row p-0 m-0 mb-2 align-items-center w-100 mb-btn-con'>
                                    <div className='col-12 col-md-3 px-0'/>
                                    <div className='col-12 col-md-9 px-0 '>
                                        <ReCAPTCHA
                                        ref={recaptchaRef}
                                        size="invisible"
                                        sitekey={captchaKey}
                                        onChange={(e) => { preRegisterUser(documentType,documento,e) }}
                                        className="d-none"
                                        />
                                        <Button 
                                            id="btnYellowValidate"  
                                            className='col-12 pl-0 button__register'
                                            onClick={()=>{ recaptchaRef.current.execute()}}
                                        >
                                                CONSULTAR TUS DATOS
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                        {
                            (stateInput)&&(<InputComponents
                                idInput="FirstNameRegister"
                                textLabel="Nombres y apellidos:"
                                valueInput={!defaultFormRegister.nombre?nombre:defaultFormRegister.nombre}
                                TypeInput="text"
                                BtnState={!defaultFormRegister.nombre?stateInput:defaultFormRegister.nombre}
                                funtioninput={({ target }) => { onChange(target.value, 'nombre') }}
                            />)
                        }
                        
                        
                        <InputComponents
                            idInput="PhoneRegister"
                            textLabel="Número celular:"
                            valueInput={telefono}
                            TypeInput="text"
                            BtnState={!stateInput}
                            funtioninput={(e) => { onChangePhone(e.target.value, false) }}
                            placeholder="Ingresa tu número de celular"
                        />
                        {
                            errorPhone != '' &&( <ErrorMessageComponent message = {errorPhone} />)
                        }
                        <InputComponents
                            idInput="CorreoRegister"
                            textLabel="Correo electrónico:"
                            valueInput={correo}
                            TypeInput="text"
                            BtnState={!stateInput}
                            funtioninput={(e) => { onChangeEmail(e.target.value, false) }}
                            placeholder="Ingresa tu correo"
                        />
                        {
                            errorEmail != '' &&( <ErrorMessageComponent message = {errorEmail} />)
                            
                        }
                        <InputComponents
                            idInput="ContrasenaRegistro"
                            textLabel="Contraseña:"
                            valueInput={clave}
                            TypeInput="password"
                            BtnState={!stateInput}
                            funtioninput={({ target }) => { onChange(target.value, 'clave') }}
                            placeholder="Ingresa tu contraseña"
                            refPassword={refPassword}
                        />
                        <div className="checkbox-terminos mt-4 mb-2" style={{ fontSize: 14, color: "#777777", fontFamily: "Inter-regular", margin: '0 10px' }}>
                            <input
                                className="checkbox-terminos"
                                type="checkbox"
                                id="chkTC"
                                name="chkTC"
                                onChange={({target}) => onChangeTerminos(target.checked,'chkTC')}
                            />
                            <label className="checkbox-terminos" htmlFor="chkTC">

                            </label>
                            <span style={{ marginLeft: '4%', fontFamily: 'Scotia-regular', color: '#777777', fontSize: '14px', fontWeight: '400', }}> Acepto los </span>
                            {" "}
                            <ModalTerms title={terCond} text="este es el texto" />{
                                    errorTerminos != '' &&(<><br/><ErrorMessageComponent message = {errorTerminos} className='errorMassageCheck'/></>)
                            }
                        </div>
                        <div className="checkbox-terminos mb-2" style={{ fontSize: 14, color: "#777777", fontFamily: "Inter-regular", margin: '0 10px' }}>
                            <input
                                className="checkbox-terminos"
                                type="checkbox"
                                id="chkPL"
                                name="chkPL"
                                onChange={({target}) => onChangePoliticas(target.checked, 'chkPL')}
                            />
                            <label className="checkbox-terminos"
                            htmlFor="chkPL"
                            >

                            </label>
                            <span style={{ marginLeft: '4%', fontFamily: 'Scotia-regular', color: '#777777', fontSize: '14px', fontWeight: '400', }}> Acepto la </span>
                            {" "}
                            <ModalPolicies title={polServ} />
                            {
                                    errorPoliticas != '' &&(
                                    <><br/> <ErrorMessageComponent message = {errorPoliticas} className='errorMassageCheck'/></>
                                    )
                                
                            }
                        </div>
                        {
                            (stateInput)&&(
                                <div className="mt-3">
                                    <ReCAPTCHA
                                        ref={recaptchaRegister}
                                        size="invisible"
                                        sitekey={captchaKey}
                                        onChange={(e) => {registerUser( telefono, correo, clave, documento, e) }}
                                        className="d-none"
                                        />
                                    <Button 
                                            ref={btnCreateRef}
                                            id="btnYellowRegister" 
                                            onClick={()=>{ (validateFieldsRegister(documento)) && recaptchaRegister.current.execute()}}
                                            >
                                                CREAR MI CUENTA
                                    </Button>
                                </div>
                            )
                        }
                        <Row className='mt-4'>
                            <p className='m-auto'>
                                ¿Ya tienes cuenta? <a className='link__inicio' href='/inicia-sesion'>Inicia sesión</a>
                            </p>
                        </Row>
                    </div>
                    <div className='col-12 col-lg-4 cardStyle'>
                    </div>
                </div>
            </ContenedorComponent>
        </Row>
  )
}
